:root {
  --selfai-text-color: var(--text-color);
  --selfai-secondary-text-color: #fff;
  --selfai-primary-text-color: #fff;
}

:root {
  --selfai-font-size-xs: 0.8125rem/1.25rem;
}

.p-confirm-popup-message {
  white-space: pre-line;
}

.p-paginator-pages .p-paginator-page {
  border-radius: 50% !important;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fdfeff;
  padding: 12px 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 2rem;
  gap: 2rem;
}

.prime-table-custom__paginator {
  display: none;
}

.p-column-filter-buttonbar {
  gap: 1rem;
}

.p-datatable-sm table {
  table-layout: fixed;
}
.p-datatable-sm th svg {
  vertical-align: middle;
}
.p-datatable-sm tr .actions {
  opacity: 0;
}
.p-datatable-sm tr .actions .p-button-text {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
}
.p-datatable-sm tr .actions .p-button-text:hover {
  background-color: #e0e0e0;
}
.p-datatable-sm tr:hover .actions {
  opacity: 1;
}

.data-view-card__name {
  height: 3.75rem;
}

.data-view-header {
  background: #f6f9fe;
  border: 1px solid rgba(232, 241, 248, 0.7);
}

.p-buttonset button.p-button[type=button],
.p-selectbutton .p-button {
  margin: 0;
}
.p-buttonset button.p-button[type=button]:not(:last-child),
.p-selectbutton .p-button:not(:last-child) {
  border-right: 0 none;
}
.p-buttonset button.p-button[type=button]:not(:first-of-type):not(:last-of-type),
.p-selectbutton .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
.p-buttonset button.p-button[type=button]:first-of-type,
.p-selectbutton .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-buttonset button.p-button[type=button]:last-of-type,
.p-selectbutton .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-selectbutton .p-button {
  padding: 0.6rem;
}

.short-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.short-text,
th.short-text {
  display: table-cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}