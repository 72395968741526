@charset "utf-8";

/**************************************************************
    체크박스 폼
**************************************************************/

.ddp-list-checktype.ddp-list-padd li {
  padding: 5px 0 5px 0;
  font-size: 0;

  .ddp-label-checkbox span.ddp-txt-checkbox {
    font-size: 13px;

    span.ddp-data-valuenum {
      position: relative;
      top: -1px;
      margin-left: 5px;
      color: #90969f;
      font-size: 12px;
    }
  }
}

.ddp-label-checkbox {
  &.ddp-type {
    display: inline-block;

    span.ddp-txt-checkbox {
      font-size: 12px;
    }
  }

  &.ddp-inline {
    display: inline-block;
  }

  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;

  span.ddp-txt-checkbox {
    display: inline-block;
    font-size: 13px;
    color: #36393e;
    vertical-align: bottom;
    word-break: break-all;
  }

  input[type='checkbox'] {
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 0;
    margin-top: -7px;
  }

  i {
    &.ddp-icon-checkbox {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -6px;
      width: 12px;
      height: 12px;
      background: url(/assets/bi/images/icon_checkbox.png) no-repeat;
    }

    &.ddp-icon-global {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -6px;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_global.png) no-repeat;
      background-position: left -18px;
    }
  }
}

.ddp-label-radio i.ddp-icon-global {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
  width: 13px;
  height: 13px;
  background: url(/assets/bi/images/icon_global.png) no-repeat;
  background-position: left -18px;
}

.ddp-label-checkbox.type-global,
.ddp-label-radio.type-global {
  cursor: default;
}

.ddp-label-checkbox {
  input[type='checkbox']:checked + i.ddp-icon-checkbox,
  &.ddp-checkboxtype input[type='checkbox']:checked + i.ddp-icon-checkbox {
    background-position: -12px top;
  }

  input[type='checkbox'] {
    &:disabled + i.ddp-icon-checkbox {
      background-position: -36px top;
      cursor: no-drop;
    }

    &:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
      font-weight: bold;
    }

    &:disabled + i.ddp-icon-checkbox + .ddp-txt-checkbox {
      color: #d0d1d8;
      cursor: no-drop;
    }
  }

  &.ddp-checkboxtype input[type='checkbox'] {
    &:disabled + i.ddp-icon-checkbox {
      color: #d0d1d8;
      cursor: no-drop;
    }

    + i.ddp-icon-checkbox {
      background-position: -24px top;
    }
  }

  &.ddp-position {
    display: block;
    padding: 0 19px 0 0;

    input,
    i {
      position: absolute;
      right: 0;
      left: inherit;
    }
  }

  &.ddp-type-number {
    i.ddp-icon-checkbox {
      text-indent: -300000px;
    }

    input[type='checkbox']:checked + i.ddp-icon-checkbox {
      text-indent: 0;
      background: none;
      font-style: normal;
      color: $primary;
    }
  }

  &.ddp-check-disabled:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: no-drop;
    background-color: rgba(255, 255, 255, 0.5);
    content: '';
  }

  &.ddp-form {
    span.ddp-txt-checkbox {
      color: #b7b9c2;
      font-size: 12px;
    }

    input[type='checkbox']:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
      color: #90969f;
      font-weight: normal;
    }
  }
}

.ddp-label-check-value {
  cursor: pointer;

  input {
    display: none;
  }

  i {
    display: inline-block;
    width: 22px;
    height: 14px;
    background: url(/assets/bi/images/icon_checkvalue.png) no-repeat;
  }

  input[type='checkbox']:checked + i {
    background-position: left -15px;
  }
}

.ddp-num-checkbox {
  display: inline-block;
  cursor: pointer;

  i {
    display: inline-block;
    position: relative;
    width: 31px;
    height: 21px;
    padding: 2px 0;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    text-indent: -300000px;
    border-radius: 2px;
    background-color: #d0d1d8;
    box-sizing: border-box;
    vertical-align: middle;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -7px;
      width: 14px;
      height: 10px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      content: '';
    }
  }

  input {
    display: none;

    &:checked + i {
      text-indent: 0;
      background-color: #6870ae;

      &:before {
        display: none;
      }
    }
  }
}

.ddp-wrap-num-checkbox .ddp-label-checkbox {
  margin-left: 18px;
  vertical-align: middle;
}

.ddp-ui-checkbox {
  position: relative;

  input[type='checkbox'] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  i.ddp-icon-checkbox {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_checkbox.png) no-repeat;
  }

  input[type='checkbox'] {
    &:disabled + i.ddp-icon-checkbox {
      background-position: -36px top;
      cursor: no-drop;
    }

    &:checked + i.ddp-icon-checkbox {
      background-position: -12px top;
    }
  }

  &.ddp-checkboxtype input[type='checkbox'] {
    &:checked + i.ddp-icon-checkbox {
      background-position: -12px top;
    }

    + i.ddp-icon-checkbox {
      background-position: -24px top;
    }
  }

  input[type='checkbox']:disabled {
    + i.ddp-icon-checkbox + .ddp-ui-tooltip-info {
      position: absolute;
      top: 100%;
      left: -10px;
      right: inherit;
      margin-top: 6px;

      .ddp-icon-view-top {
        left: 14px;
        margin-left: 0;
      }
    }

    &:hover + i.ddp-icon-checkbox + .ddp-ui-tooltip-info {
      display: block;
    }
  }

  &.ddp-check-disabled:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: no-drop;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    content: '';
  }
}

.ddp-label-checkbox {
  &.ddp-type2 {
    span.ddp-txt-checkbox {
      color: #90969f;
      font-size: 12px;
    }

    i {
      display: inline-block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -6px;
      background: url(/assets/bi/images/icon_checkbox2.png) no-repeat;
      background-position: left -13px;
    }

    input[type='checkbox'] {
      &:checked + i {
        background-position: -13px -13px;
      }

      &:disabled + i {
        background-position: -26px -26px;
      }
    }
  }

  &.ddp-type3 {
    span.ddp-txt-checkbox {
      color: #9096a2;
      font-size: 12px;
    }

    i {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      background: url(/assets/bi/images/icon_checkbox2.png) no-repeat;
      background-position: left -26px;
    }

    input[type='checkbox'] {
      &:checked + i {
        background-position: -13px -26px;
        background-position: -13px -26px;
      }

      &:disabled + i {
        background-position: -26px -26px;
        opacity: 0.2;

        + span {
          font-weight: normal;
          opacity: 0.2;
        }
      }
    }

    &.ddp-disabled span.ddp-txt-checkbox {
      opacity: 0.5;
    }
  }
}

/*************************************************************************
	checkbox_slide
***************************************************************************/

.ddp-ui-slidecheck {
  float: left;
  width: 100%;
}

.ddp-checkbox-slide {
  label {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 3px;
    position: relative;
    background-color: #dcdce2;
    border-radius: 10px;
  }

  .ddp-slide {
    display: block;
    width: 11px;
    height: 11px;
    color: #fff;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: absolute;
    top: -4px;
    left: -1px;
    z-index: 1;
    background: #b7b9c2;
    border-radius: 50%;
  }

  label:after {
    color: #9c9c9c;
    position: absolute;
    left: -41px;
    top: -3px;
    z-index: 0;
    width: 35px;
    text-align: right;
    font-size: 11px;
  }

  &.ddp-checkbox-automatic2,
  &.ddp-checkbox-automatic3 {
    display: inline-block;
    position: relative;
    padding: 5px 5px 5px 65px;
  }

  &.ddp-checkbox-automatic2 label:after {
    display: inline-block;
    top: -7px;
    content: 'OFF';
    color: #b7b9c2;
    font-size: 11px;
  }

  &.ddp-checkbox-automatic3 {
    label:after {
      display: inline-block;
      top: -7px;
      content: '수동';
      color: #b7b9c2;
      font-size: 11px;
    }

    &.ddp-change label:after {
      content: '자동';
    }

    &.ddp-en {
      label:after {
        width: 40px;
        left: -44px;
        top: -6px;
        content: 'manual';
      }

      &.ddp-change label:after {
        content: 'Auto';
      }
    }
  }

  &.ddp-checkbox-automatic2 {
    &.ddp-disabled {
      * {
        opacity: 0.5;
      }

      input {
        opacity: 0;
      }

      &:after {
        display: inline-block;
        z-index: 10;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
      }
    }

    input {
      &:disabled + label {
        &:after {
          opacity: 0.5;
        }

        background-color: rgba(221, 221, 221, 0.5);
        cursor: no-drop;
      }

      &[type='checkbox']:checked + label:after {
        display: inline-block;
        top: -7px;
        content: 'ON';
        color: #9c9c9c;
        font-size: 11px;
      }
    }
  }

  &.ddp-checkbox-automatic3 {
    input[type='checkbox']:checked + label:after {
      display: inline-block;
      top: -7px;
      content: '자동';
      color: #9c9c9c;
      font-size: 11px;
      content: '수동';
    }

    &.ddp-en {
      input[type='checkbox']:checked + label:after {
        width: 60px;
        top: -6px;
        left: -65px;
        content: 'Automatic';
      }

      &.ddp-change input[type='checkbox']:checked + label:after {
        content: 'Manual';
      }
    }
  }

  &.ddp-checkbox-automatic2 {
    input:disabled + label span.ddp-slide,
    &.ddp-disabled input:disabled + label span.ddp-slide {
      opacity: 0.2;
    }
  }

  input[type='checkbox'] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:checked + label {
      background-color: #b7bcdb;

      > span.ddp-slide {
        background-color: $primary;
      }

      &:after {
        color: #9f9f9f;
        position: absolute;
        left: -41px;
        top: -3px;
        z-index: 0;
        width: 35px;
        text-align: right;
      }

      span.ddp-slide {
        left: 20px;
      }
    }
  }

  &.ddp-middle {
    input[type='checkbox'] {
      &:checked + label {
        background-color: #b7bcdb;
      }

      + label span.ddp-slide {
        display: inline-block;
        width: 11px;
        height: 11px;
        left: 9px;
        background: url(/assets/bi/images/icon_switch2.png) no-repeat;
      }
    }

    label:after {
      display: inline-block;
      top: -5px;
      content: 'ON';
      color: #9c9c9c;
      font-size: 11px;
    }
  }

  &.ddp-slide-text {
    position: relative;

    .ddp-txt-slide {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 5px;
      color: #9c9c9c;
      font-size: 11px;
      vertical-align: middle;
    }

    label {
      display: inline-block;
    }

    &.ddp-middle label:after {
      display: none;
    }
  }
}
