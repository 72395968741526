@charset "utf-8";

/**********************************************************************************
	검색
**********************************************************************************/

.ddp-form-search {
  position: relative;
  width: 300px;
  box-sizing: border-box;

  em.ddp-icon-search {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    width: 11px;
    height: 11px;
    background: url('/assets/bi/images/icon_search.png') no-repeat;
  }

  input {
    display: block;
    padding: 6px 22px 6px 22px !important;
    width: 100%;
    border: none;
    border-bottom: 1px solid #d0d1d7;
    background-color: transparent;
    font-size: 13px;
    box-sizing: border-box;

    &:focus {
      border-bottom: 1px solid #b7b9c1;

      ~ .tooltip-for-search {
        display: block;
        position: absolute;
        background: $primary;
        padding: 3px;
        margin: 6px;
        z-index: 2;
        color: white;
        border-radius: 3px;
        margin-left: 0px;
      }
    }

    ~ .tooltip-for-search {
      display: none;
    }

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: #b7b9c2;
    }
  }

  .ddp-btn-search-close {
    display: inline-block;
    padding: 5px;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -8px;
    cursor: pointer;
    font-size: 0 !important;

    &:before {
      display: inline-block;
      width: 7px;
      height: 7px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: left -38px;
      content: '';
    }
  }

  &.ddp-full {
    width: 100%;
  }
}

.ddp-ui-searching {
  position: relative;
  margin: 0 16px 0 16px;
  padding: 0 30px 0 26px;
  background-color: #f3f4f9;

  .ddp-icon-search {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 8px;
    margin-top: -7px;
    width: 13px;
    height: 14px;
    background: url(/assets/bi/images/btn_search.png) no-repeat left -51px;
  }

  &.type-search input {
    color: #666db7;
  }

  input {
    display: block;
    width: 100%;
    padding: 5px 0 4px 0;
    border: none;
    font-size: 13px;
    line-height: 21px;
    background: none;
  }

  .ddp-btn-delete {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -8px;
    width: 15px;
    height: 15px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: -16px -132px;
  }
}

.ddp-form-search2 {
  position: relative;
  padding-right: 30px;
  border: 1px solid #d0d1d9;

  input {
    display: block;
    width: 100%;
    padding: 6px 10px 6px 10px;
    box-sizing: border-box;
    border: none;
  }

  span.ddp-icon-search {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 4px;
    width: 22px;
    height: 22px;
    margin: -11px 0 0 0;
    background-color: #f6f6f7;
    border-radius: 2px;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e8;
    }

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      width: 12px;
      height: 12px;
      background: url(/assets/bi/images/btn_search.png) no-repeat;
      background-position: left -38px;
      content: '';
    }
  }
}

.ddp-search-value {
  .ddp-data-value {
    display: block;
    position: relative;
    padding: 0 28px 0 25px;
    border-radius: 3px;
    background-color: #efeff1;
    z-index: 10;

    &.ddp-data-del {
      padding-right: 8px;
    }

    input {
      display: block;
      width: 100%;
      padding: 7px 0;
      font-size: 13px;
      border: none;
      background-color: transparent;
      box-sizing: border-box;
    }

    .icon-search-p {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 8px;
      margin-top: -6px;
      width: 11px;
      height: 11px;
      background: url('/assets/bi/images/icon_search.png') no-repeat;
    }

    .icon-warning {
      display: none;
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -7px;
      width: 15px;
      height: 14px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat;
      background-position: left -12px;
      cursor: pointer;
    }
  }

  &.ddp-warning .ddp-data-value .icon-warning {
    display: block;
  }

  .ddp-data-value .ddp-btn-del {
    display: none;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -8px;
    width: 15px;
    height: 15px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -132px;
  }

  .ddp-btn-line {
    display: none;
    float: right;
    margin-left: 4px;
  }

  &.ddp-warning {
    &.ddp-search .ddp-btn-line {
      display: block;
    }

    &.ddp-focus {
      &.ddp-search .ddp-btn-line {
        display: block;
        border: 1px solid #4b515b;
        color: #4b515b;
      }

      .ddp-btn-line {
        display: block;
      }

      .icon-warning,
      .ddp-btn-del {
        display: none;
      }
    }
  }

  &.ddp-search {
    .ddp-data-value .icon-warning {
      display: none;
    }

    .ddp-btn-del {
      display: block !important;
    }
  }

  &.ddp-warning {
    &.ddp-focus .ddp-data-value,
    &.ddp-search .ddp-data-value {
      overflow: hidden;
    }
  }

  .ddp-pop-warning {
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    padding: 5px 10px;
    max-width: 240px;
    border-radius: 3px;
    background-color: rgba(97, 116, 179, 0.9);
    color: #fff;
    font-size: 13px;
    box-sizing: border-box;
    letter-spacing: -0.5px;

    &:before {
      display: inline-block;
      position: absolute;
      bottom: 100%;
      right: 23px;
      width: 4px;
      height: 8px;
      margin-bottom: -2px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      background-position: left -9px;
      transform: rotate(90deg);
      content: '';
    }
  }

  .ddp-data-value .icon-warning:hover + .ddp-pop-warning,
  &.ddp-nofilter .ddp-txt-nofilter {
    display: block;
  }

  .ddp-txt-nofilter {
    display: none;
    float: right;
    position: relative;
    top: 6px;
    margin: 0 6px;
    color: #b6b9c2;
    font-size: 12px;

    &:before {
      display: inline-block;
      position: relative;
      top: -1px;
      margin-right: 3px;
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat;
      background-position: -15px -94px;
      content: '';
      vertical-align: middle;
    }
  }
}

/**************************************************************
  multi search
**************************************************************/

.ddp-multi-search {
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #d0d1d9;
  background-color: #fff;

  .ddp-search-select {
    display: inline-block;
    position: relative;
    width: 133px;
    padding: 0 25px 0 10px;
    text-align: left;
    border-right: 1px solid #d0d1d9;
    cursor: pointer;
    vertical-align: top;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 11px;
      margin-top: -2px;
      width: 7px;
      height: 4px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -8px top;
      content: '';
    }

    span.ddp-data {
      display: block;
      padding: 6px 0 6px 0;
      color: #4b515b;
      font-size: 13px;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      left: -1px;
      right: -1px;
      background-color: #fff;
      box-sizing: border-box;
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }

  .ddp-search {
    display: inline-block;
    position: relative;
    width: 289px;
    padding: 0 0 0 24px;
    box-sizing: border-box;

    .ddp-icon-search {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 8px;
      margin-top: -6px;
      width: 11px;
      height: 11px;
      background: url('/assets/bi/images/icon_search.png') no-repeat;
      background-position: -24px 0;
    }

    input {
      display: block;
      width: 100%;
      padding: 6px 10px 6px 0;
      font-size: 13px;
      box-sizing: border-box;
      border: none;
    }
  }
}

/**************************************************************
  select search
**************************************************************/

.ddp-select-search {
  display: inline-block;
  max-width: 100%;
  position: relative;

  .ddp-ui-search2 {
    display: inline-block;
    max-width: 100%;
    padding-right: 30px;
    color: #4b515b;
    font-weight: bold;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    vertical-align: middle;

    span.ddp-icon-search {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      background-color: #f6f6f7;
      border-radius: 2px;
      vertical-align: middle;

      &.ddp-disabled {
        opacity: 0.3;
        cursor: no-drop;
      }
    }
  }

  &.ddp-selected .ddp-ui-search2 span.ddp-icon-search.ddp-disabled {
    background-color: #f6f6f7;
  }

  .ddp-ui-search2 {
    &.ddp-selected span.ddp-icon-search {
      background-color: #e3e3e8;
    }

    span.ddp-icon-search:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      width: 12px;
      height: 12px;
      background: url(/assets/bi/images/btn_search.png) no-repeat;
      background-position: left -38px;
      content: '';
    }
  }

  .ddp-box-layout4 {
    display: none;
    position: absolute;
    top: 100%;
    left: -30px;
  }

  &.ddp-selected {
    .ddp-box-layout4 {
      display: block;
      z-index: 20;
    }

    a.ddp-ui-search2 {
      text-decoration: underline;

      &.type-underline-none {
        text-decoration: none;
      }
    }

    .ddp-ui-search2 span.ddp-icon-search {
      background-color: #e3e3e8;
    }
  }
}

.ddp-type-search {
  &.type-box {
    border: 1px solid #d0d1d8;
    border-radius: 2px;
    padding-left: 35px;
    box-sizing: border-box;

    &:before {
      left: 12px;
    }
  }

  position: relative;
  padding: 0 20px 0 23px;

  &:before {
    display: Inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    background: url('/assets/bi/images/icon_search.png') no-repeat;
    background-position: -15px -12px;
    content: '';
  }

  .ddp-btn-delete {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
    width: 15px;
    height: 15px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -132px;
  }

  &.type-box .ddp-btn-delete {
    right: 3px;
  }

  &.ddp-selected .ddp-btn-delete {
    display: block;
  }

  input {
    display: block;
    width: 100%;
    padding: 6px 0;
    border: none;
    background: none;
    font-size: 13px;

    &::placeholder {
      color: #b6b9c2;
    }
  }
}

/**************************************************************
  hover search
**************************************************************/
/*.ddp-hover-search {display:inline-block; position:relative;}*/
/*.ddp-hover-search .ddp-ui-search2 {color:#4b515b; font-weight:bold;}*/
/*.ddp-hover-search .ddp-ui-search2 span.ddp-icon-search {display:inline-block; position:relative; width:22px; height:22px; margin-left:7px; background-color:#f6f6f7; border-radius:2px; vertical-align:middle;}*/
/*.ddp-hover-search .ddp-ui-search2:hover span.ddp-icon-search {background-color:#e3e3e8;}*/
/*.ddp-hover-search .ddp-ui-search2 span.ddp-icon-search:before {display:inline-block; position:absolute; top:50%; left:50%; margin:-6px 0 0 -6px; width:12px; height:12px; background:url(/assets/bi/images/btn_search.png) no-repeat; background-position:left -38px; content:'';}*/
/*.ddp-hover-search .ddp-box-layout4 {display:none; position:absolute; top:100%; left:-30px;}*/
/*.ddp-hover-search:hover .ddp-box-layout4 {display:block;}*/
/*.ddp-hover-search:hover a.ddp-ui-search2 {text-decoration:underline;}*/
/*.ddp-hover-search:hover .ddp-ui-search2 span.ddp-icon-search {background-color:#e3e3e8;}*/
