@charset "utf-8";

/**************************************************************
	icons
**************************************************************/

.ddp-icon-time,
.ddp-icon-time2,
.ddp-icon-time3,
.ddp-icon-time4,
.ddp-icon-time5 {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(/assets/bi/images/icon_time.png) no-repeat;
  vertical-align: middle;
}

.ddp-icon-time4 {
  width: 14px;
}

.ddp-icon-time2 {
  background-position: -16px top;
}

.ddp-icon-time3 {
  background-position: -32px top;
}

.ddp-icon-time4 {
  background-position: -14px -16px;
}

.ddp-icon-time5 {
  width: 13px;
  height: 13px;
  background-position: -28px -16px;
}

.ddp-icon-scheme,
.ddp-icon-refresh3,
.ddp-icon-tb {
  display: inline-block;
  width: 13px;
  height: 14px;
  background: url(/assets/bi/images/icon_scheme.png) no-repeat;
}

.ddp-icon-refresh3 {
  width: 12px;
  height: 13px;
  background-position: 0 -15px;

  &:hover {
    background-position: -14px -15px;
  }
}

.ddp-icon-tb {
  width: 10px;
  height: 10px;
  background-position: -14px 0;
}

.ddp-icon-group-s2,
.ddp-icon-user-s2 {
  display: inline-block;
  background: url(/assets/bi/images/icon_group.png) no-repeat;
  opacity: 0.5;
}

.ddp-icon-group-s2 {
  width: 17px;
  height: 12px;
  background-position: left -15px;
}

.ddp-icon-user-s2 {
  width: 10px;
  height: 12px;
  background-position: left -28px;
}

em {
  &.ddp-icon-dataset2 {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_dataset.png) no-repeat;
    background-position: -20px top;
    vertical-align: middle;
  }

  &.ddp-icon-dataset3 {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_dataset.png) no-repeat;
    background-position: -20px top;
    vertical-align: middle;
    background-position: -60px top;
  }
}

.ddp-result-scuccess {
  color: #5cacdf;
  font-size: 12px;
}

.ddp-result-fail {
  color: #e35252;
  font-size: 12px;
  cursor: pointer;

  &:after {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 3px;
    content: '';
    width: 13px;
    height: 13px;
    vertical-align: middle;
    background: url(/assets/bi/images/icon_go.png) no-repeat;
  }

  &:hover {
    text-decoration: underline;
  }
}

em.ddp-icon-dataset {
  display: inline-block;
  position: relative;
  top: -3px;
  width: 19px;
  height: 19px;
  margin-right: 5px;
  background: url(/assets/bi/images/icon_dataset.png) no-repeat;
  vertical-align: middle;
}

.ddp-icon-success {
  display: inline-block;
  background: url(/assets/bi/images/icon_message.png) no-repeat;
}

.ddp-icon-fail {
  display: inline-block;
  background: url(/assets/bi/images/icon_message.png) no-repeat;
  width: 9px;
  height: 10px;
}

.ddp-icon-success {
  width: 11px;
  height: 8px;
  background-position: left -19px;
}

.ddp-btn-reset2,
.ddp-btn-reset3 {
  display: inline-block;
  width: 14px;
  height: 13px;
  background: url(/assets/bi/images/icon_reset.png) no-repeat;
  background-position: left -34px;
}

.ddp-btn-reset2:hover {
  background-position: -15px -34px;
}

.ddp-btn-reset3 {
  background-position: -16px -20px;

  &:hover {
    background-position: -15px -34px;
  }
}

.ddp-icon-reset2 {
  display: inline-block;
  width: 14px;
  height: 13px;
  background: url(/assets/bi/images/icon_reset.png) no-repeat;
  background-position: -31px -20px;
}

.ddp-icon-database-s,
.ddp-icon-database-s2,
.ddp-icon-database-w {
  display: inline-block;
  width: 12px;
  height: 15px;
  background: url(/assets/bi/images/icon_database.png) no-repeat;
  background-position: left -30px;
  vertical-align: middle;
}

.ddp-icon-database-b {
  display: inline-block;
  width: 12px;
  height: 15px;
  background: url(/assets/bi/images/icon_database.png) no-repeat;
  background-position: left -30px;
  vertical-align: middle;
  width: 12px;
  height: 13px;
  background-position: -39px top;
}

.ddp-icon-database-s2 {
  background-position: -26px top;
}

.ddp-icon-database-w {
  width: 12px;
  height: 13px;
  background-position: left top;
}

.ddp-icon-database3 {
  display: inline-block;
  width: 12px;
  height: 14px;
  background: url(/assets/bi/images/icon_database.png) no-repeat;
  background-position: -13px -31px;
  vertical-align: middle;
}

em {
  &.ddp-icon-lock {
    display: inline-block;
    width: 11px;
    height: 13px;
    background: url(/assets/bi/images/icon_lock.png) no-repeat;
    background-position: left top;
  }

  &.ddp-icon-alias {
    display: inline-block;
    position: relative;
    top: -2px;
    height: 11px;
    font-size: 12px;
    color: #656d7b;
    vertical-align: middle;
    font-family: sans-serif;
    font-weight: bold;
  }
}

.ddp-wrap-datarecommend {
  display: inline-block;
  position: relative;
  padding: 1px 0 0 0;
  min-width: 16px;
  height: 14px;
  border: 1px solid #b7b9c2;
  color: #b8bac3;
  font-size: 10px;
  text-align: center;
  line-height: 11px;

  em {
    &.ddp-icon-recommend-s {
      display: inline-block;
      position: absolute;
      bottom: -5px;
      right: -3px;
      width: 6px;
      height: 9px;
      background: url(/assets/bi/images/icon_recommend.png) no-repeat;
      background-position: left -16px;
    }

    &.ddp-icon-recommend-s2 {
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 9px;
      background: url(/assets/bi/images/icon_recommend.png) no-repeat;
      background-position: left -16px;
      position: absolute;
      bottom: -4px;
      right: -6px;
      background-position-y: -26px;
      background-color: #f7f7f8;
      border: 1px solid #f7f7f8;
    }
  }
}

.ddp-wrap-datalock {
  display: inline-block;
  position: relative;
  padding: 1px 0 0 0;
  min-width: 16px;
  height: 11px;
  border: 1px solid #b7b9c2;
  color: #b8bac3;
  font-size: 10px;
  text-align: center;
  line-height: 11px;

  em {
    &.ddp-icon-lock-s {
      position: absolute;
      bottom: -2px;
      right: -2px;
    }

    &.ddp-icon-lock-s2 {
      position: absolute;
      bottom: -3px;
      right: -5px;
      background-color: #f7f7f8;
      border: 1px solid #f7f7f8;
    }
  }
}

.ddp-btn-option {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(/assets/bi/images/icon_option.png) no-repeat;
}

.ddp-btn-option2 {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(/assets/bi/images/icon_option.png) no-repeat;
  width: 14px;
  height: 14px;
  background-position: -30px -16px;
  vertical-align: middle;
}

.ddp-btn-option:hover {
  background-position: -16px top;
}

.ddp-btn-option2:hover {
  background-position: -15px -16px;
}

.ddp-btn-info {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(/assets/bi/images/icon_info.png) no-repeat;
  background-position: -14px -30px;
  cursor: pointer;

  &:hover {
    background-position: -28px -30px;
  }
}

a {
  &.ddp-btn-return-s {
    display: inline-block;
    width: 22px;
    height: 19px;
    background: url(/assets/bi/images/icon_return.png) no-repeat;
  }

  &.ddp-btn-return {
    display: inline-block;
    width: 22px;
    height: 19px;
    background: url(/assets/bi/images/icon_return.png) no-repeat;

    &:hover {
      background-position-x: -23px;
    }
  }

  &.ddp-btn-return-s {
    width: 14px;
    height: 13px;
    background-position: -15px -20px;
  }

  &.ddp-btn-switch {
    display: inline-block;
    width: 7px;
    height: 12px;
    background: url(/assets/bi/images/icon_switch.png) no-repeat;

    &.ddp-selected {
      background-position: -8px top;
    }
  }

  &.ddp-btn-edit-b,
  &.ddp-btn-delete-b {
    display: inline-block;
    background: url(/assets/bi/images/icon_edit_b.png) no-repeat;
  }

  &.ddp-btn-edit-b {
    width: 16px;
    height: 17px;

    &:hover {
      background-position-x: -17px;
    }
  }

  &.ddp-btn-delete-b {
    width: 18px;
    height: 17px;
    background-position: left -18px;

    &:hover {
      background-position-x: -19px;
    }
  }
}

span {
  &.ddp-data-jupyter:before,
  &.ddp-data-zeppelin:before {
    display: inline-block;
    content: '';
    margin-right: 5px;
    background: url(/assets/bi/images/icon_server.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-data-jupyter:before {
    width: 12px;
    height: 16px;
  }

  &.ddp-data-zeppelin:before {
    width: 18px;
    height: 10px;
    background-position: left -17px;
  }

  &.ddp-data-lang-r:before,
  &.ddp-data-lang-python:before {
    display: inline-block;
    content: '';
    margin-right: 5px;
    background: url(/assets/bi/images/icon_lang.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-data-lang-r:before {
    width: 16px;
    height: 13px;
    background-position: left -14px;
  }

  &.ddp-data-lang-python:before {
    width: 13px;
    height: 13px;
  }
}

em {
  &.ddp-icon-lang-zeppelin,
  &.ddp-icon-lang-jupyter {
    display: inline-block;
    content: '';
    margin-right: 5px;
    background: url(/assets/bi/images/icon_lang.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-lang-zeppelin {
    width: 17px;
    height: 11px;
    background-position: left -28px;
  }

  &.ddp-icon-lang-jupyter {
    width: 12px;
    height: 16px;
    background-position: left -40px;
  }

  &.ddp-icon-window {
    display: inline-block;
    width: 12px;
    height: 10px;
    background: url(/assets/bi/images/icon_window.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-lock-s {
    display: inline-block;
    width: 6px;
    height: 8px;
    background: url(/assets/bi/images/icon_lock.png) no-repeat;
    background-position: left -14px;
  }

  &.ddp-icon-lock-s2 {
    display: inline-block;
    width: 6px;
    height: 8px;
    background: url(/assets/bi/images/icon_lock.png) no-repeat;
    background-position: left -14px;
    background-position: left -23px;
  }

  &.ddp-icon-recommend {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 11px;
    height: 15px;
    background: url(/assets/bi/images/icon_recommend.png) no-repeat;
  }

  &.ddp-icon-boardside-lock {
    display: inline-block;
    width: 11px;
    height: 13px;
    background: url(/assets/bi/images/icon_lock.png) no-repeat;
  }

  &.ddp-icon-delete {
    display: inline-block;
    width: 11px;
    height: 12px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_edit2.png) no-repeat;
    background-position: left -21px;
    vertical-align: middle;
  }

  &.ddp-icon-delete2 {
    display: inline-block;
    width: 11px;
    height: 12px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_edit2.png) no-repeat;
    background-position: left -21px;
    vertical-align: middle;
    opacity: 0.5;
  }

  &.ddp-icon-file-sql {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_file.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-wrangled,
  &.ddp-icon-imported,
  &.ddp-icon-imported-s,
  &.ddp-icon-imported2-s,
  &.ddp-icon-db-s,
  &.ddp-icon-flow,
  &.ddp-icon-flow2,
  &.ddp-icon-file,
  &.ddp-icon-file2,
  &.ddp-icon-dbdata,
  &.ddp-icon-dbdata2,
  &.ddp-icon-staging,
  &.ddp-icon-dbdata-s,
  &.ddp-icon-wrangled-m {
    display: inline-block;
    position: relative;
    width: 13px;
    height: 12px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_data.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-imported {
    width: 11px;
    height: 14px;
    background-position: -27px top;
  }

  &.ddp-icon-imported-s {
    width: 13px;
    height: 12px;
    background-position: left top;
    vertical-align: middle;
  }

  &.ddp-icon-imported2-s {
    width: 13px;
    height: 12px;
    background-position: -53px top;
    vertical-align: middle;
  }

  &.ddp-icon-db-s {
    width: 13px;
    height: 13px;
    background-position: -39px top;
    vertical-align: middle;
  }

  &.ddp-icon-flow {
    width: 13px;
    height: 13px;
    background-position: left -50px;
  }

  &.ddp-icon-flow2 {
    width: 13px;
    height: 13px;
    background-position: -14px -50px;
    vertical-align: middle;
  }

  &.ddp-icon-file {
    width: 11px;
    height: 13px;
    background-position: left -64px;
  }

  &.ddp-icon-file2 {
    width: 16px;
    height: 20px;
    background-position: left -78px;
  }

  &.ddp-icon-dbdata {
    width: 17px;
    height: 19px;
    margin-right: 10px;
    background-position: left -30px;
  }

  &.ddp-icon-dbdata2 {
    width: 17px;
    height: 19px;
    margin-right: 10px;
    background-position: left -30px;
    background-position: -18px -30px;
  }

  &.ddp-icon-staging {
    width: 13px;
    height: 15px;
    background-position: left -99px;
  }

  &.ddp-icon-dbdata-s {
    width: 11px;
    height: 14px;
    background-position: -27px top;
  }

  &.ddp-icon-wrangled-m {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-position: left -13px;
  }

  &.ddp-icon-use {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 8px;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    border-radius: 50%;
    background-color: #4b515b;
    vertical-align: middle;
  }
}

.ddp-icon-add {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(/assets/bi/images/icon_add2.png) no-repeat;
  background-position: -14px top;
  vertical-align: middle;
}

.ddp-icon-edit2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(/assets/bi/images/icon_edit2.png) no-repeat;
  vertical-align: middle;

  &:hover {
    background-position: -11px top;
  }
}

em {
  &.ddp-icon-svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }

  &.ddp-icon-sinfo2,
  &.ddp-icon-error2,
  &.ddp-icon-error-s,
  &.ddp-icon-alart {
    display: inline-block;
    background: url(/assets/bi/images/icon_info.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-sinfo2 {
    width: 13px;
    height: 13px;
    background-position: left -16px;
    cursor: pointer;
  }

  &.ddp-icon-error2 {
    width: 13px;
    height: 13px;
    background-position: -14px -16px;
  }

  &.ddp-icon-error-s {
    width: 11px;
    height: 11px;
    background-position: -16px 0;
  }

  &.ddp-icon-alart {
    width: 15px;
    height: 15px;
    background-position: left -44px;
  }

  &.ddp-icon-essential {
    display: inline-block;
    width: 4px;
    height: 5px;
    background: url(/assets/bi/images/icon_essential.png) no-repeat;
  }

  &.ddp-icon-type-graph {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 10px;
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background: url(/assets/bi/images/icon_graph.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-type-sdel {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 10px;
    width: 11px;
    height: 12px;
    margin-top: -6px;
    background: url(/assets/bi/images/btn_info_det.png) no-repeat;
    background-position: left -14px;
    vertical-align: middle;
  }

  &.ddp-icon-type-round {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -6px;
    padding: 1px 3px 0px 1px;
    border-radius: 50%;
    border: 1px solid #7078b7;
    color: #7078b7;
    font-size: 9px;
    vertical-align: middle;
    letter-spacing: -1px;
    box-sizing: border-box;
  }

  &.ddp-icon-tool-title,
  &.ddp-icon-tool-legend,
  &.ddp-icon-tool-minimap,
  &.ddp-icon-tool-linked,
  &.ddp-icon-tool-chart,
  &.ddp-icon-tool-pointer,
  &.ddp-icon-tool-elliptical,
  &.ddp-icon-tool-freeshape,
  &.ddp-icon-tool-rectangular,
  &.ddp-icon-tool-zoomin,
  &.ddp-icon-tool-zoomout,
  &.ddp-icon-tool-revert,
  &.ddp-icon-tool-selectbox,
  &.ddp-icon-tool-display,
  &.ddp-icon-tool-list,
  &.ddp-icon-chartdet {
    display: inline-block;
    background: url(/assets/bi/images/icon_tool.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-tool-title {
    width: 13px;
    height: 13px;
  }

  &.ddp-icon-tool-legend {
    width: 12px;
    height: 11px;
    background-position: left -14px;
  }

  &.ddp-icon-tool-minimap {
    width: 12px;
    height: 13px;
    background-position: left -26px;
  }

  &.ddp-icon-tool-linked {
    width: 16px;
    height: 11px;
    background-position: left -40px;
  }

  &.ddp-icon-tool-chart {
    width: 12px;
    height: 13px;
    background-position: left -52px;
  }

  &.ddp-icon-tool-pointer {
    width: 12px;
    height: 11px;
    background-position: left -66px;
  }

  &.ddp-icon-tool-elliptical {
    width: 13px;
    height: 13px;
    background-position: left -78px;
  }

  &.ddp-icon-tool-freeshape {
    width: 13px;
    height: 12px;
    background-position: left -92px;
  }

  &.ddp-icon-tool-rectangular {
    width: 14px;
    height: 14px;
    background-position: left -105px;
  }

  &.ddp-icon-tool-zoomin {
    width: 12px;
    height: 13px;
    background-position: left -121px;
  }

  &.ddp-icon-tool-zoomout {
    width: 13px;
    height: 13px;
    background-position: left -135px;
  }

  &.ddp-icon-tool-revert {
    width: 11px;
    height: 13px;
    background-position: left -149px;
  }

  &.ddp-icon-chartdet {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background-position: left -163px;
  }

  &.ddp-icon-tool-selectbox {
    width: 15px;
    height: 11px;
    margin: -6px 0 0 -8px;
    background-position: left -177px;
  }

  &.ddp-icon-tool-display {
    width: 12px;
    height: 11px;
    background-position: left -199px;
  }

  &.ddp-icon-tool-list {
    width: 13px;
    height: 9px;
    margin: -5px 0 0 -7px;
    background-position: left -189px;
  }

  &.ddp-icon-spin-loading {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_tabloading.png) no-repeat;
    vertical-align: middle;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.ddp-icon-widget-loading:before {
    display: inline-block;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_tabloading.png) no-repeat;
    vertical-align: middle;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    content: '';
  }

  &.ddp-icon-widget-edit,
  &.ddp-icon-widget-cut,
  &.ddp-icon-widget-moveto,
  &.ddp-icon-widget-save,
  &.ddp-icon-widget-full,
  &.ddp-icon-widget-downsize,
  &.ddp-icon-widget-copy,
  &.ddp-icon-widget-chart,
  &.ddp-icon-widget-gridsave,
  &.ddp-icon-widget-store,
  &.ddp-icon-widget-info {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  &.ddp-icon-widget-edit:before,
  &.ddp-icon-widget-cut:before,
  &.ddp-icon-widget-cut-w:before,
  &.ddp-icon-widget-moveto:before,
  &.ddp-icon-widget-save:before,
  &.ddp-icon-widget-gridsave:before,
  &.ddp-icon-widget-full:before,
  &.ddp-icon-widget-downsize:before,
  &.ddp-icon-widget-copy:before,
  &.ddp-icon-widget-chart:before,
  &.ddp-icon-widget-store:before,
  &.ddp-icon-widget-info:before,
  &.ddp-icon-widget-dataview:before,
  &.ddp-icon-widget-synch:before {
    display: inline-block;
    background: url(/assets/bi/images/icon_widget.png) no-repeat;
    vertical-align: middle;
    content: '';
  }

  &.ddp-icon-widget-trigger:before {
    display: inline-block;
    background: url(/assets/bi/images/icon_widget.png) no-repeat;
    vertical-align: middle;
    content: '';
    width: 17px;
    height: 17px;
    background-position: left -259px;
  }

  &.ddp-icon-widget-edit:before {
    width: 13px;
    height: 13px;
    background-position: left -131px;
  }

  &.ddp-icon-widget-cut:before {
    width: 11px;
    height: 11px;
    background-position: 0 -176px;
  }

  &.ddp-icon-widget-cut-w:before {
    width: 11px;
    height: 11px;
    background-position: -24px -176px;
  }

  &.ddp-icon-widget-moveto:before {
    width: 13px;
    height: 12px;
    background-position: -14px top;
  }

  &.ddp-icon-widget-save:before {
    width: 12px;
    height: 13px;
    background-position: -13px -40px;
  }

  &.ddp-icon-widget-gridsave:before {
    display: inline-block;
    width: 12px;
    height: 13px;
    background-position: -39px -40px;
    content: '';
  }

  &.ddp-icon-widget-full:before {
    width: 14px;
    height: 14px;
    background-position: left -161px;
  }

  &.ddp-icon-widget-downsize:before {
    width: 14px;
    height: 14px;
    background-position: -15px -161px;
  }

  &.ddp-icon-widget-copy:before {
    width: 13px;
    height: 13px;
    background-position: -14px -92px;
  }

  &.ddp-icon-widget-chart:before {
    position: relative;
    top: 2px;
    width: 13px;
    height: 11px;
    background-position: -14px -106px;
  }

  &.ddp-icon-widget-info:before {
    width: 15px;
    height: 15px;
    background-position: left -145px;
  }

  &.ddp-icon-widget-dataview:before {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-position: left -188px;
  }

  &.ddp-icon-widget-store:before {
    width: 13px;
    height: 12px;
    background-position: -14px -118px;
  }

  &.ddp-icon-widget-synch {
    &:before {
      display: inline-block;
      width: 18px;
      height: 13px;
      background-position: left -202px;
    }

    &.ddp-on:before {
      width: 16px;
      background-position-y: -216px;
    }
  }

  &.ddp-icon-workbook {
    display: inline-block;
    width: 12px;
    height: 14px;
    background: url(/assets/bi/images/icon_workbook.png) no-repeat;
  }

  &.ddp-icon-group {
    display: inline-block;
    width: 14px;
    height: 12px;
    background: url(/assets/bi/images/icon_group.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-btnplus2 {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: -11px -66px;
  }

  &.ddp-icon-folder {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 68px;
    width: 15px;
    height: 13px;
    margin-top: -7px;
    background: url(/assets/bi/images/icon_folder.png) no-repeat;
  }

  &.ddp-icon-btnsource,
  &.ddp-icon-result-source,
  &.ddp-icon-result-source-w,
  &.ddp-icon-result-user,
  &.ddp-icon-result-users,
  &.ddp-icon-result-data,
  &.ddp-icon-result-workbook {
    display: inline-block;
    position: relative;
    top: -1px;
    background: url(/assets/bi/images/icon_result.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-btnsource {
    width: 12px;
    height: 14px;
    background-position: -37px top;
  }

  &.ddp-icon-result-source {
    width: 12px;
    height: 14px;
    margin-right: 5px;
    background-position: -37px top;
  }

  &.ddp-icon-result-source-w {
    width: 12px;
    height: 14px;
    background-position: -24px top;
  }

  &.ddp-icon-result-user {
    width: 12px;
    height: 14px;
    margin-right: 5px;
    background-position: left -14px;
  }

  &.ddp-icon-result-users {
    width: 14px;
    height: 12px;
    margin-right: 5px;
    background-position: left -44px;
  }

  &.ddp-icon-result-data {
    width: 12px;
    height: 14px;
    margin-right: 5px;
    background-position: -63px top;
  }

  &.ddp-icon-result-workbook {
    width: 17px;
    height: 14px;
    margin-right: 5px;
    background-position: left -29px;
  }

  &.ddp-icon-btnchart,
  &.ddp-icon-btnchart-w,
  &.ddp-icon-btntext,
  &.ddp-icon-btntext-w,
  &.ddp-icon-btnfilter,
  &.ddp-icon-btnvariable,
  &.ddp-icon-spacelist,
  &.ddp-icon-btnplus,
  &.ddp-icon-dismiss,
  &.ddp-icon-btndone,
  &.ddp-icon-btnsave,
  &.ddp-icon-btnclose,
  &.ddp-icon-btnfav,
  &.ddp-icon-btnworkspace,
  &.ddp-icon-btnadd,
  &.ddp-icon-chart-new,
  &.ddp-icon-filter-new,
  &.ddp-icon-parameter-new,
  &.ddp-icon-text-new,
  &.ddp-icon-btn-edit,
  &.ddp-icon-btn-edit2,
  &.ddp-icon-btn-done {
    display: inline-block;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_buttons.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-btnchart {
    width: 17px;
    height: 14px;
    background-position: left -101px;
  }

  &.ddp-icon-btnchart-w {
    width: 17px;
    height: 14px;
    background-position: -18px -101px;
  }

  &.ddp-icon-btntext {
    width: 11px;
    height: 13px;
    background-position: left -119px;
  }

  &.ddp-icon-btntext-w {
    width: 11px;
    height: 13px;
    background-position: -12px -119px;
  }

  &.ddp-icon-btnfilter {
    width: 16px;
    height: 13px;
    background-position: left -133px;
  }

  &.ddp-icon-btnvariable {
    width: 17px;
    height: 15px;
    background-position: left -147px;
  }

  &.ddp-icon-spacelist {
    width: 14px;
    height: 15px;
    background-position: left -73px;
  }

  &.ddp-icon-btnplus {
    position: relative;
    top: -1px;
    width: 11px;
    height: 11px;
    background-position: left -89px;
  }

  &.ddp-icon-dismiss {
    width: 12px;
    height: 12px;
    background-position: left -173px;
  }

  &.ddp-icon-btndone,
  &.ddp-icon-btnsave {
    width: 15px;
    height: 9px;
    background-position: left -163px;
  }

  &.ddp-icon-btnclose {
    width: 11px;
    height: 11px;
    background-position: left -186px;
  }

  &.ddp-icon-btnfav {
    width: 12px;
    height: 11px;
    background-position: left -227px;
  }

  &.ddp-icon-btnworkspace {
    width: 10px;
    height: 12px;
    background-position: left -239px;
  }

  &.ddp-icon-btnadd {
    width: 10px;
    height: 10px;
    background-position: left -252px;
  }

  &.ddp-icon-chart-new {
    width: 22px;
    height: 17px;
    background-position: -23px -277px;
  }

  &.ddp-icon-filter-new {
    width: 21px;
    height: 14px;
    background-position: left -295px;
  }

  &.ddp-icon-parameter-new {
    width: 21px;
    height: 15px;
    background-position: left -310px;
  }

  &.ddp-icon-btn-edit {
    width: 13px;
    height: 13px;
    background-position: left -339px;
  }

  &.ddp-icon-btn-edit2 {
    width: 13px;
    height: 13px;
    background-position: -14px -339px;
  }

  &.ddp-icon-text-new {
    width: 19px;
    height: 13px;
    background-position: left -326px;
  }

  &.ddp-icon-btn-done {
    width: 15px;
    height: 9px;
    background-position: left -353px;
  }

  &.ddp-icon-btnoption {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_option.png) no-repeat;
    background-position: -30px -16px;
    vertical-align: middle;
  }

  &.ddp-icon-chart2,
  &.ddp-icon-chart3,
  &.ddp-icon-chart-s {
    display: inline-block;
    width: 19px;
    height: 15px;
    background: url(/assets/bi/images/icon_chart.png) no-repeat;
    background-position: left -12px;
    vertical-align: middle;
  }

  &.ddp-icon-chart3 {
    width: 17px;
    height: 14px;
    background-position: left -28px;
  }

  &.ddp-icon-chart-s {
    width: 10px;
    height: 8px;
    background-position: left -43px;
  }

  &.ddp-icon-chart-w {
    display: inline-block;
    width: 13px;
    height: 11px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_widget.png) no-repeat;
    background-position: -28px -106px;
  }

  &.ddp-icon-dashboard2 {
    display: inline-block;
    width: 19px;
    height: 15px;
    background: url(/assets/bi/images/icon_dashboard.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-dashboard3 {
    display: inline-block;
    width: 19px;
    height: 15px;
    background: url(/assets/bi/images/icon_dashboard.png) no-repeat;
    vertical-align: middle;
    width: 17px;
    height: 14px;
    background-position: left -16px;
  }

  &.ddp-icon-notebook {
    display: inline-block;
    width: 12px;
    height: 14px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    background: url(/assets/bi/images/icon_gnb.png) no-repeat;
    background-position: left -29px;
  }

  &.ddp-link-add {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 10px;
    background: url(/assets/bi/images/icon_linkadd.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-eyes2,
  &.ddp-icon-eyes2-none,
  &.ddp-icon-eyes2-on {
    display: inline-block;
    width: 16px;
    height: 11px;
    background: url(/assets/bi/images/icon_eyes.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-eyes2 {
    background-position: -17px 0;
  }

  &.ddp-icon-eyes2-none {
    background-position: left -24px;
  }

  &.ddp-icon-eyes2-on {
    background-position: -17px top;
  }

  &.ddp-icon-sclose {
    padding: 4px;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: left -77px;
      cursor: pointer;
    }
  }

  &.ddp-icon-sclose2 {
    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    background: url(/assets/bi/images/btn_sclose.png) no-repeat;
    background-position: left -94px;
    cursor: pointer;

    &:hover {
      background-position: -9px -94px;
    }
  }

  &.ddp-icon-edit3 {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_edit2.png) no-repeat;
    background-position: -13px -48px;
  }
}

.ddp-icon-edit-s,
.ddp-icon-del-s {
  display: inline-block;
  padding: 4px;
  cursor: pointer;
}

.ddp-icon-edit-s:before,
.ddp-icon-del-s:before {
  display: inline-block;
  content: '';
  background: url(/assets/bi/images/icon_edit2.png) no-repeat;
  cursor: pointer;
}

.ddp-icon-edit-s:before {
  width: 10px;
  height: 10px;
}

.ddp-icon-del-s:before {
  width: 9px;
  height: 9px;
  position: relative;
  top: -1px;
  background-position: 0 -11px;
}

.ddp-icon-edit-s:hover:before {
  background-position: -11px top;
}

.ddp-icon-del-s:hover:before {
  background-position: -10px -11px;
}

em {
  &.ddp-icon-link-add {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: -20px top;
    vertical-align: middle;
  }

  &.ddp-icon-link-plus {
    display: inline-block;
    position: relative;
    width: 9px;
    height: 9px;
    background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
    background-position: left -2px;
    vertical-align: middle;
    cursor: pointer;

    &.ddp-disabled {
      &:hover {
        background-position: left -2px;
      }

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        cursor: no-drop;
      }
    }
  }

  &.ddp-icon-link-approve,
  &.ddp-icon-link-check,
  &.ddp-icon-link-cut,
  &.ddp-icon-link-cut2,
  &.ddp-icon-link-edit,
  &.ddp-icon-link-edit2,
  &.ddp-icon-link-down {
    display: inline-block;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_control.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-link-approve {
    width: 11px;
    height: 11px;
    background-position: left -119px;
  }

  &.ddp-icon-link-check {
    width: 11px;
    height: 8px;
    background-position: left -110px;
  }

  &.ddp-icon-link-cut {
    position: relative;
    top: -1px;
    width: 12px;
    height: 13px;
    background-position: -13px -14px;
  }

  &.ddp-icon-link-cut2 {
    position: relative;
    top: -1px;
    width: 11px;
    height: 12px;
    background-position: -62px -14px;

    &.ddp-disabled {
      background-position: -26px -14px;
      opacity: 0.5;
      cursor: no-drop;

      &:hover {
        background-position: -26px -14px;
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }

  &.ddp-icon-link-edit {
    width: 12px;
    height: 12px;
    background-position: -26px -97px;
  }

  &.ddp-icon-link-edit2 {
    width: 12px;
    height: 12px;
    background-position: -39px -97px;
  }

  &.ddp-icon-link-down {
    width: 13px;
    height: 13px;
    background-position: left -230px;
  }

  &.ddp-icon-upload,
  &.ddp-icon-download {
    display: inline-block;
    background: url(/assets/bi/images/icon_control.png) no-repeat;
  }

  &.ddp-icon-upload {
    width: 11px;
    height: 12px;
    background-position: left -244px;
  }

  &.ddp-icon-download {
    width: 13px;
    height: 13px;
    background-position: left -230px;
  }

  &.ddp-icon-download2 {
    display: inline-block;
    width: 11px;
    height: 13px;
    background: url(/assets/bi/images/icon_download.png) no-repeat;

    &.type-download {
      background-position: left -14px;
    }
  }

  &.ddp-icon-control-copy,
  &.ddp-icon-control-copy-s,
  &.ddp-icon-control-cut,
  &.ddp-icon-control-plus,
  &.ddp-icon-control-cut-s,
  &.ddp-icon-control-info,
  &.ddp-icon-control-fav,
  &.ddp-icon-control-filter,
  &.ddp-icon-control-user,
  &.ddp-icon-control-parameter,
  &.ddp-icon-control-calculat,
  &.ddp-icon-control-dimension,
  &.ddp-icon-control-edit2,
  &.ddp-icon-control-check,
  &.ddp-icon-control-more,
  &.ddp-icon-control-close,
  &.ddp-icon-control-user.ddp-type,
  &.ddp-icon-control-parameter.ddp-type,
  &.ddp-icon-control-calculat.ddp-type,
  &.ddp-icon-control-filter.ddp-type,
  &.ddp-icon-control-link,
  &.ddp-icon-control-insert,
  &.ddp-icon-control-more2 {
    display: inline-block;
    padding: 3px;
    cursor: pointer;
    vertical-align: middle;
  }

  &.ddp-icon-control-copy:before,
  &.ddp-icon-control-copy-s:before,
  &.ddp-icon-control-cut:before,
  &.ddp-icon-control-plus:before,
  &.ddp-icon-control-cut-s:before,
  &.ddp-icon-control-info:before,
  &.ddp-icon-control-fav:before,
  &.ddp-icon-control-filter:before,
  &.ddp-icon-control-user:before,
  &.ddp-icon-control-parameter:before,
  &.ddp-icon-control-calculat:before,
  &.ddp-icon-control-dimension:before,
  &.ddp-icon-control-edit2:before,
  &.ddp-icon-control-check:before,
  &.ddp-icon-control-more:before,
  &.ddp-icon-control-close:before,
  &.ddp-icon-control-user.ddp-type:before,
  &.ddp-icon-control-parameter.ddp-type:before,
  &.ddp-icon-control-calculat.ddp-type:before,
  &.ddp-icon-control-filter.ddp-type:before,
  &.ddp-icon-control-link:before,
  &.ddp-icon-control-insert:before,
  &.ddp-icon-control-more2:before {
    display: inline-block;
    content: '';
    background: url(/assets/bi/images/icon_control.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-control-link {
    padding-top: 4px;

    &:before {
      width: 14px;
      height: 8px;
      background-position: left -257px;
      opacity: 0.6;
    }

    &:hover:before {
      opacity: 1;
    }
  }
}

/* icon_control.png */

.ddp-icon-control-cut-s.ddp-type {
  &:before {
    background-position: -50px -14px;
  }

  &:hover:before {
    background-position: -38px -14px;
  }
}

em {
  &.ddp-icon-control-copy:before {
    width: 13px;
    height: 13px;
  }

  &.ddp-icon-control-cut:before {
    width: 12px;
    height: 13px;
    background-position: left -14px;
  }

  &.ddp-icon-control-plus:before {
    width: 11px;
    height: 11px;
    background-position: left -218px;
  }

  &.ddp-icon-control-info:before {
    width: 13px;
    height: 13px;
    background-position: left -28px;
  }

  &.ddp-icon-control-fav:before {
    width: 12px;
    height: 12px;
    background-position: left -42px;
  }

  &.ddp-icon-control-copy:hover:before {
    background-position: -14px 0;
  }

  &.ddp-icon-control-cut:hover:before {
    background-position: -13px -14px;
  }

  &.ddp-icon-control-info:hover:before {
    background-position: -14px -28px;
  }

  &.ddp-icon-control-fav:hover:before {
    background-position: -13px -42px;
  }

  &.ddp-icon-control-plus:hover:before {
    background-position: -12px -218px;
  }

  &.ddp-icon-control-fav.ddp-selected:before {
    background-position: -26px -42px;
  }

  &.ddp-icon-control-user:before {
    width: 9px;
    height: 9px;
    background-position: left -55px;
  }

  &.ddp-icon-control-filter:before {
    width: 12px;
    height: 9px;
    background-position: left -65px;
  }

  &.ddp-icon-control-parameter:before {
    width: 11px;
    height: 9px;
    background-position: left -87px;
  }

  &.ddp-icon-control-calculat:before {
    width: 11px;
    height: 11px;
    background-position: left -75px;
  }

  &.ddp-icon-control-dimension:before {
    width: 12px;
    height: 12px;
    background-position: left -98px;
  }

  &.ddp-icon-control-more:before {
    display: inline-block;
    width: 2px;
    height: 9px;
    background-position: left -164px;
  }

  &.ddp-icon-control-close:before {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-position: left -174px;
  }

  &.ddp-icon-control-copy-s:before {
    width: 10px;
    height: 10px;
    background-position: left -183px;
  }

  &.ddp-icon-control-cut-s:before {
    width: 10px;
    height: 10px;
    background-position: left -194px;
  }

  &.ddp-icon-control-insert {
    &:before {
      width: 10px;
      height: 11px;
      background-position: left -266px;
    }

    &:hover:before {
      background-position-x: -11px;
    }
  }

  &.ddp-icon-control-more2 {
    width: 16px;
    height: 17px;
    box-sizing: border-box;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 10px;
      margin: -5px 0 0 -1px;
      background-position: left -278px;
    }

    &:hover:before {
      background-position-x: -3px;
    }
  }

  &.ddp-icon-control-copy-s:hover:before {
    background-position: -11px -183px;
  }

  &.ddp-icon-control-cut-s:hover:before {
    background-position: -11px -194px;
  }

  &.ddp-icon-control-close:hover:before {
    background-position: -9px -174px;
  }

  &.ddp-icon-control-user {
    &:hover:before,
    &.ddp-selected:before {
      background-position: -10px -55px;
    }
  }

  &.ddp-icon-control-filter {
    &:hover:before,
    &.ddp-selected:before {
      background-position: -13px -65px;
    }
  }

  &.ddp-icon-control-parameter {
    &:hover:before,
    &.ddp-selected:before {
      background-position: -12px -87px;
    }
  }

  &.ddp-icon-control-calculat {
    &:hover:before,
    &.ddp-selected:before {
      background-position: -12px -75px;
    }
  }

  &.ddp-icon-control-dimension {
    &:hover:before,
    &.ddp-selected:before {
      background-position: -13px -98px;
    }
  }

  &.ddp-icon-control-user.ddp-type:before {
    background-position: -10px -55px;
  }

  &.ddp-icon-control-parameter.ddp-type:before {
    background-position: -12px -87px;
  }

  &.ddp-icon-control-calculat.ddp-type:before {
    background-position: -12px -75px;
  }

  &.ddp-icon-control-user.ddp-type:hover:before {
    background-position: 0 -55px;
  }

  &.ddp-icon-control-parameter.ddp-type:hover:before {
    background-position: 0 -87px;
  }

  &.ddp-icon-control-calculat.ddp-type:hover:before {
    background-position: 0 -75px;
  }

  &.ddp-icon-control-filter.ddp-type:before {
    background-position: -13px -65px;
  }

  &.ddp-icon-control-more:hover:before {
    background-position: -3px -164px;
  }

  &.ddp-icon-control-filter.ddp-type {
    &:hover:before,
    &.ddp-selected:before {
      background-position: left -65px;
    }
  }

  &.ddp-icon-control-edit2 {
    &:before {
      width: 12px;
      height: 12px;
      background-position: left -97px;
    }

    &:hover:before {
      background-position: -13px -97px;
    }
  }

  &.ddp-icon-control-check {
    &:before {
      width: 11px;
      height: 8px;
      background-position: left -110px;
    }

    &:hover:before {
      background-position: -12px -110px;
    }
  }

  &.ddp-icon-control-edit {
    display: inline-block;
    padding: 4px;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      position: relative;
      top: 1px;
      width: 10px;
      height: 10px;
      background: url(/assets/bi/images/icon_edit2.png) no-repeat;
      vertical-align: middle;
    }

    &:hover:before {
      background-position: -11px top;
    }
  }

  &.ddp-icon-global {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(/assets/bi/images/icon_global.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-global-s {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(/assets/bi/images/icon_global.png) no-repeat;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    background-position: left -18px;
  }

  &.ddp-icon-source,
  &.ddp-icon-source2,
  &.ddp-icon-source-w {
    display: inline-block;
    width: 12px;
    height: 15px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_source.png) no-repeat;
    vertical-align: middle;
  }
}

a.ddp-icon-source3 {
  display: inline-block;
  width: 12px;
  height: 15px;
  margin-right: 5px;
  background: url(/assets/bi/images/icon_source.png) no-repeat;
  vertical-align: middle;
}

em.ddp-icon-source-w {
  background-position: -13px top;
}

a {
  &.ddp-icon-source3 {
    width: 16px;
    height: 18px;
    background-position: left -16px;

    &:hover {
      background-position: -17px -16px;
    }
  }

  &.ddp-icon-board-monitoring:before,
  &.ddp-icon-board-edit:before {
    display: inline-block;
    content: '';
    margin-right: 6px;
    background: url(/assets/bi/images/btn_boardoption.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-board-monitoring {
    &:before {
      width: 17px;
      height: 18px;
    }

    &:hover:before {
      background-position: -18px top;
    }
  }

  &.ddp-icon-board-edit {
    &:before {
      width: 15px;
      height: 15px;
      background-position: 0px -14px;
      background-size: 30px;
    }

    &:hover:before {
      width: 15px;
      height: 15px;
      background-position: -15px -14px;
      background-size: 30px;
    }
  }
}

.ddp-icon-drop-monitoring,
.ddp-icon-drop-editmodify,
.ddp-icon-drop-editdel3,
.ddp-icon-drop-dashboard,
.ddp-icon-drop-global,
.ddp-icon-drop-global2,
.ddp-icon-drop-refresh,
.ddp-icon-drop-member,
.ddp-icon-drop-set,
.ddp-icon-drop-schema,
.ddp-icon-drop-change,
.ddp-icon-drop-sort,
.ddp-icon-drop-move,
.ddp-icon-drop-generate,
.ddp-icon-drop-detail,
.ddp-icon-drop-re,
.ddp-icon-drop-clean,
.ddp-icon-drop-cluster,
.ddp-icon-drop-guide,
.ddp-icon-drop-select,
.ddp-icon-drop-sort,
.ddp-icon-drop-unit {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 13px;
  margin-top: -7px;
  width: 13px;
  height: 12px;
  background: url(/assets/bi/images/icon_drop.png) no-repeat;
}

.ddp-icon-drop-disabled {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 13px;
  margin-top: -7px;
  width: 13px;
  height: 12px;
  background: url(/assets/bi/images/icon_drop.png) no-repeat;
  width: 11px;
  height: 11px;
  margin-top: -6px;
  background-position: -315px top;
}

.ddp-icon-drop-unit {
  width: 11px;
  height: 11px;
  background-position: -303px top;
}

.ddp-icon-drop-select {
  width: 12px;
  height: 9px;
  background-position: -277px top;
}

.ddp-icon-drop-sort {
  width: 12px;
  height: 10px;
  background-position: -290px top;
}

.ddp-icon-drop-re {
  width: 13px;
  height: 12px;
  background-position: -205px top;
}

.ddp-icon-drop-monitoring {
  width: 13px;
  height: 12px;
}

.ddp-icon-drop-editmodify {
  width: 12px;
  height: 12px;
  background-position: -14px top;
}

.ddp-icon-drop-editdel3 {
  width: 11px;
  height: 12px;
  margin-top: -6px;
  background-position: -27px top;
}

.ddp-icon-drop-dashboard {
  width: 13px;
  height: 13px;
  background-position: -39px top;
}

.ddp-icon-drop-global {
  width: 13px;
  height: 13px;
  background-position: -53px top;
}

.ddp-icon-drop-refresh {
  width: 14px;
  height: 13px;
  background-position: -67px top;
}

.ddp-icon-drop-member {
  width: 14px;
  height: 12px;
  background-position: -82px top;
}

.ddp-icon-drop-set {
  width: 13px;
  height: 13px;
  background-position: -97px top;
}

.ddp-icon-drop-global2 {
  width: 13px;
  height: 13px;
  background-position: -111px top;
}

.ddp-icon-drop-schema {
  width: 11px;
  height: 11px;
  margin-top: -6px;
  background-position: -125px top;
}

.ddp-icon-drop-change {
  width: 11px;
  height: 12px;
  margin-top: -6px;
  background-position: -137px top;
}

.ddp-icon-drop-sort {
  width: 13px;
  height: 13px;
  margin-top: -7px;
  background-position: -150px top;
}

.ddp-icon-drop-move {
  width: 13px;
  height: 13px;
  margin-top: -7px;
  background-position: -164px top;
}

.ddp-icon-drop-generate {
  width: 13px;
  height: 13px;
  margin-top: -7px;
  background-position: -178px top;
}

.ddp-icon-drop-detail {
  width: 12px;
  height: 13px;
  margin-top: -7px;
  background-position: -192px top;
}

.ddp-icon-drop-clean {
  width: 15px;
  height: 13px;
  margin-top: -7px;
  background-position: -237px top;
}

.ddp-icon-drop-cluster {
  width: 13px;
  height: 12px;
  background-position: -251px top;
}

.ddp-icon-drop-guide {
  width: 11px;
  height: 13px;
  background-position: -265px top;
}

a.ddp-icon-more {
  position: relative;
  display: inline-block;
  padding: 8px 4px;
  cursor: pointer;
  font-size: 0;

  &:before {
    display: inline-block;
    content: '';
    width: 15px;
    height: 3px;
    background: url(/assets/bi/images/icon_more.png) no-repeat;
    transform: rotate(90deg);
  }

  &:hover:before {
    background-position: 0 -4px;
  }
}

em {
  &.ddp-icon-searchview2 {
    display: inline-block;
    content: '';
    width: 14px;
    height: 14px;
    background: url(/assets/bi/images/icon_searchview.png) no-repeat;
    vertical-align: middle;
    background-position: -15px 0;
  }

  &.ddp-icon-searchview {
    padding: 4px;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/icon_searchview.png) no-repeat;
      vertical-align: middle;
    }

    &:hover:before {
      background-position: -15px 0;
    }
  }

  &.ddp-icon-editdel,
  &.ddp-icon-editdel2 {
    display: inline-block;
    padding: 4px;
    cursor: pointer;
  }

  &.ddp-icon-editdel:before,
  &.ddp-icon-editdel2:before {
    display: inline-block;
    width: 11px;
    height: 12px;
    background: url(/assets/bi/images/icon_edit.png) no-repeat;
    background-position: left -50px;
    vertical-align: middle;
    content: '';
  }

  &.ddp-icon-editdel:hover:before {
    background-position: -12px -50px;
  }

  &.ddp-icon-editdel2 {
    &:before {
      background-position: -24px -50px;
    }

    &:hover:before {
      background-position: -36px -50px;
    }
  }

  &.ddp-icon-editsearch {
    padding: 4px;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      width: 11px;
      height: 11px;
      background: url('/assets/bi/images/icon_search.png') no-repeat;
      vertical-align: middle;
    }

    &:hover:before {
      background-position: -12px 0;
    }
  }

  &.ddp-icon-editadd {
    padding: 4px;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      width: 9px;
      height: 9px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      vertical-align: middle;
    }

    &:hover:before {
      background-position: -10px 0;
    }
  }

  &.ddp-icon-grid,
  &.ddp-icon-listgrid,
  &.ddp-icon-lineage {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_grid.png) no-repeat;
  }

  &.ddp-icon-listgrid {
    width: 15px;
    height: 11px;
    background-position: left -14px;
  }

  &.ddp-icon-lineage {
    width: 15px;
    height: 13px;
    background-position: left -26px;
  }

  &.ddp-icon-type-calen3,
  &.ddp-icon-type-text {
    display: inline-block;
    background: url(/assets/bi/images/icon_type.png) no-repeat;
  }

  &.ddp-icon-type-calen3 {
    width: 11px;
    height: 11px;
  }

  &.ddp-icon-type-text {
    width: 10px;
    height: 11px;
    background-position: left -12px;
  }
}

ul.ddp-list-popup li a em {
  &.ddp-icon-type-calen3,
  &.ddp-icon-type-text {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -6px;
  }
}

/**************************************************************
	value type
**************************************************************/

em {
  &.ddp-icon-type-calen,
  &.ddp-icon-type-ab,
  &.ddp-icon-type-local,
  &.ddp-icon-type-sharp,
  &.ddp-icon-type-account,
  &.ddp-icon-type-tf,
  &.ddp-icon-type-maplink,
  &.ddp-icon-type-float,
  &.ddp-icon-type-int,
  &.ddp-icon-type-latitude,
  &.ddp-icon-type-longitude,
  &.ddp-icon-type-array,
  &.ddp-icon-type-map,
  &.ddp-icon-type-phone,
  &.ddp-icon-type-email,
  &.ddp-icon-type-gender,
  &.ddp-icon-type-url,
  &.ddp-icon-type-zipcode,
  &.ddp-icon-type-private,
  &.ddp-icon-type-image,
  &.ddp-icon-type-binary,
  &.ddp-icon-type-spatial,
  &.ddp-icon-type-etc,
  &.ddp-icon-type-point,
  &.ddp-icon-type-line,
  &.ddp-icon-type-polygon,
  &.ddp-icon-type-expression {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 10px;
    width: 13px;
    height: 9px;
    margin-top: -5px;
    background: url(/assets/bi/images/icon_valuetype.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-type-local {
    width: 9px;
    height: 12px;
    margin-top: -6px;
    background-position: left -10px;
  }

  &.ddp-icon-type-sharp {
    width: 15px;
    height: 8px;
    margin-top: -4px;
    background-position: left -23px;
  }

  &.ddp-icon-type-account {
    width: 11px;
    height: 12px;
    margin-top: -6px;
    background-position: left -32px;
  }

  &.ddp-icon-type-maplink {
    width: 10px;
    height: 10px;
    margin-top: -5px;
    background-position: left -54px;
  }

  &.ddp-icon-type-tf {
    width: 14px;
    height: 9px;
    background-position: left -45px;
  }

  &.ddp-icon-type-float {
    width: 16px;
    height: 8px;
    background-position: left -96px;
  }

  &.ddp-icon-type-int {
    width: 8px;
    height: 8px;
    background-position: left -87px;
  }

  &.ddp-icon-type-calen {
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background-position: left -74px;
  }

  &.ddp-icon-type-latitude {
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background-position: left -119px;
  }

  &.ddp-icon-type-longitude {
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background-position: left -131px;
  }

  &.ddp-icon-type-array {
    width: 8px;
    height: 10px;
    margin-top: -5px;
    background-position: left -107px;
  }

  &.ddp-icon-type-map {
    width: 14px;
    height: 10px;
    margin-top: -5px;
    background-position: left -143px;
  }

  &.ddp-icon-type-phone {
    width: 13px;
    height: 13px;
    margin-top: -7px;
    background-position: left -154px;
  }

  &.ddp-icon-type-email {
    width: 12px;
    height: 12px;
    margin-top: -6px;
    background-position: left -168px;
  }

  &.ddp-icon-type-gender {
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background-position: left -181px;
  }

  &.ddp-icon-type-url {
    width: 15px;
    height: 9px;
    margin-top: -5px;
    background-position: left -193px;
  }

  &.ddp-icon-type-zipcode {
    width: 16px;
    height: 7px;
    margin-top: -4px;
    background-position: left -203px;
  }

  &.ddp-icon-type-private {
    width: 11px;
    height: 12px;
    margin-top: -6px;
    background-position: left -211px;
  }

  &.ddp-icon-type-image {
    width: 11px;
    height: 10px;
    background-position: left -224px;
  }

  &.ddp-icon-type-binary {
    width: 9px;
    height: 11px;
    background-position: left -235px;
  }

  &.ddp-icon-type-spatial {
    width: 11px;
    height: 12px;
    background-position: left -247px;
  }

  &.ddp-icon-type-etc {
    width: 14px;
    height: 7px;
    background-position: left -260px;
  }

  &.ddp-icon-type-point {
    width: 9px;
    height: 12px;
    background-position: left -268px;
  }

  &.ddp-icon-type-line {
    width: 10px;
    height: 12px;
    background-position: left -281px;
  }

  &.ddp-icon-type-polygon {
    width: 13px;
    height: 13px;
    background-position: left -294px;
  }

  &.ddp-icon-type-expression {
    width: 11px;
    height: 10px;
    background-position: left -308px;
  }

  &.ddp-icon-basic-calen,
  &.ddp-icon-basic-ab,
  &.ddp-icon-basic-local,
  &.ddp-icon-basic-sharp,
  &.ddp-icon-basic-account,
  &.ddp-icon-basic-tf,
  &.ddp-icon-basic-maplink,
  &.ddp-icon-basic-float,
  &.ddp-icon-basic-int,
  &.ddp-icon-basic-latitude,
  &.ddp-icon-basic-longitude,
  &.ddp-icon-basic-array,
  &.ddp-icon-basic-map {
    display: inline-block;
    background: url(/assets/bi/images/icon_valuetype.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-basic-ab {
    width: 13px;
    height: 9px;
    background-position: -42px top;
  }

  &.ddp-icon-basic-calen {
    width: 11px;
    height: 11px;
    background-position: -36px -74px;
  }

  &.ddp-icon-basic-local {
    width: 9px;
    height: 12px;
    background-position: -40px -10px;
  }

  &.ddp-icon-basic-sharp {
    width: 15px;
    height: 8px;
    background-position: -63px -23px;
  }

  &.ddp-icon-basic-account {
    width: 11px;
    height: 12px;
    background-position: -44px -32px;
  }

  &.ddp-icon-basic-maplink {
    width: 10px;
    height: 10px;
    background-position: -44px -54px;
  }

  &.ddp-icon-basic-tf {
    width: 14px;
    height: 9px;
    background-position: -59px -45px;
  }

  &.ddp-icon-basic-float {
    width: 16px;
    height: 8px;
    background-position: -67px -96px;
  }

  &.ddp-icon-basic-int {
    width: 8px;
    height: 8px;
    background-position: -35px -87px;
  }

  &.ddp-icon-basic-latitude {
    width: 11px;
    height: 11px;
    background-position: -48px -119px;
  }

  &.ddp-icon-basic-longitude {
    width: 11px;
    height: 11px;
    background-position: -48px -131px;
  }

  &.ddp-icon-basic-array {
    width: 8px;
    height: 10px;
    background-position: -36px -107px;
  }

  &.ddp-icon-basic-map {
    width: 14px;
    height: 10px;
    background-position: -60px -143px;
  }

  &.ddp-icon-dimension-ab,
  &.ddp-icon-dimension-local,
  &.ddp-icon-dimension-calen,
  &.ddp-icon-dimension-sharp,
  &.ddp-icon-dimension-float,
  &.ddp-icon-dimension-tf,
  &.ddp-icon-dimension-account,
  &.ddp-icon-dimension-maplink,
  &.ddp-icon-dimension-int,
  &.ddp-icon-dimension-latitude,
  &.ddp-icon-dimension-longitude,
  &.ddp-icon-dimension-array,
  &.ddp-icon-measure-ab,
  &.ddp-icon-measure-local,
  &.ddp-icon-measure-calen,
  &.ddp-icon-measure-sharp,
  &.ddp-icon-measure-float,
  &.ddp-icon-measure-tf,
  &.ddp-icon-measure-account,
  &.ddp-icon-measure-maplink,
  &.ddp-icon-measure-int,
  &.ddp-icon-measure-latitude,
  &.ddp-icon-measure-longitude,
  &.ddp-icon-measure-array {
    display: inline-block;
    background: url(/assets/bi/images/icon_valuetype.png) no-repeat;
    vertical-align: middle;
    font-size: 0;
  }

  &.ddp-icon-dimension-ab {
    width: 13px;
    height: 9px;
    background-position: -28px top;
  }

  &.ddp-icon-dimension-local {
    width: 9px;
    height: 11px;
    background-position: -20px -10px;
  }

  &.ddp-icon-dimension-calen {
    width: 11px;
    height: 11px;
    background-position: -24px -74px;
  }

  &.ddp-icon-dimension-sharp {
    width: 15px;
    height: 8px;
    background-position: -47px -23px;
  }

  &.ddp-icon-dimension-float {
    width: 16px;
    height: 8px;
    background-position: -33px -96px;
  }

  &.ddp-icon-dimension-tf {
    width: 14px;
    height: 8px;
    background-position: -30px -45px;
  }

  &.ddp-icon-dimension-account {
    width: 10px;
    height: 12px;
    background-position: -23px -32px;
  }

  &.ddp-icon-dimension-maplink {
    width: 10px;
    height: 10px;
    background-position: -22px -54px;
  }

  &.ddp-icon-dimension-int {
    width: 8px;
    height: 8px;
    background-position: -17px -87px;
  }

  &.ddp-icon-dimension-latitude {
    width: 11px;
    height: 11px;
    background-position: -24px -119px;
  }

  &.ddp-icon-dimension-longitude {
    width: 11px;
    height: 11px;
    background-position: -24px -131px;
  }

  &.ddp-icon-dimension-array {
    width: 8px;
    height: 12px;
    background-position: -18px -106px;
  }

  &.ddp-icon-dimension-ab.type-absolute,
  &.ddp-icon-measure-ab.type-absolute {
    margin: -5px 0 0 -7px;
  }

  &.ddp-icon-dimension-local.type-absolute,
  &.ddp-icon-measure-local.type-absolute {
    margin: -6px 0 0 -5px;
  }

  &.ddp-icon-dimension-calen.type-absolute,
  &.ddp-icon-measure-calen.type-absolute {
    margin: -6px 0 0 -6px;
  }

  &.ddp-icon-dimension-sharp.type-absolute,
  &.ddp-icon-measure-sharp.type-absolute,
  &.ddp-icon-dimension-float.type-absolute,
  &.ddp-icon-measure-float.type-absolute {
    margin: -4px 0 0 -8px;
  }

  &.ddp-icon-dimension-tf.type-absolute,
  &.ddp-icon-measure-tf.type-absolute {
    margin: -4px 0 0 -7px;
  }

  &.ddp-icon-dimension-account.type-absolute,
  &.ddp-icon-measure-account.type-absolute {
    margin: -6px 0 0 -5px;
  }

  &.ddp-icon-dimension-maplink.type-absolute,
  &.ddp-icon-measure-maplink.type-absolute {
    margin: -5px 0 0 -5px;
  }

  &.ddp-icon-dimension-int.type-absolute,
  &.ddp-icon-measure-int.type-absolute {
    margin: -4px 0 0 -4px;
  }

  &.ddp-icon-dimension-latitude.type-absolute,
  &.ddp-icon-measure-latitude.type-absolute,
  &.ddp-icon-dimension-longitude.type-absolute,
  &.ddp-icon-measure-longitude.type-absolute {
    margin: -6px 0 0 -6px;
  }

  &.ddp-icon-dimension-array.type-absolute,
  &.ddp-icon-measure-array.type-absolute {
    margin: -6px 0 0 -4px;
  }

  &.ddp-icon-measure-ab {
    width: 13px;
    height: 9px;
    background-position: -56px top;
  }

  &.ddp-icon-measure-local {
    width: 9px;
    height: 11px;
    background-position: -30px -10px;
  }

  &.ddp-icon-measure-calen {
    width: 11px;
    height: 11px;
    background-position: -48px -74px;
  }

  &.ddp-icon-measure-float {
    width: 16px;
    height: 8px;
    background-position: -50px -96px;
  }

  &.ddp-icon-measure-tf {
    width: 14px;
    height: 8px;
    background-position: -45px -45px;
  }

  &.ddp-icon-measure-sharp {
    width: 15px;
    height: 8px;
    background-position: -31px -23px;
  }

  &.ddp-icon-measure-account {
    width: 10px;
    height: 12px;
    background-position: -34px -32px;
  }

  &.ddp-icon-measure-maplink {
    width: 10px;
    height: 10px;
    background-position: -33px -54px;
  }

  &.ddp-icon-measure-int {
    width: 8px;
    height: 8px;
    background-position: -26px -87px;
  }

  &.ddp-icon-measure-latitude {
    width: 11px;
    height: 11px;
    background-position: -36px -119px;
  }

  &.ddp-icon-measure-longitude {
    width: 11px;
    height: 11px;
    background-position: -36px -131px;
  }

  &.ddp-icon-measure-array {
    width: 8px;
    height: 12px;
    background-position: -27px -106px;
  }
}

ul.ddp-list-filter li.ddp-selected a em {
  &.ddp-icon-dimension-ab,
  &.ddp-icon-measure-ab,
  &.ddp-icon-basic-ab {
    background-position-x: -14px;
  }

  &.ddp-icon-dimension-local,
  &.ddp-icon-measure-local,
  &.ddp-icon-basic-local {
    background-position-x: -10px;
  }

  &.ddp-icon-dimension-calen,
  &.ddp-icon-measure-calen,
  &.ddp-icon-basic-calen {
    background-position-x: -12px;
  }

  &.ddp-icon-dimension-sharp,
  &.ddp-icon-measure-sharp,
  &.ddp-icon-basic-sharp {
    background-position-x: -16px;
  }

  &.ddp-icon-dimension-float,
  &.ddp-icon-measure-float,
  &.ddp-icon-basic-float {
    background-position-x: -17px;
  }

  &.ddp-icon-dimension-tf,
  &.ddp-icon-measure-tf,
  &.ddp-icon-basic-tf {
    background-position-x: -15px;
  }

  &.ddp-icon-dimension-account,
  &.ddp-icon-measure-account,
  &.ddp-icon-basic-account,
  &.ddp-icon-dimension-maplink,
  &.ddp-icon-measure-maplink,
  &.ddp-icon-basic-maplink {
    background-position-x: -11px;
  }

  &.ddp-icon-dimension-int,
  &.ddp-icon-measure-int,
  &.ddp-icon-basic-int {
    background-position-x: -9px;
  }

  &.ddp-icon-dimension-latitude,
  &.ddp-icon-measure-latitude,
  &.ddp-icon-basic-latitude,
  &.ddp-icon-dimension-longitude,
  &.ddp-icon-measure-longitude,
  &.ddp-icon-basic-longitude {
    background-position-x: -12px;
  }

  &.ddp-icon-dimension-array,
  &.ddp-icon-measure-array,
  &.ddp-icon-basic-array {
    background-position-x: -9px;
  }
}

/* value type map */

em {
  &.ddp-icon-map-view {
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 0;
    background: url(/assets/bi/images/icon_valuetype_map.png) no-repeat;
  }

  &.ddp-icon-map-view-w {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_valuetype_map.png) no-repeat;
    vertical-align: top;
  }

  &.ddp-icon-polygon-w {
    width: 13px;
    height: 13px;
    background-position: -28px top;
  }

  &.ddp-icon-line-w {
    width: 10px;
    height: 12px;
    background-position: -22px -14px;
  }

  &.ddp-icon-point-w {
    width: 10px;
    height: 12px;
    background-position: -20px -27px;
  }

  &.ddp-icon-dimension-polygon,
  &.ddp-icon-dimension-line,
  &.ddp-icon-dimension-point,
  &.ddp-icon-measure-polygon,
  &.ddp-icon-measure-line,
  &.ddp-icon-measure-point {
    display: inline-block;
    background: url(/assets/bi/images/icon_valuetype_map.png) no-repeat;
  }

  &.ddp-icon-dimension-polygon {
    width: 13px;
    height: 13px;
  }

  &.ddp-icon-dimension-line {
    width: 10px;
    height: 12px;
    background-position: left -14px;
  }

  &.ddp-icon-dimension-point {
    width: 9px;
    height: 12px;
    background-position: left -27px;
  }

  &.ddp-icon-measure-polygon {
    width: 13px;
    height: 13px;
    background-position: -14px top;
  }

  &.ddp-icon-measure-line {
    width: 10px;
    height: 12px;
    background-position: -11px -14px;
  }

  &.ddp-icon-measure-point {
    width: 9px;
    height: 12px;
    background-position: -10px -27px;
  }

  &.ddp-icon-menu-chart,
  &.ddp-icon-menu-filter,
  &.ddp-icon-menu-layout,
  &.ddp-icon-menu-color,
  &.ddp-icon-menu-axis,
  &.ddp-icon-menu-num,
  &.ddp-icon-menu-presentation,
  &.ddp-icon-menu-tooltip,
  &.ddp-icon-menu-split,
  &.ddp-icon-menu-datasorce,
  &.ddp-icon-menu-text,
  &.ddp-icon-menu-general,
  &.ddp-icon-menu-axis-x,
  &.ddp-icon-menu-axis-y,
  &.ddp-icon-menu-legend,
  &.ddp-icon-menu-label,
  &.ddp-icon-menu-calculated,
  &.ddp-icon-menu-secondary,
  &.ddp-icon-menu-parameters,
  &.ddp-icon-menu-mapview1,
  &.ddp-icon-menu-mapview2,
  &.ddp-icon-menu-mapview3,
  &.ddp-icon-menu-analysis {
    display: inline-block;
    background: url(/assets/bi/images/icon_sidemenu.png) no-repeat;
  }

  &.ddp-icon-menu-calculated {
    width: 18px;
    height: 22px;
    margin: -9px 0 0 -11px;
    background-position: left -337px;
  }

  &.ddp-icon-menu-general {
    width: 22px;
    height: 19px;
    margin: -9px 0 0 -11px;
    background-position: left -297px;
  }

  &.ddp-icon-menu-axis-x {
    width: 21px;
    height: 21px;
    margin: -11px 0 0 -11px;
    background-position: left -253px;
  }

  &.ddp-icon-menu-axis-y {
    width: 21px;
    height: 21px;
    margin: -11px 0 0 -11px;
    background-position: left -231px;
  }

  &.ddp-icon-menu-legend {
    width: 22px;
    height: 19px;
    margin: -10px 0 0 -11px;
    background-position: left -317px;
  }

  &.ddp-icon-menu-label {
    width: 22px;
    height: 21px;
    margin: -11px 0 0 -11px;
    background-position: left -275px;
  }

  &.ddp-icon-menu-chart {
    width: 26px;
    height: 20px;
    margin: -10px 0 0 -13px;
  }

  &.ddp-icon-menu-filter {
    width: 18px;
    height: 15px;
    margin: -8px 0 0 -9px;
    background-position: left -21px;
  }

  &.ddp-icon-menu-layout {
    width: 26px;
    height: 20px;
    margin: -10px 0 0 -13px;
    background-position: left -37px;
  }

  &.ddp-icon-menu-color {
    width: 21px;
    height: 24px;
    margin: -12px 0 0 -11px;
    background-position: left -58px;
  }

  &.ddp-icon-menu-axis {
    width: 20px;
    height: 21px;
    margin: -12px 0 0 -10px;
    background-position: left -83px;
  }

  &.ddp-icon-menu-num {
    width: 26px;
    height: 13px;
    margin: -7px 0 0 -13px;
    background-position: left -105px;
  }

  &.ddp-icon-menu-presentation {
    width: 22px;
    height: 23px;
    margin: -11px 0 0 -12px;
    background-position: left -119px;
  }

  &.ddp-icon-menu-tooltip {
    width: 24px;
    height: 23px;
    margin: -12px 0 0 -12px;
    background-position: left -143px;
  }

  &.ddp-icon-menu-split {
    width: 23px;
    height: 22px;
    margin: -11px 0 0 -12px;
    background-position: left -167px;
  }

  &.ddp-icon-menu-datasorce {
    width: 21px;
    height: 22px;
    margin: -11px 0 0 -10px;
    background-position: left -190px;

    &.ddp-selected {
      background-position: -22px -190px;
    }
  }

  &.ddp-icon-menu-text {
    width: 15px;
    height: 17px;
    margin: -9px 0 0 -8px;
    background-position: left -213px;
  }

  &.ddp-icon-menu-secondary {
    width: 24px;
    height: 16px;
    margin: -8px 0 0 -12px;
    background-position: left -360px;
  }

  &.ddp-icon-menu-parameters {
    width: 20px;
    height: 18px;
    margin: -9px 0 0 -10px;
    background-position: left -377px;
  }

  &.ddp-icon-menu-mapview1 {
    width: 21px;
    height: 20px;
    margin: -10px 0 0 -10px;
    background-position: left -396px;
  }

  &.ddp-icon-menu-mapview2 {
    width: 21px;
    height: 20px;
    margin: -10px 0 0 -10px;
    background-position: left -417px;
  }

  &.ddp-icon-menu-mapview3 {
    width: 21px;
    height: 20px;
    margin: -10px 0 0 -10px;
    background-position: left -438px;
  }

  &.ddp-icon-menu-analysis {
    width: 22px;
    height: 16px;
    margin: -8px 0 0 -11px;
    background-position: left -459px;
  }

  &.ddp-icon-status-success,
  &.ddp-icon-status-error,
  &.ddp-icon-status-inprogress,
  &.ddp-icon-status-warning {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(/assets/bi/images/icon_snapshot_status.png) no-repeat;
  }

  &.ddp-icon-status-error {
    background-position: left -21px;
  }

  &.ddp-icon-status-inprogress {
    background-position: left -42px;
  }

  &.ddp-icon-status-warning {
    width: 20px;
    height: 18px;
    background-position: left -63px;
  }

  &.ddp-icon-status-inprogress:hover .ddp-ui-tooltip-info {
    display: block;
    left: 100%;
    top: -2px;
    margin-left: 5px;
  }
}

/**************************************************************
	대시보드, 차트 rnb 메뉴 아이콘
**************************************************************/

/*상태 아이콘*/

/**************************************************************
	svg 아이콘
**************************************************************/

.icon-db-hive-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #eaeaea;
  }

  .st1 {
    fill: #b5b5b5;
  }

  .st2 {
    fill: #f7f7f7;
  }

  .st3 {
    fill: #ffffff;
  }

  .st4 {
    stroke: #000000;
    stroke-width: 0.114;
  }
}

.icon-db-mysql-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #ececec;
    fill: #ececec;
  }
}

.icon-db-oracle-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #ececec;
  }
}

.icon-db-phoenix-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: url(#SVGID_1_);
  }

  .st1 {
    fill: url(#SVGID_2_);
  }

  .st2 {
    fill: url(#SVGID_3_);
  }

  .st3 {
    fill: url(#SVGID_4_);
  }
}

.icon-db-post-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #5a5a5a;
  }

  .st1 {
    fill: #ebebeb;
  }
}

.icon-db-presto-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #ebebeb;
  }

  .st1 {
    fill: #b5b5b5;
  }

  .st2 {
    fill: #000e0f;
  }
}

.icon-db-tibero-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #b8b8b8;
  }

  .st1 {
    fill: #ebebeb;
  }
}

.icon-db-mssql-w,
.icon-db-mssql,
.icon-db-mssql-b {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.icon-db-druid-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: none;
    stroke: #ececec;
    stroke-miterlimit: 10;
  }
}

.icon-db-custom-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #ececec;
  }

  .st1 {
    display: none;
  }

  .st2 {
    display: inline;
    fill: none;
    stroke: #ececec;
    stroke-miterlimit: 10;
  }
}

.icon-db {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #6e727b;
  }
}

.icon-db-hive {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #fdee21;
  }

  .st1 {
    fill: #c8c037;
  }

  .st2 {
    fill: #fcf6a0;
  }

  .st3 {
    fill: #ffffff;
  }

  .st4 {
    stroke: #000000;
    stroke-width: 0.114;
  }
}

.icon-db-mysql {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #2d7397;
  }
}

.icon-db-oracle {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #ea1b22;
  }
}

.icon-db-druid {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: none;
    stroke: #00b3c1;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
}

.icon-db-custom {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #a9b0c0;
  }
}

.icon-db-phoenix {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: url(#SVGID_1_);
  }

  .st1 {
    fill: url(#SVGID_2_);
  }

  .st2 {
    fill: url(#SVGID_3_);
  }

  .st3 {
    fill: url(#SVGID_4_);
  }
}

.icon-db-post,
.icon-db-post-b {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.icon-db-post {
  .st0 {
    fill: #336791;
  }

  .st1 {
    fill: #ffffff;
  }

  .st2 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.icon-db-presto {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #669cfe;
  }

  .st1 {
    fill: #3fe7fe;
  }

  .st2 {
    fill: #000e0f;
  }

  .st3 {
    fill: #6a9efe;
  }
}

.icon-db-tibero {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #01448d;
  }

  .st1 {
    fill: #ea1b22;
  }
}

.icon-db-rds {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #205b98;
  }

  .st1 {
    fill: #5294cf;
  }

  .st2 {
    fill: #2d72b8;
  }
}

.icon-db-rds-w {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #a5a5a5;
  }

  .st1 {
    fill: #ebebeb;
  }

  .st2 {
    fill: #bfbfbf;
  }
}

.icon-db-hdfs-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #d8d8d8;
  }

  .st1 {
    fill: #b5b5b5;
  }

  .st2 {
    fill: #ffffff;
  }

  .st3 {
    fill: #333333;
  }
}

.icon-db-hive-b {
  display: inline-block;
  width: 24px;
  height: 22px;

  .st0 {
    fill: #eaeaea;
  }

  .st1 {
    fill: #b5b5b5;
  }

  .st2 {
    fill: #f7f7f7;
  }

  .st3 {
    fill: #ffffff;
  }

  .st4 {
    stroke: #000000;
    stroke-width: 0.114;
  }
}

.icon-db-local-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #e7eaeb;
  }

  .st1 {
    fill: none;
    stroke: #6e727b;
  }

  .st2 {
    fill: #6e727b;
  }
}

.icon-db-mysql-b,
.icon-db-oracle-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #6e727b;
  }
}

.icon-db-presto-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #90969f;
  }

  .st1 {
    fill: #4b515b;
  }
}

.icon-db-staging-b,
.icon-file-json-b,
.icon-file-txt-b,
.icon-file-xml-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: none;
  }

  .st1 {
    fill: #6e727b;
  }
}

.icon-file-xls {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #3f7651;
  }
}

.icon-file-xlsx {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #3f7651;
  }

  .st1 {
    fill: #3f7651;
    fill-opacity: 0;
  }
}

.icon-file-dataset,
.icon-file-dataset-another {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.icon-file-b {
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: #6e727b;
  }
}

.icon-file-csv-b {
  display: inline-block;
  width: 24px;
  height: 24px;
  enable-background: new 0 0 24 24;

  .st0 {
    fill: none;
  }

  .st1 {
    fill: #6e727b;
  }
}

.icon-file-csv-big {
  display: inline-block;
  width: 24px;
  height: 24px;
  enable-background: new 0 0 24 24;

  .st0 {
    fill: none;
  }

  .st1 {
    fill: #4a515b;
  }
}

.icon-dataset-another-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    display: none;
  }

  .st1 {
    display: inline;
    fill: #ffffff;
  }

  .st2 {
    display: inline;
    fill: #b6b9c1;
  }

  .st3 {
    fill: #ffffff;
  }

  .st4 {
    fill: $primary;
  }

  .st5 {
    fill: #4a515c;
  }
}

.icon-dataset-another {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: none;
  }

  .st2 {
    fill: #4a515c;
  }
}

.icon-dataset-wrangled-delete {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: none;
  }

  .st2 {
    fill: #e7e7ea;
  }
}

.icon-dataset-wrangled-fous {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #4a515c;
  }
}

.icon-dataset-wangled {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: none;
  }

  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #4a515c;
  }
}

.icon-chart-custom-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #a9b0c0;
  }
}

.icon-chart-custom {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #a9b0c0;
  }
}

.icon-chart-druid-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #00b3c1;
  }
}

.icon-chart-druid {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #00b3c1;
  }
}

.icon-chart-db-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    display: none;
  }

  .st1 {
    display: inline;
    fill: #ffffff;
  }

  .st2 {
    display: inline;
    fill: #b6b9c1;
  }

  .st3 {
    fill: #ffffff;
  }

  .st4 {
    fill: $primary;
  }

  .st5 {
    fill: #4b515a;
  }
}

.icon-chart-db {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #4b515a;
  }
}

.icon-chart-hive-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #fdee21;
  }

  .st3 {
    fill: #c8c037;
  }

  .st4 {
    fill: #fcf6a0;
  }

  .st5 {
    stroke: #000000;
    stroke-width: 0.114;
  }
}

.icon-chart-hive {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #fdee21;
  }

  .st3 {
    fill: #c8c037;
  }

  .st4 {
    fill: #fcf6a0;
  }

  .st5 {
    stroke: #000000;
    stroke-width: 0.114;
  }
}

.icon-chart-mysql-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #2d7397;
  }
}

.icon-chart-mysql {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #2d7397;
  }
}

.icon-chart-oracle-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #ea1b22;
  }
}

.icon-chart-oracle {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #ea1b22;
  }
}

.icon-chart-phoenix-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: url(#SVGID_1_);
  }

  .st3 {
    fill: url(#SVGID_2_);
  }

  .st4 {
    fill: url(#SVGID_3_);
  }

  .st5 {
    fill: url(#SVGID_4_);
  }
}

.icon-chart-phoenix {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: url(#SVGID_1_);
  }

  .st3 {
    fill: url(#SVGID_2_);
  }

  .st4 {
    fill: url(#SVGID_3_);
  }

  .st5 {
    fill: url(#SVGID_4_);
  }
}

.icon-chart-post-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #336791;
  }

  .st3 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.icon-chart-post {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #336791;
  }

  .st3 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.icon-chart-presto-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #669cfe;
  }

  .st3 {
    fill: #3fe7fe;
  }

  .st4 {
    fill: #000e0f;
  }

  .st5 {
    fill: #6a9efe;
  }
}

.icon-chart-presto {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #669cfe;
  }

  .st3 {
    fill: #3fe7fe;
  }

  .st4 {
    fill: #000e0f;
  }

  .st5 {
    fill: #6a9efe;
  }
}

.icon-chart-rds-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #205b98;
  }

  .st3 {
    fill: #5294cf;
  }

  .st4 {
    fill: #2d72b8;
  }
}

.icon-chart-rds {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #205b98;
  }

  .st3 {
    fill: #5294cf;
  }

  .st4 {
    fill: #2d72b8;
  }
}

.icon-chart-tibero-focus {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: $primary;
  }

  .st2 {
    fill: #01448d;
  }

  .st3 {
    fill: #ea1b22;
  }
}

.icon-chart-tibero {
  display: inline-block;
  width: 56px;
  height: 56px;

  .st0 {
    fill: #ffffff;
  }

  .st1 {
    fill: #b6b9c1;
  }

  .st2 {
    fill: #01448d;
  }

  .st3 {
    fill: #ea1b22;
  }
}

.icon-chart-file {
  display: inline-block;
  width: 56px;
  height: 56px;

  &.type-csv-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-csv {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-file-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: #4b515a;
    }
  }

  &.type-file {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: #4b515a;
    }
  }

  &.type-json-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-json {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-txt-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-txt {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-xls-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: #3f7651;
    }
  }

  &.type-xls {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: #3f7651;
    }
  }

  &.type-xlsx-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: #3f7651;
    }
  }

  &.type-xlsx {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: #3f7651;
    }
  }

  &.type-xml-focus {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: $primary;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }

  &.type-xml {
    .st0 {
      fill: #ffffff;
    }

    .st1 {
      fill: #b6b9c1;
    }

    .st2 {
      fill: none;
    }

    .st3 {
      fill: #4b515a;
    }
  }
}
