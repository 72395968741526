@charset "utf-8";

/**************************************************************
	Table 기본
**************************************************************/

table.ddp-table-form {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
  border-bottom: 1px solid #e7e7ea;
  text-align: left;

  thead tr th {
    position: relative;
    padding: 7px 10px;
    color: #4b515b;
    font-size: 13px;
    font-weight: normal;
    text-align: left;

    &:last-of-type {
      border-right: none;
    }

    .ddp-wrap-type-filter {
      position: absolute;
      top: 50%;
      right: 6px;
      margin-top: -9px;

      a.ddp-icon-type-filter {
        display: inline-block;
        width: 17px;
        height: 17px;

        &:after {
          display: inline-block;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 9px;
          height: 10px;
          margin: -5px 0 0 -5px;
          background: url(/assets/bi/images/icon_filter.png) no-repeat;
        }

        &:hover {
          background-color: #f0f0f2;
        }
      }

      &.ddp-selected a.ddp-icon-type-filter {
        background-color: #f0f0f2;
      }

      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        left: -21px;
        top: 100%;
      }

      &.ddp-selected .ddp-wrap-popup2 {
        display: block;
      }
    }
  }

  tbody tr td {
    position: relative;
    padding: 5px 10px;
    border-right: 1px solid #eaeaed;
    color: #4b525b;
    font-size: 12px;

    .ddp-txt-highlight {
      background-color: rgba(210, 204, 255, 0.4);
      color: $primary;
      font-weight: bold;
    }
  }

  &.type-line-none {
    thead tr th {
      border-bottom: 1px solid #d0d1d8;
      color: #90969f;
      font-size: 12px;
    }

    tbody tr td {
      border-right: none;
    }
  }

  tbody tr {
    &:nth-child(odd) td {
      background-color: #fafafa;
    }

    td.ddp-inherit {
      overflow: inherit;
    }

    &.ddp-disabled {
      td:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 3;
        cursor: no-drop;
      }

      &:hover td .ddp-btn-control {
        display: none;
      }
    }

    td {
      .ddp-type-txt {
        position: relative;
        display: inline-block;
        max-width: 100%;
        box-sizing: border-box;

        &.ddp-tag {
          padding-right: 75px;
          line-height: 1em;

          .ddp-tag-annoymous {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -9px;
            padding: 1px 2px;
            color: $primary;
            font-size: 11px;
            border: 1px solid $primary;
            background: #eff0f7;
            border-radius: 2px;
          }
        }

        &.ddp-info {
          padding-right: 18px;

          .ddp-icon-error2 {
            position: relative;
            top: 0;
          }

          .ddp-ui-info {
            position: absolute;
            top: 3px;
            right: 0;
          }

          .ddp-status-rejected + .ddp-ui-info {
            top: 0;
          }

          .ddp-ui-info {
            .ddp-box-layout4 {
              display: none;
              position: absolute;
              left: -30px;
              top: 100%;
              margin-top: 6px;
              word-break: break-all;
            }

            &:hover .ddp-box-layout4 {
              display: block;
              white-space: normal;
            }
          }
        }
      }

      .ddp-wrap-long {
        display: inline-block;
        position: relative;
        padding-right: 41px;
        max-width: 100%;
        box-sizing: border-box;

        em.ddp-icon-new {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -7px;
        }
      }

      .ddp-txt-long {
        em.ddp-icon-new {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -7px;
        }

        &.ddp-new {
          padding-right: 40px;
        }

        &.ddp-info {
          display: inline-block;
          padding-right: 13px;
        }

        .ddp-data-txt {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .ddp-icon-global {
          display: none;
          position: absolute;
          top: 2px;
          right: 0;
          width: 53px;
          padding: 2px 2px 3px 2px;
          text-align: center;
          border: 1px solid $primary;
          border-radius: 2px;
          background: #eff0f7;
          box-sizing: border-box;

          &:before {
            display: inline-block;
            content: '전체공개';
            color: $primary;
            font-size: 11px;
            line-height: 11px;
          }
        }
      }

      .ddp-txt-long2 {
        display: inline-block;
        position: relative;
        max-width: 100%;
        line-height: 21px;
        height: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        box-sizing: border-box;
      }

      .ddp-txt-long {
        display: inline-block;
        position: relative;
        max-width: 100%;
        line-height: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        box-sizing: border-box;
        vertical-align: top;

        &.ddp-global {
          padding-right: 66px;

          &.ddp-new {
            padding-right: 100px;

            em.ddp-tag-global {
              right: 40px;
            }
          }

          &.ddp-en-connection {
            padding-right: 100px;

            .ddp-tag-global {
              width: auto;
            }
          }
        }

        em.ddp-tag-global {
          position: absolute;
          top: 0;
          right: 0;
          height: 20px;
          width: 60px;
          padding: 2px 2px 3px 2px;
          color: $primary;
          font-size: 11px;
          text-align: center;
          border: 1px solid $primary;
          border-radius: 2px;
          background: #eff0f7;
          box-sizing: border-box;
        }

        &.ddp-global .ddp-icon-global {
          display: block;
        }

        .ddp-tag-global.type-tag {
          float: right;
          position: relative;
          margin-top: -2px;
          right: 0;
          margin-left: 5px;
          width: auto;
          min-width: 60px;
        }

        .ddp-data-long {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.ddp-type {
          overflow: initial;
        }
      }
    }
  }

  tr {
    td {
      .ddp-wrap-long .ddp-box-tag {
        position: relative;
        top: 3px;
        margin-right: 13px;
      }

      .ddp-txt-colortype .ddp-txt-search {
        font-size: 13px;
        color: #666db7;
      }

      .ddp-txt-search {
        font-size: 14px;
        color: #666db7;

        &.type-search {
          padding: 2px 3px 1px 3px;
          font-size: 13px;
          color: $primary;
          font-weight: bold;
          background-color: rgba(210, 204, 255, 0.4);
        }
      }

      &.ddp-data-none {
        color: #b7b9c3;
        text-align: center;
      }

      &.ddp-nodata {
        color: #b7bac1;
        font-size: 13px;
      }
    }

    th .ddp-wrap-hover-info {
      display: inline-block;
      margin-left: 3px;
      position: Relative;
      cursor: pointer;

      em.ddp-icon-info3 {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: left -12px;
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        bottom: 100%;
        right: 100%;
        margin: 0 -20px 4px 0;
      }

      &:hover .ddp-box-layout4 {
        display: block;
        white-space: normal;
        z-index: 20;
      }
    }
  }

  tbody tr {
    td {
      .ddp-ui-flex {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: Hidden;

        .ddp-icon-info3 {
          display: inline-block;
          float: right;
          margin-left: 3px;
          position: relative;
          top: 2px;
          width: 11px;
          height: 11px;
          background: url(/assets/bi/images/icon_info3.png) no-repeat;
        }
      }

      span.ddp-txt-colortype {
        color: #b7b9c2;
      }

      em.ddp-icon-by {
        margin: 0 3px;
        color: #b7bac1;
        font-size: 13px;
      }

      span.ddp-data-num {
        display: inline-block;
        margin-right: 17px;
        color: #4c515a;
        font-size: 13px;
      }

      &.ddp-data-last {
        padding-right: 36px;
      }

      a.ddp-btn-more {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 6px;
        width: 12px;
        height: 17px;
        margin-top: -9px;

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 9px;
          margin: -5px 0 0 -1px;
          background: url(/assets/bi/images/icon_more2.png) no-repeat;
        }

        &:hover,
        &.ddp-selected {
          background-color: #f0f0f2;
        }
      }

      span.ddp-data-delete {
        color: #b7b9c2;
        font-size: 13px;
      }

      .ddp-icon-new {
        margin-left: 5px;
      }

      .ddp-link-delete {
        color: #d0d1d8;

        &:hover {
          color: #90969f;
        }

        em.ddp-icon-delete {
          display: inline-block;
          width: 11px;
          height: 12px;
          background: url(/assets/bi/images/icon_edit2.png) no-repeat;
          background-position: -12px -21px;
        }

        &:hover em.ddp-icon-delete {
          background-position: -24px -21px;
        }
      }

      .ddp-ui-disabled {
        display: none;
      }
    }

    &.type-disabled {
      td:last-of-type,
      &.ddp-selected td:last-of-type {
        padding-right: 29px;
      }

      td .ddp-ui-disabled {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 9px;
        margin-top: -8px;

        .ddp-icon-disabled {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(/assets/bi/images/icon_disabled.png) no-repeat;
          content: '';
        }

        .ddp-ui-tooltip-info {
          right: -7px;

          .ddp-icon-view-top {
            right: 12px;
          }
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }
      }
    }
  }
}

.ddp-ui-grid .ddp-ui-empty {
  padding: 20px;
  color: #b7b9c3;
  font-size: 12px;
}

.ddp-ui-gridbody {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.ddp-box-viewtable {
  .ddp-ui-gridhead {
    height: 33px;
    border-top: none;

    table {
      padding-right: 18px;
      box-sizing: border-box;
    }

    tr th {
      height: 33px;
      box-sizing: border-box;
    }
  }

  .ddp-ui-gridbody {
    top: 34px;
  }
}

.ddp-ui-gridhead2 {
  padding-right: 18px;
  background-color: #f6f6f7;
}

.ddp-ui-gridbody2 {
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.ddp-box-viewtable {
  position: relative;
  min-height: 289px;
  height: 100%;
  background-color: #fff;

  &.type-height-none {
    min-height: auto;

    table.ddp-table-form {
      border-bottom: none;
    }
  }
}

.ddp-wrap-viewtable {
  .ddp-box-add-link {
    margin-top: -1px;
    padding: 13px 0;
    border: 1px solid #b7b9c2;
  }

  &.ddp-type {
    padding-bottom: 43px;
  }
}

.ddp-box-viewtable.ddp-selected .ddp-ui-gridbody {
  bottom: 45px;
}

/**************************************************************
	Table form cursor, inherit
**************************************************************/

table {
  &.ddp-table-form.ddp-cursor tbody tr {
    td {
      cursor: pointer;
    }

    &:hover > td {
      background-color: #f1f1f3;
    }

    td .ddp-table-code tr:hover td {
      background: none;
    }

    &.ddp-selected > td {
      border-top: 1px solid #9ca2cc;
      border-bottom: 1px solid #9ca2cc;
      background-color: #f2f1f8;

      &:first-of-type {
        border-left: 1px solid #9ca2cc;
      }

      &:last-of-type {
        border-right: 1px solid #9ca2cc;
      }
    }
  }

  &.ddp-inherit tbody tr td {
    position: relative;
    overflow: inherit;
  }

  &.ddp-table-form.ddp-inherit tbody tr td {
    overflow: inherit;
    white-space: normal;
  }

  &.ddp-table-form2 {
    table-layout: fixed;
    width: 100%;
    box-sizing: border-box;

    thead tr th {
      padding: 5px 29px;
      font-size: 12px;
      color: #90969f;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }

    tbody tr td {
      position: relative;
      padding: 7px 29px;
      color: #4b515b;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      box-sizing: border-box;

      .ddp-ui-selected-option {
        display: inline-block;
      }
    }

    &.ddp-long tbody tr td {
      text-overflow: inherit;
      white-space: inherit;
    }

    tbody tr td {
      .ddp-data-note {
        color: #b7bac1;
        font-size: 13px;
      }

      &.ddp-table-icons {
        padding: 0;
        text-align: center;
      }
    }

    thead tr th.ddp-table-icons {
      padding: 0;
      text-align: center;
    }
  }

  &.ddp-table-type2 {
    thead tr th {
      position: relative;
      padding: 7px 10px;
      color: #90969f;
      font-size: 12px;
      border-right: 1px solid #eaeaed;

      &:last-of-type {
        border-right: 1px solid transparent;
      }

      .ddp-th-long {
        display: inline-block;
        position: relative;
        max-width: 100%;
        box-sizing: border-box;

        .ddp-th-txt {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 19px;
        }
      }

      &.ddp-border-right {
        border-right: 1px solid #d0d1d8;
      }
    }

    tbody tr td.ddp-border-right {
      border-right: 1px solid #d0d1d8;
    }

    thead.ddp-border-bottom tr th {
      border-bottom: 1px solid #d0d1d8;
    }

    tbody tr {
      > td {
        position: relative;
        padding: 13px 10px 14px 20px;
        font-size: 13px;
        border: none;

        &.ddp-disabled {
          cursor: no-drop !important;

          &:hover {
            background: none;
          }

          &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 15;
            content: '';
            cursor: no-drop;
          }

          em.ddp-bg-select {
            display: none !important;
          }
        }
      }

      &.ddp-selected > td {
        padding: 12px 10px 13px 10px;

        &:first-of-type {
          padding-left: 9px;
        }

        &:last-of-type {
          padding-right: 9px;
        }
      }

      td {
        .ddp-ui-checkbox {
          position: relative;
          display: inline-block;
          width: 12px;
          height: 12px;
          vertical-align: middle;
        }

        .ddp-input-form {
          cursor: pointer;

          &:hover:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #f1f1f3;
            content: '';
          }
        }

        &.ddp-selected .ddp-input-form:hover:before {
          display: none;
        }

        &.ddp-disabled > div {
          opacity: 0.3;

          &.ddp-ui-info {
            opacity: 1;
            cursor: pointer;
            z-index: 20;
          }
        }

        &.ddp-input-form {
          cursor: pointer;

          &:hover {
            background-color: #f1f1f3 !important;
          }

          input.ddp-txt-input {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            font-size: 13px;
            box-sizing: border-box;
            z-index: 10;
            cursor: pointer;
          }
        }

        .ddp-input-form.ddp-edit {
          input.ddp-txt-input {
            padding-right: 34px;
          }

          .ddp-icon-edit {
            display: none;
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -6px;
            width: 12px;
            height: 12px;
            background: url(/assets/bi/images/icon_edit.png) no-repeat;
          }

          &:hover .ddp-icon-edit {
            display: block;
          }
        }
      }
    }
  }

  &.ddp-table-form {
    &.ddp-table-type3,
    &.ddp-table-line {
      border-bottom: none;
    }
  }

  &.ddp-table-type3 {
    thead tr th {
      color: #515c66;
      font-size: 12px;
      font-weight: normal;
      border-top: 1px solid #d2d3d9;
      border-bottom: 1px solid #d2d3d9;
      background-color: #f6f9fe;
    }

    &.type-table thead tr th {
      color: #90969f;
    }

    thead tr th {
      &:first-of-type {
        padding-left: 20px;
        border-left: 1px solid #d2d3d9;
      }

      &:last-of-type {
        border-right: 1px solid #d2d3d9;
      }

      .ddp-txt-colortype {
        color: #b7b9c2;
      }

      &.ddp-border-right {
        border-right: 1px solid #d0d1d8;
      }
    }

    &.ddp-cursor-none tbody tr td {
      cursor: default;
    }

    tbody tr {
      td {
        position: relative;
        padding: 10px 10px 11px 10px;
        font-size: 13px;
        border: none;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
        cursor: pointer;
        cursor: pointer;
        overflow: inherit;
        word-break: break-all;
        white-space: normal;

        &.ddp-txt-center:first-of-type {
          padding-left: 10px;
        }

        &:first-of-type {
          padding-left: 20px;
          border-left: 1px solid #ddd;
        }

        &:last-of-type {
          border-right: 1px solid #ddd;
        }
      }

      &:nth-child(odd) td {
        background: #fff;
      }

      &:hover td {
        background-color: #f6f9fe;
        cursor: pointer;
      }
    }

    &.ddp-cursor-none tbody tr:hover td {
      background: #fff;
      cursor: default;
    }

    tbody tr {
      td .ddp-btn-control {
        display: none;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -11px;
      }

      &:hover td .ddp-btn-control {
        display: block;
      }

      td {
        &.ddp-hover-tooltip {
          display: table-cell;
          overflow: inherit;
        }

        .ddp-link {
          text-decoration: underline;
          color: #4c92e0;
        }

        &.ddp-border-right {
          border-right: 1px solid #d0d1d8;
        }

        [class*='ddp-icon-status-'].ddp-hover-tooltip {
          margin-right: 4px;

          .ddp-ui-tooltip-info {
            top: auto;
            bottom: 100%;
            left: -10px;
            margin-bottom: 5px;

            .ddp-icon-view-down {
              left: 19px;
              right: auto;
              margin: 0;
            }
          }
        }

        .ddp-txt-status {
          font-size: 12px;
          font-weight: bold;

          &.type-disabled {
            color: #dc494f;
          }

          &.type-partially {
            color: #ffba00;
          }

          &.type-fully {
            color: #10bf83;
          }

          &.type-actively {
            color: #3f72c1;
          }
        }
      }
    }
  }

  &.ddp-table-select tbody tr {
    td {
      position: relative;
      cursor: pointer;
    }

    &:hover td {
      background-color: #f6f9fe;
      cursor: pointer;
    }

    &.ddp-selected td {
      background-color: #f2f1f8;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;

      &:first-of-type {
        border-left: 1px solid $primary;
      }

      &:last-of-type {
        border-right: 1px solid $primary;
      }
    }

    td {
      .ddp-box-tag {
        position: relative;
        top: 2px;
      }

      .ddp-ui-checkbox,
      .ddp-ui-radio {
        font-size: 0;
        vertical-align: middle;
      }
    }
  }

  &.ddp-table-type2.ddp-table-select tbody tr.ddp-selected > td {
    padding: 12px 10px 13px 10px;

    &:first-of-type {
      padding-left: 9px;
    }
  }

  &.ddp-table-select {
    tr td:last-of-type {
      padding-right: 35px;
    }

    tbody tr.ddp-selected td {
      &:first-child:before {
        border-left: 1px solid $primary;
      }

      &:last-child:before {
        border-right: 1px solid $primary;
      }

      .ddp-box-tag {
        position: relative;
        top: 2px;
      }
    }
  }
}

/**************************************************************
	Table form 2
**************************************************************/

/**************************************************************
	Table type 2
**************************************************************/

/**************************************************************
	Table3
**************************************************************/

/**************************************************************
	Table select
**************************************************************/

/*table.ddp-table-select tbody tr.ddp-selected td:before {position:absolute; top:0; left:0; right:0; bottom:0; border:1px solid Red; border-left:none; border-right:none; content:'';}*/

/**************************************************************
	Table listview
**************************************************************/

.ddp-wrap-table-list {
  float: left;
  width: 100%;
  padding: 10px 53px 10px 83px;
  box-sizing: border-box;
}

table.ddp-table-listview {
  width: 100%;
  table-layout: fixed;

  tbody tr {
    td {
      padding: 3px 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      span.ddp-sub-name {
        color: #4b525b;
        font-size: 13px;
        font-weight: 300;
      }
    }

    &:nth-child(odd) td {
      background-color: #fafafa;
    }

    td {
      span.ddp-box-tag-value {
        display: inline-block;
        padding: 2px 3px;
        border-radius: 2px;
        font-size: 12px;

        &.ddp-dimension {
          width: 70px;
          border: 1px solid #439fe5;
          text-align: center;
          color: #439fe5;
          box-sizing: border-box;
        }

        &.ddp-measure {
          width: 70px;
          border: 1px solid #5fd7a5;
          text-align: center;
          color: #5fd7a5;
          box-sizing: border-box;
        }
      }

      em[class*='ddp-icon-type-'] {
        position: relative;
        left: 0;
        top: 0;
        margin: 0;
      }
    }
  }
}

/**************************************************************
	매니지먼트 모니터링 리스트
**************************************************************/

.ddp-fix-size {
  position: relative;
  height: 246px;
  box-sizing: border-box;

  .ddp-nodata {
    display: table;
    width: 100%;
    height: 215px;
    padding: 90px 0 0 0;
    text-align: center;
    background-color: #fff;
    font-size: 18px;
    color: #d0d1db;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
  }
}

/**************************************************************
	매니지먼트 메타데이터 상세
**************************************************************/

.ddp-ui-preview-contents table.ddp-table-form.ddp-table-details tbody tr:nth-child(odd) td.ddp-disabled:hover {
  background-color: #fafafa !important;
}

table {
  &.ddp-table-type2 thead tr th .ddp-th-long .ddp-th-icons {
    float: right;

    .ddp-wrap-hover-info {
      float: left;
      padding: 5px 0 0 0;
      font-size: 0;
    }

    [class*='ddp-icon-array'] {
      top: 0;
    }
  }

  tbody tr {
    td .ddp-txt-long:hover + .ddp-ui-tooltip-info {
      display: block;
      top: 100%;
      left: 0;
      min-width: 50px;
      max-width: 100%;
      white-space: normal;
      box-sizing: border-box;

      .ddp-icon-view-top {
        left: 40px;
      }
    }

    > td .ddp-hover-tooltip:hover + .ddp-ui-tooltip-info {
      display: block;
      top: 100%;
      left: -10px;
      min-width: 50px;
      max-width: 100%;
      white-space: normal;
      box-sizing: border-box;

      em.ddp-icon-view-top {
        left: 40px;
      }

      &.ddp-top {
        bottom: 100%;
        top: inherit;
      }
    }
  }

  &.ddp-table-type3 tbody tr td.ddp-hover-tooltip .ddp-ui-tooltip-info {
    top: 30px;
    left: 10px;
    right: inherit;
    max-width: 90%;
    white-space: normal;

    .ddp-icon-view-top {
      left: 20px;
      right: inherit;
    }

    .ddp-scroll {
      max-height: 150px;
      overflow: auto;
    }
  }
}

/**************************************************************
	Table tooltip
**************************************************************/

/**************************************************************
	Table 오름차순
**************************************************************/

em {
  &.ddp-icon-array-default2,
  &.ddp-icon-array-asc2,
  &.ddp-icon-array-des2 {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 19px;
    height: 19px;
    cursor: pointer;
    vertical-align: middle;
  }

  &.ddp-icon-array-default2:before,
  &.ddp-icon-array-asc2:before,
  &.ddp-icon-array-des2:before {
    display: inline-block;
    content: '';
    position: absolute;
    width: 7px;
    height: 9px;
    top: 5px;
    left: 50%;
    margin-left: -4px;
    background: url(/assets/bi/images/icon_array.png) no-repeat;
    cursor: pointer;
  }

  &.ddp-icon-array-asc2:before {
    background-position: -16px top;
  }

  &.ddp-icon-array-des2:before {
    background-position: -8px top;
  }
}

/**************************************************************
	더보기 버튼
**************************************************************/

.ddp-box-add-link {
  padding: 10px 0;
  margin-top: 7px;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: center;
  cursor: pointer;

  span.ddp-link-type {
    font-size: 13px;
    color: #90969f;
  }

  &:hover span.ddp-link-type {
    color: #4b515b;
    text-decoration: underline;
  }

  span.ddp-link-type em.ddp-icon-view {
    display: inline-block;
    width: 7px;
    height: 4px;
    margin-left: 6px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: -35px -7px;
    vertical-align: middle;
  }

  &:hover span.ddp-link-type em.ddp-icon-view {
    background-position: left -7px;
  }

  &.ddp-shadow {
    border: none;
    padding: 14px 0;
    margin-top: 10px;
    -webkit-box-shadow: 0px 2px 2px 1px rgba(231, 231, 234, 1);
    -moz-box-shadow: 0px 2px 2px 1px rgba(231, 231, 234, 1);
    box-shadow: 0px 2px 2px 1px rgba(231, 231, 234, 1);
  }
}

.ddp-box-add-link2 {
  position: relative;
  padding: 16px 0;
  margin-top: -1px;
  border-top: 1px solid #b7b9c2;
  border-bottom: 1px solid #b7b9c2;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  span.ddp-link-type {
    font-size: 13px;
    color: #90969f;
  }

  &:hover span.ddp-link-type {
    color: #4b515b;
    text-decoration: underline;
  }

  span.ddp-link-type em.ddp-icon-view {
    display: inline-block;
    width: 7px;
    height: 4px;
    margin-left: 6px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: -35px -7px;
    vertical-align: middle;
  }

  &:hover span.ddp-link-type em.ddp-icon-view {
    background-position: left -7px;
  }
}

.ddp-box-add-link3 {
  position: relative;
  padding: 13px 0;
  margin-top: -1px;
  border: 1px solid #b7b9c1;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  span.ddp-link-type {
    font-size: 13px;
    color: #90969f;
  }

  &:hover span.ddp-link-type {
    color: #4b515b;
    text-decoration: underline;
  }

  span.ddp-link-type em.ddp-icon-view {
    display: inline-block;
    width: 7px;
    height: 4px;
    margin-left: 6px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: -35px -7px;
    vertical-align: middle;
  }

  &:hover span.ddp-link-type em.ddp-icon-view {
    background-position: left -7px;
  }
}

[class*='ddp-box-add-link'].ddp-disabled {
  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    cursor: no-drop;
  }

  &:hover span.ddp-link-type {
    text-decoration: none;
    color: #90969f;

    em.ddp-icon-view {
      background-position: -35px -7px;
    }
  }
}

.ddp-ui-moretype {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.ddp-btn-moretype {
  display: inline-block;
  padding: 3px 9px;
  color: #90969f;
  font-size: 12px;
  text-align: center;
  background-color: #f6f6f7;
  border-radius: 2px 2px 0 0;

  &:hover {
    color: #4b515b;
  }

  &:after {
    display: inline-block;
    width: 8px;
    height: 4px;
    margin-left: 4px;
    background: url('/assets/bi/images/icon_select.png') no-repeat;
    vertical-align: middle;
    content: '';
  }

  &:hover:after {
    background-position: -16px top;
  }
}

/**************************************************************
	table (ddp-table-detail4)
**************************************************************/

table {
  &.ddp-table-detail4 {
    table-layout: fixed;
    border: 1px solid #cdd0e2;

    tr {
      th {
        padding: 7px 15px 7px 15px;
        text-align: left;
        color: #464c7d;
        font-weight: normal;
        font-size: 12px;
        border-bottom: 1px solid #e5e6f0;
        background-color: rgba(236, 238, 246, 0.5);
      }

      &:first-of-type td {
        border-top: none;
      }

      td {
        padding: 9px 15px 9px 15px;
        color: #4b515b;
        font-size: 13px;
        border-top: 1px solid #e5e6f0;

        .ddp-txt-long {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.ddp-type-button {
            position: relative;
            margin: -5px 0;

            .ddp-txt-name {
              display: block;
              position: Relative;
              margin-top: 5px;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .ddp-btn-selection {
              margin-left: 8px;
            }
          }

          .ddp-icon-control-fav {
            position: relative;
            top: -2px;
          }

          .ddp-txt-link {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            &.type-default {
              cursor: default;

              &:hover {
                text-decoration: none;
              }
            }
          }

          &.type-new {
            cursor: pointer;

            &:hover {
              display: inline-block;
              padding-right: 20px;
              max-width: 100%;
              text-decoration: underline;

              &:before {
                display: inline-block;
                position: relative;
                top: 2px;
                float: right;
                width: 14px;
                height: 12px;
                margin-left: 2px;
                background: url(/assets/bi/images/icon_window.png) no-repeat;
                background-position: left -24px;
                content: '';
              }
            }
          }
        }

        .ddp-txt-ellipsis.type-name {
          display: block;
          position: relative;
          margin-top: 8px;
        }
      }
    }
  }

  &.ddp-table-detail tbody tr td {
    .ddp-wrap-hover-info {
      position: relative;

      .ddp-icon-info3 {
        display: inline-block;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: left -12px;
      }

      .ddp-box-layout4.ddp-popularity {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 6px;
        width: 360px;
      }

      &:hover .ddp-box-layout4.ddp-popularity {
        display: block;
      }
    }

    .ddp-ui-popularity {
      .ddp-icon-popularity {
        display: inline-block;
        float: left;
        width: 18px;
        height: 14px;
        margin-right: 8px;
        background: url(/assets/bi/images/icon_popularity.png) no-repeat;
      }

      .ddp-bar {
        float: left;
      }
    }
  }
}

/**************************************************************
	table (ddp-table-detail)
**************************************************************/

.ddp-page-catalog.ddp-list-catalog {
  clear: both;
}

table.ddp-table-detail {
  tbody tr td {
    .ddp-ui-popularity .ddp-wrap-hover-info {
      float: left;
      position: relative;
      top: 2px;
      margin-left: 6px;
      cursor: pointer;
    }

    .ddp-ui-catalogs.ddp-type {
      background-color: #f6f6f7;

      .ddp-box-catalogs {
        display: block;
        padding: 10px 10px 10px 10px;

        &:hover {
          background-color: #e7e7ea;
        }
      }
    }

    .ddp-ui-catalog-more {
      position: relative;

      .ddp-link-more {
        display: inline-block;
        position: relative;
        margin-top: 10px;
        color: #4a525c;
        text-decoration: underline;
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        top: 100%;
        margin-top: 5px;
        width: 350px;
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }

      .ddp-box-layout4 .ddp-data-det.ddp-scroll {
        max-height: 125px;
        overflow: auto;
      }

      .ddp-ui-catalogs:first-of-type {
        margin-top: 0;
      }
    }

    .ddp-wrap-confidence {
      margin: -8px 0;
    }

    .ddp-box-confidence {
      display: inline-block;
      position: relative;
      margin: 2px 0 2px 4px;
      padding: 6px 6px 6px 29px;
      height: 28px;
      color: #10bf83;
      border-radius: 2px;
      background-color: rgba(16, 191, 131, 0.1);
      box-sizing: border-box;
      cursor: pointer;

      &:first-of-type {
        margin-left: 0;
      }
    }

    .ddp-list-tags {
      margin: -6px 0;
    }

    .ddp-box-confidence {
      &.type-warning {
        color: #ffba01;
        background: rgba(255, 182, 2, 0.1);
      }

      &.type-block {
        color: #dc494f;
        background: rgba(220, 73, 79, 0.1);
      }

      .ddp-icon-success-r,
      .ddp-icon-warning-r,
      .ddp-icon-block-r {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 6px;
        background: url(/assets/bi/images/icon_confidence.png) no-repeat;
        vertical-align: middle;
      }

      .ddp-icon-success-r {
        width: 20px;
        height: 20px;
        margin-top: -10px;
      }

      .ddp-icon-warning-r {
        width: 20px;
        height: 18px;
        margin-top: -9px;
        background-position: left -21px;
      }

      .ddp-icon-block-r {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background-position: left -40px;
      }

      .ddp-ui-tooltip-info {
        position: absolute;
        top: 100%;
        margin-top: 4px;
        left: 0;
      }

      &:hover .ddp-ui-tooltip-info {
        display: block;
      }
    }
  }

  tr td {
    .ddp-list-tags .ddp-ui-buttons {
      display: inline-block;
      margin-top: 0;
    }

    .ddp-ui-buttons {
      margin-top: 5px;

      .ddp-btn-add {
        display: inline-block;
        padding: 5px 8px;
        border: 1px solid #d0d1d7;
        border-radius: 4px;
        color: #90969f;
        font-size: 12px;

        &:hover {
          border: 1px solid #b7b9c1;
          color: #4b515b;
        }

        em.ddp-icon-plus {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          background: url(/assets/bi/images/icon_add.png) no-repeat;
          background-position: -84px -22px;
          vertical-align: middle;
        }
      }
    }

    .ddp-ui-tags {
      margin: -9px 0;
      vertical-align: top;
    }

    .ddp-tags {
      float: left;
      display: block;
      position: relative;
      max-width: 100%;
      padding: 3px 6px;
      margin: 4px 3px 4px 0;
      background-color: #f2f1f7;
      border-radius: 4px;
      color: $primary;
      font-size: 13px;
      cursor: pointer;
      box-sizing: border-box;

      span.txt-tags {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      a.ddp-btn-delete {
        display: none;
        position: absolute;
        top: -4px;
        right: -4px;
        content: '';
        width: 14px;
        height: 14px;
        background: url(/assets/bi/images/btn_sclose.png) no-repeat;
        background-position: 0 -131px;
      }

      &:hover a.ddp-btn-delete {
        display: block;
      }

      &.ddp-txt {
        cursor: default;

        &:hover {
          padding: 5px 15px;

          a.ddp-btn-delete {
            display: none;
          }
        }
      }
    }

    .ddp-tag-default {
      display: inline-block;
      position: relative;
      padding: 5px 5px 5px 20px;
      margin: 3px 6px 3px 0;
      border-radius: 4px;
      color: $primary;
      font-size: 13px;
      box-sizing: border-box;

      &:before {
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 9px;
        content: '#';
        color: $primary;
        font-size: 13px;
      }

      input {
        display: block;
        width: 100%;
        border: none;
        background: none;
        color: $primary;
        font-size: 13px;
      }
    }
  }

  &.type-meata tbody tr {
    th,
    td {
      padding: 17px 10px 17px 0;
    }
  }
}
