.p-buttonset button.p-button[type="button"],
.p-selectbutton .p-button {
  margin: 0;

  &:not(:last-child) {
    border-right: 0 none;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.p-selectbutton .p-button {
  padding: 0.6rem;
}
