.layout-topbar-light {
  $topbarBgColor: #fdfeff;
  $topbarBorderColor: #e8f1f8;

  $topbarItemTextColor: #515c66;
  $topbarItemSecondaryTextColor: rgba($topbarItemTextColor, 0.7);
  $topbarItemTextHoverBgColor: rgba(#e8f1f8, 0.7);
  $topbarItemBgColor: #e8f1f8;
  $topbarMenuButtonBgColor: #e8f1f8;

  $topbarItemSubmenuItemHoverBgColor: #fdfeff;
  $topbarSubmenuHeaderBgColor: #fdfeff;

  $topbarItemSubmenuBgColor: #fdfeff;
  $topbarItemSubmenuTextColor: #515c66;
  $topbarItemSubmenuSecondaryTextColor: rgba($topbarItemSubmenuTextColor, 0.6);

  $topbarItemSubmenuBoxShadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
  @import '../_topbar_theme';
}
