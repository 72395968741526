.social-card {
  color: #fff;
  overflow: hidden;

  .social-icon {
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 22px 23px;
  }

  .download-icon {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 125px;
    width: 125px;
    right: -125px;
    font-size: 35px;
    padding: 40px 22px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .download-icon {
      right: -60px;
    }
  }
}

.total-card {
  .text-left {
    h4 {
      color: #fff;
      font-weight: 400;
      margin-bottom: 5px;
    }

    p {
      color: #fff;
      font-size: 15px;
    }
  }
  overflow: hidden;
}

.table-card {
  .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .table {
      > thead > tr > th {
        border-top: 0;
      }

      .chk-option {
        vertical-align: middle;
        width: 30px;
        display: inline-block;

        .checkbox-fade,
        .checkbox-fade .cr,
        .checkbox-zoom,
        .checkbox-zoom .cr {
          margin-right: 0;
        }
      }

      label {
        margin-bottom: 0;
      }

      tr {
        td,
        th {
          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }
      }
    }
  }
}

.latest-update-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      td {
        border-top: none;
      }

      &:after {
        content: '';
        position: absolute;
        background: $theme-border;
        height: 100%;
        width: 1px;
        top: 0;
        left: 110px;
        z-index: 1;
      }

      .update-meta {
        z-index: 2;
        min-width: 160px;

        .update-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          padding: 12px 13px;
          font-size: 16px;
          color: #fff;
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 575px) {
        &:after {
          display: none;
        }

        .update-meta {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
        }
      }
    }
  }
}

.review-card {
  .review-block {
    .cust-img {
      width: 50px;
      height: 50px;
    }

    > div {
      padding-top: 25px;
      padding-bottom: 25px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background-color: transparentize($primary-color, 0.9);
      }
    }
  }
}

.user-card2 {
  .risk-rate {
    display: inline-block;
    margin: 0 auto;

    span {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      border: 6px dashed #d6d6d6;
      border-top-color: $success-color;
      border-bottom-color: transparent;
      padding: 45px;
      display: block;
      position: relative;

      &:after {
        content: '';
        width: 90px;
        height: 90px;
        background-color: transparentize($success-color, 0.5);
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
      }

      b {
        font-size: 20px;
        color: #fff;
        z-index: 2;
        position: relative;
      }
    }
  }
}
// =====  todo ======

.to-do-list {
  padding-bottom: 10px;
  margin-bottom: 20px;

  i {
    color: #ccc;
    font-size: 17px;
    opacity: 0;
  }

  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }

  &:hover i {
    opacity: 1;
    transition: opacity ease-in 0.3s;
  }

  p {
    display: inline-block;
  }
}

.to-do-label {
  .checkbox-fade {
    display: block;

    .delete_todo {
      float: right;
      font-size: 24px;
      color: #ccc;
    }
  }

  .check-task {
    display: block;
  }

  i {
    cursor: pointer;
  }
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}
// ======= todo end ==========

.trafic-card {
  .progress {
    height: 7px;

    &.blue {
      background-color: transparentize($primary-color, 0.5);
    }

    &.green {
      background-color: transparentize($success-color, 0.5);
    }

    &.red {
      background-color: transparentize($danger-color, 0.5);
    }

    &.purple {
      background-color: transparentize($purple-color, 0.5);
    }

    &.yellow {
      background-color: transparentize($warning-color, 0.5);
    }
  }
}

.user-activity-card {
  .u-img {
    position: relative;

    .cover-img {
      width: 40px;
      height: 40px;
    }

    .profile-img {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      right: -5px;
    }
  }
}

.user-Messages-card {
  .u-img {
    position: relative;

    .profile-img {
      width: 40px;
      height: 40px;
    }

    .tot-msg {
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: 0;
      right: -5px;
      background-color: $danger-color;
      color: #fff;
      font-size: 10px;
      border-radius: 50%;
      text-align: center;
    }
  }
}

.latest-posts-card {
  .latest-posts-img {
    width: 90px;
    height: 80px;
  }
}

.task-card {
  .task-list {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: $theme-border;
      height: 100%;
      width: 2px;
      top: 0;
      left: 10px;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      background: $theme-border;
      height: 15px;
      width: 15px;
      bottom: -14px;
      left: 3px;
      z-index: 2;
      border-radius: 50%;
    }

    li {
      margin-bottom: 30px;
      padding-left: 30px;
      position: relative;

      .task-icon {
        position: absolute;
        left: 3px;
        top: 1px;
        border-radius: 50%;
        padding: 2px;
        color: #fff;
        min-width: 15px;
        min-height: 15px;
        z-index: 2;
      }
    }
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .update-meta {
        z-index: 2;
        min-width: 160px;

        .update-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          padding: 12px 13px;
          font-size: 16px;
          color: #fff;
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 575px) {
        .update-meta {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;
        }
      }
    }
  }
}

.feed-card {
  h6 {
    margin-top: 7px;
  }

  .feed-icon {
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px;
  }
}

.latest-activity-card {
  .table td {
    vertical-align: middle;
  }
}

.order-visitor-card {
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  h4,
  h6,
  p {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background-color: $primary-color;

    h4,
    h6,
    p {
      color: #fff;
    }
  }
}

.user-card-full {
  overflow: hidden;

  .user-profile-side {
    border-radius: 5px 0 0 5px;
  }

  .social-link {
    li {
      display: inline-block;

      a {
        i {
          color: $theme-font-color;
        }
        font-size: 20px;
        margin: 0 10px 0 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.form-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;

  .btn.btn-icon {
    width: 35px;
    height: 35px;
  }
}

.widget-statstic-card {
  position: relative;
  overflow: hidden;

  .st-icon {
    color: #fff;
    font-size: 23px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    transition: all 0.3s ease-in-out;
  }

  h2 {
    font-weight: 600;
    display: inline-block;
  }

  span {
    border-radius: 30px;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600;
  }

  &:hover {
    .st-icon {
      font-size: 50px;
    }
  }
}

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0;

  i {
    color: #fff;
    font-size: 80px;
    position: absolute;
    bottom: -10px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    i {
      transform: rotate(0deg) scale(1.4);
      opacity: 0.5;
    }
  }
}

.app-design {
  .btn {
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
  }

  .team-section {
    img {
      width: 35px;
      border-radius: 5px;
    }
  }

  .progress-box {
    p {
      margin-bottom: 0;
    }

    .progress {
      width: calc(100% - 80px);
      height: 8px;
      text-align: center;
      margin: 0 auto;
      background-color: #e5e5e5;
      border-radius: 30px;
      position: relative;
      overflow: inherit;

      .progress-bar {
        border-radius: 30px;

        label {
          position: absolute;
          top: -24px;
          right: 0;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }
}

.order-card {
  color: #fff;
  overflow: hidden;

  .card-icon {
    position: absolute;
    right: -17px;
    font-size: 100px;
    top: 20px;
    opacity: 0.5;
  }
}

.statustic-card {
  .card-body {
    position: relative;

    .progress {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 5px;
      overflow: visible;

      .progress-bar {
        position: relative;

        &:before {
          content: '';
          height: 5px;
          width: 5px;
          border-radius: 50%;
          position: absolute;
          right: 0;
          background: inherit;
        }

        &.bg-c-blue:before {
          animation: blue-blink-bar 0.5s linear infinite;
        }

        &.bg-c-green:before {
          animation: green-blink-bar 0.5s linear infinite;
        }

        &.bg-c-red:before {
          animation: red-blink-bar 0.5s linear infinite;
        }

        &.bg-c-yellow:before {
          animation: yellow-blink-bar 0.5s linear infinite;
        }
      }
    }
  }
}
@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($primary-color, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($primary-color, 0.7);
  }
}
@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($success-color, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($success-color, 0.7);
  }
}
@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($danger-color, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($danger-color, 0.7);
  }
}
@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($warning-color, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($warning-color, 0.7);
  }
}
// statustic card end

.statustic-progress-card {
  .progress {
    height: 5px;
  }
}

.quater-card {
  .progress {
    height: 4px;
  }
}

.client-map {
  img {
    vertical-align: top;
    width: 90px;
  }

  .client-detail {
    margin-bottom: 20px;

    img {
      border-radius: 5px;
    }
  }

  .client-profile {
    float: left;
  }

  .client-contain {
    display: inline-block;
    margin-left: 20px;

    h5 {
      display: block;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 10px;
    }
  }

  .client-card-box {
    border: 1px solid #ccc;
    border-radius: 5px;

    .client-border {
      border-right: 1px solid #ccc;
    }

    .client-border-card {
      border-top: 1px solid #ccc;
    }
  }
}
// ===========================================================================

.flat-card {
  .row-table {
    display: table;
    align-items: center;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0;

    &:first-child {
      border-bottom: 1px solid #ddd;
    }

    .br {
      border-right: 1px solid #ccc;
    }

    i {
      font-size: 28px;
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
    }

    h5 {
      display: block;
      margin-bottom: 0.3em;
      margin-right: 0;
    }

    > [class*='col-'] {
      display: table-cell;
      float: none;
      table-layout: fixed;
      vertical-align: middle;

      .row {
        display: flex;
        align-items: center;
      }
    }
  }

  &[class*='widget-'] {
    .row-table {
      display: flex;
    }
  }
}

.table-card {
  .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .table {
      > thead > tr > th {
        border-top: 0;
      }

      tr {
        td,
        th {
          &:first-child {
            padding-left: 25px;
          }

          &:last-child {
            padding-right: 25px;
          }
        }
      }

      &.without-header {
        tr {
          &:first-child {
            td {
              border-top: none;
            }
          }
        }
      }
    }
  }
}

.stastic-slider-full-card {
  .carousel-item {
    transition: transform 12s linear, -webkit-transform 12s linear;
  }
}

.statstic-data-card {
  .active.carousel-item-right,
  .carousel-item-next:not(.carousel-item-left) {
    transform: translateY(100%);
  }

  .active.carousel-item-left,
  .carousel-item-prev:not(.carousel-item-right) {
    transform: translateY(-100%);
  }

  .card-body {
    border-top: 2px solid transparent;

    &.level-down {
      border-color: $danger-color;
    }

    &.level-up {
      border-color: $success-color;
    }
  }
}

.social-widget-card {
  border-top: none;
  color: #fff;

  &:hover i {
    opacity: 1;
    transform: scale(1.1);
  }

  i {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 48px;
    opacity: 0.4;
    transition: all ease-in 0.3s;
  }
}

.ticket-card {
  .lbl-card {
    border-radius: 50px;
    padding: 5px 15px;
    display: inline-block;
  }
}

.widget-profile-card-1 {
  position: relative;
  margin-bottom: 50px;

  .middle-user {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    bottom: -60px;

    img {
      width: 115px;
      border-radius: 50%;
    }
  }

  ~ .card-footer [class*='col-'] {
    border-right: 1px solid #fff;

    &:last-child {
      border: none;
    }
  }
  @media only screen and (max-width: 767px) {
    ~ .card-footer [class*='col-']:nth-child(2) {
      border: 0;
    }

    ~ .card-footer [class*='col-']:last-child {
      margin-top: 10px;
    }
  }
}

.widget-profile-card-2 {
  img.user-img {
    width: 115px;
  }

  a {
    color: #fff;
    transition: all ease-in 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.widget-profile-card-3 {
  background-image: ('assets/bi/images/widget/slider5.jpg');
  background-size: cover;
  padding: 50px 0;
  text-align: center;

  img {
    width: 120px;
    border-radius: 50%;
  }
}

.profile-card {
  position: relative;
  min-height: 410px;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .card-body {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.72));

    h3 {
      font-weight: 600;
    }
  }

  ~ .card-footer {
    [class*='col-'] {
      padding: 10px;
      border-right: 1px solid rgba(255, 255, 255, 0.57);

      h4 {
        font-size: 18px;
        font-weight: 600;
      }

      &:last-child {
        border: none;
      }

      span {
        text-transform: uppercase;
      }
    }
    @media only screen and (max-width: 767px) {
      [class*='col-']:nth-child(2) {
        border: none;
      }
    }
  }
}

.blur-user-card {
  color: #fff;
  text-align: center;
  padding-top: 45px;
  background-image: ('assets/bi/images/widget/blur-bg.png');
  background-size: cover;

  h3 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 300;
  }

  .blur-footer {
    [class*='col-'] {
      padding: 1em;
      border-right: 1px solid #fff;
      margin-top: 30px;
      margin-bottom: 30px;

      &:last-child {
        border-right: none;
      }
    }
    @media only screen and (max-width: 767px) {
      [class*='col-']:nth-child(2) {
        border: 0;
      }

      [class*='col-']:last-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

.weather-bg-card {
  position: relative;

  img {
    width: 100%;
  }

  .card-body {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }

  h5 {
    color: #fff;
  }

  .card-body.alt {
    position: absolute;
    top: 0;

    span {
      color: #fff;
    }
  }

  .weather-temp {
    font-size: 70px;
    color: #fff;
  }

  ~ .card-footer {
    border-top: none;
    @media only screen and (max-width: 575px) {
      [class*='col-'] {
        margin-bottom: 10px;
      }
    }
  }
}

.new-cust-card {
  img {
    width: 40px;
  }

  h6 {
    margin-bottom: 0;
  }

  .align-middle {
    position: relative;

    .status {
      position: absolute;
      right: 0;
      top: 19px;
      font-size: 13px;

      &.active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $success-color;
      }
    }
  }
}

.latest-update-card {
  &.update-card {
    .card-body {
      .latest-update-box {
        &:after {
          left: 19px;
        }
      }
    }
  }
}

.testimonial-card {
  .progress {
    height: 5px;
  }

  .review-block {
    .cust-img {
      width: 40px;
      height: 40px;
    }

    > div {
      padding-top: 15px;
      padding-bottom: 9px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background-color: transparentize($primary-color, 0.9);
      }
    }
  }
}

.widget-chat-box {
  .card-header {
    i {
      font-size: 17px;
      color: $inverse-color;
      position: relative;
    }

    .pop-up {
      &:after {
        content: '';
        position: absolute;
        background-color: $danger-color;
        height: 8px;
        width: 8px;
        border-radius: 50px;
        right: -3px;
      }
    }
  }

  .send-chat {
    $chat-color: #eae9e9;
    position: relative;
    background: lighten($primary-color, 30%);
    padding: 7px;
    border-radius: 0 10px 10px 10px;
    font-size: 13px;
    margin-bottom: 30px;

    &:before {
      content: '';
      position: absolute;
      height: 12px;
      width: 12px;
      border: 6px solid transparent;
      border-top: 6px solid lighten($primary-color, 30%);
      border-right: 6px solid lighten($primary-color, 30%);
      left: -12px;
      top: 0;
    }

    .time {
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }

  .receive-chat {
    @extend .send-chat;
    background-color: $primary-color;
    color: #fff;
    border-radius: 10px;

    &:before {
      display: none;
    }

    .time {
      color: #666;
    }
  }

  .rc-10 {
    margin-bottom: 10px;
  }
}

.chat-card {
  .msg {
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;

    img {
      width: 60px;
      border-radius: 5px;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  .received-chat {
    .msg {
      background: lighten($primary-color, 30%);
      border-radius: 0 5px 5px 5px;

      &:after {
        content: '';
        position: absolute;
        left: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-bottom-color: lighten($primary-color, 30%);
      }
    }
  }

  .send-chat {
    text-align: right;

    .msg {
      background: $primary-color;
      color: #fff;
      border-radius: 5px 0 5px 5px;

      &:after {
        content: '';
        position: absolute;
        right: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-right-color: $primary-color;
      }
    }
  }
}

.nav {
  position: relative;
  z-index: 1;
}

.feed-card {
  h6 {
    margin-top: 7px;
    font-size: 14px;
  }

  .feed-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px 9px;
  }
}

.mrr-card {
  position: relative;

  .bottom-chart {
    bottom: 0;
  }
}

.bg-pattern {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%234680ff' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
}
