.layout-menu-light {
  $menuBgColor: #fdfeff;
  $menuShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  $rootMenuTextColor: #657380;

  $rootMenuItemTextColor: #515c66;
  $rootMenuItemHoverBgColor: #e5e9f0;
  $activeRootMenuItemTextColor: #282e33;
  $menuItemIconColor: #515c66;

  $submenuItemTextColor: #515c66;
  $submenuItemHoverBgColor: rgba(#5a657c, 0.2);
  $activeSubmenuItemTextColor: #2e3440;

  $externalSubmenuBgColor: #fdfeff;

  @import '../_menu_theme';
}
