.landing-wrapper {
  background: #ffffff;

  .landing-topbar {
    height: 120px;
    background: linear-gradient(180deg, #525d72 0%, #69768d 100%);
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();
    width: 100%;
    z-index: 999;

    #landing-menu-button {
      display: none;
      padding: 0 8px;
      cursor: pointer;
    }

    .logo {
      padding: 0 120px;
    }

    > ul {
      list-style-type: none;
      @include flex();
      @include flex-align-center();
      margin: 0;
      padding: 0;
      padding-right: 120px;

      > li {
        #landing-menu-close {
          display: none;
        }
        > a {
          font-weight: 500;
          letter-spacing: 0.08px;
          color: #ffffff;
          padding: 8px 16px;
          margin: 0 6px;
          border-radius: 5px;
          @include transition(background-color $transitionDuration);

          &:hover {
            background-color: rgba(#5cacf4, 0.3);
            color: #ffffff;
          }
        }
      }
    }
  }
  .landing-banner {
    background: linear-gradient(180deg, #69768d 0%, #ffffff 100%);
    @include flex();
    @include flex-justify-between();
    @include flex-align-center();
    padding: 120px 120px 180px;

    .landing-banner-content {
      > h2 {
        font-weight: 600;
        margin: 0;
        margin-bottom: 12px;
        color: #ffffff;

        > span {
          color: #ffffff;
        }
      }

      > h6 {
        font-size: 15px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 24px;
        color: #ffffff;
        max-width: 480px;
        line-height: 1.5;
      }

      > button {
        padding: 8px 20px;
        span {
          padding: 0;
          display: block;
        }
      }
    }
    .landing-banner-image {
      text-align: right;
      img {
        width: 80%;
        height: auto;
        max-height: 400px;
        opacity: 0.8;
      }
    }
  }

  .section-header {
    @include flex();
    flex-direction: column;
    @include flex-justify-center();
    @include flex-align-center();
    text-align: center;
    padding: 0 120px;

    > h2 {
      font-weight: 500;
      margin: 0;
      margin-bottom: 14px;
      color: #2c3d57;
    }
    > h6 {
      font-weight: normal;
      margin: 0;
      color: #2c3d57;
      max-width: 480px;
      line-height: 1.33;
    }
  }

  .landing-features {
    padding: 120px 0;

    .features-cards {
      padding: 120px 120px 0;

      .feature-card {
        @include border-radius(5px);
        background-color: #ffffff;
        padding: 24px 12px 36px;
        text-align: left;
        cursor: pointer;
        @include transition(all $transitionDuration);

        &:hover {
          transform: scale3d(1.02, 1.02, 1);
        }

        h5 {
          margin: 6px 0 12px;
        }

        > img {
          height: 100px;
          width: auto;
        }

        span {
          line-height: 1.4;
          display: block;
        }
      }
    }
  }

  .landing-promo {
    background: linear-gradient(360deg, #69768d 0%, #78849b 100%);
    @include flex();
    @include flex-justify-between();
    @include flex-align-center();
    padding: 60px 120px;

    .landing-promo-content {
      > h2 {
        font-weight: 600;
        margin: 0;
        margin-bottom: 14px;
        color: #ffffff;

        > span {
          color: #ffffff;
        }
        max-width: 650px;
      }

      > h6 {
        font-size: 15px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 14px;
        color: #ffffff;
        max-width: 480px;
        line-height: 1.33;
      }

      > button {
        padding: 8px 20px;
        span {
          padding: 0;
          display: block;
        }
      }
    }
    .landing-promo-image {
      text-align: right;
      img {
        width: 60%;
        height: auto;
        max-height: 400px;
      }
    }
  }

  .landing-testimonials {
    padding: 120px 120px 60px;

    .testimonials {
      @include flex();
      @include flex-justify-center();

      .testimonials-wrapper {
        overflow: hidden;
        position: relative;
        max-width: 100%;

        .testimonials-content {
          @include flex();
          padding: 32px 0 0;
          flex-direction: row;
          transition: transform 1000ms ease 0s;
          transform: translate3d(0px, 0px, 0px);

          .testimonial {
            flex: 1 0 100vw;
            @include transition(visibility 0.8s);
            @include transition(transform 1000ms ease 0s);
            max-width: 33%;

            .testimonial-content {
              opacity: 0.9;
              margin: 0 6px;
              position: relative;
              height: 100%;
              background-color: #ffffff;
              padding: 65px 42px 66px;
              @include flex();
              @include flex-justify-between();
              flex-direction: column;

              > span {
                display: block;
                font-size: 14px;
                line-height: 1.5;
                color: #2c3d57;
                margin-bottom: 20px;
                flex-grow: 1;
              }

              > h5 {
                font-weight: 500;
                line-height: 1.2;
                color: #2c3d57;
                margin: 0;
              }

              > h6 {
                font-weight: 500;
                line-height: 1.5;
                color: #66768d;
                margin: 0;
              }
            }
          }
        }

        .link-points {
          .points {
            @include flex();
            @include flex-align-center();
            @include flex-justify-center();
            padding-top: 36px;

            .point {
              width: 15px;
              height: 15px;
              background-color: #e6e9ef;
              @include border-radius(50%);
              margin: 3px;
              cursor: pointer;

              &.active-point {
                width: 19px;
                height: 19px;
                background-color: #2c3d57;
              }

              &.mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .landing-pricing {
    padding: 120px 0;
    .section-header {
      @include flex-justify-center();
      @include flex-align-center();
      text-align: center;
    }

    .pricing-cards {
      padding: 60px 120px;

      .pricing-card {
        margin: 12px;
        height: auto;
      }
    }
  }

  .landing-contact {
    padding: 60px 0 0;
    background: linear-gradient(360deg, #69768d 0%, #ffffff 100%);

    .section-header {
      @include flex-justify-center();
      @include flex-align-center();
      text-align: center;
      > h2 {
        color: #2c3d57;
      }
      > h6 {
        color: #2c3d57;
      }
    }

    .landing-contact-content {
      @include flex();
      @include flex-justify-center();
      padding: 60px 180px;
      position: relative;
      text-align: center;

      .landing-contact-form {
        padding: 20px;

        > h3 {
          font-size: 16px;
          color: #ffffff;
          margin: 0;
          font-weight: 500;
          margin-bottom: 20px;
        }

        > input,
        > textarea {
          width: 100%;
          margin-bottom: 16px;
          border-radius: 4px;
          border: 1px solid #ced4da;
          padding: 10px 12px;
          background-color: #f6f9fe;
          color: #515c66;

          &::placeholder {
            color: #515c66;
          }
        }
        > button {
          width: 100%;
        }
      }
    }
  }

  .landing-footer {
    background: linear-gradient(360deg, #525d72 0%, #69768d 100%);
    padding: 21px 120px;
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();

    > img {
      height: 30px;
      width: auto;
    }

    .social-icons {
      @include flex();
      @include flex-align-center();

      a {
        color: #ffffff;
        margin-left: 18px;
        cursor: pointer;
        padding: 6px;

        i {
          font-size: 22px;
          @include transition(color $transitionDuration);
        }

        &:hover {
          i {
            color: #ced4da;
          }
        }
      }
    }
  }

  .p-button-text {
    width: 100%;
  }
}

@media (max-width: $tabletBreakpoint) {
  .landing-body {
    &.block-scroll {
      overflow: hidden;

      .landing-wrapper {
        .landing-mask {
          display: block;
        }
      }
    }
    .landing-wrapper {
      &.landing-menu-active {
        .landing-topbar {
          .landing-menu {
            transform: translate3d(0px, 0px, 0px);
          }
        }
      }
      .landing-topbar {
        .logo {
          padding: 0 33px;
        }
        .landing-menu {
          position: fixed;
          flex-direction: column;
          @include flex-align-end();
          right: 0;
          top: 0;
          padding: 40px 20px;
          z-index: 999;
          width: 240px;
          height: 100%;
          background-color: #69768d;
          box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02), 0 6px 16px -2px rgba(0, 0, 0, 0.06),
            0 2px 6px -2px rgba(0, 0, 0, 0.08);
          transform: translate3d(260px, 0px, 0px);
          @include transition(transform $transitionDuration);

          > li {
            margin: 0;
            width: 100%;
            margin-bottom: 12px;

            > a {
              padding: 6px 16px;
              font-size: 14px;
              text-align: left;
              display: block;
              color: #ffffff;
              @include transition(background-color $transitionDuration);
              &:hover {
                background-color: rgba(#5cacf4, 0.3);
                color: #ffffff;
              }
            }

            #landing-menu-close {
              display: block;
              font-size: 20px;
              text-align: right;
              color: #5cacf4;
              @include transition(color $transitionDuration);

              &:hover {
                background-color: #69768d;
                color: #ffffff;
              }
            }
          }
        }

        #landing-menu-button {
          display: block;
          color: #ffffff;
          font-size: 20px;
          padding: 0 33px;
        }
      }

      .landing-banner {
        padding: 120px 42px 180px;
        .landing-banner-content {
          text-align: center;
          width: 100%;
          h6 {
            max-width: 100%;
          }
        }

        .landing-banner-image {
          display: none;
        }
      }

      .landing-features {
        padding: 42px 16px;

        .features-cards {
          padding: 0;

          .feature-card {
            text-align: center;
            margin: 30px 0;
          }
        }

        .features-content {
          padding: 0;
          flex-direction: column;

          img {
            width: 100%;
          }

          ul {
            li {
              margin: 16px 0;
              max-width: 400px;

              img {
                width: 64px;
              }
            }
          }
        }
      }

      .section-header {
        padding: 0px;
      }

      .landing-promo {
        padding: 36px 42px 64px;

        .landing-promo-content {
          text-align: center;
          width: 100%;
          h6 {
            max-width: 100%;
          }
        }
        .landing-promo-image {
          display: none;
        }
      }

      .landing-pricing {
        padding: 36px 42px 64px;

        .pricing-cards {
          padding: 0;
        }
      }

      .landing-testimonials {
        padding: 36px 42px 64px;

        .testimonials {
          @include flex();
          @include flex-justify-center();

          .testimonials-wrapper {
            overflow: hidden;
            position: relative;
            max-width: 100%;

            .testimonials-content {
              text-align: center;
              padding: 0;
              .testimonial {
                max-width: 100%;
              }
            }

            .link-points {
              .points {
                .point {
                  &.mobile {
                    @include flex();
                  }
                }
              }
            }
          }
        }
      }

      .landing-contact {
        padding: 36px 42px 64px;
        .landing-contact-content {
          padding: 0;
        }
        .landing-contact-form {
          width: 100%;
        }
      }

      .landing-footer {
        padding: 18px 24px 64px;
      }
    }
  }
}
