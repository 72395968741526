.invoice {
  padding: 2rem;

  .invoice-header {
    @include flex();
    @include flex-justify-between();
  }

  .invoice-company {
    .logo-image {
      width: 120px;
      margin-bottom: 0.5rem;
    }

    div {
      margin-bottom: 0.5rem;
    }

    .company-name {
      font-weight: 700;
      font-size: 1.5rem;
    }
  }

  .invoice-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: right;
  }

  .invoice-details {
    width: 15rem;
    @include flex();
    @include flex-wrap(wrap);

    > div {
      width: 50%;
      margin-bottom: 0.5rem;
    }

    .invoice-label {
      text-align: left;
      font-weight: 700;
    }

    .invoice-value {
      text-align: right;
    }
  }

  .invoice-to {
    margin-top: 1.5rem;
    padding-top: 2rem;
    border-top: 1px solid $dividerColor;

    .bill-to {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .invoice-to-info {
      div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .invoice-items {
    margin-top: 2rem;
    padding-top: 2rem;

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid $dividerColor;
      }

      th {
        font-weight: 700;
      }

      th,
      td {
        padding: 1rem;
        text-align: right;
      }

      th:first-child,
      td:first-child {
        text-align: left;
      }
    }
  }

  .invoice-summary {
    @include flex();
    @include flex-justify-between();
    margin-top: 2.5rem;
    padding-top: 2.5rem;

    .invoice-value {
      font-weight: 700;
    }
  }
}

@media print {
  .invoice {
    padding: 0;
    margin: 0;
    background: #ffffff;
    color: #424242;
  }
}
