.short-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

td,
th {
  &.short-text {
    display: table-cell;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
