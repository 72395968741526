@charset "utf-8";
@import '../../settings/custom-variables';
@import '../../settings/theme-variables';

body {
  --color-3: #f9f8ff;
  --color-black: #444;
}

.ddp-ui-contents,
.ddp-ui-contentsin,
.ddp-ui-contents-in {
  top: 0;
}

.ddp-layout-header,
.ddp-layout-lnb.ddp-selected {
  /* display: none !important;  */
  /* background-color: $primary; */
  background-color: $primary;
  border: none !important;
}

app-lnb {
  height: 100%;
}

.ddp-layout-lnb {
  width: 100% !important;
  height: 55px !important;
  border: none !important;
  text-indent: 0 !important;
}

.ddp-ui-top-info {
  /* top: 100px !important; */
}

.able-header {
  display: grid;
  grid-template-columns: 1fr 8fr 50px;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  align-items: start;
  border: none !important;
}

.able-header-logo-block {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
}

.able-header-logo {
  height: 100%;
  display: flex;
  align-items: center;
  text-indent: 0 !important;
  border: none !important;

  a {
    line-height: 0;
  }

  img {
    position: relative;
    top: -3px;
    height: 32px;
  }
}

.able-header-menu-btn {
  position: relative !important;
  line-height: 1.5;
  text-align: left;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  touch-action: manipulation;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 50px;

  /* position: absolute; */
  top: 0;
  padding: 0 10px;
  right: auto;
  left: 10px;
  color: $white;
}

.able-header-menu-btn-icon {
  line-height: 1.5;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  color: $white;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
  backface-visibility: hidden;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);

  &:after {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:before {
    background-color: rgba(255, 255, 255, 0.8);
    top: -6px;
    width: 40%;
  }

  &:after {
    top: 6px;
    width: 70%;
    content: '';
    position: absolute;
    left: 0;
    height: 2px;
    transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 2px;
    transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2px;
  }
}

.able-header-menu-btn {
  &:hover .able-header-menu-btn-icon {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;

    &:before,
    &:after {
      background-color: rgba(255, 255, 255, 1);
      width: 100%;
    }
  }

  a::before {
    /* left: 0 !important;
    top: 50% !important;
    color: #fff !important */
  }
}

.able-header-nav {
  /* Nav bar is hidden in demo */
  display: none !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $white;
  padding: 20px 0;
}

.able-header-nav-items {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
}

.able-header-nav-item {
  margin: 0 10px;

  /* padding: 20px 0; */
  /* margin-left: 20px;
  width: 100px; */
  position: relative;

  /* width: 100%; */
  min-width: 178px;
}

.able-header-nav-item-selected .able-header-nav-item-link {
  cursor: default;
  background-color: $primary-color;
  color: $white;
  border-radius: 4px;

  /* border-radius: 4px 4px 0 0; */
  /* box-shadow: 0 10px 5px -8px rgba(0, 0, 0, 0.4); */
  margin: 10px;
  -webkit-box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.able-header-nav-item-link {
  position: relative;
  z-index: 2;
  padding: 10px 20px;

  /* margin: 0 20px;
  padding: 10px 10px; */

  fa-icon {
    margin-right: 6px;
  }
}

.able-header-nav-item-dropdown {
  position: absolute;
  z-index: 1;
  left: 0;

  /* top: 24px; */
  top: -20px;

  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  background-color: $white;

  /* padding: 15px 30px; */
  padding: 15px 0px;
  padding-top: 56px;

  /* box-shadow: 0 10px 5px -8px rgba(0, 0, 0, 0.4); */
  box-shadow: 0 10px 5px -8px rgba(0, 0, 0, 0.4), -1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.able-header-nav-item-dropdown-item {
  padding: 10px 0;
  cursor: pointer;

  fa-icon {
    padding-left: 30px;
    padding-right: 10px;
  }
}

.able-header-nav-item-dropdown-item-link {
  /* padding-left: 50px; */
}

.able-header-nav-item-dropdown-item {
  &:hover,
  &:active {
    background-color: $primary-color;
    color: $white !important;
  }

  &:hover .able-header-nav-item-dropdown-item-link,
  &:active .able-header-nav-item-dropdown-item-link {
    color: $white !important;
  }
}

app-gnb,
.ddp-ui-headright {
  height: 100% !important;
}

.ddp-wrap-myinfo {
  height: 100% !important;
  z-index: 9999;
}

.ddp-data-photo {
  color: $white !important;
  margin: 0 !important;
  height: 100% !important;
  display: flex !important;
  position: relative !important;
  top: -1px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  fa-icon {
    color: $white !important;
    margin: 0 !important;
    height: 100% !important;
    display: flex !important;
    position: relative !important;
    top: -1px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 100%;
  }
}

.ddp-ui-sub-myinfo {
  background-color: $white !important;
}

.ddp-wrap-data {
  background-color: $primary !important;
}

.ddp-data-title {
  color: $white !important;
}

.ddp-box-power.ddp-owner {
  background-color: $white !important;
  color: var(--color-black) !important;
  font-size: 10px !important;
}

.ddp-btn-line2 {
  border-color: $primary;
  color: $primary;
}

.ddp-icon-spacelist {
  color: $white !important;
}

.ddp-btn-line2 fa-icon {
  margin-right: 6px;
}

.ddp-box-tag {
  border-color: $white;
  color: $white;
}

.ddp-ui-number {
  color: $white !important;

  fa-icon {
    margin-right: 4px;
  }
}

.ddp-data-info-right .ddp-data-create,
.ddp-data-num {
  color: $white !important;
}

.ddp-layout-contents {
  padding: 0 !important;
}

.ddp-ui-contents-in {
  padding: 0 20px !important;
}

selfai-workbook-edit,
selfai-workbook-view {
  .ddp-ui-toptitle {
    display: none !important;
  }

  .ddp-wrap-chart-lnb,
  .ddp-view-chart-lnb,
  .ddp-ui-chart-lnb,
  .ddp-wrap-dropmenu,
  .ddp-ui-dropmenu.ddp-selected,
  .ddp-ui-drop-title,
  .ddp-ui-drop-contents,
  .ddp-data-in {
    background-color: white !important;
    color: black !important;
  }

  .ddp-ui-drop-contents {
    /* scrollbar-width: thin; */
    scrollbar-color: #d0d1d8 #f9f9f9;
  }
}

/* The emerging W3C standard that is currently Firefox-only */

app-edit-dataflow-rule-2 {
  .ddp-ui-page {
    top: 24px !important;
  }

  .ddp-list-typebasic {
    top: 50px !important;
  }
}

/* Works on Chrome/Edge/Safari */

selfai-workbook-edit,
selfai-workbook-view {
  .ddp-ui-dimension:hover,
  .ddp-ui-measure:hover {
    background-color: #d0d1d8 !important;

    /* color: black !important; */
  }

  .ddp-ui-dimension:hover .ddp-data-in,
  .ddp-ui-measure:hover .ddp-data-in {
    background-color: #d0d1d8 !important;
  }

  .ddp-ui-sub-title:hover {
    background-color: #d0d1d8 !important;

    /* color: black !important; */
  }

  .ddp-ui-sub-titlein {
    &.ddp-dimension {
      /* color: $primary !important; */
      color: #000 !important;
    }

    &.ddp-measure {
      /* color: #7471f2 !important; */
      color: #000 !important;
    }
  }

  .ddp-icon-dimension-ab,
  .ddp-icon-measure-sharp {
    background-position-x: 0;
  }

  .ddp-ui-dimension-wrap {
    background-color: $primary !important;
    color: white !important;
  }

  .ddp-ui-measure-wrap {
    background-color: $info !important;
    color: white !important;
  }
}

/* .ddp-type-contents2 {
  min-height: 600px !important;
} */

.ddp-box-measure,
.ddp-box-dimension,
.ddp-txt-measure,
.ddp-txt-dimension {
  display: none !important;
}

/***********************************************************************************************************
	로그인
************************************************************************************************************/

/* .ddp-wrap-login {height:100%; min-height:800px; background:url(/assets/bi/images/login.jpg) no-repeat center center fixed; background-size:cover;} */

.ddp-wrap-login {
  height: 100%;
  width: 100%;
  min-height: 800px;
  background: none !important;
  background-size: cover;

  &.let-it-snow {
    height: 100%;
    min-height: auto;
    overflow: hidden;
    background: url(/assets/bi/images/login_christmas.jpg) no-repeat center center fixed;
    background-size: cover;

    .ddp-bg-login,
    .ddp-blur2 {
      display: none;
    }
  }
}

.ddp-ui-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  background-color: white;
  background-image: url('/assets/bi/images/logotype_selfai.svg');
  background-repeat: no-repeat;
  background-size: 200px;
  background-position-x: right;
  background-position-y: center;
  background-position: 80% center;
  z-index: 1;
  box-sizing: border-box;
}

.ddp-form-login {
  box-shadow: 3px 3px 4px -0.8px;
  background: $primary;
  padding: 15px;
  border-radius: 15px;
}

/* .ddp-wrap-login .ddp-blur2{display:block;background:url(/assets/bi/images/login.jpg) no-repeat fixed;; background-size:cover; overflow:hidden; content:''; */

.ddp-wrap-login .ddp-blur2 {
  display: block;
  background: none !important;
  background-size: cover;
  overflow: hidden;
  content: '';
  filter: blur(5px);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 410px;
}

.ddp-ui-login {
  .ddp-box-login {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 278px;
    margin: 0 auto;
  }

  .ddp-ui-logo {
    height: 36px;
    padding-bottom: 20px;

    .st0 {
      fill: #1a274b;
    }

    .st1 {
      fill: #272950;
    }

    .st2 {
      fill: #47dbf4;
    }

    .st3 {
      fill: #7575ea;
    }
  }
}

.ddp-form-login {
  .ddp-form-input {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    label {
      display: block;
      padding: 5px 0 0 0;
      color: rgba(39, 41, 80, 0.7);
      font-size: 13px;
    }

    span.ddp-input-type {
      display: block;
      width: 100%;
      border: none;
      background: transparent;

      input {
        width: 100%;
        padding: 8px 0;
        border: none;
        border-bottom: 1px solid #b5b5cc;
        background-color: transparent;
        color: white;
        font-size: 18px;

        &::placeholder {
          color: white;
          font-size: 12px;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px white inset;
        }

        &:focus {
          border-bottom: 1px solid #272950;
        }
      }
    }
  }

  .ddp-ui-login-option {
    min-width: 360px;
    max-width: fit-content;
    margin: 15px 0 10px 10px;
    white-space: nowrap;
    overflow: hidden;

    .ddp-wrap-input-checkbox {
      position: relative;
      float: left;
      margin-right: 20px;
      padding-left: 20px;

      label {
        position: relative;
        font-size: 14px;
        color: white;
      }

      input[type='checkbox'] {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        opacity: 0;
        z-index: 1;
      }

      i {
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_checkbox2.png) no-repeat;
      }

      input[type='checkbox']:checked + i {
        background-position: -26px -13px;

        + label {
          font-weight: bold;
        }
      }
    }
  }

  a {
    &.ddp-link-search {
      color: white;
      font-size: 13px;
      font-weight: 300;
    }

    &.ddp-link-sign {
      position: relative;
      margin-left: 12px;
      padding-left: 12px;
      color: white;
      font-size: 13px;
      font-weight: 300;

      &:before {
        display: inline-block;
        height: 13px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -7px;
        border-left: 1px solid #585879;
        content: '';
      }
    }

    &.ddp-link-search:hover,
    &.ddp-link-sign:hover {
      text-decoration: underline;
    }
  }
}

.ddp-ui-login a.ddp-link-request {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
}

.ddp-ui-login-buttons {
  padding-bottom: 15px;

  a.ddp-btn {
    display: inline-block;
    width: 40%;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 38px;
    font-weight: bold;
    border-radius: 3px;
    background-color: $primary;

    /* background-color: #272950; */
    border: 1px solid white;
    border-radius: 7px;

    &:hover {
      border: 1px solid white;
      background-color: white;
      color: black;
      transition: 0.2s linear;
    }
  }
}

/***********************************************************************************************************
	로그인 footer
************************************************************************************************************/

.ddp-layout-footer {
  position: absolute;
  left: 66px;
  bottom: 30px;
  width: 278px;
  text-align: center;
  z-index: 1;
  overflow: hidden;
}

.ddp-ui-footer {
  display: inline-block;
  position: relative;
  text-align: left;

  img.ddp-logo-sk {
    position: absolute;
    top: 30px;
    left: 0;
  }

  ul.ddp-list-footer {
    float: left;
    display: inline-block;
    overflow: hidden;

    li {
      float: left;

      a {
        display: block;
        position: relative;
        padding: 0 7px 0 6px;
        color: #272950;
        font-weight: 300;

        &:hover {
          text-decoration: underline;
        }

        em {
          display: none;
        }
      }

      &:first-of-type a:after {
        display: none;
      }

      a:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -7px;
        content: '';
        height: 13px;
        border-right: 1px solid #909090;
      }

      &:first-child a {
        padding-left: 0;
      }
    }
  }

  span.ddp-ui-copy {
    float: left;
    display: inline-block;
    padding: 3px 0 0 0;
    color: rgba(39, 41, 80, 0.6);
    font-size: 12px;
    letter-spacing: -1px;
  }
}

/***********************************************************************************************************
	로그인 팝업
************************************************************************************************************/

.ddp-wrap-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 126;
}

.ddp-ui-popup-in {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
}

.ddp-wrap-popup em.ddp-bg-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(208, 209, 216, 0.5);
}

.ddp-ui-popup-table {
  display: table;
  width: 100%;
  height: 100%;
}

.ddp-wrap-popup .ddp-ui-popup {
  position: relative;
  left: 50%;
  width: 600px;
  margin: 98px 0 98px -300px;
  padding: 100px 0 60px 0;
  background-color: #fff;
  z-index: 91;

  a.ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    z-index: 1;
  }
}

.ddp-popup-table-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.ddp-wrap-popup {
  .ddp-popup-table-cell .ddp-ui-popup {
    display: inline-block;
    padding-bottom: 100px;
    position: relative;
    margin: 0;
    left: 0;
  }

  .ddp-ui-popup span.ddp-ui-pop-title {
    display: block;
    padding-bottom: 28px;
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    color: #222;
  }
}

.ddp-ui-popup {
  ul.ddp-list-tab {
    display: block;
    padding: 20px 0 0 0;
    text-align: center;

    li {
      display: inline-block;
      padding: 0 12px 0 12px;

      a {
        display: block;
        padding-bottom: 10px;
        color: #444;
        font-size: 14px;
        border-bottom: 2px solid #fff;
      }

      &.ddp-selected a {
        border-bottom: 2px solid #ff8b00;
        font-weight: bold;
      }
    }
  }

  .ddp-ui-buttons2 {
    padding-top: 55px;

    .ddp-btn-type-popup {
      margin: 0 4px;
    }
  }

  .ddp-txt-pop-info {
    padding: 36px 68px 0 68px;
    color: #90969f;
    font-size: 14px;
    text-align: center;
  }
}

.ddp-ui-search-contet {
  padding: 27px 68px 0 68px;

  &.ddp-ui-id-content {
    padding-top: 55px;
  }

  .ddp-ui-input-form {
    padding-bottom: 45px;

    .ddp-ui-error {
      display: block;
      padding: 10px 0 0 0;
      color: #ff3324;
      font-size: 14px;

      em.ddp-icon-error {
        display: inline-block;
        position: relative;
        top: -1px;
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        vertical-align: middle;
      }
    }
  }
}

.ddp-ui-search-id {
  padding: 106px 68px 117px 68px;

  span.ddp-txt-data {
    display: block;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 16px;
    text-align: center;

    em.ddp-data-id {
      color: #ff8b00;
    }
  }
}

.ddp-wrap-popup .ddp-ui-popup.ddp-box-user-popup {
  margin-top: 98px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  .ddp-ui-buttons2 {
    padding-top: 0;
  }

  .ddp-ui-user-contet {
    padding-top: 30px;
  }
}

.ddp-ui-user-contet {
  padding: 45px 68px 0 68px;
}

.ddp-ui-input-form {
  position: relative;
  padding-bottom: 35px;

  .ddp-ui-error {
    display: block;
    position: absolute;
    bottom: 15px;
    color: #eb5f58;
    font-size: 12px;

    em.ddp-icon-error {
      display: none;
      position: relative;
      top: -1px;
      margin-right: 5px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -16px 0;
      vertical-align: middle;
    }
  }

  .ddp-input-check {
    position: relative;
    padding-right: 20px;
    border-bottom: 1px solid #ddd;

    input {
      border-bottom: none;
    }

    em {
      &.ddp-icon-ok {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        background: url(/assets/bi/images/icon_message.png) no-repeat;
      }

      &.ddp-icon-error {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        background: url(/assets/bi/images/icon_message.png) no-repeat;
        display: none;
        width: 9px;
        height: 9px;
        margin-top: -5px;
      }

      &.ddp-icon-ok {
        display: none;
        width: 11px;
        height: 8px;
        margin-top: -4px;
        background-position: left -10px;
      }
    }
  }

  &.ddp-type-ok .ddp-input-check .ddp-icon-ok {
    display: block;
  }

  &.ddp-type-error .ddp-input-check {
    .ddp-icon-error {
      display: block;
    }

    .ddp-icon-ok {
      display: none;
    }
  }
}

.ddp-input-clear {
  margin: 0 -5px;
}

.ddp-ui-input-form.ddp-col-6 {
  padding: 0 5px 35px 5px;
}

.ddp-ui-user-contet {
  .ddp-wrap-overflow {
    margin: 0 -10px;

    .ddp-ui-fleft {
      float: left;
      width: 50%;
      padding: 0 5px;
      box-sizing: Border-box;
    }

    .ddp-ui-fright {
      float: right;
      width: 50%;
      padding: 0 5px;
      box-sizing: border-box;
    }

    .ddp-ui-input-form {
      padding: 0 5px 35px 5px;
    }
  }

  .ddp-ui-input-form .ddp-input-check {
    .ddp-icon-error {
      background-position: left -28px;
    }

    .ddp-icon-ok {
      background-position: left -38px;
    }
  }
}

.ddp-ui-input-form {
  .ddp-ui-error {
    visibility: hidden;
  }

  &.ddp-type-error .ddp-ui-error {
    visibility: inherit;
  }
}

.ddp-ui-info .ddp-txt-info {
  display: block;
  padding-bottom: 15px;

  em.ddp-icon-info {
    position: relative;
    top: -1px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.ddp-ui-file-select {
  position: relative;
  padding-bottom: 65px;

  .ddp-ui-buttons {
    padding: 31px 0 31px 0;
    margin-top: 14px;

    .ddp-btn-sbutton {
      float: left;
      position: relative;
      min-width: 90px;
      padding: 6px 10px;
      margin: 0 2px;
      color: #444;
      font-size: 13px;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #ccc;
      box-sizing: border-box;

      &:hover {
        border: 1px solid #b7b9c1;
      }

      input[type='file'] {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
      }

      &.ddp-bg-gray {
        color: #fff;
        border: 1px solid #90969f;
        background-color: #90969f;

        &:hover {
          background-color: #4b515b;
        }
      }
    }
  }

  span.ddp-txt-file-det {
    display: block;
    color: #9c9c9c;
    font-size: 12px;
  }

  .ddp-data-photo {
    float: left;
    width: 90px;
    height: 90px;
    margin-top: 14px;
    margin-right: 25px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ddp-ui-popup .ddp-ui-buttons2 {
  display: block;
  padding-top: 55px;
  text-align: center;
  overflow: hidden;
}

/***********************************************************************************************************
	PAGE : 로그인 privacy
************************************************************************************************************/

.ddp-wrap-popup .ddp-ui-popup.ddp-box-policy-popup {
  margin-top: 40px;

  span.ddp-ui-pop-title {
    padding-bottom: 0;
    font-weight: normal;
    font-size: 28px;
  }
}

.ddp-ui-policy-contet {
  padding: 50px 70px;

  p {
    &.ddp-ui-p {
      padding-bottom: 40px;
      color: #4b515b;
      font-size: 18px;
    }

    &.ddp-ui-p2 strong {
      display: block;
    }
  }

  dl.ddp-ui-dl {
    padding-bottom: 40px;

    * {
      line-height: 1.5em;
      font-size: 13px;
    }

    dt {
      padding-bottom: 10px;
      font-size: 13px;
      font-weight: bold;
      color: #4b515b;
    }

    dd {
      font-size: 13px;
      color: #4b515b;

      ol li {
        padding-bottom: 10px;

        ul {
          padding-top: 10px;

          li {
            padding-left: 20px;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 생성 팝업
************************************************************************************************************/

.ddp-box-popup {
  .ddp-wrap-editbox {
    width: 460px;
    margin: 0 auto;
    padding-bottom: 45px;

    .ddp-wrap-edit2:first-of-type {
      margin-top: 0;
    }
  }

  .ddp-wrap-edit2 {
    margin-top: 35px;

    &:first-child {
      padding-top: 0;
    }
  }

  .ddp-txt-alert {
    padding: 35px 0 40px 0;
    color: #90969f;
    font-size: 14px;
    text-align: center;
  }
}

/**************************************************************
  Page : 대시보드
**************************************************************/

.ddp-box-widget.type-event-trigger .ddp-top-control {
  float: auto;
  width: 100%;
  background-color: #4b515b;
}

.ddp-ui-boardedit .ddp-box-widget.type-event-trigger .ddp-top-control {
  background-color: #4b515b;
}

.ddp-box-widget .ddp-top-control .ddp-ui-buttons {
  em.ddp-icon-widget-info2:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -7px;
    width: 15px;
    height: 14px;
    background: url(/assets/bi/images/icon_widget.png) no-repeat;
    background-position: left -244px;
    content: '';
  }

  .ddp-box-btn2 {
    &:hover em.ddp-icon-widget-info2:before {
      background-position: -16px -244px;
    }

    .ddp-box-info.ddp-limitation {
      display: none;
      position: absolute;
      top: 100%;
      right: -100px;
      padding: 6px 10px;
      width: 240px;
      border-radius: 3px;
      background-color: #7182ba;
      color: #fff;
      box-sizing: border-box;
      z-index: 1000;

      .ddp-total {
        display: block;
        padding-bottom: 4px;

        strong {
          display: block;
        }
      }
    }

    &:hover .ddp-box-info.ddp-limitation {
      display: block;
    }
  }
}

/**************************************************************
  Page : 대시보드 편집
**************************************************************/

.page-dashboard-edit .ddp-box-widget .ddp-top-control .ddp-ui-buttons .ddp-box-btn2:hover .ddp-box-info.ddp-limitation {
  right: -20px;
}

/**************************************************************
  Page : 대시보드 열람
**************************************************************/

.page-dashboard .ddp-box-widget.ddp-popup-show:hover,
.ddp-box-widget.ddp-active.ddp-popup-show {
  z-index: initial;
}

.page-dashboard .ddp-box-widget {
  .popup-chart-data {
    display: block !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 200;
  }

  .ddp-top-control .ddp-ui-buttons {
    em.ddp-icon-widget-info2:before {
      width: 15px;
      height: 14px;
      margin: -7px 0 0 -8px;
      background-position: left -244px;
    }

    .ddp-box-btn2 {
      &:hover em.ddp-icon-widget-info2:before {
        background-position: -16px -244px;
      }

      .ddp-box-info.ddp-limitation {
        strong {
          display: block;
        }

        .ddp-total {
          display: block;
          padding-bottom: 4px;
        }
      }
    }
  }
}

/**************************************************************
  Page : 대시보드 열람 (툴팁)
**************************************************************/

/**************************************************************
  popup : 02대시보드_01멀티데이터소스_01추가_2
**************************************************************/

.page-multidata .ddp-box-resultdata .ddp-type-top-option {
  .ddp-part {
    float: left;
    position: relative;
    padding: 8px 14px;
  }

  .ddp-link-unlink {
    color: #90969f;
    font-size: 13px;

    &:hover {
      color: #4b515b;
    }

    &:before {
      display: inline-block;
      width: 15px;
      height: 10px;
      margin-right: 3px;
      background: url(/assets/bi/images/icon_unlink.png) no-repeat;
      content: '';
    }

    &:hover:before {
      background-position-x: -16px;
    }
  }
}

/**************************************************************
  Page : 데이터소스 상세
**************************************************************/

.ddp-wrap-info-detail {
  padding: 0 80px;
}

.ddp-ui-info-detail {
  .ddp-label-detail {
    color: #4e5368;
    font-size: 13px;
    font-weight: bold;

    .ddp-ui-buttons {
      padding: 4px 0 0 0;
    }

    .ddp-btn-box-edit {
      display: inline-block;
      padding: 2px 5px 3px 5px;
      border-radius: 2px;
      background-color: rgba(231, 231, 234, 0.5);
      font-size: 12px;
      color: #4b515b;
      font-weight: normal;
      font-style: italic;
    }
  }

  padding: 30px 0 0 0;

  table.ddp-table-detail {
    margin-top: 9px;
  }

  .ddp-label-detail.type-inline {
    float: left;
    display: inline-block;
    width: 135px;
  }

  .wrap-info-table {
    display: block;
    height: 240px;
    overflow: auto;
    border-radius: 3px;
    border: 1px solid #d0d1d8;
    box-sizing: border-box;

    .ddp-table-form tr th {
      border: none;
      text-align: left;
    }

    table.ddp-table-type2 {
      border-bottom: none;

      tbody tr > td {
        padding: 7px 10px 7px 10px;
        word-break: break-all;

        .ddp-btn-box-edit {
          display: inline-block;
          padding: 3px 5px 4px 5px;
          border-radius: 2px;
          background-color: rgba(231, 231, 234, 0.5);
          color: #4b515b;
          font-size: 12px;
          font-style: italic;
        }
      }

      &.type-cursor tr {
        td {
          cursor: pointer;

          .ddp-icon-view {
            display: none;
            width: 8px;
            height: 13px;
            background: url(/assets/bi/images/data_arrow.png) no-repeat;
            background-position: left -14px;
            transform: rotate(180deg);
          }
        }

        &.ddp-selected td .ddp-icon-view,
        &:hover td .ddp-icon-view {
          display: block;
        }

        &.ddp-selected td,
        &:hover td {
          background-color: #f2f1f8;
          border-top: 1px solid #9ca2cc;
          border-bottom: 1px solid #9ca2cc;
        }

        &.ddp-selected td,
        &:hover td {
          padding: 6px 10px 6px 10px;
        }

        &.ddp-selected td:first-of-type,
        &:hover td:first-of-type {
          border-left: 1px solid #9ca2cc;
          padding-left: 9px;
        }

        &.ddp-selected td:last-of-type,
        &:hover td:last-of-type {
          border-right: 1px solid #9ca2cc;
          padding-right: 9px;
        }
      }
    }
  }

  .box-graph {
    display: block;
    height: 110px;
    border: 1px solid #d0d1d8;
    border-radius: 3px;
    overflow: hidden;
  }

  .ddp-data-num {
    &:first-of-type {
      margin-left: 15px;
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    position: relative;
    padding-left: 7px;
    margin-left: 7px;
    font-weight: normal;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      border-left: 1px solid #4b515b;
      content: '';
    }
  }

  .wrap-data-detail {
    display: flex;
    height: 452px;
    padding-top: 10px;
    margin-bottom: 40px;

    .wrap-info-table.type-left {
      height: 100%;
      min-width: 315px;
      width: 315px;
      overflow: auto;
    }

    .wrap-info-table-detail {
      flex: auto;
      padding: 15px 20px;
      height: 100%;
      border: 1px solid #d0d1d8;
      box-sizing: border-box;
      overflow: auto;

      .data-interval {
        font-size: 16px;
        color: #4b515b;
        font-weight: bold;
      }

      .wrap-info-table {
        margin-top: 12px;
        height: auto;
      }
    }
  }
}

/**************************************************************
  Page : 05_매니지먼트_00데이터저장소_02데이터소스상세
**************************************************************/

/* 상태 */

.ddp-ui-status-type {
  float: left;
  margin-right: 62px;

  .ddp-top-status .ddp-link-detail {
    margin-left: 6px;
    color: #90969f;
    font-size: 12px;
    text-decoration: underline;
    font-style: italic;

    &:hover {
      color: #4b515b;
    }
  }

  .ddp-box-status {
    margin-top: 5px;
    background-color: #f6f6f7;
  }
}

.ddp-box-status {
  float: left;
  padding: 10px 10px;
  background-color: #f6f6f7;
}

.ddp-list-status {
  position: relative;
  overflow: hidden;

  .ddp-status {
    width: 130px;
    position: relative;
    float: left;
    padding: 0 5px;
    min-width: 80px;
    text-align: center;
    box-sizing: border-box;

    &:last-of-type {
      min-width: auto;
      padding-right: 0;
    }

    .ddp-num {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 50%;
      border: 2px solid #c1c3cb;
      text-align: center;
      color: #b7b9c3;
      font-size: 13px;
      font-weight: bold;
      box-sizing: Border-box;
      background-color: #fff;
      z-index: 1;
    }

    .ddp-txt-status {
      display: block;
      padding: 5px 0 0 0;
      white-space: nowrap;
      color: #4b515b;
      font-size: 12px;
    }

    &.ddp-finish {
      .ddp-num {
        position: relative;
        background-color: #4b515b;
        color: #fff;
        border-color: #4b515b;
      }

      &:after {
      }
    }

    + .ddp-status {
      position: relative;
    }

    .ddp-line {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 10px;
      right: -70px;
      height: 3px;
      background-color: #4b515b;
      content: '';

      &.type-dotted {
        background: none;
        background: url(/assets/bi/images/bg_status_ing.png) repeat-x;
      }

      &.type-finish {
        background: #d0d1d8;
      }
    }

    &.ddp-ing {
      .ddp-num {
        color: #4b515b;
        border-color: #4b515b;
      }

      .ddp-txt-status {
        color: #b7b9c3;
      }
    }

    &.ddp-complete .ddp-txt-status {
      color: #b7b9c3;
    }

    &.ddp-fail {
      &:before {
        background-color: #4b515b;
      }

      .ddp-num {
        border-color: #dc494f;
        background-color: #dc494f;
        color: #fff;
      }
    }
  }
}

.page-datasource-detail {
  .ddp-ui-status-type {
    width: 100%;

    .ddp-top-status {
      width: 100%;
      position: relative;
      padding-bottom: 10px;

      .ddp-wrap-log {
        display: none;
        position: relative;
        height: 414px;
        padding: 15px;
        background-color: #35393f;
        clear: Both;
        color: #b6b9c2;
        font-size: 12px;
        line-height: 18px;
        box-sizing: border-box;
        overflow: auto;

        a.ddp-btn-popup-view {
          position: absolute;
          top: 11px;
          right: 12px;
          padding: 7px 10px;
          border-radius: 3px;
          background-color: #4b515b;
          color: #d0d1d8;
          font-size: 13px;

          .ddp-icon-popup-view {
            display: Inline-block;
            width: 13px;
            height: 12px;
            margin-right: 5px;
            background: url(/assets/bi/images/icon_window.png) no-repeat;
            background-position: left -11px;
          }
        }
      }

      .ddp-btn-folding {
        &.ddp-selected {
          + .ddp-wrap-log {
            display: block;
          }

          background-color: #35393f;
          border: 1px solid #35393f;
          color: #fff;
        }

        display: inline-block;
        position: relative;
        padding: 3px 20px 4px 7px;
        margin-left: 10px;
        border: 1px solid #d0d1d8;
        border-radius: 2px;
        color: #9ba0a8;
        font-size: 12px;
        box-sizing: border-box;

        &:after {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 7px;
          margin-top: -2px;
          width: 7px;
          height: 4px;
          background: url('/assets/bi/images/icon_select.png') no-repeat;
          background-position: -8px top;
          content: '';
        }

        &.ddp-selected:after {
          background-position: -32px top;
          transform: rotate(180deg);
        }
      }
    }
  }

  .ddp-wrap-link-detail {
    display: inline-block;
    margin-top: 3px;
    text-align: left;

    .ddp-wrap-link-detail {
      position: Relative;
      top: 0;
      left: 0;
      right: 0;
      text-align: left;
    }

    .ddp-box-layout4 {
      right: inherit;
      left: 0;
    }
  }

  .ddp-box-histogram .ddp-box-graph .ddp-historam-tooltip {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 9px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 13px;
  }

  table.ddp-table-detail2 tr td .ddp-info {
    color: #b7b9c2;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 3px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -42px -16px;
      content: '';
      vertical-align: middle;
    }
  }
}

/************************************************************************
	PAGE : 대시보드 생성 팝업
***************************************************************************/

.ddp-popup-dashboard {
  position: relative;
  width: 1192px;
  margin: auto;
  padding-top: 1px;
  text-align: left;

  .ddp-wrap-grid {
    &.ddp-selected {
      margin-right: 300px;
    }

    .ddp-box-viewtable {
      min-height: 425px;
      box-sizing: border-box;
    }
  }
}

.ddp-type-top-option {
  padding-bottom: 0px;

  .ddp-top-title {
    line-height: 30px;
    font-size: 13px;
    color: #90969f;
    font-weight: bold;
  }

  .ddp-ui-rightoption {
    float: right;

    .ddp-btn-box-close {
      display: inline-block;
      position: relative;
      float: left;
      width: 33px;
      height: 32px;
      border-left: 1px solid #d7d8dd;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        width: 16px;
        height: 16px;
        background: url(/assets/bi/images/btn_close.png) no-repeat;
        content: '';
      }
    }

    .ddp-wrap-edit .ddp-label-checkbox {
      top: 0;
    }

    .ddp-label-checkbox {
      position: relative;
      top: 8px;
      float: left;

      .ddp-txt-checkbox {
        color: #b7b9c2;
      }

      input[type='checkbox']:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
        color: #4c515a;
      }
    }

    .ddp-wrap-iconbuttons {
      float: left;
      position: relative;
      top: 4px;

      em[class*='ddp-icon-widget-'] {
        position: relative;
        float: left;
        margin-left: 9px;

        .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
          right: 19px;
        }
      }
    }

    a.ddp-link-public {
      float: left;
      padding: 5px 16px;
      color: #90969f;
      font-size: 14px;

      em.ddp-icon-global-s {
        margin-right: 5px;
        vertical-align: middle;
      }

      &.ddp-selected {
        background-color: #e7e7ea;
        color: #4b515b;
        border-radius: 2px;

        em.ddp-icon-global-s {
          background-position: -14px -18px;
        }
      }
    }

    .ddp-form-label2 {
      float: left;
      margin-left: 28px;
      color: #b7b9c2;
    }

    .ddp-btn-selection.type-option {
      margin-left: 6px;
      color: #90969f;
    }
  }

  .ddp-form-label2 .ddp-label-type {
    font-size: 13px;
  }

  .ddp-ui-rightoption {
    .ddp-switch {
      float: left;
      position: relative;
      top: 9px;
      margin-left: 28px;
    }

    .ddp-link-toggle.ddp-fleft {
      margin-left: 28px;
    }

    .ddp-checkoption {
      float: left;
      position: relative;
      top: 7px;
    }
  }

  .ddp-wrap-dropdown {
    width: 168px;
  }

  ul.ddp-ui-tab {
    border-top: 1px solid #fff;
  }
}

.ddp-ui-bottomoption {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-top: 3px;

  span.ddp-data-type {
    color: #b7b9c2;
    font-size: 12px;
  }
}

.ddp-box-grid-detail {
  position: absolute;
  top: 44px;
  right: 0;
  bottom: 1px;
  width: 300px;
  border: 1px solid #d5d6db;
  overflow-y: auto;
  background-color: #fff;
  box-sizing: border-box;

  &.ddp-type {
    top: 0;
  }

  .ddp-btn-close-s {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 15px;
    width: 16px;
    height: 16px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    background-position: left -84px;
    z-index: 1;

    &:hover {
      background-position: -17px -84px;
    }
  }

  .ddp-wrap-datadetail {
    padding: 0 0 20px 0;
  }

  &.ddp-type2 .ddp-wrap-datadetail {
    margin: 0 12px;

    .ddp-dl-detail dt {
      padding-left: 2px;
    }

    + .ddp-wrap-datadetail {
      padding-top: 20px;
      border-top: 1px solid #e7e7ea;
    }
  }
}

dl.ddp-dl-detail dd {
  .ddp-data-none {
    color: rgba(208, 209, 216);
    font-size: 13px;
  }

  br {
    display: block;
    margin-top: 5px;
  }
}

.ddp-box-grid-detail {
  .ddp-wrap-datadetail {
    .ddp-dl-detail {
      width: 100%;
      table-layout: fixed;
      overflow: hidden;

      dt {
        float: left;
        padding: 5px 8px 5px 14px;
        width: 90px;
        color: #b7bac1;
        font-size: 13px;
        font-weight: normal;
        box-sizing: border-box;
      }

      th {
        float: left;
        padding: 5px 8px 5px 14px;
        width: 90px;
        color: #b7bac1;
        font-size: 13px;
        font-weight: normal;
        box-sizing: border-box;
        width: auto;
      }
    }

    &.ddp-size dl.ddp-dl-detail dt {
      width: 120px;
    }

    dl.ddp-dl-detail dd {
      display: block;
      padding: 5px 5px;
      color: #4b515b;
      font-size: 13px;
      overflow: hidden;

      .ddp-dl-sub-detail {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #b7bac1;
      }
    }

    .ddp-dl-detail td {
      padding: 5px 5px;
      color: #4b515b;
      font-size: 13px;
      overflow: hidden;
    }

    dl.ddp-dl-detail dd {
      span.ddp-txt-det {
        display: block;

        + span.ddp-txt-det {
          padding-top: 5px;
        }
      }

      .ddp-data-det {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        overflow: hidden;
      }
    }

    .ddp-dl-detail td .ddp-data-det {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;

      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      overflow: hidden;
    }

    dl.ddp-dl-detail dd .ddp-tags {
      max-width: 110px;
      padding: 6px;
      border-radius: 3px;
      background-color: #f2f1f8;
      color: $primary;
      font-size: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      + .ddp-tags {
        margin-left: 5px;
      }
    }
  }

  &.type-detail {
    .ddp-data-detailinfo .ddp-data-name {
      padding: 0;
    }

    .ddp-detail-button {
      padding: 20px 17px 0 0;
      float: right;
      position: relative;
      z-index: 1;

      .ddp-btn-close-s {
        position: relative;
        top: 0;
        right: 0;
        float: left;
      }

      .ddp-ui-detail {
        position: relative;
        top: 0;
        right: 0;
        margin-right: 10px;
        float: left;
      }
    }

    .ddp-ui-detail {
      .ddp-icon-detail {
        display: inline-block;
        width: 17px;
        height: 18px;
        background: url(/assets/bi/images/icon_detail2.png) no-repeat;
      }

      .ddp-ui-tooltip-info {
        position: absolute;
        top: 100%;
        right: -10px;
        left: inherit;
        margin-top: 6px;
      }

      &:hover .ddp-ui-tooltip-info {
        display: block;
      }

      .ddp-ui-tooltip-info em.ddp-icon-view-top {
        right: 17px;
        left: inherit;
      }
    }

    .ddp-data-detailinfo {
      display: block;
      overflow: hidden;
    }
  }

  .ddp-data-detailinfo {
    position: relative;
    padding: 15px 12px;

    .ddp-ui-detail {
      position: absolute;
      top: 20px;
      right: 46px;
      cursor: pointer;

      .ddp-icon-detail {
        display: inline-block;
        width: 17px;
        height: 18px;
        background: url(/assets/bi/images/icon_detail2.png) no-repeat;
      }

      .ddp-ui-tooltip-info {
        position: absolute;
        top: 100%;
        right: -10px;
        left: inherit;
        margin-top: 6px;
      }

      &:hover .ddp-ui-tooltip-info {
        display: block;
      }

      .ddp-ui-tooltip-info em.ddp-icon-view-top {
        right: 17px;
        left: inherit;
      }
    }
  }

  span.ddp-data-name {
    display: block;
    width: 100%;
    padding-right: 60px;
    color: #35393f;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    box-sizing: border-box;

    span.ddp-sub {
      font-weight: 300;
      color: #35393f;
      font-size: 16px;
    }
  }

  .ddp-data-listview {
    border-top: 1px solid #e7e7ea;
    border-bottom: 1px solid #e7e7ea;
  }
}

.ddp-box-popupcontents {
  position: relative;
  width: 800px;
  margin: 0 auto;
  text-align: left;
}

.ddp-box-popupcontents2 {
  width: 460px;
  margin: 20px auto;
  padding-bottom: 24px;
  text-align: left;

  [class*='ddp-wrap-edit3'] {
    margin-top: 20px;
  }
}

.ddp-wrap-boxtype {
  padding: 25px 50px;
  width: 600px;
  margin-left: -70px;
  min-height: 200px;
  max-height: 300px;
  background-color: #fafafa;
  overflow-y: auto;
  box-sizing: border-box;

  &.ddp-scroll-none {
    max-height: inherit;
    overflow: inherit;
  }

  &.ddp-box-size {
    height: 150px;
  }

  .ddp-wrap-boxdata {
    width: 100%;
    padding-bottom: 5px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .ddp-label-type {
    float: left;
    width: 150px;
    margin-right: 10px;
    font-size: 13px;
    color: #b7bac1;
    line-height: 1.5em;
    box-sizing: border-box;
    overflow: hidden;
  }

  .ddp-box-form {
    display: block;
    overflow: hidden;

    .ddp-data-txt {
      display: block;
      padding-bottom: 5px;
      font-size: 13px;
      color: #4b515b;
      line-height: 1.5em;
    }

    ul.ddp-list-sub li {
      position: relative;
      padding: 0 0 5px 10px;
      color: #b7bac1;
      font-size: 12px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b7bac1;
      }
    }
  }

  table.ddp-table-boxtype {
    width: 100%;

    tr th {
      min-width: 150px;
    }
  }
}

.ddp-box-form ul.ddp-list-set li {
  padding: 7px 0;
  color: #4b515b;
  font-size: 13px;

  &:first-of-type {
    padding-top: 0;
  }
}

.ddp-box-fit {
  padding-top: 10px;
  margin-bottom: -15px;
  text-align: right;

  .ddp-wrap-form {
    display: inline-block;
    width: 41%;
    margin-left: 15px;

    &:first-of-type {
      margin-left: 0;
    }

    span.ddp-label-form {
      position: relative;
      top: 5px;
      margin-right: 5px;
      float: left;
      color: #90969f;
      font-size: 12px;
    }

    .ddp-box-option-input {
      display: block;
      overflow: hidden;
    }
  }
}

/***********************************************************************************************************
	PAGE : 대시보드 생성 팝업 (조인)
************************************************************************************************************/

.ddp-box-join-option {
  height: 259px;
  padding: 19px 11px;
  background-color: #ececee;
  text-align: left;
  box-sizing: border-box;
  overflow-y: auto;

  a.ddp-data-name {
    display: block;
    float: left;
    position: relative;
    padding: 10px 21px 9px 22px;
    width: 270px;
    border-radius: 4px;
    background-color: #4b515b;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    .ddp-icon-database-w {
      margin-right: 8px;
    }

    em.ddp-icon-view {
      display: inline-block;
      width: 4px;
      height: 7px;
      position: absolute;
      top: 50%;
      right: 13px;
      margin-top: -4px;
      background: url(/assets/bi/images/icon_subview.png) no-repeat;
      background-position: left -10px;
    }
  }

  .ddp-view-join {
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.ddp-box-lookup {
  float: left;
  margin-left: -270px;
  padding-left: 294px;

  ul.ddp-list-lookup li {
    position: relative;
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: -24px;
        width: 24px;
        border-bottom: 1px solid #374050;
      }
    }

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: -31px;
      left: 16px;
      height: 36px;
      margin-left: -2px;
      border-left: 1px solid #374050;
      z-index: 1;
    }

    &:first-of-type:after {
      display: none;
    }

    .ddp-ui-lookup {
      display: inline-block;
      padding-left: 53px;
      position: relative;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 53px;
        border-bottom: 1px solid #374050;
      }

      &:last-of-type {
        padding-left: 75px;

        &:before {
          width: 75px;
        }
      }
    }

    em {
      &[class*='ddp-icon-join-'] {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 28px;
        height: 18px;
        margin: -9px 0 0 -14px;
        background: url(/assets/bi/images/icon_join_s.png) no-repeat;
        z-index: 1;
      }

      &.ddp-icon-join-right {
        background-position: left -19px;
      }

      &.ddp-icon-join-inner {
        background-position: left -38px;
      }

      &.ddp-icon-join-outer {
        background-position: left -57px;
      }
    }

    a.ddp-ui-joinicon {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -4px;
      width: 34px;
      height: 30px;
      margin-top: -15px;

      &:hover {
        border-radius: 3px;
        background-color: #d7d8dd;
      }
    }

    .ddp-ui-lookup:last-of-type a.ddp-ui-joinicon {
      left: 22px;
    }

    a.ddp-link-joinname {
      display: block;
      position: relative;
      width: 270px;
      padding: 9px 13px 9px 13px;
      color: #4b515b;
      font-size: 14px;
      border-radius: 3px;
      background-color: #dcdde2;
      box-sizing: border-box;

      &:hover {
        padding-right: 50px;
      }
    }

    .ddp-ui-lookup:last-of-type a.ddp-link-joinname:before {
      width: 55px;
      left: -55px;
    }

    a.ddp-link-joinname {
      em.ddp-icon-database2 {
        position: relative;
        top: 2px;
        float: left;
        width: 12px;
        height: 14px;
        margin-right: 7px;
        background: url(/assets/bi/images/icon_database.png) no-repeat;
        background-position: -13px top;
        vertical-align: middle;
      }

      span.ddp-data-joinname {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        box-sizing: border-box;
        overflow: hidden;
      }

      .ddp-btn-edit {
        display: none;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -10px;
      }

      &:hover .ddp-btn-edit {
        display: block;
      }
    }

    &.ddp-list-add {
      &:after {
        display: none;
      }

      &:before {
        display: inline-block;
        position: absolute;
        left: 13px;
        bottom: 14px;
        content: '';
        width: 34px;
        height: 38px;
        background: url(/assets/bi/images/bg_joinline.png) no-repeat;
      }

      padding-left: 53px;

      &:first-of-type {
        margin-left: -74px;

        &:before {
          display: none;
        }

        a.ddp-link-add-data {
          margin-top: 0;
        }
      }
    }

    a.ddp-link-add-data {
      display: inline-block;
      padding: 9px 13px 9px 13px;
      margin-top: -6px;
      color: #b7b9c2;
      font-size: 14px;

      &:hover {
        color: #4b515b;
      }

      .ddp-icon-add {
        margin-right: 5px;
      }

      &:hover .ddp-icon-add {
        background-position: left top;
      }
    }

    .ddp-ui-add-data {
      display: inline-block;
      position: relative;
      padding-left: 74px;
      vertical-align: top;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 77px;
        border-top: 1px dotted #4b515b;
      }
    }
  }
}

.ddp-popup-join .ddp-box-resultdata {
  position: relative;
  height: 345px;
  border: 1px solid #b7b9c2;

  span.ddp-txt-title {
    float: left;
    padding: 16px 17px 15px 17px;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
  }

  .ddp-wrap-grid-option .ddp-part-right {
    margin-top: -9px;
  }

  .ddp-ui-grid-form {
    position: absolute;
    top: 47px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}

/**********************************************************************************
	PAGE : 대시보드 그리드 미리보기 팝업
**********************************************************************************/

.ddp-pop-preview {
  position: fixed;
  left: 424px;
  right: 124px;
  bottom: 112px;
  top: 155px;
  background-color: #fff;
  z-index: 126;

  &.ddp-size-m {
    left: 50%;
    right: inherit;
    bottom: inherit;
    top: 50%;
    width: 700px;
    height: 500px;
    margin: -250px 0 0 -350px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(208, 209, 216, 0.5);
  }

  &.ddp-pop-database em.ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 66px;
    left: -8px;
    width: 8px;
    height: 12px;
    background: url(/assets/bi/images/icon_dataview.png) no-repeat;
    background-position: left -27px;
    z-index: 3;
  }

  .ddp-ui-top {
    position: relative;
    height: 38px;
    background-color: #4b515b;
    border: 1px solid #4b515b;

    .ddp-data-name {
      position: relative;
      padding: 10px 30px 10px 15px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;

      &.ddp-cut {
        padding-right: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .ddp-txt-detail {
        display: inline-block;
        margin-left: 5px;
        font-weight: normal;
        font-size: 13px;
      }

      em.ddp-icon-database-s2 {
        margin-right: 5px;
      }

      .ddp-wrap-info {
        display: inline-block;

        span.ddp-data-info {
          display: inline-block;
          position: relative;
          padding: 0 15px;
          color: #d0d1d8;
          font-size: 13px;
          font-weight: normal;

          &:after {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            height: 12px;
            margin-top: -6px;
            border-right: 1px solid #d0d1d8;
          }

          &:first-of-type:after {
            display: none;
          }
        }
      }

      .ddp-link-top {
        float: right;
        margin-right: 38px;
        color: #d0d1d8;
        font-size: 13px;
        text-decoration: underline;
        font-weight: normal;

        &:hover {
          color: #fff;
        }
      }

      em.ddp-icon-scheme {
        position: relative;
        margin-right: 5px;
      }

      .ddp-data-info {
        display: inline-block;
        margin-left: 10px;
        color: #90969f;
        font-size: 12px;
        font-weight: normal;

        .ddp-icon-info {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 4px;
          background: url(/assets/bi/images/icon_info.png) no-repeat;
          background-position: left -30px;
        }
      }
    }

    em {
      &.ddp-icon-database {
        display: inline-block;
        width: 12px;
        height: 14px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_database.png) no-repeat;
        background-position: -26px top;
        vertical-align: middle;
      }

      &.ddp-icon-database2 {
        display: inline-block;
        width: 12px;
        height: 14px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_database.png) no-repeat;
        background-position: -26px top;
        vertical-align: middle;
        background-position: left top;
      }

      &.ddp-btn-window {
        display: inline-block;
        position: absolute;
        top: 11px;
        right: 39px;
        width: 12px;
        height: 11px;
        background: url(/assets/bi/images/icon_window2.png) no-repeat;
      }

      &.ddp-btn-popup-close {
        display: inline-block;
        width: 9px;
        height: 9px;
        position: absolute;
        top: 50%;
        right: 14px;
        margin-top: -5px;
        background: url(/assets/bi/images/btn_sclose.png) no-repeat;
        background-position: left -18px;
        cursor: pointer;
      }
    }

    .ddp-btn-window {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 34px;
      margin-top: -6px;
      width: 12px;
      height: 11px;
      background: url(/assets/bi/images/icon_window2.png) no-repeat;
    }

    .ddp-wrap-source-name {
      padding: 3px 8px;

      .ddp-data-in {
        float: left;
        margin-right: 7px;
      }

      .ddp-txt-description {
        display: block;
        padding: 7px 0;
        color: #b7b9c2;
        font-size: 13px;
        overflow: hidden;
      }
    }
  }

  .ddp-ui-grid {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #4b515b;
  }
}

.type-scroll {
  padding: 50px 90px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: rgba(208, 209, 216, 0.5);

  .ddp-pop-preview {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin-right: 90px;
    min-width: 850px;

    &:before {
      display: none;
    }
  }
}

/*.type-scroll .ddp-pop-preview:before {display:inline-block; content:''; position:fixed; left:0; right:0; bottom:0; top:0; background-color:rgba(208,209,216,0.5);}*/

.ddp-ui-preview-contents {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #4b515b;
  background-color: #fff;
  overflow: auto;

  &.type-relative {
    position: Relative;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  ul.ddp-ui-tab li.ddp-selected a:after {
    border-bottom: none;
  }
}

.ddp-pop-preview.ddp-type {
  position: fixed;
  top: 50px;
  left: 90px;
  right: 90px;
  bottom: 52px;
}

.ddp-ui-preview-contents {
  &.ddp-add {
    padding-bottom: 38px;

    .ddp-box-add-link {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .ddp-table-scroll {
      height: 100%;
      overflow: auto;
    }
  }

  .ddp-type-top-option {
    position: relative;
    z-index: 55;

    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    ul.ddp-ui-tab {
      height: 32px;
      overflow: hidden;
    }

    .ddp-ui-rightoption {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.ddp-query {
    padding: 20px;
  }

  &.ddp-preview-group {
    .ddp-col-table {
      margin-top: -46px;
      padding: 46px 0 0 0;
      height: 100%;
      box-sizing: Border-box;

      .ddp-ui-gridbody2 {
        top: 71px;
      }
    }

    .ddpƒ√-group-num {
      padding: 15px 29px;
      font-size: 13px;
      color: #90969f;
      font-weight: 400;
    }

    .ddp-group-num strong {
      color: $primary;
    }

    .ddp-part {
      height: 100%;
    }
  }

  table {
    &.ddp-table-type2 tbody tr:hover td {
      background-color: #f2f1f9;
    }

    &.ddp-table-form tbody tr td .ddp-txt-long {
      &.ddp-type-button {
        display: block;

        .ddp-txt-name {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-top: 6px;
        }
      }

      font-size: 13px;
    }
  }

  .ddp-table-header {
    padding-right: 18px;
  }

  .ddp-table-tbody {
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    border-top: 1px solid #e9e9ec;
  }

  .ddp-col-table {
    position: relative;
    height: 100%;
    border-left: 1px solid #ddd;

    &:first-of-type {
      border-left: none;
    }

    .ddp-ui-gridbody2 {
      top: 25px;
      bottom: 21px;
    }
  }

  &.ddp-more-table {
    .ddp-ui-gridbody2 {
      bottom: 44px;
    }

    .ddp-box-add-link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 13px 0;
      border-top: 1px solid #b7b9c2;
    }
  }
}

/***********************************************************************************************************
        PAGE : 대시보드 생성 팝업 (join popup)
************************************************************************************************************/

.ddp-wrap-source-name .ddp-data-in {
  display: inline-block;
  position: relative;
  width: 240px;
  padding: 8px 23px 7px 10px;
  background-color: #303338;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;

  span.ddp-txt-namesub {
    display: block;
    color: #b7b9c2;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
  }

  .ddp-icon-database3 {
    float: left;
    margin-right: 9px;
  }

  .ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 9px;
    width: 7px;
    height: 4px;
    margin-top: -2px;
    background: url('/assets/bi/images/icon_select.png') no-repeat;
    background-position: -8px top;
  }

  .ddp-box-layout4 {
    display: none;
  }

  &.ddp-selected {
    .ddp-icon-view {
      transform: rotate(180deg);
    }

    .ddp-box-layout4 {
      display: block;
      padding: 10px 0 36px 0;
    }
  }
}

.ddp-wrap-chart-lnb .ddp-wrap-source-name .ddp-data-in.ddp-selected .ddp-box-layout4 .ddp-form-search {
  width: 200px;
}

.ddp-wrap-source-name {
  .ddp-data-in .ddp-box-layout4 {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: auto;
    z-index: 60;
    font-weight: normal;

    .ddp-data-name {
      font-weight: normal;
    }
  }

  &.ddp-white .ddp-data-in {
    background-color: #f6f6f7;

    .ddp-icon-database3 {
      background-position-x: -26px;
    }

    .ddp-txt-namesub {
      color: #4b515b;
    }
  }

  .ddp-txt {
    display: inline-block;
    padding: 8px 0 7px 0;
    color: #4b515b;
    font-size: 12px;

    .ddp-icon-database3 {
      margin-right: 9px;
      background-position-x: -26px;
    }
  }

  .ddp-data-in .ddp-box-layout4.ddp-datasource {
    padding-bottom: 0;

    .ddp-btn-more {
      padding-bottom: 20px;
    }

    .ddp-box-search {
      padding: 0 20px;

      .ddp-form-search {
        margin: 0;
        width: 100%;
      }
    }
  }
}

/***********************************************************************************************************
            PAGE : 대시보드 생성 팝업 (join popup)
************************************************************************************************************/

.ddp-wrap-join-popup {
  display: none;
  position: absolute;
  top: 48px;
  left: 177px;
  width: 304px;
  color: #fff;
  border-radius: 3px;
  background-color: #3c3f40;
  z-index: 120;

  em.ddp-icon-view {
    display: block;
    position: absolute;
    top: -7px;
    left: 13px;
    width: 5px;
    height: 10px;
    background: url(/assets/bi/images/icon_dataview.png) no-repeat;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .ddp-ui-popup-header {
    position: relative;
    padding: 9px;
    color: #a4aab0;
    font-size: 13px;
    font-weight: bold;

    a.ddp-icon-close2 {
      display: block;
      position: absolute;
      top: 50%;
      right: 9px;
      width: 9px;
      height: 9px;
      margin-top: -4px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: left -18px;
    }
  }

  ul.ddp-list-join-select {
    padding: 5px 7px;
    margin-bottom: 10px;
    overflow: hidden;

    li {
      float: left;
      width: 25%;
      height: 50px;
      padding: 0 2px;
      box-sizing: border-box;

      a {
        display: block;
        position: relative;
        padding: 30px 0 0 0;
        color: #fff;
        font-size: 10px;
        text-align: center;
        border-radius: 3px;
      }

      &.ddp-selected a {
        color: $primary;
        background-color: #fff;
      }

      a {
        em {
          &.ddp-icon-join-inner,
          &.ddp-icon-join-left,
          &.ddp-icon-join-right,
          &.ddp-icon-join-all {
            display: inline-block;
            position: absolute;
            top: 10px;
            left: 50%;
            width: 34px;
            height: 19px;
            margin-left: -17px;
            background: url(/assets/bi/images/icon_join.png) no-repeat;
          }

          &.ddp-icon-join-left {
            background-position: -35px 0;
          }

          &.ddp-icon-join-right {
            background-position: -70px 0;
          }

          &.ddp-icon-join-all {
            background-position: -105px 0;
          }
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);

          em {
            &.ddp-icon-join-inner,
            &.ddp-icon-join-left,
            &.ddp-icon-join-right,
            &.ddp-icon-join-all {
              opacity: 0.8;
            }
          }
        }
      }

      &.ddp-selected a {
        &:hover {
          background-color: #fff;
          opacity: 1;
        }

        em {
          &.ddp-icon-join-inner {
            background-position: 0 -20px;
          }

          &.ddp-icon-join-left {
            background-position: -35px -20px;
          }

          &.ddp-icon-join-right {
            background-position: -70px -20px;
          }

          &.ddp-icon-join-all {
            background-position: -105px -20px;
          }
        }

        &:hover em {
          &.ddp-icon-join-inner,
          &.ddp-icon-join-left,
          &.ddp-icon-join-right,
          &.ddp-icon-join-all {
            opacity: 1;
          }
        }
      }

      &.ddp-disabled a {
        cursor: default;
        opacity: 0.5;

        &:hover {
          background: none;

          em {
            &.ddp-icon-join-inner,
            &.ddp-icon-join-left,
            &.ddp-icon-join-right,
            &.ddp-icon-join-all {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .ddp-wrap-column-select {
    float: left;
    width: 100%;
    padding: 9px 9px 9px 9px;
    box-sizing: border-box;
    border-bottom: 1px solid #6d6f71;

    .ddp-ui-data-name {
      padding-bottom: 9px;
      overflow: hidden;

      span.ddp-data-name {
        float: left;
        width: 50%;
        color: #a4aab0;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.ddp-wrap-column {
  max-height: 160px;
  margin-right: -9px;
  overflow-y: auto;
}

.ddp-wrap-columnin {
  position: relative;
  padding-right: 35px;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: Both;
  }

  a.ddp-icon-del {
    display: none;
    width: 11px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -6px;
    background: url(/assets/bi/images/icon_edit.png) no-repeat;
    background-position: -13px top;

    &:hover {
      background-position: -13px -37px;
    }
  }

  &.ddp-finish {
    .ddp-ui-column .ddp-data-column span.ddp-data-value {
      color: #444;
    }

    a.ddp-icon-del {
      display: block;
    }
  }
}

.ddp-ui-column {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 2px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;

  .ddp-data-column {
    &.ddp-full {
      width: 100%;
    }

    position: relative;
    float: left;
    width: 45%;
    padding-right: 17px;
    box-sizing: border-box;
    cursor: pointer;
  }

  input.ddp-icon-join {
    float: left;
    width: 10%;
    height: 28px;
    border: none;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    color: #444;
    text-align: center;
    box-sizing: border-box;
  }

  .ddp-data-column {
    span.ddp-data-value {
      display: block;
      padding-left: 9px;
      color: #909090;
      line-height: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      cursor: pointer;
    }

    input.ddp-input-search {
      display: none;
    }

    em.ddp-icon-select {
      display: none;
      position: absolute;
      top: 50%;
      right: 7px;
      width: 7px;
      height: 4px;
      margin-top: -2px;
      background: url(/assets/bi/images/icon_selecview.png) no-repeat;
      background-position: left -7px;
      cursor: pointer;
    }

    ul.ddp-list-selectbox {
      display: none;
      position: absolute;
      top: 26px;
      left: -1px;
      right: -1px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-top: none;

      li a {
        display: block;
        padding: 7px 12px;
        color: #444;

        &:hover {
          background-color: #f6f6f7;
        }
      }
    }

    &.ddp-selected {
      box-shadow: 2px 3px 3px -1px #dcdfe1;
      z-index: 10;

      ul.ddp-list-selectbox {
        display: block;
        max-height: 100px;
        overflow-y: auto;
      }

      .ddp-data-value {
        color: #222;
      }
    }

    &:hover em.ddp-icon-select,
    &.ddp-selected em.ddp-icon-select {
      display: block;
    }

    select {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border: none;
      background: transparent;
      opacity: 0;
      cursor: pointer;
    }

    &.ddp-data-search input.ddp-input-search {
      display: block;
      width: 100%;
      padding: 5px 9px 6px 9px;
      border: none;
      box-sizing: border-box;
    }
  }

  &.ddp-ui-new {
    background: none;
    border: none;
    border-radius: 0;

    .ddp-data-column {
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

/**********************************************************************************
	PAGE : 대시보드 이동
**********************************************************************************/

.ddp-wrap-order {
  height: 100%;

  .ddp-ui-result {
    padding: 7px 0 7px 10px;
    border-top: 1px solid #d0d1d8;
    border-bottom: 1px solid #d0d1d8;

    span.ddp-data-result {
      position: relative;
      padding: 0 15px;
      font-size: 12px;
      color: #90969f;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 13px;
        margin-top: -7px;
        border-left: 1px solid #e7e7ea;
      }

      &:first-of-type {
        padding-left: 0;

        &:before {
          display: none;
        }
      }

      .ddp-icon-eyes2 {
        margin-right: 4px;
        vertical-align: middle;
      }

      &.ddp-selected {
        color: #444;

        .ddp-icon-eyes2 {
          background-position: -17px 0;
        }
      }
    }
  }
}

.ddp-wrap-order-setting {
  position: absolute;
  top: 151px;
  left: 0;
  right: 50px;
  bottom: 0;
  padding-left: 40px;
  box-sizing: border-box;
  overflow: auto;
}

.ddp-wrap-order .ddp-tree-number {
  margin-top: 3px;

  li {
    padding: 18px 0 17px 14px;
    width: 45px;
    height: 50px;
    box-sizing: border-box;
  }
}

ul.ddp-list-order {
  padding: 12px 0;

  li {
    display: table;
    padding: 4px 0;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f7;
    }

    .ddp-data-num {
      display: table-cell;
      width: 48px;
      text-align: center;
      vertical-align: middle;
    }

    .ddp-wrap-image {
      display: table-cell;
      position: relative;
      width: 82px;
      vertical-align: middle;

      .ddp-ui-image {
        display: inline-block;
        position: relative;
        width: 62px;
        height: 40px;
        border: 1px solid #ddd;

        &:hover:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(86, 93, 101, 0.1);
        }
      }

      .ddp-box-hover {
        display: none;
        position: absolute;
        top: 46px;
        left: 0;
        width: 258px;
        height: 168px;
        border: 1px solid #90969f;
        z-index: 1;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &:hover .ddp-box-hover {
        display: block;
      }
    }

    .ddp-ui-image img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .ddp-data-name {
      display: table-cell;
      width: 100%;
      color: #4b515b;
      font-size: 13px;
      vertical-align: middle;
      box-sizing: border-box;

      em {
        &[class*='ddp-icon-eyes'] {
          float: left;
          position: relative;
          top: 3px;
          margin-right: 9px;
        }

        &.ddp-icon-eyes2 {
          background-position: -17px 0;
        }
      }

      span.ddp-txt-name {
        display: block;
        overflow: hidden;
      }

      .ddp-chart-img {
        float: left;
      }
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 대시보드 (LNB)
************************************************************************************************************/

.ddp-lnb-dashboard {
  position: fixed;
  top: 55px;
  left: 0;
  bottom: 0;
  width: 289px;
  border-right: 2px solid #eaeaea;
  background-color: #feffff;
  box-sizing: border-box;

  &.ddp-close {
    width: 26px;
  }

  .ddp-ui-toptitle {
    position: relative;
    padding: 0 40px 0 55px;
    width: 289px;
    border-bottom: 1px solid #e7e7ea;
    border-right: 2px solid #eaeaea;
    box-sizing: border-box;
    background-color: #fff;

    em.ddp-icon-navi {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 47px;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 22px;
        margin-top: -8px;
        width: 17px;
        height: 16px;
        background: url(/assets/bi/images/btn_back.png) no-repeat;
        vertical-align: middle;
      }
    }

    span.ddp-data-name {
      display: block;
      padding: 13px 0 13px 0;
      width: 100%;
      color: #4b515b;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      box-sizing: border-box;

      em.ddp-box-tag {
        display: inline-block;
        position: relative;
        top: 2px;
        padding: 1px 2px;
        margin-right: 5px;
        border: 1px solid #90969f;
        border-radius: 2px;
        font-weight: bold;
        font-size: 10px;
        color: #565c65;
        vertical-align: middle;
        opacity: 0.8;
      }
    }

    .ddp-ui-more {
      position: absolute;
      top: 50%;
      right: 7px;
      margin-top: -10px;

      .ddp-ui-tooltip-info.ddp-down {
        right: -3px;

        em.ddp-icon-view-top {
          right: 13px;
        }
      }
    }
  }

  &.ddp-close .ddp-ui-toptitle .ddp-ui-more .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
    right: 13px;
    top: -2px;
    left: inherit;
    transform: rotate(90deg);
  }
}

.ddp-wrap-popup2.ddp-popup-lnbmore {
  display: none;
  position: fixed;
  top: 70px;
  left: 0;
  min-width: 217px;
  white-space: nowrap;
  z-index: 127;
}

.ddp-lnb-dashboard {
  .ddp-ui-toptitle .ddp-ui-more.ddp-selected {
    .ddp-wrap-popup2 {
      display: block;
    }

    .ddp-icon-more:before {
      background-position: 0 -4px;
    }

    .ddp-ui-tooltip-info {
      display: none;
    }
  }

  ul.ddp-list-tab2 li a em.ddp-icon-new-r {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: $primary;
    vertical-align: top;
  }

  .ddp-list-tab2 {
    padding-right: 53px;
  }

  .ddp-btn-folding {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 49px;
    width: 53px;
    height: 49px;

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 14px;
      margin-top: -7px;
      border-left: 1px solid #eaebed;
    }

    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -7px;
      content: '';
      width: 14px;
      height: 12px;
      background: url(/assets/bi/images/icon_folding2.png) no-repeat;
      background-position: -30px top;
    }

    &:hover:before {
      background-position: -45px top;
    }

    .ddp-ui-tooltip-info.ddp-down {
      top: 36px;
      right: 10px;
      text-align: center;
    }
  }

  &.ddp-close {
    .ddp-wrap-tab2 {
      display: none;
    }

    &:hover {
      z-index: 126;
    }

    .ddp-ui-toptitle {
      position: absolute;
      left: 0;
    }

    .ddp-btn-folding .ddp-ui-tooltip-info.ddp-down {
      position: fixed;
      top: 119px;
      right: auto;
      left: 30px;
      margin-top: 0;
      width: max-content;
      white-space: nowrap;
    }

    .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
      left: 0;
      transform: rotate(0deg);
      right: inherit;
      top: 50%;
      margin-top: -4px;
    }

    .ddp-btn-folding {
      width: inherit;
      height: inherit;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &:before {
        top: 71px;
        margin-top: 0;
        background-position: -30px -14px;
      }

      &:hover:before {
        background-position: -45px -14px;
      }
    }
  }
}

.ddp-layout-board .ddp-wrap-naviarea {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  z-index: 122;

  &.ddp-editnavi {
    background-color: #f7f7f8;
    border-bottom: 1px solid #e5e6e8;
  }
}

/***********************************************************************************************************
	PAGE : 워크북 대시보드 (CONTENTS)
************************************************************************************************************/

.ddp-ui-dash-contents {
  height: calc(100% - 80px);
  position: relative;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  z-index: 1 !important;

  &.ddp-tooltip {
    z-index: 199 !important;
    overflow: inherit;

    .ddp-ui-widget {
      z-index: 130;
    }
  }

  .ddp-loading-part {
    position: fixed;
    left: 289px;
    top: 102px;
    right: 0;
    bottom: 0;
    z-index: 126;
  }

  &.ddp-close {
    left: 26px;

    .ddp-wrap-naviarea {
    }
  }

  .ddp-wrap-naviarea {
    z-index: 126;
  }
}

.ddp-wrap-popup2 .ddp-ui-date {
  padding: 12px 13px;
  border-top: 1px solid #e7e7ea;

  span.ddp-data-date {
    display: block;
    color: #90969f;
    line-height: 18px;
    font-size: 12px;

    em {
      font-weight: normal;
    }
  }
}

.ddp-wrap-tab-dashboard {
  .ddp-ui-setting {
    position: relative;
    height: 33px;
    background-color: #f6f6f7;

    .ddp-wrap-selectbox {
      float: left;
      position: relative;
      margin-top: 9px;
      padding-left: 15px;
      color: #b7b9c2;
      cursor: pointer;
    }

    .ddp-data-selectbox {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .ddp-wrap-selectbox.ddp-selected .ddp-data-selectbox {
      opacity: 1;
    }

    .ddp-data-selectbox em.ddp-icon-database3 {
      margin-right: 5px;
      background-position: -39px -31px;
    }

    .ddp-wrap-selectbox {
      .ddp-wrap-popup2 {
        left: 0;
        right: inherit;
      }

      ul.ddp-list-popup {
        width: 170px;

        .ddp-label-checkbox {
          max-width: 100%;
          box-sizing: border-box;

          span.ddp-txt-checkbox {
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .ddp-ui-datalink {
      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: 19px;
        left: -10px;
        max-width: 160px;
        max-height: 150px;
        white-space: nowrap;
        overflow-y: auto;
      }

      &.ddp-selected .ddp-wrap-popup2 {
        display: block;
      }
    }

    .ddp-label-checkbox {
      &.ddp-type-show {
        float: left;
        margin: 8px 0 0 10px;

        span.ddp-txt-checkbox {
          color: #b7b9c2;
          font-size: 12px;
        }
      }

      input[type='checkbox']:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
        color: #90969f;
      }
    }

    .ddp-ui-datalink {
      float: left;
      position: relative;
      margin-top: 11px;
      padding-left: 15px;
      cursor: pointer;

      em.ddp-icon-database3 {
        margin-right: 5px;
      }

      span.ddp-data-datanum {
        color: #b7b9c2;
        font-size: 12px;

        .ddp-ui-tooltip-info {
          top: 19px;
          right: 1px;
          left: inherit;

          em.ddp-icon-view-top {
            left: inherit;
            right: 17px;
          }
        }
      }

      &:hover {
        em.ddp-icon-database3 {
          background-position: -26px -31px;
        }

        span.ddp-data-datanum {
          color: #90969f;
        }
      }
    }

    .ddp-ui-datanum {
      float: left;
      position: relative;
      padding: 8px 0 5px 0;

      .ddp-data-source {
        display: inline-block;
        position: relative;
        padding-left: 18px;

        em.ddp-icon-source {
          position: absolute;
          top: -2px;
          left: 0;
        }

        .ddp-wrap-popup2 {
          display: none;
          position: absolute;
          top: 20px;
          left: 8px;
          white-space: nowrap;
        }

        span.ddp-data-num {
          color: #90969f;
          cursor: pointer;

          em {
            color: #90969f;
          }

          &:hover em {
            color: #4b515b;
          }
        }

        &.ddp-selected span.ddp-data-num em {
          color: #4b515b;
        }
      }

      span.ddp-data-eyes {
        display: inline-block;
        position: relative;
        margin-left: 20px;
        padding: 3px 5px 3px 20px;
        color: #90969f;
        cursor: pointer;
        vertical-align: middle;

        em.ddp-icon-eyes {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -6px;
          width: 16px;
          height: 11px;
          background: url(/assets/bi/images/icon_eyes.png) no-repeat;
          background-position: left -12px;
        }

        &.ddp-selected {
          border-radius: 2px;
          color: #90969f;

          em.ddp-icon-eyes {
            background-position: -17px 0;
          }
        }

        .ddp-ui-tooltip-info {
          top: 28px;
          right: -1px;
          left: inherit;

          em.ddp-icon-view-top {
            left: inherit;
            right: 17px;
          }
        }
      }
    }

    .ddp-ui-dataoption {
      float: right;

      .ddp-btn-option {
        position: relative;
        float: left;
        margin-top: 12px;
        width: 17px;
        height: 11px;
        background: url(/assets/bi/images/icon_editorder.png) no-repeat;

        &:hover {
          background-position: left -12px;
        }

        .ddp-ui-tooltip-info {
          display: none;
          right: -8px;
          left: inherit;
          top: 24px;
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }

        .ddp-ui-tooltip-info em.ddp-icon-view-top {
          margin-left: 0;
          right: 13px;
          left: inherit;
        }
      }
    }

    .ddp-wrap-array {
      position: relative;
      float: left;

      a.ddp-link-array {
        position: relative;
        float: Left;
        margin: 7px 0 7px 19px;
        padding-right: 17px;
        line-height: 21px;
        color: #b7b9c2;
      }

      &.ddp-ellipsis a.ddp-link-array {
        max-width: 75px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        box-sizing: border-box;
      }

      a.ddp-link-array {
        &:hover {
          color: #90969f;
        }

        [class*='ddp-icon-array'] {
          position: absolute;
          top: 0;
          right: 0;
          width: 17px;
          height: 21px;
        }
      }

      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: 29px;
        left: 10px;
        white-space: nowrap;
      }

      &.ddp-selected .ddp-wrap-popup2 {
        display: block;
      }
    }

    .ddp-ui-dataoption ul.ddp-list-tab-button {
      float: left;
      margin: 5px 6px 4px 8px;
    }
  }

  .ddp-btn-board-add {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 26px 21px 24px 21px;
  }
}

/* 삭제될 css */

/* //삭제될 css */

a.ddp-link-board-add {
  display: block;
  padding: 8px 17px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  background-color: #4b515b;

  &:hover {
    background-color: #35393f;
  }

  .ddp-link-add {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: left -10px;
  }
}

.ddp-wrap-tab-dashboard {
  .ddp-ui-board-listview {
    position: absolute;
    top: 183px;
    left: 0;
    right: 0;
    bottom: 80px;
    box-sizing: border-box;
    overflow: auto;
  }

  .ddp-link-more {
    margin: 10px 0 0 22px;
    color: #90969f;
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      color: #4b515b;
    }
  }

  .ddp-wrap-search {
    padding: 20px 20px 0 20px;

    .ddp-form-search {
      width: 100%;
    }
  }
}

ul.ddp-list-board-listview li {
  a {
    display: block;
    position: relative;
    padding: 17px 15px 17px 0;

    span.ddp-ui-number {
      float: left;
      width: 42px;
      height: 100%;
      text-align: center;
      color: #90969f;
      font-size: 14px;
    }

    .ddp-ui-name {
      display: block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      box-sizing: border-box;
    }

    span.ddp-data-name {
      display: inline-block;
      position: relative;
      max-width: 100%;
      padding-right: 0;
      color: #90969f;
      font-size: 14px;
      vertical-align: middle;
      box-sizing: border-box;

      &.ddp-data-new {
        padding-right: 40px;
      }

      em.ddp-icon-new {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
      }

      &.ddp-data-new em.ddp-icon-new {
        display: block;
      }
    }

    em {
      &.ddp-icon-eyes2,
      &.ddp-icon-eyes2-none {
        float: Left;
        position: relative;
        top: 2px;
        margin-right: 8px;
      }
    }

    .ddp-btn-control {
      display: none;
      position: absolute;
      right: 8px;
      top: 18px;

      em[class*='ddp-icon-control-'] {
        position: relative;

        .ddp-ui-tooltip-info {
          display: none;
          left: inherit;
          right: -4px;
          top: 22px;

          em.ddp-icon-view-top {
            left: inherit;
            right: 9px;
            margin: 0;
          }
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }
      }
    }

    &:hover {
      .ddp-btn-control {
        display: block;
      }

      padding-right: 60px;
      background-color: #f6f6f7;
    }
  }

  &.ddp-selected a {
    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 6px;
      height: 11px;
      margin-top: -6px;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: -14px -68px;
    }

    &:hover:after {
      display: none;
    }

    span {
      &.ddp-data-name,
      &.ddp-ui-number {
        font-weight: bold;
        color: #4b515b;
      }
    }
  }
}

.ddp-wrap-tab-dashboard .ddp-ui-board-thumbview {
  position: absolute;
  top: 183px;
  left: 0;
  right: 0;
  bottom: 80px;
  box-sizing: border-box;
  overflow: auto;
}

ul.ddp-list-board-thumbview li {
  padding-top: 15px;

  a {
    display: block;
    position: relative;
    padding: 0 11px 0 37px;

    span {
      &.ddp-icon-number {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 20px;
        color: #90969f;
        font-size: 13px;
      }

      &.ddp-wrap-image {
        display: block;
        position: relative;
        width: 100%;
        height: 156px;
        margin-bottom: 5px;
        border: 1px solid #e7e7ea;

        img {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 1;
        }

        &:after {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -14px 0 0 -17px;
          width: 34px;
          height: 28px;
          background: url(/assets/bi/images/img_board_default2.png) no-repeat;
          content: '';
        }
      }

      &.ddp-data-name {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 10px;
        padding-left: 23px;
        color: #90969f;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        box-sizing: border-box;

        em {
          &[class*='ddp-icon-eyes2'] {
            position: absolute;
            top: 2px;
            left: 0;
          }

          &.ddp-icon-eyes2 {
            background-position: -17px top;
          }
        }
      }
    }

    .ddp-btn-control {
      display: none;
      position: absolute;
      top: 0;
      right: 8px;

      em[class*='ddp-icon-control-'] {
        position: relative;

        .ddp-ui-tooltip-info {
          display: none;
          left: inherit;
          right: -4px;
          top: 22px;

          em.ddp-icon-view-top {
            left: inherit;
            right: 9px;
            margin: 0;
          }
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }
      }
    }

    &:hover {
      span.ddp-data-name {
        padding-right: 40px;
      }

      .ddp-btn-control {
        display: block;
      }
    }
  }

  &.ddp-selected a span.ddp-wrap-image:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(193, 206, 241, 0.3);
    border: 2px solid $primary;
    content: '';
    z-index: 2;
  }

  a:hover .ddp-wrap-image:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(86, 93, 101, 0.1);
    z-index: 12;
  }

  &.ddp-selected a span.ddp-data-name {
    color: #4b515b;
  }
}

.ddp-ui-board-filter {
  position: relative;
  padding-right: 53px;

  .ddp-btn-slider {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 70px;
    padding: 15px 0;
    background-color: #fff;
    z-index: 1;

    .ddp-btn-prev {
      border-right: 1px solid #e7e7ea;
      position: relative;
      float: left;
      width: 50%;
      height: 20px;
      box-sizing: border-box;
    }

    .ddp-btn-next {
      position: relative;
      float: left;
      width: 50%;
      height: 20px;
      box-sizing: border-box;
    }

    .ddp-btn-prev:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 7px;
      margin: -4px 0 0 -2px;
      background: url(/assets/bi/images/icon_slide.png) no-repeat;
    }

    .ddp-btn-next:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 7px;
      margin: -4px 0 0 -2px;
      background: url(/assets/bi/images/icon_slide.png) no-repeat;
      background-position: -5px top;
    }

    .ddp-btn-prev:hover:before {
      background-position: left -7px;
    }

    .ddp-btn-next:hover:before {
      background-position: -5px -7px;
    }

    .ddp-bg-shadow {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -14px;
      bottom: 0;
      width: 14px;
      background: url(/assets/bi/images/bg_shadow.png) no-repeat;
      transform: rotate(180deg);
      display: inline-block;
      position: absolute;
      top: 0;
      right: -14px;
      bottom: 0;
      width: 14px;
      background: url(/assets/bi/images/bg_shadow.png) no-repeat;
      transform: rotate(180deg);
    }
  }

  .ddp-wrap-right {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 147px;
    background-color: #fff;
  }

  a.ddp-btn-reset {
    display: inline-block;
    padding: 7px 11px 7px 8px;
    color: #90969f;
    font-size: 12px;
    background-color: #fff;
    white-space: nowrap;
    z-index: 5;

    &:hover {
      z-index: 200;
    }

    &:before {
      display: inline-block;
      content: '';
      width: 14px;
      height: 13px;
      background: url(/assets/bi/images/icon_reset.png) no-repeat;
      background-position: -16px -20px;
    }

    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -8px;
      content: '';
      height: 16px;
      border-left: 1px solid #e7e7ea;
    }

    em.ddp-bg-shadow {
      display: inline-block;
      position: absolute;
      top: 0;
      left: -14px;
      bottom: 0;
      width: 14px;
      background: url(/assets/bi/images/bg_shadow.png) no-repeat;
    }

    .ddp-ui-tooltip-info {
      right: 7px;
      top: 37px;
    }
  }
}

.ddp-wrap-board-filter {
  position: relative;
  padding: 10px 7px 10px 7px;
  box-sizing: border-box;

  &.ddp-slider {
    padding-left: 80px;
  }

  &.ddp-initial {
    overflow: initial;
  }

  .ddp-list-board-filter {
    white-space: nowrap;
    word-wrap: normal;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .ddp-box-board-filter {
      display: inline-block;
      min-width: 224px;
      max-width: 352px;
      padding: 0 3px;
      box-sizing: border-box;
      vertical-align: top;

      .ddp-box-dataselect {
        width: 100%;
        padding: 7px 10px 6px 10px;
        color: #d0d1d8;
        font-size: 12px;
        border: 1px dashed #e7e7ea;
        box-sizing: border-box;
      }
    }
  }

  .ddp-box-board-filter .ddp-type-selectbox ul.ddp-list-selectbox {
    min-width: 100%;
    right: inherit;

    li span.ddp-time {
      color: #d0d1d8;
    }
  }

  .ddp-list-board-filter .ddp-box-board-filter.ddp-time .ddp-type-selectbox span.ddp-txt-selectbox {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
  }
}

.ddp-box-board-filter .ddp-icon-time4 {
  margin-right: 4px;
}

.ddp-wrap-board-filter .ddp-list-board-filter {
  .ddp-box-board-time {
    display: inline-block;
    position: relative;
    margin-right: 3px;
  }

  .ddp-btn-time {
    display: inline-block;
    position: relative;
    width: 33px;
    height: 30px;
    border: 1px solid #d0d1d9;
    border-radius: 2px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #8f96a0;
    }

    em.ddp-icon-time4 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
    }
  }

  .ddp-box-board-time {
    .ddp-ui-tooltip-info {
      top: 100%;
      left: 0;
      margin-top: 6px;
      z-index: 100;

      .ddp-icon-view-top {
        left: 16px;
        margin-left: 0;
      }
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }
  }
}

.widget-wrapper {
  height: 100% !important;
}

// .widget-wrapper_filter {
//   display: none;
//   visibility: hidden;
// }

.ddp-ui-widget {
  padding: 10px 10px;
  background: #fcfcfc;

  &.ddp-type {
    top: 47px;
  }
}

.ddp-ui-edit-contents .ddp-wrap-widget {
  overflow: initial;
}

.ddp-wrap-widget {
  position: relative;
  height: 100%;
  overflow: hidden;

  &.ddp-box-full {
    position: fixed;
    left: 290px;
    top: 165px;
    bottom: 0;
    right: 0;
    height: auto;
    background-color: #fff;
    z-index: 130;

    .ddp-loading-part {
      z-index: 130;
    }
  }
}

.ddp-box-widget {
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  box-sizing: border-box;

  .ddp-ui-graph-name {
    position: relative;
    padding: 4px 5px 4px 10px;
    color: #4b515b;
    font-size: 14px;
    box-sizing: border-box;
    z-index: 30;

    .ddp-data-name {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ddp-ui-tooltip-info {
      display: none;
      position: absolute;
      top: 35px;
      left: 10px;
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-tooltip-info em.ddp-icon-view-top {
      left: 20px;
    }
  }

  .ddp-top-control {
    position: relative;
    float: right;
    height: 25px;
    z-index: 31;
    background-color: transparent;

    .ddp-ui-buttons {
      background-color: rgba(255, 255, 255, 0.8);

      .ddp-box-btn2,
      .ddp-box-btn-hover {
        display: none;
      }

      .ddp-box-btn2.ddp-box-download {
        display: block;
        width: auto;

        span.ddp-txt {
          padding-right: 5px;
          line-height: 28px;
          font-size: 11px;
          color: #90969f;
        }
      }
    }
  }

  &:hover .ddp-top-control .ddp-ui-buttons {
    .ddp-box-btn2 {
      &.ddp-box-download span.ddp-txt {
        display: none;
      }

      display: flex;
    }

    .ddp-box-btn-hover {
      display: flex;
    }
  }

  .ddp-top-control {
    .ddp-ui-buttons .ddp-ui-part {
      float: left;
      position: relative;
      top: -3px;
      padding-right: 7px;
      margin-right: 7px;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 7px;
        right: 0;
        height: 12px;
        border-right: 1px solid #cfd0d5;
      }
    }

    .ddp-box-btn-hover {
      float: left;
      background: none;

      em[class*='ddp-icon-tool'] {
      }

      &:hover {
        border: 1px solid #ddd;
        background-color: #fff;
      }
    }
  }

  &:hover,
  &.ddp-active {
    border: 1px solid #e7e7ea;
    box-shadow: 0 3px 5px -2px rgba(144, 150, 159, 0.2);
    box-sizing: border-box;
    z-index: 10;
  }

  &:hover .ddp-top-control .ddp-ui-buttons {
    display: block;
  }

  &.ddp-active .ddp-top-control .ddp-ui-buttons {
    display: block;

    .ddp-box-btn2,
    .ddp-box-btn-hover {
      display: block;
    }
  }

  .ddp-top-control .ddp-ui-buttons {
    .ddp-box-btn2 {
      position: relative;
      float: left;
      border: 1px solid $primary;
      padding: 0px 5px 0px 5px;
      margin-top: 3px;
      margin-left: 5px;
      border-radius: 7px;

      svg {
        width: 13px;
      }

      &:hover {
        background-color: #eee;

        .ddp-box-layout4 {
          display: block;
        }
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        top: 100%;
        right: -77px;
        width: 260px;
        table-layout: fixed;
      }
    }

    .ddp-ui-btnview {
      float: left;
      position: relative;
      margin: 0 5px;
      border: 1px solid #90969f;
      cursor: pointer;
      box-sizing: border-box;
    }

    em {
      &[class*='ddp-icon-widget-'] {
        float: left;
        position: relative;
        width: 26px;
        height: 25px;
        font-size: 0;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }

      &.ddp-icon-widget-info:before {
        margin: -7px 0 0 -7px;
      }

      &.ddp-icon-widget-trigger:before {
        margin: -10px 0 0 -9px;
      }

      &.ddp-icon-widget-edit:before {
        margin: -7px 0 0 -7px;
      }

      &.ddp-icon-widget-cut:before,
      &.ddp-icon-widget-cut-w:before,
      &.ddp-icon-widget-moveto:before,
      &.ddp-icon-widget-save:before,
      &.ddp-icon-widget-store:before {
        margin: -6px 0 0 -6px;
      }

      &.ddp-icon-widget-full:before,
      &.ddp-icon-widget-downsize:before {
        margin: -7px 0 0 -7px;
      }

      &.ddp-icon-widget-copy:before {
        margin: -6px 0 0 -6px;
      }

      &.ddp-icon-widget-chart:before {
        position: relative;
        top: 2px;
        margin: -6px 0 0 -6px;
      }

      &.ddp-icon-widget-gridsave:before {
        margin: -6px 0 0 -6px;
      }

      &.ddp-icon-widget-dataview:before {
        margin: -7px 0 0 -7px;
      }

      &.ddp-icon-widget-synch {
        &:before {
          margin: -7px 0 0 -9px;
        }

        &.ddp-on:before {
          margin: -7px 0 0 -8px;
        }
      }
    }
  }

  .ddp-top-control-in {
    display: none;

    &.ddp-size1 {
      display: block;
    }
  }

  &.ddp-box-size .ddp-top-control-in {
    &.ddp-size1 {
      display: none;
    }

    &.ddp-size2 {
      display: block;
    }
  }
}

.ddp-ui-boardedit .ddp-box-widget.ddp-box-size .ddp-top-control {
  padding-right: 30px;
}

.ddp-box-widget.ddp-box-size {
  .ddp-top-control .ddp-ui-buttons {
    margin: 4px 9px 0 0;
  }

  .ddp-wrap-morebutton {
    em.ddp-icon-more:before {
      background-position: -3px top;
    }

    .ddp-wrap-popup2 {
      display: none;
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }
}

.ddp-wrap-morebutton.ddp-selected .ddp-wrap-popup2 .ddp-list-tab-button {
  position: absolute;
  top: 1px;
  right: 9px;
}

.ddp-box-widget.ddp-box-size .ddp-wrap-morebutton {
  &.ddp-selected:hover .ddp-ui-tooltip-info {
    display: none;
  }

  .ddp-wrap-popup2 ul {
    border-top: 1px solid #e7e7ea;

    &:first-of-type {
      border-top: none;
    }

    li {
      position: relative;

      a {
        display: block;
        padding: 6px 14px 6px 33px;
        position: relative;
        color: #4b515b;
        font-size: 13px;
      }

      .ddp-list-txt {
        display: block;
        padding: 6px 14px 6px 33px;
        position: relative;
        color: #4b515b;
        font-size: 13px;
        cursor: default;
      }

      a {
        label.ddp-label-checked {
          display: block;
          margin: -6px -14px -6px -33px;
          padding: 6px 55px 6px 33px;
          color: #4b515b;
          font-size: 13px;
          cursor: pointer;
        }

        em {
          &.ddp-icon-tool-edit,
          &.ddp-icon-tool-copy,
          &.ddp-icon-tool-remove {
            display: inline-block;
            background: url(/assets/bi/images/icon_widget.png) no-repeat;
          }

          &.ddp-icon-tool-edit {
            width: 13px;
            height: 13px;
            margin-top: -7px;
            background-position: -14px -131px;
          }

          &.ddp-icon-tool-copy {
            width: 13px;
            height: 13px;
            margin-top: -7px;
            background-position: 0 -92px;
          }

          &.ddp-icon-tool-remove {
            width: 11px;
            height: 11px;
            margin-top: -6px;
            background-position: left -176px;
          }

          &[class*='ddp-icon-tool-'],
          &.ddp-icon-chartdet {
            position: absolute;
            top: 50%;
            left: 11px;
          }

          &.ddp-icon-tool-title {
            margin: -6px 0 0 0;
            background-position: -14px top;
          }

          &.ddp-icon-tool-legend {
            margin: -6px 0 0 0;
            background-position: -26px -13px;
          }

          &.ddp-icon-tool-minimap {
            margin: -7px 0 0 0;
            background-position: -26px -26px;
          }

          &.ddp-icon-tool-linked {
            margin: -5px 0 0 0;
            background-position: -17px -40px;
          }

          &.ddp-icon-tool-chart {
            margin: -7px 0 0 0;
            background-position: -13px -52px;
          }

          &.ddp-icon-chartdet {
            margin: -7px 0 0 0;
            background-position: -14px -163px;
          }
        }
      }

      .ddp-list-txt em.ddp-icon-tool-display {
        position: absolute;
        top: 50%;
        left: 11px;
        margin-top: -5px;
      }

      a span.ddp-ui-check {
        position: absolute;
        top: 6px;
        right: 12px;
        padding-right: 20px;

        i {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -5px;
          width: 11px;
          height: 8px;
          margin-left: 7px;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          vertical-align: middle;
          background-position: -15px -18px;
        }

        input[type='checkbox'] {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;

          &:checked + i {
            background-position: -15px -9px;
          }
        }

        span.ddp-txt-check:before {
          display: inline-block;
          content: 'Off';
          color: #b7b9c2;
          font-size: 11px;
        }

        input[type='checkbox']:checked + i + span.ddp-txt-check:before {
          content: 'On';
          color: $primary;
        }
      }
    }
  }
}

.ddp-wrap-naviarea .ddp-ui-fix-buttons {
  display: inline-block;
  position: relative;
  top: 0;
  right: inherit;
  margin-right: 3px;
  width: inherit;
  border-radius: 2px;
  background-color: #dddee2;
  overflow: hidden;
  vertical-align: middle;

  .ddp-list-buttons2 li {
    float: left;

    a {
      display: inline-block;
      position: relative;
      padding: 7px 25px;
      height: 31px;
      box-sizing: border-box;

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -8px;
        height: 16px;
        border-right: 1px solid #c3c5ca;
        content: '';
      }
    }

    &:last-of-type a:after {
      display: none;
    }

    a:hover {
      background-color: #d0d1d8;
    }
  }

  .ddp-icon-chart-new {
    background-position-x: 0;
  }
}

.ddp-ui-fix-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 127px;
  z-index: 121;

  &:hover {
    z-index: 201;
  }
}

.ddp-btn-widget {
  border-radius: 2px;
  background-color: #90969f;

  &:hover {
    position: relative;
    z-index: 201;
  }

  .ddp-btn-chart {
    display: block;
    position: relative;
    padding: 7px 0 7px 40px;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    box-sizing: border-box;

    em.ddp-icon-chart-new {
      position: absolute;
      top: 50%;
      left: 13px;
      margin-top: -9px;
    }

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 13px;
      margin-top: -3px;
      width: 9px;
      height: 5px;
      background: url(/assets/bi/images/icon_selecview.png) no-repeat;
      background-position: left -58px;
      content: '';
    }
  }

  &:hover .ddp-btn-chart:before {
    display: none;
  }

  .ddp-types ul.ddp-list-popup li a {
    padding-left: 45px;

    &:hover {
      background-color: #f2f1f8;
      color: $primary;
    }
  }

  .ddp-list-popup li a {
    .ddp-icon-chart-new {
      position: absolute;
      top: 50%;
      left: 13px;
      margin-top: -9px;
      background-position-x: 0;
    }

    &:hover .ddp-icon-chart-new {
      background-position-x: -46px;
    }

    .ddp-icon-filter-new {
      position: absolute;
      top: 50%;
      left: 13px;
      margin-top: -7px;
    }

    &:hover .ddp-icon-filter-new {
      background-position-x: -22px;
    }

    .ddp-icon-parameter-new {
      position: absolute;
      top: 50%;
      left: 13px;
      margin-top: -8px;
    }

    &:hover .ddp-icon-parameter-new {
      background-position-x: -22px;
    }

    .ddp-icon-text-new {
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -6px;
    }

    &:hover .ddp-icon-text-new {
      background-position-x: -22px;
    }
  }

  .ddp-wrap-view .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    border: 1px solid #9ca2cc;
  }

  &:hover .ddp-wrap-view .ddp-wrap-popup2 {
    display: block;
  }
}

.ddp-box-btn2 {
  position: relative;

  .ddp-ui-tooltip-info {
    display: none;
    top: 28px;
    left: inherit;
    right: 3px;
    margin-top: 6px;
  }

  &:hover .ddp-ui-tooltip-info {
    display: block;
  }

  .ddp-ui-tooltip-info em.ddp-icon-view-down {
    left: inherit;
    right: 8px;
    bottom: inherit;
    top: -6px;
    transform: rotate(90deg);
  }
}

.ddp-ui-btnview {
  position: relative;
  border: 1px solid #90969f;
  cursor: pointer;
  box-sizing: border-box;

  em.ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 7px;
    height: 4px;
    margin-top: -2px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: -28px -7px;
  }

  .ddp-box-btn {
    position: relative;
    float: left;
    width: 20px;
    height: 18px;

    em {
      &.ddp-icon-widget-graph,
      &.ddp-icon-widget-grid {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -5px;
        background: url(/assets/bi/images/icon_widget.png) no-repeat;
      }

      &.ddp-icon-widget-graph {
        width: 10px;
        height: 11px;
        background-position: left -80px;
      }

      &.ddp-icon-widget-grid {
        width: 10px;
        height: 12px;
        background-position: left -67px;
      }
    }

    &.ddp-selected {
      .ddp-icon-widget-grid {
        background-position: -11px -67px;
      }

      .ddp-icon-widget-graph {
        background-position: -12px -80px;
      }
    }
  }

  .ddp-ui-tooltip-info {
    display: none;
    top: 25px;
    left: inherit;
    right: -6px;

    em.ddp-icon-view-down {
      right: 16px;
      left: inherit;
      bottom: inherit;
      top: -6px;
      transform: rotate(90deg);
    }
  }

  .ddp-box-btn:hover .ddp-ui-tooltip-info {
    display: block;
  }
}

.ddp-box-widget .ddp-top-control .ddp-ui-buttons em {
  &.ddp-icon-widget-grid {
    width: 11px;
    height: 12px;
    background-position: left -67px;
  }

  &.ddp-icon-widget-graph {
    width: 10px;
    height: 11px;
    background-position: left -80px;
  }
}

.ddp-ui-btnview .ddp-box-btn.ddp-selected {
  background-color: #4b515b;
}

.ddp-wrap-editboard .ddp-view-widget.ddp-view-widget-filter {
  overflow-y: auto;
  padding-top: 10px;
}

.ddp-view-widget {
  .ddp-ui-slider-type {
    margin: 0;
  }

  &.ddp-view-name {
    top: 25px;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;
  z-index: 1;

  .ddp-area-filter {
    &.ddp-flex,
    .ddp-flex {
      display: flex;
    }

    &.ddp-flex .ddp-btn-line,
    .ddp-flex .ddp-btn-line {
      margin-left: 4px;
    }

    &.ddp-flex {
      .ddp-type-selectbox,
      .ddp-search-value {
        flex: 1 auto;
      }
    }

    .ddp-flex .ddp-search-value {
      flex: 1 auto;
    }

    .ddp-search-value .ddp-pop-warning .ddp-view {
      display: inline-block;
      position: absolute;
      bottom: 100%;
      right: 23px;
      width: 4px;
      height: 8px;
      margin-bottom: -2px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      background-position: left -9px;
      transform: rotate(90deg);
    }

    .ddp-type-selectbox .ddp-wrap-popup2 {
      &.ddp-scroll-none {
        max-height: initial;
      }

      .ddp-list-popup {
        max-height: 125px;
        overflow: auto;
      }

      ul.ddp-list-popup li {
        a {
          padding: 4px 14px 4px 14px;
        }

        &.ddp-value-search {
          .ddp-label-checkbox span.ddp-txt-checkbox,
          .ddp-label-radio span.ddp-txt-radio {
            color: #9ca2cc;
          }
        }
      }

      .ddp-no-result {
        .ddp-wrap-info {
          padding: 5px 5px 0 5px;
        }

        .ddp-ui-info {
          position: relative;
          padding: 8px 8px 8px 28px;
          border: 1px solid #e7e7ea;
          border-radius: 2px;
          background-color: #f6f6f7;
          color: #90969f;
          font-size: 12px;

          .ddp-icon-info {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 8px;
            margin-top: -7px;
            width: 14px;
            height: 14px;
            background: url(/assets/bi/images/icon_info3.png) no-repeat;
            background-position: left -94px;
          }
        }

        .ddp-txt-noresult {
          display: block;
          padding: 10px 10px;
          color: #b6b9c2;
        }
      }
    }

    .ddp-select-setting {
      padding: 10px 10px 0 10px;

      .ddp-list-select {
        float: right;
        margin: 0 -10px;

        li {
          float: left;

          a {
            display: block;
            position: relative;
            padding: 0 6px;
            color: #b6b9c2;
            font-size: 12px;
            text-decoration: underline;

            &:hover {
              color: #4b515b;
            }
          }

          + li a:before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -5px;
            height: 10px;
            border-left: 1px solid #d0d1d8;
            content: '';
          }
        }
      }

      .ddp-data-total {
        display: block;
        overflow: hidden;
        color: #90969f;
        font-size: 12px;
      }
    }

    .ddp-filter-list {
      .ddp-select-setting {
        padding: 4px 0;
      }

      ul.ddp-list-checktype li {
        padding: 5px 0;
        font-size: 0;

        &.ddp-value-search {
          .ddp-label-checkbox span.ddp-txt-checkbox,
          .ddp-label-radio span.ddp-txt-radio {
            color: #9ca2cc;
          }
        }
      }
    }

    .ddp-type-selectbox .ddp-data-result2 {
      padding: 5px 10px;
      border-top: 1px dotted #d0d1d8;
      overflow: hidden;

      .ddp-data-values {
        float: left;
        position: relative;
        top: 2px;
      }

      .ddp-label-check-value {
        vertical-align: middle;

        .ddp-value,
        .ddp-value-num {
          vertical-align: top;
          position: relative;
          top: -1px;
          margin-left: 6px;
          color: #90969f;
          font-size: 12px;
        }

        .ddp-value:before {
          content: 'All';
        }

        .ddp-value-num {
          display: none;
        }

        input[type='checkbox']:checked + i + .ddp-value-num {
          display: inline-block;

          + .ddp-value {
            display: none;
          }
        }
      }

      .ddp-ui-page {
        float: right;
      }
    }
  }

  .ddp-ui-title + .ddp-ui-widget-contents {
    top: 43px !important;
    padding-top: 0;
  }

  .ddp-ui-widget-contents {
    position: absolute;
    top: 43px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    overflow: auto;

    > .ddp-list-checktype {
      padding: 0;
      margin-top: -8px;
    }
  }
}

.ddp-box-boardedit .ddp-view-widget .ddp-ui-widget-contents {
  position: relative;
  top: 0 !important;
  padding: 0;
  overflow: initial;
}

.ddp-ui-widget-contents .ddp-form-multy .ddp-box-multy.ddp-form-time {
  .ddp-input-typebasic {
    width: 45%;
  }

  .ddp-type-selectbox {
    width: 55%;
  }
}

.ddp-view-widget-text {
  color: #4b515b;
  font-size: 24px;
  overflow-y: auto;
}

.ddp-view-widget > .ddp-ui-top {
}

.ddp-box-widget:hover .ddp-view-widget > .ddp-ui-top .ddp-no-filter {
  margin-right: 26px;
}

.ddp-view-widget {
  > .ddp-ui-top .ddp-no-filter {
    position: relative;
    color: #b6b9c2;

    em.ddp-icon-info {
      display: inline-block;
      margin-right: 3px;
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat;
      background-position: -15px -94px;
      vertical-align: middle;
    }
  }

  .ddp-ui-title {
    padding: 10px 0 9px 0;
    color: #4b515b;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .ddp-icon-filter,
    .ddp-icon-parameter {
      display: inline-block;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_title.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-icon-filter {
      width: 15px;
      height: 13px;
    }

    .ddp-icon-parameter {
      width: 15px;
      height: 14px;
      background-position: left -14px;
    }
  }
}

.ddp-view-widget-parameter .ddp-ui-widget-contents {
  padding: 14px 6px 0 6px;

  .ddp-ui-slider-type {
    clear: both;
    margin-top: -10px;
  }
}

.ddp-ui-widget-contents {
  .ddp-dateinfo-view {
    position: relative;
    padding: 0 0 0 0;
    width: 280px;

    .ddp-box-preview {
      padding: 6px 0;
      margin-bottom: 10px;
      border-radius: 2px;
      background-color: #f6f6f7;
      text-align: center;
      color: #4c515a;
      font-size: 14px;
    }

    .ddp-list-buttons {
      width: 100%;

      li {
        margin-bottom: 11px;
      }
    }
  }

  .ddp-filter-calen-in {
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 4px;
    margin-right: -25px;

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.ddp-dateinfo-view.ddp-inline {
  width: auto;

  .ddp-filter-calen {
    .ddp-wrap-dateinfo {
      margin-top: -4px;
    }

    .ddp-ui-dateinfo {
      display: inline-block;
      margin-top: 4px;
      width: 280px;
    }

    .ddp-form-date .ddp-day {
      display: inline-block;
      width: 280px;
      margin-top: 5px;
    }
  }
}

.ddp-ui-widget-contents {
  .ddp-filter-dateinfo .ddp-ui-dateinfo {
    .ddp-box-rome {
      width: 100%;
    }

    width: 100%;
    text-align: left;

    &:last-of-type {
      text-align: left;
    }
  }

  .ddp-form-search {
    width: 100%;
  }

  ul.ddp-list-checktype li {
    padding: 8px 0;
  }

  .ddp-ui-slider-type .irs .irs {
    padding-top: 13px;
  }
}

.ddp-wrap-slider-type .ddp-input-typebasic {
  margin-top: 24px;
}

.ddp-form-input-multy {
  input {
    width: 42%;
    padding: 5px 5px 6px 5px;
    border: 1px solid #ddd;
    text-align: center;
    color: #444;
    font-size: 12px;
    box-sizing: border-box;

    &.ddp-input-left {
      float: left;
    }
  }

  span.ddp-text-bar {
    float: left;
    width: 16%;
    line-height: 28px;
    text-align: center;
    color: #a4aab0;
  }

  input.ddp-input-right {
    float: right;
  }
}

/* 편집 대시보드*/

.ddp-wrap-widget {
  .ddp-box-widget.type-event-trigger {
    display: none;
  }

  &.type-trigger .ddp-box-widget.type-event-trigger {
    display: block;
  }
}

.ddp-box-widget.type-event-trigger {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;

  .ddp-top-control .ddp-ui-buttons {
    background: none;

    .ddp-box-btn2 {
      display: block;

      &:hover {
        background: none;
      }
    }
  }

  .ddp-top-label {
    padding: 3px 10px;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ddp-top-control {
    padding-right: 0;

    .ddp-ui-buttons {
      position: relative;
      float: right;
    }
  }

  .ddp-ui-buttons {
    .ddp-btn-solid-type {
      display: inline-block;
      position: relative;
      top: 2px;
      float: left;
      padding: 2px 0;
      width: 54px;
      border-radius: 3px;
      background-color: #9ca2cc;
      text-align: center;
      color: #ffffff;
      font-size: 13px;
      font-weight: bold;
    }

    .ddp-txt-event {
      float: left;
      display: inline-block;
      padding: 3px 10px 0 0;
      color: #d0d1d8;
      font-size: 14px;
    }
  }

  .ddp-view-widget textarea.ddp-textarea-widget {
    display: block;
    padding: 10px;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: #35393f;
    font-size: 13px;
    box-sizing: border-box;
  }
}

.ddp-ui-boardedit .ddp-box-widget .ddp-top-control {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 35px;
  width: 100%;
  padding-right: 120px;
  background-color: #bdc0c9;
  box-sizing: border-box;

  .ddp-data-top {
    display: block;
  }

  .ddp-ui-buttons {
    position: absolute;
    top: 0;
    right: 0;
    background: none;

    .ddp-box-btn-hover,
    .ddp-box-btn2 {
      display: block;
    }

    .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
      right: 9px;
    }

    .ddp-box-btn-hover {
      border: 1px solid #bdc0c9;
      border-bottom: none;
      cursor: pointer;

      &:hover {
        border: 1px solid #e7e7ea;
        background-color: #fff;
      }

      a.ddp-btn-tool:hover {
        background-color: #eee;
      }
    }
  }

  .ddp-ui-eidt-name {
    display: block;
    position: relative;
    overflow: hidden;

    .ddp-data-enter {
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      span.ddp-data-name {
        display: inline-block;
        padding: 0 2px;
        margin: 3px 0 3px 5px;
        font-size: 14px;
        color: #4b515b;
      }

      &:hover span.ddp-data-name {
        background-color: #ced0d6;
      }

      .ddp-ui-tooltip-info {
        position: absolute;
        top: 30px;
        left: 0;
      }
    }

    input.ddp-input-edit {
      display: none;
    }

    &.ddp-selected input.ddp-input-edit {
      display: block;
      position: absolute;
      top: 3px;
      left: 5px;
      right: 0;
      bottom: 3px;
      padding: 2px 2px;
      width: 100%;
      font-size: 14px;
      color: #4b515b;
      background-color: #ced0d6;
      border: none;
      box-sizing: border-box;
    }
  }
}

.ddp-box-widget .ddp-top-control .ddp-ui-tool {
  float: left;
  margin-right: 5px;
  vertical-align: top;

  a.ddp-btn-tool {
    float: left;
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: -1px;
    background-color: #dfdfe3;
    border: 1px solid #b7b9c2;
    box-sizing: border-box;

    &:last-of-type {
      border-right: none;
    }

    em {
      &[class*='ddp-icon-tool-'] {
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &.ddp-icon-tool-title {
        margin: -6px 0 0 -7px;
      }

      &.ddp-icon-tool-legend {
        margin: -6px 0 0 -6px;
      }

      &.ddp-icon-tool-minimap {
        margin: -7px 0 0 -5px;
      }

      &.ddp-icon-tool-linked {
        margin: -5px 0 0 -8px;
      }

      &.ddp-icon-tool-chart {
        margin: -5px 0 0 -6px;
      }
    }

    &.ddp-selected {
      background-color: #fff;
      border: 1px solid #e7e7ea;
      border-bottom: 1px solid #fff;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: -1px;
        border-top: 2px solid #4b515b;
        z-index: 1;
      }
    }

    .ddp-ui-tooltip-info {
      left: 0;
      right: inherit;
      top: 24px;

      .ddp-icon-view-top {
        left: 18px;
      }
    }
  }
}

.gridster-item-inner {
  height: 100%;
}

.ddp-box-widget {
  position: relative;
  height: 100%;
}

/**********************************************************************************
	page : 대시보드 text
**********************************************************************************/

.ddp-text-boardside .ddp-box-down {
  padding-top: 0;
}

ul.ddp-list-text {
  margin: 0 -15px;

  li a {
    display: table;
    position: relative;
    table-layout: fixed;
    padding: 9px 45px 9px 17px;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    cursor: all-scroll;

    &:hover {
      background-color: #e7e7ea;
    }

    .ddp-chart-images {
      display: table-cell;
      position: relative;
      width: 28px;
      height: 28px;

      .ddp-img-txt {
        float: left;
        width: 28px;
        height: 28px;
        background: url(/assets/bi/images/img_text.png) no-repeat;
      }

      em.ddp-icon-used {
        display: inline-block;
        position: absolute;
        bottom: -4px;
        right: -5px;
        top: initial;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/icon_use.png) no-repeat;
        background-color: #f7f7f8;
      }
    }

    .ddp-btn-control {
      display: none;
      position: absolute;
      top: 50%;
      right: 6px;
      margin-top: -5px;

      .ddp-icon-edit-s,
      .ddp-icon-del-s {
        float: left;
        padding: 2px;
        margin-top: -5px;
      }

      em {
        &.ddp-icon-edit-s {
          &:before {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: url(/assets/bi/images/icon_control.png) no-repeat;
            background-position: -26px -97px;
          }

          &:hover:before {
            background-position-x: -52px;
          }
        }

        &.ddp-icon-del-s {
          &:before {
            display: inline-block;
            width: 11px;
            height: 12px;
            background: url(/assets/bi/images/icon_control.png) no-repeat;
            background-position: -26px -14px;
          }

          &:hover:before {
            background-position-x: -62px;
          }
        }
      }

      .ddp-icon-del-s {
        margin-left: 3px;
        position: relative;
        top: 1px;
      }
    }

    &:hover .ddp-btn-control {
      display: block;
    }

    .ddp-data-info {
      display: table-cell;
      width: 100%;
      padding-left: 10px;
      vertical-align: middle;
      box-sizing: border-box;

      span {
        display: block;
        color: #4b515b;
        font-size: 12px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }
}

/**********************************************************************************
        page : 대시보드 필터
**********************************************************************************/

.ddp-wrap-filter {
  position: relative;
  width: 100%;
  height: 100%;
}

.ddp-ui-filter-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 306px;
  border-right: 1px solid #e7e7ea;
  overflow: auto;
  background-color: #f6f6f7;

  .ddp-view-filter {
    position: relative;
    height: 42%;
    padding-bottom: 18px;
    padding-top: 13px;
    border-bottom: 1px solid #e7e7ea;
    background-color: #fff;
    box-sizing: border-box;

    &.ddp-type {
      height: 50%;
    }

    &.ddp-type2 {
      height: 100% !important;
    }

    &:first-of-type {
      padding-top: 0;
      height: 16%;

      .ddp-wrap-list-filter {
        top: 33px;
      }
    }

    &:nth-child(2) {
      position: absolute;
      top: 16%;
      left: 0;
      right: 0;
      height: 42%;
    }

    &.ddp-view-full {
      height: auto;
      bottom: 47px;
    }
  }

  .ddp-ui-top {
    position: relative;
    padding: 3px 11px 15px 11px;
    color: #90969f;
    font-size: 13px;
  }

  .ddp-view-column {
    .ddp-ui-top {
      cursor: pointer;

      .ddp-ui-search .ddp-form-search {
        background-color: #f6f6f7;
      }

      em.ddp-icon-view {
        position: absolute;
        top: 8px;
        right: 20px;
        width: 9px;
        height: 5px;
        background: url(/assets/bi/images/icon_dropdown.png) no-repeat;
        background-position: left -6px;
      }
    }

    &.ddp-selected .ddp-ui-top em.ddp-icon-view {
      background-position: left top;
    }

    .ddp-ui-top .ddp-ui-search {
      right: 35px;
    }
  }

  .ddp-ui-top .ddp-ui-search {
    position: absolute;
    top: -3px;
    right: 16px;
    left: 11px;
    height: 31px;

    .ddp-form-search {
      display: none;
      width: 100%;
      background-color: #fff;
    }

    &.ddp-selected .ddp-form-search {
      display: block;
    }

    em.ddp-icon-search-default {
      display: inline-block;
      padding: 4px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -11px;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        width: 11px;
        height: 11px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
      }

      &:hover:before {
        background-position: -12px top;
      }
    }
  }
}

.ddp-wrap-list-filter {
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  ul.ddp-list-filter {
    padding: 0 10px 0 13px;

    li {
      padding: 0 0 0 0;

      a {
        display: block;
        padding: 6px 23px 6px 15px;
        position: relative;
        color: #4b515b;
        font-size: 14px;

        &:hover {
          background-color: #f6f6f7;
        }

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 50%;
          right: 12px;
          width: 5px;
          height: 9px;
          margin-top: -5px;
          background: url(/assets/bi/images/icon_dataview.png) no-repeat;
          background-position: left -48px;
        }

        &:hover:after {
          display: block;
        }
      }

      &.ddp-selected a {
        background-color: #4b515b;
        color: #fff;

        &:after {
          display: block;
          background-position: -6px -48px;
        }
      }

      a {
        em.ddp-icon-error2 {
          position: relative;
          top: 0;
          margin: 0 5px 0 2px;
        }

        .ddp-ui-buttons {
          position: relative;
          top: -1px;
          float: right;
          box-sizing: border-box;

          .ddp-wrap-datalock,
          .ddp-wrap-datarecommend,
          em[class*='ddp-icon-'] {
            vertical-align: middle;
            margin-left: 7px;
          }

          span.ddp-link-filter {
            display: inline-block;
            position: relative;
            top: 2px;
            color: #90969f;
            font-size: 11px;
            text-decoration: underline;
            cursor: pointer;
            vertical-align: top;

            &:hover {
              color: #4b515b;
            }
          }

          .ddp-ui-tooltip-info {
            left: inherit;
            margin-right: 6px;
            right: 100%;
          }
        }
      }

      &.ddp-selected a .ddp-ui-buttons span.ddp-link-filter {
        color: #fff;
      }

      a {
        .ddp-icon-link-cut2 {
          position: relative;
          top: -1px;
        }

        span.ddp-data-name {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-wrap: normal;
          overflow: hidden;
        }
      }
    }
  }
}

.ddp-wrap-list-filter2 ul.ddp-list-filter2 li a span.ddp-data-name {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow: hidden;

  .ddp-ui-label {
    display: inline-block;
    margin-left: 8px;
    padding-left: 8px;
    color: #90969f;
    font-size: 11px;
    border-left: 1px solid rgba(208, 209, 216, 0.8);

    .ddp-icon-chart {
      display: inline-block;
      width: 10px;
      height: 8px;
      background: url(/assets/bi/images/icon_chart.png) no-repeat;
      background-position: -11px -43px;
    }
  }
}

.ddp-wrap-list-filter ul.ddp-list-filter li a {
  .ddp-icon-time3 {
    width: 13px;
    height: 13px;
    background-position: left -16px;
  }

  .ddp-icon-type {
    float: left;
    position: relative;
    top: 4px;
    width: 16px;
    margin-right: 5px;
    text-align: center;
    vertical-align: middle;
  }
}

.ddp-wrap-list-filter2 ul.ddp-list-filter2 li a .ddp-icon-type {
  float: left;
  position: relative;
  top: 4px;
  width: 16px;
  margin-right: 5px;
  text-align: center;
  vertical-align: middle;
}

.ddp-ui-filter-item .ddp-view-column {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 47px;
  padding-top: 13px;
  box-sizing: border-box;

  .ddp-view-column .ddp-ui-top {
    position: relative;
    padding: 3px 11px 15px 11px;
    color: #b7b9c2;
    font-size: 13px;
    font-weight: bold;
  }

  &.ddp-selected {
    top: 58%;
    height: 42%;

    .ddp-wrap-list-filter2 {
      display: block;
    }
  }
}

.ddp-wrap-list-filter2 {
  display: none;
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  ul.ddp-list-filter2 {
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 10px 0 13px;
    overflow: auto;

    li {
      position: Relative;

      a {
        display: block;
        position: relative;
        padding: 5px 23px 5px 15px;
        color: #4b515b;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .ddp-ui-buttons {
          float: right;
          box-sizing: border-box;

          .ddp-wrap-datalock,
          .ddp-wrap-datarecommend,
          em[class*='ddp-icon-'] {
            vertical-align: middle;
            margin-left: 7px;
          }
        }

        .ddp-icon-link-cut2 {
          position: relative;
          top: -1px;
          background-position: -26px -14px;
        }

        &:hover {
          background-color: #fff;
          color: #4b515b;
        }

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -4px;
          width: 7px;
          height: 7px;
          background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
          background-position: left -12px;
        }

        &:hover:after {
          display: block;
        }
      }

      &.ddp-disabled {
        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          z-index: 1;
          cursor: no-drop;
        }

        a {
          opacity: 0.5;

          &:hover:after {
            display: none;
          }
        }
      }
    }
  }

  .ddp-ui-page {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 12px 10px 12px;
    overflow: hidden;

    a {
      &.ddp-prev {
        float: left;
        color: #90969f;
        font-size: 12px;

        em {
          margin-right: 9px;
        }

        &.ddp-disabled {
          opacity: 0.3;
          cursor: no-drop;
        }
      }

      &.ddp-next {
        float: right;
        color: #90969f;
        font-size: 12px;

        em {
          margin-left: 9px;
        }

        &.ddp-disabled {
          opacity: 0.3;
          cursor: no-drop;
        }
      }
    }
  }
}

.ddp-ui-filter-set {
  position: absolute;
  top: 0;
  left: 307px;
  bottom: 0;
  padding: 0 0 0 16px;
  overflow: auto;

  .ddp-ui-top-title span.ddp-ui-label {
    display: block;
    font-size: 18px;
    color: #4b515b;
    font-weight: bold;
  }

  .ddp-ui-tags {
    overflow: Hidden;
    padding: 15px 0 15px 0;
  }
}

.ddp-ui-tags {
  .ddp-tag-type {
    position: relative;
    float: left;
    font-size: 12px;
    color: #90969f;
    height: 21px;
    padding: 2px 5px;
    margin-right: 5px;
    border-radius: 2px;
    border: 1px solid #90969f;
    box-sizing: border-box;

    &.ddp-tag-dimension {
      border: 1px solid #439fe5;
      color: #439fe5;
    }

    &.ddp-tag-measure {
      border: 1px solid #5fd7a5;
      color: #5fd7a5;
    }

    &.ddp-type {
      border: 1px solid $primary;
      color: $primary;

      .ddp-wrap-datalock {
        position: relative;
        margin-right: 5px;
        border: 1px solid $primary;
        color: $primary;

        em.ddp-icon-lock-s2 {
          position: absolute;
          left: inherit;
          top: inherit;
          margin: 0;
          background-position-x: -7px;
        }
      }
    }

    em {
      &[class*='ddp-icon'] {
        position: relative;
        top: 1px;
        left: 0;
        margin-right: 5px;
        vertical-align: middle;
      }

      &[class*='ddp-icon-basic-'] {
        top: -1px;
      }

      &.ddp-icon-global {
        width: 14px;
        background-position: -42px -18px;
      }

      &.ddp-icon-recommend,
      &.ddp-icon-lock {
        position: relative;
        top: 0;
        background-position: -24px top;
      }
    }
  }

  .ddp-data-chartname {
    float: left;
    padding: 3px 7px;
    width: 155px;
    color: #90969f;
    font-size: 12px;
    background-color: #f6f6f7;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      width: 10px;
      height: 8px;
      margin-right: 4px;
      background: url(/assets/bi/images/icon_chart.png) no-repeat;
      background-position: -11px -43px;
      content: '';
    }
  }
}

.ddp-tag-type {
  .ddp-txt-slide:after {
    color: #b7b9c2;
    font-size: 12px;
    content: 'Global off';
  }

  &.ddp-checkbox-slide {
    border: none;

    &:hover {
      padding-right: 47px;
    }

    label {
      display: none;
      position: absolute;
      right: 5px;
      top: 8px;
    }

    &:hover label {
      display: block;
    }
  }

  .ddp-txt-slide em.ddp-icon-global {
    width: 14px;
    background-position-x: 0;
  }
}

.ddp-checkbox-slide input[type='checkbox'] {
  &:checked + label + .ddp-txt-slide {
    &:after {
      color: $primary;
      content: 'Global on';
    }

    em.ddp-icon-global {
      background-position-x: -42px;
    }
  }

  + label + .ddp-txt-slide:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #b7b9c2;
    content: '';
  }
}

.ddp-ui-popup-contents .ddp-ui-filter-set .ddp-wrap-edit3 {
  margin-bottom: 5px;
}

.ddp-ui-status {
  position: absolute;
  top: 19px;
  left: 0;
  right: 0;
  bottom: 0;

  &.ddp-filtering {
    .ddp-ui-edit-set {
      display: block;
    }

    .ddp-box-item-search {
      top: 284px;
    }
  }
}

.ddp-wrap-status {
  position: absolute;
  top: 160px;
  left: 16px;
  right: 0;
  bottom: 19px;

  .ddp-ui-top {
    color: #b7b9c2;
    font-size: 13px;
  }

  .ddp-wrap-edit-set {
    margin-top: -23px;

    a.ddp-btn-filter {
      float: right;
      width: 124px;
      height: 27px;
      line-height: 27px;
      background-color: #f6f6f7;
      text-align: center;
      color: #b7b9c2;
      font-size: 13px;

      em.ddp-icon-view {
        display: inline-block;
        width: 9px;
        height: 5px;
        margin-left: 10px;
        background: url(/assets/bi/images/icon_dropdown.png) no-repeat;
      }
    }
  }

  .ddp-ui-edit-set {
    display: none;
    clear: both;
    position: relative;
    padding: 0 30px 15px 18px;
    background-color: #f6f6f7;
    z-index: 1;
  }
}

.ddp-ui-edit-set {
  .ddp-label-title {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    color: #444;
    font-size: 14px;

    a.ddp-btn-reset {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 13px;
      margin-left: 5px;
      background: url(/assets/bi/images/icon_reset.png) no-repeat;
      background-position: left -20px;
      vertical-align: middle;

      &:hover {
        background-position: -15px -20px;
      }
    }
  }

  .ddp-data-info {
    display: inline-block;
    padding: 5px 0 0 0;
    margin-left: 5px;
    color: #4b515b;
    font-size: 12px;

    span {
      &.ddp-data {
        color: #90969f;
        font-style: italic;
      }

      &.ddp-data2 {
        color: #ca4b4b;
      }
    }
  }
}

.ddp-wrap-status .ddp-ui-edit-set .ddp-ui-buttons {
  padding-top: 16px;

  .ddp-btn-pop {
    margin-right: 6px;

    &.ddp-bg-black {
      border: 1px solid #90969f;
      background-color: #90969f;

      &:hover {
        border: 1px solid #4b515b;
        background-color: #4b515b;
      }
    }
  }
}

.ddp-form-edit-set [class*='ddp-col-'] {
  padding-left: 3px;
  box-sizing: border-box;

  &:first-of-type {
    padding-left: 0;
    margin-bottom: 4px;
  }
}

.ddp-box-item-search {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 9px 0 14px;
  box-sizing: border-box;

  .ddp-form-search {
    margin-top: 7px;
    width: 100%;
  }

  .ddp-search-option {
    position: relative;
    padding-right: 63px;
  }

  a.ddp-btn-eyes {
    position: absolute;
    bottom: 0;
    right: 9px;
    width: 25px;
    height: 19px;
    border-radius: 2px;
    border: 1px solid #d3d5d9;

    &:hover {
      border: 1px solid #9da0a7;
    }

    em {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -8px;
      background-position: left -12px;
    }

    &.ddp-selected em {
      background-position: -17px top;
    }
  }

  .ddp-data-result {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;

    .ddp-data-value {
      position: relative;
      top: -3px;
      padding-left: 13px;
      color: #90969f;
    }
  }

  .ddp-list-checktype {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 99px;
    padding: 0 24px 0 24px;
    box-sizing: border-box;
    overflow-y: auto;

    li {
      position: relative;
      padding: 4px 0;

      .ddp-label-checkbox span.ddp-txt-checkbox,
      .ddp-label-radio span.ddp-txt-radio {
        font-size: 14px;
      }

      &.ddp-value-search {
        .ddp-label-radio span.ddp-txt-radio,
        .ddp-label-checkbox span.ddp-txt-checkbox {
          color: #9ca2cc;
        }
      }
    }

    &.ddp-type-list li {
      position: relative;
      padding-right: 30px;
      font-size: 0;

      .ddp-setting {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 13px;
        color: #b7b9c2;

        .ddp-label-setting {
          float: left;

          .ddp-icon-eye {
            display: inline-block;
            position: relative;
            top: 2px;
            width: 16px;
            height: 11px;
            margin-right: 3px;
            background: url(/assets/bi/images/icon_eyes.png) no-repeat;
            vertical-align: top;
          }
        }

        .ddp-setting-check {
          float: left;

          a {
            display: inline-block;
            position: relative;
            padding: 0 7px;
            color: #b7b9c2;

            &:before {
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 0;
              height: 10px;
              margin-top: -5px;
              border-right: 1px solid #e7e7ea;
              content: '';
            }

            &:last-of-type {
              padding-right: 0;

              &:before {
                display: none;
              }
            }

            &:hover {
              color: #4c515a;
            }
          }
        }
      }

      .ddp-list-all {
        padding: 6px 0 11px 0;
        margin-bottom: 5px;
        border-bottom: 1px dotted #ddd;
      }
    }

    li {
      .ddp-label-checkbox {
        display: inline-block;

        span.ddp-data-valuenum {
          position: relative;
          top: -1px;
          margin-left: 5px;
          color: #90969f;
          font-size: 12px;
        }
      }

      .ddp-label-radio span.ddp-data-valuenum {
        position: relative;
        top: -1px;
        margin-left: 5px;
        color: #90969f;
        font-size: 12px;
      }

      [class*='ddp-icon-eyes2'] {
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -6px;
        cursor: pointer;
      }

      .ddp-icon-eyes2-none {
        background-position: left -24px;
      }
    }
  }

  &.ddp-type-filter .ddp-list-checktype li {
    [class*='ddp-icon-eyes2'] {
      background-position: -15px -36px;
    }

    .ddp-icon-eyes2-none {
      width: 15px;
      background-position: left -36px;
    }
  }

  .ddp-list-checktype li {
    em.ddp-icon-sclose2 {
      display: none;
      position: absolute;
      top: 50%;
      right: -13px;
      margin-top: -5px;
    }

    &:hover em.ddp-icon-sclose2 {
      display: block;
    }
  }

  .ddp-ui-page {
    position: absolute;
    right: 24px;
    bottom: 0;
  }

  .ddp-box-search {
    position: absolute;
    bottom: 0;
    left: 13px;
    right: 0;
    padding: 9px 79px 9px 11px;
    background-color: #f6f6f7;

    .ddp-btn-solid3 {
      display: inline-block;
      position: absolute;
      top: 9px;
      right: 6px;
      padding: 6px 0;
      width: 65px;
      color: #90969f;
      font-size: 13px;
      text-align: center;
      border: 1px solid #d0d1d7;
      border-radius: 2px;
    }
  }
}

.ddp-ui-filter-set {
  .ddp-wrap-edit3 {
    &.ddp-field {
      margin-top: 33px;

      ul.ddp-list-buttons.ddp-part3 li {
        width: 76px;
      }
    }

    .ddp-label-type,
    label.ddp-label-subtype {
      width: 120px;
    }

    .ddp-data-result {
      padding: 5px 0 0 120px;
      color: #b7b9c2;
    }
  }

  .ddp-ui-part {
    padding: 70px 0 0 0;

    .ddp-wrap-edit3 {
      margin-bottom: 20px;
    }
  }
}

.ddp-ui-field-type {
  width: 217px;
  padding-top: 15px;

  .ddp-form-date + .ddp-form-date {
    padding-top: 14px;
  }

  .ddp-ui-top-type {
    position: relative;
    margin-bottom: 5px;

    .ddp-ui-buttons {
      position: absolute;
      top: -3px;
      right: 0;
      display: inline-block;
    }

    [class*='ddp-icon-link-'] {
      margin-left: 6px;
    }

    .ddp-icon-link-plus:hover {
      background-position: -10px -2px;
    }

    .ddp-icon-link-cut2:hover {
      background-position: -50px -14px;
    }

    .ddp-ui-check-type {
      position: relative;
      padding: 9px 0 0 38px;
    }
  }

  .ddp-ui-check-type {
    position: relative;
    padding: 0 0 0 38px;
    margin: 10px 0 0 0;

    .ddp-ui-buttons {
      margin-top: 0;
      top: 0;

      .ddp-icon-link-cut2 {
        position: relative;
        top: -1px;
        background-position: -50px -14px;

        &:hover {
          background-position: -62px -14px;
        }
      }
    }
  }

  label.ddp-label-checkbox {
    display: inline-block;

    span.ddp-txt-checkbox {
      font-size: 12px;
    }
  }

  .ddp-data-status {
    color: #90969f;
    font-size: 12px;
  }
}

.ddp-ui-filter-set .ddp-ui-edit-option .ddp-list-chartlist li a {
  display: block;
  position: relative;
  padding: 6px 0 6px 16px;
  color: #4b515b;
  font-size: 13px;

  em.ddp-icon-chart-s {
    position: absolute;
    top: 9px;
    left: 0;
  }

  &:hover {
    font-weight: bold;
  }
}

/**********************************************************************************
	page : 대시보드 필터
**********************************************************************************/

.ddp-area-boardside {
  .ddp-ui-down-title2 {
    position: relative;
    min-height: 50px;
    margin: 0 -10px 0 -17px;
    padding: 0 10px 0 17px;
    cursor: move;

    &.ddp-selected {
      background-color: #f4f3fa;

      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #a6acd2;
        content: '';
        z-index: 1;
      }
    }
  }

  .ddp-box-down .ddp-wrap-divide {
    padding-top: 0;
  }

  .ddp-contents-divide.ddp-type {
    // margin-top: -10px;
  }

  .ddp-ui-down-title2 {
    .ddp-ui-buttons {
      position: relative;
      top: 17px;
      float: right;
      background: none;
    }

    .ddp-wrap-morebutton {
      display: inline-block;
    }

    .ddp-ui-buttons {
      .ddp-wrap-datalock,
      .ddp-wrap-datarecommend,
      em[class*='ddp-icon-'] {
        vertical-align: middle;
        margin-left: 7px;
      }

      .ddp-wrap-morebutton.ddp-selected .ddp-wrap-popup2 {
        z-index: 15;
      }
    }

    .ddp-hover-tooltip:hover .ddp-ui-tooltip-info {
      z-index: 15;
    }

    .ddp-ui-buttons .ddp-wrap-popup2 em[class*='ddp-icon-'] {
      margin-left: 0;
    }

    &:hover {
      background-color: #e7e7ea;

      .ddp-wrap-datalock em.ddp-icon-lock-s2,
      .ddp-wrap-datarecommend em.ddp-icon-recommend-s2 {
        background-color: #e7e7ea;
        border: 1px solid #e7e7ea;
      }
    }
  }

  .ddp-box-down {
    padding: 0 17px 10px;

    &.ddp-border-none {
      border-bottom: none;
    }
  }

  .ddp-wrap-divide {
    .ddp-ui-divide {
      clear: both;
      padding-bottom: 10px;
      padding-right: 10px;

      .ddp-ui-page.ddp-center {
        overflow: hidden;
        padding-top: 10px;
        text-align: center;

        * {
          display: inline-block;
          float: inherit;
          vertical-align: middle;
        }

        .ddp-data-page {
          vertical-align: top;
        }
      }

      .ddp-ui-slider-type {
        margin: 0;

        .irs .irs {
          padding-top: 14px;
        }
      }
    }

    &.ddp-dropdown {
      .ddp-ui-divide {
        padding-bottom: 0;
        margin-bottom: -10px;

        .ddp-ui-down-title2 {
          cursor: pointer;
        }
      }

      .ddp-contents-divide,
      .ddp-none-bar {
        display: none;
      }

      &.ddp-selected {
        .ddp-ui-divide {
          padding-bottom: 10px;
          margin-bottom: 0;

          .ddp-search-value {
            margin: 5px 0 3px 0;
          }
        }

        .ddp-contents-divide {
          display: block;
          padding-left: 10px;
        }
      }
    }
  }

  .ddp-ui-divide {
    .ddp-no-result {
      .ddp-wrap-info {
        padding: 5px 0 0 0;
      }

      .ddp-ui-info {
        position: relative;
        padding: 8px 8px 8px 28px;
        border: 1px solid #e7e7ea;
        border-radius: 2px;
        background-color: #f6f6f7;
        color: #90969f;
        font-size: 12px;

        .ddp-icon-info {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 8px;
          margin-top: -7px;
          width: 14px;
          height: 14px;
          background: url(/assets/bi/images/icon_info3.png) no-repeat;
          background-position: left -94px;
        }
      }

      .ddp-txt-noresult {
        display: block;
        padding: 14px 15px;
        color: #b6b9c2;
      }
    }

    .ddp-ui-filter-info {
      position: relative;
      padding: 8px;
      border: 1px solid #e7e7ea;
      border-radius: 2px;
      background-color: #f6f6f7;
      color: #90969f;
      font-size: 12px;
      word-break: break-all;
    }

    .ddp-flex-search {
      display: flex;

      .ddp-search-value {
        flex: 1 auto;
      }

      a.ddp-btn-line {
        margin: 5px 0 3px 5px;
        padding: 6px 3px;
        min-width: auto;
        white-space: nowrap;
      }
    }

    .ddp-select-setting {
      padding: 4px 0 9px 0;

      .ddp-list-select {
        float: right;
        margin: 0 -10px;

        li {
          float: left;

          a {
            display: block;
            position: relative;
            padding: 0 10px;
            color: #b6b9c2;
            font-size: 12px;
            text-decoration: underline;

            &:hover {
              color: #4b515b;
            }
          }

          + li a:before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -5px;
            height: 10px;
            border-left: 1px solid #d0d1d8;
            content: '';
          }
        }
      }

      .ddp-data-total {
        display: block;
        overflow: hidden;
        color: #90969f;
        font-size: 12px;
      }
    }

    .ddp-list-checktype li.ddp-value-search {
      .ddp-label-checkbox span.ddp-txt-checkbox,
      .ddp-label-radio span.ddp-txt-radio {
        color: #9ca2cc;
      }
    }

    .ddp-ui-values {
      padding: 8px 10px 0 17px;
      margin: 0 -10px -10px -17px;
      border-top: 1px dotted #d0d1d8;
    }
  }
}

.ddp-contents-divide .ddp-time-filter {
  margin-top: -5px;

  .ddp-box-data {
    padding: 18px 0;
    border: 1px solid #d6d7dd;
    border-radius: 2px;
    color: #4b515b;
    font-size: 14px;
    text-align: center;
  }

  dl.ddp-dl-date {
    dt {
      display: block;
      padding: 5px 0 0 0;
      color: #90969f;
      font-size: 13px;
    }

    dd {
      padding: 4px 0 0 0;

      .ddp-date-check {
        padding-bottom: 8px;

        input {
          float: left;
          width: 110px;
          margin-right: 6px;
        }

        .ddp-label-checkbox {
          float: left;
          position: relative;
          top: 6px;
        }
      }

      .ddp-list-check {
        margin: -2px -2px;

        li {
          float: left;
          width: 50%;
          padding: 2px;
          box-sizing: border-box;

          &.ddp-full {
            width: 100%;
          }

          a {
            display: block;
            padding: 7px 7px;
            border-radius: 2px;
            background-color: #e7e7ea;
            color: #4b515b;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
              background-color: #dbdce1;
            }
          }

          &.ddp-selected a {
            background-color: #4c515a;
            color: #fff;

            &:before {
              display: inline-block;
              width: 14px;
              height: 10px;
              margin-right: 4px;
              background: url(/assets/bi/images/icon_select2.png) no-repeat;
              vertical-align: middle;
              content: '';
            }
          }

          a .ddp-input-typebasic {
            display: inline-block;
            width: 50px;
            margin: 0 4px;
            text-align: right;
          }
        }
      }
    }
  }
}

.ddp-ui-values {
  padding: 6px 0 0 0;
  margin-bottom: -10px;
  overflow: hidden;

  .ddp-data-values {
    float: left;
    color: #90969f;
    font-size: 12px;
  }

  .ddp-label-check-value {
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;

    .ddp-value-num {
      margin-left: 4px;
      vertical-align: top;
    }

    .ddp-value {
      margin-left: 4px;
      font-size: 12px;
      color: #90969f;
      vertical-align: top;
    }

    input[type='checkbox'] {
      &:checked {
        + i + .ddp-value-num {
          display: none;
        }

        + i + .ddp-value-num + .ddp-value {
          display: inline-block;
        }
      }

      + i + .ddp-value-num + .ddp-value {
        display: none;
      }
    }
  }

  .ddp-ui-page {
    float: right;
    position: relative;
    top: -3px;
  }
}

.ddp-area-boardside .ddp-ui-down-title2.ddp-flex {
  display: flex;
  align-items: center;

  .ddp-itemtype-title {
    flex: 1 1 auto;
  }

  .ddp-data-itemtype .ddp-txt-itemtype {
    display: block;
  }

  .ddp-flex-button {
    margin-left: auto;
    display: flex;
    align-items: center;

    .ddp-icon-boardside-lock,
    .ddp-wrap-morebutton,
    .ddp-icon-global-s {
      margin-left: 7px;
      min-width: 13px;
    }

    .ddp-wrap-morebutton .ddp-wrap-popup2 .ddp-list-layer-option.type-sub-down {
      width: 220px;

      .ddp-ui-layer-sub {
        min-width: 100px;
      }
    }
  }
}

.ddp-itemtype-title {
  min-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}

.ddp-data-itemtype {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 10px 0 10px 40px;
  box-sizing: border-box;
  font-size: 0;

  &.ddp-type .ddp-txt-itemtype {
    white-space: normal;
  }

  .ddp-icon-box {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -14px;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 1px solid #439fe5;
    border-radius: 4px;

    &.ddp-measure {
      float: left;
      border: 1px solid #5fd7a5;
    }

    [class*='ddp-icon-'] {
      position: relative;
      top: 8px;
    }

    .ddp-icon-dimension-point,
    .ddp-icon-measure-point,
    .ddp-icon-dimension-line,
    .ddp-icon-measure-line {
      margin: -6px 0 0 -5px;
    }

    .ddp-icon-dimension-polygon,
    .ddp-icon-measure-polygon {
      margin: -7px 0 0 -7px;
    }

    .ddp-icon-use3 {
      display: inline-block;
      position: absolute;
      bottom: -4px;
      right: -5px;
      top: initial;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_use.png) no-repeat;
      background-color: #f7f7f8;
    }
  }
}

.ddp-area-boardside .ddp-ui-down-title2 .ddp-icon-use3 {
  background-color: #e7e7ea;
}

.ddp-data-itemtype .ddp-txt-itemtype {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-size: 13px;
  color: #4b515b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;

  .ddp-data-name {
    display: block;
    font-size: 13px;
    color: #4b515b;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }

  .ddp-data-sub {
    display: block;
    max-height: 29px;
    font-size: 11px;
    color: #b7b9c2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .ddp-txt-chartname {
    display: block;
    width: 100%;
    color: #b7b9c2;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }

  &.ddp-error {
    position: relative;
    padding-right: 20px;

    .ddp-icon-error2 {
      position: absolute;
      top: 50%;
      right: 4px;
      margin-top: -7px;
    }
  }
}

.ddp-wrap-filtertime {
  padding-bottom: 20px;

  &:last-of-type {
    padding-bottom: 0;
  }

  dl.ddp-dl-time {
    dt {
      float: left;
      width: 60px;
      color: #90969f;
      font-size: 13px;
      line-height: 24px;
    }

    dd {
      display: block;
      font-size: 13px;
      color: #b7b9c2;
      line-height: 24px;
      overflow: hidden;

      &.ddp-data-time {
        color: #4c515a;
        font-size: 13px;
      }
    }
  }
}

.ddp-area-boardside .ddp-ui-divide {
  .ddp-data-preview {
    color: #4c515a;
    font-size: 14px;
    text-align: center;
  }

  .ddp-none-bar {
    position: relative;
    padding: 0 15px;
    margin: 0 -17px 0 -17px;
    color: #90969f;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    background: #efeff1;

    &.ddp-select-bar {
      cursor: pointer;

      .ddp-wrap-popup2 {
        left: 0;
        font-weight: normal;
      }

      .ddp-icon-view {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 7px;
        height: 4px;
        margin-left: 9px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -24px top;
      }
    }
  }
}

.ddp-wrap-divide .ddp-dropdown-filter + .ddp-dropdown-filter {
  margin-top: 1px;
}

.ddp-area-boardside .ddp-ui-divide .ddp-none-bar.ddp-select-bar:hover {
  background-color: #dfe0e4;
}

.ddp-dropdown-filter {
  &.ddp-selected {
    .ddp-none-bar.ddp-select-bar {
      background-color: #dfe0e4;
    }

    .ddp-select-bar .ddp-icon-view {
      transform: rotate(180deg);
    }
  }

  position: relative;
  margin-bottom: -20px;

  .ddp-wrap-popup2 {
    display: none;
    width: 238px;
    position: absolute;
    top: 100%;
    left: -14px;
    padding: 6px 0;

    .ddp-label-type {
      padding: 10px 12px 3px 12px;
      color: #b7b9c2;
      font-size: 12px;
    }
  }

  &.ddp-selected {
    .ddp-wrap-popup2 {
      display: block;
      z-index: 20;
    }

    .ddp-dropdown-data {
      display: block;
    }
  }

  .ddp-dropdown-data2 .ddp-contents-divide {
    padding: 12px 0;
  }

  .ddp-top-summary {
    cursor: pointer;

    .ddp-icon-view {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 7px;
      height: 4px;
      margin-left: 9px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -24px top;
    }

    &:hover {
      background-color: #dfe0e4;
    }
  }

  &.ddp-selected .ddp-top-summary {
    background-color: #dfe0e4;

    .ddp-icon-view {
      transform: rotate(180deg);
    }
  }
}

.ddp-filter1 .ddp-dropdown-filter.ddp-selected .ddp-wrap-popup2 {
  left: 20px;
  z-index: 20;
}

.ddp-dropdown-data2 {
}

.ddp-dropdown-data {
  display: none;
  margin: 0 -17px;
  background-color: #efeff1;

  .ddp-list-data {
    padding: 15px 17px;
    border-top: 1px solid #ddd;

    &:first-of-type {
      border-top: none;
    }

    .ddp-data-name {
      color: #4b515b;
      font-size: 13px;

      [class*='ddp-icon-'] {
        margin-right: 9px;
      }
    }

    .ddp-data-det {
      padding-top: 9px;
      color: #90969f;

      .ddp-time {
        color: #b7b9c2;
      }
    }
  }
}

.ddp-area-boardside {
  .ddp-toggle {
    padding-bottom: 20px;
  }

  .ddp-list-buttons {
    width: 100%;

    &.ddp-tab3 li {
      width: 33.33333%;
      min-width: auto;
      white-space: nowrap;
    }
  }

  .ddp-ui-toggle-contents .ddp-txt-preview {
    display: block;
    color: #4c515a;
    font-size: 14px;
    text-align: center;
  }

  .ddp-filter-area {
    .ddp-filter-type {
      padding: 10px 0 0 0;

      &:first-of-type {
        padding: 10px 0 0 0;
      }
    }

    position: relative;
    top: 0;
    padding: 0;
    border-top: none;
    overflow: inherit;

    .ddp-list-buttons,
    .ddp-filter-type .ddp-btn-time {
      background-color: #efeff1;
    }

    .ddp-form-multy .ddp-box-multy.ddp-form-time {
      .ddp-input-typebasic {
        width: 45%;
      }

      .ddp-type-selectbox {
        width: 55%;
      }
    }
  }
}

.ddp-filter-area .ddp-form-multy .ddp-box-multy.ddp-form-time .ddp-type-selectbox.ddp-selected {
  z-index: inherit;
}

.ddp-area-boardside {
  .ddp-dateinfo-view {
    position: relative;
    padding: 0;
    overflow: inherit;
  }

  .ddp-filter-calen-in {
    padding-bottom: 20px;
  }

  .ddp-filter-dateinfo .ddp-ui-dateinfo {
    width: 100%;

    .ddp-box-rome {
      width: 100%;
    }

    &:last-of-type {
      padding-top: 6px;
      text-align: left;
    }

    .ddp-dateinfo-in {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .ddp-ui-dateinfo {
    .ddp-box-dateinfo .ddp-box-date {
      padding-left: 10px;
    }

    .ddp-dateinfo-in .rd-container {
      width: initial;
      margin-left: -34px;
      text-align: center;
    }
  }

  .ddp-dateinfo-view .ddp-btn-line-s {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .ddp-form-multy {
    span.ddp-txt-label.ddp-color {
      color: #4b515b;
    }

    &.ddp-full .ddp-box-multy.ddp-form-time {
      .ddp-input-typebasic {
        width: 45%;
      }

      .ddp-type-selectbox {
        width: 55%;
      }
    }
  }
}

/**********************************************************************************
	page : 대시보드 파라미터
**********************************************************************************/

.ddp-data-itemtype {
  em.dpd-icon-box-parameter {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -14px;
    width: 28px;
    height: 28px;
    background: url(/assets/bi/images/img_parmeter.png) no-repeat;
  }

  .ddp-txt-itemtype.ddp-type {
    padding-right: 5px;
    position: relative;
    line-height: 16px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}

/**************************************************************
  popup : 02_워크북_02대시보드_05데이터보기(데이터그리드,컬럼)_popup
**************************************************************/

.popup-dashboard-dataview {
  .ddp-type-option .ddp-box-btn2 {
    color: #4b515b;
  }

  .ddp-wrap-grid-option {
    &.ddp-top-option .ddp-fleft {
      padding: 9px 14px 9px 14px;
    }

    &.ddp-setting {
      background: none;
    }
  }

  .ddp-type-preview .ddp-ui-tab-contents .ddp-ui-grid2 {
    top: 50px;

    &.ddp-info {
      top: 80px;
    }

    &.ddp-type {
      top: 100px;

      &.ddp-info {
        top: 131px;
      }
    }
  }
}

.ddp-pop-datadetail .ddp-wrap-grid-option.ddp-setting .ddp-fleft {
  padding: 9px 14px 9px 14px;
}

/**************************************************************
  popup : 02_워크북_02대시보드_01추가_03조인옵션_popup
**************************************************************/

.popup-dashboard-join {
  .ddp-wrap-board-join {
    height: 100%;
    padding-top: 110px;
    padding-bottom: 20px;
    margin-top: -120px;
    box-sizing: border-box;
  }

  .ddp-ui-board-join {
    height: 100%;
    min-height: 600px;

    .ddp-board-box-contents {
      height: 50%;
      padding: 0 50px;

      .ddp-box-pop-contents {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
      }
    }

    .ddp-board-join-type {
      height: 50%;
      box-sizing: border-box;

      .ddp-ui-join-type {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40%;
      }

      .ddp-box-resultpreview {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 60%;
        padding: 0 50px;
        min-height: auto;
      }
    }

    .ddp-box-resultpreview .ddp-wrap-gird-form {
      position: relative;
      margin-top: -54px;
      padding: 54px 0 0 0;
      height: 100%;
      box-sizing: border-box;

      .ddp-ui-grid-form {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        box-sizing: border-box;
      }
    }

    &.ddp-default {
      .ddp-board-box-contents {
        height: 100%;
      }

      .ddp-ui-joinkeys,
      .ddp-board-join-type {
        display: none;
      }

      .ddp-ui-part .ddp-ui-grid-form {
        bottom: 20px;
      }
    }
  }

  .ddp-ui-popup-join .ddp-ui-part .ddp-ui-set ul.ddp-list-tab-button li .ddp-ui-tooltip-info {
    right: 0;
    left: inherit;

    em.ddp-icon-view-top {
      left: inherit;
      right: 11px;
    }
  }
}

/**********************************************************************************
        page: 필터 new add filter
**********************************************************************************/

.ddp-pop-filter {
  position: absolute;
  top: 85px;
  left: 50%;
  bottom: 85px;
  margin: 0 0 0 -300px;
  width: 600px;
  background-color: #fff;
  z-index: 127;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */

  .ddp-txt-title-name {
    padding: 17px 25px;
    color: #4b515b;
    font-size: 16px;

    .ddp-icon-filter {
      display: inline-block;
      width: 15px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_title.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-wrap-source-name {
      float: right;
      margin-top: -5px;

      .ddp-box-layout4.ddp-datasearch .ddp-form-search {
        width: 200px;
      }
    }
  }

  .ddp-wrap-tab {
    z-index: 1;
  }

  .ddp-ui-popup-contents {
    margin-top: -54px;
    padding: 54px 0 0 0;
  }

  .ddp-wrap-tab-contents {
    height: 100%;
  }

  .ddp-ui-tab-contents {
    position: relative;
    margin-top: -60px;
    padding: 60px 28px 0 28px;
    height: 100%;
    box-sizing: border-box;
  }

  .ddp-filter0 {
    position: absolute;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 63px;
    padding: 0 28px;

    .ddp-list-filter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }

  .ddp-ui-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 0;
    text-align: center;
    border-top: 1px solid #e7e7ea;

    [class*='ddp-btn-type'] {
      margin: 0 2px;
    }
  }

  .ddp-top-filter {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    z-index: 1;

    .ddp-ui-tooltip-info {
      position: absolute;
      top: 100%;
      left: 90px;

      em.ddp-icon-view-top {
        left: 30px;
        margin-left: 0;
      }
    }

    .ddp-title .ddp-btn-back {
      position: relative;
      float: left;
      display: inline-block;
      width: 50px;
      height: 60px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -8px;
        width: 17px;
        height: 16px;
        background: url(/assets/bi/images/btn_back.png) no-repeat;
        vertical-align: middle;
        content: '';
      }
    }

    border-bottom: 1px solid #d7d8dd;

    .ddp-ui-tags {
      float: right;
      padding: 19px 10px 0 0;

      .ddp-tag-icon {
        float: left;

        [class*='ddp-icon-'] {
          float: left;
          margin-right: 10px;
        }

        .ddp-icon-recommend {
          position: Relative;
          top: 2px;
        }

        .ddp-icon-global-s {
          position: Relative;
          top: 3px;
        }
      }
    }

    .ddp-title {
      display: block;
      overflow: hidden;

      .ddp-txt-name {
        display: block;
        padding: 15px 8px 0 8px;
        color: #545c66;
        font-size: 16px;
        overflow: hidden;

        &.ddp-type {
          padding-top: 10px;
        }
      }

      .ddp-icons {
        float: left;
      }

      .ddp-txt-name {
        .ddp-txt-data {
          display: block;
          color: #545c66;
          font-size: 16px;
          font-weight: bold;
        }

        .ddp-txt-sub {
          display: block;
          color: #90969f;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }

      .ddp-icon-box {
        display: inline-block;
        position: relative;
        top: 16px;
        height: 28px;
        width: 28px;
        border-radius: 1px;
        border: 1px solid #439fe5;
        font-size: 0;
        text-align: center;
        box-sizing: border-box;
        vertical-align: middle;

        &.ddp-measure {
          border: 1px solid #5fd7a5;
        }
      }

      [class*='ddp-icon-measure'],
      [class*='ddp-icon-dimension'] {
        position: relative;
        top: 8px;
      }

      .ddp-icon-dimension-int,
      .ddp-icon-dimension-float,
      .ddp-icon-measure-int,
      .ddp-icon-measure-float {
        top: 9px;
      }

      .ddp-ui-tags {
        float: right;
        margin-top: 14px;
        right: 24px;
      }
    }

    .ddp-ui-label {
      overflow: hidden;
    }
  }
}

.ddp-add-filter {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  .ddp-data-filter {
    position: relative;
    padding: 7px 50px 7px 25px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    &:hover {
      background-color: #f6f6f7;
    }
  }

  li.ddp-selected .ddp-data-filter {
    background-color: #f6f6f7;
  }

  .ddp-sub-filter ul.ddp-list-subfilter li .ddp-txt-filter {
    display: block;
    padding-left: 22px;
    max-width: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    box-sizing: border-box;
  }

  .ddp-data-filter .ddp-txt-filter {
    display: block;
    padding-left: 22px;
    max-width: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    box-sizing: border-box;

    .ddp-sub-name {
      position: relative;
      top: -2px;
      margin-left: 4px;
      font-weight: 300;
      color: #4b515b;
      font-size: 14px;
      vertical-align: top;
    }
  }

  .ddp-sub-filter ul.ddp-list-subfilter li .ddp-txt-filter {
    padding-left: 0;

    .ddp-data-date {
      margin-left: 5px;
      color: #b7b9c2;
      font-size: 13px;
    }
  }

  .ddp-data-filter em {
    &[class*='ddp-icon-dimension-'],
    &[class*='ddp-icon-measure-'] {
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
}

.ddp-list-filter li.ddp-selected .ddp-sub-filter {
  display: block;
}

.ddp-add-filter {
  .ddp-sub-filter {
    display: none;

    span.ddp-label-filter {
      display: block;
      padding: 7px 50px 8px 50px;
      font-size: 12px;
      color: #b7b9c2;
      font-weight: bold;
    }

    ul.ddp-list-subfilter li {
      position: relative;
      padding: 6px 50px 6px 60px;
      color: #4b525b;
      font-size: 14px;
      cursor: pointer;

      &:hover,
      &.ddp-selected {
        background-color: #f6f6f7;
      }
    }
  }

  ul.ddp-list-filter li {
    .ddp-ui-icons {
      float: right;
      height: 16px;
      margin-right: -25px;

      [class*='ddp-icon-'] {
        margin-left: 5px;
      }

      .ddp-wrap-datarecommend,
      .ddp-icon-recommend,
      .ddp-btn-edit,
      .ddp-btn-add {
        position: relative;
        top: 0;
        margin-left: 8px;
        vertical-align: middle;
      }

      .ddp-link-filter {
        color: #90969f;
        font-weight: 300;
        text-decoration: underline;
        font-style: italic;
        font-size: 11px;
      }
    }

    .ddp-btn-add {
      display: inline-block;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -56px -22px;

      &:hover {
        background-position-x: -70px;
      }
    }

    .ddp-btn-edit {
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url(/assets/bi/images/icon_control.png) no-repeat;
      background-position: -26px -97px;

      &:hover {
        background-position-x: -52px;
      }
    }
  }
}

.ddp-filter1 {
  position: relative;
  height: 100%;
  padding: 60px 0 0 0;
  box-sizing: border-box;

  .ddp-dropdown-filter {
    margin-bottom: 0;
  }

  .ddp-wrap-setting {
    padding: 8px 25px;
    width: 100%;
    background-color: #f6f6f7;
    overflow: hidden;
    box-sizing: border-box;

    .ddp-wrap-edit {
      .ddp-label-type {
        padding-right: 15px;
        letter-spacing: -1px;
      }

      .ddp-ui-edit-option .ddp-type-selectbox {
        width: 148px;
        background-color: #fff;
      }
    }
  }

  .ddp-box-item-search {
    top: 166px;
    bottom: 67px;

    .ddp-list-checktype {
      top: 0;
      bottom: 91px;

      &.ddp-hover {
        padding: 0;

        li {
          padding: 6px 64px 6px 24px;
          cursor: pointer;

          &:hover {
            background-color: #f6f6f7;
          }

          [class*='ddp-icon-eyes2'] {
            right: 31px;
          }

          em.ddp-icon-sclose2 {
            right: 10px;
          }
        }
      }
    }

    .ddp-box-search {
      left: 0;
      border-top: 1px solid #e7e7ea;
    }
  }

  .ddp-toggle {
    padding: 18px 0 20px 0;
    text-align: center;

    .ddp-list-buttons.ddp-type2 li {
      padding: 10px 0;
      height: 34px;
    }

    .ddp-wrap-edit.type-option {
      padding: 0 24px;

      .ddp-label-type {
        line-height: 34px;
      }
    }
  }

  .ddp-option {
    position: relative;
    margin: 0 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .ddp-data-num {
      color: #90969f;
      font-weight: 300;
    }

    .ddp-option-btn {
      position: absolute;
      bottom: 3px;
      right: 0;
    }
  }

  .ddp-top-summary {
    padding: 4px 25px;
    border-top: 1px solid #e7e7ea;
    background-color: #f6f6f7;
  }

  .ddp-wrap-toggle-contents {
    position: absolute;
    top: 129px;
    left: 0;
    right: 0;
    bottom: 63px;
  }

  timeunit-select + .ddp-toggle + .ddp-wrap-toggle-contents,
  .ddp-dropdown-filter + .ddp-toggle + .ddp-wrap-toggle-contents,
  .ddp-top-summary + .ddp-toggle + .ddp-wrap-toggle-contents {
    top: 153px;
  }

  .ddp-wrap-toggle-contents {
    .ddp-txt-preview {
      display: block;
      padding: 10px 0 0 0;
      color: #4c515a;
      font-size: 14px;
      text-align: center;
    }

    .ddp-box-item-search {
      top: 38px;
      bottom: 0;
    }
  }
}

.ddp-dateinfo-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 24px 30px 24px;
}

.ddp-filter1 {
  .ddp-wrap-toggle-contents .ddp-box-item-search.ddp-edit-none {
    .ddp-data-result {
      bottom: 12px;
    }

    .ddp-list-checktype {
      bottom: 39px;
    }
  }

  .ddp-select-setting {
    padding: 0 24px 0 24px;

    .ddp-data-total {
      float: left;
      margin-right: 8px;
      color: #4b515b;
      font-size: 12px;
    }

    .ddp-list-select {
      float: left;
      margin: 0 -10px;

      li {
        float: left;

        a {
          display: block;
          position: relative;
          padding: 0 10px;
          color: #b6b9c2;
          font-size: 12px;
          text-decoration: underline;

          &:hover {
            color: #4b515b;
          }
        }

        + li a:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -5px;
          height: 10px;
          border-left: 1px solid #d0d1d8;
          content: '';
        }
      }
    }

    .ddp-setting {
      float: right;
      font-size: 13px;
      color: #b7b9c2;

      .ddp-label-setting {
        float: left;

        .ddp-icon-eye {
          display: inline-block;
          position: relative;
          top: 2px;
          width: 16px;
          height: 11px;
          margin-right: 3px;
          background: url(/assets/bi/images/icon_eyes.png) no-repeat;
          background-position: -32px -36px;
          vertical-align: top;
        }
      }

      .ddp-setting-check {
        float: left;

        a {
          display: inline-block;
          position: relative;
          padding: 0 7px;
          color: #b7b9c2;

          &:before {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 0;
            height: 10px;
            margin-top: -5px;
            border-right: 1px solid #e7e7ea;
            content: '';
          }

          &:last-of-type {
            padding-right: 0;

            &:before {
              display: none;
            }
          }

          &:hover {
            color: #4c515a;
          }
        }
      }
    }
  }

  &.type-filter {
    .ddp-wrap-toggle-contents {
      top: 107px;

      .ddp-option-in {
        padding-right: 62px;
      }

      .ddp-filter-form .ddp-box-item-search.ddp-type-filter {
        top: 77px;
        bottom: 0;
      }
    }

    .ddp-filter-form .ddp-box-item-search .ddp-list-checktype {
      bottom: 82px;
    }
  }
}

.ddp-filter-range {
  padding: 27px 25px 0 25px;
  box-sizing: border-box;
  overflow: hidden;

  .ddp-label-filter {
    padding-bottom: 28px;
    color: #4b515b;
    font-size: 13px;
  }

  .ddp-ui-slider-type {
    padding: 0 20px;
  }
}

.ddp-slider-bg {
  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    height: 9px;
    background-color: #e7e7ea;
    border-radius: 9px;
  }

  .irs-bar {
    height: 9px;
    background-color: #c1cef1;
  }

  .irs-line {
    height: 9px;
  }

  .irs-slider {
    background-color: $primary;
    cursor: pointer;

    &.from,
    &.to,
    &.single {
      bottom: 0;
    }

    &.from:hover,
    &.to:hover,
    &.single:hover,
    &.from.state_hover,
    &.to.state_hover,
    &.single.state_hover {
      bottom: -2px;
    }
  }
}

.ddp-dateinfo-view {
  .ddp-btn-add {
    display: block;
    clear: both;
    padding: 4px 7px;
    margin: 0 -5px;
    color: #90969f;
    font-size: 13px;

    &:hover {
      background-color: #f6f6f7;
    }
  }

  .ddp-ui-add {
    position: relative;

    &.ddp-disabled:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: no-drop;
      z-index: 1;
      content: '';
    }
  }

  .ddp-btn-add:before {
    display: inline-block;
    position: relative;
    top: 0;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: left -50px;
    content: '';
    vertical-align: middle;
  }
}

.ddp-filter-calen-in {
  padding-bottom: 30px;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  &:first-of-type .ddp-wrap-control .ddp-dateinfo-control:first-of-type,
  &:last-of-type .ddp-wrap-control .ddp-dateinfo-control:last-of-type {
    display: block;
  }
}

.ddp-filter-dateinfo {
  margin: 0 -5px;
  box-sizing: border-box;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  .ddp-ui-dateinfo {
    float: left;
    width: 50%;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;

    .ddp-dateinfo-in {
      position: relative;
      width: 100%;
      padding-left: 35px;
      display: inline-block;
      box-sizing: border-box;
    }
  }
}

.ddp-dateinfo-view .ddp-wrap-control {
  clear: both;
  padding-top: 5px;
  overflow: hidden;

  .ddp-dateinfo-control {
    display: none;
    width: 50%;
    position: relative;
    float: left;
    padding-left: 35px;
    box-sizing: Border-box;

    &:last-of-type {
      float: right;
    }
  }

  .ddp-icon-control-cut {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
    width: 11px;
    height: 12px;
    background: url(/assets/bi/images/icon_control.png) no-repeat;
    background-position: -26px -14px;

    &:hover {
      background-position-x: -50px;
    }
  }
}

.ddp-filter-dateinfo .ddp-ui-dateinfo {
  &:last-of-type {
    text-align: right;
  }

  .ddp-box-dateinfo {
    display: inline-block;
    text-align: left;
    width: 100%;
    box-sizing: border-box;

    .ddp-type-selectbox {
      float: left;
      width: 45%;
      border: none;
      border-left: 1px solid #d0d1d8;
      box-sizing: border-box;
      z-index: inherit;
    }
  }
}

.ddp-filter-view {
  max-width: 400px;
  min-width: 300px;
  box-sizing: border-box;

  &.ddp-full {
    max-width: 100%;

    .ddp-wrap-buttons {
      width: 100%;

      &.ddp-size ul.ddp-list-buttons li em {
        display: none;
      }
    }
  }

  .ddp-filter-area {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    border-top: none;
    overflow: inherit;
  }

  .ddp-txt-preview {
    display: block;
    padding: 5px 0;
    color: #4c515a;
    font-size: 16px;
    text-align: center;
  }

  .ddp-filter-area .ddp-filter-type {
    padding-top: 10px;
  }
}

.ddp-filter-area {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 25px;
  border-top: 1px solid #e7e7ea;
  overflow-y: auto;

  .ddp-list-buttons {
    width: 100%;
  }
}

.ddp-filter1 .ddp-filter-area {
  .ddp-list-buttons li,
  .ddp-filter-type .ddp-btn-time {
    background-color: #f6f6f7;
  }
}

.ddp-ui-widget-contents .ddp-filter-area {
  .ddp-list-buttons li,
  .ddp-filter-type .ddp-btn-time {
    background-color: #f6f6f7;
  }
}

.ddp-filter-area {
  .ddp-list-buttons li {
    width: 20%;
    min-width: 30px;
    border-radius: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    background-color: #efeff1;

    &.ddp-selected {
      background: #fff;
      color: #4b515b;
      font-size: 12px;

      &:before {
        background-position-y: -11px;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 2px;
        border: 1px solid #90969f;
        content: '';
      }
    }
  }

  .ddp-filter-type {
    padding: 49px 0 0 0;

    .ddp-label-name {
      display: block;
      padding-bottom: 5px;
      color: #90969f;
      font-size: 13px;
    }

    .ddp-btn-time {
      display: inline-block;
      position: relative;
      padding: 2px 3px 2px 10px;
      margin-top: 6px;
      border-radius: 2px;
      background-color: #f6f6f7;
      cursor: pointer;

      .ddp-form-multy {
        margin-top: 0;

        span.ddp-txt-label {
          color: #4c515a;
          font-size: 12px;
        }
      }

      &.ddp-selected {
        background-color: #fff;

        .ddp-form-multy span.ddp-txt-label:before {
          display: inline-block;
          position: relative;
          margin-right: 7px;
          width: 14px;
          height: 10px;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          background-position-y: -11px;
          vertical-align: middle;
          content: '';
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 2px;
          border: 1px solid #90969f;
          content: '';
        }
      }

      .ddp-form-multy .ddp-box-multy {
        top: 0;
      }
    }
  }
}

.ddp-filter-form {
  .ddp-option-type {
    padding: 0 25px 0 25px;

    .ddp-option-in {
      position: Relative;
      padding: 9px 92px 6px 0;
      border-bottom: 1px solid #e7e7ea;
    }

    .ddp-option-btn {
      position: absolute;
      top: 10px;
      right: 0;
    }

    .ddp-search-value {
      background: none;

      .ddp-data-value {
        background: #fff;
      }
    }

    .ddp-option-in .ddp-search-value .ddp-data-value .icon-search-p {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -8px;
      width: 14px;
      height: 15px;
      background: url('/assets/bi/images/icon_search.png') no-repeat;
      background-position: left -12px;
    }

    .ddp-search-value .ddp-data-value .icon-warning {
      position: absolute;
      top: 2px;
      right: 0;
      margin: 0;
      width: 25px;
      height: 25px;
      border-radius: 2px;
      border: 1px solid #cdd8f4;
      background: none;
      background-color: #f0f3fc;
      box-sizing: border-box;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -7px;
        width: 15px;
        height: 14px;
        background: url(/assets/bi/images/icon_info3.png) no-repeat;
        background-position: left -12px;
        content: '';
      }
    }
  }

  .ddp-option {
    padding: 25px 0 10px 0;

    &.ddp-type .ddp-search-value .ddp-data-value {
      background: none;
    }
  }
}

/*.ddp-filter-form .ddp-option-type .ddp-option-search {overflow:Hidden;}*/

.ddp-filter1 .ddp-filter-form .ddp-box-item-search {
  top: 135px;

  &.ddp-type-filter {
    top: 154px;
    bottom: 63px;
  }

  .ddp-list-checktype {
    margin-top: 7px;
    bottom: 35px;
  }

  .ddp-data-result {
    bottom: 12px;
  }
}

.ddp-option-btn .ddp-btn-box {
  display: inline-block;
  position: relative;
  width: 27px;
  height: 27px;
  margin-left: 4px;
  border-radius: 2px;
}

.ddp-filter1 .ddp-filter-form .ddp-box-item-search .ddp-data-result2 {
  &.type-result {
    bottom: 48px;
    padding: 6px 0;
  }

  position: absolute;
  bottom: 0;
  left: 25px;
  right: 25px;
  padding: 8px 0 9px 0;
  border-top: 1px dotted #ccc;

  .ddp-data-values {
    color: #90969f;
    vertical-align: middle;
  }

  .ddp-ui-page {
    position: relative;
    top: -1px;
    right: 0;
  }

  .ddp-label-check-value {
    position: relative;
    top: 3px;
    margin-right: 3px;

    &.type-check {
      display: inline-block;
      margin-right: 3px;
      vertical-align: middle;

      .ddp-value-num {
        margin-left: 4px;
        vertical-align: top;
      }

      .ddp-value {
        margin-left: 4px;
        font-size: 12px;
        color: #90969f;
        vertical-align: top;
      }

      input[type='checkbox'] {
        &:checked {
          + i + .ddp-value-num {
            display: none;
          }

          + i + .ddp-value-num + .ddp-value {
            display: inline-block;
          }
        }

        + i + .ddp-value-num + .ddp-value {
          display: none;
        }
      }
    }
  }
}

.ddp-option-btn {
  .ddp-wrap-edit-set.ddp-selected .ddp-btn-box,
  .ddp-btn-box:hover {
    background-color: #e7e7ea;
  }

  .ddp-ui-search {
    position: relative;
    float: left;
    cursor: pointer;

    .ddp-icon-search-default:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      width: 14px;
      height: 14px;
      background: url('/assets/bi/images/icon_search.png') no-repeat;
      background-position: left -12px;
      content: '';
    }

    &:hover .ddp-icon-search-default:before {
      background-position-x: -15px;
    }

    .ddp-form-search {
      display: none;
    }

    &.ddp-selected {
      .ddp-form-search {
        display: block;
      }

      .ddp-icon-search-default {
        display: none;
      }
    }
  }

  .ddp-box-btn2 {
    position: relative;
    float: left;
    margin-left: 4px;
    cursor: pointer;

    .ddp-icon-widget-info2 {
      float: left;
      position: relative;
      top: 1px;
      width: 25px;
      height: 25px;
      font-size: 0;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #cdd8f4;
      background-color: #f0f3fc;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -7px 0 0 -8px;
        width: 15px;
        height: 14px;
        background: url(/assets/bi/images/icon_widget.png) no-repeat;
        background-position: -16px -244px;
        content: '';
      }
    }

    .ddp-box-info.ddp-limitation {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 6px 10px;
      width: 240px;
      border-radius: 3px;
      background-color: #7182ba;
      color: #fff;
      box-sizing: border-box;
      z-index: 1000;
    }

    &:hover .ddp-box-info.ddp-limitation {
      display: block;
    }

    .ddp-box-info.ddp-limitation .ddp-total {
      display: block;
      padding-bottom: 4px;

      strong {
        display: block;
      }
    }
  }

  .ddp-wrap-edit-set {
    position: relative;
    float: left;

    .ddp-ui-tooltip-info {
      position: absolute;
      right: -5px;
      left: inherit;
      top: 100%;
      margin-top: 8px;
    }

    .ddp-ui-edit-set {
      display: none;
      position: absolute;
      top: 100%;
      right: -7px;
      width: 427px;
      padding: 13px 16px;
      border: 1px solid #e7e7ea;
      border-radius: 2px;
      background-color: #fff;
      z-index: 15;
      box-shadow: -1px 2px 3px 1px #edeff0;

      .ddp-pop-buttons {
        padding: 10px 0 0 0;
      }
    }

    &.ddp-selected .ddp-ui-edit-set {
      display: block;
      border: 1px solid #e7e7ea;
    }

    .ddp-ui-tooltip-info .ddp-icon-view-top {
      left: inherit;
      right: 17px;
    }

    .ddp-btn-reset {
      &:hover .ddp-ui-tooltip-info {
        display: block;
      }

      .ddp-ui-tooltip-info .ddp-icon-view-top {
        right: 10px;
      }
    }

    a.ddp-btn-filter:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/icon_filter.png) no-repeat;
      background-position: left -11px;
      content: '';
    }

    &.ddp-selected a.ddp-btn-filter:before {
      background-position-x: -16px;
    }

    a.ddp-btn-filter:hover {
      &:before {
        background-position-x: -16px;
      }

      + .ddp-ui-tooltip-info {
        display: block;
      }
    }
  }

  .ddp-ui-sort {
    float: left;
    margin: 0 0 0 4px;
    padding: 0;

    .ddp-icon-sort {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
    }

    .ddp-wrap-popup2 {
      top: 27px;
    }

    &.ddp-btn-box.ddp-selected {
      background-color: #e7e7ea;
    }

    &:hover .ddp-ui-tooltip-info {
      margin-top: 8px;

      em.ddp-icon-view-top {
        left: inherit;
        right: 12px;
      }
    }
  }

  .ddp-btn-eyes {
    em.ddp-icon-eyes2 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -8px;
    }

    &.ddp-selected em.ddp-icon-eyes2 {
      background-position-x: -34px;
    }
  }
}

/**********************************************************************************
            page : 대시보드 필터 essential
**********************************************************************************/

.ddp-ui-eessential {
  padding: 0 64px;

  .ddp-data-filter {
    padding-bottom: 10px;
    font-size: 13px;
    color: #4b515b;
  }
}

.ddp-type-setup {
  border-top: 1px solid #d0d1d8;

  .ddp-ui-top {
    position: relative;
    padding: 15px;
    background-color: #f7f7f8;

    .ddp-tag-name {
      display: block;
      font-weight: bold;
      font-size: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;

      .ddp-icon-box {
        display: inline-block;
        height: 21px;
        width: 21px;
        margin-right: 5px;
        border-radius: 1px;
        border: 1px solid #439fe5;
        text-align: center;
        box-sizing: border-box;
        vertical-align: middle;

        &.ddp-measure {
          border: 1px solid #5fd7a5;
        }
      }

      [class*='ddp-icon-measure'],
      [class*='ddp-icon-dimension'] {
        position: relative;
        top: 5px;
      }
    }

    .ddp-ui-tags {
      float: right;
    }
  }
}

.ddp-setup-contents {
  position: relative;
  padding: 17px 0;

  .ddp-ui-empty {
    margin: -18px 0;
    padding: 20px 16px;
    font-size: 13px;
  }

  .ddp-box-item-search {
    position: relative;

    .ddp-list-checktype {
      position: relative;
      top: 0;
      padding-bottom: 10px;
      max-height: 225px;
    }

    .ddp-data-result,
    .ddp-box-search {
      position: relative;
      bottom: 0;
    }

    .ddp-data-result {
      margin-bottom: 10px;
    }
  }
}

.ddp-box-item-search .ddp-search-option .ddp-ui-sort {
  position: absolute;
  top: -8px;
  right: 22px;
  margin-top: 0;
}

.ddp-setup-contents {
  .ddp-wrap-edit3 {
    .ddp-data-result {
      padding: 5px 0 0 168px;
      color: #b7b9c2;
    }

    label {
      &.ddp-label-type,
      &.ddp-label-subtype {
        padding-left: 15px;
        width: 168px;
        color: #90969f;
        font-size: 13px;
        box-sizing: border-box;
      }
    }
  }

  .ddp-box-item-search .ddp-data-result {
    padding-left: 0;
  }

  .ddp-filter1 {
    padding: 0;

    .ddp-toggle {
      padding: 0 0 20px 0;
    }

    .ddp-wrap-toggle-contents {
      position: relative;
      top: 0;
      bottom: inherit;

      .ddp-box-item-search {
        top: 0;
      }
    }
  }

  .ddp-box-item-search .ddp-ui-page {
    position: relative;
    padding: 5px 0 0 0;
    text-align: right;
    overflow: hidden;

    * {
      float: inherit;
      vertical-align: middle;
    }
  }

  .ddp-filter-area {
    position: relative;
    top: 0;
    margin: 0 140px;
  }

  .ddp-dateinfo-view {
    position: relative;
    padding: 0 140px 30px 140px;
  }

  .ddp-filter-range {
    padding: 0 140px 110px 140px;
  }

  .ddp-filter1 .ddp-option .ddp-option-btn {
    right: 11px;
  }
}

/***********************************************************************
	PAGE : 대시보드 프리젠테이션
***************************************************************************/

.ddp-ui-board-title {
  position: relative;
  z-index: 150;

  span.ddp-data-name {
    float: left;
    max-width: 40%;
    padding: 13px 20px 14px 20px;
    font-size: 18px;
    color: #4b515b;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ddp-data-name {
    float: left;
  }

  .ddp-ui-setting {
    position: relative;
    float: right;
    margin-right: 16px;
    z-index: 1;

    .ddp-wrap-show {
      position: relative;
      display: inline-block;
      margin-left: 25px;
      cursor: pointer;

      span.ddp-lik-set {
        color: #90969f;
        font-size: 13px;
        line-height: 48px;

        em {
          &.ddp-icon-showtime,
          &.ddp-icon-fitscreen,
          &.ddp-icon-fitheight {
            display: inline-block;
            margin-right: 4px;
            width: 15px;
            height: 15px;
            background: url(/assets/bi/images/icon_board.png) no-repeat;
            vertical-align: middle;
          }
        }
      }
    }

    a.ddp-btn-play em {
      &.ddp-icon-play,
      &.ddp-icon-pause {
        display: inline-block;
        margin-right: 4px;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_board.png) no-repeat;
        vertical-align: middle;
      }
    }

    .ddp-btn-out {
      display: inline-block;
      margin-right: 4px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/icon_board.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-wrap-show span.ddp-lik-set em {
      &.ddp-icon-showtime {
        width: 16px;
        background-position-x: -16px;
      }

      &.ddp-icon-fitscreen {
        background-position: left -16px;
      }

      &.ddp-icon-fitheight {
        background-position: -16px -16px;
      }
    }

    a.ddp-btn-play em {
      &.ddp-icon-play {
        width: 8px;
        height: 12px;
        margin-right: 5px;
        background-position: left -32px;
      }

      &.ddp-icon-pause {
        width: 9px;
        height: 11px;
        margin-right: 5px;
        background-position: left -45px;
      }
    }

    .ddp-btn-out {
      width: 18px;
      height: 19px;
      margin-left: 18px;
      background-position: left -57px;
      vertical-align: middle;

      &:hover {
        background-position: -19px -57px;
      }
    }

    .ddp-wrap-show {
      &.ddp-selected .ddp-wrap-popup2 {
        display: block;
      }

      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        white-space: nowrap;
      }
    }

    a.ddp-btn-play {
      display: inline-block;
      width: 94px;
      margin-left: 25px;
      border-radius: 2px;
      background-color: #e7e7ea;
      text-align: center;

      span {
        &.ddp-txt-play {
          display: block;
          color: #4b515b;
          font-size: 13px;
          line-height: 30px;
        }

        &.ddp-txt-pause {
          display: none;
          color: #4b515b;
          font-size: 13px;
          line-height: 30px;
        }
      }

      &.ddp-toggle span {
        &.ddp-txt-play {
          display: none;
        }

        &.ddp-txt-pause {
          display: block;
        }
      }
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 chat (CONTENTS)
************************************************************************************************************/

.ddp-wrap-tab-chat .ddp-ui-chat {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 111px;
  border-top: 1px solid #f6f6f7;
  background-color: #fcfcfc;
  overflow-y: auto;
}

.ddp-ui-chat ul.ddp-list-chat li {
  a {
    display: block;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #f6f6f7;
    cursor: default;

    .ddp-btn-control {
      display: none;
      position: absolute;
      top: 16px;
      right: 8px;

      .ddp-icon-control {
        float: left;
      }
    }

    &:hover .ddp-btn-control {
      display: block;
    }

    .ddp-data-chatuser {
      display: block;
      padding: 12px 19px 0 19px;
    }

    &:hover .ddp-data-chatuser {
      padding-right: 60px;
    }

    .ddp-data-chatuser {
      img.ddp-data-photo {
        float: left;
        margin: 0 5px 10px 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .ddp-data-name {
        display: block;
        padding: 8px 0 8px 0;
        font-weight: bold;
        color: #898d96;
        font-size: 12px;
        overflow: hidden;

        span.ddp-data-time {
          padding-left: 6px;
          font-weight: normal;
        }

        em.ddp-icon-new {
          position: relative;
          margin-left: 5px;
        }
      }
    }

    .ddp-data-chatcontent {
      clear: both;
      padding: 0 20px;
      color: #4b515b;
      font-size: 13px;
      line-height: 18px;
      white-space: pre-line;

      span.ddp-data-hash {
        display: inline-block;
        padding: 3px 3px 0 0;
        margin-right: 5px;
        color: $primary;
        font-size: 13px;
        text-decoration: underline;
      }
    }
  }

  &.ddp-box-edit {
    .ddp-data-chatcontent {
      display: none;
    }

    .ddp-data-input {
      display: block;
    }
  }

  .ddp-data-input {
    display: none;
    padding: 0 20px;

    textarea {
      display: block;
      width: 100%;
      padding: 5px;
      color: #4b515b;
      font-size: 13px;
      box-sizing: Border-box;
      border: 1px solid #e7e7ea;
      background-color: #fff;
    }
  }

  &.ddp-data-me a {
    background-color: #f6f6f7;
    cursor: pointer;
  }
}

.ddp-ui-writechat {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  textarea.ddp-textarea-chat {
    display: block;
    width: 100%;
    height: 111px;
    padding: 8px 10px;
    color: #4b515b;
    font-size: 14px;
    border: none;
    border-top: 2px solid #e7e7ea;
    box-sizing: border-box;
  }

  .ddp-wrap-popup2 {
    position: absolute;
    bottom: 100%;
    left: 0;
    white-space: nowrap;

    ul.ddp-list-popup li a span.ddp-data-hash {
      color: $primary;
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 대시보드 편집
************************************************************************************************************/

.ddp-ui-dash-contents .ddp-ui-top-title .ddp-wrap-popup2 {
  position: fixed;
  top: 95px;
}

.ddp-wrap-popup2 {
  .ddp-ui-top-setting {
    position: relative;
    padding-right: 60px;

    span.ddp-data-name {
      display: block;
      padding: 15px 0 5px 16px;
      color: #b7b9c2;
      font-size: 13px;
      font-weight: bold;

      em.ddp-data-name {
        font-weight: normal;
      }
    }

    .ddp-btn-control {
      position: absolute;
      top: 12px;
      right: 16px;
    }

    .ddp-form-search {
      position: absolute;
      top: 5px;
      left: 17px;
      right: 17px;
      width: inherit;

      input {
        background-color: #fff;
      }
    }
  }

  ul.ddp-list-imgchart {
    width: 305px;
    max-height: 275px;
    box-sizing: border-box;
    overflow-y: auto;

    li {
      a {
        display: table;
        width: 100%;
        position: relative;
        box-sizing: border-box;

        .ddp-wrap-images {
          display: table-cell;
          position: relative;
          width: 64px;
          vertical-align: middle;

          img.ddp-img-chart {
            width: 62px;
            height: 40px;
            border: 1px solid #cfd0d1;
          }
        }

        span.ddp-data-name {
          display: table-cell;
          padding-left: 11px;
          color: #4b515b;
          font-size: 13px;
          vertical-align: middle;
        }
      }

      &.ddp-selected a {
        .ddp-wrap-images:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 42px;
          background-color: rgba(86, 93, 101, 0.1);
        }

        span.ddp-data-name {
          color: $primary;
          font-weight: bold;
        }
      }
    }
  }
}

.ddp-wrap-editboard {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #e7e7ea;
  z-index: 1;
  overflow: hidden;

  .ddp-ui-edit-contents {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: top;
    background: url(/assets/bi/images/bg_boardedit.png) repeat;
  }

  .ddp-box-boardedit {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 1;

    .ddp-box-widget:hover {
      box-shadow: none;
    }

    .ddp-dimmed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 100;
    }

    .ddp-view-boardedit {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .ddp-ui-edit-contents:after {
    display: inline-block;
    content: '';
    width: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/assets/bi/images/bg_sideshadow.png) repeat;
  }

  .ddp-ui-boardedit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .ddp-box-widget {
      margin: 0;
      border: none;
    }
    > ktd-grid {
      width: inherit;
      margin: 5px;
      box-sizing: border-box;
    }
  }

  .ddp-box-widget .ddp-top-control .ddp-ui-buttons .ddp-box-btn2:hover {
    background-color: #d8d9de;
  }

  .ddp-view-widget {
    top: 10px;
  }
}

.ddp-view-widget .ddp-display-table {
  display: table;
  width: 100%;
  height: 100%;

  .ddp-table-in {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: top;
    text-align: center;
  }
}

.ddp-wrap-editboard .ddp-box-widget {
  .ddp-hover-edit {
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 20;
  }

  .ddp-hover-edit-in {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
  }

  .ddp-hover-edit button.ddp-btn-edit {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: url(/assets/bi/images/img_board_edit.png) no-repeat;
    z-index: 1;

    &:hover {
      background: url(/assets/bi/images/img_board_edit_h.png) no-repeat;
    }
  }

  &:hover .ddp-hover-edit {
    display: table;
  }
}

.ddp-datasorce-selected .ddp-area-boardside {
  overflow: hidden;
}

.ddp-wrap-editboard {
  .ddp-datasorce-selected .ddp-wrap-datasource {
    .ddp-label-datasorce:before {
      transform: rotate(180deg);
    }

    top: 53px;

    .ddp-ui-drop-contents {
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  }

  .ddp-wrap-datasource {
    position: absolute;
    height: 54px;
    right: -61px;
    left: 0;
    bottom: 0;
    background-color: #36393e;
    z-index: 20;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;

    &.ddp-selected {
      height: 100%;
      -webkit-transition: height 0.5s;
      transition: height 0.5s;
    }

    .ddp-wrap-list-source {
      position: relative;
      top: 0;

      ul.ddp-list-source {
        position: relative;
      }
    }

    .ddp-label-datasorce {
      position: relative;
      padding: 15px 20px;
      color: #4b515b;
      font-size: 16px;
      border-top: 1px solid #d0d1d8;
      background-color: #e7e7ea;
      cursor: pointer;
    }

    &.ddp-selected .ddp-label-datasorce {
      color: #e7e7ea;
      background-color: #626872;
    }

    .ddp-label-datasorce:before {
      display: inline-block;
      position: relative;
      top: 0;
      width: 21px;
      height: 22px;
      margin-right: 10px;
      background: url(/assets/bi/images/icon_database.png) no-repeat;
      background-position: -22px -46px;
      content: '';
      vertical-align: middle;
    }

    &.ddp-selected .ddp-label-datasorce:before {
      background-position-x: 0;
    }

    .ddp-label-datasorce:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 21px;
      margin-top: -3px;
      width: 9px;
      height: 5px;
      background: url(/assets/bi/images/icon_dropdown.png) no-repeat;
      background-position: 0 -6px;
      content: '';
    }

    &.ddp-selected {
      .ddp-label-datasorce:after {
        background-position: -30px 0;
      }

      .ddp-ui-drop-contents {
        position: absolute;
        top: 54px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
      }
    }
  }
}

.ddp-ui-contents-source {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;

  .ddp-ui-top-option {
    position: relative;
    padding: 0 39px 0 20px;

    em.ddp-icon-editadd {
      position: absolute;
      top: 8px;
      right: 15px;
    }

    .ddp-form-search {
      width: 100%;
    }
  }

  .ddp-box-data {
    padding: 1px 8px 1px 25px;
    position: relative;
    margin: 12px 14px 0 14px;
    border-radius: 3px;
    background-color: #f1f2f2;

    span.ddp-data-use {
      color: #9c9c9c;
      font-size: 12px;
    }
  }

  .ddp-wrap-list-source .ddp-ui-page {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
  }
}

.ddp-wrap-list-source {
  position: relative;
  top: 66px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 20px;
  overflow-y: auto;

  .ddp-ui-page {
    padding: 10px 16px 0 22px;
    overflow: hidden;

    a {
      &.ddp-prev {
        float: left;
        color: #90969f;
        font-size: 12px;

        em {
          margin-right: 9px;
        }

        &.ddp-disabled {
          opacity: 0.3;
          cursor: no-drop;
        }
      }

      &.ddp-next {
        float: right;
        color: #90969f;
        font-size: 12px;

        em {
          margin-left: 9px;
        }

        &.ddp-disabled {
          opacity: 0.3;
          cursor: no-drop;
        }
      }
    }
  }
}

.ddp-ui-dropmenu .ddp-ui-drop-contents .ddp-wrap-list-source ul.ddp-list-source {
  position: relative;
}

.ddp-wrap-list-source ul.ddp-list-source {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35px;

  li {
    position: relative;
    padding: 6px 10px 8px 40px;
    color: #444;
    font-size: 12px;
    cursor: pointer;

    &.ddp-ui-dimension,
    &.ddp-ui-measure {
      padding-left: 64px;
    }
  }
}

li {
  &.ddp-ui-dimension {
    display: block;
    grid-template-columns: 1fr 1fr;
    background: $primary;
    color: white;
    margin: 5px;
    padding: 5px;
    text-align: left;
  }

  &.ddp-ui-measure {
    display: block;
    grid-template-columns: 1fr 1fr;
    background: $primary;
    color: white;
    margin: 5px;
    padding: 5px;
    text-align: left;

    > .ddp-icon-measure-sharp {
      display: none;
    }
  }
}

em.type-apply {
  padding-right: 10px;
}

.ddp-icon-control-filter {
  position: reletive;
  top: -15px;
}

.ddp-wrap-list-source ul.ddp-list-source li {
  &:hover {
    background-color: #f6f6f7;
  }

  span.ddp-data-name {
    display: block;
    position: relative;
    top: 0;
    box-sizing: border-box;
    overflow: hidden;

    .ddp-data-in {
      display: inline-block;
      position: relative;
      vertical-align: top;
      width: 100%;

      .ddp-sub-name {
        margin-left: 5px;
        color: #90969f;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }

  em {
    &.ddp-icon-use {
      position: absolute;
      top: 50%;
      left: 22px;
      margin-top: -1px;
    }

    &[class*='ddp-icon-dimension-'],
    &[class*='ddp-icon-measure-'] {
      position: absolute;
      top: 8px;
      left: 45px;
    }

    &.ddp-icon-dimension-float,
    &.ddp-icon-measure-float,
    &.ddp-icon-dimension-int,
    &.ddp-icon-measure-int,
    &.ddp-icon-dimension-sharp,
    &.ddp-icon-measure-sharp {
      top: 10px;
    }
  }

  .ddp-txt-data {
    display: block;
    position: relative;
    top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ddp-wrap-icons {
    float: right;
    margin-top: -7px;
    margin-bottom: -7px;
  }
}

.ddp-ui-contents-source .ddp-wrap-list-source ul.ddp-list-source li .ddp-wrap-icons em[class*='ddp-icon-'] {
  padding: 8px 4px;
}

.ddp-wrap-list-source ul.ddp-list-source {
  li {
    .ddp-wrap-icons em[class*='ddp-icon-'] {
      display: none;
      padding: 9px 4px;
    }

    &:hover .ddp-wrap-icons em[class*='ddp-icon-'],
    &.ddp-selected .ddp-wrap-icons em[class*='ddp-icon-'] {
      display: block;
    }

    .ddp-wrap-icons em[class*='ddp-icon-control'] {
      float: left;
      margin-left: 3px;

      &.ddp-disabled {
        opacity: 0.3;
        cursor: no-drop;

        &:before {
          background-position: -13px -65px !important;
        }
      }
    }

    &:hover .ddp-wrap-icons em[class*='ddp-icon-control'] {
      display: block;

      .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
        right: 17px;
      }
    }

    .ddp-wrap-icons {
      .ddp-wrap-user-preview {
        position: relative;
        top: 1px;
        float: left;
      }

      .ddp-icon-control-parameter {
        position: relative;
        top: 1px;
      }

      .ddp-icon-control-cut-s {
        &:before {
          background-position: -22px -194px;
        }

        &:hover:before {
          background-position: -33px -194px;
        }
      }

      .ddp-wrap-user-preview {
        &:hover .ddp-ui-tooltip-info {
          display: block;
        }

        .ddp-ui-tooltip-info {
          display: none;
          position: absolute;
          top: 23px;
          right: -17px;
          min-width: 100px;
          max-width: 200px;
          left: inherit;
          text-align: center;

          em.ddp-icon-view-top {
            padding: 0;
            right: 23px;
            left: inherit;
            top: -6px;
          }

          span.ddp-txt-tooltip {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-wrap: normal;
            box-sizing: border-box;
          }
        }
      }

      .ddp-icon-control-filter {
        position: relative;
        top: 1px;

        &.ddp-selected {
          display: block;
        }
      }

      .ddp-icon-control-parameter.ddp-selected,
      .ddp-icon-control-calculus.ddp-selected,
      .ddp-icon-control-dimension.ddp-selected,
      .ddp-icon-control-user.ddp-selected {
        display: block;
      }

      .ddp-btn-control {
        position: relative;
        float: left;

        &.ddp-selected .ddp-ui-layer-box {
          display: block;
          position: absolute;
          top: 19px;
          left: -40px;
        }
      }
    }

    span.ddp-txt-data {
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.ddp-list-dimension li.ddp-ui-measure,
  &.ddp-list-measure li.ddp-ui-dimension {
    display: none;
  }
}

/***********************************************************************************************************
	PAGE : 워크북 대시보드 편집 (no-data)
************************************************************************************************************/

.ddp-view-widget .ddp-box-data-none {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}

.ddp-box-data-none {
  position: absolute;
  top: 5px;
  left: 10px;
  right: 10px;
  bottom: 5px;
  background-color: #fff;
  border: 2px solid #f6f6f7;
}

.ddp-ui-dash-contents .ddp-wrap-data-none .ddp-ui-data-none {
  font-size: 14px;
}

.ddp-wrap-data-none {
  display: table;
  width: 100%;
  height: 100%;
}

.ddp-ui-data-none em {
  &[class*='ddp-chart-'] {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    background: url(/assets/bi/images/img_chartnodata.png) no-repeat;
  }

  &.ddp-chart-hierarchy {
    display: inline-block;
    width: 60px;
    height: 50px;
    background: url(/assets/bi/images/img_chart_hierarchy.png) no-repeat;
    background-position: left top;
  }

  &.ddp-chart-bar {
  }

  &.ddp-chart-table {
    background-position: -61px 0;
  }

  &.ddp-chart-linegraph {
    background-position: -122px 0;
  }

  &.ddp-chart-scatter {
    background-position: -183px 0;
  }

  &.ddp-chart-heatmap {
    background-position: -244px 0;
  }

  &.ddp-chart-pie {
    background-position: -305px 0;
  }

  &.ddp-chart-map {
    background-position: -366px 0;
  }

  &.ddp-chart-cont {
    background-position: -427px 0;
  }

  &.ddp-chart-kpi {
    background-position: -488px 0;
  }

  &.ddp-chart-boxplot {
    background-position: -549px 0;
  }

  &.ddp-chart-waterfall {
    background-position: -610px 0;
  }

  &.ddp-chart-wordcloud {
    background-position: -671px 0;
  }

  &.ddp-chart-combo {
    background-position: -732px 0;
  }

  &.ddp-chart-treemap {
    background-position: -793px 0;
  }

  &.ddp-chart-radar {
    background-position: -854px 0;
  }

  &.ddp-chart-network {
    background-position: -915px 0;
  }

  &.ddp-chart-sankey {
    background-position: -976px 0;
  }

  &.ddp-chart-filter {
    background-position: -1037px 0;
  }

  &.ddp-chart-parameter {
    background-position: -1098px 0;
  }
}

.ddp-wrap-data-none .ddp-ui-data-none {
  em[class*='ddp-chart-'].ddp-error:before {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: -7px;
    right: -7px;
    content: '';
    background: url(/assets/bi/images/icon_chart_error.png) no-repeat;
  }

  .ddp-data-name {
    display: block;
    padding: 15px 0 0;
    font-size: 16px;
    text-align: center;
    color: #90969f;
  }

  .ddp-txt-det {
    display: block;
    padding-top: 18px;
    color: #b7b9c2;
    font-size: 14px;
    font-weight: 300;
    text-align: center;

    em {
      color: $primary;
    }
  }

  .ddp-link-error {
    display: inline-block;
    margin-top: 9px;
    color: #90969f;

    &:after {
      display: inline-block;
      position: relative;
      top: -2px;
      width: 13px;
      height: 13px;
      margin-left: 4px;
      background: url(/assets/bi/images/icon_list_view.png) no-repeat;
      background-position: left -23px;
      vertical-align: middle;
      content: '';
    }
  }

  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  color: #d0d1db;
}

.ddp-ui-data-none {
  em.ddp-img-nodata {
    display: inline-block;
    width: 213px;
    height: 179px;
    background: url(/assets/bi/images/img_nochart.png) no-repeat;
  }

  span.ddp-txt-nodata {
    display: block;
    padding: 30px 0 0 0;
    text-align: center;
    color: #d0d1d8;
    font-size: 18px;
    line-height: 26px;
  }

  a.ddp-link-board-add {
    display: inline-block;
    width: 212px;
    margin-top: 15px;
    box-sizing: border-box;
  }

  em.ddp-img-nodata2 {
    display: inline-block;
    width: 170px;
    height: 190px;
    background: url(/assets/bi/images/img_nochart2.png) no-repeat;
  }
}

.ddp-div-table {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 10;
}

.ddp-div-tablecell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;

  .ddp-txt-condition {
    display: block;
    padding-bottom: 35px;
    text-align: center;
    color: #d0d1d8;
    font-size: 28px;

    &.ddp-missing {
      color: #eb5f58;
    }
  }

  .ddp-detail {
    display: block;
    padding-bottom: 25px;
    color: #b7b9c2;
    font-size: 14px;
    line-height: 18px;
  }

  .ddp-btn-line {
    padding: 9px 19px;
  }
}

.ddp-view-widget .ddp-ui-error-detail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 28px;
  background-color: #eeeef0;
  z-index: 1;
  overflow: auto;
  cursor: default;

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -17px;
  }

  dl {
    + dl {
      margin-top: 20px;
    }

    dt {
      color: #6f737a;
      font-weight: bold;
      font-size: 13px;
    }

    dd {
      color: #6f737a;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 대시보드 편집 (SIDE)
    ************************************************************************************************************/

.ddp-wrap-editboard .ddp-ui-boardside-menu {
  display: table-cell;
  position: relative;
  width: 60px;
  height: 100%;
  border-left: 2px solid #e5e6e8;
  background-color: #f7f7f8;
  vertical-align: top;
}

.ddp-ui-boardside-menu {
  a {
    display: block;
    position: relative;
    width: 60px;
    height: 52px;
    text-align: center;

    em[class*='ddp-icon-menu'] {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.ddp-selected {
      background-color: #90969f;
    }
  }

  span.ddp-ui-menu-layout {
    display: block;
    position: relative;
    padding-top: 11px;
    margin-top: 13px;

    &:before {
      display: inline-block;
      content: '';
      width: 28px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -14px;
      border-top: 1px solid #e7e7ea;
    }

    &.ddp-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-top: 0;
    }
  }

  a {
    &:hover em {
      &.ddp-icon-menu-chart {
        background-position: -27px top;
      }

      &.ddp-icon-menu-filter {
        background-position: -19px -21px;
      }

      &.ddp-icon-menu-layout {
        background-position: -27px -37px;
      }

      &.ddp-icon-menu-datasorce {
        background-position: -22px -190px;
      }

      &.ddp-icon-menu-parameters {
        background-position-x: -21px;
      }

      &.ddp-icon-menu-text {
        background-position: -16px -213px;
      }
    }

    &.ddp-selected em {
      &.ddp-icon-menu-chart {
        background-position: -54px top;
      }

      &.ddp-icon-menu-filter {
        background-position: -38px -21px;
      }

      &.ddp-icon-menu-layout {
        background-position: -54px -37px;
      }

      &.ddp-icon-menu-datasorce {
        background-position: -22px -190px;
      }

      &.ddp-icon-menu-text {
        background-position: -32px -213px;
      }

      &.ddp-icon-menu-parameters {
        background-position-x: -43px;
      }
    }
  }
}

.ddp-wrap-editboard {
  .ddp-ui-boardside-contents {
    width: 265px;
    height: 100%;
    box-sizing: border-box;
    border-left: 2px solid rgb(234, 234, 234);
    background-color: #f7f7f8;
  }

  .ddp-ui-boardside-in {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.ddp-ui-boardside-contents {
  .ddp-view-boardside {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .ddp-ui-boardside-title {
    position: relative;
    padding: 16px 15px;
    font-size: 16px;
    color: #565d65;
    font-weight: bold;
    z-index: 4;
    border-bottom: 1px solid #e5e6e8;
  }

  .ddp-ui-boardside-scroll {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-x: hidden;

    .ddp-wrap-downmenu {
      padding-right: 10px;
    }
  }

  .ddp-ui-boardside-title {
    .ddp-info {
      position: relative;
      top: 1px;
      display: inline-block;
      margin-left: 13px;
      z-index: 1;

      .ddp-btn-info {
        background-position: left -30px;

        &:hover {
          background-position: -28px -30px;
        }
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: 8px;
        margin-left: -80px;
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }
    }

    .ddp-icon-add {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -6px;

      &:hover {
        background-position: left top;

        .ddp-ui-tooltip-info {
          display: block;
        }
      }

      .ddp-ui-tooltip-info {
        left: inherit;
        top: 100%;
        right: -10px;
      }
    }

    span.ddp-data-num {
      margin-left: 3px;
      color: #b7b9c2;
      font-size: 15px;

      em {
        color: $primary;
      }
    }
  }
}

.ddp-ui-down-contents {
  .ddp-list-checktype {
    max-height: 200px;
    overflow: auto;

    &.ddp-overflow-visible {
      overflow: visible;
    }

    li {
      padding: 5px 0 5px 0;

      &:first-of-type {
        padding-top: 0;
      }

      span.ddp-txt-radio {
        font-size: 12px;
      }
    }
  }

  .ddp-data-message {
    padding: 10px 0 0 0;
    font-size: 12px;
    color: #b7b9c2;
  }
}

.ddp-list-sideoption .ddp-list-sidesub {
  padding: 10px 0;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

/* LAYOUT SETTING */

.ddp-wrap-option-radio {
  position: relative;

  .ddp-label-radio {
    top: 6px;

    span.ddp-txt-radio {
      font-size: 12px;
    }
  }
}

.ddp-box-option-input {
  position: relative;
  padding-right: 20px;
  border: 1px solid #d0d1d9;
  background-color: #fff;

  input {
    &[type='text'],
    &[type='number'] {
      display: block;
      width: 100%;
      padding: 6px 5px 7px 5px;
      border: none;
      text-align: left;
      box-sizing: border-box;
    }

    &[type='text']:disabled {
      background: none;
      color: #bdc0c9;
    }
  }

  span.ddp-txt-input {
    position: absolute;
    top: 5px;
    right: 6px;
    color: #bdc0c9;
    font-size: 12px;

    &.ddp-dark {
      color: #4a515c;
    }
  }

  &.ddp-inline {
    display: inline-block;
    position: relative;
    top: -7px;
    width: 90px;
    margin-left: 10px;
    padding-right: 30px;
    box-sizing: border-box;

    &.type-row {
      padding-right: 40px;
    }
  }
}

.ddp-list-sideoption .ddp-list-sidesub {
  .ddp-txt-label {
    float: left;
    color: #4b515b;
    font-size: 13px;
  }

  .ddp-checkbox-auto {
    float: right;
    position: relative;
    top: 0;
  }
}

.ddp-box-option-input.ddp-type2 {
  padding: 0;

  .ddp-type-text {
    display: block;
    overflow: hidden;

    input {
      font-size: 13px;
    }
  }

  .ddp-txt-input {
    position: relative;
    top: 0;
    right: 0;
    float: right;
    padding: 5px 6px 5px 0;
    font-size: 12px;
  }
}

/* CHART */

.ddp-list-chart {
  /*position:absolute; top:52px; left:0; right:0; bottom:107px; overflow-y:auto;*/

  li {
    position: relative;
    cursor: pointer;

    a {
      display: block;
      position: relative;
      padding: 0 10px 0 0;
      box-sizing: border-box;
      cursor: all-scroll;

      &:hover {
        background-color: #e7e7ea;
      }
    }

    .ddp-data-name {
      display: block;
      font-size: 12px;
      color: #444;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }

    .ddp-btn-control {
      display: none;
      position: absolute;
      top: 6px;
      right: 0;

      [class*='ddp-icon-'] {
        float: left;
      }
    }
  }
}

ul.ddp-list-chart li a .ddp-btn-control {
  display: none;
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -5px;

  .ddp-icon-edit-s {
    padding: 2px;
    margin-top: -5px;
  }

  .ddp-icon-del-s {
    padding: 2px;
    margin-top: -5px;
    margin-left: 3px;
    position: relative;
    top: 1px;
  }

  em {
    &.ddp-icon-edit-s {
      &:before {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        background-position: -26px -97px;
      }

      &:hover:before {
        background-position-x: -52px;
      }
    }

    &.ddp-icon-del-s {
      &:before {
        display: inline-block;
        width: 11px;
        height: 12px;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        background-position: -26px -14px;
      }

      &:hover:before {
        background-position-x: -62px;
      }
    }
  }
}

.ddp-list-chart li {
  a:hover .ddp-btn-control {
    display: block;
  }

  .ddp-data-used {
    display: block;
    max-height: 29px;
    padding-top: 3px;
    font-size: 11px;
    color: #b7b9c2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .ddp-chart-images {
    float: left;
    position: relative;
    top: 9px;
    margin-right: 12px;
    padding-left: 17px;

    em.ddp-icon-used {
      display: inline-block;
      position: absolute;
      bottom: -4px;
      right: -5px;
      top: initial;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_use.png) no-repeat;
      background-color: #f7f7f8;
    }

    span.ddp-wrap-chartimg {
      display: block;
      font-size: 0;
      position: relative;
    }
  }

  a {
    em.ddp-icon-chart-children {
      display: none;
      position: absolute;
      top: -9px;
      left: 0;
      bottom: -9px;
      width: 24px;
      cursor: pointer;

      &:hover {
        display: block;
      }

      &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 6px;
        width: 13px;
        height: 13px;
        margin-top: -7px;
        background: url(/assets/bi/images/img_childrenchart.png) no-repeat;
        content: '';
      }

      &:hover:before {
        background-position: -14px top;
      }
    }

    &:hover em.ddp-icon-chart-children {
      display: block;
    }
  }

  .ddp-chart-images.ddp-selected {
    em.ddp-icon-chart-children:before {
      display: block;
      background-position: -14px top;
    }

    span.ddp-wrap-chartimg:before {
      display: block;
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 2px solid #4b515b;
    }
  }

  .ddp-data-info {
    display: block;
    padding: 9px 30px 9px 0;
    overflow: hidden;
  }

  .ddp-ui-edit-button {
    position: absolute;
    bottom: 6px;
    right: 7px;
  }
}

.ddp-list-chart-2depth li {
  a {
    padding-left: 43px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 30px;
    width: 13px;
    height: 23px;
    background: url(/assets/bi/images/bg_groupline.png) no-repeat;
  }

  .ddp-chart-images {
    padding-left: 0;
  }
}

.ddp-list-chart-3depth li {
  a {
    padding-left: 60px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 45px;
    width: 13px;
    height: 23px;
    background: url(/assets/bi/images/bg_groupline.png) no-repeat;
  }

  .ddp-chart-images {
    padding-left: 0;
  }
}

.ddp-list-divide-tree li {
  position: relative;

  .ddp-list-divide-tree li {
    position: relative;
    padding-left: 33px;

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 14px;
      width: 13px;
      height: 23px;
      background: url(/assets/bi/images/bg_groupline.png) no-repeat;
      z-index: 1;
    }

    .ddp-wrap-divide {
      border-top: none;
    }
  }
}

.ddp-chart-boardside {
  .ddp-wrap-downmenu {
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    bottom: 79px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ddp-link-add {
    position: absolute;
    bottom: 57px;
    left: 0;
    right: 0;
  }
}

.ddp-filter-boardside {
  .ddp-link-add {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ddp-box-down .ddp-wrap-divide {
    padding-top: 0;
  }
}

.ddp-parmeter-boardside .ddp-box-down .ddp-wrap-divide {
  padding-top: 0;
}

.ddp-ui-boardside-in .ddp-link-add {
  padding: 18px 0;
  color: #b7b9c2;
  text-decoration: underline;
  text-align: center;

  a {
    color: #b7b9c2;

    em.ddp-icon-add-r {
      position: relative;
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 4px;
      border-radius: 50%;
      border: 1px solid #bebfc8;
      vertical-align: middle;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 7px;
        margin: -4px 0 0 -3px;
        background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
        background-position: -8px -12px;
      }
    }

    &:hover {
      color: #4b515b;

      em.ddp-icon-add-r {
        border: 1px solid #4b515b;

        &:before {
          background-position: left -12px;
        }
      }
    }
  }
}

.ddp-wrap-editboard .ddp-ui-boardside-in.ddp-chart-boardside {
  overflow: inherit;
}

.ddp-chart-boardside {
  .ddp-foot-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 23px 24px 23px;
    text-align: center;
  }

  .ddp-btn-set {
    display: block;
    padding: 7px 0;
    width: 100%;
    text-align: center;
    border: 1px solid #90969f;
    color: #90969f;
    border-radius: 2px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #4b515b;
      color: #4b515b;
    }

    em.ddp-icon-hierarch {
      display: inline-block;
      width: 12px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_buttons.png) no-repeat;
      background-position: -13px -263px;
      vertical-align: middle;
    }

    &:hover em.ddp-icon-hierarch {
      background-position: left -263px;
    }
  }

  .ddp-ui-info {
    position: absolute;
    bottom: 0;
    margin: 0 15px;
    padding: 10px 0;
    border-top: 1px solid #e5e6e8;
    color: #b7b9c2;
    font-size: 12px;
    font-style: italic;
    line-height: 1.5em;

    .ddp-box-layout4 {
      display: none;
      font-style: normal;
    }

    &.ddp-selected .ddp-box-layout4 {
      display: block;
    }

    .ddp-icon-sinfo2 {
      vertical-align: middle;
      margin-left: 6px;
    }
  }
}

[class*='ddp-img-chart-'] {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: url(/assets/bi/images/img_chart.png) no-repeat;
}

.ddp-box-layout4.ddp-box-guide {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 236px;
}

.ddp-box-guide .ddp-ui-det {
  padding: 0 13px 15px 13px;

  dt {
    display: block;
    padding: 18px 0 5px 0;
    color: #4b515b;
    font-size: 12px;
  }

  dd {
    color: #9c9fa9;
    font-size: 12px;
    line-height: 18px;
  }
}

.ddp-ui-filter-boardside,
.ddp-area-boardside {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 53px;
  overflow: auto;
  overflow-x: hidden;
}

.ddp-img-chart-bar {
}

.ddp-img-chart-table {
  background-position: -29px top;
}

.ddp-img-chart-line {
  background-position: -58px top;
}

.ddp-img-chart-scatter {
  background-position: -87px top;
}

.ddp-img-chart-pie {
  background-position: -116px top;
}

.ddp-img-chart-map {
  background-position: -145px top;
}

.ddp-img-chart-ctrl {
  background-position: -174px top;
}

.ddp-img-chart-waterfall {
  background-position: -203px top;
}

.ddp-img-chart-wordcloud {
  background-position: -232px top;
}

.ddp-img-chart-combo {
  background-position: -261px top;
}

.ddp-img-chart-treemap {
  background-position: -290px top;
}

.ddp-img-chart-boxplot {
  background-position: -319px top;
}

.ddp-img-chart-heat {
  background-position: -348px top;
}

.ddp-img-chart-rander {
  background-position: -377px top;
}

.ddp-img-chart-kpi {
  background-position: -406px top;
}

.ddp-img-chart-network {
  background-position: -435px top;
}

.ddp-img-chart-gauge {
  background-position: -464px top;
}

.ddp-img-chart-sankey {
  background-position: -493px top;
}

/***********************************************************************************************************
	PAGE : 워크북 데이터소스 상세 팝업
************************************************************************************************************/

.ddp-type-option .ddp-box-btn2 {
  position: relative;
  float: left;
  margin: 8px 15px 0 20px;
  z-index: 60;

  .ddp-icon-widget-gridsave {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }

  .ddp-ui-tooltip-info {
    display: none;
    top: 25px;
    left: inherit;
    right: 0;
  }

  &.ddp-selected .ddp-box-layout4.ddp-download {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;

    .ddp-wrap-data-detail {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &:hover .ddp-ui-tooltip-info {
    display: block;
  }

  .ddp-ui-tooltip-info em.ddp-icon-view-top {
    left: inherit;
    right: 5px;
  }

  em[class*='ddp-icon-']:before {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
  }

  .ddp-link-txt [class*='ddp-icon'] {
    margin-right: 5px;
  }
}

.ddp-box-btn2.ddp-selected .ddp-box-layout4.ddp-pop-store {
  display: block;
}

.ddp-box-layout4.ddp-pop-store {
  position: fixed;
  z-index: 50;
  top: 100%;
  margin-top: 5px;
  min-width: 350px;
  padding: 20px 14px;
  box-sizing: border-box;

  .ddp-ui-detail {
    display: block;
    padding-bottom: 20px;
    color: #4b515b;
    font-size: 12px;
    border-bottom: 1px solid #e7e7ea;

    strong {
      display: block;
      padding-bottom: 5px;
      color: #4b515b;
      font-size: 14px;
    }
  }

  table.ddp-table-detail {
    margin-top: 13px;

    tbody tr td {
      padding-right: 0;
    }
  }

  td ul.ddp-list-type-input li {
    padding: 3px 0;

    &:first-of-type {
      padding-top: 0;
    }

    .ddp-input-typebasic {
      width: 75px;
      box-sizing: border-box;
    }
  }

  table.ddp-table-detail tbody tr td .ddp-label-radio.ddp-inline {
    margin-right: 0;
  }

  .ddp-pop-btn {
    text-align: center;
  }
}

.ddp-type-option .ddp-view-option {
  float: left;
  margin: 9px 0;
}

.ddp-type-preview .ddp-ui-tab-contents {
  position: absolute;
  top: 33px;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #d7d8dd;

  .ddp-ui-grid2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.ddp-type {
      top: 44px;
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크북 데이터 다운로드
************************************************************************************************************/

.ddp-box-layout4.ddp-download {
  position: fixed;
  z-index: 126;
  top: 100%;
  margin-top: 5px;
  min-width: 355px;
  padding: 20px 0;
  box-sizing: border-box;

  .ddp-data-title {
    padding-top: 0;

    .ddp-data {
      margin-left: 6px;
      color: #4b515b;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .ddp-btn-down {
    float: left;
    padding: 9px 0;
    width: 160px;
    border-radius: 2px;
    border: 1px solid #d0d1d7;
    color: #90969f;
    font-size: 13px;
    text-align: center;
    box-sizing: border-box;

    &:hover {
      color: #4c515a;
      border: 1px solid #b7b9c1;
    }

    + .ddp-btn-down {
      margin-left: 3px;
    }

    .ddp-icon-csv {
      display: inline-block;
      width: 22px;
      height: 20px;
      margin-right: 5px;
      background: url(/assets/bi/images/img_csv.png) no-repeat;
    }

    .ddp-icon-xlsx {
      display: inline-block;
      width: 22px;
      height: 20px;
      margin-right: 5px;
      background: url(/assets/bi/images/img_xlsx.png) no-repeat;
    }
  }

  .ddp-txt-det {
    clear: both;
    padding: 20px 0 0 0;
    color: #90969f;
    font-size: 12px;
    line-height: 18px;
  }
}

/***********************************************************************************************************
	PAGE : 워크북 사용자 모델 팝업
************************************************************************************************************/

.ddp-layout-pop-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 290px;
  background-color: #f6f6f7;
  border-left: 1px solid #d5d6db;
  overflow-y: auto;

  .ddp-box-name {
    display: block;
    padding-bottom: 25px;
    background-color: #fff;

    .ddp-data-name {
      display: block;
      padding: 25px 13px 10px 13px;
      font-size: 16px;
      color: #35393f;
      font-weight: bold;
    }

    .ddp-data-description {
      padding: 0 13px;
      color: #b7b9c2;
      font-size: 12px;
    }
  }
}

.ddp-layout-pop-contents {
  position: absolute;
  top: 0;
  left: 0;
  right: 291px;
  bottom: 0;

  &.ddp-wrap-snapshot {
    height: 100%;
    padding-top: 33px;
    box-sizing: border-box;

    .ddp-wrap-tab-contents {
      position: relative;
      height: 100%;
      border-top: 1px solid #ccc;
      box-sizing: Border-box;
    }

    .ddp-error-log {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 255px;
      padding: 15px 20px;
      color: #3e4148;
      font-size: 14px;

      .ddp-txt-log {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 20px;
        overflow: auto;
      }
    }

    .ddp-label-title {
      padding-bottom: 14px;
      color: #3e4148;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.ddp-box-info {
  .ddp-dl-detail {
    padding: 10px 0;

    dt {
      float: left;
      padding: 5px 0 5px 14px;
      width: 137px;
      color: #b7bac1;
      font-size: 13px;
      box-sizing: border-box;
    }

    dd {
      display: block;
      padding: 5px 10px 5px 0;
      color: #4b515b;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
    }
  }

  dl.ddp-dl-detail2 {
    padding-bottom: 30px;

    dt {
      display: block;
      padding: 30px 10px 10px 10px;
      color: #4b515b;
      font-size: 13px;
    }

    dd {
      padding: 0 10px;
      font-size: 13px;

      .ddp-ui-sub {
        padding: 0 10px;
        margin-top: 10px;
      }

      a.ddp-link-data {
        display: block;
        color: #4b515b;
        font-size: 13px;
        font-weight: 300;

        &:after {
          display: inline-block;
          width: 13px;
          height: 11px;
          background: url(/assets/bi/images/icon_selection.png) no-repeat;
          background-position: -28px -43px;
          content: '';
        }

        &:hover:after {
          background-position-x: -42px;
        }
      }

      span.ddp-data-sub {
        display: block;
        color: #b7b9c2;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;

        + span.ddp-data-sub {
          margin-top: 20px;
        }

        em.ddp-data-result {
          display: block;
          color: #90969f;
          font-weight: normal;
        }
      }

      .ddp-ui-sub2 {
        + .ddp-ui-sub2 {
          margin-top: 20px;
        }

        label.ddp-label-sub {
          display: block;
          padding-bottom: 5px;
          color: #b7b9c2;
          font-size: 13px;
          font-weight: 300;
        }

        span.ddp-data-sub2 {
          display: block;
          color: #4b515b;
          font-size: 13px;
        }
      }
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크스페이스 목록 팝업
************************************************************************************************************/

.ddp-type-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  overflow-y: auto;
  z-index: 126;

  a.ddp-btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    display: inline-block;
    width: 23px;
    height: 23px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
  }
}

.ddp-wrap-pop-header {
  padding: 100px 10% 0 10%;

  .ddp-ui-name em.ddp-icon-group2 {
    display: inline-block;
    position: relative;
    top: -2px;
    width: 17px;
    height: 12px;
    margin-right: 9px;
    background: url(/assets/bi/images/icon_group.png) no-repeat;
    background-position: left -15px;
    vertical-align: middle;
  }
}

.ddp-ui-pop-header {
  position: relative;

  .ddp-ui-name {
    font-size: 22px;
    color: #4b515b;
    font-weight: bold;
    letter-spacing: -1px;

    span.ddp-data-num {
      color: #90969f;
      font-weight: normal;
    }

    a.ddp-btn-add2 {
      display: inline-block;
      position: relative;
      top: -2px;
      width: 18px;
      height: 18px;
      margin-left: 8px;
      background: url(/assets/bi/images/icon_add2.png) no-repeat;
      background-position: left -13px;
      vertical-align: middle;

      &:hover {
        background-position: -19px -13px;
      }
    }
  }

  button.ddp-btn-line2 {
    position: absolute;
    top: 0;
    right: 0;

    em.ddp-icon-moveto {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 13px;
      height: 11px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_buttons.png) no-repeat;
      background-position: left -214px;
      vertical-align: middle;
    }

    &:hover em.ddp-icon-moveto {
      background-position: -14px -215px;
    }
  }
}

.ddp-ui-pop-option {
  padding: 40px 0 0 0;

  .ddp-form-search {
    float: left;
  }

  .ddp-wrap-buttons {
    float: right;
    margin-top: 5px;
    background-color: #f6f6f7;
  }
}

.ddp-ui-btn-list {
  position: relative;
  float: left;
  padding: 0 7px;

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 12px;
    margin-top: -6px;
    border-left: 1px solid #dbdce0;
  }

  .ddp-type-dropdown {
    float: left;
    margin: 0 12px;
    padding-top: 6px;

    .ddp-wrap-popup2 {
      top: 26px;
    }
  }

  &:first-of-type:before {
    display: none;
  }
}

a.ddp-btn-list {
  display: block;
  padding: 6px 12px;
  font-size: 12px;
  color: #4b515b;

  &:hover {
    color: #4b515b;
  }

  &.ddp-btn-fav {
    &:hover,
    &.ddp-selected {
      color: $primary;
    }
  }

  em {
    &.ddp-icon-btnfav {
      position: relative;
      top: -2px;
    }

    &.ddp-icon-btnworkspace {
      position: relative;
      top: -1px;
    }
  }

  &:hover em {
    &.ddp-icon-btnfav {
      background-position: -13px -227px;
    }

    &.ddp-icon-btnworkspace {
      background-position: -11px -239px;
    }
  }

  &.ddp-selected em {
    &.ddp-icon-btnfav {
      background-position: -26px -227px;
    }

    &.ddp-icon-btnworkspace {
      background-position: -22px -239px;
    }
  }
}

/*a.ddp-btn-list.ddp-selected {color:#4b515b; background-color:#f6f6f7; border-radius:2px;}*/

.ddp-wrap-pop-contents {
  padding: 0 10% 54px 10%;
}

ul.ddp-list-form2 {
  width: 100%;
  table-layout: fixed;
  margin-top: 15px;
  border-top: 1px solid #b7b9c2;

  li {
    position: relative;
    padding: 15px 24px 15px 0;
    border-bottom: 1px solid #e7e7ea;
    color: #b7bac1;
    font-size: 12px;
    cursor: pointer;

    &.ddp-disabled {
      opacity: 0.3;
      cursor: no-drop;

      &:hover {
        background: none;
      }
    }

    &:last-of-type {
      border-top: none;
    }

    &:hover {
      background-color: #f6f6f7;
    }

    .ddp-wrap-name {
      display: block;
      position: relative;
      padding-left: 26px;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    em.ddp-icon-fav {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 26px;
      z-index: 1;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 6px;
        width: 13px;
        height: 12px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_fav.png) no-repeat;
        background-position: left -31px;
        cursor: pointer;
      }
    }

    &:hover em.ddp-icon-fav {
      display: block;
    }

    em.ddp-icon-fav.ddp-selected {
      display: block;

      &:before {
        background-position: -13px -31px;
      }
    }

    .ddp-wrap-name span.ddp-data-name {
      display: inline-block;
      color: #4c515a;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
      vertical-align: middle;

      &.ddp-data-new {
        position: relative;
        padding-right: 40px;
        max-width: 100%;
        box-sizing: border-box;
      }

      span.ddp-data-in {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: Hidden;
        vertical-align: top;
      }

      em.ddp-icon-new {
        display: none;
        background-color: #fff;
      }
    }

    &:hover .ddp-wrap-name span.ddp-data-name em.ddp-icon-new {
      background-color: #f6f6f7;
    }

    .ddp-wrap-name span {
      &.ddp-data-name.ddp-data-new em.ddp-icon-new {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
      }

      &.ddp-data-detail {
        margin-left: 5px;
        color: #b7bac1;
        font-size: 14px;
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ddp-wrap-data-info {
      float: right;
      padding-left: 10px;

      .ddp-wrap-tag {
        display: inline-block;
      }

      .ddp-wrap-power {
        display: inline-block;
        margin-right: 25px;
        margin-left: 20px;
      }

      .ddp-wrap-number {
        display: inline-block;
        min-width: 123px;
        margin-left: 30px;
        text-align: left;
        vertical-align: top;

        span.ddp-data-user {
          padding-left: 7px;

          &:first-of-type {
            padding-left: 0;
          }

          strong {
            font-weight: bold;
            color: #90969f;
          }
        }
      }

      .ddp-anonymouse {
        display: inline-block;
        min-width: 123px;
        margin-left: 30px;
        color: #90969f;
      }

      .ddp-wrap-owner {
        float: left;
        width: 130px;
        margin-left: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;

        span.ddp-data-owner {
          color: #4c515a;
        }
      }
    }

    .ddp-btn-control {
      display: none;
      position: absolute;
      top: 50%;
      right: 6px;
      margin-top: -11px;
    }

    &:hover .ddp-btn-control {
      display: block;
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크스페이스 리스트 (block)
************************************************************************************************************/

.ddp-ui-space-contents-iframe {
  position: relative;
  padding: 0px 0px 0px 0px;
  height: 100vh;
  box-sizing: border-box;
  background: #fefefe;
}

.ddp-ui-space-contents-iframe-height {
  height: calc(100vh + 188px);
}

.ddp-ui-top-info {
  position: relative;
  left: 0;
  right: 0;
  height: 132px;
  padding: 0 80px;
  border-bottom: 1px solid #d0d0d3;
  z-index: 60;
  background-color: #fff;

  /* layer fill content */
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */

  .ddp-ui-title {
    position: relative;
    padding: 35px 200px 0 0;

    .ddp-ui-title-in {
      display: inline-block;
      position: relative;
      max-width: 100%;
      padding-right: 85px;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      &.ddp-data-title {
        padding-right: 5px;
        color: #545b65;
        font-weight: bold;
        font-size: 28px;
        vertical-align: middle;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
      }

      &.ddp-data-detail {
        position: relative;
        top: 5px;
        padding-right: 4px;
        font-size: 14px;
        color: #4b515b;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;

        &:before {
          display: inline-block;
          content: '';
        }
      }
    }

    .ddp-ui-title-in .ddp-btn-control .ddp-box-power {
      position: relative;
      float: left;
      top: 2px;
    }

    .ddp-btn-control {
      display: inline-block;
      width: 85px;
      position: absolute;
      right: 0;
      top: 12px;

      .ddp-icon-control-info {
        position: relative;
        top: 2px;
      }

      .ddp-icon-control-fav {
        position: relative;
        top: 1px;
        margin-right: 5px;
        float: left;
      }
    }

    button.ddp-btn-line2 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .ddp-wrap-info-summary {
    padding: 20px 0 0 0;

    .ddp-data-info-left {
      float: left;
    }

    .ddp-data-info-right {
      float: right;
    }

    .ddp-data-info-left {
      .ddp-ui-number {
        float: left;
        position: relative;
        padding: 0 14px;
        color: #b7b9c2;
        font-size: 13px;

        &:first-of-type {
          padding-left: 0;
          margin-top: -1px;
        }

        span.ddp-data-num {
          margin-left: 7px;
          font-weight: bold;
          color: #90969f;

          &:first-of-type {
            margin-left: 0;
          }
        }

        float: left;
      }

      .ddp-ui-info-tab {
        float: left;

        .ddp-ui-number {
          float: left;
          padding: 5px 14px 28px 14px;
          margin-top: -5px;
          cursor: pointer;
          font-size: 12px;

          &:hover {
            color: #4b515b;

            span.ddp-data-num {
              color: #4b515b;
            }

            &:after {
              display: inline-block;
              content: '';
              position: absolute;
              bottom: 10px;
              left: 50%;
              margin-left: -8px;
              width: 14px;
              height: 8px;
              background: url(/assets/bi/images/icon_selecview.png) no-repeat;
              background-position: left -42px;
            }
          }

          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            height: 12px;
            border-left: 1px solid #dbdce0;
          }

          &.ddp-selected {
            background-color: #4b515b;
            border-radius: 2px 2px 0 0;
            height: 48px;
            color: #fff;
            box-sizing: border-box;

            span.ddp-data-num {
              color: #fff;
            }

            em {
              &.ddp-icon-result-source {
                background-position: -24px top;
              }

              &.ddp-icon-result-users {
                background-position: -15px -44px;
              }
            }

            &:before {
              display: none;
            }

            &:after {
              display: inline-block;
              content: '';
              position: absolute;
              bottom: 10px;
              left: 50%;
              margin-left: -8px;
              width: 15px;
              height: 9px;
              background: url(/assets/bi/images/icon_selecview.png) no-repeat;
              background-position: left -26px;
            }
          }
        }
      }

      .ddp-box-tag strong {
        display: inline-block;
        padding-left: 5px;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .ddp-data-info-right {
      span.ddp-data-create {
        float: left;
        position: relative;
        top: 3px;
        color: #b7b9c2;
        font-size: 12px;

        span.ddp-data-name {
          font-weight: bold;
        }
      }

      .ddp-ui-more {
        float: left;
        margin-left: 5px;
        margin-right: -10px;
        position: relative;
        z-index: 60;

        a.ddp-icon-more:before {
          transform: rotate(90deg);
        }

        &.ddp-selected .ddp-icon-more:before {
          background-position: 0 -4px;
        }

        .ddp-wrap-popup2 {
          display: none;
          position: absolute;
          top: 25px;
          right: 0;
        }

        &.ddp-selected {
          .ddp-wrap-popup2 {
            display: block;
          }

          &:hover .ddp-ui-tooltip-info {
            display: none;
          }
        }
      }
    }
  }
}

.ddp-wrap-folder {
  margin: 0 -8px 9px -8px;
}

.ddp-box-folder {
  float: left;
  position: relative;
  margin: 3px 8px;
  padding: 8px 16px 8px 43px;
  width: 308px;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 0;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;

  &.ddp-selected:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #90969f;
  }

  &:hover {
    padding-right: 60px;
    border: 1px solid #8f959f;

    /* stroke */
    background-color: #fff;

    /* layer fill content */
    -moz-box-shadow: 0 1px 3px rgba(68, 87, 98, 0.2);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(68, 87, 98, 0.2);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(68, 87, 98, 0.2);

    /* drop shadow */
  }

  &.ddp-box-back {
    position: relative;
    padding: 8px 16px 8px 55px;
  }

  span.ddp-data-name {
    display: inline-block;
    max-width: 100%;
    height: 24px;
    line-height: 27px;
    color: #222;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    box-sizing: border-box;

    &.ddp-data-new {
      position: relative;
      padding-right: 40px;

      .ddp-icon-new {
        position: absolute;
        top: 6px;
        right: 0;
      }
    }
  }

  .ddp-data-input {
    display: none;
    position: absolute;
    top: 9px;
    left: 38px;
    right: 30px;
    bottom: 9px;
    background-color: #f6f6f7;
    z-index: 3;

    input {
      display: block;
      width: 100%;
      height: 100%;
      padding: 5px 23px 5px 5px;
      font-size: 14px;
      border: none;
      background: none;
      box-sizing: border-box;
    }

    .ddp-icon-control-check {
      position: absolute;
      top: 50%;
      right: 5px;
      margin-top: -8px;
      color: #90969f;
    }

    input {
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder {
        color: #90969f;
      }
    }
  }

  &.ddp-box-edit {
    padding-right: 30px;

    .ddp-data-input {
      display: block;
      height: 26px;
    }

    .ddp-btn-control {
      display: block;
    }
  }

  .ddp-ui-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 44px;
    z-index: 1;

    input[type='checkbox'] {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .ddp-icon-checkbox {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
    }
  }

  em.ddp-icon-folder-back {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 42px;
    background-color: #90969f;
  }

  &:hover em.ddp-icon-folder-back {
    background-color: #4b515b;
  }

  em.ddp-icon-folder-back:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -7px;
    content: '';
    width: 13px;
    height: 11px;
    background: url(/assets/bi/images/icon_back.png) no-repeat;
  }

  .ddp-btn-control {
    display: none;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;

    em.ddp-icon-control-edit {
      position: relative;
      top: -1px;
    }

    .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
      right: 17px;
    }
  }
}

em.ddp-icon-control-cut .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
  right: 17px;
}

.ddp-box-folder:hover .ddp-btn-control {
  display: block;
}

.ddp-wrap-box-block {
  margin: 0 -8px 0 -8px;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.ddp-box-block {
  position: relative;
  float: left;
  margin: 3px 8px 10px 8px;
  padding: 55px 0 0 0;
  width: 308px;
  height: 196px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #fff;

  /* layer fill content */
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  /* drop shadow */
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  /* drop shadow */
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  /* drop shadow */

  &:hover {
    border: 1px solid #90969f;
  }

  &.ddp-selected:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #90969f;
    z-index: 1;
  }

  em.ddp-icon-control-cut {
    display: none;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
  }

  &:hover em.ddp-icon-control-cut {
    display: block;
  }

  &.ddp-disabled {
    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: no-drop;
    }

    &:hover {
      em.ddp-icon-control-cut {
        display: none;
      }

      border: 1px solid #fff;
    }
  }

  .ddp-ui-box-top {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;

    .ddp-ui-checkbox {
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      float: left;
      margin: 0 0 0 13px;
      vertical-align: top;
      z-index: 5;

      input[type='checkbox'] {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      i.ddp-icon-checkbox {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
      }
    }

    .ddp-box-tag {
      display: inline-block;
      padding: 2px 3px 1px 3px;
      margin: 0 8px 0 9px;
      border-radius: 1px;
      font-size: 10px;
      font-weight: bold;
      vertical-align: top;

      &.ddp-workbook {
        border: 1px solid $accent;
        color: $accent;
      }

      &.ddp-notebook {
        border: 1px solid #5ecfea;
        color: #5ecfea;
      }

      &.ddp-workbench {
        border: 1px solid #bac1e5;
        color: #8c97d3;
      }
    }

    .ddp-icon-new {
      position: relative;
      top: 1px;
    }
  }

  .ddp-ui-box-contents {
    padding: 0 43px 0 43px;

    span {
      &.ddp-data-name {
        display: block;
        font-size: 18px;
        color: #545b65;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
        white-space: nowrap;

        &.ddp-data-line2 {
          max-height: 44px;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;

          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }

      &.ddp-data-date {
        display: block;
        padding: 7px 0 0 0;
        font-size: 12px;
        color: #c5c6cd;

        span.ddp-data-ago {
          color: #4c515a;
        }
      }
    }
  }

  .ddp-box-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(238, 238, 238, 0.5);

    .ddp-wrap-num {
      padding: 10px 16px;

      span.ddp-data-num {
        display: inline-block;
        padding-right: 20px;
        color: #90969f;
        font-size: 12px;

        em[class*='ddp-icon-'] {
          margin-right: 7px;
          vertical-align: middle;
        }

        .ddp-type-info {
          display: inline-block;
          position: relative;
          top: 1px;
          margin-left: 4px;
          vertical-align: middle;
          z-index: 2;

          &:hover {
            z-index: 50;
          }
        }
      }
    }

    .ddp-type-info {
      .ddp-ui-tooltip-info.ddp-down {
        display: none;
        right: initial;
        left: -19px;
        width: 190px;
        white-space: normal;
        word-break: break-all;
      }

      &:hover .ddp-ui-tooltip-info.ddp-down {
        display: block;
      }

      .ddp-ui-tooltip-info.ddp-down .ddp-icon-view-top {
        margin-right: 0;
        left: 27px;
        right: initial;
      }
    }

    .ddp-wrap-data {
      padding: 11px 14px 10px 14px;

      &:before {
        display: table;
        content: '';
      }

      &:after {
        display: table;
        content: '';
        clear: both;
      }

      .ddp-data-name {
        float: left;
        position: relative;
        max-width: 132px;
        margin: 0 0 0 10px;
        padding-left: 21px;
        font-size: 12px;
        color: #90969f;
        box-sizing: border-box;

        &:first-of-type {
          margin: 0;
        }

        > em[class*='ddp-icon-'] {
          position: absolute;
          top: 50%;
          left: 0;
          vertical-align: middle;
        }

        &[class*='ddp-data-lang']:before {
          position: absolute;
          top: 50%;
          left: 0;
        }

        em {
          &.ddp-icon-lang-zeppelin {
            margin-top: -6px;
          }

          &.ddp-icon-lang-jupyter {
            margin-top: -8px;
          }

          &.ddp-icon-result-data {
            margin-top: -7px;
          }

          &.ddp-icon-chart2 {
            margin-top: -8px;
          }
        }

        &.ddp-data-lang-python:before {
          margin-top: -6px;
        }

        &.ddp-data-lang-r:before {
          margin-top: -7px;
        }

        .ddp-txt-name {
          display: inline-block;
          max-width: 100%;

          .ddp-txt-name-in {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .ddp-type-info {
          float: right;
          position: relative;
          top: 1px;
          margin-left: 6px;
          z-index: 5;
        }
      }
    }

    .ddp-data-connection {
      position: relative;
      padding: 11px 15px 12px 43px;
      width: 100%;
      color: #90969f;
      font-size: 12px;
      box-sizing: border-box;

      .ddp-img {
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -11px;
      }
    }

    .ddp-type-info {
      float: right;
      position: relative;
      top: 1px;
      margin-left: 5px;
      z-index: 20;

      &:hover {
        z-index: 50;
      }
    }

    .ddp-txt-connection {
      display: inline-block;
      max-width: 100%;

      .ddp-txt-in {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &.ddp-box-workbench {
    display: inline-block;
    background: url(/assets/bi/images/bg_workbench.png) no-repeat right bottom;
    background-color: #fff;
  }
}

.page-workspace .ddp-ui-space-option .ddp-ui-space-nav .ddp-data-nav {
  max-width: 20%;

  &:last-of-type {
    padding-right: 25px;
  }

  .ddp-btn-folderplus {
    position: absolute;
    top: 6px;
    right: 0;
  }
}

.ddp-ui-space-option {
  position: relative;
  width: 100%;
  clear: both;
  box-sizing: border-box;

  .ddp-ui-option-right {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .ddp-ui-space-nav {
    padding: 20px 0 0 0;
    height: 60px;

    .ddp-data-nav {
      display: inline-block;
      min-height: 25px;
      max-width: 15%;
      position: relative;
      padding-left: 19px;
      margin-right: 4px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 7px;
        margin-top: -4px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: -5px -19px;
      }

      &:first-of-type {
        padding-left: 0;
        max-width: 15%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        line-height: 23px;

        &:after {
          display: none;
        }
      }

      em.ddp-icon-space {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background: url(/assets/bi/images/icon_workspace.png) no-repeat;
        vertical-align: middle;
      }

      span {
        &.ddp-data-namefolder {
          display: inline-block;
          position: relative;
          color: #35393f;
          font-size: 13px;
          line-height: 2em;
          cursor: pointer;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    a.ddp-btn-folderplus {
      display: inline-block;
      position: relative;
      top: 6px;
      width: 16px;
      height: 15px;
      background: url(/assets/bi/images/icon_buttons.png) no-repeat;
      background-position: left -198px;
      vertical-align: top;

      &:hover {
        background-position: -17px -198px;
      }
    }
  }

  .ddp-ui-option-right {
    .ddp-form-search {
      float: left;
      margin: 10px 15px 0 0;

      input::placeholder {
        color: #90969f;
      }
    }

    .ddp-type-dropdown {
      float: left;
      padding: 20px 0 0 0;
      margin-right: 5px;

      .ddp-wrap-popup2 {
        top: 43px;
      }
    }

    .ddp-list-tab-button {
      border: 1px solid #b7b9c3;
    }
  }

  .ddp-ui-datanone {
    position: relative;

    .ddp-view-datanone {
      position: static;
      margin: 0 auto;
      text-align: center;

      em.ddp-bg-img {
        display: inline-block;
        width: 190px;
        height: 130px;
        background: url(/assets/bi/images/bg_spacenone.png) no-repeat;
      }

      .ddp-txt-datanon {
        display: block;
        padding-top: 35px;
        text-align: Center;
        color: #90969f;
        font-size: 18px;
      }
    }
  }
}

.ddp-ui-space-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 1200px;
  border-top: 1px solid #d5d6db;
  background-color: #e7e7ea;
  z-index: 30;

  .ddp-ui-link {
    position: relative;
    float: left;
    padding: 0 15px 0 22px;

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 12px;
      margin-top: -6px;
      border-left: 1px solid #cfd0d6;
    }

    &:first-of-type {
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  .ddp-bottom-left {
    float: left;
    padding: 18px 0 17px 80px;
  }

  .ddp-ui-link {
    a {
      color: #4c515a;
      font-size: 13px;
    }

    &.ddp-disabled a {
      &:hover {
        color: #c1c2ca;
      }

      color: #c1c2ca;
    }

    a {
      em {
        &.ddp-icon-select-clone,
        &.ddp-icon-select-move,
        &.ddp-icon-select-delete {
          display: inline-block;
          margin-right: 8px;
          background: url(/assets/bi/images/icon_selection.png) no-repeat;
          vertical-align: middle;
        }

        &.ddp-icon-select-clone {
          width: 13px;
          height: 13px;
          background-position: -14px top;
        }

        &.ddp-icon-select-move {
          width: 16px;
          height: 14px;
          background-position: -17px -14px;
        }

        &.ddp-icon-select-delete {
          width: 12px;
          height: 13px;
          background-position: -13px -29px;
        }
      }

      &:hover em {
        &.ddp-icon-select-clone {
          background-position: -28px top;
        }

        &.ddp-icon-select-move {
          background-position: -34px -14px;
        }
      }
    }

    &.ddp-selected a em.ddp-icon-select-move {
      background-position: -34px -14px;
    }

    a:hover em.ddp-icon-select-delete {
      background-position: -26px -29px;
    }

    &.ddp-disabled a {
      cursor: no-drop;

      em.ddp-icon-select-clone,
      &:hover em.ddp-icon-select-clone {
        background-position: left top;
      }

      em.ddp-icon-select-move,
      &:hover em.ddp-icon-select-move {
        background-position: left -14px;
      }

      em.ddp-icon-select-delete,
      &:hover em.ddp-icon-select-delete {
        background-position: left -29px;
      }
    }
  }

  .ddp-bottom-right {
    float: right;
    padding: 10px 80px 10px 0;

    a.ddp-btn-solid2 {
      margin-left: 5px;
    }
  }

  .ddp-box-popup2 {
    position: absolute;
    bottom: 26px;
    left: -15px;
  }

  .ddp-ui-link {
    .ddp-box-popup2 {
      display: none;
    }

    &.ddp-selected .ddp-box-popup2 {
      display: block;
    }
  }
}

.ddp-box-popup2 {
  border-radius: 2px;
  -moz-box-shadow: 0 1px 3px rgba(82, 97, 105, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(82, 97, 105, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(82, 97, 105, 0.3);

  /* drop shadow */

  position: relative;
  width: 247px;
  height: 298px;
  background-color: #fff;

  .ddp-box-title {
    display: block;
    position: relative;
    padding: 12px 20px 12px 20px;
    font-weight: bold;
    font-size: 13px;
    color: #4c515a;
    border-bottom: 1px solid #e7e7ea;

    span.ddp-data-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }
  }

  .ddp-link-space {
    display: block;
    position: relative;
    padding: 12px 20px 12px 44px;
    border-bottom: 1px solid #e7e7ea;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    &:hover {
      background-color: #f6f6f7;
    }

    &.ddp-selected {
      color: #fff;
      background-color: #4b515b;
    }

    em {
      &.ddp-icon-user {
        position: absolute;
        top: 50%;
        left: 20px;
        display: inline-block;
        width: 10px;
        height: 12px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_group.png) no-repeat;
        background-position: left -28px;
        vertical-align: middle;
      }

      &.ddp-btn-back {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 44px;
        cursor: pointer;

        &:hover {
          background-color: #e7e7ea;
        }
      }
    }

    &.ddp-selected em.ddp-btn-back:hover {
      background-color: #2e333a;
    }

    em.ddp-btn-back:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 9px;
      margin: -5px 0 0 -5px;
      background: url(/assets/bi/images/btn_back.png) no-repeat;
      background-position: left -17px;
    }

    &.ddp-selected em.ddp-icon-user {
      background-position: left -41px;
    }
  }

  ul.ddp-list-folder {
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    bottom: 53px;
    padding: 6px 0;
    overflow-y: auto;

    li {
      position: relative;
      padding: 5px 30px 5px 13px;
      color: #4c515a;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f7;
      }

      &.ddp-selected {
        background-color: #4b515b;
        color: #fff;
      }

      &.ddp-disabled {
        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.5);
          z-index: 1;
          cursor: no-drop;
        }

        .ddp-btn-view {
          display: none;
        }

        &:hover {
          background: none;
        }
      }

      em.ddp-icon-folder2 {
        width: 13px;
        height: 12px;
        margin-right: 14px;
        float: left;
        background: url(/assets/bi/images/icon_folder.png) no-repeat;
        background-position: left -14px;
      }

      &.ddp-selected em.ddp-icon-folder2 {
        background-position: left -27px;
      }

      em {
        &.ddp-icon-shape,
        &.ddp-icon-user {
          float: left;
          margin-right: 9px;
          width: 17px;
          height: 13px;
          background: url(/assets/bi/images/icon_group.png) no-repeat;
          background-position: left -14px;
        }
      }

      &.ddp-selected em.ddp-icon-shape {
        background-position: -18px -14px;
      }

      em.ddp-icon-user {
        background-position: left -28px;
      }

      &.ddp-selected em.ddp-icon-user {
        position: relative;
        left: 3px;
        background-position: left -41px;
      }

      span {
        &.ddp-txt-name {
          display: block;
          overflow: Hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }

        &.ddp-btn-view {
          position: absolute;
          right: 0;
          bottom: 0;
          top: 0;
          width: 24px;
          cursor: pointer;

          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 4px;
            height: 7px;
            margin-top: -4px;
            background: url(/assets/bi/images/icon_dataview.png) no-repeat;
            background-position: left -19px;
          }

          &:hover {
            background-color: #d0d1d9;
          }
        }
      }

      &.ddp-selected span.ddp-btn-view:hover {
        background-color: #2e333a;
      }
    }
  }

  .ddp-ui-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    border-top: 1px solid #e7e7ea;
    text-align: center;

    .ddp-btn-type-popup2 {
      margin: 0 2px;

      &.ddp-bg-black {
        color: #fff;
      }
    }
  }
}

/***********************************************************************************************************
	PAGE : 워크스페이스 리스트 (list)
************************************************************************************************************/

.ddp-wrap-list-type {
  position: relative;
  padding-top: 14px;
  padding-bottom: 54px;

  * {
    font-weight: normal;
  }
}

ul.ddp-list-workspace {
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background-color: #fff;

  li {
    position: relative;

    &.ddp-disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background-color: rgba(255, 255, 255, 0.7);
      cursor: no-drop;
    }

    &:hover {
      position: relative;
    }

    &.ddp-list-back:hover {
      background-color: #f6f6f7;
    }

    &.ddp-disabled {
      &:hover {
        .ddp-btn-control {
          display: none;
        }

        a {
          background: none;
        }
      }

      a:hover .ddp-ui-data-list {
        background: none;
      }
    }

    &.ddp-list-back:hover a em.ddp-icon-folder-back {
      background-color: #4c515a;
    }

    &.ddp-txt a {
      cursor: default;

      &:hover {
        background: none;
      }
    }

    a {
      display: block;
      position: relative;
      padding: 12px 60px 12px 124px;
      border-top: 1px solid #ddd;

      &:hover {
        background-color: #f6f6f7;
      }
    }

    &.ddp-selected a {
      background-color: #f6f6f7;

      .ddp-ui-data-list {
        background-color: #f6f6f7;
      }
    }

    &:first-of-type a {
      border-top: none;
    }

    &.ddp-list-back a {
      padding: 10px 14px 10px 55px;
    }

    &.ddp-list-workbook a {
      padding-right: 278px;
    }

    &.ddp-list-notebook a {
      padding-right: 567px;
    }

    a {
      em.ddp-icon-folder-back {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        background-color: #90969f;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -7px;
          content: '';
          width: 13px;
          height: 11px;
          background: url(/assets/bi/images/icon_back.png) no-repeat;
        }
      }

      .ddp-wrap-name {
        padding: 0 0;
        position: relative;
        top: 1px;
        line-height: 1em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        box-sizing: border-box;

        span.ddp-data-detail {
          font-size: 14px;
          color: #b7bac1;
          vertical-align: top;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-wrap: normal;
        }

        .ddp-data-name {
          display: inline-block;
          height: 18px;
          padding: 0 0;
          color: #222;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-wrap: normal;

          .ddp-icon-new {
            display: none;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -7px;
            background-color: #fff;
          }
        }
      }

      &:hover .ddp-wrap-name .ddp-data-name .ddp-icon-new {
        background-color: #f6f6f7;
      }
    }

    &.ddp-selected a .ddp-wrap-name .ddp-data-name .ddp-icon-new {
      background-color: #f6f6f7;
    }

    a {
      .ddp-wrap-name .ddp-data-name {
        &.ddp-data-new {
          position: relative;
          padding-right: 40px;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-wrap: normal;
          box-sizing: border-box;

          .ddp-icon-new {
            display: block;
          }
        }

        span {
          &.ddp-data-detail {
            margin-left: 5px;
            color: #b7bac1;
            font-size: 14px;
          }

          &.ddp-data-in {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-wrap: normal;
          }
        }
      }

      .ddp-data-input {
        display: none;
        position: absolute;
        top: 9px;
        left: 124px;
        right: 32px;
        bottom: 9px;
        background-color: #f6f6f7;
        z-index: 3;

        input {
          display: block;
          width: 100%;
          height: 100%;
          padding: 5px 23px 5px 5px;
          font-size: 14px;
          border: none;
          background: none;
          box-sizing: border-box;
        }

        .ddp-icon-control-check {
          position: absolute;
          top: 50%;
          right: 5px;
          margin-top: -8px;
          color: #90969f;
        }

        input {
          &::-webkit-input-placeholder,
          &:-ms-input-placeholder {
            color: #90969f;
          }
        }
      }
    }

    &.ddp-box-edit a {
      .ddp-data-input,
      .ddp-btn-control {
        display: block;
      }

      &:hover {
        background: none;
      }
    }

    a {
      .ddp-ui-checkbox {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        z-index: 5;

        input[type='checkbox'] {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
        }

        i.ddp-icon-checkbox {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -6px;
        }
      }

      .ddp-btn-control {
        display: none;
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -10px;
        z-index: 2;
      }

      &:hover .ddp-btn-control {
        display: block;
      }

      .ddp-box-tag,
      .ddp-box-tag-value {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50px;
        margin-top: -7px;
        padding: 1px 2px 2px 2px;
        border-radius: 1px;
        font-size: 10px;
        font-weight: bold;
        line-height: 1em;
        vertical-align: top;
      }

      .ddp-box-tag {
        &.ddp-workbook {
          min-width: 62px;
          border: 1px solid $accent;
          color: $accent;
          text-align: center;
        }

        &.ddp-notebook {
          min-width: 62px;
          border: 1px solid #5ecfea;
          color: #5ecfea;
          text-align: center;
        }

        &.ddp-workbench {
          min-width: 62px;
          border: 1px solid #bac1e5;
          color: #8c97d3;
          text-align: center;
        }
      }

      .ddp-ui-data-list {
        position: relative;
        float: right;
        padding-left: 20px;
        background-color: #fff;
      }

      &:hover .ddp-ui-data-list {
        background-color: #f6f6f7;
      }

      .ddp-ui-data-list {
        .ddp-wrap-num {
          float: left;

          span.ddp-data-num {
            margin-left: 13px;
            font-size: 13px;
            color: #90969f;

            em {
              &.ddp-icon-btnsource,
              &.ddp-icon-dashboard {
                margin-right: 7px;
                vertical-align: middle;
              }
            }
          }
        }

        .ddp-data-date {
          float: left;
          padding: 0 0 0 25px;
          font-size: 12px;
          color: #b7bac1;

          span.ddp-data-ago {
            color: #4c515a;
          }
        }

        .ddp-data-info {
          float: left;

          .ddp-data-name {
            margin-left: 10px;
          }

          .ddp-data-connection {
            color: #90969f;
            font-size: 12px;
            vertical-align: middle;

            img {
              margin-right: 5px;
              vertical-align: middle;
            }

            .ddp-txt-connection {
              display: inline-block;
              position: Relative;
              top: 3px;
              max-width: 234px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: Hidden;
            }

            .ddp-type-info {
              top: -2px;
            }
          }
        }

        .ddp-type-info {
          display: inline-block;
          position: relative;
          top: -1px;
          margin-left: 4px;
          z-index: 20;

          &:hover {
            z-index: 50;

            .ddp-ui-tooltip-info {
              display: block;
              max-width: 190px;
              white-space: normal;
            }
          }
        }

        .ddp-data-info em[class*='ddp-icon'] {
          margin-right: 5px;
        }
      }
    }
  }

  &.ddp-select li a {
    padding-left: 89px;
  }
}

.ddp-select-catalog ul.ddp-list-workspace.ddp-select li.ddp-sub a:hover {
  padding-right: 60px;
}

/**********************************************************************************
	PAGE : 워크스페이스 데이터소스
**********************************************************************************/

.ddp-wrap-tab-popup {
  position: fixed;
  left: 44px;
  right: 44px;
  bottom: 24px;
  top: 186px;
  padding: 0 34px;
  border-top: 6px solid #4b515b;
  background-color: #fff;

  /* layer fill content */
  z-index: 999;
  overflow-y: auto;
  -moz-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);

  /* drop shadow */

  .ddp-ui-tab-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 33px;
    min-width: 1112px;
    overflow: auto;
  }

  .ddp-ui-title {
    position: relative;
    padding: 20px 0 16px 0;
    font-size: 18px;
    color: #4b515b;
    font-weight: bold;

    .ddp-label-checkbox {
      font-size: 0;
      margin-left: 20px;
      vertical-align: middle;
    }

    a.ddp-link-setting {
      position: absolute;
      top: 25px;
      right: 0;
    }

    .ddp-ui-rightoption {
      float: right;

      .ddp-type-search,
      .ddp-label-checkbox,
      .ddp-form-label2 {
        float: left;
      }

      .ddp-type-search {
        position: relative;
        top: 2px;
        width: 165px;
      }

      .ddp-label-checkbox {
        position: relative;
        top: 8px;
      }

      .ddp-form-label2 {
        margin-left: 28px;

        .ddp-wrap-dropdown {
          width: 168px;
        }
      }
    }
  }

  .ddp-popup-dashboard {
    width: inherit;
    position: absolute;
    top: 59px;
    left: 33px;
    bottom: 69px;
    right: 33px;
    min-width: 1074px;

    table.ddp-table-form tbody tr td .ddp-txt-long.ddp-global {
      padding-right: 66px;
    }

    .ddp-wrap-grid {
      height: 100%;

      .ddp-wrap-viewtable {
        padding-bottom: 43px;
        height: 100%;
        box-sizing: border-box;
      }

      .ddp-box-viewtable {
        min-height: 100%;
      }
    }
  }

  .ddp-box-grid-detail {
    top: 0;
  }
}

.ddp-popup-tabbottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 15px;
  text-align: center;
}

/**********************************************************************************
	PAGE : 워크스페이스 공유
**********************************************************************************/

.ddp-box-tab-popup {
  position: absolute;
  top: 59px;
  left: 34px;
  right: 34px;
  bottom: 70px;
  border: 1px solid #d5d6dc;

  .ddp-ui-left {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
    border-right: 1px solid #ddd;
    box-sizing: border-box;

    ul.ddp-ui-tab li {
      height: 40px;
      width: 50%;
      border-bottom: 1px solid #ddd;

      &.ddp-selected {
        border-bottom: 1px solid #fff;
      }

      a {
        padding-top: 12px;
        padding-bottom: 13px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .ddp-ui-tab-contents {
    padding: 20px 8px 0 8px;

    .ddp-form-search {
      width: 100%;
    }
  }
}

.ddp-ui-member {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 21px;
  overflow-y: auto;

  ul.ddp-list-checktype li {
    padding: 7px 9px;

    &.ddp-all {
      border-bottom: 1px dotted #cccccc;
      margin-bottom: 7px;
    }
  }
}

.ddp-box-tab-popup .ddp-ui-right {
  float: left;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}

.ddp-ui-member-table {
  position: relative;
  height: 100%;

  .ddp-ui-toptitle {
    position: relative;

    span.ddp-ui-name {
      display: block;
      padding: 15px 30px;
      color: #90969f;
      font-size: 13px;

      em.ddp-data-num {
        color: #4b515b;
      }
    }

    a.ddp-type-link3 {
      position: absolute;
      top: 17px;
      right: 28px;
    }
  }

  .ddp-box-viewtable2 {
    position: absolute;
    top: 47px;
    left: 1px;
    right: 0;
    bottom: 21px;

    &.ddp-user {
      top: 20px;
      bottom: 0;
    }

    .ddp-ui-gridbody2 {
      position: absolute;
      top: 25px;
      left: 0;
      bottom: 21px;
      overflow-y: scroll;
    }
  }

  .ddp-wrap-roles {
    .ddp-label {
      position: absolute;
      top: 0;
      right: 0;
      color: #90969f;
      font-size: 13px;
      cursor: pointer;

      &:before {
        display: inline-block;
        position: relative;
        top: -1px;
        margin-right: 4px;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -14px -30px;
        content: '';
        vertical-align: middle;
      }

      &:hover {
        color: #4b515b;
      }
    }

    &.ddp-selected .ddp-label {
      color: #4b515b;
    }

    .ddp-label:hover:before {
      background-position-x: -28px;
    }

    &.ddp-selected {
      .ddp-label:before {
        background-position-x: -28px;
      }

      .ddp-pop-form {
        display: block;
      }
    }
  }
}

.ddp-pop-form {
  display: none;
  position: fixed;
  width: 908px;
  padding: 18px 13px;
  margin: 5px 0 0 -365px;
  background: #fff;
  z-index: 10;
  border-radius: 3px;
  border: 1px solid #ddd;
  -webkit-box-shadow: -2px 1px 10px -4px rgba(84, 97, 104, 1);
  -moz-box-shadow: -2px 1px 10px -4px rgba(84, 97, 104, 1);
  box-shadow: -2px 1px 10px -4px rgba(84, 97, 104, 1);

  .ddp-label-title {
    padding-bottom: 22px;
    font-size: 12px;
    color: #4b515b;
    font-weight: bold;
  }

  a.ddp-close {
    display: inline-block;
    position: absolute;
    top: 19px;
    right: 19px;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: -12px -58px;
  }
}

table.ddp-table-form2 tbody tr td {
  &.ddp-inherit {
    overflow: inherit;
  }

  img.ddp-data-photo {
    width: 28px;
    height: 28px;
    float: left;
    margin-top: -6px;
    margin-right: 7px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
  }

  .ddp-ui-photo {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 6px;
  }

  em.ddp-icon-group {
    float: left;
    margin-right: 7px;
  }

  span.ddp-data-username {
    display: block;
    overflow: hidden;
  }
}

.ddp-wrap-type-option {
  position: relative;

  .ddp-box-layout4.type-time {
    width: 330px;
    position: absolute;
    top: 100%;
    right: -30px;
    margin-top: 5px;
    z-index: 15;
  }
}

table.ddp-table-type2 tr td.ddp-info .ddp-wrap-type-option .ddp-ui-info {
  right: -20px;
}

.ddp-ui-selected-option {
  span.ddp-link-text {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-right: 19px;
    cursor: pointer;
    box-sizing: border-box;

    &.ddp-txt-icon {
      padding-left: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
      vertical-align: middle;
    }

    span.ddp-wrap-icons {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 17px;
      height: 17px;
      margin-top: -9px;
      cursor: pointer;
    }
  }

  &.ddp-selected span.ddp-link-text span.ddp-wrap-icons {
    background-color: #f6f6f7;
  }

  span.ddp-link-text span.ddp-wrap-icons:before {
    display: inline-block;
    content: '';
    width: 6px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -3px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: left -22px;
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 32px;
    left: 30px;
    white-space: nowrap;
    max-height: 190px;
    overflow: auto;

    &.ddp-scroll-none {
      max-height: inherit;
      overflow: inherit;
    }
  }

  &.ddp-selected {
    .ddp-wrap-popup2 {
      display: block;
    }

    .ddp-types ul.ddp-list-popup li {
      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: -3px;
        left: 100%;
      }

      &:hover .ddp-wrap-popup2 {
        display: block;
      }
    }
  }
}

.ddp-wrap-share-set {
  position: relative;
  height: 100%;

  .ddp-data-total {
    display: block;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;

    em.ddp-data-num {
      color: $primary;
    }
  }

  .ddp-data-total2 {
    display: block;
    padding-left: 28px;
    color: #90969f;
    font-size: 13px;
  }

  .ddp-ui-left {
    position: relative;
    float: left;
    width: 327px;
    height: 100%;
    border-right: 1px solid #ddd;
  }

  .ddp-ui-right {
    float: left;
    width: 100%;
    height: 100%;
    margin-left: -328px;
    padding-left: 327px;
    box-sizing: border-box;
  }

  .ddp-wrap-tab {
    margin-bottom: 15px;
  }

  .ddp-wrap-tab-contents {
    height: 100%;
  }

  .ddp-ui-tab-contents {
    height: 100%;
    margin-top: -57px;
    padding-top: 57px;
    box-sizing: border-box;
  }

  &.ddp-group {
    position: relative;
    padding-top: 40px;
    box-sizing: border-box;

    .ddp-data-total {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.ddp-wrap-switch {
  position: relative;
  padding-right: 35px;

  .ddp-form-search {
    width: 100%;
  }

  a.ddp-btn-switch {
    position: absolute;
    top: 10px;
    right: 17px;
  }
}

/**********************************************************************************
	page : 워크스페이스 관리
**********************************************************************************/

.ddp-box-statistic {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #dadbe0;
  overflow: hidden;

  .ddp-wrap-option {
    position: absolute;
    top: 7px;
    right: 20px;

    .ddp-type-selectbox {
      float: left;
      width: 160px;
      margin-left: 8px;
    }
  }

  .ddp-col-6 {
    position: relative;
    padding: 20px 0;

    &.ddp-first .ddp-view-statistic {
      border-left: none;
    }
  }

  .ddp-view-statistic {
    border-left: 1px solid #e7e7ea;
  }

  .ddp-label-name {
    padding: 13px 20px;
    color: #4c515a;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid #dadbe0;
    background-color: #f6f6f7;
  }

  .ddp-data-box {
    display: inline-block;
    width: 325px;
    padding: 20px 0 0 0;
    text-align: center;

    .ddp-label {
      display: block;
      text-align: center;
      color: #4c515a;
      font-size: 15px;
      font-weight: bold;
    }

    .ddp-data {
      display: block;
      text-align: center;
      color: #4c515a;
      font-size: 70px;
    }
  }

  .ddp-data-remark {
    position: absolute;
    top: 49px;
    left: 325px;

    .ddp-list-remark li {
      display: block;
      width: 100%;
      padding-bottom: 5px;
      color: #4b515b;
      font-weight: bold;
      font-size: 13px;
      line-height: 17px;

      .ddp-box-tag {
        display: block;
        width: 64px;
        margin-right: 10px;
        text-align: center;
      }
    }

    .ddp-data-average {
      display: block;
      padding: 10px 0 0 0;
      color: #b7bac1;
      font-size: 13px;
    }
  }

  .ddp-view-statistic {
    &.ddp-setting {
      padding: 20px 0 0 0;

      .ddp-name {
        display: block;
        color: $primary;
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        .ddp-icon-favorite {
          display: inline-block;
          position: relative;
          top: -2px;
          width: 12px;
          height: 12px;
          margin-right: 5px;
          background: url(/assets/bi/images/icon_favorite.png) no-repeat;
          vertical-align: middle;
        }
      }

      .ddp-data {
        display: block;
        font-size: 70px;
        text-align: center;
        color: $primary;
      }
    }

    .ddp-data-label {
      display: block;
      padding: 20px 45px 0 45px;
      color: #4c515a;
      font-size: 16px;
      font-weight: bold;
    }

    .ddp-data-graph {
      height: 570px;
      padding: 20px 45px;
    }
  }
}

/**********************************************************************************
		page : 워크스페이스 생성
**********************************************************************************/

.ddp-box-popupcontents4 {
  width: 880px;
  margin: 0 auto;
  text-align: left;
}

table.ddp-table-base {
  position: relative;
  width: 100%;
  table-layout: fixed;
  overflow: Hidden;

  thead tr th {
    padding: 5px 0;
    background-color: #e7e7ea;
    font-weight: normal;
    text-align: center;
  }

  tr {
    td {
      padding: 5px 0;
      background-color: #e7e7ea;
      font-weight: normal;
      text-align: center;
    }

    th {
      border: 1px solid #fff;
      text-align: center;
      color: #4b515b;
    }
  }

  thead tr {
    &:last-of-type th {
      background-color: #fdfdfd;
    }

    th {
      .ddp-data-name {
        float: left;
        width: 33.333%;
        text-align: center;
        box-sizing: border-box;
      }

      .ddp-data-name2 {
        float: left;
        width: 50%;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  tr td {
    padding: 5px 10px;
    width: 156px;
    border: 1px solid #fff;
    box-sizing: border-box;

    .ddp-txt-edit.ddp-selected .ddp-wrap-input {
      margin-top: 0;
    }
  }
}

.ddp-ui-popup-contents table.ddp-table-base tr td:first-of-type {
  width: 140px;
}

table.ddp-table-base tr {
  td {
    &:first-of-type {
      width: 161px;
    }

    &:nth-child(2) {
      width: 95px;
    }

    &:nth-child(6) {
      width: 104px;
    }

    &:nth-child(7) {
      width: 52px;
    }
  }

  &:nth-child(even) td {
    background-color: #fdfdfd;
  }

  &:nth-child(odd) td {
    background-color: #f8f8f9;
  }

  td {
    .ddp-data-det {
      float: left;
      width: 33.3333%;
    }

    [class*='ddp-data-'] .ddp-label-checkbox {
      width: 12px;
      height: 12px;
      padding: 0;

      .ddp-icon-checkbox {
        position: relative;
      }
    }

    .ddp-data-det2 {
      float: left;
      width: 50%;
    }

    .ddp-delete {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: -15px -131px;

      &:hover {
        background-position-x: 0;
      }
    }

    .ddp-txt-edit {
      &.ddp-selected .ddp-wrap-input {
        position: relative;
        min-width: 100%;
      }

      .ddp-data-name {
        position: relative;
        width: 100%;
        margin-top: 0;
        padding: 0 15px 0 5px;
        text-align: left;
        box-sizing: border-box;

        &:hover .ddp-icon-edit2 {
          position: absolute;
          right: 0;
          top: inherit;
          bottom: 5px;
          margin-top: 0;
        }

        &.ddp-blank {
          color: rgba(75, 81, 91, 0.3);
        }
      }

      .ddp-icon-edit2 {
        position: absolute;
        top: 3px;
        right: 0;
      }
    }

    .ddp-icon-checked {
      display: inline-block;
      width: 11px;
      height: 8px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      background-position: -68px top;
    }

    .ddp-link-add {
      color: #4b515b;
      font-size: 12px;

      &:before {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: left -22px;
        vertical-align: middle;
        content: '';
      }

      &:hover {
        font-weight: bold;
      }
    }

    .ddp-data-role {
      display: block;
      text-align: left;
      font-weight: bold;
    }

    .ddp-select-role {
      display: inline-block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $primary;
    }

    position: relative;
    border-top: none;
    border-bottom: none;

    &:first-of-type {
      border-left: none;
      text-align: left;
    }

    &:last-of-type {
      border-left: none;
    }

    &.ddp-error:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      border: 1px solid #eb5f58;
      box-sizing: Border-box;
    }
  }
}

.ddp-ui-ex {
  margin-top: 15px;

  .ddp-label-ex {
    display: block;
    color: #b7bac1;
    font-size: 13px;
    font-weight: bold;
  }
}

ul.ddp-list-ex {
  padding: 3px 0 0 0;

  li {
    padding: 0 0 3px 4px;
    color: #b7bac1;
    font-size: 12px;

    &:before {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-right: 7px;
      border-radius: 50%;
      background-color: #b7bac1;
      vertical-align: middle;
      content: '';
    }
  }
}

/**********************************************************************************
	page : 워크스페이스 스키마
**********************************************************************************/

.ddp-pop-schema {
  position: relative;
  height: 100%;
  margin-top: -120px;
  padding: 120px 50px 0 50px;
  box-sizing: border-box;

  .ddp-pop-head {
    position: relative;
    border-top: 1px solid #d0d1d9;

    .ddp-pop-name {
      display: block;
      padding: 35px 0 17px 10px;
      font-size: 14px;
      color: #4b515b;
    }

    .ddp-ui-pop-buttons {
      position: absolute;
      top: 30px;
      right: 20px;
    }
  }

  .ddp-ui-ex {
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 50px;
    padding: 17px 0 25px 0;
    border-top: 1px solid #d0d1d9;
  }

  .ddp-form-table {
    position: absolute;
    top: 190px;
    left: 50px;
    right: 50px;
    bottom: 170px;
    overflow: auto;
  }
}

table.ddp-table-list2 {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;

  tr {
    th {
      padding: 0 10px 9px 10px;
      color: #b7b9c2;
      text-align: left;
      font-weight: normal;

      .ddp-link-edit {
        float: right;
        color: #90969f;

        &:hover {
          text-decoration: underline;
        }

        em.ddp-icon-edit {
          display: inline-block;
          position: relative;
          top: -1px;
          width: 10px;
          height: 10px;
          margin-right: 2px;
          background: url(/assets/bi/images/icon_edit2.png) no-repeat;
          background-position: -22px top;
          vertical-align: middle;
        }
      }

      &:nth-child(2) {
        padding-left: 25px;
      }
    }

    td {
      position: relative;
      padding: 5px 50px 5px 25px;
      color: #4b515b;
      font-size: 13px;
      border-bottom: 4px solid #fff;
      background-color: #f6f6f7;

      .ddp-wrap-info {
        position: absolute;
        top: 50%;
        right: 30px;
        margin-top: -7px;

        em.ddp-icon-info {
          display: inline-block;
          width: 13px;
          height: 13px;
          background: url(/assets/bi/images/icon_info.png) no-repeat;
          background-position: -14px -30px;
          cursor: pointer;

          &:hover {
            background-position: -28px -30px;
          }
        }

        &:hover .ddp-pop-form {
          display: block;
          min-width: 683px;
          z-index: 90;
        }
      }

      .ddp-name {
        display: inline-block;
        padding: 7px 10px 9px 10px;
        box-sizing: border-box;
      }

      .ddp-view {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 4px;
        height: 7px;
        margin-top: -4px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -89px;
      }

      .ddp-type-selectbox {
        margin-right: -25px;
      }

      .ddp-table-base * {
        color: inherit;
      }

      table.ddp-table-base {
        td,
        tr th {
          padding: 5px 10px;
        }
      }
    }
  }
}

.ddp-schema-top {
  .ddp-title {
    color: #4b515b;
    font-size: 13px;
  }

  .ddp-btn-line2 {
    position: relative;
    top: -7px;
    float: right;
  }
}

/**************************************************************
  popup : 01_워크스페이스_07changeOwner_popup
**************************************************************/

.pop-box-owner {
  width: 700px;
  height: 333px;
  margin: 17px auto 4px auto;
  border: 1px solid #d5d6db;
  text-align: left;
  box-sizing: border-box;
  overflow: auto;

  ul.ddp-list-popup li a .ddp-rank {
    color: #b7b9c2;
  }

  .ddp-ui-division {
    padding: 0 14px 15px 14px;

    + .ddp-ui-division {
      border-top: 1px solid #d5d6db;
    }

    .ddp-ui-top {
      margin: 0 0 0 0;

      .ddp-top-name {
        display: block;
        padding: 20px 0 0 0;
        color: #565c65;
        font-size: 14px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .ddp-btn-window {
          float: left;
          position: relative;
          top: 5px;
          width: 12px;
          height: 10px;
          margin-left: 6px;
          background: url(/assets/bi/images/icon_window.png) no-repeat;
        }

        .ddp-tag-global {
          float: left;
          padding: 2px 6px;
          margin-left: 6px;
          border-radius: 2px;
          border: 1px solid #9ca2c9;
          color: $primary;
          font-size: 11px;
          font-weight: normal;
          background-color: #eff0f7;
        }

        .ddp-txt-name {
          display: inline-block;
          max-width: 100%;
          padding-right: 10px;
          box-sizing: border-box;

          .ddp-data-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .ddp-btn-name {
          float: right;
        }
      }
    }

    .ddp-top-option {
      float: right;
      padding: 14px 0 0 0;
    }

    .ddp-ui-top .ddp-wrap-edit3 {
      display: inline-block;
      width: auto;
      float: right;
    }

    .ddp-wrap-edit3 .ddp-type-selectbox {
      display: inline-block;
      width: 168px;
      height: 30px;
      box-sizing: border-box;
    }
  }

  .ddp-type-selectbox.ddp-type-search-select {
    span.ddp-txt-selectbox {
      display: inline-block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      padding: 6px 26px 6px 30px;
      height: 30px;
      box-sizing: border-box;

      &:before {
        left: 10px;
      }
    }

    &.ddp-result span.ddp-txt-selectbox {
      padding-left: 10px;
    }
  }

  .ddp-ui-division {
    .ddp-wrap-edit3 {
      margin-top: 10px;

      label.ddp-label-type {
        display: inline-block;
        width: auto;
        padding-right: 6px;
      }

      .ddp-txt-error {
        display: inline-block;
        position: relative;
        top: 7px;
        margin-left: 6px;
        vertical-align: top;
      }

      .ddp-ui-edit-option {
        display: inline-block;
      }
    }

    .ddp-data-description {
      color: #b7b9c2;
      font-size: 12px;
    }

    .ddp-type-selectbox {
      &.ddp-selected .ddp-wrap-popup2 {
        position: fixed;
        min-width: 166px;
        right: inherit;

        li a span.ddp-name {
          display: inline-block;
          max-width: 85px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: middle;
        }
      }

      ul.ddp-list-popup li.ddp-disabled > a {
        color: #d0d1d8 !important;
        opacity: 1;

        .ddp-rank {
          color: #d0d1d8;
        }
      }
    }
  }
}

/**********************************************************************************
	PAGE : 노트북 서버 set
**********************************************************************************/

ul.ddp-ui-tab li {
  a em {
    &.ddp-icon-jupyter,
    &.ddp-icon-zepplin {
      display: inline-block;
      margin-right: 9px;
      background: url(/assets/bi/images/icon_server.png) no-repeat;
      vertical-align: middle;
    }

    &.ddp-icon-jupyter {
      width: 12px;
      height: 16px;
      background-position: -13px top;
    }
  }

  &.ddp-selected a em.ddp-icon-jupyter {
    background-position: left top;
  }

  a em.ddp-icon-zepplin {
    width: 18px;
    height: 10px;
    background-position: -19px -17px;
  }

  &.ddp-selected a em.ddp-icon-zepplin {
    background-position: left -17px;
  }
}

.ddp-ui-notebook-server {
  position: absolute;
  top: 120px;
  left: 50px;
  right: 50px;
  bottom: 0;
  box-sizing: border-box;

  .ddp-ui-sublabel {
    padding: 15px 0 15px 0;
    color: #4b515b;
    font-size: 14px;

    em {
      display: inline-block;
      padding: 0 4px;
    }
  }

  .ddp-type-top-option {
    position: relative;

    a.ddp-btn-switch {
      position: absolute;
      top: 10px;
      right: 10px;

      .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
        right: 12px;
      }
    }

    .ddp-option-right {
      position: absolute;
      top: 0;
      right: 0;

      .ddp-btn-selection {
        color: #90969f;

        &:hover {
          color: #4b515b;
        }

        .ddp-btn-reset3 {
          margin-right: 6px;
          vertical-align: middle;
        }

        &:hover .ddp-btn-reset3 {
          background-position: -15px -34px;
        }
      }
    }
  }

  .ddp-wrap-viewtable {
    position: absolute;
    top: 146px;
    left: 0;
    right: 0;
    bottom: 24px;
    padding-bottom: 45px;
  }
}

/**********************************************************************************
	PAGE : 노트북 api
**********************************************************************************/

.ddp-wrap-label {
  position: relative;
  display: inline-block;

  .ddp-wrap-hover-info {
    display: inline-block;
    position: relative;
    top: -3px;
    margin-left: 5px;
    cursor: pointer;

    &:hover .ddp-box-layout4 {
      display: block;
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: -10px;
      left: 20px;

      .ddp-data-det {
        padding: 18px 20px;
        color: #90969f;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;

        .ddp-txt-tip {
          padding: 18px 0 0 0;
          color: #35393f;
          font-size: 12px;
        }
      }
    }

    em.ddp-icon-info3 {
      display: inline-block;
      width: 11px;
      height: 11px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #5d636c;
      font-size: 14px;
    }
  }
}

.ddp-wrap-edit3.ddp-type .ddp-wrap-label .ddp-wrap-hover-info em.ddp-icon-info3 {
  top: 1px;
  background: none;
}

.ddp-wrap-label .ddp-wrap-hover-info em.ddp-icon-info3:before {
  display: inline-block;
  content: '?';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -2px;
  color: #4b515b;
  font-size: 9px;
}

/**********************************************************************************
	차트
**********************************************************************************/

@keyframes slideshow {
  from {
    top: 300px;
    left: 400px;
    bottom: 300px;
    right: 400px;
    opacity: 0;
  }

  to {
    top: 30px;
    left: 40px;
    right: 40px;
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes slidehide {
  from {
    top: 30px;
    left: 40px;
    right: 40px;
    bottom: 30px;
    visibility: visible;
    opacity: 1;
  }

  to {
    top: 300px;
    left: 400px;
    bottom: 300px;
    right: 400px;
    visibility: hidden;
    opacity: 0;
  }
}

.ddp-layout-chart {
  position: fixed;
  top: 55px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 126;
  background-color: #fff;
  box-sizing: border-box;
  -webkit-box-shadow: -2px 0 7px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0 7px 0 rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0 7px 0 rgba(0, 0, 0, 0.35);
  animation-duration: 0.5s;
  animation-name: slideshow;
  z-index: 127;

  &.ddp-hide {
    animation-duration: 0.5s;
    animation-name: slidehide;
    visibility: hidden;
  }

  &:before {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(208, 209, 216, 0.5);
    content: '';
  }

  .ddp-layout-contents2 {
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding: 0;
    z-index: 31;
    background-color: #fff;
    box-sizing: border-box;
  }
}

.ddp-wrap-chart {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  .ddp-ui-buttons2 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    text-align: center;
    border-top: 1px solid #e7e7ea;
    background-color: #f7f7f8;
    z-index: 70;

    .ddp-btn-type-popup {
      margin: 0 2px;
    }
  }

  .ddp-wrap-chart-lnb {
    float: left;
    width: 288px;
    height: 100%;
    background-color: #35393f;
    vertical-align: top;
  }

  .ddp-view-chart-lnb {
    position: relative;
    height: 100%;
  }

  .ddp-ui-chart-lnb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
}

.ddp-ui-chart-lnb {
  .ddp-box-down {
    border-bottom: none;

    .ddp-list-label {
      color: #fff;
    }

    .ddp-form {
      margin: 0 -2px;

      [class*='ddp-col-'] {
        padding: 0 2px;
      }
    }

    .ddp-form-buttons {
      padding: 14px 0 0 0;

      .ddp-btn-blue {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }

  .ddp-wrap-slider-down {
    .ddp-wrap-option-slider {
      span.ddp-label-slider {
        color: #d0d1d8;
      }

      padding: 7px 0;
    }

    .ddp-list-part-sub {
      display: none;
      padding: 0 0 0 20px;
    }

    &.ddp-selected .ddp-list-part-sub {
      display: block;
    }

    .ddp-list-part-sub .ddp-divide2 .ddp-list-label {
      padding: 7px 0;
      color: #90969f;
    }
  }
}

/* wrap slider down */

.ddp-wrap-chart {
  .ddp-wrap-chart-contents {
    width: 100%;
    height: 100%;
    vertical-align: top;
    overflow: hidden;
    box-sizing: border-box;

    .ddp-view-chart-contents {
      position: relative;
      height: 100%;
      overflow: auto;

      &.ddp-tooltip {
        z-index: 70;
        overflow: initial;

        .ddp-ui-chart-contents {
          z-index: 10;
          overflow: initial;
        }
      }

      .ddp-wrap-naviarea {
        padding-right: 430px;
        margin-left: 0;

        .ddp-box-navi.ddp-selected .ddp-wrap-input {
          width: 100%;
        }

        .ddp-ui-control {
          position: absolute;
          top: 50%;
          right: 14px;
          margin-top: -15px;
        }
      }
    }

    &.ddp-size .ddp-view-chart-contents {
    }

    .ddp-ui-chart-contents {
      position: absolute;
      top: 198px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      &.ddp-map-chart {
        &.ddp-shelf1 {
          top: 104px;
        }

        &.ddp-shelf2 {
          top: 149px;
        }

        &.ddp-shelf3 {
          top: 194px;
        }

        .ddp-ui-chart-main .ddp-ui-chart-area {
          padding: 0;
        }
      }
    }
  }

  .ddp-wrap-chart-side {
    float: left;
    width: 400px;
    height: 100%;
    background-color: #f7f7f8;
    vertical-align: top;
    z-index: 60;

    &.type-popup .ddp-chart-side-in {
      z-index: 70;
    }

    .ddp-chart-side-in {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 61;
    }
  }

  .ddp-ui-chart-side {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-left: 2px solid #e7e7ea;
    overflow-y: auto;
    overflow-x: hidden;

    .ddp-area-boardside {
      bottom: 0;
    }

    .ddp-wrap-page {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px 0;
      text-align: center;

      .ddp-ui-page {
        display: inline-block;
      }
    }

    .ddp-ui-scroll {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 51px;
      overflow: auto;
      overflow-x: hidden;
    }

    .ddp-ui-option-down .ddp-ui-down-title em.ddp-icon-menumove {
      position: relative;
    }
  }
}

.ddp-wrap-chart-menu {
  float: left;
  position: relative;
  width: 52px;
  height: 100%;
  border-left: 2px solid #e7e7ea;
  background-color: #f7f6f8;
  vertical-align: top;
  box-sizing: border-box;
  overflow: auto;
  z-index: 71;

  a {
    display: block;
    position: relative;
    height: 52px;
    text-align: center;
    z-index: 62;

    em[class*='ddp-icon-menu'] {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .ddp-ui-tooltip-info {
      display: none;
      position: fixed;
      left: inherit;
      right: 95px;
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    &.ddp-selected {
      background-color: #90969f;
    }

    &:hover em {
      &.ddp-icon-menu-color {
        background-position: -22px -58px;
      }

      &.ddp-icon-menu-axis {
        background-position: -22px -83px;
      }

      &.ddp-icon-menu-num {
        background-position: -27px -105px;
      }

      &.ddp-icon-menu-presentation {
        background-position: -23px -119px;
      }

      &.ddp-icon-menu-tooltip {
        background-position: -25px -143px;
      }

      &.ddp-icon-menu-split {
        background-position: -24px -167px;
      }

      &.ddp-icon-menu-filter {
        background-position: -19px -21px;
      }

      &.ddp-icon-menu-general {
        background-position-x: -25px;
      }

      &.ddp-icon-menu-axis-x,
      &.ddp-icon-menu-axis-y {
        background-position-x: -22px;
      }

      &.ddp-icon-menu-legend,
      &.ddp-icon-menu-label {
        background-position-x: -23px;
      }

      &.ddp-icon-menu-secondary {
        background-position-x: -26px;
      }

      &.ddp-icon-menu-calculated {
        background-position-x: -19px;
      }

      &.ddp-icon-menu-mapview1,
      &.ddp-icon-menu-mapview2,
      &.ddp-icon-menu-mapview3,
      &.ddp-icon-menu-analysis {
        background-position-x: -23px;
      }
    }

    &.ddp-selected em {
      &.ddp-icon-menu-color {
        background-position: -44px -58px;
      }

      &.ddp-icon-menu-axis {
        background-position: -44px -83px;
      }

      &.ddp-icon-menu-num {
        background-position: -54px -105px;
      }

      &.ddp-icon-menu-presentation {
        background-position: -46px -119px;
      }

      &.ddp-icon-menu-tooltip {
        background-position: -50px -143px;
      }

      &.ddp-icon-menu-split {
        background-position: -48px -167px;
      }

      &.ddp-icon-menu-filter {
        background-position: -38px -21px;
      }

      &.ddp-icon-menu-calculated {
        background-position-x: -38px;
      }

      &.ddp-icon-menu-general {
        background-position-x: -49px;
      }

      &.ddp-icon-menu-axis-x,
      &.ddp-icon-menu-axis-y {
        background-position-x: -44px;
      }

      &.ddp-icon-menu-legend,
      &.ddp-icon-menu-label {
        background-position-x: -46px;
      }

      &.ddp-icon-menu-secondary {
        background-position-x: -52px;
      }

      &.ddp-icon-menu-mapview1,
      &.ddp-icon-menu-mapview2,
      &.ddp-icon-menu-mapview3,
      &.ddp-icon-menu-analysis {
        background-position-x: -46px;
      }
    }

    &.ddp-disabled {
      cursor: no-drop;

      em[class*='ddp-icon-menu-'] {
        opacity: 0.5;
        cursor: no-drop;
      }

      &:hover {
        .ddp-ui-tooltip-info {
          display: block;
        }

        em {
          &.ddp-icon-menu-color {
            background-position: left -58px;
          }

          &.ddp-icon-menu-axis {
            background-position: left -83px;
          }

          &.ddp-icon-menu-num {
            background-position: left -105px;
          }

          &.ddp-icon-menu-presentation {
            background-position: left -119px;
          }

          &.ddp-icon-menu-tooltip {
            background-position: left -143px;
          }

          &.ddp-icon-menu-split {
            background-position: left -167px;
          }

          &.ddp-icon-menu-filter {
            background-position: left -21px;
          }

          &.ddp-icon-menu-mapview1 {
            background-position: left -396px;
          }

          &.ddp-icon-menu-mapview2 {
            background-position: left -417px;
          }

          &.ddp-icon-menu-mapview3 {
            background-position: left -438px;
          }

          &.ddp-icon-menu-analysis {
            background-position: left -459px;
          }
        }
      }
    }
  }
}

/**********************************************************************************
    차트 (lnb)
**********************************************************************************/

.ddp-wrap-dropmenu {
  border-bottom: 1px solid #35393f;
}

.ddp-ui-dropmenu {
  .ddp-ui-drop-title {
    position: relative;
    padding: 16px 22px;
    font-size: 16px;
    color: #fff;
    text-align: left;
    font-weight: bold;
    background-color: #4b515b;
    cursor: pointer;

    span.ddp-data-det {
      position: absolute;
      top: 50%;
      right: 41px;
      margin-top: -7px;
      font-size: 12px;
      color: #90969f;
    }

    em.ddp-icon-drop-view {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 22px;
      width: 9px;
      height: 5px;
      margin-top: -3px;
      background: url(/assets/bi/images/icon_menuview.png) no-repeat;
      background-position: left -20px;
    }
  }

  &.ddp-selected .ddp-ui-drop-title em.ddp-icon-drop-view {
    display: none;
  }

  &.ddp-disabled .ddp-ui-drop-title {
    background-color: #4b515b;
    color: #90969f;
    cursor: no-drop;
  }

  .ddp-ui-drop-contents {
    display: none;
    overflow: auto;

    .ddp-wrap-list-source {
      display: none;
      position: relative;
      top: 0;
      overflow: inherit;
    }
  }

  &.ddp-selected .ddp-ui-drop-contents {
    display: block;
  }
}

.ddp-ui-drop-contents .ddp-wrap-source-name {
  padding: 9px 13px;
  margin-bottom: -4px;

  .ddp-data-in {
    width: 100%;
    padding-right: 47px;

    .ddp-btn-info {
      position: absolute;
      top: 50%;
      right: 27px;
      margin-top: -6px;
      opacity: 0.6;

      &:hover {
        opacity: 1;
        background-position-x: -14px;
      }
    }
  }
}

.ddp-wrap-source-name .ddp-data-in .ddp-box-layout4.ddp-datasearch .ddp-form-search {
  width: 220px;
  margin: 0 10px;
}

.ddp-ui-drop-contents .ddp-ui-drop-name {
  display: block;
  padding: 6px 22px;
  color: #b7b9c2;
  font-size: 13px;
  background-color: #2f3339;

  em.ddp-icon-source2 {
    margin-right: 8px;
  }
}

.ddp-ui-drop-option {
  position: relative;
  padding: 0 22px 0 22px;
  margin: 10px 0 17px 0;

  .ddp-form-search {
    width: 100%;

    input {
      padding-left: 15px;
      border-bottom: 1px solid #656971;
      color: #90969f;

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder {
        color: #90969f;
      }
    }
  }

  a.ddp-btn-addplus {
    padding: 4px;
    position: absolute;
    top: 50%;
    right: 18px;
    margin-top: -8px;

    &:before {
      display: inline-block;
      content: '';
      width: 9px;
      height: 9px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -20px top;
    }

    &:hover:before {
      background-position: -30px top;
    }
  }
}

.ddp-ui-drop-contents ul.ddp-list-source li {
  color: #d0d1d8;

  &:hover {
    background-color: #2f3339;
  }

  &.ddp-selected {
    background-color: #2f3339;

    em.ddp-icon-control-more:before {
      background-position: -3px -164px;
    }
  }

  em {
    &.ddp-icon-rows,
    &.ddp-icon-columns {
      position: absolute;
      top: 9px;
      left: 22px;
    }

    &.ddp-icon-cross {
      position: absolute;
      top: 9px;
      left: 22px;
      top: 9px;
    }

    &.ddp-icon-alias {
      display: block;
      margin-top: 3px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.ddp-icon-network {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 19px;
      margin-top: -8px;

      &.icon-source {
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_network.png) no-repeat;
      }

      &.icon-target {
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_network.png) no-repeat;
        background-position: left -16px;
      }

      &.icon-link {
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_network.png) no-repeat;
        background-position: left -32px;
      }
    }

    &.ddp-icon-datause {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 19px;
      margin-top: -6px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/icon_use.png) no-repeat;
    }
  }
}

.ddp-wrap-drop-submenu {
  .ddp-ui-sub-title {
    position: relative;
    padding-left: 22px;

    &:hover {
      background-color: #2f3339;
      cursor: pointer;
    }

    .ddp-icon-add {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 14px;
      margin-top: -7px;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -98px -22px;

      &:hover {
        background-position-x: -14px;
      }
    }

    .ddp-wrap-add-type {
      position: absolute;
      top: 50%;
      right: 14px;
      margin-top: -6px;

      .ddp-icon-add2 {
        display: inline-block;
        position: relative;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -98px -22px;
        z-index: 5;
      }

      &:hover .ddp-icon-add2 {
        background-position-x: -28px;
      }

      .ddp-wrap-link-type {
        display: none;
        position: absolute;
        top: -3px;
        right: -3px;
        padding-right: 20px;
        white-space: nowrap;
      }

      &:hover .ddp-wrap-link-type {
        display: block;
        padding: 2px 20px 2px 2px;
        background-color: #4c515a;
        border-radius: 15px;
      }

      .ddp-wrap-link-type .ddp-link-type {
        margin-left: 10px;
        color: #b7b9c2;
        font-size: 12px;

        .ddp-icon-measure-value {
          display: inline-block;
          width: 11px;
          height: 11px;
          margin-right: 6px;
          background: url(/assets/bi/images/icon_value.png) no-repeat;
        }

        .ddp-icon-parameter-value {
          display: inline-block;
          width: 11px;
          height: 11px;
          margin-right: 6px;
          background: url(/assets/bi/images/icon_value.png) no-repeat;
          width: 11px;
          height: 9px;
          background-position: left -12px;
        }

        &:hover {
          color: #fff;

          .ddp-icon-measure-value,
          .ddp-icon-parameter-value {
            background-position-x: -12px;
          }
        }
      }
    }
  }

  .ddp-ui-sub-titlein {
    position: relative;
    padding: 10px 0;
    border-top: 1px solid #4b515b;
    color: #91969e;

    &.ddp-dimension {
      color: #439fe5;
    }

    em.ddp-icon-menumove,
    &.ddp-dimension em.ddp-icon-menumove,
    &.ddp-measure em.ddp-icon-menumove {
      display: inline-block;
      width: 9px;
      height: 5px;
      margin-right: 10px;
      background: url(/assets/bi/images/icon_dropdown.png) no-repeat;
      vertical-align: middle;
    }

    &.ddp-dimension em.ddp-icon-menumove {
      background-position: -10px top;
    }

    &.ddp-measure em.ddp-icon-menumove {
      background-position: -20px top;
    }
  }

  &.ddp-selected .ddp-ui-sub-titlein em.ddp-icon-menumove {
    background-position: left -6px;
  }

  .ddp-ui-sub-titlein.ddp-measure {
    color: #3bddbd;
  }

  &.ddp-selected .ddp-ui-sub-titlein {
    &.ddp-dimension em.ddp-icon-menumove {
      background-position: -10px -6px;
    }

    &.ddp-measure em.ddp-icon-menumove {
      background-position: -20px -6px;
    }
  }

  .ddp-ui-sub-titlein a.ddp-btn-addplus {
    padding: 4px;
    position: absolute;
    top: 50%;
    right: 18px;
    margin-top: -8px;

    &:before {
      display: inline-block;
      content: '';
      width: 9px;
      height: 9px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -20px top;
    }

    &:hover:before {
      background-position: -30px top;
    }
  }

  &.ddp-selected .ddp-wrap-list-source {
    display: block;
  }
}

.ddp-ui-drop-contents .ddp-wrap-reset {
  padding: 20px 0 20px 0;
  background-color: #35393f;

  .ddp-ui-reset {
    text-align: center;
    background-color: #35393f;

    a.ddp-link-reset {
      position: relative;
      left: -18px;
      color: #b7b9c2;
      text-decoration: underline;
      opacity: 0.5;

      em.ddp-icon-reset {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: -1px;
        width: 14px;
        height: 13px;
        background: url(/assets/bi/images/icon_reset.png) no-repeat;
        background-position: -16px -20px;
        vertical-align: middle;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

/**********************************************************************************
	차트 (선반)
**********************************************************************************/

em {
  &.ddp-icon-columns:before,
  &.ddp-icon-rows:before,
  &.ddp-icon-cross:before,
  &.ddp-icon-columns.type-apply:before,
  &.ddp-icon-rows.type-apply:before,
  &.ddp-icon-cross.type-apply:before {
    display: inline-block;
    content: '';
    background: url(/assets/bi/images/icon_shelf.png) no-repeat;
  }

  &.ddp-icon-rows:before {
    width: 9px;
    height: 9px;
  }

  &.ddp-icon-columns:before {
    width: 10px;
    height: 9px;
    background-position: left -10px;
  }

  &.ddp-icon-cross:before {
    width: 11px;
    height: 11px;
    background-position: left -20px;
  }

  &.ddp-icon-rows.type-apply:before {
    background-position: -10px 0;
  }

  &.ddp-icon-columns.type-apply:before {
    background-position: -11px -10px;
  }

  &.ddp-icon-cross.type-apply:before {
    background-position: -12px -20px;
  }
}

.ddp-wrap-shelf {
  overflow: hidden;
  border-bottom: 1px solid #d8d8dd;
}

.ddp-ui-shelf {
  position: relative;
  height: 47px;
  padding-left: 49px;

  &.ddp-disabled {
  }
}

.ddp-wrap-shelf.ddp-mapview .ddp-ui-shelf.ddp-disabled .ddp-ui-drag-slide-in {
  opacity: 0.3;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: '';
  }
}

.ddp-ui-shelf {
  &.ddp-disabled em {
    &.ddp-icon-mapview1,
    &.ddp-icon-mapview2,
    &.ddp-icon-mapview3 {
      opacity: 0.3;
    }
  }

  em {
    &.ddp-icon-columns,
    &.ddp-icon-rows,
    &.ddp-icon-cross {
      position: absolute;
      top: 0;
      left: 0;
      width: 49px;
      height: 47px;
      z-index: 5;
    }

    &.ddp-icon-columns:before,
    &.ddp-icon-rows:before,
    &.ddp-icon-cross:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -5px 0 0 -5px;
    }

    &.ddp-icon-network {
      position: absolute;
      top: 0;
      left: 0;
      width: 49px;
      height: 47px;
      z-index: 10;
      background-color: #fff;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_network.png) no-repeat;
      }

      &.ddp-source:before {
        background-position: -16px top;
      }

      &.ddp-target:before {
        background-position: -16px -16px;
      }

      &.ddp-link:before {
        background-position: -16px -32px;
      }
    }
  }

  .ddp-wrap-example {
    float: left;
    margin-top: 6px;
    vertical-align: top;
  }

  .ddp-box-shelf {
    position: relative;
    float: left;
    margin: 0 2px;
    padding: 5px 10px;
    border: 1px dashed #bbbdc5;
    border-radius: 3px;
    color: #b4b6bb;
    font-size: 12px;
    font-style: italic;
    box-sizing: border-box;
    z-index: 1;
    vertical-align: top;
  }

  .ddp-txt-shelf {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 49px;
    margin-top: -14px;
    padding: 5px 10px;
    color: #b4b6bb;
    font-size: 12px;
  }

  .ddp-box-unavailable {
    float: left;
    margin: 0 5px;
    padding: 6px 10px;
    background-color: #f6f6f7;
    border-radius: 3px;
    color: #b7b9c2;
    font-size: 12px;
    font-style: italic;
  }

  .ddp-box-dimension {
    float: left;
    position: relative;
    min-width: 77px;
    height: 25px;
    margin: 0 5px;
    padding: 0 8px;
    border: 1px dashed #61a3e1;
    border-radius: 3px;
    color: #589edf;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    background-color: #fff;
    box-sizing: border-box;

    em[class*='ddp-icon-dimension'] {
      position: relative;
      top: -2px;
      margin-right: 6px;
      vertical-align: middle;
    }

    .ddp-ui-tooltip-info {
      display: none;
      top: 28px;
      left: 0;
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-tooltip-info .ddp-icon-view-top {
      left: 30px;
      right: inherit;
    }
  }

  .ddp-box-network {
    position: relative;
    float: left;
    padding: 3px 1px 3px 25px;
    margin: 1px 5px 0 5px;
    background-color: #f6f6f7;
    border-radius: 3px;
    vertical-align: top;

    em {
      &.ddp-icon-chart-color {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 7px;
        margin-top: -7px;
        width: 16px;
        height: 14px;
        background: url(/assets/bi/images/icon_network2.png) no-repeat;
      }

      &.ddp-icon-chart-size {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 7px;
        margin-top: -7px;
        width: 16px;
        height: 14px;
        background: url(/assets/bi/images/icon_network2.png) no-repeat;
        margin-top: -8px;
        width: 16px;
        height: 14px;
        background-position: left -15px;
      }

      &[class*='ddp-icon-chart'] .ddp-ui-tooltip-info {
        left: -10px;
        right: initial;
        width: 150px;
        text-align: center;
        white-space: normal;

        &.ddp-down em.ddp-icon-view-top {
          right: initial;
          left: 18px;
        }
      }
    }
  }

  .ddp-box-measure {
    float: left;
    position: relative;
    min-width: 77px;
    height: 25px;
    padding: 0 8px;
    margin: 0 5px;
    border: 1px dashed #6dd199;
    border-radius: 3px;
    color: #5fd7a5;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    background-color: #fff;
    box-sizing: border-box;

    .ddp-ui-tooltip-info {
      display: none;
      top: 28px;
      left: 0;
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-tooltip-info .ddp-icon-view-top {
      left: 30px;
      right: inherit;
    }
  }
}

.ddp-ui-drag-slide {
  &.ddp-slide {
    padding: 0 40px;

    a {
      &.ddp-btn-prev,
      &.ddp-btn-next,
      &.ddp-btn-prev,
      &.ddp-btn-next {
        display: block;
      }
    }
  }

  position: relative;
  top: 5px;
  height: 37px;
  padding: 0 0;

  a {
    &.ddp-btn-prev {
      display: none;
      position: absolute;
      top: 0;
      left: -2px;
      bottom: 0;
      width: 33px;
      border-right: 1px solid #ddd;
      background-color: #fff;
      z-index: 10;
    }

    &.ddp-btn-next {
      display: none;
      position: absolute;
      top: 0;
      right: -3px;
      bottom: 0;
      width: 33px;
      border-left: 1px solid #ddd;
      background-color: #fff;
      z-index: 1;
    }

    &.ddp-btn-prev em.ddp-icon-prev {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 7px;
      margin: -4px 0 0 -2px;
      background: url(/assets/bi/images/icon_slide.png) no-repeat;
    }

    &.ddp-btn-next em.ddp-icon-next {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 7px;
      margin: -4px 0 0 -2px;
      background: url(/assets/bi/images/icon_slide.png) no-repeat;
      background-position: -5px top;
    }

    &.ddp-btn-prev:hover em.ddp-icon-prev {
      background-position: left -7px;
    }

    &.ddp-btn-next:hover em.ddp-icon-next {
      background-position: -5px -7px;
    }
  }
}

.ddp-ui-drag-slide-in {
  position: relative;
  height: 37px;
  margin: 0 -2px;
  white-space: nowrap;
}

.ddp-view-drag-slide {
  display: inline-block;
}

.ddp-ui-drag-slide {
  .ddp-wrap-default {
    display: inline-block;
    min-width: 100%;
    height: 100%;
    white-space: nowrap;
  }

  .ddp-ui-default {
    position: relative;
    float: left;
    padding: 0 5px;
    white-space: nowrap;

    span.ddp-data {
      display: block;
      color: #fff;
      opacity: 0.8;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ddp-data-txt {
      display: block;
      overflow: hidden;
    }

    span.ddp-type-value {
      float: left;
      padding-right: 7px;
      margin-right: 7px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.ddp-wrap-shelf.ddp-mapview {
  .ddp-ui-drag-slide-in {
    overflow: hidden;
  }

  .ddp-ui-drag-slide {
    padding: 0;
    overflow: hidden;

    &.ddp-slide {
      padding-left: 34px;
    }
  }

  .ddp-ui-analysis {
    display: inline-block;
    position: relative;
    padding: 11px 0 12px 0;
    color: #4c515a;
    font-size: 13px;
  }
}

.ddp-ui-shelf.ddp-add-self:before {
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 6px;
  bottom: 3px;
  right: 6px;
  border-radius: 3px;
  border: 1px solid #d0d1d7;
  content: '';
}

.ddp-ui-drag-slide .ddp-slide-control {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  background-color: #fff;
  z-index: 5;

  .ddp-btn-next {
    position: relative;
    float: left;
    height: 100%;
  }

  .ddp-btn-minus {
    position: relative;
    top: 4px;
    float: left;
    width: 27px;
    height: 27px;
    margin-left: 4px;
    background: url(/assets/bi/images/btn_minus_plus2.png) no-repeat;
  }

  .ddp-btn-plus {
    position: relative;
    top: 4px;
    float: left;
    width: 27px;
    height: 27px;
    margin-left: 4px;
    background: url(/assets/bi/images/btn_minus_plus2.png) no-repeat;
    background-position: -28px top;
  }
}

.ddp-wrap-content-graph .ddp-ui-default span.ddp-data {
  color: #fff;
  opacity: 0.8;
}

.ddp-ui-drag-slide {
  .ddp-ui-default a {
    .ddp-data-txt [class*='ddp-icon-'] {
      float: left;
    }

    &.ddp-ui-dimension-wrap {
      display: inline-block;
      position: relative;
      margin: 5px 0 5px 0;
      padding: 7px 9px 4px 9px;
      max-width: 200px;
      height: 27px;
      border-radius: 3px;
      background-color: #5492e4;
      box-shadow: 1px 1px 1px #efeeee;
      box-sizing: border-box;
    }

    &.ddp-ui-measure-wrap {
      display: inline-block;
      position: relative;
      margin: 5px 0 5px 0;
      padding: 7px 9px 4px 9px;
      max-width: 200px;
      height: 27px;
      border-radius: 3px;
      background-color: #55c08b;
      box-shadow: 1px 1px 1px #efeeee;
      box-sizing: border-box;
    }
  }

  .ddp-box-network .ddp-ui-default a {
    &.ddp-ui-dimension-wrap,
    &.ddp-ui-measure-wrap {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .ddp-ui-default {
    .ddp-ui-icons {
      float: right;
      position: relative;
      top: -2px;
      margin-left: 10px;
    }

    em {
      &[class*='ddp-icon-control'] {
        vertical-align: middle;
      }

      &.ddp-icon-control-filter:before,
      &.ddp-icon-control-calculat:before,
      &.ddp-icon-control-parameter:before,
      &.ddp-icon-control-user:before,
      &.ddp-icon-split:before,
      &.ddp-icon-split2:before,
      &.ddp-icon-user:before {
        display: inline-block;
        content: '';
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        opacity: 0.8;
        vertical-align: middle;
      }

      &.ddp-icon-control-calculat:before {
        width: 11px;
        height: 11px;
        background-position: -24px -75px;
      }

      &.ddp-icon-control-parameter:before {
        width: 11px;
        height: 9px;
        background-position: -24px -87px;
      }

      &.ddp-icon-control-user:before {
        width: 9px;
        height: 9px;
        background-position: left -154px;
      }

      &.ddp-icon-split:before {
        width: 9px;
        height: 12px;
        background-position: left -131px;
      }

      &.ddp-icon-split2:before {
        width: 12px;
        height: 9px;
        background-position: left -144px;
      }

      &.ddp-icon-user:before {
        width: 9px;
        height: 9px;
        background-position: left -154px;
      }

      &.ddp-icon-control-filter {
        &:before,
        &:hover:before {
          width: 12px;
          height: 9px;
          background-position: -26px -65px;
        }
      }
    }

    span.ddp-icon-layer {
      display: inline-block;
      position: relative;
      width: 12px;
      height: 17px;
      vertical-align: middle;

      &.ddp-selected {
        background-color: rgba(255, 255, 255, 0.2);
      }

      em {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 9px;
        margin: -4px 0 0 -1px;
        background: url(/assets/bi/images/icon_layer.png) no-repeat;
      }
    }

    a {
      &.ddp-ui-dimension-wrap span.ddp-icon-close,
      &.ddp-ui-measure-wrap span.ddp-icon-close {
        display: inline-block;
        position: relative;
        width: 17px;
        height: 17px;
        vertical-align: middle;
      }

      &.ddp-ui-dimension-wrap span.ddp-icon-close em,
      &.ddp-ui-measure-wrap span.ddp-icon-close em {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 7px;
        margin: -3px 0 0 -3px;
        background: url(/assets/bi/images/btn_sclose.png) no-repeat;
        background-position: left -45px;
      }
    }
  }
}

.ddp-ui-layer-box {
  position: fixed;
  top: 100px;
  left: 0;
  padding: 6px 0;
  min-width: 252px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: -1px 2px 3px 1px #dcdfe1;
  z-index: 130;

  &.ddp-layer-shelf {
    width: 252px;
    z-index: 130;
  }

  &.ddp-layer-more {
    margin-top: 5px;
    left: inherit;
    width: 252px;
  }

  .ddp-ui-layer-title {
    padding: 8px 16px;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ddp-detail-more {
    padding: 5px 0;
    border-bottom: 1px solid #e7e7ea;
  }

  .ddp-dl-more {
    padding: 5px 16px;

    dt {
      color: #90969f;
      font-size: 12px;
      font-weight: 300;
    }

    dd {
      display: block;
      padding-top: 4px;
      color: #4b515b;
      font-size: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ddp-btn-info.ddp-type {
    position: absolute;
    top: 18px;
    right: 13px;
    cursor: pointer;
  }
}

.ddp-wrap-layer {
  border-top: 1px solid #e7e7ea;
  padding: 3px 0;

  .ddp-area-toggle {
    padding: 2px 15px 5px 15px;
  }

  .ddp-list-buttons li[class*='ddp-col-'] {
    min-width: auto;
  }
}

ul.ddp-list-layer-option {
  &.type-option {
    li a {
      border-top: none;
      padding: 6px 14px 6px 35px;
    }

    .ddp-data-result {
      margin-left: 20px;
      max-width: 93px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  li {
    padding: 0 !important;
    position: relative;

    &.ddp-disabled {
      &:hover {
        background: none !important;
      }

      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        cursor: no-drop;
        content: '';
      }

      > a {
        opacity: 0.5;
      }
    }

    a {
      display: block;
      position: relative;
      padding: 10px 16px;
      font-size: 13px;
      color: #4b515b;
      border-top: 1px solid #e7e7ea;
    }

    &:hover,
    &.ddp-hover {
      background: #f6f6f7 !important;
    }

    &:hover {
      .ddp-ui-2depth {
        display: block;
      }

      background-color: #f6f6f7;
    }

    &:first-of-type > a {
      border-top: none;
    }

    a {
      .ddp-label-toggle {
        margin: -10px -16px;
        padding: 10px 16px;
        display: block;
        color: #4b515b;
        font-size: 13px;
        cursor: pointer;
      }

      .ddp-txt-data {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .ddp-label-toggle {
        input[type='checkbox'] {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }

        span.ddp-txt-toggle {
          float: right;

          &:before {
            display: inline-block;
            content: 'Off';
            color: #b7b9c2;
            font-size: 11px;
          }
        }

        input[type='checkbox']:checked + span.ddp-txt-toggle:before {
          content: 'On';
          color: $primary;
        }

        span.ddp-txt-toggle i.ddp-icon-checked {
          display: inline-block;
          width: 11px;
          height: 8px;
          margin-left: 4px;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          background-position: -36px top;
        }

        input[type='checkbox']:checked + span.ddp-txt-toggle i.ddp-icon-checked {
          background-position: -12px top;
        }
      }

      .ddp-btn-info {
        float: right;
      }

      .ddp-data-result {
        float: right;
        position: relative;
        top: 0;
        padding-right: 13px;
        min-height: 14px;
        color: #90969f;
        font-size: 11px;

        span.ddp-txt-result {
          display: inline-block;
          max-width: 90px;
          color: #90969f;
          font-size: 11px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: normal;
          vertical-align: middle;
        }
      }
    }
  }

  &.type-sub-down li {
    a .ddp-data-result em.ddp-icon-view {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 4px;
      height: 8px;
      margin: -4px 0 0 0;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -40px;
      vertical-align: middle;
      transform: rotate(90deg);
      right: 2px;
    }

    &:hover a .ddp-data-result em.ddp-icon-view.type-down {
      transform: rotate(-90deg);
    }

    .ddp-ui-layer-sub {
      position: absolute;
      top: 100%;
      right: -10px;
      left: auto;
      z-index: 1;
    }
  }

  li {
    ul.ddp-list-popup li a em.ddp-icon-view {
      transform: rotate(0);
    }

    a .ddp-data-result {
      em.ddp-btn-option {
        margin-left: 3px;
        margin-right: -3px;
        vertical-align: middle;
      }

      &.ddp-type {
        color: $primary;
      }
    }

    .ddp-ui-layer-sub {
      display: none;
      position: absolute;
      top: -20px;
      left: 100%;
      padding: 10px 0;
      min-width: 162px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-shadow: -1px 2px 3px 1px #dcdfe1;

      .ddp-label {
        color: #b6b9c2;
        font-weight: bold;
      }
    }

    &:hover .ddp-ui-layer-sub,
    &.ddp-hover .ddp-ui-layer-sub {
      display: block;
    }

    &:hover .ddp-ui-layer-sub {
      .ddp-wrap-layer {
        padding: 0;
      }

      .ddp-ui-layer-sub {
        display: none;
      }
    }

    &.ddp-hover .ddp-ui-layer-sub .ddp-ui-layer-sub {
      display: none;
    }

    &:hover .ddp-ui-layer-sub ul li:hover .ddp-ui-layer-sub,
    &.ddp-hover .ddp-ui-layer-sub ul li.ddp-hover .ddp-ui-layer-sub {
      display: block;
    }

    .ddp-ui-layer-sub {
      &.ddp-type {
        min-width: 210px;
      }

      ul.ddp-list-popup {
        padding: 0;

        li a {
          display: block;
          padding: 5px 30px 5px 15px;
          border-top: none;

          .ddp-btn-option {
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -7px;
          }

          .ddp-data-info {
            position: relative;
            top: 1px;
            float: right;
            margin-right: 18px;
            color: #b7b9c2;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.ddp-box-layer {
  width: 208px;
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;

  .ddp-btn-solid {
    float: right;
    padding: 6px 5px;
    min-width: 49%;
    margin-top: 7px;
    color: #fff;
    box-sizing: border-box;
  }

  .ddp-btn-line {
    float: left;
    padding: 6px 5px;
    min-width: 49%;
    margin-top: 7px;
    color: #90969f;
    border: 1px solid #d0d1d7;
    box-sizing: border-box;
  }
}

.ddp-ui-layer-sub .ddp-ui-layer-title {
  padding: 5px 15px;
  margin-top: 10px;
  color: #b7b9c2;
  font-weight: bold;

  &:first-of-type {
    margin-top: 0;
  }
}

/**********************************************************************************
	차트 (기본차트선택)
**********************************************************************************/

.ddp-ui-chart-basic {
  text-align: center;

  .ddp-ui-info {
    display: block;
    padding: 100px 0 50px 0;
    font-size: 22px;
    text-align: center;
    color: #4b515b;
  }
}

ul.ddp-list-chart-type {
  display: inline-block;
  width: 494px;

  li {
    float: left;
    width: 20%;
    padding-bottom: 19px;

    a {
      display: flex;
      height: 92px;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        width: 78px;
        height: 70px;
        justify-content: center;
        align-items: center;
      }

      .ddp-txt-name {
        display: block;
        padding-top: 7px;
        color: #4b515b;
        font-size: 13px;
        white-space: nowrap;
      }

      &:hover em[class*='ddp-img-graph-']:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(75, 81, 91, 0.1);
      }
    }

    &.ddp-selected a em[class*='ddp-img-graph-'] {
      opacity: 1;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(102, 110, 178, 0.3);
        border: 2px solid $primary;
      }
    }
  }
}

em[class*='ddp-img-graph-'] {
  display: inline-block;
  position: relative;
  width: 78px;
  height: 70px;
  background: url(/assets/bi/images/img_graph.png);
  opacity: 0.8;
}

ul.ddp-list-chart-type li.ddp-recommend a em[class*='ddp-img-graph-'] {
  opacity: 1;
}

em {
  &.ddp-img-graph-grid {
    background-position: -79px 0;
  }

  &.ddp-s-graph-grid {
    background-position: -79px 0 !important;
  }

  &.ddp-img-graph-line {
    background-position: -158px 0;
  }

  &.ddp-img-graph-scatter {
    background-position: -237px 0;
  }

  &.ddp-img-graph-heatmap {
    width: 79px;
    background-position: -316px 0;
  }

  &.ddp-img-graph-pie {
    background-position: -396px 0;
  }

  &.ddp-img-graph-map {
    background-position: -475px 0;
  }

  &.ddp-img-graph-cont {
    background-position: -554px 0;
  }

  &.ddp-img-graph-label {
    width: 79px;
    background-position: -633px 0;
  }

  &.ddp-s-graph-label {
    width: 79px !important;
    background-position: -583px 0 !important;
  }

  &.ddp-img-graph-boxplot {
    background-position: -713px 0;
  }

  &.ddp-img-graph-waterfall {
    background-position: -792px 0;
  }

  &.ddp-img-graph-wordcloud {
    background-position: -871px 0;
  }

  &.ddp-img-graph-combine {
    width: 79px;
    background-position: -950px 0;
  }

  &.ddp-s-graph-combine {
    width: 79px !important;
    background-position: -875px 0 !important;
  }

  &.ddp-img-graph-treemap {
    background-position: -1030px 0;
  }

  &.ddp-img-graph-radar {
    background-position: -1109px 0;
  }

  &.ddp-img-graph-graph {
    background-position: -1188px 0;
  }

  &.ddp-img-graph-graphv2 {
    background-position: -1188px 0;
  }

  &.ddp-img-graph-sankey {
    background-position: -1267px 0;
  }

  &.ddp-img-graph-gauge {
    background-position: -1346px 0;
  }
}

ul.ddp-list-chart-type li {
  &.ddp-recommend a em.ddp-img-graph-bar,
  &.ddp-selected a em.ddp-img-graph-bar {
    background-position: left -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-grid,
  &.ddp-selected a em.ddp-img-graph-grid {
    background-position: -79px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-line,
  &.ddp-selected a em.ddp-img-graph-line {
    background-position: -158px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-scatter,
  &.ddp-selected a em.ddp-img-graph-scatter {
    background-position: -237px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-heatmap,
  &.ddp-selected a em.ddp-img-graph-heatmap {
    background-position: -316px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-pie,
  &.ddp-selected a em.ddp-img-graph-pie {
    background-position: -396px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-map,
  &.ddp-selected a em.ddp-img-graph-map {
    background-position: -475px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-cont,
  &.ddp-selected a em.ddp-img-graph-cont {
    background-position: -554px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-label,
  &.ddp-selected a em.ddp-img-graph-label {
    background-position: -633px -71px;
  }

  &.ddp-recommend a em.ddp-s-graph-label,
  &.ddp-selected a em.ddp-s-graph-label {
    background-position: -633px -71px !important;
  }

  &.ddp-recommend a em.ddp-img-graph-boxplot,
  &.ddp-selected a em.ddp-img-graph-boxplot {
    background-position: -713px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-waterfall,
  &.ddp-selected a em.ddp-img-graph-waterfall {
    background-position: -792px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-wordcloud,
  &.ddp-selected a em.ddp-img-graph-wordcloud {
    background-position: -871px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-combine,
  &.ddp-selected a em.ddp-img-graph-combine {
    background-position: -950px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-treemap,
  &.ddp-selected a em.ddp-img-graph-treemap {
    background-position: -1030px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-radar,
  &.ddp-selected a em.ddp-img-graph-radar {
    background-position: -1109px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-graph,
  &.ddp-selected a em.ddp-img-graph-graph {
    background-position: -1188px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-graphv2,
  &.ddp-selected a em.ddp-img-graph-graphv2 {
    background-position: -1188px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-sankey,
  &.ddp-selected a em.ddp-img-graph-sankey {
    background-position: -1267px -71px;
  }

  &.ddp-recommend a em.ddp-img-graph-gauge,
  &.ddp-selected a em.ddp-img-graph-gauge {
    background-position: -1346px -71px;
  }
}

em[class*='ddp-s-graph-'] {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 62px;
  background: url(/assets/bi/images/img_graph_s.png);
  opacity: 0.8;
}

ul.ddp-list-chart-img li {
  a {
    display: flex;
    position: relative;
    width: 72px;
    height: 62px;
    justify-content: center;
    align-items: center;

    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -36px;
      content: '';
      width: 72px;
      height: 62px;
      background-color: transparent;
    }
  }

  &.ddp-recommend a em[class*='ddp-s-graph-'],
  &.ddp-selected a em[class*='ddp-s-graph-'] {
    opacity: 1;
  }
}

em {
  &.ddp-s-graph-bar {
  }

  &.ddp-s-graph-txt {
    background-position: -73px 0;
  }

  &.ddp-s-graph-line {
    background-position: -146px 0;
  }

  &.ddp-s-graph-scatter {
    background-position: -219px 0;
  }

  &.ddp-s-graph-heatmap {
    background-position: -292px 0;
  }

  &.ddp-s-graph-pie {
    background-position: -365px 0;
  }

  &.ddp-s-graph-map {
    background-position: -438px 0;
  }

  &.ddp-s-graph-cont {
    background-position: -511px 0;
  }

  &.ddp-s-graph-kpi {
    background-position: -584px 0;
  }

  &.ddp-s-graph-boxplot {
    background-position: -657px 0;
  }

  &.ddp-s-graph-waterfall {
    background-position: -730px 0;
  }

  &.ddp-s-graph-wordcloud {
    background-position: -803px 0;
  }

  &.ddp-s-graph-combo {
    background-position: -876px 0;
  }

  &.ddp-s-graph-treemap {
    background-position: -949px 0;
  }

  &.ddp-s-graph-radar {
    background-position: -1022px 0;
  }

  &.ddp-s-graph-network {
    background-position: -1095px 0;
  }

  &.ddp-s-graph-sankey {
    background-position: -1168px 0;
  }

  &.ddp-s-graph-graph {
    background-position: -1095px 0;
  }

  &.ddp-s-graph-gauge {
    background-position: -1241px 0;
  }
}

ul.ddp-list-chart-img li {
  &.ddp-recommend a em.ddp-s-graph-bar,
  &.ddp-selected a em.ddp-s-graph-bar {
    background-position: 0 -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-txt,
  &.ddp-selected a em.ddp-s-graph-txt {
    background-position: -73px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-line,
  &.ddp-selected a em.ddp-s-graph-line {
    background-position: -146px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-scatter,
  &.ddp-selected a em.ddp-s-graph-scatter {
    background-position: -219px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-heatmap,
  &.ddp-selected a em.ddp-s-graph-heatmap {
    background-position: -292px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-pie,
  &.ddp-selected a em.ddp-s-graph-pie {
    background-position: -365px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-map,
  &.ddp-selected a em.ddp-s-graph-map {
    background-position: -438px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-cont,
  &.ddp-selected a em.ddp-s-graph-cont {
    background-position: -511px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-kpi,
  &.ddp-selected a em.ddp-s-graph-kpi {
    background-position: -584px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-boxplot,
  &.ddp-selected a em.ddp-s-graph-boxplot {
    background-position: -657px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-waterfall,
  &.ddp-selected a em.ddp-s-graph-waterfall {
    background-position: -730px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-wordcloud,
  &.ddp-selected a em.ddp-s-graph-wordcloud {
    background-position: -803px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-combo,
  &.ddp-selected a em.ddp-s-graph-combo {
    background-position: -876px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-treemap,
  &.ddp-selected a em.ddp-s-graph-treemap {
    background-position: -949px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-radar,
  &.ddp-selected a em.ddp-s-graph-radar {
    background-position: -1022px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-network,
  &.ddp-selected a em.ddp-s-graph-network {
    background-position: -1095px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-graph,
  &.ddp-selected a em.ddp-s-graph-graph {
    background-position: -1095px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-sankey,
  &.ddp-selected a em.ddp-s-graph-sankey {
    background-position: -1168px -63px;
  }

  &.ddp-recommend a em.ddp-s-graph-gauge,
  &.ddp-selected a em.ddp-s-graph-gauge {
    background-position: -1241px -63px;
  }
}

.ddp-ui-chart-list {
  position: relative;
  padding: 22px 0 133px 0;
  text-align: center;

  ul.ddp-list-chart-img {
    display: inline-block;
    width: 225px;
    overflow: hidden;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      width: 33.3333%;
      padding-bottom: 3px;
      text-align: center;

      a:hover em[class*='ddp-s-graph']:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(75, 81, 91, 0.1);
      }

      &.ddp-selected a em[class*='ddp-s-graph']:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(102, 110, 178, 0.3);
        border: 2px solid $primary;
      }
    }
  }

  .ddp-ui-chart-info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 22px;
    margin-bottom: 20px;
    text-align: left;

    .ddp-ui-name {
      display: block;
      padding: 15px 0 10px 0;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
    }

    .ddp-data-det {
      height: 72px;
      line-height: 24px;
      color: #b7b9c2;
      font-size: 12px;
    }
  }
}

/**********************************************************************************
	차트 (레이아웃)
**********************************************************************************/

.ddp-wrap-chart-layout {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}

.ddp-ui-cahrt-layout {
  position: relative;
  height: 100%;
}

.ddp-wrap-chart-layout [class*='ddp-box-layout'] {
  margin: 0 -30px;
}

.ddp-box-layout3 {
  .ddp-ui-layout-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 25%;
    border-right: 1px solid #e7e7ea;
    box-sizing: border-box;
  }

  .ddp-ui-layout-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    border-top: 1px solid #e7e7ea;
    box-sizing: border-box;
  }

  .ddp-ui-layout-center {
    position: absolute;
    top: 0;
    left: 25%;
    bottom: 30%;
    right: 0;
  }

  .ddp-ui-layout-bottom .ddp-ui-condition {
    margin-left: 25%;
  }
}

.ddp-box-layout2 {
  .ddp-ui-layout-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    border-top: 1px solid #e7e7ea;
    box-sizing: border-box;
  }

  .ddp-ui-layout-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70%;
  }
}

.ddp-box-layout1 .ddp-ui-layout-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ddp-ui-cahrt-layout .ddp-ui-condition {
  padding: 18px 0 0 20px;

  span {
    &.ddp-txt-measure {
      color: #55c08b;
      font-size: 13px;
      font-style: italic;
    }

    &.ddp-txt-dimension {
      color: #5492e4;
      font-size: 13px;
      font-style: italic;
    }
  }

  .ddp-icon-columns2,
  .ddp-icon-rows2,
  .ddp-icon-cross2,
  .ddp-icon-source,
  .ddp-icon-target,
  .ddp-icon-link {
    display: inline-block;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_shelf.png) no-repeat;
    opacity: 0.15;
    vertical-align: middle;
  }

  .ddp-icon-columns2 {
    width: 28px;
    height: 26px;
    background-position: left -32px;
  }

  .ddp-icon-rows2 {
    width: 28px;
    height: 26px;
    background-position: left -90px;
  }

  .ddp-icon-cross2 {
    width: 31px;
    height: 30px;
    background-position: left -59px;
  }

  .ddp-icon-source {
    width: 31px;
    height: 31px;
    background-position: left -117px;
    opacity: 1;
  }

  .ddp-icon-target {
    width: 31px;
    height: 31px;
    background-position: left -150px;
    opacity: 1;
  }

  .ddp-icon-link {
    width: 31px;
    height: 31px;
    background-position: left -181px;
    opacity: 1;
  }
}

em[class*='ddp-bg-chart-'] {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.2;
}

.ddp-bg-chart-bar {
  background: url(/assets/bi/images/img_chart/img_bar.png) no-repeat;
  width: 421px;
  height: 386px;
  margin: -193px 0 0 -210px;
}

.ddp-bg-chart-boxplot {
  background: url(/assets/bi/images/img_chart/img_boxplot.png) no-repeat;
  width: 329px;
  height: 303px;
  margin: -152px 0 0 -165px;
}

.ddp-bg-chart-combo {
  background: url(/assets/bi/images/img_chart/img_combo.png) no-repeat;
  width: 338px;
  height: 319px;
  margin: -159px 0 0 -169px;
}

.ddp-bg-chart-cont {
  background: url(/assets/bi/images/img_chart/img_cont.png) no-repeat;
  width: 376px;
  height: 257px;
  margin: -129px 0 0 -189px;
}

.ddp-bg-chart-heatmap {
  background: url(/assets/bi/images/img_chart/img_heatmap.png) no-repeat;
  width: 292px;
  height: 293px;
  margin: -147px 0 0 -146px;
}

.ddp-bg-chart-kpi {
  background: url(/assets/bi/images/img_chart/img_kpi.png) no-repeat;
  width: 306px;
  height: 307px;
  margin: -154px 0 0 -153px;
}

.ddp-bg-chart-line {
  background: url(/assets/bi/images/img_chart/img_line.png) no-repeat;
  width: 358px;
  height: 246px;
  margin: -123px 0 0 -179px;
}

.ddp-bg-chart-map {
  background: url(/assets/bi/images/img_chart/img_map.png) no-repeat;
  width: 308px;
  height: 308px;
  margin: -154px 0 0 -154px;
}

.ddp-bg-chart-network {
  background: url(/assets/bi/images/img_chart/img_network.png) no-repeat;
  width: 320px;
  height: 329px;
  margin: -165px 0 0 -160px;
}

.ddp-bg-chart-pie {
  background: url(/assets/bi/images/img_chart/img_pie.png) no-repeat;
  width: 326px;
  height: 322px;
  margin: -166px 0 0 -168px;
}

.ddp-bg-chart-randar {
  background: url(/assets/bi/images/img_chart/img_randar.png) no-repeat;
  width: 328px;
  height: 296px;
  margin: -148px 0 0 -164px;
}

.ddp-bg-chart-scatter {
  background: url(/assets/bi/images/img_chart/img_scatter.png) no-repeat;
  width: 368px;
  height: 318px;
  margin: -159px 0 0 -184px;
}

.ddp-bg-chart-table {
  background: url(/assets/bi/images/img_chart/img_table.png) no-repeat;
  width: 309px;
  height: 311px;
  margin: -155px 0 0 -155px;
}

.ddp-bg-chart-treemap {
  background: url(/assets/bi/images/img_chart/img_treemap.png) no-repeat;
  width: 301px;
  height: 273px;
  margin: -137px 0 0 -150px;
}

.ddp-bg-chart-waterfall {
  background: url(/assets/bi/images/img_chart/img_waterfall.png) no-repeat;
  width: 319px;
  height: 275px;
  margin: -138px 0 0 -160px;
}

.ddp-bg-chart-wordcloud {
  background: url(/assets/bi/images/img_chart/img_wordcloud.png) no-repeat;
  width: 395px;
  height: 250px;
  margin: -125px 0 0 -198px;
}

.ddp-bg-chart-sankey {
  background: url(/assets/bi/images/img_chart/img_sankey.png) no-repeat;
  width: 335px;
  height: 307px;
  margin: -153px 0 0 -168px;
}

.ddp-bg-chart-gauge {
  background: url(/assets/bi/images/img_chart/img_gauge.png) no-repeat;
  width: 373px;
  height: 294px;
  margin: -147px 0 0 -187px;
}

/**********************************************************************************
	차트 (그래프 메인)
**********************************************************************************/

.page-chart .ddp-ui-chart-main .ddp-ui-chart-area .ddp-top-control {
  position: absolute;
  top: 0;
  right: 0;

  .ddp-box-btn2 {
    float: left;
    cursor: pointer;
  }

  .ddp-icon-widget-info2 {
    float: left;
    position: relative;
    width: 25px;
    height: 25px;
    font-size: 0;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #cdd8f4;
    background-color: #f0f3fc;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -8px;
      width: 15px;
      height: 14px;
      background: url(/assets/bi/images/icon_widget.png) no-repeat;
      background-position: -16px -244px;
      content: '';
    }
  }

  .ddp-box-btn2:hover .ddp-box-info.ddp-limitation {
    display: block;

    .ddp-total {
      display: block;
      padding-bottom: 4px;

      strong {
        display: block;
      }
    }
  }

  .ddp-box-info.ddp-limitation {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 6px 10px;
    width: 240px;
    border-radius: 3px;
    background-color: #7182ba;
    color: #fff;
    box-sizing: border-box;
    z-index: 1000;
  }
}

.ddp-ui-chart-main {
  .ddp-ui-top {
    display: none;
    height: 50px;
    position: relative;
    z-index: 60;

    .ddp-ui-name {
      position: relative;
      padding-right: 10px;
      padding-left: 38px;
      margin-top: 16px;
      color: #4b515b;
      font-size: 14px;
      box-sizing: border-box;
      overflow: hidden;

      .ddp-icon-tool-title {
        position: absolute;
        top: 6px;
        left: 20px;
      }

      .ddp-data-name {
        display: inline-block;
        width: 100%;
        height: 22px;
        position: relative;
        padding: 3px 3px 3px 3px;
        color: #4b515b;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        box-sizing: border-box;

        &:hover {
          padding-right: 22px;
          background-color: #f1f1f2;
        }

        em.ddp-icon-edit2 {
          display: none;
          position: absolute;
          top: 6px;
          right: 8px;
        }

        &:hover em.ddp-icon-edit2 {
          display: block;
        }

        em.ddp-icon-edit2:hover {
          background-position: left top;
        }
      }

      .ddp-input-name {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-right: 23px;
        box-sizing: border-box;
      }

      &.ddp-selected .ddp-input-name {
        display: block;
      }

      .ddp-input-name {
        input {
          display: inline-block;
          padding: 3px 3px 3px 3px;
          width: 100%;
          color: #4b515b;
          font-size: 14px;
          border: none;
          background-color: #f1f1f2;
          box-sizing: border-box;
        }

        .ddp-btn-check {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .ddp-ui-control {
    float: right;
    padding: 14px 10px 0 0;

    .ddp-ui-part {
      float: left;
      position: relative;
      padding: 0 10px;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        height: 12px;
        border-left: 1px solid #dbdce0;
      }

      &:first-of-type:before {
        display: none;
      }

      .ddp-box-btn-hover {
        float: Left;
        margin-left: 1px;
      }

      em {
        &.ddp-icon-widget-gridsave,
        &.ddp-icon-widget-save {
          position: relative;
          width: 21px;
          height: 19px;
        }

        &.ddp-icon-widget-gridsave:before,
        &.ddp-icon-widget-save:before {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -7px;
        }
      }
    }

    .ddp-ui-btnview,
    .ddp-box-btn2 {
      float: left;
      position: relative;
      top: 3px;
      margin-left: 5px;
    }
  }
}

.ddp-box-btnselect {
  float: left;

  a.ddp-btn-selected {
    display: inline-block;
    padding: 5px 10px 6px 10px;
    margin: 0 4px;
    text-align: center;
    font-size: 12px;
    color: #4b515b;
    border-radius: 2px;
    border: 1px solid #f7f7f8;
    background-color: #f7f7f8;

    &:hover {
      background-color: #dbdce1;
    }

    &.ddp-selected {
      border: 1px solid #4b515b;
      background-color: #fff;
      color: #4c515a;
    }

    em {
      &.ddp-icon-tool-legend {
        background-position: left -14px;
        vertical-align: middle;
      }

      &.ddp-icon-tool-minimap {
        background-position: left -26px;
        vertical-align: middle;
      }
    }
  }

  .ddp-list-buttons {
    display: inline-block;
    vertical-align: middle;
  }
}

.ddp-box-btn-hover {
  flex-flow: column !important;
  border: 1px solid transparent;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;

  > a {
    display: none;
  }

  &:hover {
    > a {
      display: flex;
    }

    > .ddp-btn-tool-wrapper {
      display: flex;
      flex-flow: column;
      position: absolute;
      top: 70px;
    }

    border: 1px solid transparent !important;
  }

  > {
    a {
      border: 1px solid $primary;
      border-radius: 5px;
      padding: 0px 5px 0px 5px;
      margin-top: 2px;
    }

    .ddp-btn-tool-wrapper {
      display: none;
    }
  }

  > {
    .ddp-btn-tool-wrapper > a {
      border: 1px solid $primary;
      border-radius: 7px;
      padding: 5px;
      margin-bottom: 2px;
      background: white;

      > div {
        background: white;

        > p {
          padding-left: 5px;
        }
      }
    }

    a > {
      svg,
      div > svg {
        width: 13px;
      }
    }
  }

  a {
    &.ddp-btn-tool {
      display: none;
      position: relative;
      width: 28px;
      height: 28px;
    }

    &.ddp-btn-tool-cursor {
      display: flex;
      position: relative;
      width: 55px;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        > div {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          padding-top: 5px;

          > div > p {
            padding: 0;
            padding-bottom: 15px;
            margin: 0;

            &:before {
              position: absolute;
              bottom: 0px;
              left: 17px;
              font-size: 9px;
              content: '▼';
            }
          }
        }
      }
    }

    &.ddp-btn-tool {
      &.ddp-selected {
        background-color: #f2f1f8;
      }

      &:first-of-type {
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &:hover a.ddp-btn-tool {
    display: flex;
  }
}

.ddp-btn-tool-hover > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  white-space: nowrap;

  > p {
    margin: 0;
    padding: 0;
  }
}

.ddp-box-btn-hover a.ddp-btn-tool {
  &:hover {
    background-color: #eeeeee;
  }

  em {
    &[class*='ddp-icon-tool-'] {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.ddp-icon-tool-pointer {
      margin: -6px 0 0 -6px;
    }

    &.ddp-icon-tool-elliptical {
      margin: -7px 0 0 -7px;
    }

    &.ddp-icon-tool-freeshape {
      margin: -6px 0 0 -7px;
    }

    &.ddp-icon-tool-rectangular {
      margin: -7px 0 0 -7px;
    }

    &.ddp-icon-tool-zoomin {
      margin: -7px 0 0 -6px;
    }

    &.ddp-icon-tool-zoomout {
      margin: -7px 0 0 -7px;
    }

    &.ddp-icon-tool-revert {
      margin: -7px 0 0 -5px;
    }
  }
}

.ddp-ui-chart-main .ddp-ui-chart-area {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 20px 40px 20px;

  &.ddp-sankey {
    padding: 20px;

    .ddp-txt-detail {
      position: absolute;
      top: 0;
      left: 20px;
      font-size: 12px;
    }

    .ddp-chart-in {
      width: 100%;
      height: 100%;
    }
  }
}

.ddp-wrap-graph-kpi {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 12px;
  border: 1px solid #f2f2f4;
  vertical-align: middle;
}

.ddp-view-data-kpi {
  display: table-cell;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  border-collapse: collapse;

  &[class*='ddp-color'] {
    border-left: 1px solid #f2f2f4;

    &:first-of-type {
      border-left: none;
    }
  }
}

.ddp-wrap-graph-kpi {
  .ddp-wrap-data-kpi {
    display: block;
    width: 100%;
    text-align: center;

    img[class*='ddp-icon-img'] {
      width: 50%;
    }

    span.ddp-txt-title {
      display: block;
      width: 100%;
      color: #4b515b;
      font-size: 14px;
      opacity: 0.7;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }

    .ddp-data-result {
      display: block;
      width: 100%;
      padding: 5px 0 5px 0;
      font-size: 32px;
      color: #4b515b;
      text-align: center;
      line-height: 1em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      em {
        font-size: 28px;
      }
    }

    .ddp-data-result2 {
      display: block;
      padding-top: 10px;
    }

    .ddp-data-result.ddp-up {
      color: #08b496;
    }

    .ddp-data-result2.ddp-up {
      color: #4c92e1;
    }

    .ddp-data-result3 {
      display: none;
      font-size: 15px;
    }

    [class*='ddp-data-result'] {
      &.ddp-down,
      &.ddp-plus {
        color: #dc685e;
      }

      &.ddp-minus {
        color: #08b496;
      }

      &.ddp-down:before {
        display: inline-block;
        margin-right: 5px;
        content: '▼';
      }

      &.ddp-up:before {
        display: inline-block;
        margin-right: 5px;
        content: '▼';
        transform: rotate(180deg);
      }

      &.ddp-plus:before {
        display: inline-block;
        margin-right: 5px;
        content: '+';
      }

      &.ddp-minus:before {
        display: inline-block;
        margin-right: 5px;
        content: '-';
      }
    }

    .ddp-img-kpi {
      display: inline-block;
      margin-bottom: 7px;
      padding: 10px 5px;
      width: 63px;
      border-radius: 50%;
      background-color: #fff;
    }

    .ddp-data-calen {
      display: block;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      text-align: center;
      color: #90969f;
      font-size: 12px;
    }

    .ddp-data-value {
      display: block;
      font-size: 20px;
      color: #4b515b;
    }

    .ddp-data-result2 {
      color: #4b515b;
      font-size: 15px;
    }
  }

  &.ddp-size-m .ddp-wrap-data-kpi {
    .ddp-data-result {
      font-size: 28px;

      &:before {
        font-size: 28px;
      }

      em {
        font-size: 23px;
      }
    }

    span.ddp-txt-title,
    .ddp-data-result2 {
      font-size: 13px;
    }

    .ddp-data-calen {
      font-size: 10px;
    }

    .ddp-data-value {
      font-size: 16px;
    }
  }

  &.ddp-size-s .ddp-wrap-data-kpi {
    .ddp-data-result {
      font-size: 24px;

      &:before {
        font-size: 19px;
      }

      em {
        font-size: 20px;
      }
    }

    span.ddp-txt-title {
      font-size: 10px;
    }

    .ddp-data-result2 {
      font-size: 13px;
    }

    .ddp-data-calen {
      font-size: 9px;
    }

    .ddp-data-value {
      font-size: 14px;
    }
  }

  &.ddp-type {
    .ddp-view-data-kpi {
      display: table-row;
      width: 100%;
      height: auto;
      box-sizing: border-box;

      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 100%;
        margin-right: 0;
        vertical-align: middle;
      }
    }

    .ddp-wrap-data-kpi {
      display: table-cell;
      width: 100%;
      text-align: center;
      vertical-align: middle;
    }

    .ddp-view-data-kpi:last-of-type .ddp-wrap-data-kpi {
      border-bottom: none;
    }

    .ddp-wrap-data-kpi {
      span {
        &.ddp-txt-title {
          display: inline-block;
          width: inherit;
          vertical-align: top;
        }

        &.ddp-data-result {
          display: inline-block;
          width: inherit;

          em {
            font-size: 28px;
          }
        }
      }

      .ddp-summary {
        text-align: left;

        * {
          white-space: normal;
          text-overflow: inherit;
        }
      }
    }

    .ddp-area-data-kpi {
      display: inline-block;
      position: relative;
      padding-right: 78px;

      &.ddp-img-none {
        padding-right: 0;

        .ddp-img-kpi {
          display: none;
        }
      }

      .ddp-img-kpi {
        position: absolute;
        top: 50%;
        right: 0;
        margin: -36px 0 0 0;
      }

      .ddp-data-calen {
        position: relative;
        left: 0;
        bottom: 0;
        margin-top: 10px;
        text-align: left;
      }

      .ddp-data-result2 {
        display: none;
      }

      .ddp-data-result3 {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
}

.ddp-view-data-kpi.ddp-solid {
  background-color: #fff;
}

.ddp-wrap-graph-kpi .ddp-view-data-kpi {
  &.ddp-solid[class*='ddp-color'] * {
    color: #fff;
  }

  &.ddp-color1.ddp-solid {
    background-color: #58b5da;
  }

  &.ddp-color2.ddp-solid {
    background-color: #3f72c1;
  }

  &.ddp-color3.ddp-solid {
    background-color: #8d6dd2;
  }

  &.ddp-color4.ddp-solid {
    background-color: #fc79ac;
  }

  &.ddp-color5.ddp-solid {
    background-color: #fb7661;
  }

  &.ddp-color6.ddp-solid {
    background-color: #fbb700;
  }

  &.ddp-color7.ddp-solid {
    background-color: #97cb63;
  }

  &.ddp-color8.ddp-solid {
    background-color: #54b2ae;
  }
}

.ddp-view-data-kpi {
  border-top: 3px solid #fff;

  &.ddp-color1 {
    border-top: 3px solid #58b5da;
  }

  &.ddp-color2 {
    border-top: 3px solid #3f72c1;
  }

  &.ddp-color3 {
    border-top: 3px solid #8d6dd2;
  }

  &.ddp-color4 {
    border-top: 3px solid #fc79ac;
  }

  &.ddp-color5 {
    border-top: 3px solid #fb7661;
  }

  &.ddp-color6 {
    border-top: 3px solid #fbb700;
  }

  &.ddp-color7 {
    border-top: 3px solid #97cb63;
  }

  &.ddp-color8 {
    border-top: 3px solid #54b2ae;
  }
}

.ddp-wrap-graph-kpi.ddp-type .ddp-view-data-kpi {
  .ddp-wrap-data-kpi {
    border-top: 3px solid #fff;
    border-bottom: 1px solid #ddd;
  }

  &[class*='ddp-color'] .ddp-wrap-data-kpi {
    border-bottom: none;
  }

  &.ddp-color1 .ddp-wrap-data-kpi {
    border-top: 3px solid #58b5da;
  }

  &.ddp-color2 .ddp-wrap-data-kpi {
    border-top: 3px solid #3f72c1;
  }

  &.ddp-color3 .ddp-wrap-data-kpi {
    border-top: 3px solid #8d6dd2;
  }

  &.ddp-color4 .ddp-wrap-data-kpi {
    border-top: 3px solid #fc79ac;
  }

  &.ddp-color5 .ddp-wrap-data-kpi {
    border-top: 3px solid #fb7661;
  }

  &.ddp-color6 .ddp-wrap-data-kpi {
    border-top: 3px solid #fbb700;
  }

  &.ddp-color7 .ddp-wrap-data-kpi {
    border-top: 3px solid #97cb63;
  }

  &.ddp-color8 .ddp-wrap-data-kpi {
    border-top: 3px solid #54b2ae;
  }
}

.ddp-view-data-kpi {
  &.ddp-color1 .st0 {
    stroke: #58b5da;
    fill: #58b5da;
  }

  &.ddp-color2 .st0 {
    stroke: #3f72c1;
    fill: #3f72c1;
  }

  &.ddp-color3 .st0 {
    stroke: #8d6dd2;
    fill: #8d6dd2;
  }

  &.ddp-color4 .st0 {
    stroke: #fc79ac;
    fill: #fc79ac;
  }

  &.ddp-color5 .st0 {
    stroke: #fb7661;
    fill: #fb7661;
  }

  &.ddp-color6 .st0 {
    stroke: #fbb700;
    fill: #fbb700;
  }

  &.ddp-color7 .st0 {
    stroke: #97cb63;
    fill: #97cb63;
  }

  &.ddp-color8 .st0 {
    stroke: #54b2ae;
    fill: #54b2ae;
  }
}

/*.st0 {stroke-width:2;stroke-miterlimit:10; stroke:#4b515b; fill: #4b515b;}*/

/**********************************************************************************
	차트 (side)
**********************************************************************************/

.ddp-ui-chart-side {
  .ddp-ui-side-title {
    position: relative;
    padding: 16px 15px;
    font-size: 16px;
    color: #565d65;
    font-weight: bold;
    border-bottom: 1px solid #e5e6e8;
    z-index: 3;

    .ddp-icon-add {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -6px;

      &:hover {
        background-position: left top;
        z-index: 65;

        .ddp-ui-tooltip-info {
          display: block;
        }
      }

      .ddp-ui-tooltip-info {
        left: inherit;
        top: 100%;
        right: -10px;
      }
    }

    span.ddp-data-num {
      margin-left: 3px;
      color: #b7b9c2;
      font-size: 15px;

      em {
        color: #565d65;
      }
    }
  }

  .ddp-ui-chart-side {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
  }

  .ddp-link-add {
    padding: 18px 0;
    color: #b7b9c2;
    text-decoration: underline;
    text-align: center;

    a {
      color: #b7b9c2;

      em.ddp-icon-add-r {
        position: relative;
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 4px;
        border-radius: 50%;
        border: 1px solid #bebfc8;
        vertical-align: middle;

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 7px;
          margin: -4px 0 0 -3px;
          background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
          background-position: -8px -12px;
        }
      }
    }
  }

  .ddp-filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }

  .ddp-link-add {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.ddp-box-down2 {
  margin: 10px 0 0 15px;
  padding: 10px 15px 10px 0;
  border-top: 1px solid #e5e6e8;
}

.ddp-box-down {
  position: relative;
  padding: 6px 15px 10px 15px;
  border-bottom: 1px solid #e5e6e8;

  .ddp-data-option {
    padding-bottom: 13px;
  }

  .ddp-txt-title {
    padding: 7px 0 7px 0;
    color: #90969f;
    font-size: 13px;

    span.ddp-data-column-name {
      float: right;
      font-size: 12px;
      color: #4a515c;
    }
  }

  .ddp-ui-down-title {
    position: relative;
    margin: 0 0 0 0;
    color: #90969f;
    font-size: 13px;
  }

  .ddp-label-checkbox span.ddp-txt-checkbox {
    font-size: 12px;
  }

  .ddp-wrap-option-multy .ddp-label-checkbox.ddp-size span.ddp-txt-checkbox,
  .ddp-part-check-b .ddp-label-checkbox span.ddp-txt-checkbox {
    font-size: 13px;
  }

  .ddp-part-radio {
    padding: 7px 0;
  }

  .ddp-ui-point {
    position: relative;
    padding-right: 30px;

    .ddp-btn-column-add {
      position: absolute;
      top: 5px;
      right: 0;
      bottom: 5px;
      width: 26px;
      border-radius: 2px;
      background-color: #90969f;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -7px 0 0 -7px;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_column_add.png) no-repeat;
        content: '';
      }
    }

    .ddp-point-local {
      padding: 5px 0 5px 0;

      .ddp-label-point {
        font-size: 13px;
        color: #4b515b;
      }

      .ddp-box-data {
        display: Inline-block;
        position: relative;
        padding: 5px 6px 6px 34px;
        width: 100%;
        border-radius: 2px;
        background-color: #eeeef1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: Hidden;
        box-sizing: border-box;

        em {
          &[class*='ddp-icon-dimension-'],
          &[class*='ddp-icon-measure-'] {
            position: absolute;
            top: 8px;
            left: 12px;
          }
        }
      }

      .ddp-label-point {
        float: left;
        padding: 5px 0;
      }
    }
  }
}

.ddp-ui-down-title {
  .ddp-ui-buttons {
    float: right;
    margin-right: 10px;
    background-color: #f7f7f8;
    cursor: default;

    em[class*='ddp-icon-'],
    .ddp-wrap-datalock,
    .ddp-wrap-datarecommend {
      float: left;
      margin-left: 8px;
    }

    em {
      &.ddp-icon-time,
      &.ddp-icon-global-s {
        position: relative;
        top: 1px;
      }
    }

    .ddp-wrap-popup2 em[class*='ddp-icon-'] {
      margin-left: 0;
    }
  }

  .ddp-wrap-morebutton {
    .ddp-wrap-datalock {
      float: left;
    }

    float: left;
    position: relative;
    top: -1px;
  }

  .ddp-ui-label {
    display: inline-block;
    position: relative;
    max-width: 100px;
    font-size: 12px;
    color: #4b515b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;

    em.ddp-icon-chart {
      display: inline-block;
      width: 10px;
      height: 11px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_chart.png) no-repeat;
    }
  }
}

.ddp-ui-option-down {
  .ddp-ui-down-title:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid #e5e6e8;
    z-index: 1;
  }

  &:first-of-type .ddp-ui-down-title:before {
    display: none;
  }
}

.ddp-box-down .ddp-ui-down-title-in {
  padding-left: 0;
}

.ddp-ui-down-title-in {
  padding: 16px 0 10px 15px;
  cursor: pointer;

  .ddp-txt-in {
    display: block;
    position: relative;
    margin: -10px 0 -10px 0;
    padding: 10px 0 10px 0;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    &.ddp-error {
      padding-right: 18px;
    }
  }

  .ddp-ui-info.ddp-info-error {
    position: absolute;
    top: 10px;
    right: 0;
    vertical-align: top;
    cursor: pointer;

    .ddp-box-layout4 {
      position: fixed;
      right: 65px;
      top: 0;
      left: inherit;
      margin-top: 18px;
      width: 230px;
      box-sizing: border-box;
      z-index: 10;
    }
  }
}

.ddp-wrap-chart .ddp-ui-chart-side.ddp-filter .ddp-ui-down-title-in span.ddp-txt-down-title {
  max-width: 150px;
}

.ddp-ui-down-title-in span.ddp-txt-down-title {
  display: block;
  overflow: hidden;
  max-width: 100%;
  box-sizing: border-box;

  span.ddp-data-name {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
}

.ddp-ui-filter-boardside.ddp-filter .ddp-ui-down-title-in span.ddp-txt-down-title .ddp-view-name .ddp-ui-info {
  position: absolute;
  top: 0;
  right: 0;
}

.ddp-wrap-chart .ddp-ui-chart-side.ddp-filter .ddp-ui-down-title-in span.ddp-txt-down-title.ddp-type,
.ddp-ui-filter-boardside.ddp-filter .ddp-ui-down-title-in span.ddp-txt-down-title.ddp-type {
  max-width: 100px;
}

.ddp-ui-down-title-in span {
  &.ddp-txt-down-title {
    position: relative;
    display: inline-block;
    padding-right: 10px;

    .ddp-data-name {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
    }
  }

  &.ddp-txt-det {
    position: relative;
    top: -2px;
    margin-left: -8px;
    color: #b7b9c2;
    font-size: 11px;
    font-style: italic;
  }

  &.ddp-txt-down-title em.ddp-icon-use2 {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 5px;
    height: 5px;
    margin-top: -3px;
    border-radius: 50%;
    background-color: $primary;
    vertical-align: middle;
  }
}

.ddp-ui-down-title em.ddp-icon-menumove:before {
  display: inline-block;
  width: 9px;
  height: 5px;
  margin-right: 7px;
  background: url(/assets/bi/images/icon_dropdown.png) no-repeat;
  content: '';
}

.ddp-ui-option-down.ddp-selected em.ddp-icon-menumove:before {
  background-position: left -6px;
}

.ddp-ui-down-contents {
  display: none;
  padding: 8px 0 10px 18px;

  .ddp-list-sidesub:first-of-type {
    padding-top: 0;
  }

  .ddp-list-part:first-of-type {
    margin-top: 0;
  }
}

.ddp-ui-option-down.ddp-selected .ddp-ui-down-contents {
  display: block;
  padding-bottom: 20px;
  padding-left: 0;

  .ddp-data-status {
    color: #90969f;
    font-size: 12px;
  }

  .ddp-ui-slider-type {
    margin: 0;

    &.type-result .irs-single {
      display: block !important;
    }
  }

  .ddp-txt-det {
    display: block;
    padding-top: 2px;
    color: #b7b9c2;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      content: '';
      width: 13px;
      height: 13px;
      margin-right: 4px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: left -16px;
      vertical-align: middle;
    }
  }
}

.ddp-wrap-downmenu {
  padding: 0 0 0 0;
}

.ddp-box-down {
  .ddp-ui-down-contents2 {
    padding: 0 0 0 16px;
  }

  .ddp-wrap-divide {
    padding: 10px 0 0 17px;
    margin: 10px 0 0 -17px;
    position: relative;
    border-top: 1px solid #e5e6e8;

    &.ddp-first {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    &.ddp-selected {
      border-top: 2px solid #e5e6e8;

      &.ddp-first {
        border-top: 1px solid #e5e6e8;
      }
    }

    .ddp-divide2 {
      padding-bottom: 6px;

      .ddp-wrap-colorby-button {
        margin: 0;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.ddp-disabled {
      opacity: 0.5;

      &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        cursor: no-drop;
      }
    }

    .ddp-wrap-disabled {
      position: relative;
      opacity: 0.5;

      &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        cursor: no-drop;
      }
    }
  }

  > .ddp-wrap-divide:first-of-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  > .ddp-wrap-divide.ddp-selected:first-of-type {
    border-top: 1px solid #e5e6e8;
  }

  .ddp-divide2 + {
    .ddp-ui-layer-type .ddp-ui-type-contents,
    .ddp-type-map-chart .ddp-ui-layer-type .ddp-ui-type-contents {
      border-top: 1px solid #e5e6e8;
      padding-top: 10px;
      margin-top: 10px;
    }
  }

  .ddp-ui-type-contents .ddp-wrap-divide:first-of-type {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  }

  .ddp-wrap-option-multy {
    padding: 3px 0;
  }

  .ddp-list-part {
    position: relative;
    padding: 3px 0;

    .ddp-ui-divide {
      padding-top: 10px;

      &:first-of-type {
        padding-top: 0;
      }
    }

    &.ddp-divide {
      margin-top: 0;
      padding: 3px 0;
    }
  }

  .ddp-list-label {
    padding: 10px 0;
    color: #4a515c;
    font-size: 13px;

    &.type-label {
      padding-bottom: 0;
    }
  }

  .ddp-preview {
    padding: 5px 0;
    color: #4b515b;
    font-size: 16px;
    text-align: center;
    background-color: #eeeef0;
  }

  .ddp-preview2 {
    padding: 15px 18px;
    font-size: 12px;
    color: #4b515b;
    background-color: #eeeef0;

    p {
      font-size: 12px;
    }

    .ddp-part {
      padding: 10px 0 0 0;

      .ddp-sub {
        padding-left: 26px;
      }
    }

    .ddp-icon-round {
      display: inline-block;
      margin-right: 12px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #f98888;
    }
  }

  .ddp-list-part .ddp-btn-reset3 {
    position: absolute;
    top: 0;
    right: 0;

    &.ddp-disabled {
      background-position: -1px -20px;
      cursor: no-drop;
    }
  }

  .ddp-list-sub-part2 {
    padding: 0 0 16px 18px;
    border-bottom: 1px solid #e5e6e8;

    &.ddp-border-none {
      border-bottom: none;
    }

    .ddp-color-option {
      padding-bottom: 24px;
      position: relative;

      .ddp-box-color {
        display: inline-block;
        position: relative;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        cursor: pointer;
      }

      .ddp-icon-control-cut {
        position: absolute;
        top: 0;
        right: 0;
      }

      .ddp-ui-slider-type {
        margin-top: 13px;

        .irs-min,
        .irs-max {
          color: #b7b9c3;
        }

        .irs-to {
          display: none !important;
        }
      }

      .ddp-data-range {
        padding: 12px 0 0 0;

        .ddp-input-typebasic {
          text-align: center;
        }

        .ddp-bar {
          display: block;
          line-height: 30px;
          text-align: center;
          color: #a4aab0;
          font-size: 14px;
        }
      }
    }

    .ddp-ui-var-plus a.ddp-link-var-add {
      float: inherit;
      top: 0;
      padding-left: 0;
    }
  }

  .ddp-list-part .ddp-type-sum {
    padding-bottom: 9px;
    color: #4b515b;
    font-weight: bold;
    font-size: 12px;

    span.ddp-data-sum {
      display: block;
      padding: 3px 0 0 0;
      color: #b7b9c2;
      font-weight: normal;
      font-size: 11px;
    }
  }
}

.ddp-ui-option-down {
  &.ddp-move .ddp-ui-down-title-in {
    cursor: all-scroll;
  }

  .ddp-ui-down-title-in span.ddp-txt-down-title {
    position: relative;
    display: inline-block;
    margin: -10px 0 -10px 0;
    padding: 10px 10px 10px 19px;
  }

  .ddp-ui-down-title .ddp-ui-down-title-in .ddp-txt-in em.ddp-icon-menumove {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.ddp-lnb-down .ddp-ui-down-title em.ddp-icon-menumove {
    position: relative;
  }

  .ddp-ui-down-title em.ddp-icon-menumove {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 9px;
    height: 100%;
    margin-right: 9px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -3px 0 0 -5px;
    }
  }
}

.ddp-list-sub2 {
  padding: 0 0 0 18px;

  .ddp-divide2:last-of-type {
    padding-bottom: 0;
  }

  .ddp-list-sub2 {
    padding-left: 15px;
  }

  .ddp-ui-color-select {
    padding: 9px 0;

    .ddp-txt-label {
      position: relative;
    }

    .ddp-txt-data {
      display: block;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;

      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }

    .ddp-txt-label {
      .ddp-ui-txt {
        display: block;
        position: relative;
        padding: 0 17px 0 35px;
      }

      .ddp-btn-reset3 {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
      }
    }

    .ddp-box-color {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  span.ddp-box-color {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    vertical-align: middle;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.ddp-ui-chart-side .ddp-list-sub-part2 span.ddp-box-color {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
}

.ddp-list-sub2 span.ddp-box-color.ddp-selected:after,
.ddp-ui-chart-side .ddp-list-sub-part2 span.ddp-box-color.ddp-selected:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  content: '';
}

.ddp-list-sub2 span.ddp-box-color.ddp-user:before,
.ddp-ui-chart-side .ddp-list-sub-part2 span.ddp-box-color.ddp-user:before {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  background: url(/assets/bi/images/icon_coloruser.png) no-repeat;
  content: '';
}

.ddp-list-sub3 {
  padding-left: 18px;
}

.ddp-box-down {
  .ddp-wrap-disabled {
    position: relative;

    &.ddp-disabled:before {
      position: absolute;
      top: 36px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f7f7f8;
      z-index: 10;
      content: '';
      opacity: 0.5;
    }
  }

  .ddp-wrap-divide:first-of-type .ddp-divide2 {
    padding-top: 0;
  }

  .ddp-part-check {
    display: block;
    padding: 10px 0 11px 0;
  }

  .ddp-part-check-b {
    display: block;
    padding: 10px 0;
  }
}

.ddp-list-sub-part2 .ddp-ui-color-select {
  padding: 5px 0;

  .ddp-txt-label {
    line-height: 30px;

    div.ddp-box-color {
      display: inline-block;
      position: relative;
      margin-right: 5px;
      vertical-align: middle;
      cursor: pointer;
    }

    span.ddp-box-color.ddp-selected:before {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 2px solid #000;
      content: '';
    }
  }
}

.ddp-box-down {
  &.ddp-disabled:after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(247, 247, 248, 0.5);
    z-index: 1;
  }

  .ddp-list-part ul.ddp-list-checktype.ddp-multy-list li {
    padding: 6px 0;

    .ddp-wrap-option-multy {
      margin: -5px 0 -4px 0;
    }
  }
}

.ddp-wrap-option-multy {
  .ddp-label-txt {
    display: block;
    padding: 7px 0 7px 0;
    min-height: 30px;
    color: #4a515c;
    font-size: 13px;
    box-sizing: border-box;
    word-break: break-word;

    &.ddp-info {
      label.ddp-label-radio {
        display: inline-block;
      }

      .ddp-txt-det {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;

        &.ddp-det-cursor {
          cursor: pointer;

          &:before {
            margin-right: 0;
            vertical-align: top;
          }

          .ddp-ui-tooltip-info.ddp-down {
            left: -50px;
            right: inherit;

            .ddp-icon-view-top {
              left: 60px;
            }
          }
        }
      }
    }
  }

  .ddp-label-checkbox {
    margin: 7px 0 7px 0;
  }
}

.ddp-box-down .ddp-list-part {
  .ddp-check-full {
    margin-top: 0;
    margin-bottom: 9px;
  }

  .ddp-label-txt2 {
    line-height: 36px;
    color: #4b515b;
    font-size: 13px;

    &:first-of-type {
      margin-top: -8px;
    }

    .ddp-wrap-hover-info {
      display: inline-block;
      position: relative;
      cursor: pointer;

      .ddp-icon-info3 {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: left -12px;
      }

      .ddp-box-layout4 {
        display: none;
        position: fixed;
        right: 13px;
        width: 420px;
      }

      &:hover .ddp-box-layout4 {
        display: block;
      }
    }
  }

  .ddp-box-area {
    padding: 4px 0 5px 0;
  }

  .ddp-box-selectbox {
    padding: 3px 0 3px 0;
  }
}

.ddp-list-part {
  .ddp-check-full {
    margin: 4px 0;
  }

  .ddp-label-checkbox.ddp-check-full span.ddp-txt-checkbox {
    font-size: 12px;
  }
}

.ddp-list-part2 .ddp-label-checkbox.ddp-check-full span.ddp-txt-checkbox,
.ddp-list-part .ddp-label-radio.ddp-check-full span.ddp-txt-radio,
.ddp-list-part2 .ddp-label-radio.ddp-check-full span.ddp-txt-radio {
  font-size: 12px;
}

.ddp-wrap-option-checkbox {
  padding: 3px 0;

  .ddp-label-type {
    line-height: 29px;
    color: #d0d1d8;
  }

  .ddp-label-checkbox {
    position: relative;
    top: 9px;

    span.ddp-txt-checkbox {
      font-size: 12px;
    }
  }
}

.ddp-sub-inner {
  padding-left: 20px;

  .ddp-wrap-option-slider span.ddp-label-slider {
    font-size: 12px;
  }
}

.ddp-wrap-option-slider {
  position: relative;
  padding: 10px 0;

  span.ddp-label-slider {
    font-size: 13px;
    color: #4b515b;
  }

  .ddp-checkbox-slide {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -7px;
    overflow: hidden;
  }
}

.ddp-ui-active {
  position: relative;

  .ddp-data-detail {
    display: none;
    color: #b7b9c2;
    font-size: 12px;
  }

  &.ddp-disabled {
    .ddp-data-detail {
      display: block;
      opacity: 0.6;
    }

    .ddp-label-slider {
      opacity: 0.6;
    }
  }
}

.ddp-wrap-option-slider.ddp-disabled:before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
  content: '';
}

.ddp-wrap-option-radio2 {
  padding: 7px 0;
  margin-bottom: 6px;

  .ddp-label-radio {
    display: inline-block;
    margin-right: 17px;

    span.ddp-txt-radio {
      font-size: 13px;
    }
  }
}

.ddp-list-sub-part {
  &.ddp-disabled {
    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      cursor: no-drop;
    }

    .ddp-list-part {
      opacity: 0.4;
    }
  }

  position: relative;
  padding-bottom: 17px;

  .ddp-list-part {
    padding-left: 18px;
  }

  .ddp-wrap-option-multy .ddp-label-txt {
    color: #90969f;
  }
}

.ddp-data-range {
  clear: both;
  padding-top: 7px;
  text-align: left;

  span {
    &.ddp-label-range {
      padding: 10px 0 0 0;
      color: #b7b9c2;
      font-size: 12px;
      clear: both;
    }

    &.ddp-view-range {
      margin-left: 5px;
      color: #b7b9c2;
      font-size: 12px;
      font-weight: bold;

      span {
        display: inline-block;
      }
    }
  }
}

/* 차트 presentation */

.ddp-wrap-express {
  padding-bottom: 4px;

  span.ddp-ui-express-name {
    display: block;
    padding: 7px 0;
    margin-top: 6px;
    font-size: 13px;
    color: #90969f;
  }
}

.ddp-list-express {
  margin: 4px 0;
  border-radius: 2px;
  background-color: #fff;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  li.ddp-disabled:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    content: '';
    cursor: no-drop;
    z-index: 1;
  }

  &[class*='ddp-type'] li.ddp-disabled:hover {
    background-color: #e7e7ea;
  }

  li.ddp-disabled:hover .ddp-ui-tooltip-info {
    display: block;
    top: 100%;
    left: inherit;
    right: -11px;
    width: 200px;
    margin-top: 5px;
    white-space: normal;
    opacity: 1;

    .ddp-icon-view-top {
      display: inline-block;
      position: absolute;
      top: -6px;
      right: 53px;
      left: inherit;
      width: 4px;
      height: 8px;
      margin-left: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      transform: rotate(90deg);
    }
  }

  &.ddp-disabled li {
    opacity: 0.5;
    cursor: no-drop;
  }

  &[class*='ddp-type'].ddp-disabled li {
    &:hover {
      background: #e7e7ea;
    }

    &.ddp-selected:hover {
      background-color: #4b515b;
    }
  }

  &.ddp-tab3 li {
    width: 33.3333%;
  }

  li {
    float: left;
    width: 50%;
    min-height: 30px;
    padding: 8px 0;
    border: 1px solid #d0d1d8;
    border-left: none;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    color: #90969f;
    text-align: center;
    font-size: 11px;

    &.ddp-colorpicker {
      position: relative;
      padding: 0;

      .sp-replacer {
        position: relative;
        padding: 9px 0;
        width: 100%;
        height: 30px;
        text-align: center;
        box-sizing: border-box;

        .sp-preview {
          float: inherit;
          width: 11px;
          height: 11px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -6px;
          background: url(/assets/bi/images/icon_presentation2.png) no-repeat;
          background-position: -12px -32px;

          .sp-preview-inner {
            display: none;
          }
        }
      }

      &.ddp-selected .sp-replacer .sp-preview {
        .sp-preview-inner {
          display: block;
        }

        width: 16px;
        height: 16px;
        margin: -8px 0 0 -8px;
        border: 1px solid #fff;
        box-sizing: border-box;
      }
    }

    &:first-of-type {
      border-left: 1px solid #d0d1d8;
      border-radius: 2px 0 0 2px;
    }

    &:hover {
      background-color: #f3f3f4;
    }

    &.ddp-selected {
      color: #4a515c;

      &:before {
        display: inline-block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 1px solid #90969f;
        content: '';
      }
    }

    em[class*='ddp-icon-'] {
      margin-right: 5px;
    }
  }

  &.ddp-tab6 li {
    width: 33.33333%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: none;
    }

    &:nth-child(4) {
      border: 1px solid #d0d1d8;
    }
  }

  &[class*='ddp-type'] {
    margin: 0;
    padding: 0;
    background-color: #e7e7ea;

    li {
      color: #4b515b;
      border: none;
      border-left: 1px solid #fff;

      &.ddp-letter {
        letter-spacing: -1px;
      }

      &:first-of-type {
        border: none;
      }

      &:hover {
        background-color: #d0d1d8;
      }

      &.ddp-selected {
        background-color: #4b515b;
        color: #fff;

        &:before {
          border: none;
        }
      }
    }
  }

  &.ddp-type [class*='ddp-icon-'] {
    margin-right: 0;
  }

  &.ddp-type2 [class*='ddp-icon-'] {
    margin-right: 5px !important;
  }

  &.ddp-tab6[class*='ddp-type'] li {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      border: none;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
    }

    &:nth-child(4) {
      border-left: none;
    }
  }

  &.ddp-tab4 {
    margin-top: 1px;
  }
}

.ddp-wrap-multy-express .ddp-ui-page {
  padding: 9px 0 0 0;
  text-align: center;

  .ddp-data-page,
  .ddp-btn-next,
  .ddp-btn-prev {
    float: inherit;
    vertical-align: top;
  }
}

.ddp-list-express.ddp-multy4 li {
  width: 25%;
  height: 40px;
  padding: 0;
  border-top: 1px solid #fff;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-top: none;
  }

  &:nth-child(4n + 1) {
    border-left: none;
  }
}

em {
  &.ddp-icon-line-type,
  &.ddp-icon-area-type,
  &.ddp-icon-world,
  &.ddp-icon-domestic,
  &.ddp-icon-textcolor,
  &.ddp-icon-areacolor,
  &.ddp-icon-column-view,
  &.ddp-icon-horizontal-view,
  &.ddp-icon-left,
  &.ddp-icon-center,
  &.ddp-icon-right,
  &.ddp-icon-top,
  &.ddp-icon-middle,
  &.ddp-icon-bottom,
  &.ddp-icon-multi,
  &.ddp-icon-stack,
  &.ddp-icon-vertical,
  &.ddp-icon-horizontal,
  &.ddp-icon-value,
  &.ddp-icon-percent,
  &.ddp-icon-link-circle,
  &.ddp-icon-link-point,
  &.ddp-icon-link-none,
  &.ddp-icon-allview,
  &.ddp-icon-partview,
  &.ddp-icon-basic-line,
  &.ddp-icon-accrue,
  &.ddp-icon-color,
  &.ddp-icon-size,
  &.ddp-icon-shape,
  &.ddp-icon-single-color,
  &.ddp-icon-outline,
  &.ddp-icon-translucence,
  &.ddp-icon-circle,
  &.ddp-icon-square,
  &.ddp-icon-triangle,
  &.ddp-icon-diamond,
  &.ddp-icon-pin,
  &.ddp-icon-arrow,
  &.ddp-icon-pie1,
  &.ddp-icon-pie2,
  &.ddp-icon-pivot,
  &.ddp-icon-original,
  &.ddp-icon-radar-area,
  &.ddp-icon-scissors,
  &.ddp-icon-font-s,
  &.ddp-icon-font-m,
  &.ddp-icon-font-l,
  &.ddp-icon-font-bold,
  &.ddp-icon-font-italic,
  &.ddp-icon-column-repeat,
  &.ddp-icon-horizontal-repeat,
  &.ddp-icon-row-split,
  &.ddp-icon-column-split,
  &.ddp-icon-grid-split,
  &.ddp-icon-page-split,
  &.ddp-icon-percent-type,
  &.ddp-icon-updown,
  &.ddp-icon-solid-chart,
  &.ddp-icon-line-chart,
  &.ddp-icon-line-straigt,
  &.ddp-icon-hexagon,
  &.ddp-icon-line-curve {
    display: inline-block;
    background: url(/assets/bi/images/icon_presentation.png) no-repeat;
    vertical-align: middle;
  }
}

.ddp-list-express {
  em.ddp-icon-cross {
    display: inline-block;
    background: url(/assets/bi/images/icon_presentation.png) no-repeat;
    vertical-align: middle;
  }

  &[class*='ddp-type'] [class*='ddp-icon-'] {
    display: inline-block;
    background: url(/assets/bi/images/icon_presentation2.png) no-repeat;
  }

  em {
    &.ddp-icon-line-straigt {
      width: 11px;
      height: 9px;
      background-position: -12px -199px !important;
    }

    &.ddp-icon-line-curve {
      width: 11px;
      height: 9px;
      background-position: -12px -209px !important;
    }

    &.ddp-icon-line-chart {
      width: 10px;
      height: 10px;
      background-position: -11px -157px !important;
    }

    &.ddp-icon-solid-chart {
      width: 10px;
      height: 10px;
      background-position: -11px -168px !important;
    }

    &.ddp-icon-updown {
      width: 7px;
      height: 10px;
      background-position: -167px -68px !important;
    }

    &.ddp-icon-percent-type {
      width: 9px;
      height: 9px;
      background-position: -130px -96px !important;
    }

    &.ddp-icon-row-split {
      width: 11px;
      height: 11px;
      background-position: -170px -23px !important;
    }

    &.ddp-icon-column-split {
      width: 11px;
      height: 11px;
      background-position: -170px -35px !important;
    }

    &.ddp-icon-grid-split {
      width: 11px;
      height: 11px;
      background-position: -170px -47px !important;
    }

    &.ddp-icon-page-split {
      width: 17px;
      height: 8px;
      background-position: -176px -59px !important;
    }

    &.ddp-icon-font-bold {
      width: 8px;
      height: 9px;
      background-position: -9px -179px !important;
    }

    &.ddp-icon-font-italic {
      width: 6px;
      height: 9px;
      background-position: -7px -189px !important;
    }

    &.ddp-icon-cross:before {
      display: none;
    }

    &.ddp-icon-line-type {
      width: 11px;
      height: 11px;
      background-position: -12px top !important;
    }

    &.ddp-icon-area-type {
      width: 11px;
      height: 11px;
      background-position: -12px -11px !important;
    }

    &.ddp-icon-world {
      width: 9px;
      height: 9px;
      background-position: -168px -13px !important;
    }

    &.ddp-icon-domestic {
      width: 9px;
      height: 11px;
      background-position: -168px top !important;
    }

    &.ddp-icon-textcolor {
      width: 11px;
      height: 10px;
      background-position: -57px top !important;
    }

    &.ddp-icon-areacolor {
      width: 11px;
      height: 11px;
      background-position: -57px -11px !important;
    }

    &.ddp-icon-column-view {
      width: 11px;
      height: 13px;
      background-position: -57px -92px !important;
    }

    &.ddp-icon-horizontal-view {
      width: 17px;
      height: 9px;
      background-position: -63px -106px !important;
    }

    &.ddp-icon-left {
      width: 11px;
      height: 10px;
      background-position: -57px -23px !important;
    }

    &.ddp-icon-center {
      width: 11px;
      height: 10px;
      background-position: -57px -34px !important;
    }

    &.ddp-icon-right {
      width: 11px;
      height: 10px;
      background-position: -57px -45px !important;
    }

    &.ddp-icon-top {
      width: 11px;
      height: 10px;
      background-position: -57px -56px !important;
    }

    &.ddp-icon-middle {
      width: 12px;
      height: 12px;
      background-position: -57px -67px !important;
    }

    &.ddp-icon-bottom {
      width: 11px;
      height: 10px;
      background-position: -57px -81px !important;
    }

    &.ddp-icon-multi {
      width: 11px;
      height: 11px;
      background-position: -132px -37px !important;
    }

    &.ddp-icon-stack {
      width: 11px;
      height: 11px;
      background-position: -132px -49px !important;
    }

    &.ddp-icon-pivot {
      width: 11px;
      height: 11px;
      background-position: -132px -37px !important;
    }

    &.ddp-icon-original {
      width: 11px;
      height: 11px;
      background-position: -132px -49px !important;
    }

    &.ddp-icon-vertical {
      width: 11px;
      height: 11px;
      background-position: -132px -61px !important;
    }

    &.ddp-icon-horizontal {
      width: 11px;
      height: 11px;
      background-position: -132px -73px !important;
    }

    &.ddp-icon-value {
      width: 10px;
      height: 10px;
      background-position: -131px -85px !important;
    }

    &.ddp-icon-percent {
      width: 9px;
      height: 9px;
      background-position: -130px -96px !important;
    }

    &.ddp-icon-link-circle {
      width: 11px;
      height: 5px;
      background-position: -12px -26px !important;
    }

    &.ddp-icon-link-point {
      width: 14px;
      height: 5px;
      background-position: -15px -107px !important;
    }

    &.ddp-icon-link-none {
      width: 11px;
      height: 1px;
      background-position: -12px -113px !important;
    }

    &.ddp-icon-allview {
      width: 10px;
      height: 10px;
      background-position: -11px -86px !important;
    }

    &.ddp-icon-partview {
      width: 13px;
      height: 9px;
      background-position: -14px -97px !important;
    }

    &.ddp-icon-basic-line {
      width: 12px;
      height: 6px;
      background-position: -13px -68px !important;
    }

    &.ddp-icon-accrue {
      width: 12px;
      height: 10px;
      background-position: -13px -75px !important;
    }

    &.ddp-icon-color {
      width: 11px;
      height: 11px;
      background-position: -12px -32px !important;
    }

    &.ddp-icon-size {
      width: 11px;
      height: 11px;
      background-position: -12px -44px !important;
    }

    &.ddp-icon-shape {
      width: 13px;
      height: 11px;
      background-position: -14px -56px !important;
    }

    &.ddp-icon-single-color {
      width: 9px;
      height: 11px;
      background-position: -91px top !important;
    }
  }
}

.ddp-list-expressem.ddp-icon-outline {
  width: 9px;
  height: 11px;
  background-position: -91px -12px !important;
}

.ddp-list-express {
  em {
    &.ddp-icon-translucence {
      width: 9px;
      height: 11px;
      background-position: -91px -24px !important;
    }

    &.ddp-icon-circle {
      width: 11px;
      height: 11px;
      background-position: -93px -36px !important;
    }

    &.ddp-icon-square {
      width: 10px;
      height: 10px;
      background-position: -92px -48px !important;
    }

    &.ddp-icon-triangle {
      width: 11px;
      height: 10px;
      background-position: -93px -59px !important;
    }

    &.ddp-icon-diamond {
      width: 11px;
      height: 11px;
      background-position: -93px -70px !important;
    }

    &.ddp-icon-scissors {
      width: 9px;
      height: 9px;
      background-position: -91px -94px !important;
    }

    &.ddp-icon-cross {
      width: 11px;
      height: 11px;
      background-position: -93px -83px !important;
    }

    &.ddp-icon-pin {
      width: 9px;
      height: 12px;
      background-position: -91px -104px !important;
    }

    &.ddp-icon-arrow {
      width: 11px;
      height: 10px;
      background-position: -93px -117px !important;
    }

    &.ddp-icon-pie1 {
      width: 11px;
      height: 11px;
      background-position: -132px top !important;
    }

    &.ddp-icon-pie2 {
      width: 11px;
      height: 11px;
      background-position: -132px -12px !important;
    }

    &.ddp-icon-radar-area {
      width: 10px;
      height: 10px;
      background-position: -12px -115px !important;
    }

    &.ddp-icon-font-s {
      width: 7px;
      height: 7px;
      background-position: -8px -126px !important;
    }

    &.ddp-icon-font-m {
      width: 8px;
      height: 9px;
      background-position: -9px -134px !important;
    }

    &.ddp-icon-font-l {
      width: 10px;
      height: 12px;
      background-position: -11px -144px !important;
    }

    &.ddp-icon-column-repeat {
      width: 7px;
      height: 15px;
      background-position: -120px -106px !important;
    }

    &.ddp-icon-horizontal-repeat {
      width: 15px;
      height: 7px;
      background-position: -120px -122px !important;
    }

    &.ddp-icon-fly {
      width: 14px;
      height: 14px;
      background-position: -96px -128px !important;
    }

    &.ddp-icon-user {
      width: 11px;
      height: 12px;
      background-position: -93px -143px !important;
    }

    &.ddp-icon-hexagon {
      width: 10px;
      height: 11px;
      background-position: -169px -79px !important;
    }
  }

  [class*='ddp-line-bold'] {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    width: 16px;
    height: 1px;
    background-color: #4b515b;
  }

  em {
    &.ddp-line-bold2 {
      height: 2px;
      margin-top: -1px;
    }

    &.ddp-line-bold3 {
      height: 3px;
      margin-top: -2px;
    }

    &.ddp-line-bold5 {
      height: 5px;
      margin-top: -3px;
    }

    &.ddp-line-dotted,
    &.ddp-line-dashed {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      background: url(/assets/bi/images/icon_presentation2_map.png) no-repeat;
    }

    &.ddp-line-dotted {
      width: 14px;
      height: 2px;
      margin: -1px 0 0 -7px;
    }

    &.ddp-line-dashed {
      width: 16px;
      height: 3px;
      margin: -1px 0 0 -8px;
      background-position: left -3px;
    }
  }

  li {
    &.ddp-selected {
      em {
        &.ddp-line-dotted {
          background-position-x: -15px;
        }

        &.ddp-line-dashed {
          background-position-x: -17px;
        }
      }

      [class*='ddp-line-bold'] {
        background-color: #fff;
      }

      em {
        &.ddp-icon-font-bold {
          background-position: 0 -179px !important;
        }

        &.ddp-icon-font-italic {
          background-position: 0 -189px !important;
        }

        &.ddp-icon-line-type {
          background-position: 0 top !important;
        }

        &.ddp-icon-area-type {
          background-position: 0 -11px !important;
        }

        &.ddp-icon-column-repeat {
          background-position: -128px -106px !important;
        }

        &.ddp-icon-horizontal-repeat {
          background-position: -136px -122px !important;
        }

        &.ddp-icon-font-s {
          background-position: 0 -126px !important;
        }

        &.ddp-icon-font-m {
          background-position: 0 -134px !important;
        }

        &.ddp-icon-font-l {
          background-position: 0 -144px !important;
        }

        &.ddp-icon-pie1,
        &.ddp-icon-pie2 {
          background-position: -120px top !important;
        }

        &.ddp-icon-single-color {
          background-position: -81px top !important;
        }

        &.ddp-icon-outline {
          background-position: -81px -12px !important;
        }

        &.ddp-icon-translucence {
          background-position: -81px -24px !important;
        }

        &.ddp-icon-circle {
          background-position: -81px -36px !important;
        }

        &.ddp-icon-square {
          background-position: -81px -48px !important;
        }

        &.ddp-icon-triangle {
          background-position: -81px -59px !important;
        }

        &.ddp-icon-diamond {
          background-position: -81px -70px !important;
        }

        &.ddp-icon-scissors {
          background-position: -81px -94px !important;
        }

        &.ddp-icon-cross {
          background-position: -81px -83px !important;
        }

        &.ddp-icon-pin {
          background-position: -81px -104px !important;
        }

        &.ddp-icon-arrow {
          background-position: -81px -117px !important;
        }

        &.ddp-icon-world {
          background-position: -158px -13px !important;
        }

        &.ddp-icon-domestic {
          background-position: -158px top !important;
        }

        &.ddp-icon-textcolor {
          background-position: -45px top !important;
        }

        &.ddp-icon-areacolor {
          background-position: -45px -11px !important;
        }

        &.ddp-icon-column-view {
          background-position: -45px -92px !important;
        }

        &.ddp-icon-horizontal-view {
          background-position: -45px -106px !important;
        }

        &.ddp-icon-left {
          background-position: -45px -23px !important;
        }

        &.ddp-icon-center {
          background-position: -45px -34px !important;
        }

        &.ddp-icon-right {
          background-position: -45px -45px !important;
        }

        &.ddp-icon-top {
          background-position: -45px -56px !important;
        }

        &.ddp-icon-middle {
          background-position: -45px -67px !important;
        }

        &.ddp-icon-bottom {
          background-position: -45px -81px !important;
        }

        &.ddp-icon-multi {
          background-position: -120px -37px !important;
        }

        &.ddp-icon-stack {
          background-position: -120px -49px !important;
        }

        &.ddp-icon-pivot {
          background-position: -120px -37px !important;
        }

        &.ddp-icon-original {
          background-position: -120px -49px !important;
        }

        &.ddp-icon-vertical {
          background-position: -120px -61px !important;
        }

        &.ddp-icon-horizontal {
          background-position: -120px -73px !important;
        }

        &.ddp-icon-value {
          background-position: -120px -85px !important;
        }

        &.ddp-icon-percent {
          background-position: -120px -96px !important;
        }

        &.ddp-icon-link-circle {
          background-position: left -26px !important;
        }

        &.ddp-icon-link-point {
          background-position: left -107px !important;
        }

        &.ddp-icon-link-none {
          background-position: left -113px !important;
        }

        &.ddp-icon-allview {
          background-position: left -86px !important;
        }

        &.ddp-icon-partview {
          background-position: left -97px !important;
        }

        &.ddp-icon-basic-line {
          background-position: left -68px !important;
        }

        &.ddp-icon-accrue {
          background-position: left -75px !important;
        }

        &.ddp-icon-color {
          background-position: left -32px !important;
        }

        &.ddp-icon-size {
          background-position: left -44px !important;
        }

        &.ddp-icon-shape {
          background-position: left -56px !important;
        }

        &.ddp-icon-radar-area {
          background-position: left -115px !important;
        }

        &.ddp-icon-row-split {
          background-position: -158px -23px !important;
        }

        &.ddp-icon-column-split {
          background-position: -158px -35px !important;
        }

        &.ddp-icon-grid-split {
          background-position: -158px -47px !important;
        }

        &.ddp-icon-page-split {
          background-position: -158px -59px !important;
        }

        &.ddp-icon-percent-type {
          width: 9px;
          height: 9px;
          background-position: -120px -96px !important;
        }

        &.ddp-icon-updown {
          background-position: -158px -68px !important;
        }

        &.ddp-icon-line-chart,
        &.ddp-icon-solid-chart {
          background-position-x: 0 !important;
        }

        &.ddp-icon-fly,
        &.ddp-icon-user {
          background-position-x: -81px !important;
        }

        &.ddp-icon-hexagon {
          background-position-x: -158px !important;
        }
      }
    }

    em {
      &.ddp-img-kpi-type1,
      &.ddp-img-kpi-type2,
      &.ddp-img-kpi-type3,
      &.ddp-img-kpi-type4 {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        background: url(/assets/bi/images/icon_kpi.png) no-repeat;
      }

      &.ddp-img-kpi-type1 {
        margin: -11px 0 0 -11px;
        width: 22px;
        height: 23px;
      }

      &.ddp-img-kpi-type2 {
        margin: -10px 0 0 -10px;
        width: 19px;
        height: 21px;
        background-position: left -24px;
      }

      &.ddp-img-kpi-type3 {
        margin: -8px 0 0 -13px;
        width: 26px;
        height: 16px;
        background-position: left -46px;
      }

      &.ddp-img-kpi-type4 {
        margin: -10px 0 0 -9px;
        width: 17px;
        height: 21px;
        background-position: left -63px;
      }
    }

    &.ddp-selected em {
      &.ddp-img-kpi-type1 {
        background-position-x: -23px;
      }

      &.ddp-img-kpi-type2 {
        background-position-x: -19px;
      }

      &.ddp-img-kpi-type3 {
        background-position-x: -27px;
      }

      &.ddp-img-kpi-type4 {
        background-position-x: -18px;
      }

      &.ddp-icon-line-straigt,
      &.ddp-icon-line-curve {
        background-position-x: 0 !important;
      }
    }
  }
}

.ddp-wrap-colorby-button {
  position: relative;
  padding-left: 26px;
  margin: 6px 0 6px 0;
  cursor: pointer;

  em.ddp-icon-colorby {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 25px;
    background: url(/assets/bi/images/btn_color.png) no-repeat;
    background-color: #fff;
  }

  button[class*='ddp-btn-color'] {
    width: 100%;
    background-size: 100% 100% !important;
  }
}

button {
  &[class*='ddp-btn-color-series'],
  &[class*='ddp-btn-color-'],
  &[class*='ddp-btn-color2-series'] {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 25px;
    cursor: pointer;
  }

  &[class*='ddp-btn-color'].ddp-selected {
    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      border: 3px solid #4b515b;
      z-index: 10;
      overflow: hidden;
    }

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: 2px;
      border: 1px solid #fff;
    }
  }

  &.ddp-btn-color-series1.ddp-type {
    background: url(/assets/bi/images/img_color_01_type.png) no-repeat;
  }

  &.ddp-btn-color-series2.ddp-type {
    background: url(/assets/bi/images/img_color_02_type.png) no-repeat;
  }

  &.ddp-btn-color-series3.ddp-type {
    background: url(/assets/bi/images/img_color_03_type.png) no-repeat;
  }

  &.ddp-btn-color-series4.ddp-type {
    background: url(/assets/bi/images/img_color_04_type.png) no-repeat;
  }

  &.ddp-btn-color-series5.ddp-type {
    background: url(/assets/bi/images/img_color_05_type.png) no-repeat;
  }

  &.ddp-btn-color-series6.ddp-type {
    background: url(/assets/bi/images/img_color_06_type.png) no-repeat;
  }

  &.ddp-btn-color-series7.ddp-type {
    background: url(/assets/bi/images/img_color_07_type.png) no-repeat;
  }

  &.ddp-btn-color-series8.ddp-type {
    background: url(/assets/bi/images/img_color_08_type.png) no-repeat;
  }

  &.ddp-btn-color-series9.ddp-type {
    background: url(/assets/bi/images/img_color_09_type.png) no-repeat;
  }

  &.ddp-btn-color-series1 {
    background: url(/assets/bi/images/img_color_01.png) no-repeat;
  }

  &.ddp-btn-color-series2 {
    background: url(/assets/bi/images/img_color_02.png) no-repeat;
  }

  &.ddp-btn-color-series3 {
    background: url(/assets/bi/images/img_color_03.png) no-repeat;
  }

  &.ddp-btn-color-series4 {
    background: url(/assets/bi/images/img_color_04.png) no-repeat;
  }

  &.ddp-btn-color-series5 {
    background: url(/assets/bi/images/img_color_05.png) no-repeat;
  }

  &.ddp-btn-color-series6 {
    background: url(/assets/bi/images/img_color_06.png) no-repeat;
  }

  &.ddp-btn-color-series7 {
    background: url(/assets/bi/images/img_color_07.png) no-repeat;
  }

  &.ddp-btn-color-series8 {
    background: url(/assets/bi/images/img_color_08.png) no-repeat;
  }

  &.ddp-btn-color-series9 {
    background: url(/assets/bi/images/img_color_09.png) no-repeat;
  }

  &.ddp-btn-color2-series1 {
    background: url(/assets/bi/images/img_color2_01.png) no-repeat;
  }

  &.ddp-btn-color2-series2 {
    background: url(/assets/bi/images/img_color2_02.png) no-repeat;
  }

  &.ddp-btn-color2-series3 {
    background: url(/assets/bi/images/img_color2_03.png) no-repeat;
  }

  &.ddp-btn-color2-series4 {
    background: url(/assets/bi/images/img_color2_04.png) no-repeat;
  }

  &.ddp-btn-color2-series5 {
    background: url(/assets/bi/images/img_color2_05.png) no-repeat;
  }

  &.ddp-btn-color2-series6 {
    background: url(/assets/bi/images/img_color2_06.png) no-repeat;
  }

  &.ddp-btn-color2-series7 {
    background: url(/assets/bi/images/img_color2_07.png) no-repeat;
  }

  &.ddp-btn-color-type1 {
    background: url(/assets/bi/images/img_color_type1.png) no-repeat;
  }

  &.ddp-btn-color-type2 {
    background: url(/assets/bi/images/img_color_type2.png) no-repeat;
  }

  &.ddp-btn-color-type3 {
    background: url(/assets/bi/images/img_color_type3.png) no-repeat;
  }

  &.ddp-btn-color-type4 {
    background: url(/assets/bi/images/img_color_type4.png) no-repeat;
  }

  &.ddp-btn-color-type5 {
    background: url(/assets/bi/images/img_color_type5.png) no-repeat;
  }

  &.ddp-btn-color-type6 {
    background: url(/assets/bi/images/img_color_type6.png) no-repeat;
  }

  &.ddp-btn-color-type7 {
    background: url(/assets/bi/images/img_color_type7.png) no-repeat;
  }

  &.ddp-btn-color-type8 {
    background: url(/assets/bi/images/img_color_type8.png) no-repeat;
  }

  &.ddp-btn-color-type9 {
    background: url(/assets/bi/images/img_color_type9.png) no-repeat;
  }

  &.ddp-btn-color-type10 {
    background: url(/assets/bi/images/img_color_type10.png) no-repeat;
  }

  &.ddp-btn-color-type11 {
    background: url(/assets/bi/images/img_color_type11.png) no-repeat;
  }

  &.ddp-btn-color-type12 {
    background: url(/assets/bi/images/img_color_type12.png) no-repeat;
  }

  &.ddp-btn-color-type13 {
    background: url(/assets/bi/images/img_color_type13.png) no-repeat;
  }

  &.ddp-btn-color-type14 {
    background: url(/assets/bi/images/img_color_type14.png) no-repeat;
  }

  &.ddp-btn-color-type15 {
    background: url(/assets/bi/images/img_color_type15.png) no-repeat;
  }

  &.ddp-btn-color-type16 {
    background: url(/assets/bi/images/img_color_type16.png) no-repeat;
  }

  &.ddp-btn-color-type17 {
    background: url(/assets/bi/images/img_color_type17.png) no-repeat;
  }

  &.ddp-btn-color-type18 {
    background: url(/assets/bi/images/img_color_type18.png) no-repeat;
  }

  &.ddp-btn-color-type19 {
    background: url(/assets/bi/images/img_color_type19.png) no-repeat;
  }

  &.ddp-btn-color-type20 {
    background: url(/assets/bi/images/img_color_type20.png) no-repeat;
  }

  &.ddp-btn-color-type21 {
    background: url(/assets/bi/images/img_color_type21.png) no-repeat;
  }

  &.ddp-btn-color-type22 {
    background: url(/assets/bi/images/img_color_type22.png) no-repeat;
  }

  &.ddp-btn-color-type23 {
    background: url(/assets/bi/images/img_color_type23.png) no-repeat;
  }

  &.ddp-btn-color-type24 {
    background: url(/assets/bi/images/img_color_type24.png) no-repeat;
  }

  &.ddp-btn-color-type25 {
    background: url(/assets/bi/images/img_color_type25.png) no-repeat;
  }

  &.ddp-btn-color-type26 {
    background: url(/assets/bi/images/img_color_type26.png) no-repeat;
  }

  &.ddp-btn-color-type27 {
    background: url(/assets/bi/images/img_color_type27.png) no-repeat;
  }
}

.ddp-ui-colorby {
  position: relative;
}

.ddp-popup-side {
  position: absolute;
  top: -45px;
  left: -285px;
  width: 275px;
  border-radius: 3px;
  background-color: #fff;
  -moz-box-shadow: 0 1px 3px 1px rgba(82, 97, 105, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px 1px rgba(82, 97, 105, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px 1px rgba(82, 97, 105, 0.3);

  /* drop shadow */
}

.ddp-ui-colorby .ddp-popup-side {
  position: fixed;
  top: 105px;
  left: inherit;
  right: 345px;
  max-height: 510px;
  overflow: auto;
  z-index: 5;
}

.ddp-pop-side-top {
  position: relative;
  padding: 15px 20px;
  color: #444;
  font-weight: bold;

  a.ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 13px;
    right: 20px;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: -12px -58px;
  }
}

.ddp-popup-side.ddp-pop-colorpicker {
  display: inline-block;
}

.ddp-pop-side-contents {
  padding: 0 18px 18px 18px;

  span.ddp-pop-sub-title {
    display: block;
    padding-bottom: 6px;
    font-size: 12px;
    color: #b7b9c2;
  }

  .ddp-box-slider {
    padding: 5px 10px;
    margin: -8px 0 10px 0;
    background-color: #f7f7f9;
    border-radius: 3px;

    .ddp-wrap-option-slider {
      padding: 0;

      span.ddp-label-slider {
        color: #90969f;
      }
    }
  }

  ul.ddp-list-series li {
    padding: 7px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ddp-btn-type-popup {
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
  }
}

.ddp-popup-side.ddp-pop-colorpicker {
  position: fixed;
  z-index: 126;

  &.ddp-type {
    width: 288px;
  }
}

.ddp-pop-colorpicker.ddp-type [class*='ddp-color-picker'] {
  display: block;
  overflow: hidden;

  span.ddp-ui-color {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.ddp-selected {
      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid #4b515b;
      }

      &:after {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        border: 1px solid #fff;
        content: '';
      }
    }
  }

  [class*='ddp-bg-color'] {
    float: left;
    margin: 2px;
  }
}

.ddp-color-picker {
  float: left;
  margin-right: 4px;
  width: 26px;
  padding-bottom: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  span.ddp-ui-color {
    display: inline-block;
    position: relative;
    width: 26px;
    height: 24px;
    cursor: pointer;

    &.ddp-selected:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid #fff;
    }
  }
}

.ddp-color-picker1 span {
  &.ddp-bg-color1 {
    background-color: #ffcaba;
  }

  &.ddp-bg-color2 {
    background-color: #fda08c;
  }

  &.ddp-bg-color3 {
    background-color: #fb7661;
  }

  &.ddp-bg-color4 {
    background-color: #f8533b;
  }

  &.ddp-bg-color5 {
    background-color: #f23a2c;
  }

  &.ddp-bg-color6 {
    background-color: #e5342c;
  }

  &.ddp-bg-color7 {
    background-color: #d73631;
  }

  &.ddp-bg-color8 {
    background-color: #c22a32;
  }

  &.ddp-bg-color9 {
    background-color: #9a0b2c;
  }
}

.ddp-color-picker2 span {
  &.ddp-bg-color1 {
    background-color: #f6f4b7;
  }

  &.ddp-bg-color2 {
    background-color: #f9f6a1;
  }

  &.ddp-bg-color3 {
    background-color: #fee330;
  }

  &.ddp-bg-color4 {
    background-color: #ffd200;
  }

  &.ddp-bg-color5 {
    background-color: #fbb700;
  }

  &.ddp-bg-color6 {
    background-color: #f6a300;
  }

  &.ddp-bg-color7 {
    background-color: #f28a00;
  }

  &.ddp-bg-color8 {
    background-color: #f27603;
  }

  &.ddp-bg-color9 {
    background-color: #fb6e2c;
  }
}

.ddp-color-picker3 span {
  &.ddp-bg-color1 {
    background-color: #d1e5c2;
  }

  &.ddp-bg-color2 {
    background-color: #b5d994;
  }

  &.ddp-bg-color3 {
    background-color: #97cb63;
  }

  &.ddp-bg-color4 {
    background-color: #83bf47;
  }

  &.ddp-bg-color5 {
    background-color: #72b235;
  }

  &.ddp-bg-color6 {
    background-color: #5d9f27;
  }

  &.ddp-bg-color7 {
    background-color: #4b8a21;
  }

  &.ddp-bg-color8 {
    background-color: #39751d;
  }

  &.ddp-bg-color9 {
    background-color: #2d681a;
  }
}

.ddp-color-picker4 span {
  &.ddp-bg-color1 {
    background-color: #b5e0e1;
  }

  &.ddp-bg-color2 {
    background-color: #9ad5d2;
  }

  &.ddp-bg-color3 {
    background-color: #75c4be;
  }

  &.ddp-bg-color4 {
    background-color: #54b2ae;
  }

  &.ddp-bg-color5 {
    background-color: #2b9a9e;
  }

  &.ddp-bg-color6 {
    background-color: #0c8691;
  }

  &.ddp-bg-color7 {
    background-color: #026e7f;
  }

  &.ddp-bg-color8 {
    background-color: #015268;
  }

  &.ddp-bg-color9 {
    background-color: #064059;
  }
}

.ddp-color-picker5 span {
  &.ddp-bg-color1 {
    background-color: #c4eeed;
  }

  &.ddp-bg-color2 {
    background-color: #a9e7eb;
  }

  &.ddp-bg-color3 {
    background-color: #8adfe9;
  }

  &.ddp-bg-color4 {
    background-color: #6ed0e4;
  }

  &.ddp-bg-color5 {
    background-color: #58b5da;
  }

  &.ddp-bg-color6 {
    background-color: #4a95cf;
  }

  &.ddp-bg-color7 {
    background-color: #3f72c1;
  }

  &.ddp-bg-color8 {
    background-color: #3452b5;
  }

  &.ddp-bg-color9 {
    background-color: #23399f;
  }
}

.ddp-color-picker6 span {
  &.ddp-bg-color1 {
    background-color: #efdffd;
  }

  &.ddp-bg-color2 {
    background-color: #cdbaf8;
  }

  &.ddp-bg-color3 {
    background-color: #b099f0;
  }

  &.ddp-bg-color4 {
    background-color: #9b7fe4;
  }

  &.ddp-bg-color5 {
    background-color: #8d6dd2;
  }

  &.ddp-bg-color6 {
    background-color: #7c5ac1;
  }

  &.ddp-bg-color7 {
    background-color: #6344ad;
  }

  &.ddp-bg-color8 {
    background-color: #4c309a;
  }

  &.ddp-bg-color9 {
    background-color: #391f8a;
  }
}

.ddp-color-picker7 span {
  &.ddp-bg-color1 {
    background-color: #fcc9dd;
  }

  &.ddp-bg-color2 {
    background-color: #fca0c3;
  }

  &.ddp-bg-color3 {
    background-color: #fc79ac;
  }

  &.ddp-bg-color4 {
    background-color: #ee5398;
  }

  &.ddp-bg-color5 {
    background-color: #e03c8f;
  }

  &.ddp-bg-color6 {
    background-color: #cd2287;
  }

  &.ddp-bg-color7 {
    background-color: #ad037c;
  }

  &.ddp-bg-color8 {
    background-color: #7d0071;
  }

  &.ddp-bg-color9 {
    background-color: #4c006a;
  }
}

.ddp-color-picker8 span {
  &.ddp-bg-color1 {
    background-color: #eeeeee;
  }

  &.ddp-bg-color2 {
    background-color: #c3c3c3;
  }

  &.ddp-bg-color3 {
    background-color: #acacac;
  }

  &.ddp-bg-color4 {
    background-color: #959595;
  }

  &.ddp-bg-color5 {
    background-color: #797979;
  }

  &.ddp-bg-color6 {
    background-color: #636363;
  }

  &.ddp-bg-color7 {
    background-color: #4f4f4f;
  }

  &.ddp-bg-color8 {
    background-color: #3c3c3c;
  }

  &.ddp-bg-color9 {
    background-color: #313131;
  }
}

span {
  &.ddp-bg1-color1 {
    background-color: #c94819;
  }

  &.ddp-bg1-color2 {
    background-color: #fb7100;
  }

  &.ddp-bg1-color3 {
    background-color: #ffbe69;
  }

  &.ddp-bg1-color4 {
    background-color: #ffd37d;
  }

  &.ddp-bg2-color1 {
    background-color: #007e78;
  }

  &.ddp-bg2-color2 {
    background-color: #00a99c;
  }

  &.ddp-bg2-color3 {
    background-color: #01cbbb;
  }

  &.ddp-bg2-color4 {
    background-color: #70dec3;
  }

  &.ddp-bg3-color1 {
    background-color: #02936f;
  }

  &.ddp-bg3-color2 {
    background-color: #17d18e;
  }

  &.ddp-bg3-color3 {
    background-color: #54df9a;
  }

  &.ddp-bg3-color4 {
    background-color: #cee6d2;
  }

  &.ddp-bg4-color1 {
    background-color: #4c95ce;
  }

  &.ddp-bg4-color2 {
    background-color: #589ed7;
  }

  &.ddp-bg4-color3 {
    background-color: #81b8e1;
  }

  &.ddp-bg4-color4 {
    background-color: #d3e4ef;
  }

  &.ddp-bg5-color1 {
    background-color: #9678bc;
  }

  &.ddp-bg5-color2 {
    background-color: #a992c7;
  }

  &.ddp-bg5-color3 {
    background-color: #c7a9c7;
  }

  &.ddp-bg5-color4 {
    background-color: #e3d4e2;
  }

  &.ddp-bg6-color1 {
    background-color: #d57295;
  }

  &.ddp-bg6-color2,
  &.ddp-bg6-color3 {
    background-color: #e898b2;
  }

  &.ddp-bg6-color4 {
    background-color: #fae2ea;
  }

  &.ddp-bg7-color1 {
    background-color: #0d0d0d;
  }

  &.ddp-bg7-color2 {
    background-color: #595959;
  }

  &.ddp-bg7-color3 {
    background-color: #7f7f7f;
  }

  &.ddp-bg7-color4 {
    background-color: #8f8f8f;
  }

  &.ddp-bg8-color1 {
    background-color: #f2f2f2;
  }

  &.ddp-bg8-color2 {
    background-color: #d9d9d9;
  }

  &.ddp-bg8-color3 {
    background-color: #bfbfbf;
  }

  &.ddp-bg8-color4 {
    background-color: #afafaf;
  }
}

.ddp-wrap-usercolor {
  position: relative;
  left: 0;
  margin: 0 -15px;

  &.ddp-delete {
    left: -240px;
    -webkit-transition: left 0.5s ease-out;
    transition: left 0.5s ease-out;
  }
}

.ddp-list-usercolor {
  position: relative;
  padding: 3px 33px 3px 38px;

  .ddp-box-color {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;

    .sp-replacer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .sp-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: inherit;
      height: inherit;
    }
  }

  .ddp-data-range {
    display: block;
    width: 100%;
    padding-top: 0;

    .ddp-input-range {
      position: relative;
      float: left;
      width: 47%;

      &:last-of-type {
        float: right;

        .ddp-ui-tooltip-info {
          left: initial;
          right: -30px;
        }
      }

      .ddp-ui-tooltip-info em.ddp-icon-view-down {
        left: 60px;
      }

      &:last-of-type .ddp-ui-tooltip-info em.ddp-icon-view-down {
        left: inherit;
        right: 80px;
      }
    }

    .ddp-ng-ellipsis {
      .ddp-data-ellipsis,
      .ddp-input-typebasic {
        padding: 6px 6px;
        font-family: 'SpoqaHanSans', sans-serif;
      }
    }

    .ddp-ui-tooltip-info {
      bottom: 100%;
      top: inherit;
      left: -30px;
      max-width: 200px;
      margin-bottom: 5px;
      white-space: normal;
    }

    .ddp-input-typebasic:focus + .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-bar {
      float: left;
      width: 6%;
      padding: 7px 0 8px 0;
      text-align: center;
      box-sizing: border-box;
    }
  }

  .ddp-icon-listdelete {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -6px;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_delete.png) no-repeat;
    cursor: pointer;
  }
}

.ddp-user-color {
  .ddp-list-blank {
    position: relative;
    left: 0;
    height: 4px;
    text-indent: -300000px;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: height 0.2s;

    /* Safari */
    transition: height 0.2s;

    &:hover {
      padding: 4px 15px;
      height: 24px;
      color: $primary;
      font-size: 12px;
      font-weight: 300;
      text-indent: 0;
      background-color: rgba(156, 162, 204, 0.2);
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  .ddp-wrap-usercolor:first-of-type .ddp-list-blank {
    display: none;
  }

  .ddp-list-blank:hover:before {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 19px;
    background: url(/assets/bi/images/icon_plus.png) no-repeat;
    background-position: left -31px;
    vertical-align: middle;
    content: '';
  }
}

.ddp-wrap-divide {
  .ddp-btn-set {
    display: block;
    padding: 7px 0 8px 0;
    border: 1px solid #d0d1d7;
    border-radius: 2px;
    text-align: center;
    color: #90969f;
    font-size: 13px;
  }

  .ddp-data-sum {
    padding: 11px 0;
    color: #b7b9c2;
    font-size: 11px;
  }

  .ddp-btn-set:hover {
    border: 1px solid #b7b9c2;
    color: #4b515b;
  }
}

/* Analysis */

.ddp-lnb-down {
  .ddp-ui-down-title {
    position: relative;
    padding: 10px 9px 10px 22px;
    color: #90969f;
    font-size: 13px;
    background-color: #2f3339;
    cursor: pointer;

    &:before {
      display: none;
    }
  }

  .ddp-ui-down-contents {
    display: none;
    padding: 0 22px 0 40px;
    background-color: #35393f;
  }

  &.ddp-selected {
    .ddp-ui-down-contents {
      display: block;
      padding-left: 22px;
    }

    .ddp-ui-down-title em.ddp-icon-menumove:before {
      transform: rotate(0deg);
      content: '';
    }
  }

  span.ddp-label-slider {
    color: #d0d1d8;
  }
}

.ddp-ui-drop-contents .ddp-ui-option-down .ddp-ui-down-title em.ddp-icon-drop {
  display: inline-block;
}

.ddp-lnb-down {
  .ddp-ui-sign {
    position: relative;
    padding-top: 10px;

    &.ddp-disabled {
      opacity: 0.3;

      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 1;
      }
    }

    .ddp-txt-label {
      display: block;
      color: #90969f;
    }
  }

  .ddp-list-part {
    padding-bottom: 20px;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    .ddp-ui-form-type {
      padding: 0 0 10px 0;
    }

    .ddp-ui-label {
      padding: 7px 0 7px 0;
      color: #d0d1d8;
      font-size: 13px;
    }

    .ddp-form-multy {
      margin-bottom: 10px;
    }
  }

  .ddp-list-part-sub {
    padding: 0 0 0 18px;

    .ddp-ui-slider-type {
      margin: 10px -10px 10px 0;
    }

    .ddp-ui-link {
      text-align: left;
    }
  }

  .ddp-ui-link {
    &.ddp-setup {
      padding: 10px 0 0 0;

      .ddp-ui-option {
        display: none;
      }

      &.ddp-selected .ddp-ui-option {
        display: block;
        clear: both;
      }
    }

    .ddp-link-option {
      display: block;
      position: relative;
      float: right;
      padding-right: 12px;
      text-decoration: underline;
      font-style: italic;
      color: #90969f;
      font-size: 12px;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -2px;
        width: 8px;
        height: 4px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -8px top;
      }
    }

    &.ddp-selected .ddp-link-option:after {
      transform: rotate(180deg);
    }
  }

  .ddp-ui-option .ddp-ui-option-check {
    padding: 15px 0 8px 0;
    text-align: right;
  }

  .ddp-list-sub-in {
    position: relative;
    padding-left: 20px;
  }

  .ddp-ui-color-select {
    position: relative;
    margin-top: 7px;
    margin-bottom: 15px;
    white-space: nowrap;

    &.ddp-mgb0 {
      margin-bottom: 0;
    }

    .ddp-txt-label {
      display: inline-block;
      color: #90969f;
      font-size: 12px;
      line-height: 26px;

      div.ddp-box-color {
        top: 0;
        cursor: pointer;
      }
    }

    div.ddp-ui-box-color {
      display: inline-block;
      width: 18px;
      margin-right: 5px;
      vertical-align: top;
    }

    .ddp-type-selectbox {
      display: inline-block;
      width: 70px;
      box-sizing: border-box;
    }

    .ddp-size {
      display: inline-block;

      .ddp-type-selectbox {
        width: 91px;
      }
    }

    &.ddp-disabled {
      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        left: 0;
        z-index: 1;
        cursor: no-drop;
        content: '';
      }

      opacity: 0.5;
    }
  }

  div.ddp-box-color {
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    font-size: 0;
    background: url(/assets/bi/images/img_noncolor.png) no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
    cursor: pointer;
  }

  span.ddp-box-color {
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    font-size: 0;
    background: url(/assets/bi/images/img_noncolor.png) no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
    cursor: pointer;
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  .ddp-list-buttons {
    margin: 10px 0 20px 0;
  }

  ul.ddp-list-type {
    padding: 0 10px;

    li {
      padding: 10px 0;
      overflow: hidden;

      span.ddp-data-name {
        display: block;
        position: relative;
        top: 2px;
        color: #90969f;
        font-size: 12px;
        overflow: hidden;
      }

      .ddp-box-color {
        float: left;
        margin-right: 7px;
      }
    }
  }
}

.ddp-view-widget .ddp-ui-slider-type.type-per {
  display: inline-block;
  width: 80%;
  min-width: 200px;
}

.ddp-ui-slider-type.type-per {
  position: relative;

  span.ddp-txt-label {
    position: absolute;
    top: 0;
    left: 0;
    color: #90969f;
    font-size: 12px;
  }

  .irs-min,
  .irs-max {
    display: none !important;
  }

  .irs-single {
    display: none !important;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: inherit !important;
    color: #90969f;
    font-size: 12px;
  }

  .irs-to {
    display: none !important;
  }

  .irs-single:after {
    display: inline-block;
    content: '%';
    color: #90969f;
    font-size: 12px;
  }
}

.ddp-ui-check-type.ddp-inline {
  padding-top: 9px;
  white-space: nowrap;

  .ddp-label-radio {
    &:first-of-type {
      margin-left: 0;
    }

    display: inline-block;
    margin-left: 10px;
  }
}

.ddp-ui-select-value .ddp-ui-color-select {
  margin-bottom: 0;
}

.ddp-lnb-down .ddp-ui-color-select .ddp-box-color {
  margin-top: 5px;
}

.ddp-wrap-trendbtn {
  padding: 10px 0;
  white-space: nowrap;
  text-align: center;

  .ddp-ui-trendbtn {
    display: inline-block;
    position: relative;
  }
}

.ddp-ui-trendbtn {
  a {
    &[class*='ddp-btn-'] {
      display: inline-block;
      width: 38px;
      height: 38px;
      background: url(/assets/bi/images/btn_trend.png) no-repeat;
    }

    &.ddp-btn-linear {
    }

    &.ddp-btn-logarithmic {
      background-position: -42px top;
    }

    &.ddp-btn-exp {
      background-position: -84px top;
    }

    &.ddp-btn-quadratic {
      background-position: -126px top;
    }

    &.ddp-btn-cubic {
      background-position: -168px top;
    }
  }

  &:hover a {
    &.ddp-btn-linear {
      background-position: 0 -39px;
    }

    &.ddp-btn-logarithmic {
      background-position: -42px -39px;
    }

    &.ddp-btn-exp {
      background-position: -84px -39px;
    }

    &.ddp-btn-quadratic {
      background-position: -126px -39px;
    }

    &.ddp-btn-cubic {
      background-position: -168px -39px;
    }
  }

  .ddp-ui-tooltip-info {
    display: none;
    top: 44px;
    left: inherit;
    right: 0;

    em.ddp-icon-view-top {
      left: inherit;
      right: 17px;
    }
  }

  &:hover .ddp-ui-tooltip-info {
    display: block;
  }
}

.ddp-data-info-value {
  color: #90969f;
  font-size: 12px;
  line-height: 18px;
}

/* 차트 공통 */

.ddp-color-multy {
  position: relative;

  .ddp-type-selectbox {
    display: none;

    &.ddp-default {
      display: block;
    }
  }
}

ul.ddp-list-selectbox {
  li em.ddp-img-colorpicker {
    display: inline-block;
    width: 48px;
    height: 20px;
    background: url(/assets/bi/images/img_colorpicker.png) no-repeat;
  }

  &.ddp-color-select {
    position: absolute;
    top: 34px;
    right: 0;
    min-width: 100%;
    max-height: 160px;
    padding: 7px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    overflow-y: auto;
    z-index: 1;
    box-sizing: border-box;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    li a {
      display: block;
      position: relative;
      padding: 5px 10px;
      color: #4b525b;
      font-size: 13px;

      &:hover {
        background-color: #f6f6f7;
      }
    }
  }
}

/**********************************************************************************
        차트 (side menu)
    **********************************************************************************/

.ddp-wrap-chart-menu span.ddp-ui-menu-layout {
  display: block;
  position: relative;
  padding-top: 11px;
  margin-top: 13px;

  &:before {
    display: inline-block;
    content: '';
    width: 28px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -14px;
    border-top: 1px solid #e7e7ea;
  }
}

/**********************************************************************************
	차트 : 컬럼 디테일 팝업
**********************************************************************************/

.ddp-pop-preview .ddp-ui-top .ddp-data-name em {
  &.ddp-icon-type-ab,
  &.ddp-icon-type-calen,
  &.ddp-icon-type-sharp,
  &.ddp-icon-type-float {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 5px 0 0;
  }

  &.ddp-icon-type-ab {
    background-position: -14px top;
  }

  &.ddp-icon-type-calen {
    background-position: -12px -74px;
  }

  &.ddp-icon-type-sharp {
    background-position: -15px -23px;
  }

  &.ddp-icon-type-float {
    background-position: -17px -96px;
  }
}

.ddp-column-detail {
  top: 74px;
  left: 286px;
  right: 74px;
  bottom: 26px;

  .ddp-ui-preview-contents {
    overflow: hidden;
  }

  &.ddp-full {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ddp-wrap-list {
    position: relative;
    float: left;
    width: 264px;
    height: 100%;
    border-right: 1px solid #d5d6dc;
    z-index: 1;

    .ddp-ui-title {
      padding: 21px 16px 22px 16px;
      color: #4a515c;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #e7e7ea;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      &.ddp-bottom {
        .ddp-ui-topTit {
          font-size: 14px;
          font-weight: normal;
        }

        padding: 15px 16px 15px 16px;
      }

      .ddp-view-option {
        float: right;
      }
    }

    .ddp-ui-top {
      background-color: #e7e7ea;
    }
  }
}

.ddp-wrap-list {
  .ddp-ui-dataname {
    padding: 20px 10px 10px 10px;
    background-color: #e7e7ea;

    .ddp-top-title {
      display: inline-block;
      position: relative;
      max-width: 100%;
      padding: 0 20px 10px 9px;
      box-sizing: border-box;
    }

    .ddp-txt-name {
      display: inline-block;
      max-width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: #4b515b;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ddp-wrap-info {
      position: absolute;
      top: 5px;
      right: 0;
      display: inline-block;
      vertical-align: top;

      .ddp-btn-info2 {
        &:before {
          display: inline-block;
          content: '';
          width: 13px;
          height: 13px;
          margin-left: 5px;
          background: url(/assets/bi/images/icon_info.png) no-repeat;
          background-position: left -30px;
        }

        &:hover:before {
          background-position: -14px -30px;
        }
      }

      .ddp-box-layout4 {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 5px;

        &.ddp-scheme {
          max-width: 520px;
          width: auto;

          table.ddp-table-info {
            table-layout: inherit;
          }
        }
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }
    }
  }

  .ddp-data-namesub {
    position: relative;

    .ddp-data-in {
      position: relative;
      padding: 10px 25px 10px 10px;
      height: 35px;
      background-color: #f6f6f7;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        background-color: #fff;
      }

      .ddp-txt-dataname {
        display: block;
        color: #4b515b;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
      }

      .ddp-icon-database3 {
        float: left;
        margin-right: 5px;
      }

      .ddp-txt-namesub {
        display: block;
        color: #4b515b;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .ddp-icon-view {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 9px;
        width: 7px;
        height: 4px;
        margin-top: -2px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        content: '';
      }
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0 0 20px 0;
      box-sizing: border-box;

      .ddp-wrap-search {
        padding: 0 20px;
      }

      .ddp-form-search {
        margin-left: 0;
      }
    }

    &.ddp-selected .ddp-box-layout4 {
      display: block;
    }
  }
}

.ddp-column-detail {
  .ddp-wrap-list ul.ddp-list-data {
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    li {
      padding: 7px 15px;
      color: #4a525c;

      &:nth-child(odd) {
        background-color: #fafafa;
      }
    }
  }

  .ddp-wrap-detail {
    position: relative;
    float: left;
    width: 100%;
    max-height: 100%;
    margin-left: -265px;
    padding-left: 289px;
    padding-right: 24px;
    overflow: auto;
    box-sizing: border-box;
  }
}

/* 스키마팝업 */

.ddp-type-scheme.ddp-column-detail {
  .ddp-preview-type {
    .ddp-wrap-list {
      width: 460px;
    }

    .ddp-ui-datadetail2 {
      display: block;
      position: relative;
      overflow: hidden;
      height: 100%;
    }

    .ddp-type-top-option {
      left: 0;
    }
  }

  .ddp-wrap-list {
    width: 600px;
    box-sizing: border-box;
    z-index: 70;
  }

  .ddp-wrap-detail {
    height: 100%;
    margin-left: -600px;
    padding-left: 600px;
    padding-right: 0;
    overflow: hidden;
  }
}

.ddp-pop-preview.ddp-type-scheme {
  top: 50px;
  left: 90px;
  right: 90px;
  bottom: 52px;

  &.ddp-full {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .ddp-ui-top {
      display: none;
    }

    .ddp-ui-preview-contents {
      top: 0;
      border: none;
    }
  }

  .ddp-wrap-grid {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ddp-nodata {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 20px;
    color: #b7bac1;
    font-size: 13px;
  }

  .ddp-type-top-option {
    position: absolute;
    top: 0;
    left: 600px;
    right: 0;
    bottom: 0;
    padding: 34px 0 0 0;
    box-sizing: border-box;
  }
}

.ddp-type-scheme.ddp-column-detail .ddp-metadata {
  .ddp-wrap-list {
    width: 50%;
  }

  .ddp-wrap-detail {
    margin-left: -50%;
    padding-left: 50%;
  }
}

.ddp-pop-preview.ddp-type-scheme {
  .ddp-metadata .ddp-type-top-option {
    left: 50%;
  }

  .ddp-type-top-option .ddp-ui-tab-contents {
    position: relative;
    height: 100%;

    .ddp-wrap-viewtable {
      position: absolute;
      top: 44px;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .ddp-wrap-datadetail {
      position: absolute;
      top: 44px;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 28px;
      overflow: auto;

      &.ddp-full {
        top: 0;
      }
    }
  }
}

.ddp-wrap-datadetail .ddp-ui-option {
  .ddp-wrap-edit {
    float: left;
    padding-left: 46px;
    margin-right: 6px;

    &:first-of-type {
      padding-left: 0;
    }
  }

  .ddp-ui-rightoption {
    float: right;

    .ddp-btn-selection {
      float: left;
      margin-left: 4px;
    }
  }

  .ddp-type-selectbox {
    min-width: 160px;
  }
}

.ddp-pop-preview.ddp-type-scheme {
  .ddp-type-top-option .ddp-ui-tab-contents .ddp-wrap-searching {
    padding: 6px 15px 7px 15px;
    border-bottom: 1px solid #e7e7ea;
    background-color: #f6f6f7;

    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    .ddp-form-search {
      float: left;
      width: 400px;
      margin-top: 0;
    }
  }

  .ddp-form-search {
    width: 100%;
    margin-top: 10px;
  }

  ul {
    &.ddp-list-data {
      top: 102px;
    }

    &.ddp-ui-tab {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid #d7d8dd;

      &::after {
        display: block;
        content: '';
        clear: both;
      }

      li.ddp-selected a:after {
        display: none;
      }
    }
  }

  .ddp-view-option {
    .ddp-txt-tbNum {
      font-size: 12px;
    }

    .ddp-txt-table {
      font-size: 12px;
      font-weight: lighter;
    }

    em.ddp-icon-refresh3 {
      position: relative;
      top: -1px;
      margin-left: 10px;
    }
  }

  .ddp-box-viewtable {
    border: none;
  }
}

.ddp-box-viewtable .ddp-data-form {
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;

  a.ddp-btn-filter {
    position: relative;
    display: block;
    width: 17px;
    height: 17px;
    text-align: center;

    em {
      position: relative;
      top: 4px;
    }
  }

  .ddp-wrap-popup2 {
    position: absolute;
    top: 20px;
    left: -135px;
    width: 156px;
  }
}

.ddp-data-form.type-filter {
  .ddp-wrap-popup2 {
    display: none;
  }

  &.ddp-selected {
    .ddp-wrap-popup2 {
      display: block;
    }

    a.ddp-btn-filter {
      background-color: #f0f0f0;
    }
  }

  a.ddp-btn-filter:hover {
    background-color: #f0f0f0;
  }

  .ddp-list-popup {
    padding: 12px 20px;
    box-sizing: border-box;

    li {
      padding: 8px 0;

      em {
        position: relative;
        top: 0;
        left: inherit;
        margin: 0 6px 0 8px;
      }

      span {
        display: inline-block;
      }
    }
  }
}

.ddp-pop-wrapList {
  position: absolute;
  top: 186px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.ddp-table-form.type-scTb {
  .ddp-txt-long {
    padding: 0 30px 0 30px;
  }

  tr {
    td {
      em {
        &.ddp-icon-tb {
          position: absolute;
          top: 9px;
          left: 18px;
        }

        &.ddp-icon-rArrow {
          position: absolute;
          display: none;
          top: 10px;
          right: 13px;
        }
      }

      &:hover em.ddp-icon-rArrow {
        display: block;
      }
    }

    &.ddp-selected td em.ddp-icon-rArrow {
      display: block;
      background-position: -6px -48px;
    }
  }
}

table.ddp-table-select.type-scTb tbody tr.ddp-selected td:last-of-type:after {
  display: none;
}

.ddp-table-form.type-scTb tr td {
  &:hover .ddp-ui-tooltip-info {
    display: block;
  }

  .ddp-ui-tooltip-info {
    display: none;
    width: 235px;
    top: 28px;
    left: 37px;
    white-space: initial;
  }
}

.ddp-column-detail .ddp-ui-detail {
  padding-bottom: 40px;
  border-top: 1px solid #e7e7ea;

  &:first-of-type {
    border-top: none;
  }

  .ddp-ui-detail-in {
    padding: 0 0 20px 20px;
  }

  .ddp-wrap-histogram {
    margin-top: 15px;

    &:first-of-type {
      margin-top: 0;
    }

    .ddp-ui-label {
      padding-bottom: 5px;
      color: #4a515c;
      font-size: 13px;
    }
  }

  .ddp-box-histogram {
    height: 61px;
    padding: 5px;
    border: 1px solid #dadada;
  }

  .ddp-box-valuelist {
    height: 179px;
    border: 1px solid #dadada;
    box-sizing: border-box;
  }
}

.ddp-ui-preview-contents {
  &.ddp-full {
    position: absolute;
    top: 0;
  }

  .ddp-ui-title {
    padding: 21px 0 24px 0;
    color: #4a515c;
    font-size: 13px;
    font-weight: bold;

    &.ddp-name {
      font-size: 14px;
      padding-bottom: 0;

      .ddp-sub {
        font-weight: 300;
      }
    }
  }
}

.ddp-pop-datadetail .ddp-wrap-colum-detail .ddp-wrap-detail .ddp-ui-title.ddp-name + .ddp-ui-detail {
  border-top: none;
}

ul.ddp-list-graph {
  &.ddp-bottom li .ddp-box-image {
    margin-top: 0;
    margin-bottom: 5px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.ddp-cursor li {
    cursor: pointer;
  }

  margin: 20px -4px 0 -4px;
  overflow: hidden;

  li {
    float: left;
    width: 20%;
    padding: 0 4px;
    box-sizing: border-box;

    .ddp-data-graph span.ddp-txt-det {
      display: block;
      color: #4b515b;
      font-size: 12px;
    }

    span.ddp-txt-sub {
      display: block;
      margin-top: 5px;
      color: #90969f;
      font-size: 12px;
    }

    .ddp-box-image {
      margin-top: 5px;
      height: 137px;
      border: 1px solid #dadada;
    }
  }
}

.ddp-wrap-detail.ddp-type table.ddp-table-detail2 {
  padding: 0 20px;
  box-sizing: border-box;
}

table {
  &.ddp-table-detail2 {
    width: 100%;
    table-layout: fixed;

    tr {
      th {
        padding: 6px 0 6px 20px;
        text-align: left;
        font-size: 13px;
        color: #90969f;
        font-weight: normal;
        word-break: break-all;

        .ddp-wrap-hover-info {
          display: inline-block;
          position: relative;
          top: -2px;

          .ddp-icon-info3 {
            display: inline-block;
            width: 11px;
            height: 11px;
            position: relative;
            background: url(/assets/bi/images/icon_que.png) no-repeat;
            background-position: left -12px;
          }

          .ddp-box-layout4 {
            display: none;
            position: absolute;
            bottom: 100%;
            left: 100%;
            margin-left: 5px;
            margin-bottom: -50px;
            width: 350px;
          }

          &:hover .ddp-box-layout4 {
            display: block;
          }
        }

        &.ddp-th-valid {
          color: #26af5e;
        }

        &.ddp-th-missing {
          color: #ff2a1b;
        }
      }

      td {
        padding: 6px 0;
        color: #4b515b;
        font-size: 13px;
        word-break: break-all;

        .ddp-wrap-datarecommend {
          margin-right: 5px;
        }

        .ddp-box-tag-value {
          display: inline-block;
          padding: 2px 3px;
          margin: -3px 0 -4px 0;
          font-size: 12px;
        }

        .ddp-link-url {
          color: #69a2f8;
          font-size: 13px;
        }

        .ddp-box-tag-value {
          &.ddp-dimension {
            color: #439fe5;
            border: 1px solid #439fe5;
          }

          &.ddp-measure {
            border: 1px solid #4abccc;
            color: #4abccc;
          }
        }

        .ddp-data-size {
          .ddp-ui-bar-per {
            display: inline-block;
            margin-top: 8px;
          }

          &.type-error {
            .ddp-txt-data .ddp-txt-size {
              color: #dc494f;
            }

            .ddp-ui-bar-per {
              .ddp-ui-bar {
                background-color: #dc494f;
              }

              .ddp-bar-line {
                border-left: 1px solid #fff;
              }
            }
          }
        }
      }
    }

    &.type-vertical-top tr th {
      vertical-align: top;
    }

    &.ddp-nowrap tr {
      td,
      th {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
      }
    }

    tr.ddp-tr-sub th {
      padding-left: 36px;
    }
  }

  &.ddp-table-order {
    table-layout: fixed;
    width: 60%;
    border-top: 1px solid #b7b9c3;
    border-bottom: 1px solid #b7b9c3;

    tr {
      &:first-of-type td {
        border-top: none;
      }

      td {
        padding: 13px 13px;
        border-top: 1px solid #e7e7ea;
        font-size: 14px;
        color: #4c515a;
      }
    }
  }
}

/**********************************************************************************
	차트 : 알리아스 값
**********************************************************************************/

.ddp-data-alias-column {
  position: relative;
  padding-left: 19px;
  font-size: 14px;
  font-weight: bold;

  em[class*='ddp-icon-type'] {
    left: 0;
  }
}

.ddp-box-copy-table {
  position: absolute;
  top: 153px;
  left: 50px;
  right: 50px;
  bottom: 57px;
  padding-bottom: 42px;
  border: 1px solid #b7b9c2;

  .ddp-txt-info {
    position: absolute;
    top: -33px;
    right: 0;
    font-size: 12px;
    color: #b7b9c2;
  }

  .ddp-btn-bg {
    position: absolute;
    top: -38px;
    right: 0;
    padding: 7px 12px;
    border-radius: 2px;
    color: #90969f;

    .ddp-icon-reset {
      display: inline-block;
      margin-right: 3px;
      width: 14px;
      height: 13px;
      background: url(/assets/bi/images/icon_reset.png) no-repeat;
      background-position: -16px -20px;
      vertical-align: middle;
    }

    &:hover {
      color: #4b515b;

      .ddp-icon-reset {
        background-position: -15px -34px;
      }
    }
  }

  .ddp-box-add-link3 {
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
  }

  .ddp-view-grid {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

/**************************************************************
  popup : 맵 차트
**************************************************************/

.ddp-page-mapview {
  .ddp-btn-color-heatmap1 {
    background: url(/assets/bi/images/img_color_heatmap_type1.png) no-repeat;
  }

  .ddp-btn-color-heatmap2 {
    background: url(/assets/bi/images/img_color_heatmap_type2.png) no-repeat;
  }

  .ddp-btn-color-heatmap3 {
    background: url(/assets/bi/images/img_color_heatmap_type3.png) no-repeat;
  }

  .ddp-btn-color-heatmap4 {
    background: url(/assets/bi/images/img_color_heatmap_type4.png) no-repeat;
  }

  .ddp-btn-color-heatmap5 {
    background: url(/assets/bi/images/img_color_heatmap_type5.png) no-repeat;
  }

  .ddp-btn-color-heatmap6 {
    background: url(/assets/bi/images/img_color_heatmap_type6.png) no-repeat;
  }

  .ddp-btn-color-heatmap7 {
    background: url(/assets/bi/images/img_color_heatmap_type7.png) no-repeat;
  }

  .ddp-box-down .ddp-wrap-divide .ddp-divide2 .ddp-wrap-colorby-button.ddp-solid-color {
    padding-left: 0;
    height: 25px;

    .sp-preview,
    .sp-replacer {
      width: 100%;
      height: 100%;
    }

    .sp-preview:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 25px;
      background: url(/assets/bi/images/btn_color.png) no-repeat;
      content: '';
      background-color: #fff;
      border-radius: 3px;
      z-index: 1;
    }

    .sp-preview-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .ddp-box-color .ddp-solid-color .sp-replacer.sp-active {
    &:before,
    &:after {
      border: none;
    }
  }
}

.ddp-box-color .ddp-solid-color .sp-replacer.sp-active {
  border: none;
}

.ddp-page-mapview {
  .ddp-ui-drop-contents ul.ddp-list-source li em {
    &[class*='ddp-icon-mapview'] {
      position: absolute;
      top: 6px;
      left: 18px;
    }

    &.ddp-icon-mapview1:before {
      background-position: left top;
    }

    &.ddp-icon-mapview2:before {
      background-position: left -16px;
    }

    &.ddp-icon-mapview3:before {
      background-position: left -32px;
    }
  }

  .ddp-ui-cahrt-layout .ddp-ui-layout-center {
    .ddp-icon-mapview-guide {
      display: inline-block;
      width: 30px;
      height: 28px;
      margin-right: 15px;
      background: url(/assets/bi/images/icon_mapview_guide.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-bg-chart-mapview {
      display: inline-block;
      width: 307px;
      height: 307px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -154px 0 0 -154px;
      background: url(/assets/bi/images/img_chart/img_map.png) no-repeat;
    }
  }

  .ddp-wrap-chart-layout {
    position: relative;
  }

  .ddp-wrap-remark {
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    box-sizing: border-box;
  }

  .ddp-layout-remark {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;
    z-index: 10;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #e7e7ea;
    border-radius: 2px;
    overflow: auto;

    &:hover {
      border: 1px solid #9a9fa7;
    }

    &.ddp-small {
      border: none !important;
    }
  }

  .ddp-wrap-remark {
    &.ddp-right-bottom .ddp-layout-remark {
      top: initial;
      bottom: 0;
    }

    &.ddp-left-bottom,
    &.ddp-left-top {
      right: initial;
      left: 37px;
    }

    &.ddp-left-bottom .ddp-layout-remark {
      top: initial;
      bottom: 0;
      left: 0;
      right: initial;
    }

    &.ddp-left-top .ddp-layout-remark {
      top: 0;
      bottom: initial;
      right: initial;
    }
  }

  .ddp-layout-remark {
    .ddp-remark-size-b {
      display: block;
      position: relative;
      width: 150px;
      overflow: auto;
      box-sizing: border-box;
    }

    &.ddp-small {
      .ddp-remark-size-s {
        display: block;
      }

      .ddp-remark-size-b {
        display: none;
      }
    }

    .ddp-remark-size-s {
      display: none;
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 3px;
      border: 1px solid #626770;
      background-color: rgba(255, 255, 255, 0.9);

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        width: 12px;
        height: 11px;
        background: url(/assets/bi/images/icon_size_remark.png) no-repeat;
        content: '';
      }
    }

    .ddp-btn-size {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/btn_size_remark.png) no-repeat;
    }

    .ddp-ui-layer {
      position: relative;
      padding: 9px 9px;

      + .ddp-ui-layer {
        border-top: 1px solid #ededee;
      }

      .ddp-label {
        display: block;
        padding-bottom: 2px;
        color: #4a515c;
        font-size: 12px;
        font-weight: bold;

        + .ddp-data {
          padding-top: 0;
        }
      }

      .ddp-data {
        display: block;
        padding: 6px 0 0 0;
        color: #90969f;
        font-size: 11px;
      }

      .ddp-list-remark {
        padding: 6px 0 0 0;

        li {
          position: relative;
          padding-left: 20px;
          color: #4a515c;
          font-size: 11px;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-wrap: break-word;

          em {
            &.ddp-bg-remark-r,
            &.ddp-bg-remark {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -7px;
              width: 14px;
              height: 14px;
              margin-right: 6px;
              vertical-align: middle;
            }

            &.ddp-bg-remark-r {
              border-radius: 50%;
            }

            &.ddp-bg-remark-triangle {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -6px;
              width: 0;
              height: 0;
              border-right: 7px solid transparent;
              border-left: 7px solid transparent;
              border-bottom: 12px solid;
            }
          }

          + li {
            margin-top: 6px;
          }
        }
      }
    }
  }

  .ddp-form-slider {
    position: relative;
    padding-right: 70px;

    .ddp-input-typebasic {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -15px;
      width: 60px;
    }

    .ddp-box-option-input {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -15px;
      width: 60px;
      padding-right: 18px;
    }
  }
}

/**************************************************************
   맵 차트 remark
**************************************************************/

/**************************************************************
   맵 차트 slider form
**************************************************************/

.ddp-box-option-input.ddp-type .ddp-txt-input {
  float: right;
}

/**************************************************************
   맵 차트 tooltip
**************************************************************/

.ddp-map-tooltip .ddp-tooltip-view {
  display: inline-block;
  position: absolute;
  left: 90px;
  bottom: -6px;
  width: 4px;
  height: 8px;
  background: url(/assets/bi/images/icon_tagview.png) no-repeat;
  transform: rotate(-90deg);
}

/* 라이브러리 tooltip */

.ol-viewport {
  overflow: inherit !important;

  .ol-overlaycontainer .ol-overlay-container.ol-selectable {
    z-index: 15;
  }
}

/**********************************************************************************
	사용자지정
**********************************************************************************/

/* 팝업 타이틀 */

.ddp-label-pop-title {
  padding: 7px 0;
  color: #b7b9c2;
  font-size: 13px;
  font-weight: bold;
}

.ddp-ui-top span.ddp-ui-sub-title {
  display: block;
  padding: 5px 0 0 0;
  color: #4c515a;
  font-size: 13px;
  font-weight: bold;
}

.ddp-ui-popup-contents .ddp-wrap-edit3 {
  margin-bottom: 20px;

  &.ddp-mgb0 {
    margin-bottom: 0;
  }
}

.ddp-account-top {
  padding-bottom: 18px;
  overflow: hidden;

  .ddp-label {
    color: #4c515a;
    font-size: 13px;
    font-weight: bold;
  }

  .ddp-error {
    float: right;
    color: #ca4b4b;
    font-style: italic;
    font-size: 13px;
  }
}

.ddp-ui-accout-textarea {
  position: relative;
  width: 100%;
  height: 220px;
  background-color: #f9f9f9;

  .ddp-wrap-edit {
    width: 100%;
    height: 100%;

    .ddp-ui-edit {
      display: inline-block;
      padding: 25px 30px;
      width: 100%;
      height: 100%;
      color: #b7b9c2;
      font-size: 13px;
      line-height: 18px;
      border: none;
      border-top: 1px solid #e7e7ea;
      border-bottom: 1px solid #e7e7ea;
      overflow-y: auto;
      white-space: pre-wrap;
      box-sizing: border-box;
      text-align: left !important;
      z-index: 1;
    }
  }

  .ddp-ui-message {
    position: absolute;
    bottom: 20px;
    right: 20px;

    a.ddp-btn-pop {
      margin-left: 7px;
    }
  }

  .ddp-wrap-popup2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 248px;
  }
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block;

  /* For Firefox */
}

.ddp-wrap-popup2 {
  &.ddp-accout-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 248px;
    max-height: 230px;
    z-index: 127;
    overflow-y: auto;
    word-break: break-all;
  }

  &.ddp-types.ddp-accout-popup ul.ddp-list-popup li a {
    white-space: normal;
  }
}

.ddp-ui-account-search {
  position: absolute;
  top: 410px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px 30px 50px;
  box-sizing: border-box;
}

.ddp-ui-popup-contents.page-flex {
  display: flex;
  flex-direction: column;
}

.page-flex {
  .ddp-ui-accout-textarea {
    display: flex;
    flex: 1 1 0;
    margin-bottom: 20px;
  }

  .ddp-ui-account-search {
    display: flex;
    flex: 1 1 0;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0 30px 0;
  }

  .ddp-ui-formula {
    margin-top: -32px;
    padding-top: 32px;

    .ddp-ui-typebasic {
      top: 32px;
    }
  }
}

.ddp-ui-account-search {
  .ddp-wrap-form-list {
    float: left;
    height: 100%;
    margin-top: -30px;
    padding: 30px 0 0 0;
    box-sizing: border-box;
  }

  .ddp-wrap-add-list {
    position: relative;
    float: left;
    width: 305px;
    height: 100%;
    border-right: 1px solid #e7e7ea;
    box-sizing: Border-box;
    z-index: 1;

    .ddp-ui-page {
      position: absolute;
      bottom: 15px;
      left: 10px;
    }
  }
}

.ddp-wrap-add-list {
  .ddp-ui-top {
    position: relative;

    .ddp-ui-sub-title {
      padding-left: 10px;
    }

    .ddp-ui-sort {
      position: absolute;
      top: -10px;
      right: 20px;
      margin-top: 0;
    }

    .ddp-ui-page {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  ul.ddp-list-typebasic {
    position: absolute;
    top: 40px;
    left: 0;
    right: 22px;
    bottom: 0;
    overflow-y: auto;

    &.type-page {
      bottom: 45px;
    }
  }
}

.ddp-wrap-formula {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  margin: -30px 0 0 -305px;
  padding: 30px 0 0 325px;
  border-bottom: 1px solid #e7e7ea;
  box-sizing: border-box;
}

@keyframes boxSize {
  from {
    width: 240px;
  }

  to {
    width: 100%;
  }
}

@keyframes boxSizeLeft {
  from {
    width: 100%;
  }

  to {
    width: 241px;
  }
}

.ddp-ui-formula {
  float: left;
  position: relative;
  width: 258px;
  height: 100%;
  padding: 0 9px 0 0;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 1;

  &.ddp-full {
    width: 100%;
  }
}

.ddp-view-formula {
  position: relative;
  height: 100%;
  margin-top: -28px;
  padding-top: 28px;
  box-sizing: border-box;

  .ddp-ui-option {
    float: left;
    position: relative;
    width: 100%;
    z-index: 2;

    .ddp-type-search {
      float: left;
      width: 250px;
      box-sizing: border-box;
    }

    .ddp-wrap-edit3 {
      float: right;
      width: auto;
      margin-bottom: 0;

      label.ddp-label-type {
        width: auto;
        padding-right: 6px;
      }

      .ddp-type-selectbox {
        width: 155px;
      }
    }
  }
}

.ddp-ui-formula {
  .ddp-form-search {
    width: 100%;
    margin-bottom: 13px;
  }

  .ddp-ui-typebasic {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}

.ddp-view-formula {
  .ddp-ui-detail {
    position: absolute;
    top: 70px;
    left: 270px;
    bottom: 0;
    right: 0;
    padding: 0 10px 20px 10px;
    box-sizing: border-box;
    border: 1px solid #e7e7ea;
    border-bottom: none;
    overflow-y: auto;
    color: #90969f;
    font-size: 13px;

    .ddp-txt-part {
      display: block;

      .ddp-txt-list {
        display: block;
        padding-top: 10px;

        &:first-of-type {
          padding-top: 0;
        }

        .ddp-txt-sub-det {
          color: #83ae4a;
        }
      }
    }

    .ddp-btn-close {
      display: inline-block;
      position: absolute;
      right: 27px;
      top: 19px;
      width: 16px;
      height: 16px;
      background: url(/assets/bi/images/btn_close.png) no-repeat;
      cursor: pointer;
    }

    .ddp-ui-det-title {
      display: block;
      padding: 15px 0 5px 0;
      color: #4b515b;
      font-size: 13px;
      font-weight: bold;
    }

    .ddp-ui-det-category {
      display: block;
      padding-bottom: 25px;
      font-size: 11px;
      color: #4b515b;
    }

    .ddp-txt-det {
      display: block;
      padding-bottom: 10px;
      color: #90969f;
      font-size: 13px;
      line-height: 21px;
    }

    .ddp-data-start {
      color: #2774a1;
    }

    .ddp-data-end {
      color: #dd5e82;
    }

    .ddp-list-det {
      padding: 10px 0 0 0;

      li {
        position: relative;
        padding-left: 10px;
        color: #90969f;
        font-size: 13px;

        &:before {
          display: inline-block;
          position: absolute;
          top: 6px;
          left: 0;
          width: 4px;
          height: 4px;
          margin-right: 5px;
          background-color: #90969f;
          border-radius: 50%;
          vertical-align: middle;
          content: '';
        }
      }
    }
  }

  &.ddp-full .ddp-ui-detail {
    display: none;
  }
}

.ddp-ui-userfield {
  position: absolute;
  top: 240px;
  left: 50px;
  right: 50px;
  bottom: 0;
  padding: 40px 0 0 0;
  border-top: 1px solid #e7e7ea;

  .ddp-ui-setting {
    float: left;
    width: 48%;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;
    border-right: 1px solid #e7e7ea;
  }

  .ddp-ui-account-search {
    float: left;
    position: relative;
    width: 52%;
    padding: 0 0 0 20px;
    top: 0;
    box-sizing: border-box;

    .ddp-wrap-add-list {
      width: 100%;
      border-right: none;

      .ddp-ui-top .ddp-ui-page {
        right: 45px;
      }
    }
  }
}

/**********************************************************************************
	dataflow v2
**********************************************************************************/

.ddp-wrap-popup2.ddp-type {
  position: relative;
  max-height: 267px;
  overflow-y: auto;

  ul.ddp-list-popup {
    max-height: 225px;
    overflow-y: auto;

    li {
      a {
        padding: 14px 19px;

        .ddp-icon-dataset3 {
          position: relative;
          top: -2px;
        }
      }

      &.ddp-selected a {
        .ddp-icon-dataset3 {
          background-position: -40px top;
        }

        span.ddp-data-name {
          color: $primary;
          font-weight: bold;
        }
      }

      a span.ddp-data-name {
        color: #4b515b;
        font-size: 13px;
      }
    }
  }
}

.ddp-wrap-flow {
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 214px;
  overflow: auto;
  background-color: #e7e7ea;
  z-index: 9;

  &.ddp-split {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin-top: -48px;
    padding-top: 48px;
    box-sizing: border-box;
    overflow: hidden;
  }
}

.ddp-top-flow {
  position: relative;
  height: 48px;
  background-color: #e7e7ea;
  z-index: 10;

  .ddp-data-form {
    float: right;
    padding-right: 20px;
    margin-top: 9px;
    font-size: 12px;
    color: #9ba0ab;

    .ddp-btn-return {
      float: left;
      position: relative;
      top: 5px;
      margin-left: 20px;
    }

    span.ddp-txt-date {
      float: left;
      line-height: 30px;
    }

    .ddp-ui-more {
      float: left;
      margin-top: 5px;
      margin-left: 10px;

      &.ddp-selected .ddp-data-box {
        display: block;
      }
    }

    a.ddp-btn-info {
      display: inline-block;
      position: relative;
      width: 29px;
      height: 29px;
      margin-left: 5px;
      border: 1px solid transparent;
      border-radius: 2px;
      background: none;
      vertical-align: middle;

      em.ddp-icon-myinfo {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 3px;
        margin: -2px 0 0 -8px;
        background: url(/assets/bi/images/icon_more.png) no-repeat;
        background-position: left -8px;
        transform: rotate(90deg);
      }

      &:hover em.ddp-icon-myinfo {
        background-position: left -4px;
      }

      &.ddp-selected + .ddp-data-box {
        display: block;
      }
    }

    .ddp-data-box {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 10px;
      right: 10px;
      width: 250px;
      padding-bottom: 5px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 2px;
      box-sizing: border-box;
      -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */

      .ddp-data-by {
        display: block;
        padding: 10px 9px;
        border-top: 1px solid #ddd;
        color: #abb1bb;
        font-size: 12px;
      }

      a.ddp-list-link {
        display: block;
        padding: 10px 9px;
        color: #4b525b;
        font-size: 13px;

        &:hover {
          background-color: #f6f6f7;
        }

        em.ddp-icon-create {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          background: url(/assets/bi/images/icon_add.png) no-repeat;
          background-position: left -22px;
        }

        .ddp-icon-drop-re {
          position: Relative;
          top: 0;
          left: 0;
          margin-top: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .ddp-wrap-navi {
    padding: 0 0 0 10px;
    height: 48px;
    box-sizing: border-box;
    overflow: hidden;

    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    a.ddp-btn-back {
      position: relative;
      float: left;
      width: 50px;
      height: 48px;
      margin: 0 5px 0 -10px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 22px;
        margin-top: -8px;
        width: 17px;
        height: 16px;
        background: url(/assets/bi/images/btn_back.png) no-repeat;
        vertical-align: middle;
        content: '';
      }
    }

    .ddp-ui-naviarea {
      overflow: hidden;
    }

    span.ddp-ui-navitag {
      float: left;
      position: relative;
      top: 16px;
      padding: 2px 3px;
      margin-right: 8px;
      border: 1px solid #90969f;
      border-radius: 2px;
      font-size: 10px;
      color: #4b515b;
      font-weight: bold;
      line-height: 1em;
    }

    .ddp-icon-dbdata2 {
      float: left;
    }

    .ddp-ui-navi {
      position: relative;
      display: block;
      float: left;
      padding: 14px 0;

      .ddp-data-navi {
        display: inline-block;
        position: relative;
        padding-left: 26px;
        color: #4b515b;
        font-weight: 300;
        vertical-align: middle;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 9px;
          margin-top: -5px;
          width: 7px;
          height: 9px;
          background: url(/assets/bi/images/icon_navi2.png) no-repeat;
          content: '';
        }

        &:last-of-type:hover .ddp-icon-select {
          display: inline-block;
        }

        &:first-of-type {
          padding-left: 0;
          margin-left: 0;
          background: none;

          &:before {
            display: none;
          }
        }

        input.ddp-input-navi {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 10px;
          width: 100%;
          color: #545c66;
          font-size: 16px;
          border: none;
          background-color: #d9dadf;
          box-sizing: border-box;
        }

        a.ddp-txt-navi {
          min-width: 120px;
          color: #545c66;
          font-size: 16px;
        }

        &.ddp-data-flow {
          top: -2px;
          padding-left: 10px;

          &:first-of-type {
            padding-left: 0;
          }

          &:before {
            display: none;
          }
        }

        a.ddp-link-navi {
          display: inline-block;
          position: relative;
          padding: 3px 4px;
          min-width: 100px;
          height: 26px;
          vertical-align: middle;
          box-sizing: border-box;

          &.ddp-link-detail {
            padding: 5px 4px;
          }

          .ddp-txt-navi {
            color: #545c66;
            font-size: 16px;
            vertical-align: middle;
          }

          &:hover,
          &.ddp-selected {
            background-color: #d9dadf;
          }
        }

        em.ddp-icon-edit2 {
          display: none;
          position: relative;
          top: 0;
          margin-left: 15px;
        }

        a.ddp-link-navi {
          &.ddp-link-detail em.ddp-icon-edit2 {
            top: -1px;
          }

          &.ddp-selected:hover em.ddp-icon-edit2 {
            display: none;
          }

          &:hover em.ddp-icon-edit2,
          &.ddp-selected input.ddp-input-navi {
            display: inline-block;
          }
        }

        em.ddp-icon-select {
          display: none;
          margin-left: 7px;
          width: 9px;
          height: 5px;
          background: url(/assets/bi/images/icon_drop2.png) no-repeat;
          vertical-align: middle;
        }

        &.ddp-selected {
          em.ddp-icon-select,
          input.ddp-input-navi {
            display: inline-block;
          }
        }
      }
    }
  }

  .ddp-data-navi.ddp-selected .ddp-data-sub-navi {
    display: block;
  }

  .ddp-wrap-navi .ddp-ui-navi .ddp-data-navi a.ddp-link-navi.ddp-link-detail {
    .ddp-txt-navi {
      font-size: 12px;
      color: #90969f;

      &.ddp-datanone {
        color: #b7b9c2;
      }
    }

    input.ddp-input-navi {
      font-size: 12px;
      color: #9ba0ab;
    }
  }

  .ddp-data-sub-navi {
    display: none;
    position: absolute;
    top: 24px;
    left: 37px;
    white-space: nowrap;
    background-color: #fff;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    span.ddp-data-total {
      display: block;
      padding: 7px 15px;
      font-size: 12px;
      color: #acb2bb;
    }

    ul.ddp-list-sub-navi {
      width: 189px;

      li {
        a {
          display: block;
          position: relative;
          padding: 7px 15px 7px 35px;
          color: #4b525b;
          font-size: 12px;
          font-weight: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: normal;

          em.ddp-icon-dataset-s {
            position: absolute;
            top: 50%;
            left: 13px;
            margin-top: -8px;
          }

          &:hover {
            background-color: #f6f6f7;

            em.ddp-icon-dataset-s {
              background-position: -15px -20px;
            }
          }
        }

        &.ddp-selected a {
          background-color: #edebf5;
          color: $primary;

          &:hover {
            background-color: #edebf5;
            color: $primary;
          }

          em.ddp-icon-dataset-s,
          &:hover em.ddp-icon-dataset-s {
            background-position: left -20px;
          }
        }
      }
    }
  }

  .ddp-ui-buttons {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -15px;

    a[class*='ddp-btn-'] {
      margin-right: 5px;
    }
  }
}

/*.ddp-top-flow .ddp-ui-more.ddp-selected .ddp-icon-more:before {background-position:left -12px}*/

/*.ddp-top-flow .ddp-ui-more .ddp-wrap-popup2 {display:none; position:absolute; top:100%; right:-10px; margin-top:10px; width:250px;}*/

/*.ddp-top-flow .ddp-ui-more.ddp-selected .ddp-wrap-popup2 {display:block;}*/

em.ddp-icon-shot {
  display: inline-block;
  width: 16px;
  height: 11px;
  margin-right: 7px;
  background: url(/assets/bi/images/icon_shot.png) no-repeat;
  vertical-align: middle;
}

a:hover em.ddp-icon-shot {
  background-position: -17px top;
}

.ddp-wrap-flow {
  .ddp-wrap-flow-grid {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 377px;
    min-height: 117px;
    background-color: #fff;

    .ddp-wrap-grid-option {
      height: 55px;
      border-bottom: 1px solid #eaeaec;
      box-sizing: border-box;
    }
  }

  .ddp-ui-bar-right {
    position: absolute;
    top: 0;
    right: 370px;
    bottom: 0;
    width: 7px;
    background-color: #e7e7ea;
    z-index: 1;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -1px;
      width: 2px;
      height: 13px;
      background: url(/assets/bi/images/icon_bar.png) no-repeat;
    }
  }

  .ddp-ui-bar-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 7px;
    background-color: #e7e7ea;
    z-index: 1;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -1px;
      width: 2px;
      height: 13px;
      background: url(/assets/bi/images/icon_bar.png) no-repeat;
      transform: rotate(90deg);
    }
  }

  .ddp-wrap-flow-grid .ddp-box-gridarea {
    position: absolute;
    padding-top: 55px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    border-top: 1px solid #ddd;

    .ddp-ui-gridbody {
      top: 35px;
    }
  }
}

.ddp-box-gridarea.ddp-wrap-result-expand.ddp-ui-import-grid .ddp-wrap-grid {
  top: 0;
}

.ddp-wrap-flow .ddp-wrap-flow-grid .ddp-box-gridarea span.ddp-txt-mssing {
  color: #da5359;
}

.ddp-type-morepopup {
  position: fixed;
}

.ddp-wrap-flow .ddp-wrap-rules-flow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 370px;
  background-color: #fff;

  .ddp-box-title {
    position: relative;
  }

  &.ddp-split {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: inherit;
    height: 100%;
  }
}

.ddp-type-preview .ddp-wrap-grid-option {
  background-color: #f6f6f7;
  border-bottom: 1px solid #e7e7ea;
}

.ddp-type-contents-in .ddp-wrap-grid-option.ddp-top-option {
  padding-bottom: 0;
  margin-bottom: 0;
}

.ddp-wrap-grid-option {
  position: relative;
  z-index: 50;

  .ddp-part-left {
    float: left;
  }

  .ddp-part-right {
    float: right;
    padding-right: 15px;
  }

  .ddp-list-tab-button {
    float: left;
  }
}

.ddp-wrap-tab-dashboard .ddp-ui-setting ul.ddp-list-tab-button {
  margin: 4px 0 0 20px;
}

.ddp-wrap-grid-option {
  .ddp-part-right .ddp-type-search {
    float: left;
    width: 200px;
    margin-top: 15px;
    box-sizing: border-box;
  }

  .ddp-data-form {
    display: inline-block;
    position: relative;
    padding: 19px 0 12px 0;
    margin-left: 17px;
    color: #4b515b;
    font-size: 12px;
    font-weight: 300;

    strong {
      color: #4b515b;
      font-weight: bold;
    }

    input.ddp-data-input {
      display: inline-block;
      width: 80px;
      padding: 4px 9px;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid #d7d8dc;
      background: none;
      color: #656eb2;
      font-size: 12px;
    }

    .ddp-wrap-popup2 {
      position: absolute;
      right: 0;
      top: 47px;
    }
  }
}

.ddp-wrap-rules-flow {
  padding: 0 11px;
  box-sizing: border-box;
}

.ddp-rule-tab {
  padding: 19px 11px;
  margin: 0 -11px;
  overflow: hidden;
  border-bottom: 1px solid #eaeaec;

  li {
    float: left;

    a {
      display: inline-block;
      position: relative;
      padding: 0 13px;
      color: #b8b9c2;
      font-size: 13px;
    }

    + li a:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -7px;
      height: 14px;
      border-left: 1px solid #e7e7ea;
      content: '';
    }

    &.ddp-selected a {
      color: #6f7cc4;
      font-weight: bold;
    }

    a em.ddp-icon-selected {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-left: 2px;
      border-radius: 50%;
      background-color: #6f7cc4;
      vertical-align: top;
      content: '';
    }
  }
}

.ddp-wrap-rules-flow {
  &.ddp-snapshot .ddp-box-title {
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #eaeaec;
    color: #36393f;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    em.ddp-data-num {
      display: inline-block;
      padding: 2px 2px 1px 2px;
      min-width: 18px;
      min-height: 18px;
      margin-right: 6px;
      color: #3d4147;
      font-size: 9px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #3d4147;
      vertical-align: middle;
      box-sizing: border-box;
    }
  }

  .ddp-ui-buttons {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -10px;

    a {
      &.ddp-icon-refresh {
        display: inline-block;
        width: 17px;
        height: 16px;
        margin-left: 10px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
      }

      &.ddp-icon-refresh2 {
        display: inline-block;
        width: 17px;
        height: 16px;
        margin-left: 10px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
        background-position: -18px top;
      }

      &.ddp-icon-refresh:hover {
        background-position: left -17px;
      }

      &.ddp-icon-refresh2:hover {
        background-position: -18px -17px;
      }

      &.ddp-icon-refresh.ddp-disabled,
      &.ddp-icon-refresh2.ddp-disabled {
        opacity: 0.4;
        cursor: no-drop;
      }

      &.ddp-icon-refresh.ddp-disabled:hover {
        background-position: left top;
      }

      &.ddp-icon-refresh2.ddp-disabled:hover {
        background-position: -18px top;
      }
    }
  }
}

ul.ddp-list-snapshot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 76px;
  overflow: auto;

  li {
    position: relative;

    > .ddp-txt-snapshot {
      display: block;
      position: relative;
      padding: 15px 30px 14px 67px;
      border-bottom: 1px solid #eaeaec;
      cursor: pointer;

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 18px;
        margin-top: -6px;
        width: 6px;
        height: 11px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -68px;
        content: '';
      }

      &.ddp-disabled {
        &:before {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          z-index: 1;
        }

        &:after {
          display: none;
        }

        opacity: 0.3;
        border-bottom: none;
      }
    }

    .ddp-txt-cancel {
      padding-bottom: 13px;
      text-align: center;
      vertical-align: middle;

      .ddp-txt-det {
        display: block;
        padding-bottom: 15px;
        text-align: center;
        color: #3e4148;
        font-size: 14px;
      }

      a {
        display: inline-block;
        margin: 0 2px;
        vertical-align: middle;
      }
    }

    .ddp-txt-snapshot em {
      &.ddp-icon-snap-success,
      &.ddp-icon-snap-failed,
      &.ddp-icon-snap-cancel,
      &.ddp-icon-snap-play {
        display: inline-block;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: 19px;
        margin-top: -16px;
        background: url(/assets/bi/images/icon_snapshot.png) no-repeat;
      }

      &.ddp-icon-snap-failed {
        background-position: left -33px;
      }

      &.ddp-icon-snap-cancel {
        background-position: 0 -99px;
      }

      &.ddp-icon-snap-play {
        background-position: left -66px;

        .ddp-icon-cancel {
          display: inline-block;
          position: absolute;
          bottom: -4px;
          right: -4px;
          width: 15px;
          height: 15px;
          background: url(/assets/bi/images/icon_delete.png) no-repeat;
          background-position: left -14px;
          content: '';
          border: 1px solid #fff;
          background-color: #fff;
        }
      }
    }

    .ddp-ui-progress {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: #e7e7ea;

      span.ddp-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        background: $primary;
      }

      &.ddp-disabled {
        opacity: 0.3;
      }
    }

    a {
      .ddp-data-det {
        .ddp-data-title {
          display: block;
          padding-bottom: 6px;
          color: #3e4148;
          font-size: 14px;
        }

        .ddp-data-date {
          display: block;
          color: #b7b9c2;
          font-size: 12px;
        }
      }

      em.ddp-icon-snap-failed {
        background-position: left -33px;
      }
    }
  }
}

.ddp-ui-rule-flow {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  .ddp-ui-link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 23px 0;
    text-align: center;
  }
}

.ddp-wrap-tab-contents .ddp-wrap-rules-flow.ddp-snapshot {
  right: 0;
}

.ddp-wrap-rules-flow.ddp-snapshot {
  position: absolute;
  bottom: 0;
  right: 291px;
  left: 0;
  height: 255px;
  padding: 0 17px;

  ul.ddp-list-rule2 li {
    cursor: default;
    padding: 6px 17px;

    &:hover {
      background: none;
    }
  }
}

ul.ddp-list-rule2 {
  padding: 10px 0 0 0;

  li {
    &.ddp-edit {
      &:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 50;
      }

      .ddp-wrap-line-add {
        display: none;
      }
    }

    .ddp-data-type {
      float: left;
      position: relative;
      min-width: 23px;
      min-height: 18px;
      margin-right: 5px;
      margin-top: -1px;
      color: #727ab7;
      font-size: 13px;
      box-sizing: border-box;
      vertical-align: middle;

      .ddp-ui-round {
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        border-radius: 50%;
        color: #727ab7;
        font-size: 9px;
        border: 1px solid #727ab7;
        overflow: hidden;
        vertical-align: middle;
      }
    }

    .ddp-ui-code {
      display: block;
      font-size: 13px;
      line-height: 18px;
      color: #444;
      overflow: hidden;

      .ddp-type-code {
        color: #727ab7;
        font-size: 13px;
        line-height: 18px;
      }

      &.ddp-error {
        color: #eb5f58;
      }

      &.ddp-error2 {
        color: rgba(235, 95, 88, 0.5);
      }
    }

    .ddp-ui-tooltip-info {
      margin-top: 5px;
      top: 100%;
      left: 25px;
      max-width: 250px;
      white-space: normal;

      &.ddp-top {
        bottom: 100%;
        top: inherit;
      }

      .ul.ddp-list-rule2 li .ddp-ui-tooltip-info {
        left: 30px;
      }
    }

    &.ddp-disabled {
      * {
        color: #b8bac2 !important;
      }

      .ddp-ui-tooltip-info {
        color: #fff !important;
      }
    }

    &.ddp-disabled2 {
      * {
        color: #b8bac2 !important;
      }

      .ddp-data-type .ddp-ui-round {
        border: 1px solid #b8bac2;
      }

      .ddp-ui-tooltip-info {
        color: #fff !important;
      }
    }

    &.ddp-disabled {
      cursor: no-drop;

      .ddp-data-type .ddp-ui-round {
        border: 1px solid #b8bac2;
        color: #b8bac2;
      }
    }

    position: relative;

    .ddp-view-data {
      position: relative;
      padding: 6px 20px 6px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f7;

        .ddp-ui-tooltip-info {
          display: block;
          margin-top: 0;
          z-index: 20;
        }
      }

      &.ddp-tooltip-none:hover .ddp-ui-tooltip-info {
        display: none;
      }

      &.ddp-edit {
        position: relative;
        background-color: #ebecf4;

        .ddp-ui-edit-button,
        &:hover .ddp-ui-edit-button {
          background-color: #ebecf4;
        }

        .ddp-ui-edit-button .ddp-btn-link {
          float: left;
          position: relative;
          width: 40px;
          height: 100%;
        }

        .ddp-btn-icon {
          display: none;
        }

        .ddp-btn-link {
          display: block;
        }
      }
    }

    .ddp-wrap-line-add {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      cursor: pointer;
      padding: 6px 0 0 0;
      height: 6px;
      box-sizing: border-box;
      overflow: hidden;
      z-index: 10;

      &.ddp-top {
        position: absolute;
        top: 0;
      }

      &:hover {
        position: relative;
        height: 29px;
        padding-top: 0;
        clear: both;
        -webkit-transition: height 0.2s;

        /* Safari */
        transition: height 0.2s;
      }

      .ddp-line-add {
        display: block;
        background-color: #ebecf4;
      }

      .ddp-input-info {
        display: none;
        border-top: 1px solid #fff;
        padding: 6px 20px 7px 20px;
        color: rgba(102, 111, 173, 0.5);
        font-size: 13px;
        background-color: #ebecf4;

        .ddp-link-cancel {
          float: right;
          color: #90969f;
          font-size: 12px;
          text-decoration: underline;
          font-style: italic;
        }
      }

      &.ddp-selected {
        position: relative;
        height: auto;
        padding-top: 0;

        .ddp-input-info {
          display: block;
        }

        .ddp-line-add {
          display: none;
        }
      }
    }

    .ddp-line-add {
      display: none;

      span.ddp-txt {
        position: relative;
        display: block;
        padding: 7px 7px 7px 43px;
        color: $primary;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 20px;
          width: 15px;
          height: 15px;
          margin-top: -8px;
          background: url(/assets/bi/images/icon_plus.png) no-repeat;
          z-index: 1;
          background-position: left -31px;
          content: '';
        }
      }
    }

    .ddp-view-data:hover + .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-edit-button {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding-right: 15px;

      .ddp-btn-icon {
        display: inline-block;
        width: 22px;
        height: 100%;
      }

      .ddp-btn-link {
        display: none;
        height: 100%;
      }
    }

    .ddp-view-data {
      .ddp-ui-edit-button {
        padding-left: 10px;
      }

      &:hover .ddp-ui-edit-button {
        display: block;
        padding-left: 10px;
        background-color: #f6f6f7;
      }

      &.ddp-edit .ddp-ui-edit-button {
        display: block !important;
      }
    }

    .ddp-ui-edit-button {
      .ddp-icon-edit,
      .ddp-icon-del {
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -14px;
        padding: 8px 5px;
        cursor: pointer;
      }

      .ddp-icon-edit:before,
      .ddp-icon-del:before {
        display: inline-block;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        content: '';
      }

      .ddp-icon-edit:before {
        width: 12px;
        height: 12px;
        background-position: -26px -97px;
      }

      .ddp-icon-del:before {
        width: 11px;
        height: 12px;
        background-position: -26px -14px;
      }

      .ddp-icon-edit:hover:before {
        background-position-x: -39px;
      }

      .ddp-icon-del:hover:before {
        background-position-x: -50px;
      }
    }

    .ddp-view-data.ddp-edit .ddp-ui-edit-button .ddp-link-cancel {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -8px;
      padding: 0 0 0 10px;
      color: #90969f;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
    }
  }
}

/*ul.ddp-list-rule2.ddp-insert-step li:after {display:inline-block; position:absolute; top:0; left:0; right:0;bottom:0; content:''; z-index:50}*/

.ddp-wrap-rulecontents {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  vertical-align: top;
  overflow: auto;

  &.ddp-inherit {
    overflow: inherit;
  }
}

ul.ddp-list-rule2 li {
  .ddp-ui-edit-button .ddp-wrap-more {
    display: inline-block;
    position: relative;

    .ddp-icon-more {
      display: inline-block;
      padding: 8px;

      &:before {
        display: inline-block;
        content: '';
        width: 2px;
        height: 9px;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        background-position: -6px -164px;
      }

      &:hover:before {
        background-position: -9px -164px;
      }
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      white-space: nowrap;

      li {
        padding: 0;
      }
    }
  }

  &.ddp-selected:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #9ca2cc;
    content: '';
  }

  &.ddp-disabled:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    cursor: no-drop;
    content: '';
    z-index: 1;
  }

  &.ddp-disabled2:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    cursor: no-drop;
    content: '';
    z-index: 1;
    cursor: pointer;
  }

  &.ddp-disabled .ddp-wrap-line-add {
    display: none;
  }

  &.ddp-disabled2 .ddp-wrap-line-add {
    display: none;
    display: block;
  }
}

.ddp-type-rulepop {
  position: fixed;
  z-index: 130;
  width: 160px;
}

.ddp-wrap-rulecontents {
  &.ddp-selected {
    .ddp-wrap-switch-editor {
      display: block;
    }

    .ddp-wrap-switch-builder {
      display: none;
    }
  }

  .ddp-wrap-switch-builder {
    display: block;
    height: 100%;
  }
}

.ddp-box-contents {
  position: relative;
  height: 100%;
  padding: 0 15px 0 15px;

  .ddp-ui-title {
    padding-bottom: 5px;
    color: #4b515b;
    font-size: 11px;
  }

  .ddp-box-command {
    float: left;
    width: 260px;
    padding: 0 35px 6px 0;
    box-sizing: border-box;
    border-right: 1px solid #e3e3e7;

    &.ddp-box-full {
      width: 100%;
      padding-right: 0;
      border-right: none;
    }
  }

  .ddp-wrap-part {
    height: 100%;
    white-space: nowrap;
    word-wrap: normal;

    .ddp-box-part {
      display: inline-block;
      position: relative;
      height: 100%;
      padding: 0 15px;
      width: 220px;
      vertical-align: top;

      &:first-of-type {
        padding-left: 30px;
      }

      &.ddp-box-s {
        width: 170px;
      }

      &.ddp-editor {
        width: inherit;

        input {
          width: 278px;
          float: left;
        }

        .ddp-input-selectbox {
          float: left;
        }
      }

      .ddp-btn-bg {
        display: inline-block;
        margin-left: 7px;
        padding: 8px 12px;
        vertical-align: middle;
      }
    }
  }
}

.ddp-wrap-addrule {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &.ddp-split {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: inherit;
  }

  .ddp-box-top {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 180px;

    &.ddp-split {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .ddp-box-title {
    padding: 15px 15px 5px 15px;
    color: #4b515b;
    font-size: 12px;
    font-weight: bold;

    a.ddp-link-switch {
      margin-left: 10px;
      font-size: 11px;
      font-weight: normal;
      color: #727ab7;
      text-decoration: underline;
    }

    .ddp-ui-sub-title {
      position: relative;
      padding: 0 0 0 9px;
      margin-left: 9px;
      color: #b7b9c2;
      font-size: 12px;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        height: 10px;
        border-left: 1px solid #b7b9c2;
      }
    }

    em.ddp-icon-essential {
      margin-left: 3px;
      vertical-align: top;
    }
  }
}

.ddp-wrap-rulecontents .ddp-box-title {
  font-weight: normal;
}

.ddp-wrap-addrule {
  .ddp-ui-buttons {
    position: absolute;
    top: 8px;
    right: 7px;
    z-index: 10;

    [class*='ddp-btn'] {
      float: left;
      margin-left: 4px;
    }
  }

  ul.ddp-list-command li {
    &.ddp-data-select strong {
      color: #656eb2;
    }

    a {
      em.ddp-ui-round {
        display: inline-block;
        padding: 2px 2px 1px 2px;
        margin-left: 2px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #656eb2;
        color: #656eb2;
        font-size: 9px;
      }

      span {
        &.ddp-ui-type {
          display: block;
          color: #656eb2;
          font-size: 12px;
        }

        &.ddp-ui-code {
          display: block;
          padding: 5px 0 0 29px;
          color: #b7b9c2;
          font-size: 12px;
        }
      }
    }
  }
}

.ddp-box-contents .ddp-box-part {
  .ddp-box-title {
    position: relative;
    padding: 0 0 5px 0;
    z-index: 2;
  }

  .ddp-list-buttons {
    width: 100%;

    li {
      width: 50%;
      min-width: auto;
      box-sizing: border-box;
    }
  }

  &:hover .ddp-ui-info {
    display: block;
  }

  .ddp-ui-info {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    &:hover .ddp-box-info {
      display: block;
    }

    .ddp-box-info {
      display: none;
      position: absolute;
      bottom: 20px;
      right: -10px;
      padding: 10px;
      width: 253px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #aeb0bb;
      white-space: initial;
      box-sizing: border-box;
      z-index: 10;
      -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        bottom: -6px;
        right: 10px;
        width: 10px;
        height: 6px;
        background: url(/assets/bi/images/icon_infobox.png) no-repeat;
      }
    }
  }

  .ddp-wrap-boxwrap {
    &.ddp-selected .ddp-box-info {
      display: block;
    }

    .ddp-box-info {
      display: none;
      position: absolute;
      bottom: 103%;
      top: inherit;
      padding: 10px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #aeb0bb;
      white-space: initial;
      box-sizing: border-box;
      -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
      box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

      /* drop shadow */
    }
  }
}

.ddp-wrap-addrule .ddp-wrap-box-info {
  margin-top: -20px;
  padding-top: 20px;
  box-sizing: Border-box;
}

.ddp-wrap-box-info {
  position: relative;
  height: 100%;

  &.ddp-selected .ddp-box-info {
    display: block;
  }

  .ddp-box-info {
    display: none;
    position: absolute;
    top: inherit;
    bottom: 103%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #aeb0bb;
    white-space: initial;
    box-sizing: border-box;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    .ddp-box-info-in {
      padding: 10px;
      max-height: 350px;
      overflow: auto;
    }

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      bottom: -6px;
      left: 10px;
      width: 10px;
      height: 6px;
      background: url(/assets/bi/images/icon_infobox.png) no-repeat;
    }
  }
}

.ddp-wrap-input-info {
  position: relative;

  .ddp-list-selectbox {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    max-height: 160px;
    padding: 7px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    overflow-y: auto;
    z-index: 1;
    box-sizing: border-box;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    li {
      a {
        display: block;
        padding: 5px 10px;

        &:hover {
          background-color: #f6f6f7;
        }
      }

      span.ddp-noresult {
        display: block;
        padding: 5px 10px;
        color: #b7b9c2;
        cursor: default;
        font-size: 13px;
      }
    }
  }

  &.ddp-selected .ddp-box-info {
    display: block;
  }

  .ddp-box-info {
    display: none;
    position: absolute;
    top: inherit;
    bottom: 34px;
    width: 300px;
    max-height: 350px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #aeb0bb;
    white-space: initial;
    box-sizing: border-box;
    z-index: 5;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    .ddp-box-info-in {
      padding: 10px;
      max-height: 350px;
      overflow: auto;
    }

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      bottom: -6px;
      left: 10px;
      width: 10px;
      height: 6px;
      background: url(/assets/bi/images/icon_infobox.png) no-repeat;
    }
  }
}

.ddp-box-info {
  .ddp-input-title {
    display: block;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
  }

  strong.ddp-ex {
    display: block;
    padding: 20px 0 10px 0;
  }
}

.ddp-box-contents .ddp-box-title {
  .ddp-ui-open-view {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .ddp-icon-view {
      display: inline-block;
      position: relative;
      top: -2px;
      width: 17px;
      height: 17px;
      cursor: pointer;

      em {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 9px;
        height: 5px;
        margin: -3px 0 0 -5px;
        background: url(/assets/bi/images/icon_drop2.png) no-repeat;
      }
    }

    &.ddp-selected {
      .ddp-icon-view {
        background-color: #e7e7ea;

        em {
          background-position: left -6px;
        }
      }

      + .ddp-box-select-check {
        display: block;
      }
    }

    .ddp-ui-selected-option {
      position: relative;
      z-index: 1;
    }
  }

  ul.ddp-box-select-check {
    display: none;
    position: absolute;
    bottom: 109%;
    left: 0;
    right: -70px;
    width: 282px;
    z-index: 15;
    white-space: initial;

    li {
      &.ddp-txt-view-name {
        display: block;
        padding: 4px 20px 10px 20px;
        color: #b7b9c2;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
      }

      a {
        span.ddp-data-pattern {
          display: block;

          em:last-child {
            position: relative;
            padding: 0 7px;
            margin-left: 7px;
            color: #444;
            font-size: 12px;

            &:before {
              display: inline-block;
              content: '';
              position: absolute;
              top: 2px;
              left: 0;
              height: 10px;
              border-left: 1px solid #a8a8a8;
            }
          }
        }

        .ddp-data-det {
          display: block;
          padding-top: 10px;
          color: #9c9c9c;
          font-size: 12px;
          line-height: 1.5em;
        }
      }

      &.ddp-selected a em.ddp-icon-check {
        top: 7px;
        margin-top: 0;
      }

      a:hover {
        background-color: #f6f6f7;
        color: #444;
      }
    }
  }
}

.ddp-wrap-checkaction {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 214px;
  background-color: #fff;
  z-index: 1;
}

.ddp-ui-checkaction {
  padding: 13px 0 0 14px;

  .ddp-wrap-action {
    display: inline-block;
  }

  .ddp-type-selectbox {
    display: inline-block;
    width: 275px;
    margin-right: 5px;
    box-sizing: border-box;
  }
}

.ddp-pop-bar {
  position: relative;
  padding: 14px 0;
  background-color: #4b515b;
  text-align: center;

  span.ddp-txt-info {
    color: #fff;
    font-size: 14px;
  }

  a {
    &.ddp-btn-bar {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 5px;
      padding: 6px 0;
      width: 85px;
      border-radius: 2px;
      border: 1px solid #90969f;
      color: #d0d1d8;
      font-size: 12px;
      text-align: center;

      &:hover {
        border: 1px solid #fff;
        color: #fff;
      }
    }

    &.ddp-btn-close {
      position: absolute;
      top: 15px;
      left: 20px;
      color: #d0d1d8;
      font-size: 14px;
      vertical-align: middle;

      em.ddp-icon-close {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 7px;
        background: url(/assets/bi/images/btn_popclose.png) no-repeat;
        background-position: left -84px;
        vertical-align: middle;
      }
    }
  }
}

.ddp-wrap-flow .ddp-wrap-addrule {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 207px;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 10;
}

/****	데이터셋 팝업	  *****/

.ddp-ui-popup-contents {
  position: relative;
  height: 100%;
  margin-top: -120px;
  padding: 120px 50px 0 50px;
  box-sizing: border-box;

  &.ddp-info {
    padding-top: 134px;
  }

  &.ddp-info2 {
    padding-top: 168px;
  }

  .ddp-box-message {
    position: absolute;
    top: 84px;
    left: 50px;
    right: 50px;
  }

  .ddp-popup-viewtable {
    position: absolute;
    top: 170px;
    left: 50px;
    right: 50px;
    bottom: 90px;

    .ddp-box-add-link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .ddp-box-viewtable {
    height: 100%;
    box-sizing: Border-box;
  }
}

.ddp-box-viewtable.ddp-viewtable-type {
  .ddp-ui-gridbody {
    bottom: 35px;
  }

  .ddp-box-add-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 0 13px 0;
    text-align: center;
    border: none;
    border-top: 1px solid #d0d1d8;
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 3px rgba(75, 81, 91, 0.1);

    /* inner shadow */
    -webkit-box-shadow: inset 0 1px 3px rgba(75, 81, 91, 0.1);

    /* inner shadow */
  }
}

/****	flow  *****/

.ddp-wrap-flow2 {
  .ddp-ui-number {
    padding: 15px 14px;
    box-sizing: border-box;

    .ddp-data-number {
      display: inline-block;
      margin-right: 20px;
      font-size: 12px;
      color: #4b515b;

      .ddp-data-num {
        color: #4b515b !important;
      }
    }

    a.ddp-link-set {
      position: relative;
      top: 2px;
      margin-right: 20px;
      color: #b7b9c3;
      font-size: 12px;

      em.ddp-icon-add {
        position: relative;
        top: -1px;
        margin-right: 5px;
        width: 9px;
        height: 9px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -20px top;
      }

      &:hover {
        color: #4b515b;
        text-decoration: underline;

        em.ddp-icon-add {
          background-position: -10px top;
        }
      }
    }
  }

  .ddp-split-chart {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
  }

  .ddp-empty-flow {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -156px;
    text-align: center;

    .ddp-img-flow {
      display: inline-block;
      padding-bottom: 25px;
      width: 193px;
      height: 150px;
      background: url(/assets/bi/images/img_flow.png) no-repeat;
    }

    .ddp-empty-title {
      display: block;
      padding-bottom: 20px;
      color: #d0d1d8;
      font-size: 28px;
      text-align: center;
    }

    .ddp-empty-det {
      display: block;
      padding-bottom: 25px;
      color: #b7b9c2;
      font-size: 14px;
      text-align: center;
    }
  }

  &.ddp-type {
    .ddp-box-flowdetail {
      position: relative;
      float: left;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      border: none;

      .ddp-no-data {
        display: table;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f6f6f7;
        z-index: 2;

        .ddp-no-data-in {
          display: table-cell;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          text-align: center;

          .ddp-btn-solid {
            width: 166px;
          }

          .ddp-txt-data {
            display: block;
            padding-bottom: 14px;
            color: #4b515b;
            font-size: 16px;
          }
        }
      }
    }

    &.ddp-full .ddp-split-chart {
      width: 100% !important;
    }
  }
}

.ddp-box-flowdetail {
  position: absolute;
  top: 5px;
  right: 6px;
  bottom: 5px;
  width: 288px;
  border: 1px solid #90969f;
  background-color: #fff;

  .ddp-box-in {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 35px;
    overflow-y: auto;
  }

  a.ddp-btn-popup-close {
    position: absolute;
    top: 19px;
    right: 15px;
    z-index: 1;
  }

  .type-title {
    .ddp-data-filename {
      padding-right: 0;
    }

    .ddp-ui-top-button {
      position: relative;
      float: right;
      padding: 19px 0 0 0;
      z-index: 1;

      .ddp-btn-popup-close {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 15px 0 0;
        float: left;
      }

      .ddp-ui-detail {
        position: relative;
        top: -1px;
        float: left;
        margin: 0 10px 0 10px;

        .ddp-icon-detail {
          display: inline-block;
          width: 17px;
          height: 18px;
          background: url(/assets/bi/images/icon_detail2.png) no-repeat;
        }

        .ddp-ui-tooltip-info {
          position: absolute;
          top: 100%;
          right: -10px;
          left: inherit;
          margin-top: 6px;
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }

        .ddp-ui-tooltip-info em.ddp-icon-view-top {
          right: 17px;
          left: inherit;
        }
      }
    }
  }

  .ddp-data-filename {
    position: relative;
    padding: 0 40px 0 40px;
    margin-top: 13px;
    box-sizing: border-box;

    .ddp-icon-file-svg {
      position: absolute;
      top: 3px;
      left: 10px;
      margin-right: 10px;
    }

    .ddp-ui-textarea {
      display: none;
      background-color: #fff;
      z-index: 1;
      height: 35px;
    }

    .ddp-ui-input {
      .ddp-btn-check {
        display: inline-block;
        position: absolute;
        top: 2px;
        bottom: 2px;
        right: 2px;
        width: 24px;
        height: inherit;
        border-radius: 3px;
        background-color: #d0d1d8;
        cursor: pointer;

        &:hover {
          background-color: #9ca2cc;
        }
      }

      display: none;
      position: relative;
      z-index: 1;
    }

    &.ddp-selected {
      .ddp-ui-textarea {
        display: block;
      }

      .ddp-ui-input {
        display: block;
        position: relative;
        left: -4px;
        padding-right: 30px;
        border: 1px solid #b7b9c2;
        border-radius: 3px;
        overflow: hidden;
      }
    }

    .ddp-ui-input input {
      position: relative;
      padding: 4px 0 4px 8px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      border: none;
      box-sizing: border-box;
    }

    &.ddp-selected {
      .ddp-ui-input textarea {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1px 0 2px 5px;
        font-family: sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        border: none;
        background-color: #f6f6f7;
        box-sizing: border-box;
      }

      &:hover .ddp-link-edit {
        display: none;
      }
    }

    em {
      &.ddp-icon-dbdata,
      &.ddp-icon-wrangled-m {
        position: absolute;
        top: 0;
        left: 18px;
      }

      &.ddp-icon-file-sql {
        position: absolute;
        top: -2px;
        left: 18px;
      }
    }

    span.ddp-txt-filename {
      display: block;
      position: relative;
      max-width: 100%;
      padding: 5px 23px 3px 5px;
      font-size: 16px;
      color: #35393f;
      font-weight: bold;
      line-height: 21px;
      font-family: sans-serif;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      word-break: break-all;
    }

    &.ddp-selected span.ddp-txt-filename {
      display: none;
    }

    span.ddp-txt-filename {
      .ddp-link-edit {
        display: none;
        position: absolute;
        top: 6px;
        right: 0;
        padding: 3px 5px;
        vertical-align: top;
        font-size: 0;
      }

      &:hover {
        background-color: #f6f6f7;

        .ddp-link-edit {
          display: inline-block;

          .ddp-icon-edit2 {
            background-position: -11px top;
          }
        }
      }
    }

    .ddp-icon-db {
      position: absolute;
      top: -4px;
      left: 10px;
      display: inline-block;
      width: 24px;
      height: 24px;
    }
  }

  .ddp-data-explain {
    position: relative;
    margin: 5px 10px 0 10px;

    .ddp-txt-explain {
      display: inline-block;
      position: relative;
      max-width: 100%;
      padding: 4px 23px 3px 6px;
      margin: -2px 0;
      line-height: 17px;
      color: #b7b9c2;
      font-size: 12px;
      font-family: sans-serif;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;
      word-break: break-all;
    }

    .ddp-tag-target {
      display: inline-block;
      padding: 2px 3px;
      margin: 4px 0 0 6px;
      background-color: #f6f6f7;
      color: #90969f;
      font-size: 10px;
    }

    .ddp-txt-explain:hover {
      background-color: #f6f6f7;
    }

    &.ddp-selected {
      .ddp-ui-input {
        display: block;
        position: relative;
        padding-right: 23px;
        margin: -1px 0 0 0;
        border-radius: 3px;
        border: 1px solid #b7b9c2;
        overflow: hidden;
        box-sizing: border-box;

        textarea {
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 5px 5px;
          height: 51px;
          border: none;
          font-size: 12px;
          line-height: 17px;
          font-family: sans-serif;
        }
      }

      .ddp-txt-explain {
        display: none;
      }
    }

    .ddp-ui-input {
      display: none;
      position: relative;

      .ddp-btn-check {
        display: inline-block;
        position: absolute;
        bottom: 2px;
        right: 2px;
        top: 2px;
        height: inherit;
        border-radius: 3px;
        background-color: #d0d1d8;

        &:hover {
          background-color: #9ca2cc;
        }
      }

      input {
        width: 100%;
        padding: 8px 31px 8px 8px;
        border: none;
        background: #f6f6f7;
        box-sizing: border-box;
      }
    }

    .ddp-link-edit {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      padding: 3px 5px;
      z-index: 1;
    }

    &.ddp-selected .ddp-link-edit {
      display: none;
    }
  }

  .ddp-txt-explain:hover .ddp-link-edit {
    display: block;

    .ddp-icon-edit2 {
      background-position: -11px top;
    }
  }

  .ddp-ui-databuttons {
    padding: 10px 10px 0 10px;
    text-align: center;

    .ddp-databuttons {
      margin: 0 -37px 0 -2px;

      [class*='ddp-col-'] {
        padding: 0 2px;
      }

      [class*='ddp-btn-'] {
        font-size: 13px;
        padding: 6px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ddp-btn-buttons4 {
      &.ddp-full {
        width: 100%;
        height: 30px;
        box-sizing: border-box;
      }

      display: inline-block;
      width: 46%;
      margin: 0 2px;

      &.ddp-disabled {
        border: 1px solid #e7e7ea;
        color: #d0d1d8;
        cursor: no-drop;
      }

      em.ddp-icon-window {
        margin-left: 5px;
      }
    }

    &.ddp-type {
      position: relative;
      padding-right: 45px;

      .ddp-more {
        position: absolute;
        bottom: 0;
        right: 10px;

        .ddp-btn-more {
          display: inline-block;
          position: relative;
          width: 30px;
          height: 30px;
          border: 1px solid #d0d1d8;
          border-radius: 2px;
          box-sizing: border-box;

          &:hover {
            border: 1px solid #b7b9c2;
          }

          &:before {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -2px 0 0 -8px;
            width: 15px;
            height: 3px;
            background: url(/assets/bi/images/icon_more.png) no-repeat;
            background-position: left -4px;
            transform: rotate(90deg);
            content: '';
          }
        }

        .ddp-wrap-popup2 {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
        }

        &.ddp-selected .ddp-wrap-popup2 {
          display: block;

          .ddp-icon-drop-re {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .ddp-ui-preview {
    padding: 20px 0 0 0;

    .ddp-txt-title {
      padding: 0 0 5px 0;
      color: #4b515b;
      font-size: 12px;
    }

    .ddp-box-preview {
      height: 158px;
      margin-bottom: 10px;
      border: 1px solid #e7e7ea;
      overflow: auto;
      color: #4b515b;
      font-size: 12px;
      line-height: 18px;
      background-color: #fff;
      box-sizing: border-box;

      .ddp-font-size {
        font-size: 14px;
        padding: 5px;
      }
    }
  }

  .ddp-ui-queryview {
    .ddp-txt-title {
      padding: 5px 0 5px 0;
      color: #b7bac1;
      font-size: 13px;
    }

    .ddp-box-preview {
      height: 158px;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #e7e7ea;
      overflow: auto;
      color: #4b515b;
      font-size: 12px;
      line-height: 18px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }

  .ddp-ui-preview.ddp-type-rule {
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
    border-top: 1px solid #e7e7ea;

    .ddp-box-preview {
      padding: 0;
      margin-bottom: 5px;
    }

    ul.ddp-list-rule2 {
      padding: 0;

      li {
        padding-right: 10px;
        cursor: default;

        &:hover {
          background: none;
        }
      }
    }

    .ddp-box-preview ul.ddp-list-rule2 {
      padding: 10px;

      li {
        padding-right: 0;

        + li {
          padding: 5px 0 0 0;
        }
      }
    }

    .ddp-data-rule {
      color: #b7bac2;
      font-size: 12px;
    }
  }

  dl.ddp-dl-detail {
    &.type-part {
      margin-top: 10px;
    }

    padding: 3px 0;
    clear: both;

    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    dt {
      float: left;
      padding: 0 10px 0 0;
      width: 95px;
      font-size: 13px;
      color: #b7bac1;
      box-sizing: border-box;

      &:before {
        display: inline-block;
        content: '';
      }
    }

    dd {
      display: block;
      padding-left: 95px;
      position: relative;
      font-size: 13px;
      color: #4b515b;
      box-sizing: border-box;

      .ddp-wrap-alart {
        display: inline-block;
        position: relative;
        margin-left: 6px;
        cursor: pointer;

        .ddp-ui-tooltip-info {
          display: none;
          max-width: 170px;
          min-width: 100px;
          position: absolute;
          left: inherit;
          top: 23px;
          right: -73px;
          text-align: center;
          white-space: normal;

          em.ddp-icon-view-top {
            left: inherit;
            right: 79px;
          }
        }
      }

      .ddp-ui-data {
        position: relative;
      }

      span.ddp-txt-data {
        display: inline-block;
        padding-right: 45px;
        position: relative;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        box-sizing: border-box;
      }

      .ddp-ui-data.ddp-selected span.ddp-txt-data {
        white-space: normal;
      }

      a.ddp-link-detailview {
        position: absolute;
        top: 0;
        right: 0;
        color: #90969f;
        font-size: 12px;
      }

      span.ddp-data-num {
        text-decoration: underline;
        cursor: pointer;
      }

      .ddp-wrap-popup2 {
        position: absolute;
        top: 20px;
        left: 90px;
        max-width: 155px;

        ul li a {
          padding-left: 34px;

          em.ddp-icon-flow {
            position: absolute;
            top: 6px;
            left: 12px;
          }
        }
      }
    }

    &.type-date {
      padding-left: 10px;

      dd .ddp-data-by {
        color: #90969f;
        display: block;
      }
    }
  }

  .ddp-wrap-part {
    margin: 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7ea;

    &.ddp-bg {
      background-color: #f6f6f7;
      margin: 20px 0 0 0;
      padding: 0 10px 10px 10px;

      .ddp-ui-preview {
        padding-top: 10px;
      }
    }

    &.ddp-type {
      padding-top: 15px;
    }
  }

  .ddp-data-type {
    color: #b7bac2;
    font-size: 12px;
  }

  .ddp-wrap-date {
    padding: 10px 0;
  }

  dl.ddp-dl-date {
    padding: 5px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    dt {
      position: relative;
      width: inherit;
      padding-right: 8px;
      font-weight: normal;
      color: #b7bac2;
    }

    dd {
      width: inherit;
      float: inherit;
      padding-left: 0;
      margin-left: 0;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
      color: #b7b9c2;
      font-size: 13px;
      font-weight: 300;
    }
  }

  .ddp-ui-footbutton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    text-align: center;
  }

  .ddp-data-detail {
    padding-bottom: 5px;

    .ddp-label-detail {
      padding-bottom: 10px;
      color: #b7bac1;
      font-size: 13px;
    }

    dl.ddp-dl-detail2 {
      padding-top: 10px;

      &:first-of-type {
        padding-top: 0;
      }

      dt {
        float: Left;
        padding-right: 5px;
        color: #4b515b;
        font-size: 12px;
      }

      dd {
        display: block;
        color: #4b515b;
        font-size: 12px;
        font-weight: 300;
        overflow: hidden;
      }
    }
  }
}

/**/

/*.ddp-box-flowdetail dl.ddp-dl-detail dd .ddp-wrap-alart:hover .ddp-ui-tooltip-info {display:block;}*/

a.ddp-type-link2 {
  color: #b7b9c2;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    color: #4b515b;

    em.ddp-icon-delete2 {
      opacity: 1;
    }
  }
}

/****	상세보기  *****/

table.ddp-table-detail tbody tr td {
  .ddp-box-sub {
    width: 710px;
  }

  .ddp-txt-cut {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .ddp-data-form {
    display: block;
    padding-top: 5px;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .ddp-wrap-edit3:last-of-type {
    margin-bottom: 13px;
  }

  label.ddp-label-type.ddp-normal {
    display: block;
    padding-bottom: 13px;
  }

  .ddp-wrap-box {
    position: relative;
    border: 1px solid #d0d1d8;

    a.ddp-btn-detail {
      display: inline-block;
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid #d0d1d8;
      box-sizing: border-box;

      .ddp-icon-searchview2 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
      }
    }

    .ddp-form-box {
      overflow: hidden;
      border: none;
    }
  }

  .ddp-form-box {
    position: relative;
    border: 1px solid #d0d1d8;
    max-width: 100%;
    height: 160px;
    overflow: auto;

    &.ddp-hidden {
      overflow: hidden;
    }

    a.ddp-btn-detail {
      display: inline-block;
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid #d0d1d8;
      box-sizing: border-box;

      .ddp-icon-searchview2 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
      }

      &:hover {
        background-color: #f6f6f7;
      }
    }
  }

  .ddp-data-detail {
    .ddp-link a.ddp-link-url2 {
      display: inline-block;
      width: 710px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }

    span.ddp-txt-detail {
      display: inline-block;
      position: relative;
    }
  }

  .ddp-ui-edit-option .ddp-data-detail span.ddp-txt-detail a.ddp-link-detail {
    top: -1px;
  }

  .ddp-wrap-edit3 .ddp-data-detail span.ddp-txt-detail a.ddp-link-detail {
    top: 4px;
  }

  .ddp-data-detail {
    span.ddp-txt-detail {
      a.ddp-link-detail {
        display: none;
        position: absolute;
        top: -1px;
        right: 3px;
        color: #90969f;
        font-size: 12px;
      }

      &.ddp-detail {
        max-width: 710px;
        padding-right: 45px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        box-sizing: border-box;

        a.ddp-link-detail {
          display: block;
        }
      }
    }

    &.ddp-selected span.ddp-txt-detail {
      white-space: normal;
    }
  }

  .ddp-wrap-alart {
    position: relative;
    top: -1px;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    cursor: pointer;

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    .ddp-ui-tooltip-info {
      display: none;
      top: 21px;
      left: -60px;

      .ddp-icon-view-top {
        left: 68px;
        right: inherit;
      }
    }
  }

  a {
    &.ddp-link-url {
      text-decoration: underline;
      font-style: italic;
    }

    &.ddp-link-url2 {
      text-decoration: underline;
      color: #4c92e0;
    }
  }

  &.ddp-link-url a {
    text-decoration: underline;
    color: #4c92e0;
  }

  a.ddp-btn-pop.ddp-type {
    min-width: 178px;
    border: 1px solid #90969f;
    background-color: #90969f;

    &:hover {
      background-color: #4b515b;
    }
  }

  .ddp-data-none {
    color: #b7b9c2;
    font-size: 13px;
  }

  .ddp-ui-button {
    padding-top: 20px;
  }

  .ddp-wrap-edit3.ddp-type {
    .ddp-label-type {
      font-weight: bold;
      font-size: 12px;
    }

    &:first-of-type {
      margin-top: -8px;
    }
  }

  .ddp-link-window {
    position: relative;
    padding-right: 15px;
    color: $primary;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      display: none;
      position: absolute;
      top: 1px;
      right: 0;
      width: 12px;
      height: 10px;
      background: url(/assets/bi/images/icon_window.png) no-repeat;
      content: '';
    }

    &:hover:after {
      display: block;
    }
  }

  .ddp-label-radio.ddp-inline {
    display: inline-block;
  }

  .ddp-box-option-input.ddp-inline {
    display: inline-block;

    &.ddp-only {
      margin-left: 0;
    }
  }

  .ddp-multy-option {
    .ddp-box-option-input input[type='text'] {
      padding: 7px 5px 6px 5px;
      text-align: right;
    }

    .ddp-btn-line-s {
      position: relative;
      top: -7px;
    }

    .ddp-box-option-input span.ddp-txt-input {
      top: 7px;
    }
  }

  .ddp-wrapping {
    display: inline-block;
    position: relative;
    padding-right: 100px;

    .ddp-box-option-input {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -14px;
    }
  }

  .ddp-ui-summary {
    .ddp-data-txt {
      display: block;
      padding-bottom: 8px;
      color: #4b515b;
      font-size: 13px;

      .ddp-box-btn-r {
        margin: -4px 0 -4px 5px;
        vertical-align: middle;
      }
    }

    ul.ddp-list-sub li {
      position: relative;
      padding-left: 11px;
      padding-bottom: 10px;
      color: #b7bac1;
      font-size: 12px;

      &:last-of-type {
        padding-bottom: 0;
      }

      .ddp-box-btn-r {
        margin: -4px 0 -4px 5px;
        vertical-align: middle;
      }

      &:before {
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b7bac1;
        content: '';
      }

      a {
        color: #91969e;
        font-size: 12px;
        font-style: italic;

        &.ddp-link-history {
          display: inline-block;
          background-color: #f3f3f4;
          padding: 3px 6px;
          margin: -4px 0;
          border-radius: 2px;

          &:hover {
            background-color: #e7e7ea;
          }
        }
      }
    }
  }

  .ddp-txt-colortype {
    color: #90969f;
  }
}

.ddp-txt-edit {
  position: relative;
  margin-left: -5px;

  .ddp-data-name {
    display: inline-block;
    margin-top: -7px;
    max-width: 800px;
    padding: 5px 23px 5px 5px;
    color: #4b515b;
    font-size: 13px;
    line-height: 18px;
    font-family: sans-serif;
    cursor: pointer;
    box-sizing: border-box;

    &.ddp-data-none {
      color: #b7bac1;
    }

    .ddp-icon-edit2 {
      display: none;
    }

    &:hover .ddp-icon-edit2 {
      display: inline-block;
      position: relative;
      top: -2px;
      background-position: 0 top;
      margin-left: 3px;
    }
  }

  .ddp-wrap-input {
    display: none;
  }

  &.ddp-selected {
    .ddp-data-name {
      display: none;
    }

    .ddp-wrap-input {
      display: inline-block;
      position: relative;
      left: 0;
      padding: 0 23px 0 5px;
      margin-top: -7px;
      min-width: 800px;
      background-color: #e3e3e8;
      box-sizing: border-box;

      .ddp-btn-check {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        height: auto;
      }

      input {
        display: block;
        width: 100%;
        padding: 6px 0 6px 0;
        border: none;
        background: none;
        box-sizing: Border-box;
        font-size: 13px;
        font-family: sans-serif;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      textarea {
        display: block;
        padding: 5px 0;
        width: 100%;
        height: 76px;
        border: none;
        background: none;
        font-family: sans-serif;
        font-size: 13px;
        line-height: 18px;
        box-sizing: border-box;
      }
    }
  }

  .ddp-wrap-input {
    .ddp-txt-error {
      display: none;
    }

    &.ddp-error {
      .ddp-txt-error {
        display: block;
        float: right;
        position: relative;
        top: 5px;
        margin: 0 4px 0 2px;
        vertical-align: middle;

        em.ddp-icon-error-s {
          position: Relative;
          top: 2px;
        }
      }

      .ddp-wrap-edit-input {
        display: block;
        overflow: hidden;
      }
    }
  }
}

.ddp-edit-description {
  position: relative;
  padding-bottom: 23px;

  &.ddp-line:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px dotted #ccc;
    content: '';
  }

  .ddp-box-textarea {
    display: none;
  }

  .ddp-txt-description {
    position: relative;
    top: -3px;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
  }

  .ddp-data-description {
    position: relative;
    padding-bottom: 20px;

    &:hover .ddp-btn-edit {
      display: inline-block;
    }

    .ddp-btn-edit {
      display: none;
      padding: 4px 7px;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 21px;
      color: #91969e;
      font-size: 12px;
      font-style: italic;
      background-color: #f3f3f4;
      box-sizing: border-box;

      .ddp-icon-edit {
        display: inline-block;
        width: 12px;
        height: 13px;
        margin-right: 3px;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        background-position: -39px -96px;
        vertical-align: top;
      }

      &:hover {
        background-color: #e7e7ea;
      }
    }
  }

  &.ddp-edit {
    .ddp-data-description {
      display: none;
    }

    .ddp-box-textarea {
      display: block;
      overflow: hidden;

      textarea {
        display: block;
        width: 100%;
        height: 194px;
        padding: 5px 9px;
        line-height: 18px;
        border: 1px solid #d0d1d8;
        border-radius: 2px;
        box-sizing: border-box;
      }

      .ddp-textarea-buttons {
        padding: 4px 0 0 0;

        .ddp-box-btn {
          float: Left;
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 4px;
          border-radius: 2px;
          background-color: #d0d1d8;

          &:hover {
            background-color: #9ca2cc;
          }

          em.ddp-icon-cancel {
            display: inline-block;
            width: 9px;
            height: 9px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -5px 0 0 -5px;
            background: url(/assets/bi/images/btn_close.png) no-repeat;
            background-position: -20px -82px;
          }

          .ddp-icon-check {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -4px 0 0 -6px;
            width: 11px;
            height: 8px;
            background: url(/assets/bi/images/icon_select2.png) no-repeat;
            background-position: -27px top;
          }
        }
      }
    }
  }
}

.ddp-wrap-table-detail {
  .ddp-wrap-edit3.ddp-type .ddp-label-type.ddp-normal {
    font-size: 16px;
  }

  position: relative;
  border-top: 1px dotted #ccc;
  margin: 40px -20px 0 -20px;
  padding: 40px 20px 0 20px;

  &.ddp-last {
    padding-bottom: 40px;

    .ddp-detail-setting .ddp-wrap-down {
      margin-bottom: -40px;
    }
  }

  .ddp-type-plan {
    .ddp-label-detail {
      display: inline-block;
      padding-right: 15px;
      line-height: 30px;

      a.ddp-btn-option2 {
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .ddp-btn-pop {
      min-width: 140px;
    }
  }

  .ddp-btn-pop em.ddp-icon-email {
    display: inline-block;
    width: 14px;
    height: 12px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_mail.png) no-repeat;
  }
}

.ddp-wrap-detail-sub {
  margin-top: 43px;

  .ddp-table-label {
    position: relative;
    margin-left: 27px;
    padding-left: 5px;
    font-size: 14px;

    &:before {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
      height: 12px;
      border-left: 2px solid #4b515b;
      content: '';
    }
  }

  table.ddp-table-detail {
    margin-top: 11px !important;

    tr {
      th,
      td {
        padding: 4px;
      }
    }
  }
}

table.ddp-table-detail {
  tbody tr td {
    .ddp-data-list {
      display: inline-block;
      position: relative;
      padding: 0 20px;

      .ddp-data-name {
        &.ddp-hover {
          cursor: pointer;
        }

        .ddp-box-layout4 {
          display: none;
          position: absolute;
          top: 18px;
          right: -30px;
        }

        &.ddp-hover:hover .ddp-box-layout4 {
          display: block;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        height: 13px;
        border-left: 1px solid #d0d1d7;
        content: '';
      }

      &:first-of-type:before {
        display: none;
      }

      a.ddp-link-member {
        font-weight: 300;
      }

      .ddp-box-power {
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .ddp-btn-link a.ddp-btn-pop {
      margin: 10px 0 0 0;
    }
  }

  .ddp-ui-dropdown {
    margin-top: -7px;
    margin-bottom: 20px;
    background-color: #f6f6f7;

    .ddp-data {
      font-size: 13px;
      color: #4b515b;
      font-weight: bold;
    }

    .ddp-label-dropdown {
      display: block;
      padding: 10px 15px;
      font-weight: 300;
      cursor: pointer;

      .ddp-wrap-link-detail2 {
        padding-right: 5px;
      }

      em.ddp-icon-drop {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 13px;
        height: 8px;
        margin-right: 10px;
        background: url(/assets/bi/images/icon_arrow.png) no-repeat;
        vertical-align: middle;
        transform: rotate(180deg);
      }
    }

    &.ddp-selected .ddp-label-dropdown em.ddp-icon-drop {
      transform: rotate(0deg);
    }

    .ddp-label-dropdown .ddp-btn-option2 {
      position: relative;
      top: -1px;
      margin-left: 3px;
    }

    .ddp-det-dropdown {
      display: none;
      padding: 10px 40px;
    }

    &.ddp-selected .ddp-det-dropdown {
      display: block;
    }

    .ddp-det-dropdown .ddp-wrap-link-detail2 a.ddp-link-info.ddp-type:hover {
      padding-right: 17px;
    }
  }

  a.ddp-btn-moretype2 {
    display: inline-block;
    padding: 2px 5px;
    background-color: #f3f3f4;
    border-radius: 2px;
    color: #4c515a;
    font-size: 12px;
    font-style: italic;
  }
}

.ddp-wrap-table-detail a.ddp-btn-moretype2 {
  display: inline-block;
  padding: 2px 5px;
  background-color: #f3f3f4;
  border-radius: 2px;
  color: #4c515a;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
}

table.ddp-table-detail a.ddp-btn-moretype2:hover,
.ddp-wrap-table-detail a.ddp-btn-moretype2:hover {
  background-color: #d0d1d8;
}

table {
  &.ddp-table-detail table.ddp-table-solid {
    margin-top: -7px;
  }

  &.ddp-table-solid {
    margin-bottom: 7px;
    width: 100%;
    table-layout: fixed;
    border-top: 1px solid #b7b9c2;
    border-bottom: 1px solid #b7b9c2;
  }

  &.ddp-table-detail table.ddp-table-solid tbody tr {
    td {
      padding: 10px 22px !important;
      border-top: 1px solid #e7e7ea;
      font-size: 14px;
      cursor: pointer;

      &.ddp-nolist {
        color: #b7b9c3;
        font-size: 12px;
        cursor: default;
      }
    }

    &:hover td {
      &.ddp-nolist {
        background: none;
      }

      background-color: #f2f1f8;
    }
  }

  &.ddp-table-solid tbody tr {
    &:first-of-type tbody tr td {
      border-top: none;
    }

    td {
      .ddp-txt-long {
        display: inline-block;
        position: relative;
        padding-top: 1px;
        margin-top: -1px;
        max-width: 100%;
        line-height: 21px;
        font-size: 14px;
        height: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: normal;
        box-sizing: border-box;

        .ddp-data-long {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .ddp-tag-global.type-tag {
          float: right;
          position: relative;
          margin-top: -1px;
          right: 0;
          margin-left: 5px;
          width: auto;
        }
      }

      span.ddp-txt-colortype {
        color: #b7b9c2;
        font-size: 14px;
      }

      .ddp-txt-long {
        &.ddp-global {
          padding-right: 66px;
        }

        &.ddp-connection {
          padding-right: 101px;

          .ddp-tag-global {
            width: auto;
          }
        }
      }

      .ddp-tag-global {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 20px;
        padding: 2px 2px 3px 2px;
        color: $primary;
        font-size: 11px;
        text-align: center;
        border: 1px solid $primary;
        background: #eff0f7;
        border-radius: 2px;
        box-sizing: border-box;
      }
    }
  }

  tbody tr td .ddp-tag-connect {
    float: right;
    padding: 3px 7px;
    margin-right: -11px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    background-color: $primary;
    vertical-align: middle;
  }
}

.ddp-table-form.ddp-table-type2 tbody tr td .ddp-tag-connect {
  margin-right: 0;
}

table tbody tr td .ddp-tag-connect:before {
  display: inline-block;
  margin-right: 3px;
  width: 11px;
  height: 8px;
  background: url(/assets/bi/images/icon_select2.png) no-repeat;
  background-position: -27px top;
  content: '';
}

.ddp-wrap-table-detail {
  table.ddp-table-form.ddp-table-type3 {
    width: 900px;

    &.ddp-full {
      width: 100%;
    }
  }

  .ddp-ui-table-title {
    display: block;
    position: relative;
    padding-bottom: 10px;
    color: #b7bac1;
    font-size: 13px;
    font-weight: bold;

    span.ddp-title {
      display: inline-block;
      width: 133px;
    }
  }

  .ddp-wrap-linktype {
    display: none;
    position: absolute;
    top: 0;
    left: 133px;
    font-weight: normal;

    .ddp-btn-linktype {
      margin-right: 24px;
    }
  }

  &:hover .ddp-wrap-linktype {
    display: block;
  }
}

/*폼*/

.ddp-wrap-boxwrap {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .ddp-wrap-boxadd {
    margin-bottom: 5px;
  }

  .ddp-box-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.ddp-wrap-boxadd {
  position: relative;
  padding-right: 30px;

  .ddp-ui-buttons {
    position: absolute;
    top: 0;
    right: 0;

    a.ddp-btn-minus {
      display: inline-block;
      position: relative;
      width: 29px;
      height: 28px;
      border: 1px solid transparent;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
        width: 9px;
        height: 1px;
        margin: -1px 0 0 -5px;
      }

      &:hover {
        border: 1px solid #b7b9c2;
        border-radius: 2px;
        background-color: #e7e7ea;
      }
    }
  }
}

.ddp-box-contents .ddp-box-part .ddp-box-title {
  span.ddp-box-title-in {
    position: relative;
    padding-right: 30px;
  }

  a.ddp-btn-plus {
    display: inline-block;
    position: absolute;
    top: -7px;
    right: 0;
    width: 23px;
    height: 23px;
    border: 1px solid transparent;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
      content: '';
      width: 9px;
      height: 9px;
      margin: -5px 0 0 -5px;
      background-position: left -2px;
    }

    &:hover {
      border: 1px solid #b7b9c2;
      border-radius: 2px;
      background-color: #e7e7ea;
    }
  }
}

.ddp-type-multi {
  position: relative;
  padding-right: 17px;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  .ddp-type-selectbox {
    display: inline-block;
    box-sizing: border-box;

    &.ddp-size-s {
      float: left;
      width: 70px;
    }

    &.ddp-size-m {
      float: left;
      width: 125px;
      margin-left: 5px;
    }
  }

  a.ddp-btn-formclose {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    background-position: left -55px;
  }
}

.ddp-type-multy2 [class*='ddp-col-'] {
  padding-left: 5px;

  &:first-of-type {
    padding-left: 0;
  }
}

.ddp-form-label {
  margin-bottom: 3px;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  label.ddp-label-type {
    float: left;
    width: 110px;
    color: #a4aab0;
    font-size: 12px;
    line-height: 31px;
  }

  .ddp-wrap-dropdown,
  .ddp-wrap-inputform {
    float: left;
    width: 100%;
    margin-left: -110px;
    padding-left: 110px;
    box-sizing: border-box;
  }

  .ddp-wrap-dropdown ul.ddp-list-dropdown {
    left: 111px;
    max-height: 150px;
    overflow-y: auto;
  }

  .ddp-box-input {
    float: left;
    width: 100%;
    margin-left: -110px;
    padding-left: 110px;
    box-sizing: border-box;

    .ddp-ui-error {
      left: 110px;
    }
  }
}

ul.ddp-box-select-check {
  padding: 9px 0;
  border: 1px solid #ddd;
  border-radius: 1px;
  background-color: #fff;
  -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */

  li {
    a {
      display: block;
      position: relative;
      padding: 4px 50px 4px 20px;
      color: #4b515b;
      font-size: 13px;

      em.ddp-icon-check {
        display: none;
        position: absolute;
        top: 50%;
        right: 18px;
        width: 11px;
        height: 8px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
      }
    }

    &.ddp-selected a em.ddp-icon-check {
      display: block;
    }

    a:hover {
      em.ddp-icon-check {
        background-position: left top;
      }

      background-color: #ff8b00;
      color: #fff;
    }
  }

  &.ddp-box-select-basic li a {
    padding-right: 20px;
  }
}

/**********************************************************************************
	노트북, 데이터셋 데이터타입 선택
**********************************************************************************/

.ddp-ui-data-select {
  padding: 35px 70px 40px 70px;

  a.ddp-btn-select {
    margin-top: 5px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

a.ddp-btn-select {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 24px 0;
  border-radius: 3px;
  border: 1px solid #b7b9c2;
  text-align: center;
  color: #4b515b;
  font-size: 16px;

  &:hover {
    background-color: #4b515b;
    color: #fff;
  }

  &.ddp-selected {
    color: #fff;
    background-color: #4b515b;
    font-weight: normal;

    em.ddp-icon-check {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 22px;
      width: 14px;
      height: 10px;
      margin-top: -5px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      background-position: left -9px;
    }
  }
}

em {
  &.ddp-icon-boxbtn-data,
  &.ddp-icon-boxbtn-dashboard,
  &.ddp-icon-boxbtn-chart,
  &.ddp-icon-boxbtn-file,
  &.ddp-icon-boxbtn-database,
  &.ddp-icon-boxbtn-ftp,
  &.ddp-icon-boxbtn-file2,
  &.ddp-icon-boxbtn-stream,
  &.ddp-icon-boxbtn-snapshot,
  &.ddp-icon-boxbtn-staging,
  &.ddp-icon-boxbtn-engine,
  &.ddp-icon-boxbtn-druid,
  &.ddp-icon-boxbtn-hive,
  &.ddp-icon-boxbtn-url,
  &.ddp-icon-boxbtn-s3-storage,
  &.ddp-icon-boxbtn-file-multi {
    display: inline-block;
    margin-right: 15px;
    background: url(/assets/bi/images/icon_boxbtn.png) no-repeat;
    vertical-align: middle;
  }
}

.ddp-btn-select:hover em {
  &.ddp-icon-boxbtn-data {
    background-position: -22px top;
  }

  &.ddp-icon-boxbtn-dashboard,
  &.ddp-icon-boxbtn-s3-storage {
    background-position: -22px -25px;
  }

  &.ddp-icon-boxbtn-chart {
    background-position: -29px -49px;
  }

  &.ddp-icon-boxbtn-file {
    background-position: -27px -150px;
  }

  &.ddp-icon-boxbtn-database {
    background-position: -23px -97px;
  }

  &.ddp-icon-boxbtn-ftp {
    background-position: -24px -123px;
  }

  &.ddp-icon-boxbtn-hadoop {
    background-position: left -64px;
  }

  &.ddp-icon-boxbtn-file2 {
    background-position: -27px -150px;
  }

  &.ddp-icon-boxbtn-stream {
    background-position: -25px -173px;
  }

  &.ddp-icon-boxbtn-snapshot {
    background-position: -31px -203px;
  }

  &.ddp-icon-boxbtn-staging {
    background-position: -25px -227px;
  }

  &.ddp-icon-boxbtn-engine,
  &.ddp-icon-boxbtn-druid {
    background-position: -23px -253px;
  }

  &.ddp-icon-boxbtn-hive {
    background-position: -28px -271px;
  }

  &.ddp-icon-boxbtn-url {
    background-position: -23px -300px;
  }

  &.ddp-icon-boxbtn-file-multi {
    background-position: -22px -322px;
  }
}

em.ddp-icon-boxbtn-data,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-data {
  width: 21px;
  height: 24px;
}

em.ddp-icon-boxbtn-dashboard,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-dashboard,
em.ddp-icon-boxbtn-s3-storage,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-s3-storage {
  width: 21px;
  height: 23px;
  background-position: left -25px;
}

em.ddp-icon-boxbtn-chart,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-chart {
  width: 28px;
  height: 22px;
  background-position: left -49px;
}

em.ddp-icon-boxbtn-file,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-file {
  width: 20px;
  height: 24px;
  background-position: left -72px;
}

em.ddp-icon-boxbtn-database,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-database {
  width: 22px;
  height: 25px;
  background-position: left -97px;
}

em.ddp-icon-boxbtn-ftp,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-ftp {
  width: 23px;
  height: 26px;
  background-position: left -123px;
}

em.ddp-icon-boxbtn-file2,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-file2 {
  width: 26px;
  height: 22px;
  background-position: left -150px;
}

em.ddp-icon-boxbtn-stream,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-stream {
  width: 24px;
  height: 29px;
  background-position: left -173px;
}

em.ddp-icon-boxbtn-snapshot,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-snapshot {
  width: 30px;
  height: 23px;
  background-position: left -203px;
}

em.ddp-icon-boxbtn-staging,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-staging {
  width: 24px;
  height: 25px;
  background-position: left -227px;
}

em.ddp-icon-boxbtn-engine,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-engine,
em.ddp-icon-boxbtn-druid,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-druid {
  width: 22px;
  height: 17px;
  background-position: left -253px;
}

em.ddp-icon-boxbtn-hive,
.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-hive {
  width: 28px;
  height: 28px;
  background-position: left -271px;
}

em {
  &.ddp-icon-boxbtn-hadoop {
    display: inline-block;
    margin-right: 15px;
    width: 128px;
    height: 31px;
    background: url(/assets/bi/images/icon_boxbtn2.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-boxbtn-url {
    width: 23px;
    height: 22px;
    background-position: left -299px;
  }
}

.ddp-btn-select.ddp-disabled:hover em.ddp-icon-boxbtn-url {
  width: 23px;
  height: 22px;
  background-position: left -299px;
}

em.ddp-icon-boxbtn-file-multi {
  width: 21px;
  height: 25px;
  background-position: left -322px;
}

.ddp-btn-select.ddp-disabled {
  &:hover em.ddp-icon-boxbtn-file-multi {
    width: 21px;
    height: 25px;
    background-position: left -322px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: no-drop;
  }

  &:hover {
    background: none;
    color: #4b515b;
  }
}

/**********************************************************************************
	노트북 생성
**********************************************************************************/

.ddp-wrap-file-navi {
  border: 1px solid #bec0c8;
  width: 100%;
  height: 424px;
  overflow-y: hidden;
  overflow-x: auto;
}

.ddp-ui-file-navi {
  height: 100%;
  white-space: nowrap;
}

.ddp-box-filelist {
  display: inline-block;
  width: 240px;
  height: 100%;
  border-right: 1px solid #e9e9ec;
  overflow-y: auto;

  ul.ddp-list-file li {
    a {
      display: block;
      position: relative;
      padding: 5px 36px 5px 46px;
      color: #4b515b;
      font-size: 12px;
    }

    &:nth-child(odd) a {
      background-color: #fafafa;
    }

    a:hover,
    &.ddp-selected a {
      background-color: #f0f0f2;
    }

    &.ddp-list-parent a:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      right: 13px;
      width: 4px;
      height: 7px;
      margin-top: -4px;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -19px;
    }

    a {
      .ddp-icon-folder,
      .ddp-icon-workbook,
      .ddp-icon-dashboard3,
      .ddp-icon-chart3 {
        position: absolute;
      }

      .ddp-icon-folder {
        left: 17px;
        top: 50%;
        margin-top: -7px;
      }

      .ddp-icon-workbook {
        left: 18px;
        top: 50%;
        margin-top: -7px;
      }

      .ddp-icon-dashboard3,
      .ddp-icon-chart3 {
        left: 16px;
        top: 50%;
        margin-top: -7px;
      }
    }
  }

  &:last-of-type ul.ddp-list-file li {
    &.ddp-selected a {
      position: relative;
      background-color: #f2f1f8;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid $primary;
        content: '';
      }

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        width: 11px;
        height: 8px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -15px top;
        content: '';
      }
    }

    &.ddp-list-parent.ddp-selected a {
      background-color: #f0f0f2;
      color: #4b515b;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        right: 13px;
        width: 4px;
        height: 7px;
        margin-top: -4px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -19px;
      }

      .ddp-icon-dashboard3 {
        background-position: left -16px;
      }

      .ddp-icon-chart3 {
        background-position: left -28px;
      }
    }
  }
}

/**********************************************************************************
	노트북 상세
**********************************************************************************/

.ddp-wrap-table2 {
  overflow: hidden;

  .ddp-ui-table {
    overflow: hidden;

    table.ddp-table-form.ddp-table-type3 {
      width: 100%;
    }
  }

  .ddp-label-table {
    float: left;
    padding: 7px 0;
    color: #b7bac1;
    font-size: 13px;
  }

  table.ddp-table-form {
    display: block;
    overflow: hidden;
  }
}

.ddp-wrap-datadetail2 {
  margin: 0 -80px;
  padding: 113px 80px 0 80px;
  border-top: 9px solid #e7e7ea;
  box-sizing: border-box;
}

.ddp-wrap-datadetail {
  &.ddp-type {
    height: 100%;
    margin-top: -48px;
    padding: 88px 45px 0 45px;
    box-sizing: border-box;
  }

  padding: 15px 45px;

  table.ddp-table-detail:first-of-type {
    margin-top: 20px;
  }

  .ddp-wrap-detail-sub table.ddp-table-detail {
    margin-bottom: 30px;
  }

  .ddp-info-left {
    display: inline-block;
    float: left;
    width: 350px;
    height: 100%;
    border-right: 1px dotted #ccc;
  }

  &.ddp-type {
    .ddp-ui-datadetail {
      padding: 0;
      height: 100%;
      border-top: none;
      overflow: auto;

      table.ddp-table-detail tbody tr td {
        padding-right: 20px;
      }

      .ddp-data-none {
        color: #b7bac1;
        font-size: 13px;
      }
    }

    .ddp-wrap-rules-flow.ddp-snapshot {
      position: relative;
      right: 0;
      height: auto;
      padding: 50px 35px 50px 0;
    }

    .ddp-ui-rule-flow {
      position: relative;
      top: 0;
      overflow: inherit;
    }

    .ddp-wrap-rules-flow.ddp-snapshot {
      ul.ddp-list-rule2 li {
        padding: 6px 0;
      }

      .ddp-box-title {
        font-size: 16px;
        font-weight: normal;
        vertical-align: middle;
      }

      .ddp-data-num {
        position: relative;
        top: -1px;
      }
    }

    .ddp-form-box {
      height: 350px;
      border: 1px solid #d0d1d8;
    }
  }

  .ddp-info-right {
    display: block;
    padding-left: 50px;
    overflow: hidden;
  }
}

table.ddp-table-detail {
  &.type-sub {
    padding-left: 27px;
  }

  table-layout: fixed;
  width: 100%;
  margin-top: 30px;

  &.ddp-mgt20 {
    margin-top: 20px;
  }

  tbody tr {
    > {
      th,
      td {
        padding: 7px 0;
        vertical-align: top;
        font-weight: normal;
      }
    }

    th {
      color: #91969e;
      font-size: 13px;
      text-align: left;

      &.type-line-height {
        line-height: 30px;
      }

      .ddp-wrap-hover-info {
        display: inline-block;
        position: relative;
        cursor: pointer;

        .ddp-icon-info3 {
          display: inline-block;
          width: 11px;
          height: 11px;
          background: url(/assets/bi/images/icon_que.png) no-repeat;
          background-position: left -12px;
        }

        .ddp-box-layout4 {
          display: none;
          position: absolute;
          bottom: -30px;
          left: 100%;
          margin-left: 5px;
          width: 350px;
        }

        &:hover .ddp-box-layout4 {
          display: block;
        }
      }
    }

    td {
      padding-right: 40px;
      color: #4b515b;
      font-size: 13px;
      word-break: break-all;

      &.ddp-line {
        line-height: 30px;
      }

      > .ddp-box-det {
        width: 500px;
        margin-top: 7px;
        padding: 10px 20px;
        background-color: #f6f6f7;
        border-radius: 2px;

        .ddp-list-det li {
          padding: 6px 0;
          font-size: 13px;

          &:before {
            display: inline-block;
            margin-right: 7px;
            vertical-align: middle;
            content: '•';
          }
        }
      }
    }
  }

  tr td {
    .ddp-data-total {
      padding: 15px 0 10px 0;
      color: #b7b9c2;
    }

    .ddp-btn-pop {
      margin-top: -7px;

      &.ddp-bg-black {
        border: 1px solid #90969f;
        background-color: #90969f;

        &:hover {
          border: 1px solid #4b515b;
          background-color: #4b515b;
        }
      }
    }

    .ddp-apply {
      margin: -6px 0;
      margin-bottom: 6px;

      .ddp-btn-add {
        display: inline-block;
        padding: 7px 14px;
        margin-left: 4px;
        border-radius: 2px;
        color: #fff;
        font-size: 13px;
        background-color: #b7b9c2;

        .ddp-icon-plus {
          display: inline-block;
          margin-right: 6px;
        }

        &:hover {
          background-color: #90969f;
        }
      }

      .ddp-type-search {
        float: left;
        width: 400px;
      }
    }
  }
}

ul.ddp-list-detail {
  border-top: 1px solid #b7b9c2;
  border-bottom: 1px solid #b7b9c2;

  li {
    position: relative;
    padding: 12px 0 8px 25px;
    height: 40px;
    border-bottom: 1px solid #e7e7ea;
    overflow: hidden;
    box-sizing: border-box;

    &:last-of-type {
      border-bottom: none;
    }

    .ddp-wrap-detailinfo {
      float: right;
      padding-left: 10px;
      background-color: #fff;

      .ddp-wrap-detaildata {
        float: left;

        .ddp-data-num {
          margin-left: 15px;
        }
      }

      .ddp-data-by2 {
        float: left;
        margin-left: 40px;
        padding-right: 10px;

        .ddp-icon-by {
          color: #b7bac1;
        }
      }
    }

    .ddp-box-tag2 {
      display: inline-block;
      position: relative;
      float: left;
      margin-right: 7px;
      padding: 1px 3px 0 3px;
      border: 1px solid #656eb2;
      border-radius: 1px;
      line-height: 1.2em;
      color: #656eb2;
      font-size: 10px;
    }

    .ddp-wrap-detailname {
      display: block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      text-overflow: ellipsis;

      span {
        &.ddp-data-detailname {
          display: inline-block;
          color: #4c515a;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: normal;
          vertical-align: middle;

          span.ddp-data-in {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: normal;
          }
        }

        &.ddp-data-summary {
          color: #b7bac1;
          font-size: 14px;
          vertical-align: middle;
        }
      }
    }

    .ddp-data-no {
      float: left;
      width: 45px;
      margin-left: -25px;
      text-align: center;
    }

    .ddp-wrap-detailinfo {
      .ddp-type-lang {
        float: left;
        min-width: 60px;
        color: #4c515a;
      }

      .ddp-data-state {
        float: left;
        min-width: 65px;
      }
    }
  }

  &.ddp-list-notebook li {
    padding-right: 450px;
  }

  &.ddp-list-model {
    margin-top: 10px;

    li .ddp-wrap-detailname {
      text-overflow: ellipsis;
    }
  }
}

/**********************************************************************************
	모델 매니저리스트
**********************************************************************************/

.ddp-ui-contents-top {
  display: none !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 54px;
  padding: 0 80px;
  border-bottom: 1px solid #d0d0d3;
  z-index: 20;
  background-color: #fff;

  /* layer fill content */
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */

  .ddp-ui-title {
    padding: 25px 0 18px 0;
    color: #4b515b;
    font-weight: bold;
    font-size: 22px;
  }
}

ul.ddp-list-top-tab {
  overflow: hidden;

  li {
    float: left;
    padding: 0 20px;

    &:first-of-type {
      padding-left: 0;
    }

    a {
      display: block;
      padding: 10px 0;
      color: #90969f;
      font-size: 14px;
      border-bottom: 3px solid #fff;
    }

    &.ddp-selected a {
      border-bottom: 3px solid $primary;
      color: $primary;
    }
  }
}

.ddp-ui-contents-list {
  padding-top: 0 !important;
  position: relative;
  padding-top: 113px;
  padding-bottom: 54px;

  .ddp-wrap-option {
    float: left;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 13px;
  }

  .ddp-ui-option {
    padding: 10px 0 0 0;

    .ddp-form-search input::placeholder {
      color: #90969f;
    }

    .ddp-multi-search {
      margin-top: 5px;
    }

    .ddp-box-tag {
      font-size: 10px !important;
    }

    .ddp-type-selectbox ul.ddp-list-selectbox.ddp-selectdown {
      left: 0;
      right: inherit;
      min-width: 100%;
      white-space: nowrap;
    }

    &.ddp-optiontype {
      padding-top: 24px;
    }

    .ddp-type-selectbox {
      width: 132px;
    }

    .ddp-wrap-edit {
      float: left;
      margin-left: 30px;

      &:first-of-type {
        margin-left: 0;
      }

      .ddp-label-type {
        padding-right: 10px;
      }

      .ddp-ui-edit-option.ddp-inline .ddp-label-radio {
        margin-right: 10px;
      }
    }

    .ddp-btn-toggle {
      float: left;
      margin-right: 4px;
    }

    .ddp-ui-calen {
      float: left;
      width: 320px;
      margin-right: 4px;

      + .ddp-btn-line-s {
        padding: 6px 15px;
      }
    }

    .ddp-form-search {
      position: relative;
      width: 370px;
    }

    .ddp-ui-rightoption {
      float: right;
      position: relative;
      margin-top: 10px;

      .ddp-btn-selection {
        position: relative;
        top: -6px;
        margin-left: 10px;

        &:first-of-type {
          margin-left: 0;
        }
      }

      .ddp-wrap-edit {
        position: relative;
        top: -9px;

        &:after {
          display: inline-block;
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          height: 14px;
          border-right: 1px solid #d2d3da;
        }
      }
    }

    span.ddp-data-total {
      position: relative;
      top: 2px;
      float: left;
      padding: 0 20px;
      color: #90969f;
      font-size: 12px;

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 14px;
        border-right: 1px solid #d2d3da;
      }

      &.ddp-only {
        padding-right: 0;

        &:after {
          display: none;
        }
      }

      &.ddp-type:after {
        display: none;
      }
    }

    .ddp-list-option {
      position: relative;
      float: left;
      padding: 0 17px 0 17px;

      .ddp-wrap-info {
        display: inline-block;
        position: relative;
        top: 2px;
        vertical-align: top;

        .ddp-icon-info {
          display: inline-block;
          width: 13px;
          height: 13px;
          background: url(/assets/bi/images/icon_info.png) no-repeat;
          background-position: -14px -30px;
          cursor: pointer;

          &:hover {
            background-position: -28px -30px;
          }
        }

        .ddp-box-layout4 {
          display: none;
          position: absolute;
          top: 100%;
          left: -43px;
          width: 235px;
          margin-top: 8px;
        }

        &:hover .ddp-box-layout4 {
          display: block;
        }
      }

      a.ddp-btn-line-s {
        vertical-align: middle;
        margin: -6px 3px 0 3px;

        + .ddp-wrap-info em.ddp-icon-info {
          margin: 0;
        }
      }

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 14px;
        border-right: 1px solid #d2d3da;
      }
    }

    a.ddp-btn-link {
      float: left;
      margin-left: 20px;
      color: #90969f;
      font-size: 12px;

      &.ddp-type {
        position: relative;
        top: 3px;

        &:hover {
          .ddp-icon-export {
            background-position: -14px -56px;
          }

          .ddp-ui-tooltip-info {
            display: block;
            position: absolute;
            right: -10px;
            left: inherit;
            top: 100%;
            margin-top: 5px;

            .ddp-icon-view-top {
              left: inherit;
              right: 15px;
            }
          }
        }
      }

      &:hover {
        color: #4b515b;
      }

      &.ddp-disabled {
        color: #90969f;
        opacity: 0.5;
        cursor: no-drop;

        &:hover {
          color: #90969f;
          opacity: 0.5;
          cursor: no-drop;
        }
      }

      em.ddp-icon-link-add {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: left -22px;
      }
    }

    .ddp-ui-sort.type-option {
      margin: -13px 0 0 0;
    }

    a.ddp-link-reset {
      color: #90969f;
      font-size: 12px;

      &:hover {
        color: #4b515b;
      }

      em.ddp-btn-reset3 {
        position: relative;
        top: -1px;
        margin-right: 6px;
        vertical-align: middle;
      }

      &:hover em.ddp-btn-reset3 {
        background-position: -15px -34px;
      }
    }

    .ddp-wrap-edit .ddp-ui-edit-option.ddp-inline .ddp-label-checkbox {
      margin-right: 10px;
    }

    *,
    .ddp-label-radio span.ddp-txt-radio,
    .ddp-label-checkbox span.ddp-txt-checkbox {
      font-size: 12px;
    }
  }
}

.ddp-wrap-edit .ddp-ui-edit-option {
  &.ddp-inline {
    .ddp-label-checkbox {
      display: inline-block;
      margin-right: 30px;
    }

    .ddp-label-radio {
      display: inline-block;
      margin-right: 30px;
      vertical-align: middle;
    }

    .ddp-label-checkbox:last-of-type,
    .ddp-label-radio:last-of-type {
      margin-right: 0;
    }
  }

  .ddp-type-selectbox {
    background-color: transparent;

    &.ddp-white {
      background-color: #fff;
    }
  }

  &.ddp-inline .ddp-label-radio {
    &.type-scheduled span.ddp-txt-radio {
      font-size: 11px;
      color: #0ead76;
      font-weight: bold;
    }

    &.type-unscheduled span.ddp-txt-radio {
      font-size: 11px;
      color: #90969f;
      font-weight: bold;
    }

    &.type-creating span.ddp-txt-radio {
      font-size: 11px;
      color: #e8a900;
      font-weight: bold;
    }
  }

  a.ddp-btn-toggle {
    display: inline-block;
    padding: 7px;
    margin-right: 4px;
    background-color: #d0d1d8;
    border-radius: 3px;
    color: #4b515b;
    font-size: 12px;
    text-align: center;
    box-sizing: border-box;

    &.ddp-selected {
      background-color: #4b515b;
      color: #fff;
    }

    // &.ddp-all {
    //   width: 58px;
    // }

    // &.ddp-today {
    //   width: 83px;
    // }

    // &.ddp-last {
    //   width: 89px;
    // }
  }

  .ddp-ui-calen {
    margin-right: 4px;
  }
}

/**********************************************************************************
	모델 매니저리스트
**********************************************************************************/

.ddp-top-flow .ddp-wrap-navi .ddp-ui-naviarea.ddp-type {
  left: 55px;
}

ul.ddp-list-detail li .ddp-wrap-detailinfo .ddp-data-history {
  float: left;
  min-width: 60px;

  a.ddp-link-fail {
    color: #e35252;
    font-size: 12px;
    vertical-align: middle;

    &:hover {
      text-decoration: underline;
    }

    em.ddp-icon-go {
      display: inline-block;
      position: relative;
      top: -1px;
      margin-left: 3px;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_go.png) no-repeat;
      vertical-align: middle;
    }
  }

  .ddp-data-success {
    color: #5cacdf;
    font-size: 12px;
  }
}

/************************************************************************
	page : 데이터 프리퍼레이션 유니온
**********************************************************************/

.ddp-wrap-union {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fafafa;

  .ddp-ui-title {
    padding: 20px 0 19px 0;
    font-size: 13px;
    color: #b7b9c2;
    font-weight: bold;
  }
}

.ddp-ui-union-output {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 258px;
  border-right: 1px solid #e7e7ea;
  background-color: #fff;

  .ddp-data-total {
    display: block;
    padding-bottom: 8px;
    color: #444;
    font-size: 13px;

    .ddp-data-num {
      color: $primary;
    }
  }
}

.ddp-wrap-union-list {
  position: relative;
  float: left;
  width: 100%;
  padding-right: 12px;
  box-sizing: border-box;

  &:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    content: '';
  }

  ul.ddp-list-union li {
    padding-bottom: 10px;

    .ddp-box-column {
      position: relative;
      padding: 0 25px 0 51px;
      border-radius: 2px;
      border: 1px solid #d0d1d8;
      cursor: pointer;

      &:hover {
        border: 1px solid #b7b9c2;
      }

      em {
        &.ddp-icon-type-ab,
        &.ddp-icon-type-calen,
        &.ddp-icon-type-sharp,
        &.ddp-icon-type-float,
        &.ddp-icon-type-array,
        &.ddp-icon-type-map {
          position: absolute;
          top: 50%;
          left: 17px;
        }
      }

      input.ddp-input-column {
        display: none;
        width: 100%;
        padding: 6px 0;
        color: #4b515b;
        font-size: 12px;
        border: none;
        box-sizing: border-box;
      }

      span.ddp-data-column {
        display: block;
        width: 100%;
        padding: 6px 0;
        color: #4b515b;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
      }
    }

    &.ddp-selected .ddp-box-column {
      input.ddp-input-column {
        display: block;
      }

      span.ddp-data-column {
        display: none;
      }
    }

    .ddp-box-column {
      em.ddp-icon-control-edit {
        display: none;
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -10px;
      }

      &:hover em.ddp-icon-control-edit {
        display: block;
      }
    }
  }
}

.ddp-ui-union-set {
  position: absolute;
  left: 259px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;

  .ddp-ui-title {
    padding: 20px 20px 20px 20px;
  }
}

.ddp-ui-popup-contents {
  a.ddp-dataset {
    position: absolute;
    top: 93px;
    right: 50px;
    font-weight: normal;
    cursor: pointer;

    .ddp-icon-link-plus {
      margin-right: 5px;
    }
  }

  &.ddp-union {
    padding-top: 140px;
  }
}

.ddp-ui-union-set .ddp-ui-title a.ddp-btn-line:hover .ddp-icon-link-plus {
  background-position: -20px -2px;
}

.ddp-wrap-union-set {
  position: absolute;
  top: 53px;
  left: 0;
  right: 0;
  bottom: 0;
}

.ddp-ui-union-table {
  display: inline-block;
  white-space: nowrap;

  table.ddp-table-union:first-of-type tr {
    th,
    td {
      padding-left: 23px;
    }
  }
}

table.ddp-table-union {
  display: inline-block;
  width: auto;
  margin-left: -3px;
  white-space: nowrap;
  vertical-align: top;

  tr {
    th {
      position: relative;
      padding: 0 5px 5px 12px;
      text-align: left;

      &:first-of-type {
        padding-left: 23px;
      }

      .ddp-wrap-top {
        padding: 0 0 0 0;
        width: 234px;
        font-size: 0;
        box-sizing: border-box;
      }

      .ddp-data-name {
        display: block;
        font-size: 13px;
        color: #4b515b;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
      }

      .ddp-ui-value {
        float: right;
        margin-left: 5px;

        .ddp-data-value {
          float: left;
          position: relative;
          top: 2px;
          color: #b7b9c2;
          font-size: 12px;

          strong {
            color: $primary;
            font-weight: normal;
          }
        }

        em.ddp-icon-control-cut {
          position: relative;
          top: -1px;
          float: Left;
          margin-left: 10px;
        }
      }
    }

    td {
      padding: 5px 6px 5px 6px;
      height: 39px;
      vertical-align: top;
      box-sizing: border-box;
    }
  }
}

.ddp-ui-union-table table.ddp-table-union tr td .ddp-box-column {
  padding-left: 35px;
}

table.ddp-table-union tr {
  td .ddp-box-column {
    position: relative;
    padding: 0 10px 0 51px;
    border-radius: 2px;
    border: 1px solid #d0d1d8;
    background-color: #fff;

    span.ddp-data-column {
      display: block;
      width: 100%;
      padding: 6px 0;
      color: #4b515b;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }

    &.ddp-disabled {
      background-color: #e9eaec;
      border: 1px solid #e9eaec;

      * {
        opacity: 0.4;
      }
    }
  }

  &.ddp-disabled td {
  }

  td .ddp-data-dropped {
    color: #b7b9c2;
    font-style: italic;

    strong {
      color: $primary;
      font-weight: normal;
    }
  }
}

/**********************************************************************************
	데이터 프리퍼레이션 리스트목록
**********************************************************************************/

.ddp-ui-optionset-head {
  position: relative;
  background-color: #313538;

  span.ddp-ui-title {
    display: block;
    padding: 33px 0 35px 45px;
    color: #fff;
    font-size: 18px;

    em.ddp-icon-option {
      display: inline-block;
      width: 21px;
      height: 22px;
      margin-right: 17px;
      background: url(/assets/bi/images/icon_option2.png) no-repeat;
      vertical-align: middle;
    }
  }

  ul.ddp-list-management {
    padding: 0 60px;
    overflow: Hidden;

    li {
      float: left;
      padding: 0 23px;

      a {
        display: inline-block;
        padding-bottom: 13px;
        border-bottom: 2px solid #313538;
        color: #c7ccd0;
        font-size: 14px;
      }

      &.ddp-selected a {
        color: #fefefe;
        font-weight: bold;
        border-bottom: 2px solid #ff8b00;
      }

      a:hover {
        color: #fff;
      }
    }
  }

  span.ddp-btn-add {
    display: inline-block;
    position: absolute;
    bottom: -20px;
    right: 51px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #90969f;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: #b7b9c2;
    }

    em.ddp-icon-plus {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 11px;
      height: 11px;
      margin: -5px 0 0 -5px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: left -10px;
    }
  }
}

.ddp-ui-list-control {
  position: relative;
  float: left;
  padding: 0 53px 0 83px;
  width: 100%;
  box-sizing: border-box;

  .ddp-fleft .ddp-ui-user-buttons {
    float: left;
    position: relative;
    top: 35px;
    padding-right: 13px;
    margin-right: 9px;
    border-right: 1px solid #ddd;
  }

  .ddp-ui-user-buttons a.ddp-btn-type-s {
    margin-right: 5px;
    padding: 6px 10px;
  }

  .ddp-form-search {
    float: left;
    position: relative;
    width: 290px;
    margin-top: 30px;

    input.ddp-input-search {
      width: 100%;
      padding: 9px 0;
      font-size: 14px;
      border: none;
      background: none;
    }
  }

  .ddp-ui-accept-select {
    position: absolute;
    bottom: 0;
    right: 210px;

    .ddp-data-select-num {
      float: left;
      font-style: italic;
      margin-right: 15px;
      line-height: 32px;
    }

    .ddp-ui-user-buttons {
      float: left;
    }
  }

  .ddp-ui-view {
    position: absolute;
    bottom: 0;
    right: 40px;
  }
}

.ddp-layout-contents .ddp-data-source-none {
  position: relative;
  width: 100%;
  padding: 28px 53px 28px 0;
  color: #9c9c9c;
  font-size: 14px;
  box-sizing: border-box;
  clear: both;
}

/************************************************************************
	page : 데이터 프리퍼레이션 데이터셋 추가
**********************************************************************/

.ddp-ui-dataset-new {
  position: relative;
  height: 100%;

  .ddp-wrap-viewtable {
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    bottom: 83px;
  }

  .ddp-ui-sublabel {
    position: relative;
    padding: 15px 0 15px 0;
    color: #4b515b;
    font-size: 14px;
    border-top: 1px solid #d0d1d8;

    .ddp-ui-pop-buttons {
      position: absolute;
      top: 13px;
      right: 20px;
    }
  }

  &.ddp-type .ddp-wrap-viewtable {
    top: 94px;
  }
}

/**********************************************************************************
	page : 데이터 프리퍼레이션 데이터 플로우 생성
**********************************************************************************/

.ddp-dataflow .ddp-box-viewtable {
  height: 426px;
}

/**************************************************************
  Page : 99_데이터프리퍼레이션_01데이터플로우_03룰2
**************************************************************/

.page-prep-dataflow {
  ul.ddp-list-snapshot li {
    > .ddp-txt-snapshot {
      .ddp-data-status {
        padding-bottom: 3px;
        font-size: 14px;

        &.type-success {
          color: #65c394;
        }

        &.type-failed {
          color: #df5b60;
        }
      }

      .ddp-data-name {
        color: #4b515b;
        font-size: 13px;

        .ddp-txt-name {
          display: block;
          padding: 3px 0;
          color: #4b515b;
          font-size: 13px;
          overflow: hidden;
        }

        .ddp-tag-type {
          float: left;
          padding: 1px 4px;
          margin-right: 5px;
          background-color: #b7b9c1;
          border-radius: 3px;
          color: #fff;
          font-size: 11px;
          font-weight: bold;
          vertical-align: middle;
        }
      }

      .ddp-data-date {
        padding-top: 5px;
        color: #b7b9c2;
        font-size: 12px;
      }

      .ddp-data-det .ddp-data-title {
        padding-bottom: 3px;
        font-size: 14px;
      }
    }

    .ddp-wrap-progress {
      position: relative;
      padding-right: 20px;
      margin-top: 5px;

      .ddp-btn-cancel {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -8px;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_delete.png) no-repeat;
        background-position: left -14px;
      }
    }

    .ddp-ui-progress {
      position: relative;
      width: 100%;
    }

    .ddp-txt-cancel {
      padding: 12px 0;
      margin: 5px 12px 14px 12px;
      background-color: #f7f7f8;
    }

    > .ddp-txt-snapshot {
      border-bottom: none;
    }

    border-bottom: 1px solid #eaeaec;
  }

  .ddp-input-selectbox {
    position: relative;

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      left: 0;
      right: inherit;
      bottom: 100%;
      margin-bottom: 3px;
      min-width: 100%;
      max-height: 150px;
      box-sizing: Border-box;
      overflow: auto;

      &.ddp-fix {
        position: fixed;
        bottom: inherit;
        min-width: auto;
        margin-bottom: 0;
      }
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }

  .ddp-type-selectbox ul.ddp-list-selectbox.ddp-fix {
    position: fixed;
    bottom: inherit;
  }

  .ddp-box-contents .ddp-box-part .ddp-box-title {
    span.ddp-box-title-in {
      padding-right: 19px;
    }

    a.ddp-btn-plus {
      display: inline-block;
      width: 13px;
      height: 13px;
      top: 0;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -84px -22px;
      border: none;

      &:before {
        display: none;
      }

      &:hover {
        background-position-x: -98px;
        border: none;
      }
    }
  }

  .ddp-wrap-boxadd .ddp-ui-buttons a.ddp-btn-minus {
    border: none;
    background: none;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/icon_delete.png) no-repeat;
      background-position: left -14px;
    }

    &.ddp-disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }

  ul.ddp-list-snapshot li > .ddp-txt-snapshot .ddp-data-name svg {
    float: left;
    margin-right: 5px;
    vertical-align: middle;
  }

  .icon-db-local-b {
    display: inline-block;
    margin-right: 5px;
  }

  .ddp-box-contents .ddp-wrap-part .ddp-box-part {
    &.ddp-type-part {
      width: auto;
    }

    .ddp-error-part {
      display: table-cell;

      .ddp-wrap-selectbox {
        display: table-cell;
      }

      .ddp-type-selectbox {
        display: inline-block;
        width: 220px;
      }
    }
  }

  .ddp-wrap-flow-grid .ddp-wrap-grid-option .ddp-list-tab-button {
    margin-left: 10px;
  }

  .ddp-wrap-warning {
    display: table-cell;
    padding-left: 6px;
    vertical-align: middle;
    display: table-cell;
    padding-left: 6px;
    vertical-align: middle;

    .ddp-box-warning {
      margin: 0;
    }
  }

  .ddp-box-warning {
    position: relative;
    float: left;
    margin-top: 14px;
    margin-left: 6px;
    width: 25px;
    height: 25px;
    border: 1px solid #f7d375;
    border-radius: 2px;
    background-color: #fdf1d5;
    box-sizing: border-box;
    cursor: pointer;

    .ddp-icon-warning {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      width: 15px;
      height: 14px;
      background: url(/assets/bi/images/icon_error.png) no-repeat;
      background-position: left -16px;
    }

    .ddp-txt-warning {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      padding: 7px 10px;
      margin-top: 2px;
      background-color: #e9b017;
      color: #fff;
      font-size: 12px;
      white-space: nowrap;
      border-radius: 2px;
    }

    &:hover .ddp-txt-warning {
      display: block;
    }
  }

  .slick-headerrow-column.ui-state-default {
    border-right: 1px solid #ebebed;
  }

  .slick-headerrow.ui-state-default {
    border-top: 1px solid #ebebed;
  }
}

/**********************************************************************************
	page : 데이터 프리퍼레이션 데이터셋 파일업로드
**********************************************************************************/

.ddp-box-file-create {
  display: table;
  width: 100%;
  height: 100%;
  border: 1px solid #d0d1d8;
  background-color: #f6f6f7;

  .ddp-box-default {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;

    &.ddp-disabled {
      opacity: 0.3;
    }
  }

  .ddp-txt-file-info {
    display: inline-block;
    padding-bottom: 15px;
    color: #90969f;
    font-size: 18px;
  }

  &.ddp-file-single {
    &.type-upload {
      padding-bottom: 100px;

      .ddp-box-default {
        position: absolute !important;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 28px 29px 27px 29px;
        height: auto;
        width: auto;
        text-align: left;
        border-top: 1px solid #d0d1d8;

        .ddp-txt-file-info {
          padding-bottom: 5px;
        }
      }
    }

    .ddp-file-progress {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      background-color: #fff;
    }

    &.type-upload .ddp-file-progress {
      display: table-cell;
    }

    .ddp-file-progress {
      &.ddp-disabled:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.7);
      }

      .ddp-data-name {
        padding-bottom: 7px;
        font-size: 14px;
        color: #4b515b;
        font-weight: bold;
      }

      .ddp-icon-file {
        display: inline-block;
        width: 56px;
        height: 56px;
      }
    }

    &.type-upload .ddp-icon-file {
      display: inline-block;
      margin: 30px 0;
      width: 60px;
      height: 60px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .ddp-file-progress {
      .ddp-data-size {
        color: #91969e;
        font-size: 12px;
      }

      .ddp-ui-condition {
        box-sizing: border-box;

        .ddp-type-progressbar2 {
          width: 200px;
        }

        &.type-failed .ddp-type-progressbar2 .ddp-data-progressbar {
          background-color: #cd5454;
        }

        .ddp-data-per {
          display: inline-block;
          width: 48px;
          padding-right: 8px;
          text-align: right;
          color: $primary;
          font-weight: bold;
          vertical-align: middle;
        }

        &.type-failed .ddp-data-per {
          color: #cd5454;
        }

        .ddp-data-condition {
          display: inline-block;
          width: 65px;
          padding-left: 7px;
          text-align: center;
          color: #90969f;
          vertical-align: middle;

          .ddp-line {
            width: 65px;
            margin: -12px 0;
            box-sizing: Border-box;
          }

          .ddp-txt-error {
            position: relative;
            font-style: normal;
            cursor: pointer;

            .ddp-box-layout4 {
              display: none;
              position: absolute;
              top: 100%;
              right: 0;
              margin-top: 4px;
              width: 275px;
              text-align: left;
              z-index: 2;
            }

            &:hover .ddp-box-layout4 {
              display: block;
            }

            .ddp-box-layout4 .ddp-data-det {
              font-weight: normal;
              color: #4b515b;
            }
          }
        }
      }
    }
  }
}

/*.ddp-box-file-create.ddp-file-single.type-upload .ddp-import-file {display:block;}*/

.ddp-txt-file-info {
  .ddp-link-file {
    display: inline-block;
    position: relative;
    color: #4b515b;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.ddp-txt-file-info2 {
  color: #90969f;
  font-size: 13px;
}

.ddp-box-upload {
  height: 70px;
  padding: 0 140px 0 29px;
  margin-bottom: 5px;
  background-color: #f6f6f7;
}

.ddp-wrap-file-create {
  width: 880px;
}

.popup-prep-create-complete .type-metadata {
  .ddp-wrap-boxtype {
    height: auto;
    min-height: 160px;
    max-height: 160px;
    margin-bottom: 0;
  }

  .ddp-sheet-table {
    margin-top: -192px;
    padding-top: 202px;
    padding-bottom: 10px;
    height: 100%;
    box-sizing: border-box;

    &.type-info {
      margin-top: -223px;
      padding-top: 233px;
    }
  }
}

.ddp-box-popupcontents {
  &.ddp-flex {
    display: flex;
    flex-direction: column;

    .ddp-flex-column {
      margin-bottom: 10px;
    }

    .ddp-wrap-boxtype.ddp-flex-column {
      height: auto;
      min-height: auto;
      max-height: auto;
      overflow-y: inherit;
    }
  }

  .ddp-data-check {
    margin-top: 10px;

    .ddp-label-type {
      float: left;
      color: #4b515b;
    }
  }
}

.ddp-box-upload {
  .ddp-data-load-name {
    position: relative;
    padding: 27px 0 27px 30px;
    color: #4b515b;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    em.ddp-icon-file2 {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -10px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -12px;
    }
  }

  .ddp-txt-file-info {
    position: absolute;
    top: 26px;
    right: 35px;
    color: #90969f;
    font-size: 13px;

    .ddp-link-file {
      color: #4b515b;
      font-size: 13px;
    }
  }
}

.ddp-wrap-sheet {
  height: 333px;
  border: 1px solid #b7b9c2;

  &.ddp-full {
    .ddp-ui-sheet-list {
      display: none;
    }

    .ddp-view-sheet {
      margin-left: 0;
      padding-left: 0;

      .ddp-wrap-grid {
        left: 0;
      }
    }
  }

  .ddp-ui-sheet-list {
    position: relative;
    float: left;
    width: 199px;
    height: 100%;
    border-right: 1px solid #d7d8dd;
    overflow: auto;
    z-index: 1;

    ul.ddp-list-sheet li {
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid #e6e6e9;

      &:before {
        display: none;
        content: '';
        width: 6px;
        height: 11px;
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -68px;
      }

      &:hover:before {
        display: block;
      }

      &.ddp-selected {
        &:before {
          display: block;
        }

        background-color: #f6f6f7;
      }

      position: relative;
      padding: 0 0 0 35px;

      label.ddp-label-checkbox {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 100%;
        box-sizing: border-box;

        input,
        i {
          left: 14px;
        }
      }

      span.ddp-txt-checkbox {
        display: block;
        padding: 14px 28px 14px 0;
        font-size: 14px;
        color: #4c515a;

        .ddp-icon-error2 {
          margin-left: 3px;
          position: relative;
          top: -2px;
        }
      }

      label.ddp-label-radio {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 100%;
        box-sizing: border-box;

        input,
        i {
          left: 14px;
        }
      }

      span.ddp-txt-radio {
        display: block;
        padding: 14px 28px 14px 0;
        font-size: 14px;
        color: #4c515a;

        em.ddp-icon-error2 {
          margin-left: 3px;
          position: relative;
          top: -2px;
        }
      }

      &.ddp-selected label.ddp-label-radio input[type='radio']:checked + i {
        background-position: -48px top;
      }
    }
  }
}

.ddp-view-sheet {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin-left: -200px;
  padding-left: 200px;
  box-sizing: Border-box;

  &.ddp-full {
    margin: 0;
    padding: 0;

    .ddp-wrap-grid {
      left: 0;
    }
  }

  .ddp-data-name {
    position: relative;
    padding: 9px 15px 9px 15px;
    height: 36px;
    color: #4c515a;
    font-size: 14px;
    border-bottom: 1px solid #d7d8dd;
    box-sizing: border-box;

    .ddp-part-right {
      position: absolute;
      top: -13px;
      right: 0;
    }

    .ddp-icon-error2 {
      margin-left: 3px;
      position: relative;
      top: -2px;
    }
  }

  .ddp-wrap-grid {
    position: absolute;
    top: 36px;
    left: 200px;
    right: 0;
    bottom: 0;
    font-size: 14px;
    line-height: 1.5em;
    overflow: auto;

    &.ddp-full {
      top: 0;
    }
  }
}

.ddp-wrap-edit-form .ddp-wrap-edit3 {
  margin-top: 20px;
}

/****************************************************************************
   Popup : 05_매니지먼트_00데이터저장소_01생성_stram1
*************************************************************************/

.page-storage-create {
  width: 660px;

  .type-col {
    margin: 0 -8px;

    &:first-of-type .ddp-wrap-edit2 {
      margin-top: 0;
    }

    [class*='ddp-col-'] {
      padding: 0 8px;
    }

    .ddp-check {
      margin-top: 3px;
    }
  }

  .ddp-type-topic {
    .ddp-wrap-edit2 {
      margin-top: 20px;
    }

    .ddp-type-selectbox {
      display: inline-block;
      width: 200px;
    }
  }
}

.ddp-ui-buttons.ddp-inline .ddp-ui-message .ddp-data-error2.type-font-normal {
  font-style: normal;
}

.page-storage-create .ddp-type-topic {
  .ddp-txt-info {
    display: inline-block;
    position: relative;
    top: -5px;
    margin-left: 5px;
    padding: 0;
    color: #90969f;
    font-size: 12px;
    white-space: nowrap;
    font-weight: normal;
  }

  .ddp-label-checkbox {
    margin: 5px 0 10px 0;
  }
}

/****************************************************************************
   Popup : 99_데이터프리퍼레이션_01데이터플로우_04조인_popup
*************************************************************************/

.popup-prep-join {
  .ddp-wrap-prep-join {
    height: 100%;
    margin-top: -120px;
    padding: 120px 0 0 0;
    box-sizing: border-box;

    .ddp-ui-prep-join {
      position: relative;
      height: 100%;
      min-height: 600px;
      padding-bottom: 60px;
      box-sizing: border-box;

      &.ddp-default {
        .ddp-prep-box-contents {
          height: 100%;
        }

        .ddp-prep-join-type {
          display: none;
        }
      }
    }
  }

  .ddp-ui-popup-join {
    .ddp-prep-box-contents {
      height: 60%;
      padding: 0 50px;

      .ddp-box-pop-contents.ddp-type {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
      }
    }

    .ddp-prep-join-type {
      position: relative;
      height: 40%;
      padding-bottom: 60px;

      .ddp-ui-join-type.ddp-type {
        position: relative;
        height: 100%;
        bottom: 0;
      }
    }

    .ddp-ui-part .ddp-ui-set ul.ddp-list-tab-button li .ddp-ui-tooltip-info {
      right: 0;
      left: inherit;

      em.ddp-icon-view-top {
        left: inherit;
        right: 11px;
      }
    }
  }
}

/**************************************************************
  popup :99_데이터프리퍼레이션_01데이터플로우_03데이터셋선택_popup
**************************************************************/

.popup-prep-dataset {
  .ddp-ui-popup-contents {
    padding-top: 170px;
  }

  .ddp-ui-dataset-new .ddp-wrap-viewtable {
    bottom: 33px;
  }

  .ddp-type-top-option .ddp-ui-rightoption .ddp-data-total {
    position: relative;
    top: 6px;
  }

  .ddp-ui-popup-contents .ddp-data-dataset {
    position: absolute;
    top: 104px;
    left: 50px;
    right: 50px;
    padding: 15px 20px;
    background-color: #f6f6f7;
    font-size: 14px;
    color: #35393f;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .ddp-txt-label {
      color: #90969f;
      font-size: 14px;
    }
  }
}

/**************************************************************
  popup : 99_데이터프리퍼레이션_02데이터셋_01생성완료_popup
**************************************************************/

.popup-prep-create-complete {
  .ddp-wrap-complete {
    width: 860px;
    height: 100%;
    margin: 0 auto;
    text-align: left;
  }

  .ddp-wrap-boxtype {
    width: 100%;
    height: 160px;
    margin: 0;
  }

  .ddp-wrap-sheet-list {
    height: 100%;
    margin-top: -200px;
    padding: 210px 0 0 0;
    box-sizing: border-box;

    &.ddp-full {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .ddp-ui-sheet-list {
    border: 1px solid #d6d7dc;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

  table.ddp-table-sheet {
    width: 100%;
    table-layout: fixed;

    tr {
      th {
        padding: 8px 20px 8px 20px;
        text-align: left;
        font-weight: normal;
        color: #90969f;
        font-size: 12px;
        border-bottom: 1px solid #e7e7ea;
      }

      td {
        padding: 16px 0 16px 20px;
        text-align: left;
        color: #4b515b;
        font-size: 13px;
        vertical-align: top;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: Hidden;

        .ddp-sheet-form {
          margin: -8px 0;
        }

        input.ddp-input-typebasic {
          margin: -8px 0 -8px 0;
        }

        .ddp-sheet-form {
          input.ddp-input-typebasic {
            margin: 0;
          }

          .ddp-data-error {
            color: #e70000;
            font-size: 12px;
            font-style: italic;

            &:before {
              display: inline-block;
              width: 13px;
              height: 13px;
              background: url(/assets/bi/images/icon_info.png) no-repeat;
              background-position: -28px top;
              vertical-align: middle;
              content: '';
            }
          }

          .ddp-textarea-resize {
            margin: 0;
          }
        }

        .ddp-textarea-resize {
          margin: -8px 0 -8px 0;
        }
      }

      th:last-of-type,
      td:last-of-type {
        padding-right: 20px;
      }

      &:nth-child(odd) td {
        background-color: #fafafa;
      }

      td textarea {
        display: block;
        width: 100%;
        padding: 5px 10px;
        height: 30px;
        border: 1px solid #d0d1d9;
        box-sizing: border-box;
      }
    }
  }
}

/**************************************************************
  popup :99_데이터프리퍼레이션_02데이터셋_02파일업로드1
**************************************************************/

.popup-prep-fileupload {
  .ddp-box-file-create .ddp-box-loading {
    &.ddp-pop {
      &.ddp-progress {
        background: none;
        box-shadow: none;
      }

      .ddp-btn-cancel {
        color: #b6b9c2;

        &:after {
          background: url(/assets/bi/images/btn_popclose.png) no-repeat;
          background-position: -22px -101px;
          content: '';
        }
      }
    }

    &.ddp-cancel {
      margin-top: 10px;
    }

    &.ddp-pop.ddp-disabled {
      opacity: 0.3;

      &:before {
        background: none;
      }
    }
  }

  .ddp-wrap-sheet .ddp-select-all {
    padding: 10px 14px;
    background-color: #f6f6f7;
  }

  .ddp-view-sheet .ddp-wrap-grid .ddp-box-loading.ddp-cancel {
    margin-top: 10px;
  }
}

/**************************************************************
  popup : 99_데이터프리퍼레이션_02데이터셋_02파일업로드2_popup
**************************************************************/

/**************************************************************
  popup : 99_데이터프리퍼레이션_03데이터스냅샷_01상세_success_popup
**************************************************************/

.popup-prep-snapshot .ddp-layout-pop-right {
  &.ddp-detail {
    z-index: 56;

    .ddp-view-title {
      padding: 20px 10px 0 15px;
      height: 85px;
      background-color: #fff;
      box-sizing: border-box;

      span {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #35393f;
        line-height: 22px;
        letter-spacing: -1px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .ddp-view-detail dl.ddp-dl-detail dd {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  &.ddp-detail {
    .ddp-view-title {
      &.type-edit {
        span.ddp-txt-title {
          position: relative;
          padding-right: 23px;
          font-size: 16px;
          font-weight: bold;
          color: #35393f;
          line-height: 22px;
          letter-spacing: -1px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;

          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          cursor: pointer;

          .ddp-link-edit {
            display: none;
            position: absolute;
            bottom: 2px;
            right: 5px;
          }

          &:hover .ddp-link-edit {
            display: block;
          }
        }

        &.ddp-selected {
          .ddp-txt-title {
            display: none;
          }

          .ddp-ui-input {
            display: block;
          }
        }
      }

      .ddp-ui-input {
        display: none;
        position: Relative;
        padding-right: 23px;
        margin: -1px 0 0 -5px;
        border-radius: 3px;
        border: 1px solid #b7b9c2;
        overflow: hidden;

        textarea {
          display: block;
          width: 100%;
          height: 100%;
          padding: 1px 0 2px 5px;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          letter-spacing: -1px;
          border: none;
          background-color: #f6f6f7;
          box-sizing: border-box;
        }

        input {
          display: block;
          width: 100%;
          height: 100%;
          padding: 4px 0 4px 5px;
          font-size: 16px;
          font-weight: bold;
          border: none;
          box-sizing: border-box;
        }

        .ddp-btn-check {
          display: inline-block;
          position: absolute;
          top: 2px;
          bottom: 2px;
          right: 2px;
          width: 23px;
          height: inherit;
          border-radius: 3px;
          background-color: #d0d1d8;
          cursor: pointer;

          &:hover {
            background-color: #9ca2cc;
          }
        }
      }
    }

    .ddp-view-contents-scroll {
      position: absolute;
      top: 83px;
      left: 0;
      right: 0;
      bottom: 70px;
      overflow-y: auto;

      .ddp-view-detail {
        padding-top: 0;
      }
    }

    .ddp-view-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background-color: #fff;

      a.ddp-btn-pop {
        width: 100%;
      }
    }

    .ddp-box-info dl.ddp-dl-detail2 {
      padding-bottom: 20px;
    }
  }
}

/**************************************************************
    popup : 99_데이터프리퍼레이션_02데이터셋_01생성_file1_popup
**************************************************************/

.ddp-box-popupcontents .ddp-box-file-create {
  position: relative;
  overflow: auto;
  box-sizing: border-box;

  .ddp-wrap-upload {
    display: inline-block;
    position: relative;
    padding: 23px 0 0 0;
    width: 323px;
    text-align: left;

    .ddp-box-upload-type {
      padding: 15px 20px 15px 17px;
      border-radius: 5px;
      background-color: #ececef;

      .ddp-wrap-edit3 {
        .ddp-ui-edit-option.ddp-type {
          padding-bottom: 0;
        }

        &.ddp-type {
          .ddp-type-selectbox {
            width: 100%;
          }

          .ddp-label-type {
            padding: 7px 0;
            font-size: 13px;
          }
        }
      }
    }
  }

  .ddp-box-file-progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
  }
}

.ddp-box-file-progress {
  .ddp-import-file {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    padding: 27px 0 0 30px;
    height: 100px;
    border: 1px solid #d0d1d8;
    background-color: #f6f6f7;
    box-sizing: border-box;

    &.ddp-disabled {
      opacity: 0.3;

      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 60;
        content: '';
      }
    }

    .ddp-txt-file-info {
      padding-bottom: 5px;
    }

    .ddp-wrap-edit3 {
      position: absolute;
      top: 34px;
      right: 30px;
      width: auto;

      &.ddp-type .ddp-label-type {
        font-size: 13px;
        width: auto;
        padding-right: 22px;
      }
    }
  }

  .ddp-file-progress {
    height: 100%;
    padding-top: 39px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .ddp-wrap-file-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px 0 10px 14px;
      height: 39px;
      border-bottom: 1px solid #d0d1d8;
      background-color: #91969e;
      color: #fff;
      font-size: 14px;
      overflow: hidden;
      box-sizing: border-box;

      .ddp-file-title {
        float: left;
      }

      .ddp-btn-restore {
        float: right;
        position: relative;
        top: 2px;
        margin-right: 15px;
        color: #fff;
        font-size: 12px;

        .ddp-icon-reset2 {
          vertical-align: right;
          margin-right: 3px;
        }
      }
    }
  }

  .ddp-list-file-progress {
    height: 100%;
    overflow: auto;

    li {
      position: relative;
      float: left;
      width: 100%;
      padding-right: 360px;
      border-bottom: 1px solid #e6e6e9;
      box-sizing: border-box;

      &.ddp-disabled:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        content: '';
        z-index: 1;
      }

      .ddp-file-name {
        position: relative;
        width: 100%;
        padding: 27px 0 20px 66px;
        box-sizing: border-box;

        [class*='ddp-icon-flow-svg'] {
          position: absolute;
          top: 50%;
          left: 30px;
          margin-top: -12px;
          background: none;
        }

        .ddp-data-filename {
          display: inline-block;
          max-width: 100%;
          padding-bottom: 8px;
          color: #4b515b;
          font-size: 14px;
          font-weight: bold;

          .ddp-txt-filename {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .ddp-txt-subname {
            float: right;
          }
        }

        .ddp-data-size {
          display: block;
          color: #91969e;
          font-size: 12px;
        }
      }
    }

    .ddp-ui-condition {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 360px;
      padding: 37px 0 0 0;
      box-sizing: border-box;

      .ddp-type-progressbar2 {
        width: 200px;
      }

      &.type-failed .ddp-type-progressbar2 .ddp-data-progressbar {
        background-color: #cd5454;
      }

      .ddp-data-per {
        display: inline-block;
        width: 48px;
        padding-right: 8px;
        text-align: right;
        color: $primary;
        font-weight: bold;
        vertical-align: middle;
      }

      &.type-failed .ddp-data-per {
        color: #cd5454;
      }

      .ddp-data-condition {
        display: inline-block;
        width: 65px;
        padding-left: 7px;
        text-align: center;
        color: #90969f;
        vertical-align: middle;

        .ddp-line {
          width: 65px;
          margin: -12px 0;
          box-sizing: Border-box;
        }

        .ddp-txt-error {
          position: relative;
          font-style: normal;
          cursor: pointer;

          .ddp-box-layout4 {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 4px;
            width: 275px;
            text-align: left;
            z-index: 2;
          }

          &:hover .ddp-box-layout4 {
            display: block;
          }

          .ddp-box-layout4 .ddp-data-det {
            font-weight: normal;
            color: #4b515b;
          }
        }
      }
    }

    li.ddp-disabled .ddp-type-progressbar2 .ddp-data-progressbar {
      display: none;
    }
  }
}

.ddp-ui-create-file {
  &.type-filelist-none {
    .ddp-list-create-file {
      display: none;
    }

    .ddp-wrap-create-detail {
      margin-left: 0;
      padding-left: 0;
    }
  }

  width: 100%;
  height: 100%;
  border: 1px solid #d0d1d8;
  box-sizing: border-box;

  .ddp-list-create-file {
    float: left;
    width: 250px;
    max-width: 250px;
    height: 100%;
    border-right: 1px solid #d0d1d8;
    overflow: auto;
    box-sizing: border-box;
  }

  .ddp-wrap-create-detail {
    float: left;
    margin-left: -250px;
    padding-left: 250px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .ddp-ui-create-detail {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .ddp-list-file-name {
    .ddp-wrap-checkbox {
      position: relative;
      padding: 14px 35px 14px 34px;
      cursor: pointer;
    }

    > li {
      position: relative;
      border-bottom: 1px solid #e6e6e9;

      .ddp-wrap-checkbox:hover,
      &.ddp-selected > .ddp-wrap-checkbox {
        background-color: #f6f6f7;
        font-weight: bold;
      }
    }
  }

  .ddp-list-sub-file > li {
    &.ddp-selected > .ddp-wrap-checkbox,
    > .ddp-wrap-checkbox:hover {
      background-color: #f6f6f7;
      font-weight: bold;
    }
  }

  .ddp-list-file-name > li > .ddp-wrap-checkbox:after,
  .ddp-list-sub-file > li > .ddp-wrap-checkbox:after {
    display: none;
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -6px;
    width: 6px;
    height: 11px;
    background: url(/assets/bi/images/icon_dataview.png) no-repeat;
    background-position: left -68px;
    content: '';
  }

  .ddp-list-file-name > li {
    > .ddp-wrap-checkbox:hover:after,
    &.ddp-selected > .ddp-wrap-checkbox:after {
      display: Block;
    }
  }

  .ddp-list-sub-file > li {
    > .ddp-wrap-checkbox:hover:after,
    &.ddp-selected > .ddp-wrap-checkbox:after {
      display: Block;
    }
  }

  .ddp-list-file-name {
    li .ddp-label-checkbox {
      padding-left: 0;
      max-width: 100%;
      box-sizing: border-box;
    }

    .ddp-wrap-checkbox .ddp-label-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 34px;

      .ddp-icon-checkbox {
        left: 14px;
      }
    }

    li {
      span.ddp-txt-checkbox {
        display: inline-block;
        max-width: 100%;
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .ddp-txt-subname {
          float: right;
          font-size: 13px;
        }
      }

      .ddp-label-checkbox .ddp-txt-filename {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .ddp-list-sub-file {
    border-top: 1px solid #e6e6e9;

    > li .ddp-wrap-checkbox {
      position: relative;
      padding: 10px 35px 10px 60px;
      border-bottom: none;
    }
  }

  .ddp-list-file-name .ddp-list-sub-file .ddp-wrap-checkbox .ddp-label-checkbox {
    left: 25px !important;
  }

  .ddp-ui-create-detail {
    .ddp-wrap-grid-option {
      border-bottom: 1px solid red;
      overflow: hidden;
      background-color: #f6f6f7;
      border-bottom: 1px solid #d7d8dd;

      .ddp-data-total {
        float: right;
        padding: 14px 0 15px 0;

        .ddp-txt-data {
          color: #4b515b;
          font-weight: 300;
          margin-right: 15px;

          .ddp-txt-num {
            font-weight: normal;
          }
        }
      }

      .ddp-data-name {
        position: relative;
        padding: 14px 0 14px 43px;
        color: #4c515a;
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        [class*='ddp-icon-files'] {
          position: absolute;
          top: 50%;
          left: 15px;
          margin-top: -12px;
        }
      }
    }

    .ddp-wrap-grid {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      bottom: 56px;
    }
  }
}

.popup-prep-create-complete table.ddp-table-sheet tr td [class*='ddp-icon-flow-'] {
  margin-right: 5px;
}

/**************************************************************
  popup : 99_데이터프리퍼레이션_02데이터셋_01생성_url2
**************************************************************/

.ddp-wrap-urltype {
  position: relative;
  width: 860px;
  height: 100%;
  text-align: left;
  margin: auto;

  .ddp-box-resultpreview {
    display: block;
    padding: 0;
    top: 84px;
    left: 0;
    right: 0;

    .ddp-ui-pop-top .ddp-label-type {
      color: #4b515b;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .ddp-ui-create-file {
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;

    .ddp-ui-create-detail .ddp-wrap-grid {
      top: 0;
      bottom: 0;
    }
  }
}

/**********************************************************************************
	popup : 데이터저장소 시트선택 (적재팝업
**********************************************************************************/

.ddp-wrap-layout-popup.type-setting {
  .ddp-box-popup {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    margin: 0 0 0 0;
    padding-bottom: 79px;

    .ddp-pop-title {
      padding: 80px 0 0 0;
    }

    .ddp-pop-detail {
      padding: 8px 0 20px 0;
      color: #90969f;
      font-size: 14px;
      text-align: center;
    }
  }

  .ddp-pop-detail .ddp-wrap-advance {
    margin: 0 auto;
    width: 464px;
    text-align: right;

    .ddp-txt-setting {
      display: inline-block;
      margin-top: 10px;
      font-size: 13px;
      color: #4c515a;
      text-decoration: underline;
      display: inline-block;
      padding-bottom: 20px;
      color: #4c515a;
      font-size: 13px;
      text-decoration: underline;

      &:after {
        display: inline-block;
        width: 7px;
        height: 4px;
        margin-left: 10px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -16px top;
        vertical-align: middle;
        content: '';
      }
    }

    &.ddp-selected .ddp-txt-setting:after {
      transform: rotate(180deg);
    }
  }

  .ddp-wrap-advance {
    &.ddp-selected .ddp-wrap-boxtype {
      display: block;
    }

    .ddp-wrap-boxtype {
      display: none;
      text-align: left;
      margin: 0;
      width: 100%;
      height: auto;
      min-height: auto;
      padding: 20px 20px;
      overflow: inherit;

      table tr {
        td,
        th {
          padding: 10px 6px;
          font-size: 12px;
        }

        td {
          .ddp-sub-option {
            margin: -6px 0 0 0;
          }

          .ddp-label-radio {
            margin-right: 28px;
          }

          .ddp-wrap-edit3 {
            display: block;
            width: auto;

            &.ddp-type .ddp-label-type {
              width: auto;
              margin-right: 6px;
              padding: 6px 0;
              font-size: 12px;
            }
          }

          .ddp-ui-calen.type-calen {
            margin-top: 10px;
          }

          .ddp-wrap-edit3 .ddp-ui-edit-option {
            display: block;
            overflow: Hidden;
          }

          .ddp-wrap-info {
            float: left;
            width: 100%;
            padding: 5px 0 0 0;

            .ddp-txt-info + .ddp-txt-info {
              margin-top: 5px;
            }

            .type-hover {
              cursor: pointer;

              &:hover .ddp-ui-tooltip-info {
                display: block;
              }

              .ddp-ui-tooltip-info {
                top: 100%;
                left: -100px;
                margin-top: 5px;
                width: 340px;
                white-space: normal;

                .ddp-icon-view-top {
                  left: 105px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**************************************************************
  popup : 05_매니지먼트_00데이터저장소_01생성_00myfile_01시트선택_popup
**************************************************************/

.popup-datasource {
  .ddp-view-sheet .ddp-data-name .ddp-icon-error2 {
    position: relative;
    top: -1px;
    vertical-align: middle;
  }

  .ddp-wrap-sheet .ddp-ui-sheet-list ul.ddp-list-sheet li span.ddp-txt-radio .ddp-icon-error2 {
    position: relative;
    top: -1px;
    margin-left: 4px;
    vertical-align: middle;
  }
}

.ddp-wrap-edit-form .ddp-wrap-edit3 .ddp-data-error {
  display: inline-block;
  min-width: 100%;
  margin-top: 20px;
}

/**************************************************************
  popup : 05_매니지먼트_00데이터저장소_01생성_03StringDb_03
**************************************************************/

.popup-datasource {
  .ddp-wrap-edit2 {
    .ddp-ui-setting-table {
      display: table;
      margin-top: 4px;

      .ddp-type-label {
        display: table-cell;
        position: relative;
        top: 7px;
        padding-right: 10px;
        color: #90969f;
        vertical-align: top;
      }

      .ddp-wrap-units {
        display: table-cell;
      }

      .ddp-ui-units {
        white-space: nowrap;
      }

      .ddp-ui-calen {
        display: inline-block;
        margin-right: 7px;

        .ddp-box-calen {
          width: 160px;
        }
      }

      .ddp-ui-sub-setting .ddp-txt-error {
        position: relative;
        top: 0;
        margin-top: 5px;
      }

      .ddp-wrap-units .ddp-data-units {
        position: relative;
        top: 7px;
        display: inline-block;
        color: #90969f;
        vertical-align: top;

        .ddp-data-num {
          color: #4b515b;

          &.ddp-error-num {
            color: #e70000;
          }
        }
      }
    }

    .ddp-ui-option-sub {
      .ddp-label-radio.ddp-mgr0 {
        margin-right: 0;
      }

      .ddp-wrap-hover-info {
        .ddp-icon-info3 {
          display: inline-block;
          position: relative;
          top: -2px;
          width: 11px;
          height: 11px;
          background: url(/assets/bi/images/icon_que.png) no-repeat;
          background-position: left -12px;
          cursor: pointer;
        }

        .ddp-pop-setting {
          width: 350px;
        }
      }
    }
  }

  .ddp-wrap-hover-info {
    .ddp-box-layout4.ddp-pop-setting {
      display: none;
    }

    .ddp-wrap-pop-setting {
      position: absolute;
      left: 100%;
      top: -30px;
      padding-left: 5px;
      z-index: 10;
    }

    &:hover .ddp-box-layout4.ddp-pop-setting {
      display: block;
      position: relative;
      left: 0;
      top: 0;
      margin-left: 0;
    }

    .ddp-box-layout4.ddp-pop-setting {
      table.ddp-table-info {
        table-layout: fixed;
        width: 100%;
      }

      .ddp-data-det3 {
        padding: 0 15px 15px 15px;
        color: #4b515b;
        font-size: 12px;

        .ddp-data-ex {
          display: block;
          padding: 18px 0 0 0;
          color: #9ba0a8;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .ddp-data-date {
        display: inline-block;
        padding: 0 15px;
        margin: -5px 0 18px 0;
        color: #727ab6;
        font-size: 12px;
        font-style: italic;
      }
    }
  }

  .ddp-wrap-partition {
    padding-left: 0;
  }

  .ddp-ui-partition {
    padding: 14px;
    background-color: #f6f6f7;
    overflow: auto;
  }

  .ddp-list-partition {
    padding: 0;
    overflow: initial;

    li {
      padding: 0;
      margin-right: 2px;
      width: 149px;
      vertical-align: top;

      span.ddp-ui-label {
        font-weight: normal;
      }
    }

    &.ddp-type li .ddp-data {
      padding: 3px 0 0 0;
    }
  }

  .ddp-ui-partition {
    .ddp-data-error {
      display: block;
      color: #e70000;
      font-size: 12px;
      font-style: italic;

      &.ddp-info:before {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 3px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px top;
        vertical-align: middle;
        content: '';
      }
    }

    .ddp-btn-control {
      padding: 6px 0 0 0;

      .ddp-btn-add {
        display: inline-block;
        width: 11px;
        height: 11px;
        margin-right: 4px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -12px -10px;

        &:hover {
          background-position-x: -24px;
        }
      }

      .ddp-btn-delete {
        display: inline-block;
        width: 11px;
        height: 12px;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
        background-position: -26px -14px;

        &:hover {
          background-position-x: -50px;
        }
      }
    }
  }

  .ddp-wrap-partition .ddp-ui-buttons {
    padding-top: 8px;

    .ddp-wrap-result {
      display: inline-block;
      position: relative;
      margin-left: 5px;

      .ddp-link-result {
        display: inline-block;
        padding: 3px;
        color: #4c515a;
        font-size: 12px;
        font-style: italic;
        background: #eeeef0;

        &:hover {
          background-color: #d0d1d9;
        }
      }
    }
  }

  .ddp-box-layout4.ddp-result {
    display: none;
    position: fixed;
    min-width: 243px;
    width: auto;
    max-height: 350px;
    overflow: auto;

    .ddp-data-det {
      color: #4b515b;

      .ddp-data-row {
        color: #9ba0a8;
      }
    }
  }

  .ddp-box-loading {
    &.ddp-pop {
      &.ddp-progress {
        background: none;
        box-shadow: none;
      }

      .ddp-btn-cancel {
        color: #b6b9c2;

        &:after {
          background: url(/assets/bi/images/btn_popclose.png) no-repeat;
          background-position: -22px -101px;
          content: '';
        }
      }
    }

    &.ddp-cancel {
      margin-top: 10px;
    }
  }
}

/**************************************************************
  popup : 05_매니지먼트_00데이터저장소_01생성_00myfile
**************************************************************/

/**************************************************************
  popup : 05_매니지먼트_00데이터저장소_01생성_02커넥션
**************************************************************/

.popup-datastore-connect .ddp-ui-folding.ddp-type {
  .ddp-wrap-edit2 {
    margin-top: 10px;
  }

  .ddp-box-code {
    padding: 14px 0 0 0;
    width: 630px;

    .ddp-list-code + .ddp-list-code,
    .ddp-btn-add {
      margin-top: 10px;
    }
  }

  .ddp-list-code .ddp-icon-del {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 5px;
    width: 11px;
    height: 12px;
    background: url(/assets/bi/images/icon_control.png) no-repeat;
    background-position: -26px -14px;

    &:hover {
      background-position-x: -62px;
    }
  }
}

/**************************************************************
  popup : 05_매니지먼트_00데이터저장소_01생성_02커넥션
**************************************************************/

.popup-datastore-schema {
  .ddp-type-top-option {
    .ddp-form-search {
      width: 270px;
    }

    .ddp-ui-rightoption .ddp-form-label2:first-of-type {
      margin-left: 0;
    }

    .ddp-wrap-add-column {
      float: left;
      position: relative;
      margin-left: 6px;

      &.ddp-selected {
        .ddp-box-column {
          display: block;
          z-index: 20;
        }

        .ddp-btn-selection {
          background-color: #d0d1d8;
        }
      }
    }
  }

  .ddp-wrap-add-column .ddp-box-column {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 19px 14px 13px 14px;
    margin-top: 5px;
    width: 470px;
    box-sizing: border-box;

    .ddp-wrap-edit3 {
      + .ddp-wrap-edit3 {
        margin-top: 12px;
      }

      .ddp-ui-label-name {
        margin-bottom: 5px;
        color: #4b515b;
      }
    }

    .ddp-option-form + .ddp-option-form {
      margin-top: 6px;
    }

    .ddp-box-button {
      padding: 5px 0 0 0;

      [class*='ddp-btn-'] {
        float: Left;
        margin-right: 3px;
        min-width: auto;
      }
    }

    .ddp-option-form.ddp-clear {
      margin-left: -2px;
      margin-right: -2px;

      [class*='ddp-col-'] {
        padding: 0 2px;
      }
    }
  }

  .ddp-wrap-value-setting .ddp-info {
    margin: 3px 0 0 0;
    color: #b7b9c2;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 3px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -42px -16px;
      content: '';
      vertical-align: middle;
    }
  }

  .ddp-ui-setting .ddp-ui-option-in .ddp-sub-option {
    padding: 6px 0 0 0;
  }

  .ddp-box-action {
    .ddp-info {
      clear: both;
      color: #b7b9c2;
      margin-top: 3px;
      font-size: 12px;
      font-style: italic;
    }

    &.ddp-type .ddp-change-type .ddp-box-layout4.ddp-change .ddp-change-select .ddp-info:before {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 3px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -42px -16px;
      content: '';
      vertical-align: middle;
    }
  }

  .ddp-ui-checkaction .ddp-wrap-action .ddp-info {
    clear: both;
    color: #b7b9c2;
    margin-top: 3px;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 3px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -42px -16px;
      content: '';
      vertical-align: middle;
    }
  }
}

/**********************************************************************************
    page : 데이터 프리퍼레이션 리메인
**********************************************************************************/

.ddp-page-rename {
  margin-top: -120px;
  padding: 120px 50px 49px 50px;
  height: 100%;
  box-sizing: border-box;
}

.ddp-wrap-rename {
  position: relative;
  height: 100%;

  .ddp-result {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
  }

  .ddp-col {
    float: right;
    color: #b7b9c2;
    font-size: 12px;
  }

  .ddp-name {
    display: block;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ddp-wrap-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 10px;
    height: 50%;
    border-top: 1px solid #b7b9c2;
    border-bottom: 1px solid #b7b9c2;
    box-sizing: Border-box;
    overflow: auto;
  }

  .ddp-wrap-grid {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 10px;
    height: 50%;
    box-sizing: Border-box;

    .ddp-ui-grid {
      height: 100%;
      border: 1px solid #b7b9c2;
    }
  }
}

table.ddp-table-column {
  width: 100%;
  table-layout: fixed;

  tr {
    th {
      padding: 14px 25px;
      color: #90969f;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }

    td {
      position: relative;
      padding: 5px 25px;
      color: #4b515b;
      font-size: 13px;
      border-bottom: 1px solid #fff;
      vertical-align: top;

      .ddp-view {
        display: none;
        position: absolute;
        top: 15px;
        right: 1px;
        width: 4px;
        height: 7px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -89px;
      }
    }

    &.ddp-edit td .ddp-view {
      background-position-x: -5px;
    }

    &:nth-child(odd) td {
      background-color: #fafafa;
    }

    td .ddp-box-edit {
      position: relative;
      border: 1px solid #b7b9c2;
      border-radius: 2px;
      background-color: #fff;

      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;
        content: '';
      }

      &.ddp-selected:before {
        display: none;
      }

      input {
        display: block;
        padding: 6px 15px 5px 15px;
        width: 100%;
        border: none;
        background: none;
        color: #4b515b;
        font-size: 13px;
        box-sizing: border-box;

        &:focus + .ddp-icon-edit {
          display: block;
        }
      }

      &:hover input,
      input:focus {
        padding-right: 30px;
      }

      &:hover .ddp-icon-edit {
        display: block;
      }

      .ddp-icon-edit {
        display: none;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background: url(/assets/bi/images/icon_edit.png) no-repeat;
      }
    }

    &.ddp-selected td {
      background-color: #f2f1f8;

      .ddp-view {
        display: block;
      }
    }

    &.ddp-edit td .ddp-txt-column {
      color: $primary;
    }

    td {
      .ddp-txt-error {
        display: block;
        padding: 5px 0 0 0;
      }

      .ddp-txt-column {
        line-height: 30px;
      }
    }
  }
}

/**********************************************************************************
	데이터소스 druid
**********************************************************************************/

.ddp-wrap-sheet.ddp-wrap-druid {
  height: 514px;

  .ddp-select-all {
    padding: 9px 14px;
    background-color: #f6f6f7;
    border-bottom: 1px solid #e6e6e9;
  }

  .ddp-list-sheet {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .ddp-data-tablename {
    padding: 9px 10px;
    color: #4c515a;
    font-size: 13px;
    border-bottom: 1px solid #e6e6e9;
    background-color: #f6f6f7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }
}

.ddp-wrap-sheet-top {
  padding-bottom: 10px;
}

.ddp-wrap-table-sheet {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
}

/**********************************************************************************
	데이터 프리퍼레이션 조인팝업
**********************************************************************************/

.ddp-ui-popuptype {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 960px;
  margin-left: -480px;
  background-color: #fff;
  z-index: 91;
  box-shadow: -1px -2px 15px 0px #eff0f1;
}

.ddp-layout-popuptype em.ddp-bg-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(208, 209, 216, 0.5);
  z-index: 126;
}

.ddp-ui-popup-title {
  padding: 49px 330px 49px 50px;
  text-align: left;
  overflow: hidden;

  span.ddp-txt-title-name {
    width: 100%;
    line-height: 32px;
    box-sizing: border-box;
    color: #222;
    font-size: 16px;
  }
}

.ddp-ui-popup {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 960px;
  margin-left: -480px;
  background-color: #fff;

  /* layer fill content */
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  z-index: 91;
}

.ddp-ui-pop-buttons {
  position: absolute;
  top: 49px;
  right: 47px;

  .ddp-btn-pop {
    float: left;

    + .ddp-btn-pop {
      margin-left: 4px;
    }
  }
}

.ddp-box-pop-contents {
  position: absolute;
  top: 104px;
  left: 50px;
  right: 50px;
  bottom: 35px;
  background-color: #fafafa;
}

.ddp-ui-pop-top {
  padding: 19px 10px;
  color: #b7b9c2;
  font-weight: bold;
  font-size: 13px;
}

.ddp-ui-popup-join {
  .ddp-ui-part {
    float: left;
    position: relative;
    width: 50%;
    height: 100%;
    padding: 0 9px;
    box-sizing: border-box;

    .ddp-ui-set {
      position: relative;
      padding-right: 56px;
      z-index: 20;

      /* padding: 10px; */
    }
  }

  .ddp-key .ddp-ui-part .ddp-ui-set {
    margin-top: -9px;
  }

  &.ddp-multy .ddp-key .ddp-ui-part {
    .ddp-ui-grid-form,
    .ddp-data-none {
      top: 82px;
    }
  }

  .ddp-ui-part .ddp-ui-set .ddp-wrap-drop-search {
    width: 100%;
  }
}

.ddp-wrap-drop-search ul.ddp-list-selectbox2 .ddp-txt-search {
  color: $primary;
}

.ddp-ui-popup-join {
  .ddp-ui-part {
    .ddp-ui-set {
      .ddp-list-tab-button {
        position: absolute;
        top: 50%;
        right: 0;
        margin: 0;
        margin-top: -13px;
      }

      .ddp-data-name {
        display: block;
        font-size: 14px;
        color: #444;
      }
    }

    .ddp-ui-grid-form {
      position: absolute;
      top: 91px;
      left: 10px;
      right: 10px;
      bottom: 93px;
      border: 1px solid #d0d1d8;
      background-color: #fff;
    }

    .ddp-data-none {
      position: absolute;
      top: 91px;
      left: 10px;
      right: 9px;
      bottom: 21px;
      text-align: center;
      border: 1px solid #e7e7ea;
      background-color: #fff;
      z-index: 1;

      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 100%;
        margin-right: 0;
        vertical-align: middle;
      }

      .ddp-data-contents {
        display: inline-block;
        color: #d0d1d8;
        font-size: 13px;
      }
    }

    .ddp-ui-pop-top {
      padding-left: 0;
    }
  }

  .ddp-box-pop-contents {
    padding: 0 10px;
    bottom: 384px;
    border-bottom: 1px solid #dbdce0;
    z-index: 60;

    &.ddp-default {
      bottom: 35px;

      .ddp-ui-joinkeys,
      .ddp-ui-resultinfo {
        display: none;
      }

      .ddp-ui-part .ddp-ui-grid-form {
        bottom: 20px;
      }
    }

    &.ddp-type {
      bottom: 318px;

      &.ddp-default {
        bottom: 55px;
      }

      .ddp-ui-part {
        .ddp-data-none {
          bottom: 22px;
          top: 116px;
        }

        .ddp-ui-grid-form {
          bottom: 22px;
          top: 116px;
          min-height: 100px;
          overflow: auto;
        }

        .ddp-checkall {
          position: absolute;
          top: 96px;
          left: 9px;
        }
      }
    }
  }
}

.ddp-comment {
  color: #b7b9c2;
  font-size: 11px;

  em {
    color: $primary;
  }
}

.ddp-ui-popup-join .ddp-box-pop-contents.ddp-type .ddp-ui-part .ddp-comment {
  position: absolute;
  top: 96px;
  right: 9px;

  em {
    color: $primary;
  }
}

.ddp-ui-grid-form {
  .ddp-wrap-scroll {
    height: 100%;
    overflow: auto;
  }

  table.ddp-table-grid-list {
    width: 100%;
    table-layout: fixed;
    background-color: #fff;

    tr {
      td {
        position: relative;
        padding: 5px 20px;
        color: #4b515b;
        font-size: 13px;
        border-left: 1px solid #ebebed;
        border-bottom: 1px solid #ebebed;
        cursor: pointer;
      }

      &:hover td {
        background-color: #f2f1f8;
      }

      &.ddp-selected td {
        background-color: #d6d9ef;

        &:last-of-type:after {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 14px;
          margin-top: -4px;
          content: '';
          width: 10px;
          height: 7px;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          background-position: -80px top;
        }
      }

      td {
        &:first-of-type {
          border-left: none;
        }

        em[class*='ddp-icon-type-'] {
          position: relative;
          top: 0;
          left: 0;
          margin-top: 0;
        }
      }
    }
  }
}

.ddp-ui-joinkeys {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 23px 0 0 0;
  text-align: center;

  .ddp-data-info {
    display: block;
    padding: 5px 0 22px 0;
    text-align: center;
    color: #b7b9c2;
    font-size: 12px;

    strong {
      font-weight: normal;
      color: $primary;
    }
  }

  .ddp-ui-error {
    display: block;
    padding: 5px 0 22px 0;
    text-align: center;
    color: #b7b9c2;
    font-size: 12px;
  }
}

.ddp-view-joinkeys {
  display: inline-block;

  .ddp-btn-solid {
    margin-left: 3px;
  }
}

.ddp-form-join {
  display: inline-block;
  width: 360px;
  border: 1px solid #d0d1d8;
  border-radius: 2px;
  background-color: #fff;
  vertical-align: middle;
  text-align: left;
  box-sizing: border-box;

  .ddp-data-column {
    float: left;
    width: 46%;
    padding: 7px 10px;
    color: #4b515b;
    font-size: 12px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ddp-wrap-drop-search {
    float: left;
    width: 46%;
    box-sizing: border-box;

    .ddp-type-selectbox2 {
      border: none;
    }
  }

  .ddp-type-selectbox {
    float: left;
    width: 46%;
    border: none;
    box-sizing: border-box;

    ul.ddp-list-selectbox.ddp-selectdown {
      top: 31px;
      max-height: 112px;
      overflow-y: auto;
    }
  }

  .ddp-data-default {
    float: left;
    width: 8%;
    padding: 6px 0 7px 0;
    border-left: 1px solid #d0d1d8;
    border-right: 1px solid #d0d1d8;
    text-align: center;
    box-sizing: border-box;
  }
}

.ddp-ui-join-type {
  position: absolute;
  bottom: 261px;
  left: 0;
  right: 0;
  height: 123px;
  padding: 0 50px;

  &.ddp-type {
    height: 261px;
    bottom: 56px;

    .ddp-data-join:first-of-type {
      z-index: 1;
    }

    .ddp-list-form-join {
      top: 105px;
    }

    .ddp-view-joinkeys {
      position: relative;
      float: left;
      margin-bottom: 10px;
      text-align: left;
      width: 100%;
      box-sizing: border-box;

      .ddp-form-join {
        width: 100%;
        box-sizing: border-box;
      }

      .ddp-btn-solid {
        position: absolute;
        top: 0;
        right: -65px;
        min-width: auto;
        background-color: #90969f;
        border: 1px solid #90969f;

        &.ddp-disabled {
          background-color: #d0d1d8;
          border: 1px solid #d0d1d8;
          cursor: no-drop;

          &:hover {
            background-color: #d0d1d8;
            border: 1px solid #d0d1d8;
          }
        }

        &:hover {
          background-color: #4b515b;
          border: 1px solid #4b515b;
        }
      }
    }

    .ddp-data-key {
      text-align: left;

      span.ddp-data-num {
        padding: 0 2px;
        font-size: 12px;
        color: #90969f;
        background-color: #f2f1f8;
      }
    }
  }
}

.ddp-ui-popup-join .ddp-btn-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
  padding: 15px 50px;

  .ddp-txt-error {
    font-style: italic;
  }

  .ddp-btn-solid2 {
    position: relative;
    margin-left: 10px;

    .ddp-ui-tooltip-info {
      display: none;
      right: 100%;
      left: inherit;
      top: 1px;
      margin-right: 9px;
    }

    &.ddp-disabled:hover .ddp-ui-tooltip-info {
      display: block;
    }
  }
}

.ddp-ui-data-join {
  height: 100%;
  border-bottom: 1px solid #dbdce0;
}

.ddp-ui-join-type .ddp-data-join {
  float: left;
  position: relative;
  width: 50%;
  height: 100%;
  padding: 0 84px 0 20px;
  box-sizing: border-box;

  .ddp-comment {
    position: absolute;
    top: 19px;
    right: 18px;
  }

  &.ddp-jointype {
    width: 48%;
  }

  .ddp-ui-label {
    display: block;
    padding: 20px 0 10px 0;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    overflow: hidden;

    span {
      &.ddp-title {
        float: left;
        padding-right: 10px;
      }

      &.ddp-data-detail {
        display: block;
        position: relative;
        top: 1px;
        color: #b7b9c2;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
      }

      &.ddp-txt-error {
        font-style: italic;
      }

      &.ddp-data-detail strong {
        color: $primary;
      }
    }

    .ddp-data-error {
      color: #ca4b4b;
    }
  }
}

.ddp-data-join {
  position: relative;
  text-align: center;

  ul.ddp-list-join {
    display: inline-block;
    overflow: Hidden;

    li {
      float: left;
      position: relative;

      a {
        display: block;
        position: relative;
        width: 68px;
        height: 62px;
        padding: 42px 0 0 0;
        border-radius: 2px;
        border: 1px solid transparent;
        box-sizing: border-box;
        color: #b7b9c2;

        em[class*='ddp-icon-join'] {
          position: absolute;
          top: 11px;
          left: 50%;
          margin: 0 0 0 -18px;
        }
      }
    }
  }
}

em {
  &.ddp-icon-joininner,
  &.ddp-icon-joinleft,
  &.ddp-icon-joinright,
  &.ddp-icon-joinouter {
    display: inline-block;
    width: 35px;
    height: 24px;
    background: url(/assets/bi/images/icon_join2.png) no-repeat;
    cursor: pointer;
  }

  &.ddp-icon-joinleft {
    background-position: -36px top;
  }

  &.ddp-icon-joinright {
    background-position: -73px top;
  }

  &.ddp-icon-joinouter {
    background-position: -109px top;
  }
}

.ddp-data-join {
  ul.ddp-list-join li {
    &.ddp-selected a,
    a:hover {
      color: #4b515b;
    }

    &.ddp-selected a em.ddp-icon-joininner,
    a:hover em.ddp-icon-joininner {
      background-position: left -25px;
    }

    &.ddp-selected a em.ddp-icon-joinleft,
    a:hover em.ddp-icon-joinleft {
      background-position: -36px -25px;
    }

    &.ddp-selected a em.ddp-icon-joinright,
    a:hover em.ddp-icon-joinright {
      background-position: -72px -25px;
    }

    &.ddp-selected a em.ddp-icon-joinouter,
    a:hover em.ddp-icon-joinouter {
      background-position: -108px -25px;
    }

    &.ddp-disabled:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(250, 250, 250, 0.4);
      cursor: no-drop;
    }

    a:hover {
      background-color: #fff;
      border: 1px solid #d0d1d8;
    }
  }

  .ddp-ui-nodata {
    padding: 10px 0 0 12px;
    color: #d0d1d8;
    font-size: 12px;
    text-align: left;
  }
}

ul.ddp-list-form-join {
  position: absolute;
  top: 45px;
  left: 20px;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  li {
    position: relative;
    padding: 0 20px 4px 0;
  }
}

.ddp-ui-join-type.ddp-type .ddp-list-form-join li {
  margin-right: 64px;
}

ul.ddp-list-form-join li {
  .ddp-form-join {
    width: 100%;
  }

  em.ddp-icon-control-cut {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.ddp-box-resultpreview {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 50px;
  min-height: 260px;

  .ddp-ui-pop-top {
    position: relative;
    padding-left: 0;

    .ddp-wrap-grid-option {
      position: absolute;
      top: 0;
      right: 0;

      .ddp-data-form {
        padding-top: 17px;
        padding-bottom: 0;
      }
    }
  }

  .ddp-ui-grid-form {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 36px;
    border: 1px solid #d0d1d8;
    border-radius: 2px;

    .ddp-ui-nodata {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      color: #b7b9c2;
      font-size: 13px;
      margin-top: -6px;
    }

    .ddp-txt-error {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      margin-top: -6px;
    }
  }

  .ddp-ui-pop-top .ddp-wrap-grid-option .ddp-data-form .ddp-wrap-popup2 {
    top: 38px;
  }
}

.ddp-ui-popup-join {
  .ddp-box-resultpreview {
    display: block;
  }

  .ddp-ui-resultinfo {
    display: block;
    height: 115px;
  }

  .ddp-box-resultpreview.ddp-box-type {
    height: 100%;
    left: 0;
    right: 0;

    .ddp-ui-grid-form {
      bottom: 55px;
    }
  }
}

/* 에러 메세지 */

.ddp-wrap-errortxt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    margin-right: 0;
    vertical-align: middle;
  }
}

.ddp-type-selectbox.ddp-type {
  position: relative;
  padding-left: 28px;
  height: 28px;
  box-sizing: border-box;

  ul.ddp-list-selectbox {
    position: relative;
    bottom: inherit;
    box-shadow: none;
    border: none;
  }

  .ddp-box-selecbox {
    display: none;
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    padding-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
  }

  em.ddp-icon-search {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 11px;
    width: 11px;
    height: 11px;
    background: url('/assets/bi/images/icon_search.png') no-repeat;
    margin-top: -6px;
    z-index: 1;
  }

  input.ddp-input-selectbox2 {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 28px;
    padding-right: 30px;
    color: $primary;
    border: none;
    box-sizing: border-box;
  }
}

/**********************************************************************************
	데이터 프리퍼레이션 데이터스냅샷
*********************************************************************************/

.ddp-wrap-preview {
  width: 880px;
  margin: 0 auto;
  border-top: 1px solid #ddd;
}

.ddp-ui-import-option {
  display: inline-block;
  width: 880px;
  padding: 55px 0 60px 0;
  text-align: left;
}

.ddp-wrap-preview .ddp-ui-source-info .ddp-wrap-grid-area {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.ddp-ui-edit .ddp-wrap-input-title {
  padding: 30px 0 28px 0;
  font-size: 28px;
  font-weight: 300;
  color: #222;
}

dl.ddp-dl-store {
  overflow: hidden;

  dt {
    float: left;
    padding: 28px 0 0 20px;
    width: 160px;
    color: #6f6f6f;
    font-size: 12px;
    box-sizing: border-box;
  }

  dd {
    display: block;
    padding: 28px 0 0 0;
    overflow: hidden;
  }
}

/***********************************************************************************************************
	page : 데이터 프리퍼레이션 데이터스냅샷 상세
************************************************************************************************************/

.ddp-wrap-snapshot {
  .ddp-data-log {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 255px;
    padding: 10px 17px 10px 16px;
  }

  .ddp-grid-label .ddp-txt-label {
    position: relative;
    top: 9px;
    margin-left: 16px;
    color: #36393f;
    font-weight: bold;
  }

  .ddp-btn-bg {
    float: right;
    margin-right: 16px;
  }

  .ddp-wrap-summary {
    padding: 23px 34px 10px 34px;
    overflow: Hidden;

    .ddp-data-summary {
      float: left;
      margin-right: 50px;

      .ddp-txt-label {
        color: #4c92e0;
        font-size: 13px;
      }

      .ddp-data-per {
        display: block;
        color: #4c92e0;
        font-weight: bold;
        font-size: 28px;
      }

      &.ddp-mismatch * {
        color: #eb5f58;
      }

      &.ddp-missing * {
        color: #90969f;
      }
    }

    .ddp-box-stick {
      clear: both;
      width: 100%;
      height: 17px;
      overflow: hidden;

      .ddp-data-valid {
        float: left;
        height: 100%;
        background-color: #4c92e0;
      }

      .ddp-data-mismatched {
        display: block;
        float: left;
        height: 100%;
        background-color: #eb5f58;
      }

      .ddp-data-missing {
        display: block;
        float: left;
        height: 100%;
        background-color: #d0d1d8;
      }
    }

    .ddp-ui-error {
      margin-top: 5px;
    }
  }

  .ddp-wrap-progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 255px;
    padding: 12px 16px;

    .ddp-data-status {
      font-size: 14px;
      font-weight: bold;
      color: #3e4148;

      .ddp-icon-info {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px -16px;
        vertical-align: middle;
      }
    }

    .ddp-box-loading {
      &.ddp-progress {
        border: none;
        box-shadow: none;
        margin-top: -49px;
      }

      &.ddp-cancel {
        margin-top: 50px;

        .ddp-pop-buttons {
          margin-top: 0;
        }
      }
    }
  }

  .ddp-wrap-grid {
    position: absolute;
    top: 125px;
    left: 17px;
    bottom: 255px;
    right: 16px;
    border: 1px solid #b7b9c2;
  }
}

.ddp-layout-pop-right .ddp-view-detail {
  background-color: #fff;
  padding: 28px 14px;

  dl {
    &.ddp-dl-detail {
      overflow: hidden;

      dt {
        float: left;
        width: 106px;
        padding-top: 13px;
        color: #b7bac1;
        font-size: 13px;
      }

      dd {
        display: block;
        padding-top: 13px;
        color: #4b515b;
        font-size: 13px;
        overflow: hidden;
      }

      &:first-of-type {
        dt,
        dd {
          padding-top: 0;
        }
      }
    }

    &.ddp-dl-detail2 {
      padding-top: 20px;

      &:first-of-type {
        padding-top: 0;
      }

      dt {
        margin-bottom: 10px;
        color: #b7bac1;
        font-size: 13px;
      }

      dd a.ddp-link-type {
        color: #35393f;
        font-size: 16px;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}

/**********************************************************************************
	데이터 프리퍼레이션 DB 타입
**********************************************************************************/

.ddp-ui-dbconnect {
  display: inline-block;
  width: 880px;
  padding: 0 0 27px 0;
  text-align: left;

  .ddp-type-selectbox.ddp-connection {
    width: 390px;
  }

  .ddp-wrap-edit3 {
    margin-top: 30px;
  }

  > .ddp-wrap-edit3:first-of-type {
    margin-top: 0;
  }

  .ddp-wrap-edit2 {
    &.ddp-mgt30 {
      margin-top: 30px !important;
    }

    margin-top: 30px;

    .ddp-data-result {
      padding-bottom: 9px;
      color: #4b515b;
      font-weight: 300;

      .ddp-link-edit2 {
        margin-left: 5px;
      }
    }
  }

  > .ddp-wrap-edit2:first-of-type {
    margin-top: 0;
  }
}

.ddp-wrap-edit2 label.ddp-label-type + .ddp-white-space {
  margin: 0 0 -10px 0;
}

.ddp-white-space {
  margin: -10px 0;
}

ul.ddp-list-dbtype {
  margin: -2px -2px;
  overflow: hidden;

  li {
    position: relative;
    float: left;
    width: 16.66666%;
    padding: 2px 2px 2px 2px;
    box-sizing: border-box;

    &.ddp-disabled {
      a {
        opacity: 0.5;
        cursor: no-drop;

        &:hover {
          border: 1px solid #d0d1d7;
          font-weight: normal;
          color: #90969f;
        }
      }

      &.ddp-selected a {
        opacity: 1;

        &:hover {
          border: 1px solid #4b515b;
          color: #4b515b;
          font-weight: bold;
        }
      }
    }

    &:last-of-type {
      &.ddp-disabled:before {
        right: 0;
      }

      padding-right: 0;
    }

    [class*='ddp-label-'] {
      width: 100%;
      box-sizing: border-box;
    }

    &.ddp-selected [class*='ddp-label-'] {
      padding-right: 20px;
      border: 1px solid #91969e;
      color: #4b515b;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
        width: 11px;
        height: 8px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -68px top;
        content: '';
      }
    }

    .ddp-label-dbtype {
      display: table;
      position: relative;
      height: 50px;
      padding: 0 0 0 44px;
      border-radius: 4px;
      font-size: 13px;
      color: #90969f;
      border: 1px solid #d0d1d7;
      box-sizing: border-box;
      vertical-align: middle;

      .ddp-txt-db {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }

      &:hover {
        border: 1px solid #4b515b;
        color: #4b515b;
        font-weight: bold;
      }

      .ddp-icon-db,
      .ddp-img-icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -12px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.ddp-ui-dbconnect {
  .ddp-ui-db-type {
    float: left;
    width: 389px;

    label.ddp-label-type {
      display: block;
      padding-bottom: 13px;
      color: #444;
      font-weight: bold;

      &.ddp-type {
        font-weight: bold;
        font-size: 13px;
      }
    }

    .ddp-wrap-edit3.ddp-type {
      margin-bottom: 20px;

      .ddp-label-type {
        display: table-cell;
      }
    }

    ul.ddp-list-db-type {
      float: left;
      margin-bottom: 10px;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #ddd;

      li {
        float: left;
        position: relative;
        width: 50%;
        height: 98px;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin-bottom: -1px;
        box-sizing: border-box;

        &:nth-child(2n) {
          border-right: none;
        }

        a {
          display: block;
          position: relative;
          width: 100%;
          height: 100%;

          em {
            &.ddp-img-oracle,
            &.ddp-img-mysql,
            &.ddp-img-sqlserver,
            &.ddp-img-hive,
            &.ddp-img-green,
            &.ddp-img-postgre,
            &.ddp-img-presto,
            &.ddp-img-phoenix,
            &.ddp-img-tibero,
            &.ddp-img-stage,
            &.ddp-img-amazon {
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              background: url(/assets/bi/images/icon_dbtype.png) no-repeat;
            }

            &.ddp-img-oracle {
              width: 107px;
              height: 38px;
              margin: -19px 0 0 -54px;
            }

            &.ddp-img-mysql {
              width: 96px;
              height: 50px;
              margin: -25px 0 0 -48px;
              background-position: left -39px;
            }

            &.ddp-img-sqlserver {
              width: 75px;
              height: 61px;
              margin: -32px 0 0 -38px;
              background-position: left -90px;
            }

            &.ddp-img-hive {
              width: 60px;
              height: 54px;
              margin: -27px 0 0 -30px;
              background-position: left -152px;
            }

            &.ddp-img-green {
              width: 107px;
              height: 27px;
              margin: -14px 0 0 -54px;
              background-position: left -206px;
            }

            &.ddp-img-postgre {
              width: 134px;
              height: 45px;
              margin: -23px 0 0 -67px;
              background-position: left -233px;
            }

            &.ddp-img-presto {
              width: 130px;
              height: 42px;
              margin: -21px 0 0 -65px;
              background-position: left -279px;
            }

            &.ddp-img-phoenix {
              width: 116px;
              height: 31px;
              margin: -15px 0 0 -58px;
              background-position: left -322px;
            }

            &.ddp-img-tibero {
              width: 118px;
              height: 40px;
              margin: -20px 0 0 -59px;
              background-position: left -354px;
            }

            &.ddp-img-stage {
              width: 108px;
              height: 24px;
              margin: -12px 0 0 -54px;
              background-position: left -395px;
            }

            &.ddp-img-amazon {
              width: 110px;
              height: 43px;
              margin: -22px 0 0 -55px;
              background-position: left -454px;
            }
          }
        }

        &.ddp-disabled {
          &:before {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            cursor: no-drop;
            content: '';
          }

          a em {
            &.ddp-img-oracle,
            &.ddp-img-mysql,
            &.ddp-img-sqlserver,
            &.ddp-img-hive,
            &.ddp-img-green,
            &.ddp-img-postgre,
            &.ddp-img-presto,
            &.ddp-img-phoenix,
            &.ddp-img-tibero,
            &.ddp-img-stage {
              background: url(/assets/bi/images/icon_dbtype_dim.png) no-repeat;
            }

            &.ddp-img-mysql {
              background-position: left -39px;
            }

            &.ddp-img-sqlserver {
              background-position: left -90px;
            }

            &.ddp-img-hive {
              background-position: left -152px;
            }

            &.ddp-img-green {
              background-position: left -206px;
            }

            &.ddp-img-postgre {
              background-position: left -233px;
            }

            &.ddp-img-presto {
              background-position: left -279px;
            }

            &.ddp-img-phoenix {
              background-position: left -322px;
            }

            &.ddp-img-tibero {
              background-position: left -354px;
            }

            &.ddp-img-stage {
              background-position: left -395px;
            }
          }
        }

        a em.ddp-icon-hover {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #444;
          z-index: 1;
        }

        &:nth-child(2n) a em.ddp-icon-hover {
          left: -1px;
        }

        a:hover em.ddp-icon-hover {
          display: block;
        }

        &.ddp-selected {
          em.ddp-icon-hover {
            display: block;
          }

          a .ddp-bg-selected {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 23px;
            background: #454545;

            em.ddp-icon-select {
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 11px;
              height: 8px;
              margin: -4px 0 0 -6px;
              background: url(/assets/bi/images/icon_select2.png) no-repeat;
              background-position: -27px top;
            }
          }
        }
      }
    }

    .ddp-type-selectbox {
      margin-bottom: 23px;
    }
  }

  .ddp-ui-db-option {
    float: right;
    width: 435px;
    padding-top: 45px;
    padding-bottom: 10px;

    .ddp-ui-buttons .ddp-ui-message {
      margin-top: 10px;
    }
  }

  .ddp-clear {
    [class*='ddp-col-'] {
      padding-left: 8px;
      padding-right: 8px;
    }

    &.ddp-db-url {
      .ddp-ui-error {
        display: none;
      }

      .ddp-error .ddp-ui-error {
        display: block;
      }

      .ddp-ui-error {
        position: relative;
        bottom: 0;
        left: 0;
      }
    }

    margin: 0 -8px;
  }

  .ddp-ui-db-option {
    .ddp-ui-message {
      float: left;
      width: 100%;
      margin-top: 30px;

      &.ddp-ui-info {
        margin-top: 0;
      }

      &.ddp-inline {
        display: inline-block;
      }
    }

    .ddp-wrap-edit2 label.ddp-label-type {
      font-weight: normal;
    }
  }
}

/*삭제 예정*/

/* //삭제 예정*/

.ddp-wrap-edit2 label.ddp-label-type .ddp-link-edit {
  float: right;
  position: relative;
  top: 6px;
  color: #90969f;
  font-weight: normal;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  em.ddp-icon-edit {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    background: url(/assets/bi/images/icon_edit2.png) no-repeat;
    background-position: -22px top;
    vertical-align: middle;
  }
}

.ddp-ui-dbconnect {
  .ddp-ui-db-option .ddp-ui-buttons {
    padding-top: 20px;
    white-space: nowrap;

    &.ddp-inline {
      .ddp-btn-type4,
      .ddp-btn-pop {
        display: inline-block;
        margin-right: 9px;
      }

      .ddp-ui-message {
        display: inline-block;
        float: inherit;
        width: inherit;

        span.ddp-data-error2 {
          display: inline-block;
          padding: 6px 0 0 0;
          white-space: nowrap;
        }
      }
    }
  }

  .ddp-wrap-dbaddress {
    float: left;
    width: 100%;

    .ddp-data-host {
      float: left;
      width: 325px;
    }

    .ddp-data-port {
      float: right;
      width: 100px;
    }
  }

  .ddp-ui-db-option2 {
    margin: 24px 0 0 0;
    padding: 0;
  }
}

.ddp-ui-db-option2 {
  clear: both;
  padding: 12px 0 0 0;

  .ddp-label-checkbox {
    position: relative;
    top: 6px;
    float: left;
    margin-right: 10px;
  }

  .ddp-input-typebasic {
    display: block;
    width: 237px;

    &.ddp-type {
      width: 463px;
    }
  }

  .ddp-wrap-error-type {
    overflow: hidden;

    .ddp-ui-message {
      margin-top: -6px;
    }
  }
}

/***********************************************************************************************************
	page : 데이터 프리퍼레이션 db타입 데이터 셋 생성
************************************************************************************************************/

.ddp-ui-import-option {
  display: inline-block;
  width: 880px;
  padding: 0 0 26px 0;
  text-align: left;

  .ddp-ui-textarea {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
  }

  ul.ddp-list-buttons {
    display: block;
    margin-bottom: 20px;

    li {
      font-size: 13px;
    }
  }

  .ddp-ui-input-form {
    padding-bottom: 42px;
  }

  label.ddp-label-type {
    font-size: 13px;
    color: #444;
    font-weight: bold;
  }

  .ddp-ui-edit-db {
    .ddp-form-input-dropdown .ddp-form-dropdown-white {
      padding: 0 35px 0 10px;

      .ddp-ui-drop-wrap {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 10px;
        padding: 7px 0;
      }
    }

    .ddp-label-type {
      display: block;
      padding-bottom: 15px;
    }
  }

  .ddp-ui-option-select {
    width: 100%;

    .ddp-ui-search-db {
      position: relative;
      margin-bottom: 10px;
      white-space: nowrap;

      .ddp-type-selectbox {
        display: inline-block;
        width: 270px;
        margin-right: 10px;
        box-sizing: border-box;
      }

      .ddp-txt-error {
        display: inline-block;
        position: relative;
        top: -3px;
      }

      .ddp-ui-search-table {
        .ddp-type-selectbox {
          display: inline-block;
          width: 600px;
          margin-right: 10px;
          box-sizing: border-box;
        }

        ul.ddp-list-selectbox {
          max-height: 500px;
        }
      }
    }

    .ddp-ui-buttons {
      position: absolute;
      bottom: 18px;
      right: 17px;
      overflow: hidden;

      .ddp-btn-type4 {
        float: left;
        margin-left: 10px;
      }

      .ddp-ui-message {
        float: left;
        padding: 7px 0 0 0;
      }
    }

    .ddp-ui-input-form {
      padding-bottom: 0;
    }

    .ddp-ui-edit-db {
      .ddp-wrap-edit {
        width: 100%;
        border-top: 1px solid #e7e7ea;
        border-bottom: 1px solid #e7e7ea;
      }

      .ddp-ui-edit {
        display: block;
        position: relative;
        width: 100%;
        height: 420px;
        padding: 28px 30px;
        font-size: 14px;
        color: #444;
        border: none;
        background-color: #f9f9f9;
        white-space: pre-wrap;
        box-sizing: border-box;

        &:focus {
          outline: none;
        }

        .ddp-wrap-editor {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #f9f9f9;
        }
      }

      .ddp-wrap-edit .ace_scroller {
        background-color: #f9f9f9;
      }
    }
  }

  .ddp-btn-preview {
    display: block;
    padding: 18px 0 0 0;
    text-align: right;

    a {
      color: #297bb8;
      font-size: 14px;
      line-height: 31px;
    }

    em.ddp-icon-preview {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_preview.png) no-repeat;
      vertical-align: middle;
    }
  }

  .ddp-ui-message {
    padding: 20px 0 0 0;
  }

  .ddp-ui-grid {
    clear: both;
    border: 1px solid #b7b9c2;
    height: 290px;
  }

  &.ddp-selected-table {
    .ddp-ui-textarea {
      display: none;
    }

    .ddp-ui-db-table {
      display: block;
      position: relative;
      z-index: 10;
    }
  }

  &.ddp-selected-query {
    .ddp-ui-textarea {
      display: block;
    }

    .ddp-ui-db-table {
      display: none;
    }
  }

  &.ddp-selected-table .ddp-ui-grid {
    height: 420px;
  }
}

/**********************************************************************************
	page : 데이터 프리퍼레이션 데이터소스 상세
**********************************************************************************/

.ddp-area-datadetail {
  &:first-of-type {
    margin-top: 15px;
  }

  position: relative;
  margin-top: 43px;

  label.ddp-label-detail {
    display: block;
    padding-bottom: 20px;
  }

  .ddp-type-search.type-metasearch {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
  }
}

.ddp-wrap-detail.ddp-type label.ddp-label-detail {
  font-weight: bold;
  font-size: 13px;
}

.ddp-wrap-datadetail {
  label.ddp-label-detail {
    color: #4c515a;
    font-size: 16px;
    font-weight: bold;

    .ddp-data-num {
      font-weight: normal;
    }

    &.ddp-font-weight {
      font-weight: 300;
    }

    [class*='ddp-btn'] {
      margin-left: 4px;
      font-weight: normal;
    }
  }

  .ddp-ui-information {
    padding: 0 0 0 27px;

    .ddp-data-name {
      padding-bottom: 20px;
      font-size: 22px;
    }

    .ddp-ui-popularity {
      display: inline-block;
      padding: 15px 15px 15px 19px;
      background-color: rgba(199, 202, 235, 0.15);

      .ddp-icon-popularity {
        float: left;
        position: relative;
        top: 1px;
        margin-right: 8px;
      }

      .ddp-txt-label {
        float: left;
        margin-right: 19px;
        color: $primary;
        font-size: 13px;
        font-weight: bold;
      }

      .ddp-bar {
        display: inline-block;
        position: relative;
        height: 16px;
        width: 439px;
        border-radius: 2px;
        background-color: #e4e5f3;

        span {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-radius: 2px;
          background-image: linear-gradient(to right, #c7cef6 0%, #9196f1 46%, #6361bc);
        }
      }
    }
  }
}

table.ddp-table-detail {
  .ddp-box-histogram {
    position: relative;
    height: 118px;
    padding: 10px;
    border: 1px solid #dddde2;
    box-sizing: border-box;

    .ddp-box-graph {
      width: 100%;
      height: 100%;
    }

    .ddp-wrap-link-detail {
      position: absolute;
      top: -19px;
      right: 5px;

      a.ddp-link-detail {
        font-style: italic;
        font-size: 12px;
      }
    }
  }

  th a.ddp-link-edit2 {
    display: inline-block;
    margin-top: 5px;
  }

  td .ddp-data-permission {
    display: block;
    width: 100%;
    margin-top: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    &:first-of-type {
      margin-top: 0;
    }

    span {
      &.ddp-data-workspace {
        display: inline-block;
        margin: 0 19px 0 9px;
        color: #4b515b;
        font-size: 13px;
      }

      &.ddp-data-name {
        color: #b7bac1;
        font-size: 12px;
      }
    }
  }
}

.ddp-ui-datadetail {
  position: relative;
  padding: 40px 20px 0 20px;
  border-top: 1px dotted #ccc;

  + .ddp-ui-datadetail {
    margin: 40px 0 0 0;
    border-top: 1px dotted #ccc;
  }

  &.ddp-border-none {
    border: none;
  }

  &.ddp-box-type .ddp-wrap-result {
    min-height: 233px;
  }

  &:first-of-type {
    border-top: none;
  }

  .ddp-label-detail {
    display: block;
    margin-bottom: 15px;
  }

  .ddp-box-synch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .ddp-btn-solid2.type-link {
    position: absolute;
    top: 40px;
    right: 60px;
  }

  &.type-detail {
    .ddp-label-detail {
      font-size: 16px;
    }

    table.ddp-table-detail3 {
      &.type-running {
        width: 100%;
        table-layout: fixed;

        .ddp-txt-long {
          position: relative;
          top: 5px;
        }
      }

      .ddp-txt-long {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.type-running .ddp-btn-pop {
        float: right;
        min-width: auto;
        margin: -3px 0;
      }
    }

    .ddp-detail-option {
      padding-bottom: 10px;

      .ddp-type-selectbox {
        width: 180px;
      }
    }

    .ddp-wrap-edit .ddp-ui-edit-option.ddp-inline .ddp-label-checkbox {
      margin-right: 11px;
    }

    table.ddp-table-detail {
      padding: 0 20px;
    }

    border-top: none;

    + .ddp-ui-datadetail.type-detail {
      margin-top: 0;
      padding-top: 30px;
    }
  }

  .ddp-btn-detail {
    position: absolute;
    top: 40px;
    right: 60px;

    [class*='ddp-btn'] {
      float: left;
      margin-left: 5px;
    }
  }
}

table.ddp-table-detail tr td {
  dl.ddp-dl-detail {
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }

    dt {
      float: left;
      width: 140px;
      font-weight: bold;
      color: #4b515b;
      font-size: 13px;
    }

    dd {
      display: block;
      color: #4b515b;
      font-size: 13px;
      overflow: hidden;

      .ddp-txt-detail {
        display: inline-block;
        position: relative;
        max-width: 100%;
        padding-right: 35px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        box-sizing: Border-box;

        a.ddp-link-edit2 {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      a.ddp-link-url {
        color: #4c92e0;
        font-size: 13px;
        text-decoration: underline;
      }
    }
  }

  .ddp-box-title {
    padding-bottom: 10px;
    font-size: 14px;
    color: #4b515b;
  }

  .ddp-box-sub {
    padding: 20px;
    background-color: #f6f6f7;

    dl.ddp-dl-detail {
      dt {
        width: 160px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.ddp-wrap-link-detail {
  &.ddp-selected .ddp-box-layout4 {
    display: block;
  }

  position: relative;
  max-width: 720px;
  text-align: right;

  .ddp-box-layout4 {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    margin: 5px 0 0 0;
    max-height: 290px;
    text-align: left;
    overflow-y: auto;

    .ddp-data-title {
      padding-bottom: 16px;
    }

    dl.ddp-dl-setting {
      margin: 0 14px;
      position: relative;

      dt {
        color: #b7bac1;
        font-size: 12px;
      }

      dd {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        color: #4b515b;
      }
    }

    .ddp-wrap-data-detail {
      padding: 10px 14px 10px 14px;

      .ddp-ui-top-title {
        padding-bottom: 10px;
        color: #4b515b;
        font-size: 12px;
        font-weight: bold;
      }

      ul.ddp-list-data-detail li {
        position: relative;
        margin-top: 5px;

        span.ddp-data-date {
          color: #4b515b;
          font-size: 12px;
        }

        .ddp-data-size {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          color: #b7bac1;
        }
      }
    }
  }
}

.ddp-wrap-datadetail {
  .ddp-box-monitoring {
    max-width: 1220px;
    height: 233px;
    padding: 10px;
    border: 1px solid #dddde2;
    box-sizing: border-box;
  }

  .ddp-ui-datadetail.type-detail:first-of-type {
    padding-top: 20px;
  }

  .ddp-part {
    padding: 0 20px;

    .ddp-ui-datadetail {
      + .ddp-ui-datadetail {
        margin-top: 0;
      }

      float: left;
      width: 600px;
      padding: 40px 0 0 0;
      margin-right: 20px;
    }
  }

  .ddp-wrap-result {
    //width: 1220px;

    .ddp-wrap-grid-option {
      padding-bottom: 10px;
      //white-space: nowrap;

      .ddp-box-calen {
        width: 150px;
      }

      .ddp-wrap-edit {
        display: inline-block;
        margin-right: 30px;

        .ddp-type-selectbox {
          width: 132px;
          box-sizing: border-box;
        }
      }
    }

    .ddp-wrap-edit3 {
      width: auto;
    }
  }
}

.ddp-table-form tr td a.ddp-link-edit2 {
  float: right;
  margin-right: 30px;
}

/**********************************************************************************
	page : 데이터 프리퍼레이션 데이터소스 상세_데이서상세팝업
**********************************************************************************/

.ddp-pop-datadetail {
  overflow: inherit;

  .ddp-wrap-grid-option {
    .ddp-fleft {
      padding: 7px 14px 6px 14px;
    }

    .ddp-form-search {
      float: left;
      width: 150px;
      margin-right: 25px;
    }

    .ddp-wrap-edit3 {
      float: left;
      width: auto;

      label.ddp-label-type {
        width: inherit;
        padding-right: 15px;
      }
    }

    .ddp-btn-reset3 {
      float: left;
    }

    .ddp-form-label2 {
      float: Left;
      margin-left: 40px;

      .ddp-type-selectbox {
        background-color: transparent;

        &.ddp-selected .ddp-wrap-popup2 {
          white-space: nowrap;
          right: inherit;
        }
      }

      &.ddp-mgl0 {
        margin-left: 0;
      }

      .ddp-type-selectbox {
        width: 110px;
      }
    }

    .ddp-btn-reset3 {
      position: relative;
      top: 7px;
      margin-left: 10px;
    }

    .ddp-data-form {
      padding: 9px 13px 10px 13px;
    }
  }
}

.ddp-type-preview .ddp-ui-tab-contents.ddp-pop-datadetail .ddp-ui-grid2 {
  top: 53px;
}

.ddp-pop-datadetail {
  .ddp-wrap-colum-detail {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;

    &.ddp-info {
      top: 75px;
    }
  }

  .ddp-wrap-detail.ddp-type .ddp-ui-detail .ddp-ui-title2 {
    padding: 10px 20px 25px 20px;
    font-size: 13px;
    font-weight: bold;
  }

  .ddp-wrap-column-list {
    position: relative;
    float: left;
    width: 459px;
    height: 100%;
    border-right: 1px solid #e7e7ea;
    overflow-y: auto;
    overflow-x: hidden;

    .ddp-ui-column-list + .ddp-ui-column-list {
      border-top: 1px solid #e7e7ea;
    }

    .ddp-label-title {
      padding: 13px 18px;
      color: #4b515b;
      font-size: 14px;
      font-weight: bold;

      em {
        &.ddp-icon-database-b {
          margin-right: 13px;
          vertical-align: middle;
        }

        &.ddp-icon-sub {
          display: inline-block;
          position: relative;
          top: -8px;
          margin-right: 8px;
          width: 17px;
          height: 17px;
          background: url(/assets/bi/images/icon_subline.png) no-repeat;
        }
      }
    }
  }

  .ddp-wrap-sourcename .ddp-label-title,
  .ddp-data-details .ddp-label-title {
    padding: 13px 18px;
    color: #4b515b;
    font-size: 14px;
    font-weight: bold;

    em {
      &.ddp-icon-database-b {
        margin-right: 13px;
        vertical-align: middle;
      }

      &.ddp-icon-sub {
        display: inline-block;
        position: relative;
        top: -8px;
        margin-right: 8px;
        width: 17px;
        height: 17px;
        background: url(/assets/bi/images/icon_subline.png) no-repeat;
      }
    }
  }

  .ddp-wrap-colum-detail .ddp-wrap-detail {
    float: left;
    width: 100%;
    height: 100%;
    margin-left: -460px;
    padding-left: 476px;
    padding-right: 16px;
    box-sizing: border-box;
    overflow-y: auto;

    .ddp-ui-detail {
      padding-bottom: 42px;
      border-top: 1px solid #e7e7ea;

      &:first-of-type {
        border-top: none;
      }
    }
  }

  .ddp-ui-detail .ddp-box-histogram {
    height: 61px;
    border: 1px solid #dadada;
    box-sizing: border-box;

    &.ddp-type {
      height: 125px;
    }
  }

  .ddp-ui-tab .ddp-box-layout4.ddp-information {
    position: fixed;
    top: 120px;
    left: 450px;
    z-index: 55;
    width: 270px;
    height: 310px;
    box-sizing: border-box;
    overflow: auto;
  }
}

ul.ddp-ui-tab.ddp-type li .ddp-box-layout4.ddp-information a {
  padding: 0;
  border: none;
}

.ddp-box-layout4.ddp-information {
  .ddp-data-type {
    display: inline-block;
    margin-left: 9px;

    &:first-of-type {
      margin-left: 0;
    }

    em[class*='ddp-icon-type-'] {
      position: relative;
      left: 0;
      margin-right: 3px;
    }
  }

  .ddp-view-join {
    padding: 0 15px;

    .ddp-data-name {
      display: block;
      padding: 8px 10px;
      background-color: #4b515b;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;

      em.ddp-icon-database-w {
        margin-right: 5px;
      }
    }
  }

  .ddp-list-join {
    padding-bottom: 17px;

    li {
      &:first-of-type {
        padding-top: 0;

        &:before {
          display: none;
        }
      }

      position: relative;
      float: inherit;
      padding-top: 10px;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 14px;
        border-left: 1px solid #374050;
      }

      &:last-of-type:before {
        height: 25px;
      }

      .ddp-data-joinname {
        float: left;
        width: 100%;

        &.ddp-2depth {
          position: relative;
          padding: 12px 0 0 22px;
          box-sizing: border-box;

          &:before {
            display: inline-block;
            content: '';
            position: absolute;
            top: -10px;
            bottom: 0;
            left: 36px;
            border-left: 1px solid #374050;
          }
        }

        &:before {
          display: inline-block;
        }

        em {
          &[class*='ddp-icon-join-'] {
            float: left;
            width: 28px;
            height: 18px;
            background: url(/assets/bi/images/icon_join_s.png) no-repeat;
            z-index: 1;
          }

          &.ddp-icon-join-left {
          }

          &.ddp-icon-join-right {
            background-position: left -19px;
          }

          &.ddp-icon-join-inner {
            background-position: left -38px;
          }

          &.ddp-icon-join-outer {
            background-position: left -57px;
          }
        }
      }

      .ddp-list-name {
        display: block;
        position: relative;
        top: 1px;
        padding-left: 19px;
        color: #4b515b;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;

        &:before {
          display: inline-block;
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 15px;
          border-bottom: 1px solid #374050;
        }
      }
    }
  }
}

/**************************************************************
  Page : 워크벤치 상세 (lnb)
**************************************************************/

.page-workbench {
  .ddp-ui-benchlnb .ddp-data-name .ddp-txt-dataname {
    padding-left: 30px;
  }

  .ddp-db-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;

    .icon-db-tibero-w {
      position: relative;
      top: -4px;
    }

    [class*='ddp-icon-data-'] {
      display: inline-block;
      background: url(/assets/bi/images/icon_dbtype_s.png) no-repeat;
    }

    .ddp-icon-data-sql {
      width: 19px;
      height: 22px;
      background-position: -23px -22px;
    }

    .ddp-icon-data-tibero {
      width: 19px;
      height: 22px;
      background-position: left -135px;
    }

    .ddp-icon-data-oracle {
      width: 21px;
      height: 21px;
      background-position: left top;
    }

    .ddp-icon-data-db {
      width: 16px;
      height: 19px;
      background-position: left -158px;
    }

    .ddp-icon-data-phoenix {
      width: 16px;
      height: 24px;
      background-position: left -110px;
    }

    .ddp-icon-data-post {
      width: 22px;
      height: 22px;
      background-position: left -45px;
    }

    .ddp-icon-data-hive {
      width: 24px;
      height: 22px;
      background-position: left -68px;
    }

    .ddp-icon-data-presto {
      width: 20px;
      height: 17px;
      background-position: left -91px;
    }
  }

  .ddp-wrap-list-search {
    padding: 0 18px;

    .ddp-ui-list-search {
      position: relative;
      padding-left: 22px;
      overflow: hidden;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 11px;
        height: 11px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
        content: '';
      }

      input {
        display: block;
        width: 100%;
        padding: 16px 0 16px 0;
        background: none;
        border: none;
        color: #fff;
        font-size: 13px;
      }

      .ddp-btn-search-close {
        display: inline-block;
        padding: 5px;
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -8px;
        cursor: pointer;
        font-size: 0;

        &:before {
          display: inline-block;
          width: 7px;
          height: 7px;
          background: url(/assets/bi/images/btn_sclose.png) no-repeat;
          background-position: -8px -38px;
          content: '';
        }
      }
    }

    a.ddp-btn-refresh {
      float: right;
      position: relative;
      top: 18px;
      width: 13px;
      height: 12px;
      background: url(/assets/bi/images/icon_refresh2.png) no-repeat;

      &:hover {
        background-position: -14px top;
      }
    }
  }

  .ddp-ui-tablelist {
    top: 175px;

    .ddp-list-table {
      padding-top: 10px;
      box-sizing: border-box;
    }
  }

  .ddp-tablelist-header {
    padding: 2px 0;
    height: 25px;
    border-top: 1px solid #4b515b;
    border-bottom: 1px solid #4b515b;
    background-color: rgba(75, 81, 91, 0.3);
    box-sizing: border-box;

    .ddp-table-num {
      float: right;
      padding-right: 18px;
      color: #90969f;
      font-weight: 300;

      span.ddp-number {
        font-weight: normal;
      }
    }

    .ddp-table-title {
      display: inline-block;
      padding-left: 18px;
      color: #90969f;
      font-size: 12px;
      overflow: Hidden;
      cursor: pointer;
      box-sizing: border-box;

      [class*='ddp-icon-sort'] {
        display: inline-block;
        position: relative;
        width: 19px;
        height: 19px;
        font-size: 0;
        vertical-align: middle;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 10px;
          margin: -5px 0 0 -4px;
          background: url(/assets/bi/images/icon_sorting.png);
          content: '';
        }
      }

      .ddp-icon-sort-asc:before {
        background-position: -8px top;
      }

      .ddp-icon-sort-des:before {
        background-position: -16px top;
      }
    }
  }

  .ddp-ui-benchlnb .ddp-data-namesub .ddp-data-in {
    .ddp-wrap-search {
      padding: 0 15px;
    }

    .ddp-box-layout4 {
      width: 275px;

      .ddp-form-search {
        margin: 0;
        width: 100%;

        input {
          letter-spacing: -0.5px;
        }
      }
    }
  }

  .ddp-ui-tablelist .ddp-ui-pageing {
    position: relative;
    height: 35px;

    .ddp-data-page {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px 0;
      text-align: center;
      color: #90969f;
      font-size: 12px;
    }

    .ddp-btn-prev,
    .ddp-btn-next {
      position: relative;
      z-index: 1;
    }
  }

  .ddp-wrap-editor .ddp-box-layout4 {
    .ddp-btn-close {
      z-index: 1;
    }

    &.ddp-shortcuts {
      display: block;
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      width: auto;
      overflow: auto;
      background-color: #fff;
      z-index: 10;

      * {
        font-family: 'SpoqaHanSans', sans-serif;
      }

      .ddp-table-pop {
        white-space: nowrap;
        width: auto;
      }
    }
  }

  .ddp-ui-tabs ul.ddp-list-tabs li .ddp-wrap-retry {
    position: absolute;
    bottom: 100%;
    padding-bottom: 5px;

    .ddp-box-tabs-popup {
      position: relative;
    }
  }

  .ddp-ui-query .ddp-box-editer .ddp-wrap-foot-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 39px;
    border: 1px solid #ddd;
    background-color: #f7f7f8;
    z-index: 10;

    .ddp-link-query {
      float: left;
      padding: 12px 0 0 16px;
      color: #90969f;
      font-size: 13px;

      [class*='ddp-icon-query-'] {
        margin-right: 5px;
      }

      &:hover {
        color: #4b515b;
      }
    }

    .ddp-icon-button {
      float: right;
      padding: 0 13px 0 0;

      .ddp-btn-query {
        float: left;
        position: relative;
        margin: 11px 0 0 14px;
        width: 20px;
        height: 20px;

        [class*='ddp-icon-query-'] {
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }

        .ddp-ui-tooltip-info {
          right: -14px;
          left: inherit;
          margin-bottom: 8px;

          .ddp-icon-view-down {
            right: 23px;
            left: inherit;
            margin: 0;
          }
        }
      }
    }
  }

  .ddp-link-query {
    .ddp-icon-query-full,
    .ddp-icon-query-partial {
      display: inline-block;
      background: url(/assets/bi/images/icon_query2.png) no-repeat;
    }
  }

  .ddp-icon-button {
    .ddp-icon-query-clear,
    .ddp-icon-query-beautifier,
    .ddp-icon-query-history,
    .ddp-icon-query-shortcuts {
      display: inline-block;
      background: url(/assets/bi/images/icon_query2.png) no-repeat;
    }
  }

  .ddp-link-query {
    .ddp-icon-query-full {
      width: 8px;
      height: 12px;
    }

    &:hover .ddp-icon-query-full {
      background-position-x: -9px;
    }

    .ddp-icon-query-partial {
      width: 13px;
      height: 12px;
      background-position: left -13px;
    }

    &:hover .ddp-icon-query-partial {
      background-position-x: -14px;
    }
  }

  .ddp-icon-button {
    .ddp-icon-query-clear {
      width: 18px;
      height: 16px;
      margin: -8px 0 0 -9px;
      background-position: left -26px;

      &:hover {
        background-position-x: -19px;
      }
    }

    .ddp-icon-query-beautifier {
      width: 19px;
      height: 16px;
      margin: -8px 0 0 -10px;
      background-position: left -43px;

      &:hover {
        background-position-x: -21px;
      }
    }

    .ddp-icon-query-history {
      width: 16px;
      height: 16px;
      margin: -8px 0 0 -8px;
      background-position: left -60px;

      &:hover,
      &.ddp-selected {
        background-position-x: -17px;
      }
    }

    .ddp-icon-query-shortcuts {
      width: 16px;
      height: 16px;
      margin: -8px 0 0 -8px;
      background-position: left -77px;

      &.ddp-selected,
      &:hover {
        background-position-x: -17px;
      }
    }

    .ddp-btn-query {
      position: relative;
    }
  }

  .ddp-wrap-editor {
    .ddp-box-layout4 {
      &.ddp-history {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        bottom: 8px;
        width: 350px;
        overflow: auto;
        background-color: #fff;
        z-index: 10;

        * {
          font-family: 'SpoqaHanSans', sans-serif;
        }
      }

      .ddp-data-title {
        position: relative;
      }

      &.ddp-history {
        .ddp-ui-history-option {
          float: right;
          top: 3px;
          padding-right: 0;

          .ddp-history-search {
            float: left;
            margin-left: 10px;

            a.ddp-icon-search {
              display: inline-block;
              width: 12px;
              height: 12px;
              background: url('/assets/bi/images/icon_search.png') no-repeat;
              background-position: left -27px;
            }
          }

          .ddp-btn-close2 {
            display: inline-block;
            width: 11px;
            height: 11px;
            margin-left: 10px;
            background: url(/assets/bi/images/btn_close.png) no-repeat;
            background-position: -24px -58px;

            &:hover {
              background-position-x: -36px;
            }
          }
        }

        .ddp-data-list-num {
          padding: 0 0 3px 15px;
          color: #b7b9c2;
          font-size: 12px;
        }

        .ddp-nodata {
          padding: 0 0 15px 15px;
          color: #b7b9c3;
          font-size: 12px;
        }

        .ddp-list-history li {
          a {
            display: block;
            padding: 9px 15px;

            &:hover {
              background-color: #f6f6f7;
            }
          }

          .ddp-data-history {
            overflow: hidden;

            .ddp-data-edit {
              color: #444;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .ddp-data-time {
              color: #90969f;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .ddp-ui-history-data {
            float: right;
            padding: 5px 0;

            .ddp-data-ms {
              color: #90969f;
              font-size: 12px;
            }

            .ddp-icon-status-success {
              display: inline-block;
              width: 13px;
              height: 13px;
              margin-left: 4px;
              background: url(/assets/bi/images/icon_status.png) no-repeat;
            }

            .ddp-icon-status-fail {
              display: inline-block;
              width: 13px;
              height: 13px;
              margin-left: 4px;
              background: url(/assets/bi/images/icon_status.png) no-repeat;
              background-position-x: -14px;
            }

            .ddp-icon-return {
              display: inline-block;
              width: 10px;
              height: 11px;
              margin-left: 8px;
              background: url(/assets/bi/images/icon_control.png) no-repeat;
              background-position: left -266px;
            }

            .ddp-icon-view {
              display: inline-block;
              width: 6px;
              height: 11px;
              margin-left: 12px;
              background: url(/assets/bi/images/icon_list_view.png) no-repeat;
              background-position: left -10px;
            }
          }
        }

        .ddp-ui-history-option {
          .ddp-history-search {
            &.ddp-selected {
              background-color: #fff;
            }

            a.ddp-icon-search:hover {
              background-position-x: -13px;
            }
          }

          .ddp-icon-del {
            float: left;
            width: 11px;
            height: 12px;
            margin-left: 10px;
            background: url(/assets/bi/images/icon_control.png) no-repeat;
            background-position: -50px -14px;
          }
        }
      }
    }

    .ddp-ui-history-option {
      .ddp-icon-del:hover {
        background-position-x: -62px;
      }

      .ddp-history-search {
        .ddp-type-search {
          display: none;
        }

        &.ddp-selected .ddp-type-search {
          display: block;
        }

        .ddp-type-search {
          position: absolute;
          top: 9px;
          left: 200px;
          right: 55px;
          padding: 0 50px 0 25px;
          background-color: #f6f6f7;

          &:before {
            left: 7px;
          }

          .ddp-btn-delete {
            right: 28px;
          }

          .ddp-btn-back {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 28px;
            background-color: #b7b9c3;

            &:before {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 50%;
              margin: -6px 0 0 -3px;
              width: 6px;
              height: 11px;
              background: url(/assets/bi/images/icon_list_view.png) no-repeat;
              background-position: left -37px;
              content: '';
            }
          }
        }
      }
    }

    .ddp-box-layout4.ddp-history .ddp-btn-more {
      display: inline-block;
      padding: 10px 0;
      color: #4b515b;
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .ddp-ui-rnb-contents {
    .ddp-ui-var-plus {
      padding-top: 0;

      .ddp-ui-addview {
        margin-top: 0;
      }
    }

    .ddp-ui-title {
      margin-left: 0;
      padding-left: 15px;

      span.ddp-data-num {
        margin-left: 3px;
      }
    }

    .ddp-ui-var-plus .ddp-ui-addview .ddp-box-addview {
      margin-bottom: 2px;
    }
  }

  .ddp-ui-var-plus .ddp-list-form-plus .ddp-wrap-link-list.ddp-edit {
    background-color: #e7e7ea;
  }
}

/**************************************************************
  Page : 워크벤치 상세 (contents)
**************************************************************/

/*.page-workbench .ddp-box-editer .ddp-wrap-edit-foot {bottom:6px; z-index:30;}*/

/**************************************************************
  Page : 워크벤치 상세 (rnb)
**************************************************************/

/**************************************************************
  Page : 워크벤치 상세 (rnb) Global variable
**************************************************************/

.ddp-ui-var-plus .ddp-list-form-plus .ddp-link-buttons .ddp-btn-pop {
  float: left;
  min-width: inherit;
  padding: 6px 12px;
  margin-left: 4px;
}

/***********************************************************************************************************
	page : 워크벤치 detail
************************************************************************************************************/

.ddp-wrap-workbench {
  position: absolute;
  top: 101px;
  left: 0;
  right: 0;
  bottom: 0;
  width: initial;
  height: auto;
  overflow: hidden;

  .ddp-ui-rnb {
    position: relative;
    display: table-cell;
    width: 52px;
    background-color: #f8f7f9;
    z-index: 31;
    vertical-align: top;
  }

  .ddp-layout-table {
    display: table;
    width: 100%;
    height: 100%;
  }

  .ddp-ui-benchlnb {
    display: table-cell;
    position: relative;
    width: 455px;
    height: 100%;
    background-color: #35393f;
    vertical-align: top;
    z-index: 20;

    &.ddp-fold {
      width: 280px;

      .ddp-view-benchlnb {
        width: 100%;
      }

      .ddp-ui-benchtable {
        display: none;
      }
    }

    .ddp-view-benchlnb {
      position: relative;
      float: left;
      height: 100%;
      width: 55%;
    }
  }
}

.ddp-ui-benchlnb {
  &.ddp-close {
    width: 9px;
    min-width: 9px;
  }

  &.ddp-none {
    display: none;
  }

  &.ddp-close {
    .ddp-view-benchlnb {
      width: 9px;
      min-width: auto;
    }

    .ddp-box-benchlnb,
    .ddp-ui-benchtable {
      display: none;
    }
  }
}

.ddp-box-benchlnb {
  width: 100%;
  height: 100%;
}

.ddp-ui-benchlnb {
  .ddp-btn-folding {
    position: absolute;
    top: 23px;
    right: 18px;
    z-index: 1;

    &:before {
      display: inline-block;
      content: '';
      width: 14px;
      height: 12px;
      background: url(/assets/bi/images/icon_folding2.png) no-repeat;
      background-position: -15px top;
    }

    &:hover:before {
      background-position: left top;
    }
  }

  &.ddp-close .ddp-btn-folding {
    display: inline-block;
    position: absolute;
    top: 0;
    right: -14px;
    width: 23px;
    height: 91px;
    background-color: #4b515b;

    &:before {
      position: absolute;
      top: 23px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 11px;
      background-position: -15px -14px;
    }

    &:hover:before {
      background-position: left -14px;
    }
  }

  .ddp-wrap-name {
    background-color: #4b515b;
  }

  .ddp-data-name {
    position: relative;
    padding: 18px 35px 8px 18px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    box-sizing: border-box;
  }

  .ddp-wrap-name > .ddp-data-name {
    height: 46px;
  }

  .ddp-data-name {
    .ddp-txt-dataname {
      display: inline-block;
      max-width: 100%;
      position: relative;
      padding-right: 24px;
      box-sizing: border-box;

      .ddp-txt-in {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
      }
    }

    .ddp-wrap-info {
      position: absolute;
      top: 1px;
      right: 0;
      padding: 3px;
      font-size: 0;

      .ddp-btn-info2 {
        display: inline-block;
        vertical-align: middle;
      }

      .ddp-box-layout4 {
        display: none;
        width: auto;
        min-width: 320px;
        max-width: 520px;
        position: fixed;
        top: 140px;
        left: 20px;
        z-index: 10;

        .ddp-form-scroll {
          max-height: 480px;
          overflow: auto;
        }

        table.ddp-table-info {
          table-layout: inherit;

          tr td {
            word-break: break-all;
          }
        }
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }
    }

    .ddp-btn-info2 {
      &:before {
        display: inline-block;
        content: '';
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: left -30px;
      }

      &:hover:before {
        background-position: -14px -30px;
      }
    }
  }

  .ddp-data-namesub {
    position: relative;
    height: 56px;
    padding: 15px 50px 10px 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    cursor: pointer;
    box-sizing: border-box;

    .ddp-data-in {
      position: relative;
      padding: 8px 20px 8px 10px;
      height: 31px;
      border-radius: 2px;
      background-color: #3c4047;
      box-sizing: border-box;

      &.ddp-disabled {
        opacity: 0.3;
        cursor: no-drop;
      }

      em.ddp-icon-view {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 9px;
        width: 7px;
        height: 4px;
        margin-top: -2px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -8px top;
        content: '';
      }

      &.ddp-selected em.ddp-icon-view {
        transform: rotate(180deg);
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        padding: 10px 0 20px 0;
        box-sizing: border-box;

        .ddp-form-search {
          width: 180px;
        }
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;

        &.ddp-datasearch {
          padding-bottom: 37px;
        }
      }
    }
  }
}

.ddp-box-layout4.ddp-datasearch {
  padding-bottom: 36px;

  .ddp-form-search {
    width: 250px;
  }

  .ddp-link-join {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 10px;
    color: #4b515b;
    font-size: 13px;
    border-top: 1px solid #e7e7ea;

    .ddp-btn-option {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -8px;
    }

    &:hover .ddp-btn-option {
      background-position: -16px top;
    }
  }
}

.ddp-ui-benchlnb .ddp-data-namesub {
  .ddp-link-btn {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 11px;
    height: 30px;
    width: 30px;
    background-color: #3c4047;
    border-radius: 2px;

    &:hover {
      background-color: #2f3339;
    }

    &.ddp-disabled {
      opacity: 0.3;
      cursor: no-drop;

      &:hover {
        background-color: #3c4047;
      }
    }

    em.ddp-icon-table {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      width: 13px;
      height: 14px;
      background: url(/assets/bi/images/icon_data.png) no-repeat;
      background-position: -67px top;
    }
  }

  &.ddp-selected .ddp-box-layout4 {
    display: block;
  }

  .ddp-icon-database3 {
    display: inline-block;
    float: left;
    margin-right: 8px;
    width: 12px;
    height: 15px;
    background: url(/assets/bi/images/icon_database.png) no-repeat;
    background-position: left -14px;
  }

  span.ddp-txt-namesub {
    display: block;
    position: relative;
    color: #b7b9c2;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }

  &:hover em.ddp-icon-view {
    opacity: 1;
  }
}

.ddp-wrap-tablelist {
  .ddp-ui-title {
    position: relative;
    padding: 15px 18px 0 18px;
    color: #a0acbf;
    font-size: 12px;
  }

  .ddp-form-search {
    width: 235px;
    margin: 10px 25px 13px 25px;

    input {
      border-bottom: 1px solid #656971;
      color: #d0d1d8;
    }

    .ddp-btn-search-close:before {
      background-position-x: -8px;
    }
  }
}

.ddp-ui-tablelist {
  position: absolute;
  top: 157px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 36px;

  a.ddp-btn-more {
    display: inline-block;
    margin: 12px 0 0 25px;
    color: #d0d1d8;
    font-size: 12px;
    text-decoration: underline;
  }

  .ddp-ui-pageing {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

ul.ddp-list-table {
  height: 100%;
  overflow: auto;

  li {
    position: relative;
    padding: 8px 58px 8px 43px;
    cursor: pointer;

    &:hover {
      background-color: #2f3339;
    }

    .ddp-data-tablename {
      display: block;
      color: #d0d1d8;
      font-size: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }
  }
}

.ddp-box-tableinfo {
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 2px;
}

ul.ddp-list-table li {
  em.ddp-icon-table {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 25px;
    width: 10px;
    height: 10px;
    background: url(/assets/bi/images/icon_table.png) no-repeat;
  }

  .ddp-ui-buttons {
    position: absolute;
    top: 5px;
    right: 16px;

    .ddp-icon-info.ddp-selected em.ddp-icon-info:before {
      background-position: -14px -30px;
    }
  }

  &:hover .ddp-ui-buttons {
    display: block;
  }

  .ddp-ui-buttons {
    .ddp-icon-info {
      float: left;
      position: relative;
      top: 1px;
    }

    .ddp-icon-schemainfo {
      float: left;
    }
  }

  em {
    &.ddp-icon-info {
      display: inline-block;
      padding: 3px;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px -30px;
      }

      &.ddp-disabled {
        cursor: no-drop;

        &:before,
        &:hover:before {
          background-position: -28px -30px;
        }
      }

      &:hover:before,
      &.ddp-selected:before {
        background-position: -14px -30px;
      }
    }

    &.ddp-icon-tableinfo {
      display: inline-block;
      position: relative;
      top: 2px;
      padding: 3px;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        width: 13px;
        height: 10px;
        background: url(/assets/bi/images/btn_schema.png) no-repeat;
      }

      &:hover:before,
      &.ddp-selected:before {
        background-position: -14px top;
      }

      &.ddp-disabled {
        cursor: no-drop;

        &:before,
        &:hover:before {
          background-position: left top;
        }
      }
    }
  }
}

/* 팝업 */

.ddp-box-layout4 {
  position: relative;
  width: 211px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  /* drop shadow */

  &.ddp-basic {
    padding: 17px;
    color: #4b515b;
    font-size: 12px;
    line-height: 18px;
  }

  &.ddp-layout-info {
    position: fixed;
    left: 270px;
    min-width: 220px;
    width: auto;
    max-width: 350px;
    max-height: 520px;
    z-index: 70;
    overflow: auto;

    &.ddp-info-type {
      max-width: 520px;
      min-width: 200px;
      overflow: hidden;
      table-layout: inherit;

      .ddp-form-scroll {
        max-height: 476px;
        overflow: auto;
      }

      table tr td {
        word-break: break-all;
      }
    }
  }

  .ddp-label-table {
    padding: 0 15px;
    margin-bottom: -4px;
    max-width: 520px;

    .ddp-txt-label {
      display: inline-block;
      position: relative;
      padding-left: 6px;
      color: #4b515b;
      font-size: 12px;

      &:before {
        position: absolute;
        top: 3px;
        left: 0;
        height: 9px;
        border-left: 2px solid #4b515b;
        content: '';
      }
    }
  }

  &.ddp-layout-info.ddp-info-type .ddp-data-title {
    font-size: 14px;
  }

  &.ddp-layout-tableinfo {
    position: fixed;
    left: 270px;
    min-width: 420px;
    width: auto;
    padding: 0 20px;
    max-width: 650px;
    max-height: 500px;
    z-index: 70;
    overflow: auto;
    box-sizing: border-box;

    .ddp-form-search {
      width: 100%;
      margin-left: 0;
      box-sizing: border-box;
    }

    .ddp-data-title {
      padding-left: 0;
      padding-right: 0;
    }

    .ddp-ui-list-type {
      margin: 10px -20px 10px -20px;

      ul {
        padding: 0;
      }
    }
  }

  a.ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 17px;
    right: 20px;
    width: 11px;
    height: 11px !important;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: -12px -58px;
  }

  .ddp-data-explain {
    padding: 11px 15px;
    color: #b7bac1;
    font-size: 12px;
    border-bottom: 1px solid #e7e7ea;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
  }

  .ddp-txt-strong {
    padding: 20px 0 0 0;
    color: #d62d1f;
    font-size: 12px;
  }

  table.ddp-table-info {
    width: 100%;
    padding: 10px 0;

    tr {
      th {
        padding: 2px 15px 2px 15px;
        color: #b7bac1;
        text-align: left;
        font-size: 12px;
        vertical-align: top;
      }

      td {
        padding: 2px 15px 2px 0;
        color: #4b515b;
        font-size: 12px;
      }
    }
  }

  &.ddp-information table.ddp-table-info tr td {
    text-align: left;
  }

  table.ddp-table-info tr td a.ddp-link {
    text-decoration: underline;
    color: #4c92e0;
  }

  .ddp-form-search {
    width: 168px;
    margin-left: 20px;
  }

  .ddp-data-det2 {
    padding: 10px 13px 0 13px;
    color: #9c9fa9;
    font-size: 12px;
    font-weight: normal;

    strong {
      display: block;
      padding-bottom: 5px;
      color: #4b515b;
      font-size: 12px;
      font-weight: normal;
    }

    .ddp-txt-det {
      display: block;
      padding-bottom: 10px;
      color: #9c9fa9;
      font-size: 12px;
      line-height: 18px;
      font-weight: normal;

      em.ddp-icon-chart-children {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/img_childrenchart.png) no-repeat;
        vertical-align: middle;
      }
    }
  }

  .ddp-ui-list-type {
    max-height: 361px;
    overflow: auto;

    a.ddp-btn-more {
      display: inline-block;
    }
  }

  ul.ddp-list-type {
    padding: 10px 0;

    li {
      a {
        display: block;
        position: relative;
        padding: 8px 20px 8px 20px;
        color: #4b515b;
        font-size: 12px;

        span.ddp-data-name {
          display: block;
          max-width: 100%;
          color: #4b515b;
          font-size: 13px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: normal;
          padding: 0;

          em.ddp-data {
            position: relative;
            padding: 0 6px;
            margin-right: 5px;
            border-right: 1px solid #b7b9c3;
            border-left: 1px solid #b7b9c3;
            color: #b7b9c3;
            font-size: 11px;
          }
        }

        &:hover {
          background-color: #f6f6f7;
        }

        .ddp-icon-database-s {
          float: left;
          margin-right: 7px;
        }
      }

      &.ddp-selected a {
        color: $primary;
      }
    }

    &.ddp-list-popup li {
      a {
        padding-right: 30px;
      }

      &.ddp-selected a .ddp-data-name {
        color: $primary;
      }
    }
  }

  .ddp-btn-more {
    display: inline-block;
    margin: 0 20px 0 20px;
    color: #4b515b;
    font-size: 12px;
    text-decoration: underline;
  }
}

ul.ddp-list-rnb li {
  a {
    display: block;
    position: relative;
    width: 52px;
    height: 52px;
  }

  &.ddp-selected a {
    background-color: #90969f;
  }

  a:hover em {
    &.ddp-icon-globalvar {
      background-position: -21px top;
    }

    &.ddp-icon-history {
      background-position: -21px -17px;
    }

    &.ddp-icon-navimenu {
      background-position: -17px -38px;
    }
  }

  &.ddp-selected a em {
    &.ddp-icon-globalvar {
      background-position: -42px top;
    }

    &.ddp-icon-history {
      background-position: -42px -17px;
    }

    &.ddp-icon-navimenu {
      background-position: -34px -38px;
    }
  }
}

em {
  &.ddp-icon-globalvar,
  &.ddp-icon-history,
  &.ddp-icon-navimenu {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(/assets/bi/images/icon_query_rnb.png) no-repeat;
  }

  &.ddp-icon-globalvar {
    margin: -8px 0 0 -10px;
    width: 20px;
    height: 16px;
  }

  &.ddp-icon-history {
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    background-position: left -17px;
  }

  &.ddp-icon-navimenu {
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    background-position: left -38px;
  }
}

.ddp-wrap-rnb-contents {
  height: 100%;
}

.ddp-ui-rnb-contents {
  position: absolute;
  right: 52px;
  bottom: 0;
  top: 0;
  width: 505px;
  height: 100%;
  border-right: 1px solid #e7e7ea;
  background-color: #f7f7f8;

  &.ddp-type {
    width: 316px;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -5px;
    top: 0;
    bottom: 0;
    width: 5px;
    background: url(/assets/bi/images/bg_shadow2.png) repeat-y;
  }

  .ddp-ui-title {
    position: relative;
    padding: 17px 0 15px 0;
    margin-left: 15px;
    font-size: 16px;
    color: #555d66;
    font-weight: bold;
    border-bottom: 1px solid #e5e6e8;

    span {
      &.ddp-data-num {
        margin-left: 10px;
        color: #b7b9c2;
      }

      &.ddp-data-num2 {
        color: #b7b9c2;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .ddp-ui-option {
    position: relative;
  }

  .ddp-wrap-selectbox {
    position: absolute;
    top: 10px;
    right: 17px;
  }

  .ddp-ui-option {
    .ddp-form-search {
      margin: 6px 20px 9px 20px;
      width: 200px;
    }

    .ddp-ui-reset {
      position: absolute;
      top: 10px;
      right: 17px;

      .ddp-ui-tooltip-info {
        display: none;
        position: absolute;
        top: 20px;
        right: -33px;
        left: inherit;
      }

      &:hover .ddp-ui-tooltip-info {
        display: block;

        .ddp-icon-view-top {
          left: inherit;
          right: 37px;
        }
      }
    }
  }

  .ddp-wrap-table-simple {
    position: absolute;
    top: 96px;
    left: 0;
    right: 0;
    bottom: 0;

    .ddp-table-tbody {
      position: absolute;
      top: 23px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
    }
  }

  .ddp-ui-var-plus {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 15px 0 15px;

    .ddp-ui-addview {
      position: relative;
      margin-top: -15px;
    }

    .ddp-wrap-addview {
      .ddp-btn-add {
        display: inline-block;
        position: absolute;
        top: -33px;
        right: 16px;
        width: 12px;
        height: 12px;
        background: url(/assets/bi/images/icon_add2.png) no-repeat;
        background-position: -55px top;
      }

      &.ddp-selected .ddp-btn-add {
        animation-name: plusspin;
        animation-duration: 0.3s;
        transform: rotate(45deg);
      }

      .ddp-ui-addview {
        display: none;
      }

      &.ddp-selected .ddp-ui-addview {
        display: block;
      }
    }

    .ddp-ui-addview .ddp-box-addview {
      width: 316px;
      padding: 15px;
      margin-left: -15px;
      margin-bottom: 13px;
      background-color: #e7e7ea;
      box-sizing: border-box;
      font-weight: normal;
      z-index: 10;

      &:before {
        display: inline-block;
        position: absolute;
        bottom: 100%;
        right: 0;
        width: 18px;
        height: 11px;
        background: url(/assets/bi/images/icon_addview.png) no-repeat;
        content: '';
      }
    }
  }
}

@keyframes plusspin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(45deg);
  }
}

.ddp-ui-var-plus {
  .ddp-ui-addview .ddp-list-addview tr {
    th {
      padding: 8px 0;
      color: #90969f;
      font-size: 13px;
      font-weight: normal;
      text-align: left;
      vertical-align: top;
    }

    td {
      padding: 2px 0;

      .ddp-type-selectbox.ddp-txt-type {
        width: 120px;
      }
    }
  }

  .ddp-data-error {
    color: #e70000;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      vertical-align: middle;
      background-position: -28px top;
      content: '';
    }
  }

  .ddp-ui-addview .ddp-box-buttons {
    padding: 2px 0 0 46px;
    overflow: hidden;

    .ddp-btn-pop {
      float: left;
      margin: 0 2px;
    }
  }

  .ddp-list-form-plus {
    color: #b7b9c2;
    font-size: 13px;

    .ddp-input-typebasic.ddp-readonly {
      border-color: transparent;
      background: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.ddp-value {
        color: #90969f;
      }
    }

    .ddp-wrap-link-list {
      float: left;
      margin: 0 -15px;
      padding: 2px 12px 2px 15px;
      cursor: pointer;

      &:hover {
        background-color: #e7e7ea;
      }

      .ddp-form-multy2 {
        margin-bottom: 0;

        &.ddp-type em {
          &.ddp-icon-type-calen3,
          &.ddp-icon-type-text {
            margin-top: 0;
            top: 9px;
          }
        }

        .ddp-ui-buttons {
          margin-top: 0;
          top: 5px;

          .ddp-icon-control-insert {
            position: relative;
            top: 2px;
          }
        }
      }

      .ddp-box-typebasic {
        width: 55%;
        float: left;

        &.ddp-value {
          width: 43%;
          float: right;
        }

        input.ddp-input-typebasic {
          width: 100%;
          cursor: pointer;
        }

        .ddp-form-calen {
          float: left;
          width: 100%;
        }
      }

      input {
        cursor: text !important;

        &[type='text'].ddp-readonly {
          cursor: pointer !important;
        }
      }
    }

    .ddp-link-buttons {
      float: right;
      clear: both;
      padding: 6px 0 3px 0;
      text-align: right;
    }
  }

  .ddp-list-blank {
    float: left;
    width: 100%;
    margin-top: 15px;
    position: relative;
    color: #b7b9c2;
    font-size: 13px;
    letter-spacing: -1px;

    .ddp-icon-blank-plus {
      display: inline-block;
      position: relative;
      top: -2px;
      width: 9px;
      height: 10px;
      margin-right: 6px;
      background: url(/assets/bi/images/icon_blank.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-icon-blank-point {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 2px;
      width: 10px;
      height: 14px;
      margin-left: 5px;
      background: url(/assets/bi/images/icon_blank.png) no-repeat;
      background-position: left -11px;
      vertical-align: middle;
    }
  }

  a.ddp-link-var-add {
    display: inline-block;
    float: right;
    position: relative;
    top: 6px;
    padding-left: 13px;
    padding-right: 15px;
    color: #90969f;
    font-size: 12px;
    overflow: hidden;

    &.ddp-disabled {
      opacity: 0.5;
      cursor: no-drop;
    }

    em.ddp-box-plus {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
      margin-right: 7px;
      text-align: center;
      border: 1px solid #dededf;
      vertical-align: middle;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -5px 0 0 -5px;
        width: 9px;
        height: 9px;
        background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
        background-position: left -2px;
        content: '';
      }
    }
  }
}

.ddp-ui-rnb-contents .ddp-ui-form-list {
}

.ddp-form-multy2 {
  position: relative;
  padding-right: 40px;
  margin-bottom: 6px;

  &.ddp-type {
    padding-left: 20px;

    em {
      &.ddp-icon-type-calen3,
      &.ddp-icon-type-text {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
      }
    }
  }

  .ddp-input-typebasic {
    float: left;
    width: 55%;

    &.ddp-value {
      float: right;
      width: 43%;
    }
  }

  .ddp-ui-buttons {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -11px;

    .ddp-btn-control {
      position: relative;
      float: left;
      margin-left: 3px;

      .ddp-ui-tooltip-info {
        display: none;
        top: 22px;
        left: inherit;
        right: -10px;
      }

      &:hover .ddp-ui-tooltip-info {
        display: block;
      }

      .ddp-ui-tooltip-info em.ddp-icon-view-top {
        left: inherit;
        right: 17px;
      }

      .ddp-icon-control-close {
        position: relative;
        top: 3px;
      }

      &.ddp-disabled {
        opacity: 0.3;

        &:after {
          display: inline-block;
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          cursor: no-drop;
        }

        .ddp-ui-tooltip-info {
          display: none;
        }
      }

      .ddp-wrap-popup2 {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 3px;
      }

      &.ddp-selected {
        .ddp-wrap-popup2 {
          display: block;
        }

        .ddp-icon-control-more2:before {
          background-position-x: -3px;
        }
      }
    }
  }

  .ddp-ui-calen {
    position: relative;
    float: right;
    width: 43%;
    background-color: #fff;

    .ddp-icon-type-calen2 {
      left: inherit;
      right: 9px;
    }

    input.ddp-input-typebasic {
      padding-right: 23px;
      width: 100%;
      text-align: left;
      border: 1px solid #d0d1d9;
      box-sizing: border-box;

      &:focus {
        border: 1px solid #b7b9c2;
      }
    }
  }
}

.ddp-wrap-table-simple .ddp-table-thead {
  padding-right: 18px;
  background-color: #e7e7eb;
}

table.ddp-table-simple {
  table-layout: fixed;
  width: 100%;

  tr {
    th {
      position: relative;
      padding: 2px 5px;
      color: #90969f;
      font-size: 11px;
      text-align: left;
      font-weight: normal;

      em[class*='ddp-icon-array-'] {
        display: inline-block;
      }
    }

    td {
      padding: 5px 5px;
      text-align: left;
      color: #4b515b;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
      cursor: pointer;

      .ddp-icon-user-s,
      .ddp-icon-group-s {
        display: inline-block;
        margin-right: 5px;
        width: 17px;
        text-align: right;
      }

      .ddp-icon-user-s {
        position: relative;
        right: 2px;

        &:before {
          display: inline-block;
          content: '';
          width: 8px;
          height: 10px;
          background: url(/assets/bi/images/icon_group.png) no-repeat;
          background-position: left -54px;
        }
      }

      .ddp-icon-group-s:before {
        display: inline-block;
        content: '';
        width: 8px;
        height: 10px;
        background: url(/assets/bi/images/icon_group.png) no-repeat;
        background-position: left -54px;
        width: 17px;
        height: 11px;
        background-position: left -65px;
      }

      .ddp-ui-action {
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ddp-ui-time {
        width: 100%;
        color: #90969f;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.ddp-wrap-table-simple .ddp-table-tbody a.ddp-btn-more {
  display: inline-block;
  margin: 10px 22px;
  color: #4b515b;
  font-size: 12px;
  text-decoration: underline;
}

.ddp-ui-query {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 0 13px 11px 2px;
  z-index: 5;
  vertical-align: top;
  box-sizing: border-box;
}

.ddp-ui-query-in {
  position: relative;
  width: 100%;
  height: 100%;
}

.ddp-box-editer {
  &.ddp-full {
    position: fixed !important;
    left: 13px !important;
    right: 64px !important;
    bottom: 2px !important;
    top: 101px !important;
    height: auto !important;
    background-color: #fff;
    z-index: 15;

    .ddp-box-full {
      display: none;
    }

    .ddp-box-reduce {
      display: block;
    }
  }

  position: absolute;
  top: 0;
  left: 12px;
  right: 13px;
  bottom: 60%;
  padding-bottom: 33px;
  border: 1px solid #ddd;
  background-color: #fff;
  overflow: hidden;
}

.ddp-wrap-tabs-edit {
  position: relative;
  height: 33px;
  padding-right: 33px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 5;

  &:before {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ddd;
    content: '';
  }

  .ddp-wrap-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ddp-btn-reset {
    position: relative;
    float: left;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-left: 1px solid #ddd;
    background-color: #fff;
    z-index: 1;

    em.ddp-btn-reset2 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
    }

    .ddp-ui-tooltip-info {
      display: none;
      top: 40px;
      left: inherit;
      right: -11px;
    }

    &:hover {
      .ddp-ui-tooltip-info {
        display: block;
      }

      em.ddp-btn-reset2 {
        background-position: -15px -34px;
      }
    }
  }
}

.ddp-wrap-tabs-query {
  .ddp-wrap-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ddp-btn-reset {
    position: relative;
    float: left;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-left: 1px solid #ddd;
    background-color: #fff;
    z-index: 1;

    em.ddp-btn-reset2 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
    }

    .ddp-ui-tooltip-info {
      display: none;
      top: 40px;
      left: inherit;
      right: -11px;
    }

    &:hover {
      .ddp-ui-tooltip-info {
        display: block;
      }

      em.ddp-btn-reset2 {
        background-position: -15px -34px;
      }
    }
  }

  .ddp-ui-result-none {
    padding: 9px 10px;
    color: #b6b9c2;
    font-size: 13px;
  }
}

.ddp-wrap-tabs-edit .ddp-btn-size {
  position: relative;
  float: left;
  width: 33px;
  height: 32px;
  border-left: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  z-index: 1;
}

.ddp-box-editer {
  .ddp-box-full {
    display: block;
  }

  .ddp-box-reduce {
    display: none;
  }
}

.ddp-wrap-tabs-edit .ddp-btn-size {
  em {
    &.ddp-icon-fullsize {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      margin: -7px 0 0 -7px;
      background: url(/assets/bi/images/btn_size.png) no-repeat;
    }

    &.ddp-icon-reducesize {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      margin: -7px 0 0 -7px;
      background: url(/assets/bi/images/btn_size.png) no-repeat;
      background-position: left -14px;
    }
  }

  &:hover em {
    &.ddp-icon-fullsize {
      background-position: -14px 0;
    }

    &.ddp-icon-reducesize {
      background-position: -14px -14px;
    }
  }

  .ddp-ui-tooltip-info {
    display: none;
    top: 40px;
    left: inherit;
    right: 0;

    em.ddp-icon-view-top {
      left: initial;
      right: 15px;
    }
  }

  &:hover .ddp-ui-tooltip-info {
    display: block;
  }
}

.ddp-ui-tabs {
  .ddp-btn-prev {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 23px;
    height: 33px;
    border-right: 1px solid #ddd;
    background-color: #fff;
    z-index: 1;
  }

  .ddp-btn-next {
    display: none;
    position: absolute;
    top: 0;
    left: 24px;
    width: 23px;
    height: 33px;
    border-right: 1px solid #ddd;
    background-color: #fff;
    z-index: 1;
  }
}

.ddp-wrap-querylist {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  white-space: nowrap;
}

.ddp-ui-tabs.ddp-slide .ddp-btn-listslider {
  display: block;
}

.ddp-btn-listslider {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1;

  .ddp-btn-sliderprev {
    display: inline-block;
    float: left;
    position: relative;
    width: 35px;
    height: 33px;
    border: 1px solid #ddd;
    box-sizing: Border-box;
  }

  .ddp-btn-slidernext {
    display: inline-block;
    float: left;
    position: relative;
    width: 35px;
    height: 33px;
    border: 1px solid #ddd;
    box-sizing: Border-box;
    border-left: none;
  }

  .ddp-btn-sliderprev:before,
  .ddp-btn-slidernext:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -2px;
    width: 4px;
    height: 7px;
    background: url(/assets/bi/images/icon_dataview.png) no-repeat;
    background-position: left -19px;
    content: '';
  }

  .ddp-btn-sliderprev {
    transform: rotate(180deg);
  }
}

.ddp-wrap-querylist {
  .ddp-wrap-popup2 {
    display: none;
    width: 182px;
    position: absolute;
    top: 33px;
    left: 0;
    box-sizing: border-box;
    overflow-y: auto;
  }

  &.ddp-selected .ddp-wrap-popup2 {
    display: block;
  }

  .ddp-wrap-popup2 a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
  }
}

.ddp-ui-tabs {
  .ddp-btn-prev em.ddp-icon-prev {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 7px;
    margin: -4px 0 0 -2px;
    background: url(/assets/bi/images/icon_slide.png) no-repeat;
  }

  .ddp-btn-next em.ddp-icon-next {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 7px;
    margin: -4px 0 0 -2px;
    background: url(/assets/bi/images/icon_slide.png) no-repeat;
    background-position: -5px top;
  }

  .ddp-btn-prev:hover em.ddp-icon-prev {
    background-position: 0 -7px;
  }

  .ddp-btn-next:hover em.ddp-icon-next {
    background-position: -5px -7px;
  }

  &.ddp-slide {
    padding: 0 33px 0 70px;

    .ddp-btn-prev,
    .ddp-btn-next {
      display: block;
    }
  }

  position: relative;
  display: inline-block;
  padding-right: 33px;
  max-width: 100%;
  box-sizing: border-box;

  a.ddp-btn-plus {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 33px;
    height: 32px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #fff;

    &:hover {
      background-color: #f9f9f9;
    }

    em {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: -4px 0 0 -4px;
      background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
      background-position: -20px -2px;
    }
  }

  ul.ddp-list-tabs {
    height: 32px;
    white-space: nowrap;

    li {
      float: left;
      position: relative;
      height: 32px;
      margin-bottom: 2px;
      box-sizing: border-box;
      border-right: 1px solid #ddd;
      cursor: pointer;
      vertical-align: top;

      &:hover {
        background-color: #f9f9f9;
      }

      &.ddp-selected:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 2px solid #4b515b;
        content: '';
        z-index: 1;
      }

      &.ddp-disabled {
        opacity: 0.5;
        cursor: no-drop;

        &:hover {
          background: none;
        }
      }

      .ddp-box-tabs-popup {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 10px;
        width: 300px;
        max-height: 220px;
        border: 1px solid #ddd;
        border-radius: 2px;
        background-color: #fff;
        text-align: left;
        box-sizing: border-box;
        z-index: 20;
        cursor: default;
        -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

        /* drop shadow */
        -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

        /* drop shadow */
        box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

        /* drop shadow */

        .ddp-txt-query {
          width: 100%;
          max-height: 220px;
          padding: 15px;
          font-size: 12px;
          line-height: 18px;
          white-space: normal;
          overflow: auto;
          box-sizing: border-box;
        }

        &.ddp-retry {
          .ddp-txt-query {
            max-height: 174px;
          }

          padding-bottom: 46px;

          .ddp-btn-log-status {
            position: absolute;
            bottom: 13px;
            left: 15px;
          }
        }
      }

      .ddp-data-loading {
        position: relative;
        min-width: 110px;
        height: 33px;
        color: #4b515b;
        font-size: 12px;
        padding: 10px 10px 9px 10px;
        box-sizing: border-box;

        em.ddp-icon-tabloading {
          display: inline-block;
          position: relative;
          top: -1px;
          margin-right: 5px;
          width: 12px;
          height: 12px;
          background: url(/assets/bi/images/icon_tabloading.png) no-repeat;
          vertical-align: middle;
          animation-name: spin;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .ddp-icon-dot {
          display: inline-block;
          height: 12px;
          margin-left: 4px;
          color: #4b515b;
          font-size: 12px;
          overflow: hidden;
          animation-name: loaddot;
          animation-duration: 0.8s;
          vertical-align: bottom;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .ddp-btn-close-s {
          display: inline-block;
          width: 7px;
          height: 7px;
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -4px;
          background: url(/assets/bi/images/btn_sclose.png) no-repeat;
          background-position: left -69px;
          cursor: pointer;
        }
      }

      .ddp-data-tab {
        display: block;
        position: relative;
        padding: 9px 25px 10px 7px;
        min-width: 110px;
        max-width: 220px;
        height: 33px;
        color: #444;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        box-sizing: border-box;

        .ddp-btn-close-s {
          display: inline-block;
          width: 7px;
          height: 7px;
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -4px;
          background: url(/assets/bi/images/btn_sclose.png) no-repeat;
          background-position: left -69px;
          cursor: pointer;
        }
      }

      .ddp-wrap-morebutton {
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -9px;
      }

      input.ddp-input-edit {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 110px;
        padding: 10px 10px 9px 10px;
        box-sizing: border-box;
        background: #fff;
        border: none;
      }

      &.ddp-edit input.ddp-input-edit {
        display: block;
      }
    }
  }
}

/*.ddp-ui-tabs ul.ddp-list-tabs li.ddp-selected:after {display:inline-block; position:absolute; bottom:-1px; left:0; right:0; border-bottom:1px solid #fff; content:''; z-index:1;}*/

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loaddot {
  from {
    width: 0;
  }

  to {
    width: 10px;
  }
}

.ddp-pop-more {
  position: fixed;
}

.ddp-wrap-editor {
  position: absolute;
  top: 33px;
  left: 0;
  right: 0;
  bottom: 41px;
  background-color: #fff;
  text-align: left;
  z-index: 10;
}

.ddp-ui-empty {
  padding: 18px 20px;
  color: #b7b9c3;
  font-size: 12px;
}

.ddp-ui-empty2 {
  padding: 15px 15px;
  color: #b7b9c3;
  font-size: 12px;
}

.ddp-ui-error-message {
  padding: 15px 15px;
  color: #e70000;
  font-size: 12px;
}

.ddp-box-editer {
  a.ddp-btn-beautifier {
    position: relative;
    float: left;
    width: 32px;
    height: 32px;
    background: rgba(75, 81, 91, 0.05);
    margin-right: 2px;

    &:hover .ddp-ui-tooltip-info {
      display: block;
    }

    &:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 21px;
      height: 18px;
      margin: -10px 0 0 -9px;
      background: url(/assets/bi/images/icon_query.png) no-repeat;
      background-position: left -70px;
    }

    &:hover:before {
      background-position: -22px -70px;
    }
  }

  .ddp-wrap-question {
    position: relative;
    float: left;

    a.ddp-btn-question {
      position: relative;
      float: left;
      width: 32px;
      height: 32px;
      margin-left: 3px;
      background: rgba(75, 81, 91, 0.05);
      margin-right: 2px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        width: 16px;
        height: 16px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: -17px -24px;
        content: '';
      }
    }

    &:hover a.ddp-btn-question:before,
    &.ddp-selected a.ddp-btn-question:before {
      background-position-x: 0;
    }

    .ddp-box-layout4 {
      display: none;
      position: fixed;
      top: 100%;
      right: 70px;
      width: 420px;
    }

    &.ddp-selected .ddp-box-layout4 {
      display: block;
    }
  }
}

.ddp-ui-editor {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  &:focus {
    border: none;
  }
}

.ddp-box-query-result {
  position: absolute;
  top: 40%;
  left: 12px;
  right: 13px;
  bottom: 2px;
  padding-top: 0;

  .ddp-wrap-handler {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 14px;

    em.ddp-icon-handler {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 5px;
      margin: -3px 0 0 -10px;
      background: url(/assets/bi/images/icon_handler.png) no-repeat;
    }
  }

  .ddp-box-in {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    background-color: #fff;

    .ddp-wrap-tabs-query {
      position: relative;
      height: 33px;
      background-color: #fff;
      z-index: 20;

      &:before {
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #ddd;
        content: '';
      }
    }

    .ddp-ui-tabs {
      position: relative;
      height: 33px;
      padding-right: 0;

      .ddp-btn-next {
        right: 0;
      }
    }
  }

  .ddp-view-result {
    position: absolute;
    top: 33px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
  }

  .ddp-wrap-result {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 28px;
    background-color: #fff;
    z-index: 10;

    .ddp-box-grid {
      padding: 15px;
      width: 100%;
      height: 100%;
      line-height: 18px;
      box-sizing: border-box;

      .ddp-text-result.ddp-nodata {
        padding: 15px;
        color: #b7bac1;
        font-size: 13px;
      }
    }

    .ddp-ui-setting {
      position: relative;
      z-index: 2;
      background-color: #fff;

      .ddp-form-search {
        float: left;
      }
    }
  }

  .ddp-ui-bottom-tab {
    float: left;
    z-index: 3;

    ul.ddp-list-bottom-tab li {
      float: left;
      height: 28px;

      a {
        display: block;
        padding: 6px 16px;
        color: #4b515b;
        font-size: 13px;
      }

      &.ddp-selected a {
        background-color: #fff;
        font-weight: bold;
        border: 1px solid #d0d1d8;
        border-top: none;
        border-bottom: none;
      }
    }
  }

  .ddp-wrap-log {
    position: relative;
    padding-bottom: 28px;
    height: 100%;
    background-color: #35393f;
    box-sizing: border-box;

    &.ddp-ing {
      padding-bottom: 95px;
    }
  }

  .ddp-box-log {
    position: relative;
    padding: 15px 16px;
    height: 100%;
    color: #b6b9c2;
    font-size: 12px;
    line-height: 18px;
    overflow: auto;
    box-sizing: border-box;
  }

  .ddp-wrap-log span {
    &.ddp-txt-info {
      font-size: 12px;
      line-height: 18px;
      color: #26c9ff;
    }

    &.ddp-txt-error {
      font-size: 12px;
      line-height: 18px;
      color: #ff3b3b;
    }

    &.ddp-txt-warn {
      font-size: 12px;
      line-height: 18px;
      color: #fff726;
    }

    &.ddp-txt-type-a {
      font-size: 12px;
      line-height: 18px;
      color: #f5a837;
    }

    &.ddp-txt-type-b {
      font-size: 12px;
      line-height: 18px;
      color: #a6ce4e;
    }

    &.ddp-txt-type-c {
      font-size: 12px;
      line-height: 18px;
      color: #9983fc;
    }
  }

  .ddp-box-infobar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 27px;
    border-top: 1px solid #d0d1d8;
    background: #e7e7ea;
    z-index: 30;
  }

  .ddp-view-status {
    display: block;
    position: relative;
    overflow: hidden;

    &.ddp-fail {
      height: 29px;
      margin-top: -1px;
      background: #f8dbdc;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #ea9295;
        content: '';
      }
    }
  }

  .ddp-list-time {
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 4px 6px 0;

    .ddp-data-time {
      position: relative;
      padding: 0 11px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -7px;
        height: 15px;
        border-left: 1px solid #cdcfd3;
        content: '';
      }
    }
  }

  .ddp-box-infobar {
    .ddp-list-time .ddp-data-time {
      .ddp-data-label {
        padding-right: 9px;
        color: #4b515b;
        font-size: 12px;
        opacity: 0.5;
      }

      .ddp-data-txt {
        color: #4b515b;
        font-size: 12px;
      }
    }

    .ddp-info-status {
      display: inline-block;
      position: relative;
      margin: 6px 0 6px 15px;
      color: #4b515b;
      font-size: 12px;
      overflow: hidden;

      &.ddp-fail {
        padding-left: 19px;
        color: #e35252;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -6px;
          width: 13px;
          height: 13px;
          background: url(/assets/bi/images/icon_fail.png) no-repeat;
          content: '';
        }
      }
    }
  }

  .ddp-box-status-button {
    position: absolute;
    bottom: 39px;
    left: 10px;
    right: 10px;
    padding: 13px 15px;
    height: 56px;
    border-radius: 3px;
    background-color: #f6f6f7;
    box-sizing: border-box;
  }

  .ddp-btn-log-status {
    display: inline-block;
    position: relative;
    padding: 7px 11px;
    min-width: 72px;
    margin-right: 14px;
    border-radius: 2px;
    background-color: #90969f;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    text-align: center;

    &.ddp-retry {
      padding-left: 32px;
      background-color: #dc494f;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -6px;
        width: 13px;
        height: 12px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
        background-position: left -63px;
        content: '';
        vertical-align: middle;
      }
    }

    &.ddp-retry2 {
      padding-left: 32px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -6px;
        width: 13px;
        height: 12px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
        background-position: left -63px;
        content: '';
        vertical-align: middle;
      }
    }
  }

  .ddp-wrap-log .ddp-box-status-button .ddp-txt-log-status {
    color: #4b515b;
    font-size: 13px;

    &.ddp-fail {
      color: #dc494f;
    }
  }
}

.ddp-btn-allcheck {
  float: left;
  margin: 9px 0 0 12px;
  color: #b7b9c2;
  font-size: 12px;
  text-decoration: underline;

  &:before {
    display: inline-block;
    width: 8px;
    height: 6px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_select2.png) no-repeat;
    background-position: -47px -7px;
    content: '';
  }

  &:hover {
    color: #4b515b;

    &:before {
      background-position: -47px top;
    }
  }
}

.ddp-box-query-result .ddp-wrap-result {
  .ddp-ui-buttons {
    position: absolute;
    bottom: 40px;
    right: 10px;
    float: right;
    padding: 10px 0 0 0;
  }

  .ddp-wrap-btn-link {
    float: left;
    border: 1px solid #b6b9c2;
    border-radius: 2px;
    background-color: #fff;

    &.type-opacity {
      opacity: 0.2;
    }
  }
}

.ddp-wrap-btn-link.type-opacity a.ddp-btn-link.ddp-selected .ddp-box-searching {
  opacity: 1;
}

.ddp-box-query-result .ddp-wrap-result {
  .ddp-wrap-btn-link {
    + .ddp-wrap-btn-link {
      margin-left: 9px;
    }

    .ddp-box-page {
      float: left;
      width: 75px;
      height: 30px;
      margin-top: 0;
      padding: 8px 0;
      color: #90969f;
      font-size: 13px;
      text-align: center;
      box-sizing: border-box;
      border: none;

      + .ddp-box-page {
        border-left: 1px solid #b6b9c2;
      }

      &:hover {
        background-color: #e7e7ea;
      }

      .ddp-icon-prev {
        display: inline-block;
        width: 4px;
        height: 7px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_page2.png) no-repeat;
        transform: rotate(180deg);
        vertical-align: middle;
        content: '';
      }

      .ddp-icon-next {
        display: inline-block;
        width: 4px;
        height: 7px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_page2.png) no-repeat;
        transform: rotate(180deg);
        vertical-align: middle;
        content: '';
        margin-left: 5px;
        margin-right: 0;
        transform: rotate(0);
      }
    }
  }

  .ddp-ui-buttons {
    a {
      &.ddp-btn-link {
        position: relative;
        float: left;
        width: 30px;
        height: 30px;
        color: #90969f;
        font-size: 12px;

        &:hover {
          background: #e7e7ea;
        }

        .ddp-ui-tooltip-info {
          display: none;
          bottom: 100%;
          top: inherit;
          right: -15px;
          left: inherit;
          margin-bottom: 8px;
          white-space: nowrap;
        }

        &:hover .ddp-ui-tooltip-info {
          display: block;
        }

        .ddp-ui-tooltip-info .ddp-icon-view-down {
          left: inherit;
          right: 29px;
        }

        &:hover {
          color: #4b515b;
        }

        &.ddp-disabled {
          opacity: 0.5;
          cursor: no-drop;
        }

        &.ddp-selected {
          background-color: #e7e7ea;

          .ddp-ui-tooltip-info {
            display: none;
          }
        }

        &.ddp-value:after {
          display: Inline-block;
          width: 5px;
          height: 5px;
          position: absolute;
          top: 5px;
          right: 5px;
          background: $primary;
          border-radius: 50%;
          content: '';
        }

        &.ddp-selected .ddp-box-searching {
          display: block;
        }

        .ddp-box-searching {
          display: none;
          position: absolute;
          bottom: 100%;
          margin-bottom: 7px;
          padding: 12px 17px;
          right: -20px;
          border-radius: 4px;
          background-color: #fff;
          -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.7);

          /* drop shadow */
          -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.7);

          /* drop shadow */
          box-shadow: 0 1px 3px rgba(84, 97, 104, 0.7);

          /* drop shadow */

          .ddp-form-search {
            width: 278px;
          }
        }
      }

      &.ddp-btn-box {
        display: inline-block;
        position: relative;
        top: -6px;
        width: 25px;
        height: 25px;
        margin-left: 13px;
        border-radius: 2px;
        border: 1px solid #ccc;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #8f96a0;
        }

        .ddp-icon-cancel,
        .ddp-icon-result-grid,
        .ddp-icon-result-list {
          display: inline-block;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -8px 0 0 -7px;
          background: url(/assets/bi/images/icon_query.png) no-repeat;
          background-position: left -139px;
        }

        &:hover .ddp-icon-cancel {
          background-position-x: -17px;
        }

        .ddp-icon-result-grid {
          margin: -6px 0 0 -7px;
          background-position: left -156px;
        }

        .ddp-icon-result-list {
          margin: -5px 0 0 -6px;
          width: 15px;
          height: 14px;
          background-position: left -173px;
        }

        &:hover {
          .ddp-icon-result-grid {
            background-position-x: -17px;
          }

          .ddp-icon-result-list {
            background-position-x: -16px;
          }

          .ddp-ui-tooltip-info {
            display: block;
            top: 100%;
            left: inherit;
            right: -10px;
            margin-top: 8px;
          }
        }

        .ddp-ui-tooltip-info .ddp-icon-view-top {
          right: 18px;
          left: inherit;
          margin: 0;
        }
      }
    }

    .ddp-ui-link {
      position: relative;
      float: left;

      &.ddp-selected {
        background-color: #e7e7ea;

        .ddp-wrap-popup2 {
          display: block;
          position: absolute;
          bottom: 100%;
          right: -20px;
          margin-top: 5px;
          white-space: nowrap;
        }

        .ddp-ui-tooltip-info {
          display: none !important;
        }
      }

      .ddp-wrap-popup2 {
        display: none;
      }

      &.ddp-selected .ddp-wrap-popup2 {
        display: block;
      }
    }

    a.ddp-btn-link {
      > [class*='ddp-icon-'] {
        position: absolute;
        top: 50%;
        left: 50%;
      }

      em {
        &.ddp-icon-chart {
          margin: -6px 0 0 -7px;
          background-position-x: -30px;
        }

        &.ddp-icon-save {
          margin: -7px 0 0 -8px;
          background-position-x: -32px;
        }

        &.ddp-icon-export,
        &.ddp-icon-online-excel {
          margin: -7px 0 0 -7px;
          background-position-x: -28px;
        }

        &.ddp-icon-query-search {
          margin: -7px 0 0 -7px;
        }
      }
    }
  }
}

em {
  &.ddp-icon-query-search,
  &.ddp-icon-chart,
  &.ddp-icon-save,
  &.ddp-icon-excel,
  &.ddp-icon-query-info,
  &.ddp-icon-export,
  &.ddp-icon-online-excel {
    display: inline-block;
    position: relative;
    top: -1px;
    background: url(/assets/bi/images/icon_query.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-chart {
    width: 14px;
    height: 13px;
  }

  &.ddp-icon-save {
    width: 15px;
    height: 14px;
    background-position: left -89px;
  }

  &.ddp-icon-online-excel {
    width: 13px;
    height: 14px;
    background-position: left -104px;
  }

  &.ddp-icon-query-search {
    width: 14px;
    height: 14px;
    background-position: left -188px;
  }
}

/*****************************************************************
	워크벤치 테이블리스트팝업
***********************************************************************/

.ddp-ui-benchtable {
  float: left;
  position: relative;
  width: 45%;
  height: 100%;
  background-color: #2f3339;

  .ddp-ui-table-name {
    position: relative;
    padding: 15px 35px 15px 15px;
    font-size: 13px;
    color: #d0d1d8;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    background-color: #35393f;

    a.ddp-btn-close {
      display: inline-block;
      position: absolute;
      top: 17px;
      right: 15px;
      width: 11px;
      height: 11px;
      background: url(/assets/bi/images/btn_close.png) no-repeat;
      background-position: -12px -58px;
    }
  }

  .ddp-ui-columnlist {
    .ddp-ui-label {
      padding: 10px 15px 7px 15px;
      color: #d0d1d8;
      font-size: 12px;

      .ddp-form-search {
        margin: 5px 0 0 0;
        width: 100%;

        input {
          border-bottom: 1px solid #656971;
          color: #fff;
        }

        .ddp-btn-search-close:before {
          background-position-x: -8px;
        }
      }
    }

    .ddp-list-column {
      position: absolute;
      top: 113px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;

      li a {
        position: relative;
        display: block;
        padding: 5px 35px 5px 15px;

        &:hover {
          background-color: #4b515b;
        }

        .ddp-data-char {
          color: #d0d1d8;
          font-size: 13px;
        }

        .ddp-data-columnname {
          color: #90969f;
          font-size: 11px;
        }

        em.ddp-btn-more {
          display: none;
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -5px;
          width: 9px;
          height: 9px;
          background: url(/assets/bi/images/icon_add.png) no-repeat;
          background-position: -40px top;
        }

        &:hover em.ddp-btn-more {
          display: block;
        }
      }
    }
  }
}

.ddp-wrap-workbench .handle[_ngcontent-c4] {
  background-color: #e7e7ea;
}

/**************************************************************
  Page : 워크벤치 상세 스키마
**************************************************************/

.popup-workbench {
  .ddp-wrap-list .ddp-ui-dataname .ddp-top-title {
    padding-left: 30px;
  }

  .ddp-db-icons {
    position: absolute;
    top: -2px;
    left: 0;
    width: 24px;
    height: 24px;

    [class*='ddp-icon-data-'] {
      display: inline-block;
      background: url(/assets/bi/images/icon_dbtype_s.png) no-repeat;
    }

    .ddp-icon-data-sql {
      width: 19px;
      height: 22px;
      background-position: left -22px;
    }

    .ddp-icon-data-tibero {
      width: 19px;
      height: 22px;
      background-position: left -135px;
    }

    .ddp-icon-data-oracle {
      width: 21px;
      height: 21px;
      background-position: left top;
    }

    .ddp-icon-data-db {
      width: 16px;
      height: 19px;
      background-position: left -158px;
    }

    .ddp-icon-data-phoenix {
      width: 16px;
      height: 24px;
      background-position: left -110px;
    }

    .ddp-icon-data-post {
      width: 22px;
      height: 22px;
      background-position: left -45px;
    }

    .ddp-icon-data-hive {
      width: 24px;
      height: 22px;
      background-position: left -68px;
    }

    .ddp-icon-data-presto {
      width: 20px;
      height: 17px;
      background-position: left -91px;
    }
  }

  &.ddp-column-detail .ddp-wrap-list .ddp-ui-title.ddp-bottom {
    padding-top: 20px;
    padding-bottom: 0;
    border: none;
  }

  .ddp-wrap-list-search {
    padding: 0 18px;
    margin: 0 -16px;

    .ddp-ui-list-search {
      position: relative;
      padding-left: 22px;
      overflow: hidden;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 11px;
        height: 11px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
        background-position: -12px 0;
        content: '';
      }

      input {
        display: block;
        width: 100%;
        padding: 19px 0 19px 0;
        background: none;
        border: none;
        font-size: 13px;
      }

      .ddp-btn-search-close {
        display: inline-block;
        padding: 5px;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -8px;
        cursor: pointer;
        font-size: 0;

        &:before {
          display: inline-block;
          width: 7px;
          height: 7px;
          background: url(/assets/bi/images/btn_sclose.png) no-repeat;
          background-position: -8px -38px;
          content: '';
        }
      }
    }

    a.ddp-btn-refresh {
      float: right;
      position: relative;
      top: 21px;
      width: 13px;
      height: 12px;
      background: url(/assets/bi/images/icon_refresh2.png) no-repeat;
      background-position: -28px top;

      &:hover {
        background-position: -42px top;
      }
    }
  }

  .ddp-tablelist-header {
    padding: 0 0;
    height: 27px;
    margin: 0 -16px;
    border-top: 1px solid #e7e7ea;
    border-bottom: 1px solid #e7e7ea;
    box-sizing: border-box;

    .ddp-table-num {
      float: right;
      padding: 3px 18px 3px 0;
      position: relative;
      top: 2px;
      font-size: 12px;
      color: #4b515b;
      font-weight: 300;

      span.ddp-number {
        font-weight: normal;
      }
    }

    .ddp-table-title {
      display: inline-block;
      padding: 3px 0 3px 18px;
      color: #90969f;
      font-size: 12px;
      font-weight: normal;
      overflow: Hidden;
      box-sizing: border-box;
      cursor: pointer;

      [class*='ddp-icon-sort'] {
        display: inline-block;
        position: relative;
        width: 19px;
        height: 19px;
        font-size: 0;
        vertical-align: middle;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 7px;
          height: 10px;
          margin: -5px 0 0 -4px;
          background: url(/assets/bi/images/icon_sorting.png);
          content: '';
        }
      }

      .ddp-icon-sort-asc:before {
        background-position: -8px top;
      }

      .ddp-icon-sort-des:before {
        background-position: -16px top;
      }
    }
  }

  .ddp-pop-wrapList {
    top: 214px;
  }
}

.ddp-pop-preview.ddp-type-scheme.popup-workbench .ddp-type-top-option .ddp-ui-tab-contents .ddp-wrap-searching {
  padding: 0 15px;
}

.popup-workbench {
  .ddp-ui-tab-contents .ddp-wrap-list-search .ddp-ui-list-search input {
    padding: 14px 0 14px 0;
  }

  .ddp-wrap-detail-column {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 30px 0 30px;
    overflow: auto;
  }

  &.ddp-column-detail .ddp-ui-detail {
    border-top: none;
  }

  .ddp-ui-preview-contents .ddp-ui-detail + .ddp-ui-detail .ddp-ui-title {
    padding-top: 0;
  }
}

/***********************************************************************
	쿼리 결과
***********************************************************************/

em {
  &.ddp-icon-excel {
    width: 12px;
    height: 13px;
    background-position: left -28px;
  }

  &.ddp-icon-export {
    width: 13px;
    height: 13px;
    background-position: left -56px;
  }
}

/**********************************************************************************
	page : 데이터 저장소
**********************************************************************************/

table.ddp-table-form tr td span.ddp-data-group {
  margin-right: 16px;

  em {
    &.ddp-icon-group-s2,
    &.ddp-icon-user-s2 {
      margin-right: 4px;
    }
  }
}

.ddp-ui-info-det2 {
  color: #90969f;
  font-size: 14px;

  em.ddp-icon-sinfo2 {
    vertical-align: middle;
    margin-right: 5px;
  }
}

/**********************************************************************************
	page : 데이터 저장소 db edit order
**********************************************************************************/

.ddp-ui-filter-order {
  position: relative;
  width: 100%;
  height: 100%;

  .ddp-data-filter {
    padding: 7px 13px;
    color: #90969f;
    font-size: 12px;
    border-top: 1px solid #d0d1d8;
    border-bottom: 1px solid #d0d1d8;
  }

  ul.ddp-list-filter-order {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 16px;
    overflow-y: auto;

    li {
      position: relative;
      padding: 12px 0 13px 135px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f7;
      }

      .ddp-data-no {
        position: absolute;
        top: 50%;
        left: 16px;
        margin-top: -10px;
        font-size: 13px;
        color: #4b515b;
      }

      span {
        &.ddp-data-tag {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 45px;
          margin-top: -11px;
          width: 80px;
          text-align: center;
        }

        &.ddp-box-tag-value {
          display: inline-block;
          width: 70px;
          padding: 2px 3px 1px 3px;
          border-radius: 1px;
          font-size: 12px;
          box-sizing: border-box;
          text-align: center;
        }
      }
    }
  }
}

table.ddp-table-type2 tr td span.ddp-box-tag-value {
  display: inline-block;
  width: 70px;
  padding: 2px 3px 1px 3px;
  border-radius: 1px;
  font-size: 12px;
  box-sizing: border-box;
  text-align: center;
}

.ddp-ui-filter-order ul.ddp-list-filter-order li span.ddp-box-tag-value.ddp-dimension,
table.ddp-table-type2 tr td span.ddp-box-tag-value.ddp-dimension {
  color: #439fe5;
  border: 1px solid #439fe5;
}

.ddp-ui-filter-order ul.ddp-list-filter-order li span.ddp-box-tag-value.ddp-measure {
  color: #5fd7a5;
  border: 1px solid #5fd7a5;
}

table.ddp-table-type2 tr td {
  span.ddp-box-tag-value.ddp-measure {
    color: #5fd7a5;
    border: 1px solid #5fd7a5;
  }

  .ddp-ui-selected-option.ddp-selected span.ddp-link-text span.ddp-wrap-icons {
    background-color: #e8e9ea;
  }

  .ddp-icon-error2 {
  }

  &.ddp-info {
    padding-right: 30px;

    &.ddp-cursor-default:hover {
      background: none !important;
      border-color: none !important;
    }

    .ddp-ui-info {
      position: absolute;
      top: 50%;
      right: 9px;
      margin-top: -7px;
      cursor: pointer;
      font-size: 0;

      .ddp-icon-error2 {
        position: relative;
        top: 0;
      }

      &.ddp-selected .ddp-box-layout4.type-time {
        display: block;
        z-index: 23;
      }

      .ddp-box-layout4.type-time {
        display: none;
        width: 330px;
        position: absolute;
        top: 100%;
        right: -30px;
        margin-top: 5px;
      }

      &.ddp-selected .ddp-icon-sinfo2 {
        background-position: -28px -30px;
      }
    }
  }
}

.ddp-box-layout4.type-time {
  .ddp-wrap-edit3 {
    + .ddp-wrap-edit3 {
      margin-top: 20px;
    }

    .ddp-ui-label-name {
      position: relative;
      margin-bottom: 10px;
      color: #4a515c;

      .ddp-wrap-hover-info {
        .ddp-box-layout4 {
          position: absolute;
          top: 100%;
          right: -20px;
          left: inherit;
          margin-top: 5px;
        }

        &:hover .ddp-box-layout4 {
          display: block !important;
        }
      }
    }

    .ddp-view-label {
      position: relative;
    }

    .ddp-type-format {
      position: absolute;
      top: 0;
      right: 0;

      .ddp-label-checkbox span.ddp-txt-checkbox {
        color: #90969f;
      }
    }

    .ddp-ui-option-in {
      padding: 0 0 0 13px;

      .ddp-list-checktype {
        padding: 5px 0 0 0;

        li + li {
          margin-top: 6px;
        }
      }
    }

    &.type-det {
      color: #4a515c;
      font-size: 13px;
      margin-bottom: 0;
      min-height: auto;

      .ddp-txt-det {
        margin-left: 10px;
      }
    }
  }

  .ddp-data-title {
    position: relative;
    font-size: 14px;

    .ddp-txt-error {
      position: absolute;
      bottom: 0;
      left: 14px;
      font-weight: normal;
    }
  }
}

.ddp-ui-filter-order ul.ddp-list-filter-order li span.ddp-data-name {
  position: relative;
  display: block;
  padding-left: 20px;
  font-size: 13px;
  color: #4b515b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;

  em[class*='ddp-icon-type'] {
    position: absolute;
    left: 0;
    top: 8px;
  }
}

/**********************************************************************************
	page : 데이터 저장소 myfile 스키마설정
**********************************************************************************/

.ddp-box-popupcontents3 {
  width: 1192px;
  margin: 0 auto;
  text-align: left;

  .ddp-set-message {
    padding: 25px 0 0 0;
    text-align: center;
    color: #90969f;
    font-size: 14px;

    a.ddp-link {
      color: #4c92e0;
      font-size: 14px;
      font-style: italic;
      text-decoration: underline;
    }
  }
}

.ddp-type-top-option .ddp-ui-rightoption .ddp-label-checkbox span.ddp-txt-checkbox {
  font-size: 13px;
}

.ddp-link-toggle {
  padding: 7px 11px;
  color: #90969f;
  font-size: 13px;
  border-radius: 2px;
  background-color: #f6f6f7;

  em.ddp-icon-recommend {
    margin-right: 5px;
    vertical-align: middle;
  }

  &.ddp-selected {
    color: #4c515a;
    background-color: #e7e7ea;

    em.ddp-icon-recommend {
      background-position: -36px top;
    }
  }
}

table {
  &.ddp-table-form tbody tr td {
    .ddp-td-schema {
      display: block;
      position: relative;
      overflow: hidden;
    }

    .ddp-txt-icon {
      display: inline-block;
      position: relative;
      padding-left: 30px;
    }

    .ddp-btn-solid2 {
      margin: -4px 0;
    }

    .ddp-td-schema span.ddp-box-tag-value {
      float: left;
      padding: 2px 3px 3px 3px;
      border-radius: 1px;
      font-size: 12px;

      &.ddp-dimension {
        width: 70px;
        text-align: center;
        color: #439fe5;
        border: 1px solid #439fe5;
        box-sizing: border-box;
      }

      &.ddp-measure {
        width: 70px;
        text-align: center;
        color: #5fd7a5;
        border: 1px solid #5fd7a5;
        box-sizing: border-box;
      }
    }

    .ddp-wrap-value-icon {
      position: relative;
      float: right;
      min-height: 15px;
      margin-left: 5px;
      margin-top: 4px;

      [class*='ddp-icon-'] {
        float: left;
        margin-left: 8px;
      }

      .ddp-wrap-datarecommend {
        float: left;
        margin-left: 10px;
      }

      &.ddp-type {
        padding-right: 15px;
      }

      .ddp-btn-revival {
        float: left;
        position: relative;
        top: -2px;
        padding: 3px 4px;
        margin-left: 8px;
        background-color: #e7e7ea;
        border-radius: 2px;
        color: #4b515b;
        font-size: 12px;
        vertical-align: middle;
        line-height: 1em;
        z-index: 10;

        &:hover {
          background-color: #d0d1d8;
        }

        .ddp-icon-time-s {
          display: inline-block;
          width: 14px;
          height: 13px;
          margin-right: 5px;
          margin-left: 0;
          background: url(/assets/bi/images/icon_return.png) no-repeat;
          background-position: left -20px;
          vertical-align: middle;
        }
      }
    }

    .ddp-td-schema a.ddp-btn-box {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
      padding: 3px 4px;
      border-radius: 2px;
      background-color: #e7e7ea;
      color: #4b515b;
      font-size: 12px;
      font-style: italic;
      z-index: 3;

      &:hover {
        background-color: #d0d1d8;
      }
    }

    .ddp-type-icon {
      float: left;
      width: 43px;
      height: 22px;
      position: relative;

      em {
        &[class*='ddp-icon-type'],
        &[class*='ddp-icon-dimension'],
        &[class*='ddp-icon-measure'] {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -7px;
          margin-top: -6px;
        }

        &.ddp-icon-dimension-ab,
        &.ddp-icon-dimension-maplink,
        &.ddp-icon-measure-ab,
        &.ddp-icon-measure-maplink,
        &.ddp-icon-type-ab,
        &.ddp-icon-type-maplink {
          margin-top: -5px;
        }

        &.ddp-icon-dimension-sharp,
        &.ddp-icon-dimension-float,
        &.ddp-icon-dimension-tf,
        &.ddp-icon-dimension-int,
        &.ddp-icon-measure-sharp,
        &.ddp-icon-measure-float,
        &.ddp-icon-measure-tf,
        &.ddp-icon-measure-int,
        &.ddp-icon-type-sharp,
        &.ddp-icon-type-float,
        &.ddp-icon-type-tf,
        &.ddp-icon-type-int {
          margin-top: -4px;
        }
      }
    }

    .ddp-form-name {
      display: block;
      position: relative;
      padding: 3px 0px 3px 0;
      overflow: hidden;
      font-size: 0;

      .ddp-data-name {
        position: relative;
        display: inline-block;
        padding-right: 16px;
        max-width: 100%;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;
        vertical-align: top;

        &.ddp-padd0 {
          padding-right: 0;
        }
      }

      &:hover + .ddp-ui-tooltip-info {
        display: block;
        top: 100%;
        left: 0;
        white-space: normal;
      }
    }

    .ddp-td-schema {
      &:hover + .ddp-ui-tooltip-info {
        display: block;
        top: 100%;
        left: 100px;
        width: max-content;
        min-width: 100px;
        max-width: 200px;
        white-space: normal;

        &.ddp-top {
          top: inherit;
        }
      }

      + .ddp-ui-tooltip-info {
        em.ddp-icon-view-top {
          left: inhert;
          right: 1px;
          left: 45px;
        }

        &.ddp-left {
          left: 0;
        }

        &.ddp-type {
          left: 20px;
        }

        &.ddp-left2 {
          max-width: 400px;

          em.ddp-icon-view-top {
            left: 42px;
          }
        }
      }
    }

    .ddp-txt-edit {
      float: left;
      margin-left: 0;
      position: relative;

      a.ddp-icon-edit {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_edit2.png) no-repeat;
        background-position: left -34px;
      }

      .ddp-wrap-input {
        display: none;
        position: absolute;
        top: 1px;
        right: -15px;
        left: inherit;
        min-width: 382px;
        z-index: 10;

        &.ddp-type {
          bottom: 100%;
          margin-top: 0;
          margin-bottom: 7px;
          top: inherit;
        }

        &.type-input {
          min-width: 161px;
        }

        &.type-file {
          min-width: 495px;
        }
      }

      &.ddp-selected {
        .ddp-wrap-input {
          display: block;

          input {
            padding: 5px 0 6px 0;
          }
        }

        + {
          .ddp-wrap-datarecommend,
          .ddp-icon-time {
            display: none;
          }
        }
      }
    }

    em.ddp-icon-error2 {
      position: absolute;
      right: 0;
      top: 1px;
    }

    .ddp-txt-error-type {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .ddp-icon-error2 {
        position: relative;
        top: 0;
        left: 0;
        float: right;
        margin-left: 4px;
      }

      .ddp-txt-data {
      }
    }
  }

  &.ddp-table-view {
    thead tr th {
      position: relative;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;

      a.ddp-link-filter {
        position: absolute;
        top: 4px;
        right: 3px;
        display: inline-block;
        padding: 3px 4px;
        color: #4c515a;
        font-size: 12px;
        font-style: italic;
        background-color: #f3f3f4;

        &:hover {
          background-color: #e7e7ea;
        }
      }
    }

    tbody tr {
      td {
        padding: 3px 10px 4px 10px;
        cursor: pointer;
        border-right: 1px solid #e7e7ea;

        &:last-of-type {
          border-right: none;
        }
      }

      &:hover td {
        background-color: #f0f0f2;
      }

      td .ddp-icon-view {
        display: none;
        content: '';
        width: 6px;
        height: 11px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_dataview.png) no-repeat;
        background-position: left -68px;
      }

      &:hover td .ddp-icon-view {
        display: block;
      }

      &.ddp-selected td {
        background-color: #f2f1f8;
        padding: 2px 10px 3px 10px;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;

        &:first-of-type {
          padding-left: 9px;
          border-left: 1px solid $primary;
        }

        &:last-of-type {
          padding-right: 9px;
          border-right: 1px solid $primary;
        }

        .ddp-icon-view {
          display: block;
        }
      }
    }
  }

  &.ddp-table-form tbody tr.ddp-disabled {
    em.ddp-icon-view,
    .ddp-ui-checkbox {
      display: none;
    }

    &:nth-child(odd) td {
      background-color: #fafafa;
    }

    &:nth-child(even) td {
      background-color: #fff;
    }

    &:nth-child(odd) td {
      &:first-of-type {
        border-left: 1px solid #fafafa;
      }

      &:last-of-type {
        border-right: 1px solid #fafafa;
      }
    }

    &.ddp-selected:nth-child(odd) td {
      &:first-of-type {
        border-left: 1px solid $primary;
      }

      &:last-of-type {
        border-right: 1px solid $primary;
      }
    }
  }
}

.ddp-box-viewtable .ddp-ui-gridbody.ddp-action {
  bottom: 103px;
}

.ddp-box-schema {
  &.ddp-box-full {
    .ddp-wrap-fleft {
      width: 100%;
    }

    .ddp-wrap-fright {
      display: none;
    }
  }

  display: flex;
}

.ddp-view-schema .ddp-box-schema {
  .ddp-wrap-fleft {
    width: 340px;
  }

  .ddp-wrap-fright {
    width: 520px;
  }

  .ddp-wrap-value-setting {
    padding: 0;
    margin: 0;
  }
}

.ddp-box-schema {
  .ddp-wrap-fleft {
    float: left;
    width: 592px;
    height: 425px;
  }

  .ddp-wrap-fright {
    float: right;
    width: 592px;
    height: 425px;
  }

  .ddp-box-viewtable {
    height: 100%;
    box-sizing: border-box;
  }

  .ddp-box-setting {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #b7b9c2;
    box-sizing: border-box;

    .ddp-data-top-title {
      padding: 13px 16px;
      background-color: #f6f6f7;
      color: #4c515a;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ddp-data-delete {
      position: absolute;
      top: 56px;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      background-color: #fff;
      z-index: 3;

      span.ddp-txt {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -13px;
        color: #b7b9c2;
        font-size: 14px;
        text-align: center;
        box-sizing: border-box;
      }
    }

    .ddp-wrap-set-contents {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;

      &.ddp-full {
        top: 0;
      }
    }

    .ddp-wrap-value-det {
      position: relative;
      float: Left;
      width: 255px;
      height: 100%;
      border-right: 1px solid #e7e7ea;

      .ddp-top-set {
        border-bottom: 1px solid #e7e7e7;
        overflow: Hidden;
      }

      .ddp-data-name {
        float: left;
        padding: 23px 0 22px 16px;
        color: #4a515c;
        font-size: 14px;
      }

      &.ddp-type .ddp-data-name {
        padding: 19px 0 20px 16px;
        color: #4a515c;
        font-weight: bold;
        font-size: 13px;
      }

      .ddp-view-option {
        position: relative;
        top: 22px;
        margin-right: 16px;
        color: #4b515b;
        font-size: 12px;
      }

      .ddp-top-set .ddp-view-option.ddp-selected input {
        display: inline-block;
        width: 40px;
        margin-right: 5px;
        text-align: center;
        border-bottom: 1px solid #d7d8dc;
      }
    }
  }
}

.ddp-wrap-value-det.ddp-type ul.ddp-list-value {
  top: 56px;
}

ul.ddp-list-value {
  height: max-content;
  overflow-y: auto;

  li {
    padding: 6px 15px;
    min-height: 28px;
    color: #4b525b;
    font-size: 13px;
    box-sizing: border-box;

    &:nth-child(odd) {
      background-color: #fafafa;
    }

    &.ddp-total {
      color: #b7b9c2;
    }
  }
}

.ddp-wrap-value-setting {
  float: left;
  width: 100%;
  height: 100%;
  margin-left: -256px;
  padding-left: 256px;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
}

.ddp-ui-value-setting {
  height: 100%;

  .ddp-ui-value-setting {
    z-index: 1;
  }

  .ddp-form-column {
    box-sizing: border-box;
    min-height: 50vh;

    ul.ddp-list-value {
      top: 32px;
    }

    .ddp-wrap-value-det {
      max-height: 100%;
      border-right: 1px solid #b7b9c2;
      box-sizing: border-box;

      .ddp-label-value {
        background-color: #f7f7f8;
        padding: 8px 20px;
        color: #90969f;
        font-size: 12px;
        border-bottom: 1px solid #e9e9ec;
        box-sizing: border-box;
      }
    }
  }
}

.ddp-wrap-value-setting {
  .ddp-form-column .ddp-ui-setting {
    overflow: auto;
  }

  .ddp-ui-title {
    position: relative;
    padding: 19px 0 20px 16px;
    color: #4a515c;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid #b7b9c2;

    .ddp-btn-delete {
      display: inline-block;
      position: absolute;
      top: 23px;
      right: 13px;
      width: 11px;
      height: 12px;
      background: url(/assets/bi/images/icon_edit2.png) no-repeat;
      background-position: -24px -21px;

      &:hover {
        background-position-x: left;
      }

      &.ddp-fright {
        position: relative;
        top: 1px;
        right: 0;
        margin: 0 13px 0 5px;
      }
    }

    .ddp-btn-return-s.ddp-fright {
      position: relative;
      top: 2px;
      right: 0;
      margin: 0 13px 0 5px;
    }

    .ddp-data-name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.ddp-box-popupcontents3.ddp-configure {
  width: 1192px;

  .ddp-wrap-value-setting {
    margin: 0;
  }

  .ddp-box-schema {
    .ddp-box-setting {
      border-left: none;
    }

    .ddp-wrap-fleft {
      width: 672px;
    }

    .ddp-wrap-fright {
      width: 520px;
    }
  }

  .ddp-wrap-value-setting {
    padding: 0;
  }
}

.ddp-configure-schema {
  position: relative;

  .ddp-form-search {
    width: 240px;
  }

  .ddp-data-details.ddp-ui-preview-contents {
    position: absolute;
    top: 170px;
    left: 50px;
    right: 50px;
    bottom: 48px;
    height: auto;
  }

  &.ddp-info .ddp-data-details.ddp-ui-preview-contents {
    top: 185px;
  }

  &.ddp-info2 .ddp-data-details.ddp-ui-preview-contents {
    top: 219px;
  }
}

.ddp-type-top-option .ddp-ui-rightoption.ddp-type-schema .ddp-form-label2 {
  margin-left: 10px;
}

.ddp-configure-schema .ddp-ui-rightoption.ddp-type-schema {
  .ddp-wrap-dropdown {
    width: 110px;
  }

  .ddp-ui-setbtn {
    position: relative;
    float: left;

    .ddp-btn-setting {
      display: inline-block;
      position: relative;
      width: 30px;
      height: 30px;
      margin: 0 8px;
      border: 1px solid #d0d1d8;
      box-sizing: border-box;
      border-radius: 2px;

      &:hover {
        border-color: #b7b9c2;
      }
    }

    &.ddp-selected .ddp-btn-setting {
      border-color: #b7b9c2;
    }

    .ddp-btn-setting {
      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        margin: -7px 0 0 -7px;
        background: url(/assets/bi/images/icon_option.png) no-repeat;
        background-position: left -16px;
        content: '';
      }

      &:hover:before {
        background-position-x: -15px;
      }
    }

    &.ddp-selected .ddp-btn-setting:before {
      background-position-x: -15px;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      left: 8px;
      margin-top: 3px;
      z-index: 20;
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }
}

.ddp-wrap-value-setting .ddp-ui-setting {
  padding: 20px 20px 20px 20px;

  .ddp-setting-button {
    padding: 10px 0 0 0;
  }

  .ddp-wrap-edit3 {
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    .ddp-ui-label-name {
      margin-bottom: 5px;
      color: #4a515c;
      font-weight: bold;

      .ddp-icon-recommend {
        display: inline-block;
        margin-left: 5px;
        position: relative;
        vertical-align: middle;
      }
    }

    .ddp-view-label {
      float: left;
      width: 100%;

      .ddp-ui-label-name {
        float: left;
        width: initial;
      }
    }

    .ddp-ui-error {
      display: block;
      margin-top: 5px;
    }

    .ddp-apply .ddp-ui-error {
      margin-right: -52px;
    }

    .ddp-ui-error {
      &.ddp-full {
        padding: 0;
        margin-left: 0;
      }

      em.ddp-icon-error {
        display: inline-block;
        position: relative;
        top: 0;
        margin-right: 5px;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px 0;
        vertical-align: middle;
      }
    }

    .ddp-ui-label-name .ddp-wrap-hover-info {
      z-index: inherit;
    }
  }
}

.ddp-wrap-edit3 .ddp-ui-label-name .ddp-wrap-hover-info .ddp-box-layout4 {
  &.ddp-box-time {
    width: 290px;
    box-sizing: border-box;
  }

  .ddp-data-det dl.ddp-dl-det {
    padding: 5px 0;
    overflow: hidden;

    &:first-of-type {
      padding-top: 0;
    }

    dt {
      float: left;
      width: 35px;
      color: #4c515a;
      font-size: 12px;
    }

    dd {
      display: block;
      position: relative;
      overflow: hidden;
      padding-left: 10px;
      color: #90969f;
      font-size: 11px;

      em {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.ddp-wrap-value-setting .ddp-ui-setting .ddp-wrap-edit3 {
  .ddp-ui-label-name a.ddp-link-type {
    float: right;
    color: #b7b9c2;
    font-size: 12px;
    text-decoration: underline;
    font-style: italic;

    &:hover {
      color: #90969f;
    }
  }

  ul.ddp-list-checktype li {
    position: relative;
    padding: 5px 0;

    label {
      &.ddp-label-radio,
      &.ddp-label-checkbox {
        display: inline-block;
      }

      &.ddp-label-radio span.ddp-txt-radio,
      &.ddp-label-checkbox span.ddp-txt-checkbox {
        font-size: 13px;
      }
    }

    .ddp-form-checktype {
      .ddp-input-typebasic {
        position: absolute;
        top: 0;
        right: 0;
        width: 160px;
        box-sizing: Border-box;

        &.ddp-full {
          position: relative;
          width: 100%;
          margin-top: 5px;
        }
      }

      .ddp-input-apply {
        margin-top: 5px;
      }

      .ddp-type-input {
        padding-left: 16px;
      }
    }

    .ddp-ui-example {
      position: relative;
      padding-left: 20px;
      color: rgba(75, 81, 91, 0.5);

      em.ddp-icon-check {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 8px;
        height: 6px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -47px top;
      }
    }

    .ddp-ui-error {
      display: block;
      padding: 5px 0 0 16px;
      white-space: normal;
    }
  }
}

.ddp-ui-setting .ddp-ui-option-in {
  padding: 0 0 0 12px;
}

.ddp-wrap-value-setting .ddp-ui-setting {
  ul.ddp-list-dataview {
    margin: 0 0 0 -12px;
    border-top: 1px solid #e9e9ec;
    border-bottom: 1px solid #e9e9ec;

    li {
      padding: 5px 17px;
      color: #4b525b;
      font-size: 13px;

      &.ddp-total {
        color: #b7b9c2;
        font-size: 12px;
      }

      &.ddp-nodata {
        color: #b6b9c1;
        font-size: 13px;
      }

      &:nth-child(odd) {
        background-color: #fafafa;
      }
    }
  }

  .ddp-wrap-edit3 .ddp-data-time {
    margin-top: 3px;

    .ddp-type-label {
      line-height: 30px;
    }

    &.ddp-apply .ddp-input-apply {
      position: relative;
      padding-right: 52px;

      .ddp-btn-line-s {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px 10px;
      }
    }
  }
}

.ddp-ui-timestamp {
  padding: 20px 0 0 0;

  .ddp-txt-timestamp {
    position: relative;
    top: -4px;
    vertical-align: middle;

    em.ddp-icon-time2 {
      margin-right: 5px;
    }
  }

  .ddp-ui-setting {
    padding: 15px 0 0 18px;

    label.ddp-label-radio {
      display: inline-block;
      vertical-align: middle;

      + label.ddp-label-radio {
        margin-left: 20px;
      }
    }

    .ddp-type-selectbox {
      display: inline-block;
      margin-left: 9px;
      width: 228px;
      box-sizing: border-box;
      vertical-align: middle;

      ul.ddp-list-selectbox.ddp-selectdown {
        top: inherit;
        bottom: 34px;
      }
    }
  }
}

.ddp-box-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 103px;
  padding: 16px 0 16px 14px;
  border: 1px solid #4c515a;
  background-color: #fff;
  box-sizing: border-box;

  a.ddp-btn-box-close {
    color: #90969f;
    font-size: 13px;

    em.ddp-icon-box-close {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background: url(/assets/bi/images/btn_popclose.png) no-repeat;
      background-position: left -84px;
      vertical-align: middle;
    }
  }

  span.ddp-data-selet {
    margin-left: 150px;
    color: #4c515a;
    font-size: 14px;
  }

  &.ddp-type span.ddp-data-selet {
    margin-left: 0;
    font-size: 13px;
    font-weight: 100;
  }

  span.ddp-data-selet strong {
    margin-right: 3px;
  }

  .ddp-ui-checkaction {
    padding-left: 0;

    .ddp-type-selectbox {
      width: 180px;
    }
  }

  a.ddp-btn-line {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

.ddp-box-viewtable.ddp-selected .ddp-box-action.ddp-type {
  display: block;
  z-index: 10;
}

.ddp-box-action.ddp-type {
  display: none;
  height: 45px;

  .ddp-box-button {
    clear: both;
    padding: 17px 0 13px 0;
    text-align: center;

    [class*='ddp-btn-'] {
      position: relative;
      top: 0;
      right: 0;
      margin: 0 2px;
      vertical-align: middle;
    }
  }

  .ddp-type-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ddp-change-type {
    float: left;
  }

  a.ddp-btn-change {
    display: inline-block;
    padding: 13px 12px 15px 12px;
    height: 43px;
    color: #4b515b;
    box-sizing: border-box;
  }

  .ddp-change-type.ddp-selected a.ddp-btn-change {
    background-color: #f0f0f2;
  }

  a.ddp-btn-change {
    &:hover {
      background-color: #f0f0f2;
    }

    &:before {
      display: inline-block;
      width: 11px;
      height: 12px;
      margin-right: 7px;
      background: url(/assets/bi/images/icon_change.png) no-repeat;
      vertical-align: middle;
      content: '';
    }
  }

  .ddp-change-type {
    .ddp-box-layout4.ddp-change {
      display: none;
      position: absolute;
      bottom: 100%;
      right: 0;
      margin-bottom: 5px;
      width: 350px;

      .ddp-change-select {
        float: Left;

        .ddp-type-selectbox {
          float: left;
          width: 158px;

          + .ddp-type-selectbox {
            margin-left: 3px;
          }
        }
      }
    }

    &.ddp-selected .ddp-box-layout4.ddp-change {
      display: block;
      z-index: 10;
      width: 355px;
    }
  }

  .ddp-delete-type {
    float: left;
    position: relative;

    .ddp-btn-delete {
      display: inline-block;
      padding: 13px 12px 15px 12px;
      margin-left: 2px;
      height: 43px;
      color: #4b515b;
      font-size: 13px;
      box-sizing: border-box;
    }

    &.ddp-selected .ddp-btn-delete {
      background: #f0f0f2;
    }

    .ddp-btn-delete {
      &:hover {
        background: #f0f0f2;
      }

      &:before {
        display: inline-block;
        width: 11px;
        height: 12px;
        margin-right: 6px;
        background: url(/assets/bi/images/icon_edit2.png) no-repeat;
        background-position: left -21px;
        content: '';
        vertical-align: middle;
      }
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      bottom: 100%;
      right: 0;
      margin-bottom: 5px;
      width: 350px;
    }

    &.ddp-selected .ddp-box-layout4 {
      display: block;
    }

    .ddp-box-layout4.ddp-delete {
      .ddp-wrap-data-detail {
        padding-bottom: 0;

        .ddp-ui-top-title {
          padding-bottom: 0;
        }
      }

      .ddp-box-button {
        padding-top: 20px;
      }
    }
  }
}

/**********************************************************************************
    page : 데이터 저장소 myfile 항목설정
**********************************************************************************/

.ddp-box-source-create {
  min-height: 514px;

  .ddp-wrap-edit3 {
    position: relative;
    margin-top: 100px;

    &:first-of-type {
      margin-top: 0;
    }

    &.ddp-type .ddp-label-type {
      width: 170px;
    }

    .ddp-ui-edit-option em[class*='ddp-icon-'] {
      margin-right: 8px;
    }
  }
}

.ddp-wrap-edit3 .ddp-ui-edit-option {
  span.ddp-data-group {
    display: block;
    padding-top: 20px;
    margin-right: 29px;
    color: #4b515b;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;

    span.ddp-data-det {
      font-size: 12px;
      color: #b7bac1;
    }
  }

  &.ddp-padb15 {
    padding-bottom: 15px;
  }

  .ddp-wrap-data-group {
    .ddp-link-edit2 {
      display: inline-block;
      margin-top: 10px;
    }

    .ddp-result-num {
      padding-bottom: 8px;

      .ddp-link-edit2 {
        margin-top: 0;
      }

      span.ddp-txt-num {
        color: #4b515b;
        font-weight: 300;
        font-size: 13px;
      }
    }
  }
}

.ddp-link-edit2 {
  color: #b7b9c2;
  font-size: 12px;
  text-decoration: underline;
  font-style: italic;

  &:hover {
    color: #90969f;
    text-decoration: underline;
  }
}

/**********************************************************************************
	page : 데이터 저장소 myfile 항목설정_워크스페이스 설정
**********************************************************************************/

ul.ddp-ui-tab li {
  em {
    &.ddp-icon-user-s2,
    &.ddp-icon-group-s2 {
      margin-right: 8px;
    }
  }

  &.ddp-selected em {
    &.ddp-icon-user-s2,
    &.ddp-icon-group-s2 {
      opacity: 1;
    }
  }
}

.ddp-wrap-permission {
  &.ddp-type {
    padding-top: 0;

    .ddp-wrap-viewtable {
      top: 98px;
    }
  }

  position: relative;
  padding-top: 42px;
  height: 100%;
  box-sizing: border-box;

  .ddp-type-check {
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .ddp-wrap-viewtable {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    bottom: 68px;
  }

  .ddp-wrap-switch .ddp-form-search {
    width: 270px;
  }

  table.ddp-table-type2 tbody tr > td {
    .ddp-data-owner {
      float: left;
      color: #b7b9c2;
    }

    .ddp-txt-data {
      float: left;
      padding-right: 4px;
    }
  }
}

.ddp-box-popupcontents2.ddp-setting-source {
  width: 666px;

  > .ddp-wrap-edit2:first-of-type {
    margin-top: 0;
  }
}

/**********************************************************************************
	page : 데이터저장소 데이터소스상세 datagrid
**********************************************************************************/

.ddp-wrap-datadetail {
  .ddp-ui-option {
    padding: 20px 20px 15px 20px;
  }

  .ddp-wrap-datapreview {
    padding: 20px 0px 20px 0px;

    .ddp-label-name {
      padding: 25px 15px 8px 15px;
    }

    .ddp-box-preview {
      height: 293px;
      margin-bottom: 10px;
      overflow: auto;
      border-top: 1px solid #eaeaed;
    }
  }
}

.ddp-box-grid-detail .ddp-script-preview {
  border-top: 1px solid #eaeaed;
  overflow: auto;

  .ddp-txt-error3 {
    padding: 10px;
    color: #ca4b4b;
    font-size: 12px;
  }
}

.ddp-wrap-datadetail {
  .ddp-box-statistic .ddp-label-name {
    padding: 13px 0 15px 20px;
  }

  .ddp-label-name {
    display: block;
    padding: 0 0 5px 0;
    color: #4b515b;
    font-size: 12px;
  }
}

.ddp-datagrid .ddp-ui-option {
  .ddp-wrap-edit {
    padding-left: 30px;
  }

  .ddp-reset {
    float: left;
    position: relative;
    top: 8px;
    margin-left: 10px;
  }

  .ddp-data-form {
    position: relative;
    float: left;
    top: 10px;

    input.ddp-data-input {
      width: 40px;
      border: none;
      border-bottom: 1px solid #d7d8dc;
      color: #656eb2;
      font-size: 12px;
      text-align: center;
    }
  }

  .ddp-box-btn2 {
    float: left;
  }

  .ddp-btn-bg {
    float: left;
    margin-left: 10px;

    em.ddp-icon-widget-gridsave {
      margin-right: 5px;
    }
  }
}

.ddp-fix-grid {
  position: absolute;
  bottom: 45px;
  right: 65px;
  top: 185px;
  left: 65px;
  border: 1px solid #d5d6db;

  &.ddp-msg-info1 {
    top: 267px;
  }

  &.ddp-msg-info2 {
    top: 302px;
  }
}

/**********************************************************************************
    page : 데이터 저장소 db 항목설정
**********************************************************************************/

.ddp-box-source-create.ddp-box-type {
  width: 880px;

  > .ddp-wrap-edit2:first-of-type {
    margin-top: 0;
  }

  .ddp-wrap-edit3 {
    margin-top: 40px;
  }
}

[class*='ddp-wrap-edit'] .ddp-box-sub {
  margin-top: 10px;
  padding: 20px;
  background-color: #f6f6f7;

  label.ddp-label-type {
    color: #b7b9c2;
    font-size: 13px;
  }

  input.ddp-input-type {
    background: transparent;
  }

  .ddp-clear:first-of-type .ddp-wrap-edit2 {
    margin-top: 0;
  }
}

.ddp-box-source-create.ddp-box-type .ddp-wrap-edit3.ddp-type .ddp-type-selectbox {
  width: 290px;
}

[class*='ddp-wrap-edit'] .ddp-box-sub {
  .ddp-wrap-edit2 .ddp-label-type {
    color: #4b515b;
    font-size: 13px;
  }

  .ddp-box-sub-option {
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    .ddp-label-type {
      width: 100%;
    }

    .ddp-option-in label.ddp-label-radio + label.ddp-label-radio {
      margin-left: 28px;
    }

    .ddp-label-radio {
      + .ddp-box-option-input {
        top: -5px;

        + .ddp-txt-error {
          position: relative;
          margin-left: 5px;
          vertical-align: top;
        }
      }

      vertical-align: top;
      position: relative;
      top: 2px;
    }
  }
}

/* 삭제 예정 */

.ddp-box-sub .ddp-wrap-boxdata {
  width: 100%;

  + .ddp-wrap-boxdata {
    padding-top: 20px;
  }

  .ddp-label-type {
    float: left;
    width: 150px;
    padding: 0;
    margin-right: 10px;
    font-size: 13px;
    color: #b7bac1;
    line-height: 1.5em;
    box-sizing: border-box;
    overflow: hidden;
  }

  .ddp-box-form {
    display: block;
    overflow: hidden;

    .ddp-data-txt {
      display: block;
      padding-bottom: 5px;
      font-size: 13px;
      color: #4b515b;
      line-height: 1.5em;
    }

    ul.ddp-list-sub li {
      position: relative;
      padding: 0 0 5px 10px;
      color: #b7bac1;
      font-size: 12px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b7bac1;
        content: '';
      }
    }
  }
}

table.ddp-wrap-boxdata {
  margin: -5px 0;
  table-layout: auto;

  tr {
    th {
      line-height: 1.5em;
      padding: 5px 20px 5px 0;
      font-size: 13px;
      color: #b7bac1;
      font-weight: normal;
      white-space: nowrap;
      vertical-align: top;
    }

    td {
      padding: 5px 0 5px 0;
      font-size: 13px;
      color: #4b515b;
      line-height: 1.5em;
      vertical-align: top;

      [class*='ddp-icon-flow'] {
        margin-right: 8px;
      }

      .ddp-list-set li {
        padding: 7px 0;
        font-size: 13px;

        &:first-of-type {
          padding-top: 0;
        }
      }

      .ddp-data-txt {
        display: block;
        padding-bottom: 5px;
        font-size: 13px;
        color: #4b515b;
        line-height: 1.5em;
      }

      ul.ddp-list-sub li {
        position: relative;
        padding: 0 0 5px 10px;
        color: #b7bac1;
        font-size: 12px;

        &:before {
          display: inline-block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #b7bac1;
          content: '';
        }

        .ddp-sub {
          padding-left: 15px;
          color: #b7bac1;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

.ddp-wrap-edit3 .ddp-box-sub .ddp-option-in .ddp-size-s {
  width: 80px;
  box-sizing: border-box;
}

.ddp-wrap-edit2 .ddp-box-sub .ddp-option-in {
  .ddp-size-s {
    width: 80px;
    box-sizing: border-box;
  }

  .ddp-size-sm {
    width: 110px;
  }

  &.ddp-inline .ddp-size-sm {
    display: inline-block;
    margin-right: 5px;
  }
}

.ddp-wrap-edit3 {
  .ddp-box-sub .ddp-option-in input.ddp-input-typebasic {
    display: inline-block;
  }

  &.ddp-type .ddp-ui-cycle .ddp-wrap-hover-info:hover .ddp-box-layout4 {
    right: -20px;
    top: 100%;
    margin-top: 5px;
  }
}

.ddp-ui-cycle {
  .ddp-form-time input.ddp-input-time,
  .ddp-form-wrapper .ddp-form-time {
    width: 80px;
  }

  .ddp-type-cycle {
    float: left;
    width: 200px !important;
    margin-right: 4px;
  }

  .ddp-list-buttons {
    float: left;
    margin-right: 5px;

    li {
      width: 58px;
    }
  }

  .ddp-input-typebasic.ddp-crontab {
    width: 490px;
    margin-right: 5px;
  }
}

.ddp-form-time {
  position: relative;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #d2d2d6;
  background-color: #fff;
}

.ddp-ui-cycle .ddp-txt-error {
  display: block;
  margin-top: 5px;
}

.ddp-form-time {
  input.ddp-input-time {
    display: inline-block;
    padding: 7px 13px 6px 33px;
    width: 100px;
    border: none;
    box-sizing: border-box;
  }

  em.ddp-icon-time {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -7px;
  }
}

.ddp-form-wrapper {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #d2d2d6;
  background-color: #fff;

  .ddp-type-selectbox {
    width: 120px !important;
    float: Left;
    margin-right: 0;
    border: none;
    border-radius: 0;
    border-right: 1px solid #d2d2d6;
  }

  .ddp-form-time {
    width: 120px;
    border: none;
  }
}

.ddp-ui-cycle {
  .ddp-input-typebasic,
  .ddp-input-apply {
    display: inline-block;
    width: 250px;
    box-sizing: border-box;
  }
}

.ddp-type-selectbox ul.ddp-list-selectbox.ddp-box-s {
  max-height: 120px;
}

table.ddp-table-form tr.ddp-tr-select td {
  color: rgba(76, 81, 90, 0.5);
}

em.ddp-icon-select2 {
  display: inline-block;
  width: 8px;
  height: 6px;
  background: url(/assets/bi/images/icon_select2.png) no-repeat;
  background-position: -47px top;
}

.ddp-wrap-partition {
  position: relative;
  padding: 10px 0 0 16px;
  overflow: Hidden;
  box-sizing: border-box;
}

.ddp-fix-label {
  position: relative;
  z-index: 1;
}

.ddp-ui-partition {
  display: block;
  white-space: nowrap;
  overflow: auto;
}

.ddp-list-partition {
  padding: 0 2px 10px 2px;

  li {
    display: inline-block;
    position: relative;
    width: 143px;
    padding-right: 6px;
    box-sizing: border-box;

    &.ddp-disabled:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background-color: #fff;
      content: '';
    }

    .ddp-ui-label {
      display: block;
      color: #4b515b;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
    }
  }
}

.ddp-wrap-partition .ddp-btn-control {
  position: relative;
}

.ddp-box-source-create .ddp-wrap-edit3 .ddp-ui-edit-option .ddp-btn-control em[class*='ddp-icon-'] {
  margin-right: 0;
}

.ddp-list-partition.ddp-type li {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 2px;
  background-color: #f6f6f7;

  span.ddp-ui-label {
    display: block;
    font-size: 12px;
    color: #90969f;
    font-weight: 300;
  }

  .ddp-data {
    padding: 5px 0 0 0;

    span.ddp-txt-data {
      display: block;
      padding: 7px 0 0 0;
      color: #4b515b;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/**********************************************************************************
	page : 데이터 저장소 데이터소스상세 팝업
**********************************************************************************/

table.ddp-table-type3.ddp-popup tr td {
  span.ddp-data-date {
    color: #90969f;
    font-size: 12px;
  }

  padding: 10px 10px 11px 10px;
}

.ddp-data-details {
  position: relative;
  padding-left: 460px;
  border: 1px solid #d5d6db;

  .ddp-title {
    padding: 15px 20px;
    color: #4b515b;
    font-size: 14px;
    font-weight: bold;
  }

  .ddp-wrap-sourcename {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 459px;

    .ddp-wrap-table table.ddp-table-form {
      margin-top: -1px;
    }
  }

  .ddp-wrap-detail {
    display: block;
    padding: 15px 30px;
    border-left: 1px solid #e7e7ea;
    overflow: hidden;

    .ddp-title {
      padding: 0;
      margin-bottom: -10px;
    }
  }

  .ddp-ui-detail {
    padding-top: 25px;
    margin: 0 -20px;

    .ddp-popularity {
      height: 120px;
    }

    [class*='ddp-col-'] {
      padding: 0 20px;
      border-left: 1px dotted #ccc;

      &:first-of-type {
        border-left: none;
      }
    }

    .ddp-ui-title {
      padding: 10px 0 25px 0;
      font-size: 16px;
    }

    .ddp-ui-title2 {
      padding: 10px 20px 25px 20px;
      font-size: 16px;
    }
  }

  .ddp-wrap-detail.ddp-type .ddp-ui-detail {
    .ddp-ui-title {
      padding: 10px 0 25px 0;
      font-size: 13px;
      font-weight: bold;
    }

    .ddp-ui-title2 {
      padding: 10px 20px 25px 20px;
      font-size: 13px;
      font-weight: bold;
    }

    table.ddp-table-detail2 tr th {
      color: #90969f;
    }
  }

  .ddp-ui-detail {
    table.ddp-table-detail2 tr {
      th {
        padding-left: 0;
        color: #90969f;
        font-size: 13px;
        vertical-align: top;

        &.ddp-th-valid {
          color: #53ac66;
        }

        &.ddp-th-missing {
          color: #ed422f;
        }
      }

      &.ddp-tr-sub th {
        padding-left: 20px;
      }
    }

    .ddp-bar {
      position: relative;
      width: 200px;
      height: 15px;
      border-radius: 2px;
      background-color: #f3f3f4;

      span {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background-color: $primary;
      }
    }
  }
}

.ddp-wrap-detail {
  &.ddp-type label.ddp-label-detail + .ddp-ui-detail-in .ddp-box-histogram {
    margin: 15px 0 50px 0;
  }

  .ddp-box-histogram {
    padding: 10px;
    margin: 0 0 50px 0;
    height: 118px;
    border: 1px solid #dddde2;
    box-sizing: border-box;
  }
}

.ddp-data-details {
  .ddp-box-histogram {
    padding: 10px;
    margin: 0 0 50px 0;
    height: 118px;
    border: 1px solid #dddde2;
    box-sizing: border-box;
  }

  table.ddp-table-order {
    width: 100%;
    margin-top: 15px;

    tr td {
      padding: 11px 13px;
    }
  }

  ul.ddp-list-graph {
    margin: 30px -12px 0 -12px;

    li {
      padding: 0 12px;
    }
  }

  &.ddp-ui-preview-contents {
    position: relative;
    top: 0;
    height: 100%;
    padding-left: 0;
    border: 1px solid #d0d1d8;
    box-sizing: border-box;
    overflow: auto;

    &.type-border {
      border: 1px solid #cdd0e2;

      .ddp-table-detail4 {
        border: none;
        border-bottom: 1px solid #cdd0e2;
      }
    }

    table tr th {
      border-bottom: 1px solid #e9e9ec;
    }

    .ddp-ui-selected-option.ddp-selected .ddp-wrap-popup2 {
      left: inherit;
      right: 0;
      width: 140px;
      z-index: 20;
    }
  }
}

.ddp-ui-preview-contents {
  table {
    &.ddp-table-form.ddp-table-details tbody tr {
      &:nth-child(odd):hover td {
        background-color: #fafafa;
      }

      &:nth-child(even):hover td {
        background-color: #fff;
      }

      td {
        &.ddp-selected {
          background-color: #f2f1f8 !important;

          &:hover {
            background-color: #f2f1f8 !important;
          }

          border: 1px solid #9ca2cc;
          padding: 12px 9px 13px 9px;

          &.ddp-info {
            padding-right: 29px;
          }
        }

        &.ddp-info.ddp-disabled {
          cursor: initial !important;
        }
      }
    }

    &.ddp-table-type2 tbody tr td.ddp-selected .ddp-input-form input.ddp-txt-input {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 12px 9px 13px 9px;
      content: '';
    }
  }

  .ddp-data-details {
    &.ddp-info {
      top: 76px;
    }

    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;

    .ddp-wrap-sourcename {
      top: 0;
      overflow: auto;
    }

    .ddp-wrap-detail {
      overflow: auto;
      height: 100%;
      box-sizing: border-box;
    }
  }
}

/*.ddp-ui-preview-contents table.ddp-table-form.ddp-table-details tbody tr td:hover {background-color:#f1f1f3 !important; cursor:pointer;}*/

/*.ddp-ui-preview-contents table.ddp-table-form.ddp-table-details tbody tr td.ddp-selected:after {display:inline-block; position:absolute; top:0; right:0; bottom:0; left:0; border:1px solid #9ca2cc; content:'';}*/

.ddp-data-details {
  .ddp-wrap-table-detail {
    margin-right: 0;
    margin-left: 0;
    padding: 40px 0;
  }

  &.ddp-ui-preview-contents .ddp-type-input tbody tr > td {
    &.ddp-padding-none {
      padding: 0;
    }

    .ddp-input-form {
      position: static;
    }
  }
}

/**********************************************************************************
        사용자 리스트
**********************************************************************************/

a.ddp-link-selection {
  display: inline-block;
  width: 88px;
  padding: 5px 0;
  margin-right: 2px;
  color: $primary;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid $primary;
  background-color: #f2f1f8;

  &:hover {
    color: #fff;
    border: 1px solid $primary;
    background-color: $primary;
  }

  em.ddp-icon-check {
    display: inline-block;
    width: 11px;
    height: 8px;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_select2.png) no-repeat;
    background-position: -15px -9px;
  }

  &:hover em.ddp-icon-check {
    background-position: -15px -28px;
  }

  em.ddp-icon-close {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 8px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -82px;
  }

  &:hover em.ddp-icon-close {
    background-position: -10px -82px;
  }

  &.ddp-reject {
    color: #e35252;
    border: 1px solid rgba(227, 82, 82, 0.6);
    background: none;

    &:hover {
      color: #c63b3b;
      border: 1px solid #e35252;
      background: none;
    }
  }
}

/**********************************************************************************
	사용자 deny popup
**********************************************************************************/

.ddp-box-total {
  padding: 34px 0;
  margin-top: 35px;
  color: #90969f;
  font-size: 22px;
  text-align: center;
  background-color: #f6f6f7;
}

/**********************************************************************************
	사용자 new member
**********************************************************************************/

.ddp-ui-user-new {
  padding: 0 50px;
  text-align: left;
}

.ddp-type-contents-in .ddp-top-option {
  position: relative;
  padding-bottom: 29px;
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc;
  overflow: hidden;

  > .ddp-wrap-edit3:first-of-type {
    margin-top: 0;
  }
}

.ddp-ui-edit-option dl.ddp-dl-option {
  display: block;
  position: relative;
  top: -2px;
  overflow: hidden;

  dt {
    float: left;
    margin-left: 15px;
    margin-right: 5px;
    color: #b7b9c2;
    font-size: 14px;
  }

  dd {
    float: left;
    color: #4b515b;
    font-size: 14px;
  }
}

.ddp-type-contents-in .ddp-top-option {
  .ddp-ui-edit-option dl.ddp-dl-option {
    dt {
      margin-left: 0;
    }

    dd {
      margin-right: 45px;
    }
  }

  .ddp-ui-link {
    position: absolute;
    top: 0;
    right: 0;

    a.ddp-btn-link {
      &:first-of-type:before {
        display: none;
      }

      em {
        &.ddp-icon-link-cut,
        &.ddp-icon-link-password {
          display: inline-block;
          margin-right: 5px;
          background: url(/assets/bi/images/icon_control.png) no-repeat;
          vertical-align: middle;
        }

        &.ddp-icon-link-cut {
          width: 10px;
          height: 10px;
          background-position: left -194px;
        }
      }

      &:hover {
        color: #90969f;

        em.ddp-icon-link-cut {
          background-position: -11px -194px;
        }
      }

      em.ddp-icon-link-password {
        width: 11px;
        height: 12px;
        background-position: left -205px;
      }

      &:hover em.ddp-icon-link-password {
        background-position: -12px -205px;
      }
    }
  }
}

/*.ddp-type-contents-in .ddp-top-option .ddp-ui-link a.ddp-btn-link {position:relative; top:8px; padding:0 20px; color:#b7b9c2; font-size:13px;}*/

/*.ddp-type-contents-in .ddp-top-option .ddp-ui-link a.ddp-btn-link:before {display:inline-block; content:''; height:14px; position:absolute; left:0; top:50%; margin-top:-7px; border-left:1px solid #d2d3d9;}*/

.ddp-ui-input-form {
  .ddp-label-type .ddp-info {
    color: #4b515b;
    font-size: 14px;
    font-weight: normal;
    margin-left: 26px;
  }

  a.ddp-link-edit {
    position: absolute;
    left: 0;
    color: #b7b9c2;
    font-size: 12px;
    text-decoration: underline;
    font-style: italic;
  }
}

.ddp-ui-user-option {
  display: block;
  padding: 0;
  overflow: Hidden;

  .ddp-ui-alart {
    margin-top: -10px;
    margin-bottom: 35px;
    color: #b7b9c2;
    font-size: 14px;

    em.ddp-icon-alart {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -16px -44px;
      vertical-align: middle;
    }
  }

  .ddp-ui-input-form label.ddp-label-type {
    font-weight: bold;
  }
}

.ddp-ui-input-form {
  &.ddp-line {
    padding-bottom: 20px;
    margin-bottom: 28px;
    border-bottom: 1px dotted #ccc;
  }

  dl.ddp-dl-option {
    display: block;
    position: relative;
    top: -2px;
    overflow: hidden;
    white-space: nowrap;

    dt {
      float: left;
      margin-left: 15px;
      margin-right: 5px;
      color: #b7b9c2;
      font-size: 14px;
      font-weight: 300;
    }

    dd {
      float: left;
      color: #4b515b;
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.ddp-box-group .ddp-ui-input-form dl.ddp-dl-option dd {
  letter-spacing: -1px;
}

.ddp-box-popupcontents2.ddp-box-group {
  position: relative;
  margin-top: 30px;
  border-top: 1px dotted #ccc;

  .ddp-wrap-edit2 {
    margin-top: 60px;

    &:first-of-type {
      margin-top: 36px;
    }
  }
}

a.ddp-btn-linktype {
  color: #b7b9c2;
  font-size: 13px;

  .ddp-icon-link-cut {
    width: 10px;
    height: 10px;
    background-position: left -194px;
  }

  &:hover {
    color: #90969f;

    em.ddp-icon-link-cut {
      background-position: -11px -194px;
    }

    .ddp-icon-link-edit {
      background-position: -39px -97px;
    }
  }
}

.ddp-box-popupcontents2.ddp-box-group a.ddp-btn-linktype {
  position: absolute;
  top: -30px;
  right: 0;
}

/**********************************************************************************
	사용자 detail
**********************************************************************************/

.ddp-ui-userdetail {
  position: relative;
  padding-left: 160px;

  .ddp-data-photo {
    position: absolute;
    top: 0;
    left: 12px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
  }

  &.type-user .ddp-data-photo {
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -45px;
    background: url(/assets/bi/images/img_profile.png) no-repeat;
    background-position: left -133px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  table.ddp-table-detail tr td .ddp-data-follow-photo {
    display: inline-block;
    float: left;
    margin-right: 4px;
    width: 24px;
    height: 24px;
    background: url(/assets/bi/images/img_profile.png) no-repeat;
    background-position: left -224px;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ddp-data-photo img {
    width: 100%;
    height: 100%;
  }

  .ddp-data-user-info {
    padding-bottom: 3px;
    color: #4b515b;
    font-size: 22px;

    em.ddp-icon-fav {
      display: inline-block;
      width: 13px;
      height: 12px;
      background: url(/assets/bi/images/icon_fav.png) no-repeat;
      cursor: pointer;
      background-position: 0 -31px;

      &:hover {
        background-position: -26px -31px;
      }

      &.ddp-selected {
        background-position: -13px -31px;
      }
    }
  }
}

table.ddp-list-form2 {
  width: 100%;
  table-layout: fixed;
  margin-top: 15px;
  border-top: 1px solid #b7b9c2;
  border-bottom: 1px solid #b7b9c2;

  tr {
    td {
      padding: 15px 18px;
      color: #4c515a;
      font-size: 13px;
      font-weight: 300;
      border-bottom: 1px solid #e7e7ea;
    }

    &:last-of-type td {
      border-bottom: none;
    }

    td {
      .ddp-link-info {
        &.ddp-normal {
          position: relative;
          font-weight: normal;

          &:hover {
            font-weight: bold;
          }
        }

        &.ddp-group {
          position: relative;
          padding-right: 17px;
          color: #4c515a;
          font-size: 13px;
          font-weight: normal;

          &:hover {
            text-decoration: underline;
          }

          .ddp-icon-window {
            display: none;
            position: absolute;
            top: 3px;
            right: 0;
          }

          &:hover .ddp-icon-window {
            display: block;
          }
        }
      }

      .ddp-box-layout4 table.ddp-table-pop tr td {
        border-bottom: none;
      }
    }
  }
}

/**********************************************************************************
	사용자 change owner
**********************************************************************************/

.ddp-wrap-popup .ddp-ui-popup.ddp-type {
  padding: 80px 0 80px 0;

  span.ddp-ui-pop-title {
    line-height: 40px;
    padding-bottom: 10px;
  }

  .ddp-ui-user-content {
    padding: 20px 48px 0px 48px;
    margin-bottom: -35px;
  }
}

.ddp-box-owner {
  position: relative;
  padding: 20px 20px;
  height: 375px;
  border: 1px solid #d5d6db;
  box-sizing: border-box;

  .ddp-form-search {
    width: 100%;
  }

  .ddp-ui-moretype {
    padding: 13px 0 0 0;
  }

  .ddp-nodata {
    color: #b7b9c2;
    font-size: 13px;
  }
}

ul.ddp-list-owner {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 34px;
  padding: 0 20px;
  overflow: auto;

  li {
    padding: 6px 0;
  }
}

/**************************************************************
  Page : 모니터링 Layout
**************************************************************/

.ddp-layout-contents .ddp-ui-contents-in {
  position: Relative;
  height: 100%;
}

.ddp-layout-top-menu {
  background-color: #fff;

  /* layer fill content */
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  /* drop shadow */

  .ddp-top-label {
    float: left;
    padding: 13px 57px 13px 20px;
    font-size: 22px;
    color: #b6b9c2;
  }

  .ddp-top-menu {
    float: left;

    li {
      float: left;
      padding-right: 28px;

      a {
        display: block;
        position: relative;
        padding: 18px 0;
        color: #4b515b;
        font-size: 14px;
      }

      &.ddp-selected a {
        color: $primary;
        font-weight: bold;

        &:before {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 3px solid $primary;
          content: '';
        }
      }
    }
  }
}

.ddp-ui-header-option {
  float: right;
  padding-right: 74px;

  .ddp-link-info {
    margin-top: 17px;
    float: left;
    width: 19px;
    height: 19px;
    background: url(/assets/bi/images/icon_header_info.png) no-repeat;

    &:hover {
      background-position: -20px top;
    }
  }

  .ddp-ui-more {
    position: relative;
    float: left;
    margin: 17px 0 0 10px;

    &.ddp-selected .ddp-icon-more:before {
      background-position: left -4px;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      right: -10px;
      width: 212px;
      margin-top: 9px;
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }
}

.ddp-wrap-contents-det {
  margin-top: -54px;
  padding: 54px 0 0 0;
  height: 100%;
  box-sizing: Border-box;

  .ddp-ui-contents-det {
    height: 100%;
    padding: 10px 80px 18px 80px;
    overflow: auto;
    box-sizing: border-box;

    .ddp-wrap-top-filtering {
      background-color: #fff;
    }
  }
}

/**********************************************************************************
        모니터링
**********************************************************************************/

.ddp-layout-contents .ddp-ui-contents-in {
  position: Relative;
  height: 100%;

  .ddp-box-searching {
    position: relative;
    margin: 0 -80px;
    padding: 20px 80px;
    border-bottom: 1px solid #cbcdd2;
    background-color: #dedee3;
    overflow: hidden;
    z-index: 1;

    .ddp-form-searching {
      position: relative;
      float: left;
      padding: 0 0 0 43px;
      width: 675px;
      border-radius: 4px;
      border: 1px solid #d0d1d9;
      background-color: #fff;
      box-sizing: border-box;
      overflow: hidden;

      input {
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 16px;
        border: none;
      }

      .ddp-icon-searching {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 17px;
        width: 17px;
        height: 17px;
        margin-top: -9px;
        background: url(/assets/bi/images/btn_search.png) no-repeat;
        background-position: left -20px;
      }
    }

    .ddp-ui-edit-option {
      position: relative;
      top: 17px;
      float: left;

      label.ddp-label-checkbox {
        display: inline-block;
        margin-left: 40px;
      }
    }
  }
}

.ddp-data-monitoring {
  margin-top: 40px;

  .ddp-list-title {
    padding-bottom: 7px;
    color: #4c515a;
    font-size: 14px;

    em {
      &[class*='ddp-icon-'] {
        display: inline-block;
        margin-right: 8px;
        background: url(/assets/bi/images/icon_monitoring.png) no-repeat;
        vertical-align: middle;
      }

      &.ddp-icon-table {
        width: 13px;
        height: 14px;
      }

      &.ddp-icon-sql {
        width: 17px;
        height: 14px;
        background-position: left -15px;
      }

      &.ddp-icon-dataflow {
        width: 13px;
        height: 13px;
        background-position: left -30px;
      }

      &.ddp-icon-datasource {
        width: 12px;
        height: 14px;
        background-position: left -44px;
      }
    }
  }
}

table.ddp-table-list {
  width: 100%;
  table-layout: fixed;
  border: 1px solid #d2d3da;
  background-color: #fff;

  tr {
    th {
      padding: 7px 15px;
      color: #4b515b;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      border-bottom: 1px solid #d2d3d9;
      background-color: #f8f8f8;
    }

    td {
      padding: 14px 15px;
      color: #4c515a;
      font-size: 14px;
      vertical-align: top;
      border-top: 1px solid #eaeaed;
    }

    &.ddp-selected td {
      padding: 13px 15px 13px 15px;

      &:first-of-type {
        padding-left: 14px;
      }

      &:last-of-type {
        padding-right: 35px;
      }
    }

    &:first-of-type td {
      border-top: none;
    }

    td {
      .ddp-txt-long {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        color: #4c515a;
        font-size: 14px;

        span.ddp-txt-colortype {
          color: #b7bac1;
        }
      }

      strong {
        &.ddp-strong {
          font-size: 12px;
        }

        &.ddp-strong2 {
          color: #b7b9c2;
          font-size: 12px;
        }
      }

      .ddp-txt-sql {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }
}

.ddp-ui-contents-list .ddp-data-none {
  padding: 10px 0 0 25px;
  color: #b7b9c2;
  font-size: 13px;
}

.ddp-log-statistics {
  padding-bottom: 54px;

  .ddp-ui-option .ddp-clear {
    margin: 0 5px 0 0;
    padding: 0;
  }

  .ddp-clear {
    padding: 20px 0 0 0;
    margin: 0 -5px;

    [class*='ddp-col-'] {
      padding: 0 5px;
    }
  }

  .ddp-data-title {
    position: relative;
    padding-bottom: 6px;
    color: #4b515b;
    font-size: 13px;

    a.ddp-more {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
    }
  }

  .ddp-box-chart {
    height: 168px;
    border: 1px solid #d8d8de;
    border-radius: 3px;
    background-color: #fff;
  }
}

/**************************************************************
  Page : 05_06_EngineMonitoring_01overview
**************************************************************/

.ddp-ui-contents-det {
  .ddp-list-option {
    padding: 10px 0 20px 0;

    .ddp-wrap-boxtab {
      float: left;
    }

    .ddp-option-right {
      float: right;
    }

    .ddp-form-label2 label.ddp-label-type {
      color: #90969f;
    }
  }

  .ddp-box-type {
    background-color: #fff;
    border: 1px solid #d2d3da;
    border-radius: 2px;
    margin: 4px 0 10px 0;
  }

  .ddp-view-setting {
    margin: 6px 0 8px 0;

    .ddp-label-name {
      float: left;

      .ddp-data-date {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-left: 6px;
        color: #90969f;
        font-size: 13px;
        vertical-align: top;
      }

      .ddp-type-selectbox {
        display: inline-block;
        position: relative;
        top: -5px;
        width: 77px;
        margin: 0 6px;
      }

      .ddp-btn-refresh {
        display: inline-block;
        position: relative;
        top: -7px;
        width: 12px;
        height: 13px;
        margin-left: 5px;
        background: url(/assets/bi/images/icon_refresh2.png) no-repeat;
        background-position: -42px top;
      }

      .ddp-txt-label {
        float: left;
        color: #4b515b;
        font-size: 14px;
      }

      .ddp-wrap-info {
        position: relative;
        top: 3px;
        float: left;
        margin: 0 0 0 9px;

        .ddp-btn-info2 {
          display: inline-block;
          width: 13px;
          height: 13px;
          background: url(/assets/bi/images/icon_info.png) no-repeat;
          background-position: left -16px;
        }

        &:hover .ddp-box-layout4 {
          display: block;
        }

        .ddp-wrap-hover-layout {
          position: absolute;
          top: -10px;
          left: 100%;
        }

        .ddp-box-layout4 {
          display: none;
          margin-left: 5px;
          width: auto;
          min-width: 320px;
          z-index: 10;

          table {
            tr td {
              white-space: nowrap;
            }

            &.ddp-table-info tr th {
              font-weight: normal;
              color: #90969f;
              letter-spacing: -0.6px;
            }
          }
        }

        .ddp-bar-size {
          display: block;
          position: relative;
          height: 12px;
          margin-top: 5px;
          background-color: #e7e7ea;

          .ddp-data-bar {
            .ddp-ui-tooltip-info {
              display: none;
              top: 100%;
              margin-top: 8px;
              margin-left: -29px;
              width: 57px;
              text-align: center;
              box-sizing: border-box;
            }

            &:hover .ddp-ui-tooltip-info {
              display: block;
            }

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: #4b515b;
            cursor: pointer;
          }

          .ddp-data-line {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            border-left: 1px solid #dc494f;
          }
        }
      }
    }
  }
}

.ddp-wrap-engine-graph {
  display: flex;
  margin-left: -6px;

  .ddp-box-graph {
    position: relative;
    margin-left: 6px;
    border-radius: 3px;

    .ddp-btn-link-go {
      display: inline-block;
      position: relative;
      top: 4px;
      margin-left: 5px;
      width: 12px;
      height: 13px;
      background: url(/assets/bi/images/icon_buttons.png) no-repeat;
      background-position: left -376px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    &.type-cpu {
      position: relative;
      width: 250px;
      height: 250px;
      background-color: #5373c6;
    }

    &.type-memory {
      position: relative;
      width: 250px;
      height: 250px;
      background-color: #5373c6;
      background-color: #5373c6;

      .ddp-icon-status2 {
        position: absolute;
        top: 16px;
        right: 10px;
      }
    }

    .ddp-box-label {
      padding: 19px 18px 0 18px;
      color: #fff;
      font-size: 14px;
    }

    .ddp-graph-round {
      display: table;
      position: absolute;
      bottom: 30px;
      left: 50%;
      margin-left: -77px;
      width: 154px;
      height: 154px;

      .ddp-graph-in {
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        .ddp-data-num {
          display: block;
          font-size: 40px;
          color: #fff;
        }

        .ddp-data-per {
          display: inline-block;
          padding: 2px 6px 3px 6px;
          margin-top: 9px;
          color: #fff;
          font-size: 12px;
          border-radius: 2px;
          background-color: #415b9e;
        }
      }
    }

    &.type-memory .ddp-graph-round .ddp-graph-in .ddp-data-per {
      background-color: #2f426c;
    }

    .ddp-data-per {
      .ddp-icon-up {
        display: inline-block;
        position: relative;
        top: 6px;
        width: 7px;
        height: 4px;
        margin-right: 4px;
        background: url(/assets/bi/images/icon_graph_per.png) no-repeat;
        transform: rotate(180deg);
        vertical-align: top;
      }

      .ddp-icon-down {
        display: inline-block;
        position: relative;
        top: 6px;
        width: 7px;
        height: 4px;
        margin-right: 4px;
        background: url(/assets/bi/images/icon_graph_per.png) no-repeat;
        transform: rotate(180deg);
        vertical-align: top;
        transform: rotate(0deg);
      }
    }

    .ddp-graph-line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 79px;
    }

    .ddp-graph-num {
      position: absolute;
      bottom: 35px;
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
    }

    .ddp-icon-status {
      position: absolute;
      top: 19px;
      right: 17px;

      .ddp-icon-error,
      .ddp-icon-warning {
        display: none;
        width: 19px;
        height: 18px;
        background: url(/assets/bi/images/icon_confidence.png) no-repeat;
        background-position: -20px -21px;
      }
    }

    &.type-count-num .ddp-icon-status2 .ddp-icon-warning,
    .ddp-icon-warning {
      display: none;
      width: 19px;
      height: 18px;
      background: url(/assets/bi/images/icon_confidence.png) no-repeat;
      background-position: -20px -21px;
    }

    .ddp-icon-status .ddp-icon-error {
      width: 20px;
      height: 20px;
      background-position: -21px -57px;
    }

    &.type-warning {
      background-color: #f3b110;

      .ddp-icon-status .ddp-icon-warning {
        display: block;
      }

      .ddp-graph-round .ddp-graph-in .ddp-data-per {
        background-color: #c28d0d;
      }
    }

    &.type-error {
      background-color: #ba363a;

      .ddp-icon-status .ddp-icon-error {
        display: inline-block;
      }

      .ddp-graph-round .ddp-graph-in .ddp-data-per {
        background-color: #952a2e;
      }
    }
  }

  .ddp-wrap-line-graph {
    flex: auto;
    display: flex;
    flex-direction: column;
  }

  .ddp-box-graph {
    &.type-count {
      margin-bottom: 6px;
      height: 122px;
      background-color: #383f49;
    }

    &.type-count-num {
      width: 200px;
      margin-bottom: 6px;
      height: 122px;
      background-color: #75ab4f;

      .ddp-icon-status2 {
        position: absolute;
        top: 16px;
        right: 10px;

        .ddp-icon-warning {
        }
      }

      .ddp-data-per {
        display: inline-block;
        padding: 2px 6px 3px 6px;
        margin-left: 5px;
        border-radius: 2px;
        font-size: 12px;
        background-color: #5e8940;
        color: #fff;
      }

      &.type-warning {
        background-color: #f3a611;

        .ddp-icon-warning {
          display: block;
          float: left;
        }

        .ddp-data-per {
          background-color: #dc494f;
        }
      }
    }

    &.type-datasource,
    &.type-segment {
      width: 250px;
      height: 122px;
      margin-bottom: 6px;
      background-color: #ffaa35;
    }

    &.type-datasource.type-warning {
      background-color: #ffaa35;
    }

    &.type-segment {
      background-color: #e94f5f;
    }

    &.type-datasource {
      .ddp-data-per {
        display: inline-block;
        padding: 2px 6px 3px 6px;
        border-radius: 2px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.type-warning {
        .ddp-icon-warning {
          display: inline-block;
          margin-left: 3px;
          vertical-align: middle;
        }

        .ddp-data-per {
          background-color: #dc494f;
        }
      }
    }

    &.type-task {
      margin-bottom: 6px;
      height: 122px;
      background-color: #478743;
    }
  }

  .ddp-wrap-num-graph {
    display: flex;
    flex-direction: column;
  }
}

.ddp-ui-contents-det {
  .ddp-view-setting .ddp-date-setting {
    float: right;

    .ddp-btn-toggle {
      float: left;
      height: 30px;
      margin-left: 3px;
      padding: 7px 10px 7px 10px;
      background-color: #d0d1d8;
      border-radius: 2px;
      color: #4b515b;
      font-size: 13px;
      text-align: center;
      box-sizing: border-box;

      &.ddp-selected {
        background-color: #4b515b;
        color: #fff;
      }
    }

    .ddp-data-date {
      padding: 6px 0;
      margin-right: 10px;
      float: left;
      color: #4b515b;

      .ddp-btn-refresh {
        display: inline-block;
        width: 12px;
        height: 13px;
        margin-left: 5px;
        background: url(/assets/bi/images/icon_refresh2.png) no-repeat;
        background-position: -42px top;
        vertical-align: middle;
      }
    }
  }

  .ddp-box-type .ddp-wrap-status-value {
    display: flex;
    padding: 20px 20px 19px 20px;
    margin-left: -6px;
    border-bottom: 1px dotted #d2d3da;

    .ddp-ui-status-value {
      flex: auto;
      margin-left: 6px;
      box-sizing: border-box;

      &.type-all {
        flex: initial;
        width: 80px;
      }

      &:first-of-type {
        padding-left: 0;
      }
    }

    .ddp-label-status {
      display: block;
      position: relative;
      padding: 11px 0 11px 20px;
      height: 38px;
      border-radius: 3px;
      font-size: 13px;
      font-weight: bold;
      box-sizing: border-box;
      cursor: pointer;

      &.type-selected:after {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #90969f;
        content: '';
        border-radius: 3px;
      }

      &.type-all {
        padding: 11px 0 11px 0;
        text-align: center;
        background-color: #f5f5f5;
        color: #90969f;

        &.type-selected:after {
          border: 1px solid #90969f;
        }
      }

      &.type-warning {
        color: #f7af88;
        background-color: rgba(255, 182, 2, 0.1);

        &.type-selected:after {
          border: 1px solid #ffb602;
        }
      }

      &.type-error {
        color: #dc494f;
        background-color: rgba(237, 57, 73, 0.1);

        &.type-selected:after {
          border: 1px solid #dc494f;
        }
      }

      &.type-historical,
      &.type-normal {
        color: #58bb90;
        background-color: rgba(120, 196, 164, 0.1);

        &.type-selected:after {
          border: 1px solid #78c4a4;
        }
      }

      &.type-middleManager {
        color: #dc494f;
        background-color: rgba(237, 57, 73, 0.1);

        &.type-selected:after {
          border: 1px solid #dc494f;
        }
      }

      &:hover {
        color: #fff !important;
      }

      &.type-all:hover {
        background-color: #90969f;
      }

      &.type-warning:hover {
        background-color: #ffb602;
      }

      &.type-error:hover {
        background-color: #dc494f;
      }

      &.type-historical:hover,
      &.type-normal:hover {
        background-color: #58bb90;
      }

      &.type-middleManager:hover {
        background-color: #dc494f;
      }

      [class*='ddp-icon-status'] {
        position: absolute;
        top: 50%;
        right: 11px;
        margin-top: -10px;
      }

      &:hover {
        [class*='ddp-icon-status'] {
          background-position-x: -21px;
        }

        &:before {
          display: none;
        }
      }

      &:before {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        border-radius: 4px;
        content: '';
      }

      &.type-selected:before {
        display: block;
      }

      &.type-warning:before {
        background-color: #ffb602;
      }

      &.type-error:before {
        background-color: #dc494f;
      }

      &.type-historical:before,
      &.type-normal:before {
        background-color: #78c4a4;
      }

      &.type-middleManager:before {
        background-color: #dc494f;
      }

      &.type-all:before {
        background-color: #90969f;
      }
    }
  }
}

.ddp-type-server {
  padding-bottom: 20px;

  .ddp-box-top-setting {
    .ddp-type-search {
      float: left;
      margin: 15px 20px 0 20px;
    }

    .ddp-type-option {
      float: right;
      padding: 0 20px 8px 0;

      .ddp-form-label2,
      .ddp-wrap-edit3,
      .ddp-list-tab-button {
        float: left;
      }

      .ddp-form-label2 {
        padding: 12px 18px 0 0;

        .ddp-type-selectbox {
          width: 140px;
        }
      }

      .ddp-wrap-edit3 {
        width: auto;
        padding: 10px 0 0 0;

        label {
          &.ddp-label-type {
            display: inline-block;
            width: auto;
            margin-right: 8px;
          }

          &.ddp-label-radio .ddp-tag-status {
            margin: -2px 0;
          }
        }
      }

      .ddp-list-tab-button {
        margin: 13px 0 0 20px;
      }
    }
  }

  .ddp-box-detail {
    padding: 0 20px;
    margin-bottom: -10px;

    .ddp-grid-detail {
      position: Relative;
      margin: -2px -2px 10px -2px;
      clear: both;

      .ddp-grid-box {
        float: left;
        width: 36px;
        padding: 10px 0 11px 0;
        margin: 2px;
        background-color: rgba(120, 196, 164, 0.1);
        text-align: center;
        color: #58bb90;

        &.type-error {
          background-color: #dc494f;
          color: #fff;
        }
      }

      .ddp-wrap-tooltip {
        display: inline-block;
        position: absolute;
        max-width: 260px;
        padding: 10px 10px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 13px;
        box-sizing: Border-box;

        .ddp-data-error {
          color: #dc494f;
          font-weight: bold;
        }

        .ddp-data-det {
          padding: 2px 0 10px 0;
          color: #b6b9c2;
        }

        .ddp-data-name {
          color: #b6b9c2;
          font-size: 13px;

          .ddp-txt-name {
            color: #fff;
          }
        }
      }
    }

    .ddp-table-detail3 {
      margin-bottom: 10px;
    }
  }
}

table {
  &.ddp-table-detail3 {
    border-collapse: collapse;
    border: 1px solid #d0d1d9;

    tr {
      th {
        position: relative;
        padding: 9px 30px 9px 14px;
        color: #90969f;
        font-weight: normal;
        text-align: Left;
        border-bottom: 1px solid #d0d1d9;
        border-right: 1px solid #e7e7ea;

        &:last-of-type {
          border-right: none;
        }

        [class*='ddp-icon-array'] {
          position: absolute;
          top: 50%;
          right: 5px;
          margin-top: -10px;
        }
      }

      td {
        padding: 9px 18px 9px 18px;
        border: 1px solid #e7e7ea;
        border-left: none;
        vertical-align: top;
        color: #4b515b;
        font-size: 13px;
      }

      &:last-of-type td {
        border-bottom: none;
      }

      &:nth-child(odd) td {
        background-color: #fafafa;
      }

      td {
        ul.ddp-list-data {
          margin: -9px -18px;

          li {
            padding: 9px 18px 9px 18px;

            &:nth-child(odd) {
              background-color: #fafafa;
            }
          }
        }

        &:last-of-type {
          border-right: none;
        }

        .ddp-tag-status {
          margin: -3px 0;
        }

        .ddp-data-node {
          font-size: 13px;
          font-weight: bold;

          &.type-broker {
            color: #f7af88;
          }

          &.type-coordinator {
            color: #f78887;
          }

          &.type-historical {
            color: #93cf6d;
          }

          &.type-overlord {
            color: #54bc9b;
          }

          &.type-middleManager {
            color: #8899d9;
          }
        }

        .ddp-type-status-error {
          position: relative;

          .ddp-txt-error {
            position: relative;
            font-style: normal;
            margin-left: 8px;
            cursor: pointer;

            .ddp-icon-error {
              margin-right: 4px;
            }
          }

          .ddp-box-layout4 {
            .ddp-data-title .ddp-sub-title {
              font-weight: normal;
            }

            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 5px;
            width: auto;

            .ddp-data-det {
              white-space: nowrap;
            }
          }

          &:hover .ddp-box-layout4 {
            display: block;
          }
        }
      }
    }
  }

  &.ddp-table-type3 tbody tr td .ddp-box-btn-able {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #ddd;
      background-color: #f6f6f7;

      .ddp-ui-tooltip-info {
        display: block;
      }
    }

    .ddp-ui-tooltip-info {
      display: none;
      position: absolute;
      right: -10px;
      left: auto;

      .ddp-icon-view-top {
        right: 25px;
        margin-left: 0;
        left: auto;
      }
    }

    .ddp-icon-enable,
    .ddp-icon-disable {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      background: url(/assets/bi/images/icon_buttons.png) no-repeat;
    }

    .ddp-icon-enable {
      width: 15px;
      height: 9px;
      margin: -5px 0 0 -7px;
      background-position: -32px -163px;
    }

    .ddp-icon-disable {
      width: 16px;
      height: 16px;
      margin: -7px 0 0 -8px;
      background-position: left -390px;
    }
  }
}

/*table.ddp-table-detail3 tr:nth-child(odd) td {background-color:#fafafa;}*/

/**************************************************************
  Page : 05_06EngineMonitoring_02Ingestion_01List
**************************************************************/

.ddp-ui-contents-det .ddp-list-option {
  padding: 10px 0 20px 0;

  .ddp-wrap-boxtab {
    float: left;
  }

  .ddp-option-right {
    float: right;
  }

  .ddp-form-label2 label.ddp-label-type {
    color: #90969f;
  }
}

.ddp-form-label2 .ddp-wrap-dropdown.type-refresh {
  position: relative;
  padding-right: 29px;
}

.ddp-ui-contents-det .ddp-wrap-top-filtering {
  margin: 0 0 10px 0;
  padding: 0 20px;
}

.ddp-wrap-top-filtering {
  .ddp-filter-search.type-dataname .ddp-form-filter-search {
    width: 234px;
  }

  .ddp-form-filtering:first-of-type .ddp-result-filtering {
    margin-left: 0;
  }
}

/* refresh form */

.ddp-wrap-dropdown.type-refresh .ddp-btn-refresh {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 0 2px 2px 0;
  background-color: #90969f;

  &:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -9px;
    width: 18px;
    height: 15px;
    background: url(/assets/bi/images/btn_refresh.png) no-repeat;
    background-position: -36px top;
    content: '';
  }
}

/**************************************************************
  Page : 05_06EngineMonitoring_02Ingestion_01Task_02Detail
**************************************************************/

.ddp-top-flow .ddp-wrap-navi .ddp-data-name {
  display: block;
  padding: 14px 0;
  font-size: 16px;
  color: #4b515b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ddp-ui-datadetail {
  &.type-detail {
    .ddp-data-sub {
      padding: 0 20px;
    }

    .ddp-wrap-log {
      position: relative;
      margin-top: 10px;
      height: 397px;
      padding: 15px 15px 76px 15px;
      background-color: #35393f;
      box-sizing: border-box;

      .ddp-box-log {
        height: 100%;
        color: #fff;
      }
    }

    .ddp-box-log-option {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      background: #f6f6f7;
      border-radius: 2px;

      .ddp-left-option {
        float: left;
      }

      .ddp-dl-option {
        padding: 14px 0 14px 13px;

        dt {
          float: left;
          position: relative;
          top: 8px;
          padding-right: 6px;
          color: #4b515b;
          font-size: 12px;
        }

        dd {
          float: left;

          .ddp-data-status {
            position: relative;
            top: 8px;
            float: left;
            margin-right: 16px;
            font-weight: bold;
            color: #4b515b;
            font-size: 12px;
          }

          .ddp-box-status {
            float: left;
            margin-right: 4px;
            padding: 6px 8px;
            color: #fff;
            font-size: 13px;
            border-radius: 3px;

            &.type-error {
              background-color: #ed3949;
            }

            &.type-warning {
              background-color: #ffba00;
            }

            &:before {
              display: inline-block;
              background: url(/assets/bi/images/icon_info3.png) no-repeat;
              content: '';
              vertical-align: middle;
            }

            &.type-error:before {
              width: 13px;
              height: 13px;
              background-position: -14px -27px;
            }

            &.type-warning:before {
              width: 13px;
              height: 12px;
              background-position: -14px -67px;
            }
          }
        }
      }

      .ddp-right-option {
        float: right;
      }

      .ddp-ui-btn-log {
        float: left;
        padding: 20px 25px 0 0;
      }

      .ddp-btn-log {
        float: left;
        margin-left: 15px;
        background: url(/assets/bi/images/btn_log.png) no-repeat;

        &.type-refresh {
          width: 14px;
          height: 14px;
        }

        &.type-down {
          width: 13px;
          height: 13px;
          background-position-y: -15px;
        }

        &.type-newwindow {
          width: 14px;
          height: 12px;
          background-position-y: -29px;
        }

        &.type-newtab {
          width: 13px;
          height: 13px;
          background-position-y: -43px;
        }
      }
    }

    .ddp-box-graph {
      height: 250px;
      padding: 20px;
      background-color: #383f49;
      box-sizing: border-box;
    }
  }

  .ddp-label-option {
    position: relative;

    .ddp-type-selectbox {
      position: absolute;
      top: -12px;
      right: 0;
      width: 180px;
    }
  }
}

.ddp-detail-contents.type-form {
  .ddp-option-type {
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 1;

    .ddp-type-selectbox {
      width: 180px;
    }
  }

  .ddp-wrap-monitoring {
    height: 330px;
    background-color: #383f49;
  }
}

.ddp-detail-overview {
  margin-top: 20px;

  .ddp-label {
    margin-bottom: 10px;
    color: #4e5368;
    font-weight: bold;
  }

  .ddp-box-viewtable {
    position: absolute;
    top: 373px;
    left: 0;
    right: 0;
    bottom: 20px;
    min-height: auto;
    border: 1px solid #d0d1d8;
    overflow: auto;
  }
}

.ddp-detail-contents {
  .ddp-view-datadetail {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0 20px 20px;
    overflow: auto;

    .ddp-ui-datadetail .ddp-label-detail {
      font-size: 13px;
      font-weight: bold;
    }

    .ddp-wrap-graph {
      height: 230px;
      background-color: #383f49;
    }
  }

  .ddp-ui-datadetail.type-detail + .ddp-ui-datadetail.type-detail {
    padding-top: 20px;
  }
}

.ddp-table-detail tr td .ddp-datasource {
  .ddp-tag-status {
    display: inline-block;
    margin-right: 8px;
  }

  .ddp-txt-info {
    display: inline-block;
  }
}

/**************************************************************
  popup : 05_06DataMonitoring_01overview_01DruidInfo_popup
**************************************************************/

.ddp-pop-preview.ddp-type.page-information {
  left: 170px;
  right: 170px;
}

.ddp-wrap-informaiton {
  position: relative;
  padding: 0 20px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1;

  .ddp-ui-detail {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #e7e7ea;

    &:first-of-type {
      border-top: none;
    }

    &.ddp-task-detail {
      padding: 20px 0;

      table.ddp-table-detail2 {
        tr th {
          padding-left: 0;
        }

        margin-bottom: 14px;
      }
    }
  }
}

/**********************************************************************************
	모니터링 flow
**********************************************************************************/

.ddp-type-flow {
  position: absolute;
  top: 101px;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;

  &.ddp-flow {
    background-color: #e7e7ea;
    overflow: hidden;
  }

  .ddp-type-top-option {
    padding: 13px 20px;
    background-color: #fff;

    .ddp-wrap-dropdown {
      width: 96px;
    }

    em.ddp-icon-widget-save {
      position: relative;
      margin-left: 15px;

      .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
        right: 18px;
      }
    }
  }
}

.ddp-view-flow {
  overflow: hidden;

  .ddp-wrap-box {
    margin: 0 -20px;
    box-sizing: border-box;
    background-color: #fff;
  }
}

.ddp-icon-flowline {
  position: absolute;
  top: 10px;
  left: 0;
  width: 40px;
  height: 1px;
  margin-left: -20px;
  border-top: 1px solid #b7b9c2;
  z-index: 1;

  &.ddp-right {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -20px;
  }

  &:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -2px;
    margin-top: -5px;
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #b7b9c2;
  }

  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -5px;
    content: '';
    width: 10px;
    height: 7px;
    background: url(/assets/bi/images/icon_flowline.png) no-repeat;
  }

  &.ddp-type-dot {
    border-top: 1px dotted #b7b9c2;
  }
}

.ddp-box-flow {
  width: 37%;
  float: left;
  padding: 0 20px;
  box-sizing: border-box;

  &.ddp-center {
    width: inherit;
    position: absolute;
    top: 0;
    left: 37%;
    right: 37%;
  }

  &.ddp-last {
    float: right;
  }
}

.ddp-wrap-box-flow {
  position: relative;
  overflow: hidden;
}

.ddp-data-flow {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #eaeaed;
  box-sizing: border-box;

  .ddp-title {
    padding: 7px 0;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }

  table.ddp-table-grid {
    border-bottom: none;
  }

  .ddp-wrap-table {
    overflow: auto;
  }
}

table.ddp-table-grid {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #ebebed;
  border-bottom: 1px solid #ebebed;

  tr {
    th {
      padding: 10px 7px;
      color: #4b515b;
      font-size: 12px;
      text-align: left;
      font-weight: normal;
      border-left: 1px solid #ebebed;
      border-bottom: 1px solid #ebebed;
    }

    td {
      padding: 5px 7px;
      font-size: 12px;
      color: #4a525c;
      text-align: left;
      border-left: 1px solid #ebebed;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:nth-child(odd) td {
      background-color: #fafafa;
    }

    th:first-of-type,
    td:first-of-type {
      border-left: none;
    }
  }
}

em {
  &[class*='ddp-icon-mt-'] {
    display: inline-block;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_monitoring.png) no-repeat;
    vertical-align: middle;
  }

  &.ddp-icon-mt-table {
    width: 13px;
    height: 14px;
  }

  &.ddp-icon-mt-sql {
    width: 17px;
    height: 14px;
    background-position: left -15px;
  }

  &.ddp-icon-mt-dataflow {
    width: 13px;
    height: 13px;
    background-position: left -30px;
  }

  &.ddp-icon-mt-datasource {
    width: 12px;
    height: 14px;
    background-position: left -44px;
  }

  &.ddp-icon-mt-data {
    width: 13px;
    height: 14px;
    background-position: left -59px;
  }
}

.ddp-type-flow {
  .ddp-wrap-result {
    padding: 11px 11px 0 11px;
  }

  &.ddp-flow .ddp-wrap-result {
    padding-bottom: 10px;
  }

  .ddp-wrap-result {
    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }
  }
}

.ddp-wrap-naviarea .ddp-ui-navidata {
  .ddp-data-lineage-num {
    float: left;
    padding: 15px 9px 5px 0;
    font-size: 13px;
    color: #90969f;
  }

  ul.ddp-list-tab-button {
    margin-top: 11px;
    margin-right: 8px;
    border: 1px solid #b7b9c3;

    li {
      position: Relative;

      .ddp-ui-tooltip-info {
        left: inherit;
        right: -10px;

        em.ddp-icon-view-top {
          left: inherit;
          right: 19px;
        }
      }
    }
  }
}

.ddp-box-flowdetail.ddp-default {
  width: 306px;
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;

  &:before {
    display: none;
  }

  .ddp-box-in {
    bottom: 0;
  }

  .ddp-data-filename,
  .ddp-data-explain {
    cursor: default;
  }

  .ddp-data-filename {
    margin-top: 25px;
  }
}

.ddp-box-lineage {
  padding: 10px 23px;
  white-space: nowrap;
}

.ddp-view-flow .ddp-data-lineage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow: auto;

  &.ddp-selected {
    right: 310px;
  }
}

.ddp-wrap-entity {
  display: inline-block;
  position: relative;
  padding: 0 18px;
  margin-right: 23px;
  white-space: nowrap;

  .ddp-btn-plus {
    display: inline-block;
    width: 18px;
    height: 56px;
    background: url(/assets/bi/images/btn_plus.png) no-repeat;

    &:hover {
      background-position: -18px top;
    }

    &.ddp-start {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.ddp-end {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.ddp-box-entity {
  &:last-of-type {
    margin-right: 0;
  }

  display: inline-block;
  width: 190px;
  margin-right: 36px;
  padding: 0 12px;
  position: relative;
  border: 1px solid #e9ebef;
  border-radius: 2px;
  box-sizing: border-box;
  vertical-align: top;
  cursor: pointer;

  &.ddp-search-unit {
    border: 1px solid #4a515c;
  }

  &.ddp-click-unit {
    border: 1px solid $primary;
    background-color: $primary;

    .ddp-ui-name {
      color: #fff;
    }

    em {
      &.ddp-icon-mt2-table {
        background-position: -17px top;
      }

      &.ddp-icon-mt2-dataflow {
        background-position: -17px -16px;
      }

      &.ddp-icon-mt2-sql {
        background-position: -23px -32px;
      }
    }

    &.ddp-selected {
      background-color: #fff;
      color: #4a515c;

      em {
        &.ddp-icon-mt2-table {
          background-position: 0 top;
        }

        &.ddp-icon-mt2-dataflow {
          background-position: 0 -16px;
        }

        &.ddp-icon-mt2-sql {
          background-position: 0 -32px;
        }
      }
    }
  }

  &:first-of-type:after {
    display: none;
  }

  &:after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 25px;
    left: -44px;
    width: 44px;
    height: 7px;
    background: url(/assets/bi/images/icon_flowline2.png) no-repeat;
  }

  .ddp-ui-name {
    display: block;
    position: relative;
    padding: 20px 15px 20px 33px;
    height: 54px;
    color: #35393f;
    font-size: 12px;
    font-weight: bold;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .ddp-data-sql {
      display: block;
      margin-top: -5px;
      color: #4b515b;
      font-size: 12px;
      font-weight: normal;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }

  .ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 24px;
    right: 12px;
    width: 10px;
    height: 6px;
    background: url(/assets/bi/images/icon_selecview.png) no-repeat;
    background-position: left -51px;
  }

  &.ddp-detail-unit:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 2px solid $primary;
  }

  &.ddp-connection {
    background-color: #f2f1f9;

    .ddp-ui-name {
      color: $primary;
    }

    em {
      &.ddp-icon-mt2-table {
        background-position: -34px top;
      }

      &.ddp-icon-mt2-dataflow {
        background-position: -32px -16px;
      }

      &.ddp-icon-mt2-sql {
        background-position: -46px -32px;
      }
    }

    &.ddp-selected {
      background-color: #fff;
      color: #4a515c;

      .ddp-ui-name {
        color: #4a515c;
      }

      em {
        &.ddp-icon-mt2-table {
          background-position: 0 top;
        }

        &.ddp-icon-mt2-dataflow {
          background-position: 0 -16px;
        }

        &.ddp-icon-mt2-sql {
          background-position: 0 -32px;
        }
      }
    }
  }

  &.ddp-selected {
    .ddp-icon-view {
      transform: rotate(180deg);
    }

    .ddp-list-column {
      display: block;
    }
  }

  em {
    &[class*='ddp-icon-mt2-'] {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 8px;
      margin-right: 6px;
      background: url(/assets/bi/images/icon_monitoring2.png) no-repeat;
      vertical-align: middle;
    }

    &.ddp-icon-mt2-table {
      width: 16px;
      height: 15px;
      margin-top: -8px;
    }

    &.ddp-icon-mt2-dataflow {
      width: 16px;
      height: 15px;
      margin-top: -8px;
      background-position: left -16px;
    }

    &.ddp-icon-mt2-sql {
      left: 7px;
      width: 22px;
      height: 20px;
      margin-top: -10px;
      background-position: left -32px;
    }
  }

  .ddp-list-column {
    display: none;
    padding-bottom: 8px;

    li {
      padding: 2px 0;

      &:first-of-type {
        padding-top: 0;
      }

      a.ddp-box-column {
        display: block;
        position: relative;
        padding: 8px 8px 8px 44px;
        border: 1px solid #e7e7ea;
        border-radius: 2px;
        font-size: 12px;
        color: #4b515b;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.ddp-list-sql li a.ddp-box-column {
      padding-left: 15px;
    }

    li {
      a.ddp-box-column .ddp-icon-type-ab {
        position: absolute;
        top: 50%;
        left: 16px;
      }

      &.ddp-selected a.ddp-box-column {
        background-color: $primary;
        color: #fff;

        .ddp-icon-type-ab {
          background-position: -14px top;
        }
      }

      &.ddp-connection a.ddp-box-column {
        background-color: #f2f1f9;
        border: 1px solid #e9ebef;
        color: $primary;

        .ddp-icon-type-ab {
          background-position: -71px top;
        }
      }
    }
  }
}

/**********************************************************************************
        모니터링 jobstatistics detail
**********************************************************************************/

.ddp-logdetail {
  text-align: left;

  .ddp-data-logdet {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      display: inline-block;
    }

    &.ddp-long {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;
    }
  }

  .ddp-wrap-logdetail {
    float: right;
    margin-top: -3px;
    display: none;

    .ddp-btn-detail {
      position: relative;
      display: inline-block;
      margin-left: 5px;
      width: 19px;
      height: 19px;

      &:hover {
        background-color: #e7e7ea;
      }
    }

    &.ddp-selected .ddp-btn-detail {
      background-color: #e7e7ea;
    }

    .ddp-btn-detail {
      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -6px;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/icon_detail.png) no-repeat;
        content: '';
        opacity: 0.6;
      }

      &:hover:before {
        opacity: 1;
      }
    }

    &.ddp-selected .ddp-pop-logdetail {
      display: block;
    }
  }

  .ddp-pop-logdetail {
    display: none;
    position: absolute;
    left: 20px;
    top: 100%;
    padding: 15px 15px 53px 15px;
    margin-top: 4px;
    width: 845px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #fff;
    text-align: left;
    z-index: 1;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    .ddp-logdetal-contents {
      min-height: 54px;
      max-height: 180px;
      color: #4c515a;
      font-size: 13px;
      line-height: 18px;
      overflow: auto;
    }

    .ddp-log-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 15px 15px 15px;

      .ddp-link-copy {
        position: relative;
        top: 7px;
        color: #90969f;

        &:hover {
          text-decoration: underline;
        }

        &:before {
          display: inline-block;
          position: relative;
          top: -1px;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          background: url(/assets/bi/images/icon_clone.png) no-repeat;
          content: '';
          vertical-align: middle;
        }
      }

      .ddp-btn-line {
        float: right;
      }
    }
  }
}

/**********************************************************************************
	error
**********************************************************************************/

.ddp-wrap-error {
  display: table;
  width: 100%;
  height: 100%;
  background: url(/assets/bi/images/bg_error.png) no-repeat;
  background-size: 100% 100%;

  .ddp-ui-error {
    display: table-cell;
    width: 100%;
    text-align: center;
    vertical-align: middle;

    .ddp-num-error {
      display: block;
      color: #7575ea;
      font-size: 110px;
      font-weight: 100;
    }

    .ddp-txt-error {
      display: block;
      color: #7575ea;
      font-size: 28px;
      font-weight: 300;
    }

    .ddp-det-error {
      display: block;
      padding: 40px 0 0 0;
      color: #272950;
      font-size: 16px;
      font-weight: 300;
    }

    .ddp-ui-buttons {
      padding: 40px 0 0 0;
      text-align: center;

      a[class*='ddp-btn-'] {
        margin: 0 5px;
      }
    }
  }
}

a {
  &.ddp-btn-bgtype {
    display: inline-block;
    width: 230px;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    border: 1px solid #272950;
    background-color: #272950;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #7575ea;
      background-color: #7575ea;
    }
  }

  &.ddp-btn-linetype {
    display: inline-block;
    width: 230px;
    padding: 10px 0;
    text-align: center;
    color: #272950;
    border-radius: 3px;
    border: 1px solid #272950;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #7575ea;
      color: #7575ea;
    }
  }
}

/**********************************************************************************
	클러스터 _ add
**********************************************************************************/

.ddp-cluster-add {
  width: 850px;
  margin: 0 auto 0 auto;
  text-align: left;
  border: 1px solid #d0d1d8;
  overflow: hidden;
}

.ddp-box-application {
  float: left;
  width: 50%;
  height: 500px;
  padding: 19px 19px;
  border-right: 1px solid #d0d1d8;
  background-color: #f6f6f7;
  box-sizing: border-box;
  overflow: auto;

  ul.ddp-list-application li {
    width: 50%;
    float: left;
    padding: 5px 5px;
    box-sizing: border-box;

    a {
      display: table;
      height: 98px;
      width: 100%;

      .ddp-add-application {
        display: table-cell;
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid #ddd;
        background-color: #fff;
        text-align: center;
        vertical-align: middle;
      }

      &:hover .ddp-add-application:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #454545;
        content: '';
      }
    }

    &.ddp-selected a .ddp-add-application:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #454545;
      content: '';
    }

    a {
      .ddp-add-application span.ddp-bg-selected {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 24px;
        background-color: #454545;

        em.ddp-icon-select {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 11px;
          height: 8px;
          margin: -4px 0 0 -6px;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          background-position: -27px top;
        }
      }

      &:hover .ddp-add-application span.ddp-bg-selected {
        display: block;
      }
    }

    &.ddp-selected a .ddp-add-application span.ddp-bg-selected {
      display: block;
    }
  }
}

.ddp-cluster-add .ddp-wrap-ex {
  float: left;
  width: 50%;
  padding: 30px 19px;
  box-sizing: border-box;

  .ddp-form-option {
    padding-bottom: 30px;
  }
}

.ddp-form-option {
  .ddp-label-type {
    display: block;
    padding-bottom: 9px;
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
  }

  .ddp-type-selectbox {
    width: 217px;
  }
}

.ddp-cluster-add .ddp-wrap-ex {
  .ddp-ui-ex {
    margin-top: 0;
  }

  ul.ddp-list-ex li {
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.ddp-cluster-add2 {
  width: 1192px;
  height: 100%;
  margin: 0 auto;
  text-align: left;

  .ddp-ui-version {
    float: left;
    width: 257px;
    height: 100%;
    border: 1px solid #e4e5ea;
    box-sizing: border-box;
  }

  .ddp-ui-set-info {
    display: block;
    position: relative;
    height: 100%;
    overflow: auto;
  }
}

.ddp-ui-version {
  .ddp-label-list {
    position: relative;
    padding: 22px 20px;
    cursor: pointer;
  }

  .ddp-ui-step.ddp-selected .ddp-label-list {
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 3px solid $primary;
      content: '';
    }

    .ddp-tag-step {
      background-color: $primary;
    }
  }

  .ddp-label-list .ddp-txt-label {
    color: #90969f;
    font-size: 14px;
  }

  .ddp-ui-step.ddp-selected .ddp-label-list .ddp-txt-label {
    color: $primary;
    font-weight: bold;
  }

  .ddp-step-contents {
    display: none;
    padding: 12px 0;
    border-top: 1px solid #ddd;
    background-color: #f6f6f7;
    box-sizing: border-box;
  }

  .ddp-ui-step {
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      border-bottom: none;

      .ddp-step-contents {
        border-bottom: none;
      }
    }

    &.ddp-selected .ddp-step-contents {
      display: block;
      overflow: auto;
    }
  }
}

.ddp-step-contents .ddp-list-set li {
  > a {
    display: block;
    position: relative;
    padding: 12px 10px 12px 10px;

    &:hover {
      background-color: #e7e7ea;
      font-weight: Bold;
    }
  }

  .ddp-sub-list {
    display: none;
  }

  &.ddp-selected .ddp-sub-list {
    display: block;
  }
}

.ddp-ui-set-info {
  padding: 0 25px;

  .ddp-ui-title {
    padding: 22px 0;
    border-bottom: 1px solid #f6f6f7;
    color: #35393f;
    font-size: 14px;
    font-weight: bold;

    .ddp-data-ver {
      display: block;
      color: #35393f;
      font-size: 12px;
      font-weight: normal;
    }
  }
}

ul.ddp-list-setinfo li {
  .ddp-form-setting {
    position: relative;
    padding: 0 0 0 43px;
    overflow: auto;

    .ddp-clear {
      margin: 0 -5px;
    }

    [class*='ddp-col-'] {
      padding: 0 5px;
    }

    .ddp-txt-title {
      padding: 7px 0;
      color: #35393f;
      font-size: 13px;
    }

    .ddp-btn-icons {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -7px;
      width: 68px;

      [class*='ddp-icon'] {
        margin-left: 10px;
        float: Left;
      }
    }

    .ddp-icon-refresh {
      display: inline-block;
      width: 16px;
      height: 14px;
      background: url(/assets/bi/images/btn_refresh.png) no-repeat;
      background-position: left -48px;
    }

    .ddp-icon-close {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: -15px -131px;
    }
  }

  .ddp-label-info {
    position: relative;
    padding: 10px 0 10px 43px;
    border-bottom: 1px solid #e7e7ea;
    cursor: pointer;

    em.ddp-icon-view {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 18px;
      margin-top: -2px;
      width: 4px;
      height: 7px;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -102px;
    }
  }

  &.ddp-selected .ddp-label-info em.ddp-icon-view {
    width: 7px;
    height: 4px;
    background-position: left -97px;
  }

  .ddp-btn-setinfo {
    padding: 12px 0;
    text-align: right;

    .ddp-btn-add {
      display: inline-block;
      padding: 7px 14px;
      margin-right: 68px;
      border-radius: 2px;
      color: #fff;
      font-size: 13px;
      background-color: #90969f;

      &:hover {
        background-color: #4c515a;
      }

      .ddp-icon-plus {
        display: inline-block;
        margin-right: 6px;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -42px -22px;
      }
    }
  }
}

.ddp-setinfo-contents {
  display: none;
}

ul.ddp-list-setinfo li {
  &.ddp-selected .ddp-setinfo-contents {
    display: block;
  }

  .ddp-setinfo-contents {
    display: none;
    background-color: #f6f6f7;
    border-bottom: 1px solid #e7e7ea;

    span.ddp-label-title {
      display: block;
      padding: 12px 0;
      color: #35393f;
      font-size: 13px;
    }

    .ddp-ui-setinfo {
      position: Relative;
      padding: 6px 68px 6px 0;

      .ddp-txt-title {
        color: #90969f;
        font-size: 13px;
      }
    }
  }
}

/**********************************************************************************
	sso _ loading
**********************************************************************************/

.ddp-wrap-login {
  &.sso-loading {
    min-height: 500px;
  }

  .ddp-blur {
    display: block;
    background: none !important;
    background-size: cover;
    overflow: hidden;
    filter: blur(5px);
    position: absolute;
    height: 500px;
    width: 500px;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -250px;
  }
}

/* .ddp-wrap-login .ddp-blur {display:block;background:url(/assets/bi/images/login.jpg) no-repeat center center fixed;; background-size:cover; overflow:hidden; */

.ddp-sso-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -250px 0 0 -250px;
  width: 500px;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;

  .ddp-wrap-logo {
    padding: 110px 0 60px 0;
    text-align: center;
  }

  em.ddp-icon-logo {
    display: inline-block;
    width: 278px;
    height: 35px;

    .st0 {
      fill: #1a274b;
    }

    .st1 {
      fill: #272950;
    }

    .st2 {
      fill: #47dbf4;
    }

    .st3 {
      fill: #7575ea;
    }
  }
}

/* .ddp-sso-loading em.ddp-icon-logo {display:inline-block; width:278px; height:35px; background:url(/assets/bi/images/logo_login.png) no-repeat;} */

.ddp-icon-loading {
  display: block;
  padding-bottom: 35px;
  text-align: center;

  .lds-wedges {
    display: inline-block;
    position: relative;
    top: 50px;
    left: 50px;
  }
}

.ddp-sso-loading {
  .ddp-txt-welcome {
    padding-bottom: 10px;
    color: #272950;
    font-size: 22px;
    text-align: center;
  }

  .ddp-txt-message {
    color: #272950;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }
}

/**********************************************************************************
	메타데이터
**********************************************************************************/

.ddp-ui-meta {
  width: 666px;
  margin: 0 auto;
  text-align: left;
}

.ddp-wrap-create {
  position: relative;

  .ddp-list-option {
    position: absolute;
    top: -7px;
    right: 0;

    .ddp-btn-line-s {
      font-size: 12px;
      float: left;
      margin-left: 4px;
    }
  }

  .ddp-label-title {
    display: block;
    padding-bottom: 25px;
    color: #4c515a;
    font-weight: bold;
    font-size: 14px;
  }
}

.ddp-ui-meta {
  .ddp-wrap-edit3.ddp-type .ddp-label-type {
    width: 160px;
  }

  &.ddp-dictionary .ddp-wrap-edit3.ddp-type .ddp-label-type {
    width: 200px;
  }
}

.ddp-wrap-datadetail.ddp-meta .ddp-txt-edit.ddp-selected .ddp-wrap-input {
  min-width: 505px;
}

table {
  .ddp-type-multy {
    margin: 0 -3px;
    width: 505px;

    [class*='ddp-col-'] {
      padding: 0 3px;
    }
  }

  &.ddp-table-detail .ddp-box-slider {
    width: 505px;

    .ddp-checkbox-slide.ddp-checkbox-automatic2 {
      padding-left: 30px;
      margin-bottom: 10px;
    }
  }

  &.ddp-table-form span.ddp-box-tag-value {
    display: inline-block;
    min-width: 80px;
    padding: 2px 3px 1px 3px;
    border-radius: 1px;
    font-size: 12px;
    box-sizing: border-box;
    text-align: center;

    &.ddp-dimension {
      color: #439fe5;
      border: 1px solid #439fe5;
    }

    &.ddp-measure {
      color: #5fd7a5;
      border: 1px solid #5fd7a5;
    }
  }

  &.ddp-table-type2 tbody tr td {
    .ddp-pop-select {
      .ddp-table-pop tr {
        th {
          text-align: left;
          border-bottom: none;
        }

        td,
        &:nth-child(odd) td {
          background: none !important;
        }
      }

      .ddp-txt-link {
        font-weight: bold;
      }

      &.ddp-selected .ddp-txt-link {
        text-decoration: underline;

        &.type-underline-none {
          text-decoration: none;
        }
      }

      .ddp-box-layout4.type-dictionary {
        width: 300px;
        overflow: auto;
        white-space: normal;

        .ddp-data-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .ddp-box-hover {
      display: inline-block;

      a {
        display: inline-block;
        position: relative;
        color: #4b525b;
        font-size: 12px;
        text-decoration: underline;
        font-style: italic;
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        right: 0;
        top: 30px;
      }

      &:hover .ddp-box-layout4 {
        display: block;
      }
    }

    .ddp-box-select {
      a {
        display: inline-block;
        position: relative;
        color: #4b525b;
        font-size: 12px;
        text-decoration: underline;
        font-style: italic;
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        right: 0;
        top: 30px;
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }

      .ddp-box-layout4 .ddp-btn-close {
        display: inline-block;
        position: absolute;
        top: 17px;
        right: 21px;
        width: 11px;
        height: 11px;
        background: url(/assets/bi/images/btn_close.png) no-repeat;
        background-position: -12px -58px;
      }
    }

    table.ddp-table-code tr td {
      background: none;
      cursor: default;
    }
  }
}

.ddp-box-code {
  position: relative;
  padding: 16px 20px;
  background-color: #f6f6f7;

  .ddp-list-label {
    padding-right: 23px;

    [class*='ddp-col-'] {
      display: block;
      padding: 0 9px 9px 9px;
      color: #4c515a;
      font-size: 13px;
    }
  }
}

.ddp-list-code {
  position: relative;
  margin: 0 -3px 9px -3px;
  padding: 0 24px 0 0;

  [class*='ddp-col-'] {
    padding: 0 3px;
  }

  .ddp-icon-close {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 0;
    width: 14px;
    height: 14px;
    background: url(/assets/bi/images/btn_sclose.png) no-repeat;
    background-position: -15px -131px;
  }

  .ddp-data-error {
    display: block;
    padding: 0 3px;
    color: #e70000;
    font-size: 12px;
    font-style: italic;

    &:before {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 13px;
      height: 13px;
      vertical-align: middle;
      background: url(/assets/bi/images/icon_info.png) no-repeat;
      background-position: -28px top;
      content: '';
    }
  }

  .ddp-txt-label {
    display: block;
    padding: 7px 9px;
    color: #90969f;
    font-size: 13px;
  }

  .ddp-ui-radio.ddp-select-radio {
    position: absolute;
    top: 50%;
    left: -20px;
    margin-top: -6px;
  }

  &.ddp-btn-multy {
    padding-right: 133px;

    .ddp-btn-gray {
      position: absolute;
      top: 0;
      right: 27px;
      width: 100px;
      padding: 7px;
      text-align: center;
      box-sizing: border-box;
    }

    .ddp-input-typebasic.ddp-permission {
      width: 100%;
      margin-right: -107px;
    }
  }

  .ddp-input-value {
    margin-right: -108px;
  }
}

.ddp-box-code .ddp-btn-add {
  display: inline-block;
  padding: 7px 14px;
  margin-right: 68px;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
  background-color: #b7b9c2;

  .ddp-icon-plus {
    display: inline-block;
    margin-right: 6px;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: -42px -22px;
  }

  &:hover {
    background-color: #90969f;
  }
}

.ddp-wrap-codetable {
  position: relative;
  width: 666px;

  .ddp-box-code {
    margin-bottom: 10px;
  }

  .ddp-txt-right .ddp-btn-pop {
    margin-left: 4px;
  }

  .ddp-list-option {
    position: absolute;
    top: -7px;
    right: 0;

    .ddp-btn-line-s {
      font-size: 12px;
      float: left;
      margin-left: 4px;
    }
  }

  .ddp-code-btn {
    text-align: right;
  }
}

/**********************************************************************************
	메타데이터 (my favorite)
**********************************************************************************/

.ddp-wrap-list-create {
  padding: 31px 0;
  margin: -5px;

  .ddp-box-create {
    float: left;
    width: 420px;
    padding: 20px;
    margin: 5px 5px;
    border-radius: 2px;
    box-shadow: 0 2px 6px 2px rgba(102, 110, 178, 0.15);
    background-color: #fff;
    cursor: pointer;

    .ddp-data-top {
      margin-bottom: 16px;
      overflow: hidden;

      .ddp-data-photo {
        float: left;
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 9px;
        border-radius: 50%;
        overflow: hidden;
        background: url(/assets/bi/images/img_profile.png) no-repeat;
        background-position: left -82px;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .ddp-data-name {
        display: block;
        position: relative;
        top: 16px;
        overflow: hidden;
        font-size: 16px;
        color: #4e5368;

        .ddp-icon-control-fav {
          position: relative;
          top: 1px;
        }

        .ddp-txt-name {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
        }

        .ddp-name-in {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .ddp-data-contents {
      .ddp-data-label {
        margin-bottom: 7px;
        color: #4e5368;
        font-size: 12px;
        font-weight: bold;

        .ddp-data-num {
          color: #90969f;
        }
      }

      .ddp-box-list {
        padding: 10px;
        height: 132px;
        background-color: rgba(236, 238, 246, 0.3);
        box-sizing: border-box;

        .ddp-list-data {
        }
      }
    }
  }
}

.ddp-list-creator {
  margin: -3px 0;

  li {
    padding: 4px 0;

    .ddp-box-tag-value {
      float: left;
      width: 63px;
      margin-right: 4px;
      text-align: center;
      box-sizing: border-box;
    }

    .ddp-txt-ellipsis {
      .ddp-data-name {
        display: inline-block;
        max-width: 100%;
        color: #4e5368;
        font-size: 12px;
        overflow: Hidden;

        .ddp-name-in {
          display: block;
          overflow: Hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .ddp-data-date {
        margin-left: 3px;
        color: #90969f;
        font-size: 12px;
      }
    }
  }
}

.ddp-wrap-data-list {
  .ddp-type-top-option {
    padding: 10px 0;
  }

  .ddp-box-list {
    padding: 0 20px 31px 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 2px rgba(102, 110, 178, 0.15);

    .ddp-data-total {
      padding: 16px 0 18px 0;
      color: #4b515b;
      font-size: 13px;
    }

    .ddp-no-data {
      padding: 42px 0;
      color: #b5b9c2;
      border-top: 1px solid #e7e7ea;
      text-align: center;
    }
  }
}

/**********************************************************************************
	메타데이터 (Admin)
**********************************************************************************/

.ddp-wrap-flow2.type-admin {
  top: 219px;

  .ddp-box-chart {
    top: 0;
  }

  .ddp-box-flowdetail .ddp-box-in {
    bottom: 0;
  }
}

.ddp-admin-detail {
  padding: 17px 50px 0 50px;

  .ddp-wrap-tab {
    margin-bottom: 0;
  }
}

.ddp-flow-option {
  margin: 9px 50px;
  padding: 6px 0;
  background-color: #f6f6f7;

  .ddp-wrap-edit {
    float: left;
    margin-left: 15px;

    + .ddp-wrap-edit {
      margin-left: 20px;
    }

    .ddp-type-selectbox {
      width: 125px;
      background-color: #fff;
    }

    .ddp-ui-edit-option.ddp-inline .ddp-label-radio {
      margin-right: 10px;
    }
  }

  .ddp-fright {
    padding-right: 15px;
  }

  .ddp-box-btn2,
  .ddp-list-tab-button {
    float: left;
  }

  .ddp-box-btn2 {
    position: relative;
    top: 8px;
    padding-right: 12px;
    margin-right: 12px;
    color: #4b515b;

    &:before {
      display: inline-block;
      position: absolute;
      top: -3px;
      right: 0;
      height: 20px;
      border-left: 1px solid #e7e7ea;
      content: '';
    }

    .ddp-icon-widget-gridsave {
      margin-right: 2px;
      vertical-align: middle;
    }
  }

  .ddp-list-tab-button {
    position: relative;
    top: 3px;
    margin: 0;
  }
}

ul.ddp-list-tab-button li {
  a {
    .ddp-icon-lineage-depth {
      display: inline-block;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      background: url(/assets/bi/images/icon_lineage_grid.png) no-repeat;
    }

    .ddp-icon-lineage-grid {
      display: inline-block;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -7px 0 0 -7px;
      background: url(/assets/bi/images/icon_lineage_grid.png) no-repeat;
      background-position: left -14px;
    }
  }

  &.ddp-selected a {
    .ddp-icon-lineage-depth,
    .ddp-icon-lineage-grid {
      background-position-x: -14px;
    }
  }
}

.ddp-wrap-flow2.type-admin .ddp-box-chart .ddp-wrap-grid {
  position: relative;
  padding: 0 8px;
  overflow: hidden;

  .ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 32px;
    border-radius: 4px;
    border: 2px solid #4b515b;
    background-color: #f6f6f7;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -7px;
      width: 14px;
      height: 12px;
      background: url(/assets/bi/images/icon_move.png) no-repeat;
      background-position: left -28px;
      content: '';
    }
  }
}

.ddp-chart-grid {
  float: left;
  width: 50%;
  border-radius: 2px;
  border: 1px solid #d3d3da;
  box-sizing: Border-box;

  .ddp-top-title {
    padding: 7px 0;
    background-color: #90969f;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;

    &.type-to {
      background-color: #4b515b;
    }
  }

  table.ddp-table-basic tr {
    th {
      padding: 8px 0 8px 20px;
      color: #90969f;
      font-size: 12px;
      text-align: left;
      font-weight: normal;
      border-bottom: 1px solid #d0d1d8;
    }

    td {
      padding: 8px 0 8px 20px;
      color: #4b515b;
    }

    &:nth-child(odd) td {
      background-color: #f7f7f8;
    }
  }
}

/**********************************************************************************
	메타데이터 (Admin_lineage)
**********************************************************************************/

.ddp-tag-type {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  border-radius: 2px;
  padding: 1px;
  color: #fff;
  font-size: 10px;
  line-height: 16px;

  &.type-column {
    background-color: #93a3ba;
    opacity: 0.8;
  }

  &.type-metadata {
    background-color: #566b8a;
  }
}

.ddp-wrap-grid.type-information {
  position: relative;
  padding-bottom: 30px;

  .ddp-icon-view {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 26px;
    border: 2px solid #4b515b;
    border-radius: 4px;
    background-color: #f6f6f7;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -7px;
      width: 14px;
      height: 12px;
      background: url(/assets/bi/images/icon_move.png) no-repeat;
      background-position: left -28px;
      content: '';
    }
  }

  .ddp-chart-grid {
    border: none;

    .ddp-top-title {
      padding: 5px 0;
      font-size: 13px;
      font-weight: normal;
    }

    .ddp-wrap-edit3 {
      margin-top: 8px;
    }

    .ddp-view-edit {
      padding: 0 16px 0 17px;
      margin-top: 22px;
    }
  }
}

.ddp-type-contents-in .ddp-box-edit {
  clear: both;
  padding: 20px;
  background-color: #f6f6f7;

  .ddp-txt-title {
    display: block;
    padding-bottom: 10px;
    color: #4b515b;
    font-size: 13px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 90px;
    padding: 17px 10px;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #d0d1d8;
    box-sizing: border-box;
  }
}

.ddp-box-popupcontents.type-file {
  padding-bottom: 40px;
  box-sizing: border-box;

  .ddp-box-synch {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.ddp-box-synch a.ddp-link {
  float: right;
  font-size: 12px;
  text-decoration: underline;
  color: rgba(75, 81, 91, 0.8);

  em.ddp-icon-download2 {
    margin-right: 4px;
  }
}

.ddp-box-popupcontents.ddp-wrap-file-create2 {
  width: 1192px;
}

.ddp-wrap-variable {
  &.type-total {
    padding-top: 22px !important;
    position: relative;

    .ddp-txt-total {
      position: absolute;
      top: 0;
      left: 0;
      color: #4b515b;
      font-size: 12px;
    }
  }

  &.type-vertical .ddp-wrap-sheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ddp-txt-result {
      display: flex;
      color: #90969f;
      font-size: 18px;
    }

    .ddp-btn-selection {
      margin-top: 15px;

      .ddp-icon-download2 {
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .ddp-txt-result.type-error {
      color: #dc494f;
    }
  }

  .ddp-grid-detail {
    border: 1px solid #d0d1d8;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    .ddp-table-form {
      white-space: nowrap;
    }
  }
}

.ddp-type-top-option .ddp-type-search.type-edit-search {
  width: 240px;
  position: relative;
}

.ddp-configure-schema .ddp-flex {
  .ddp-type-top-option {
    padding-bottom: 5px;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
  box-sizing: border-box;

  .ddp-data-details.ddp-ui-preview-contents {
    position: relative;
    flex: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.ddp-ui-preview-contents table.ddp-table-form.ddp-table-details.ddp-cursor tbody tr {
  td .ddp-btn-control {
    display: none;
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -10px;
  }

  &:hover td {
    background-color: #f6f6f7;

    .ddp-btn-control {
      display: block;
    }
  }

  &.ddp-add {
    td {
      background-color: #f2f1f8;
      border-bottom: 1px solid #dddddd;

      &.ddp-selected {
        border-bottom: 1px solid #9ca2cc;
      }
    }

    .ddp-btn-control {
      display: block;
    }
  }

  td.ddp-input-form:hover {
    background: #f2f1f8 !important;
  }
}

.ddp-create-option {
  padding: 12px 0;

  .ddp-radio-inline .ddp-label-radio {
    margin-left: 20px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  .ddp-type-info {
    color: #e9b017;
    font-size: 12px;

    .ddp-icon-info {
      position: relative;
      top: 2px;
      float: left;
      width: 13px;
      height: 12px;
      margin-right: 4px;
      background: url(/assets/bi/images/icon_info3.png) no-repeat left -67px;
      vertical-align: middle;
    }
  }
}

table.ddp-table-type2 tbody tr > td {
  .ddp-icon-success-r,
  .ddp-icon-warning-r,
  .ddp-icon-block-r {
    display: inline-block;
    background: url(/assets/bi/images/icon_confidence.png) no-repeat;
    vertical-align: middle;
  }

  .ddp-icon-success-r {
    width: 20px;
    height: 20px;
  }

  .ddp-icon-warning-r {
    width: 20px;
    height: 18px;
    background-position: left -21px;
  }

  .ddp-icon-block-r {
    width: 20px;
    height: 20px;
    background-position: left -57px;
  }

  .ddp-wrap-catalogs2 {
    .ddp-ui-catalogs2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      float: inherit;
    }
  }
}

/**********************************************************************************
        메타데이터 (01meta data)    **********************************************************************************/

.ddp-wrap-meatadata {
  width: 600px;
  margin: 0 auto 0 auto;
  text-align: left;

  .ddp-type-data {
    width: 390px;
  }

  .ddp-clear {
    [class*='ddp-col-'] {
      padding-left: 8px;
      padding-right: 8px;
    }

    margin: 0 -8px;
  }
}

.ddp-view-catalog.ddp-close {
  .ddp-ui-contents-list {
    padding-left: 56px !important;
  }

  .ddp-wrap-catalogs {
    width: 26px;

    * {
      display: none;
    }

    .ddp-btn-folding {
      display: block;
      right: 6px;

      .ddp-ui-tooltip-info {
        left: 100%;
        right: initial;
        top: -12px;
        margin-left: 9px;
        white-space: nowrap;

        em.ddp-icon-view-top {
          left: 0;
          right: initial;
          top: 9px;
          transform: rotate(0);
        }
      }
    }
  }
}

.ddp-layout-meta .ddp-view-catalog.ddp-close .ddp-ui-contents-list.ddp-type {
  padding-left: 0;
}

.ddp-ui-meta-option {
  width: 436px;
  white-space: nowrap;
}

.ddp-ui-db-option2.ddp-meta {
  padding: 0 0 28px 0;

  &:before {
    display: block;
    padding: 30px 0 0 0;
    margin-bottom: 38px;
    content: '';
    border-bottom: 1px dotted #ccc;
  }

  .ddp-input-typebasic.ddp-type {
    width: 100%;
  }
}

.ddp-ui-meta-option .ddp-ui-buttons {
  padding-top: 20px;
  clear: both;

  .ddp-ui-info {
    display: inline-block;
    margin-left: 12px;
  }
}

.ddp-ui-contents-list.ddp-type {
  padding-left: 209px;
}

.ddp-wrap-catalogs {
  position: fixed;
  left: 0;
  top: 167px;
  bottom: 0;
  width: 272px;
  background-color: #fff;
  z-index: 1;

  .ddp-type-label {
    padding: 47px 24px 10px 24px;
    color: #4b515b;
    font-size: 16px;
  }

  .ddp-btn-folding {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -7px;

    &:before {
      display: inline-block;
      width: 14px;
      height: 11px;
      background: url(/assets/bi/images/icon_folding2.png) no-repeat;
      background-position: -30px top;
      content: '';
    }
  }
}

.ddp-view-catalog.ddp-close .ddp-wrap-catalogs .ddp-btn-folding:before {
  background-position-y: -14px;
}

.ddp-wrap-catalogs .ddp-btn-folding {
  &:hover:before {
    background-position-x: -45px;
  }

  .ddp-ui-tooltip-info.ddp-down {
    right: inherit;
    left: -10px;

    .ddp-icon-view-top {
      left: 16px;
      right: inherit;
    }
  }
}

.ddp-ui-catalog-contents {
  position: absolute;
  top: 114px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  &.ddp-type {
    top: 41px;

    .ddp-ui-favorite {
      position: relative;
      border-top: 1px solid #e7e7ea;
    }
  }
}

.ddp-ui-favorite {
  border-top: 4px solid #f0eff7;
}

.type-dropdown-data {
  .ddp-label-down {
    position: relative;
    padding: 14px 20px;
    color: #4b515b;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    .ddp-data-nodata {
      float: right;
      padding-right: 22px;
      color: #b5b9c2;
      font-size: 12px;
      font-weight: normal;
    }

    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 19px;
      margin-top: -4px;
      width: 13px;
      height: 8px;
      background: url(/assets/bi/images/icon_arrow.png) no-repeat;
      content: '';
      transform: rotate(180deg);
    }
  }

  &.ddp-selected .ddp-label-down {
    &:after {
      transform: rotate(0);
    }

    color: $primary;
    font-weight: bold;

    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 4px solid $primary;
      content: '';
    }
  }

  .ddp-down-contents {
    display: none;
  }

  &.ddp-selected .ddp-down-contents {
    display: block;
  }
}

.ddp-list-favorite {
  padding-bottom: 16px;

  li a {
    position: relative;
    display: block;
    padding: 10px 10px 10px 43px;

    &:hover {
      background-color: #f3f4f9;
    }

    [class*='ddp-icon-folder'] {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 18px;
      margin-top: -6px;
    }

    .ddp-icon-folder-type {
      width: 13px;
      height: 12px;
      background: url(/assets/bi/images/icon_folder.png) no-repeat;
      background-position: -16px top;
    }

    .ddp-data-txt {
    }

    .ddp-data-navi {
      display: block;
      color: #adb0ba;
      font-size: 11px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ddp-data-name {
      display: block;
      margin-top: 2px;
      font-size: 13px;
      color: #424751;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .ddp-data-num {
        color: #90969f;
        font-size: 13px;
      }
    }
  }
}

.ddp-wrap-catalogs .ddp-ui-tags {
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding-top: 10px;
}

ul.ddp-list-tags li {
  a {
    display: block;
    padding: 10px 16px;

    .ddp-icon-tag {
      float: left;
      position: relative;
      top: 2px;
      margin-right: 4px;
      width: 12px;
      height: 12px;
      background: url(/assets/bi/images/icon_tag.png) no-repeat;
    }
  }

  .ddp-txt-data {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .ddp-data-num {
      float: right;
      margin-left: 4px;
      color: #c2c3cb;
    }

    .ddp-data-name {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: Hidden;

      .ddp-txt-search {
        color: #666db7;
      }
    }
  }

  &.ddp-selected a {
    background-color: #f3f4f9;
  }
}

.ddp-ui-tree {
  position: relative;
  border-top: 1px solid #e7e7ea;

  ul.ddp-list-tree {
    > li em.ddp-view {
    }

    li {
      a {
        display: block;
        position: relative;
        min-height: 36px;
        padding: 10px 0 10px 61px;
        font-size: 13px;
        color: #4b515b;
        white-space: nowrap;
        box-sizing: border-box;
      }

      ul li a:before {
        display: inline-block;
        position: absolute;
        top: -17px;
        left: 0;
        width: 18px;
        height: 39px;
        background: url(/assets/bi/images/icon_tree_line.png) no-repeat left bottom;
        content: '';
      }

      a .ddp-txt-link {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          text-decoration: underline;
        }

        .ddp-data-num {
          color: #90969f;
        }
      }

      &.ddp-selected > a > .ddp-txt-link {
        font-weight: bold;
        text-decoration: underline;
      }

      .ddp-view {
        display: none;
        position: absolute;
        top: 0;
        left: 16px;
        bottom: 0;
        width: 18px;
        cursor: pointer;
        z-index: 1;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 7px;
          margin-top: -2px;
          width: 7px;
          height: 3px;
          background: url('/assets/bi/images/icon_select.png') no-repeat;
          background-position: -24px top;
          content: '';
          transform: rotate(-90deg);
          cursor: pointer;
        }
      }

      a {
        &.ddp-depth .ddp-view {
          display: block;
        }

        .ddp-icon-folder {
          position: absolute;
          top: 50%;
          left: 32px;
          margin-top: -11px;
          background-color: #fff;
          border: 4px solid #fff;
          z-index: 1;
        }

        .ddp-icon-question {
          display: inline-block;
          width: 7px;
          height: 11px;
          position: absolute;
          top: 50%;
          left: 41px;
          margin-top: -6px;
          background: url(/assets/bi/images/icon_que.png) no-repeat;
          background-position: left -41px;
        }
      }

      &.ddp-unclass a {
        .ddp-icon-question {
          background-position-x: -9px;
        }

        .ddp-txt-link {
          color: #b4b9c3;
        }
      }

      > ul {
        display: none;
        margin-left: 43px;
      }

      &.ddp-open > {
        ul {
          display: block;
        }

        a {
          font-weight: bold;
        }
      }

      a em.ddp-icon-view {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 36px;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -4px;
          width: 5px;
          height: 8px;
          background: url(/assets/bi/images/icon_tree_line.png) no-repeat right bottom;
          content: '';
        }
      }

      &.ddp-open > a em.ddp-icon-view:before {
        width: 8px;
        height: 5px;
        margin-top: -3px;
        right: -2px;
        background-position: -27px top;
      }

      > a.ddp-depth .ddp-view:hover:before {
        background-position: -40px 0;
      }
    }
  }
}

table.ddp-table-form tr td em.ddp-icon-fav {
  display: inline-block;
  width: 13px;
  height: 12px;
  background: url(/assets/bi/images/icon_fav.png) no-repeat;
  cursor: pointer;
  background-position: 0 -31px;

  &:hover {
    background-position: -26px -31px;
  }

  &.ddp-selected {
    background-position: -14px -31px;
  }
}

.ddp-td-tags {
}

table {
  &.ddp-table-form tr {
    td {
      .ddp-tags {
        display: inline-block;
        max-width: 110px;
        padding: 3px 6px;
        height: 22px;
        border-radius: 3px;
        background-color: #f2f1f8;
        color: $primary;
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        vertical-align: middle;
        box-sizing: border-box;

        .ddp-txt-search.type-search {
          position: relative;
          top: 1px;
        }
      }

      .ddp-ui-popularity {
        position: relative;
        top: 2px;
      }

      .ddp-wrap-tags {
        margin: -2px 0 0 0;
      }

      .ddp-tag-more {
        color: $primary;
        vertical-align: middle;
      }

      .ddp-tags:last-of-type {
        margin-right: 0;
      }

      .ddp-bar {
        width: 70px;
        height: 10px;
        background-color: #e6e8f3;
        border-radius: 2px;

        span {
          display: inline-block;
          height: 100%;
          border-radius: 2px;
          background-color: #666db7;
        }
      }
    }

    th .ddp-box-layout4.ddp-popularity {
      top: 100%;
      right: 0;
      left: inherit;
      bottom: inherit;
      margin-top: 5px;
      width: 359px;

      ul {
        padding: 25px 0 0 0;
      }
    }
  }

  &.ddp-table-detail tr td .ddp-bar {
    position: relative;
    top: 2px;
    width: 70px;
    height: 10px;
    background-color: #e6e8f3;
    border-radius: 2px;

    span {
      display: inline-block;
      height: 100%;
      border-radius: 2px;
      background-color: #666db7;
    }
  }
}

.ddp-box-catalogs {
  display: inline-block;
  position: relative;
  padding: 5px 30px 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.ddp-ui-catalogs {
  &:first-of-type {
    margin-top: -5px;
  }

  + .ddp-ui-catalogs {
    margin-top: 5px;
  }
}

.ddp-box-catalogs {
  &.ddp-list {
    padding: 0;

    &:hover {
      background: none;
    }

    span {
      font-weight: bold;
      color: #4b515b;
    }
  }

  span {
    position: relative;
    padding: 0 12px;

    &:first-of-type {
      padding-left: 0;
    }

    &:before {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -4px;
      width: 4px;
      height: 8px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      transform: rotate(180deg);
      content: '';
    }

    &:first-of-type:before {
      display: none;
    }
  }

  &:hover {
    background-color: #f6f6f7;
  }

  a.ddp-btn-delete {
    display: none;
    position: absolute;
    top: 50%;
    right: 11px;
    margin-top: -7px;
    content: '';
    width: 14px;
    height: 14px;
    background: url(/assets/bi/images/btn_sclose.png) no-repeat;
    background-position: -15px -131px;
  }

  &:hover .ddp-btn-delete {
    display: block;
  }
}

.ddp-wrap-catalogs2 {
  margin: -7px 0 0 0;

  + .ddp-apply.type-catalog {
    margin-top: 6px !important;
  }

  .ddp-ui-catalogs2 {
    padding: 7px 0 7px 20px;
    color: #4b515b;

    .ddp-catalogs-in {
      display: inline-block;
      cursor: pointer;
    }

    .ddp-btn-delete {
      display: none;
      float: left;
      width: 14px;
      height: 14px;
      margin-left: 5px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: -15px -131px;
      vertical-align: middle;
    }

    .ddp-catalogs-in:hover .ddp-btn-delete {
      display: block;
    }

    .ddp-btn-delete:hover {
      background-position-x: -0;
    }
  }
}

.ddp-ui-catalogs2 {
  position: relative;
  overflow: hidden;
  font-weight: normal;

  .ddp-icon-folder-type {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    width: 13px;
    height: 12px;
    margin-right: 8px;
    background: url(/assets/bi/images/icon_folder.png) no-repeat;
    background-position: -16px top;
  }

  .ddp-icon-folder {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
  }

  &.ddp-unclass .ddp-icon-question2 {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    width: 7px;
    height: 11px;
    margin-right: 9px;
    background: url(/assets/bi/images/icon_que.png) no-repeat;
    background-position: -9px -41px;
  }

  span {
    float: left;
    font-size: 13px;

    + span {
      position: relative;
      padding-left: 8px;
      margin-left: 4px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
        width: 4px;
        height: 8px;
        background: url(/assets/bi/images/icon_tagview.png) no-repeat;
        background-position: left -18px;
        transform: rotate(180deg);
        content: '';
      }
    }
  }

  &.ddp-unclass {
    color: #b5b9c2;
    font-size: 13px;
  }
}

table.ddp-table-detail tr td {
  .ddp-list-tags .ddp-ui-buttons {
    display: inline-block;
    margin-top: 0;
  }

  .ddp-ui-buttons {
    margin-top: 5px;

    .ddp-btn-add {
      display: inline-block;
      padding: 5px 8px;
      border: 1px solid #d0d1d7;
      border-radius: 4px;
      color: #90969f;
      font-size: 12px;

      &:hover {
        border: 1px solid #b7b9c1;
        color: #4b515b;
      }

      em.ddp-icon-plus {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -84px -22px;
        vertical-align: middle;
      }
    }
  }

  .ddp-ui-tags {
    margin: -9px 0;
    vertical-align: top;
  }

  .ddp-tags {
    float: left;
    display: block;
    position: relative;
    max-width: 100%;
    padding: 3px 6px;
    margin: 4px 3px 4px 0;
    background-color: #f2f1f7;
    border-radius: 4px;
    color: $primary;
    font-size: 13px;
    cursor: pointer;
    box-sizing: border-box;

    span.txt-tags {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    a.ddp-btn-delete {
      display: none;
      position: absolute;
      top: -4px;
      right: -4px;
      content: '';
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: 0 -131px;
    }

    &:hover a.ddp-btn-delete {
      display: block;
    }

    &.ddp-txt {
      cursor: default;

      &:hover {
        padding: 5px 15px;

        a.ddp-btn-delete {
          display: none;
        }
      }
    }
  }

  .ddp-tag-default {
    display: inline-block;
    position: relative;
    padding: 3px 5px 3px 20px;
    margin: 3px 6px 3px 0;
    border-radius: 4px;
    color: $primary;
    font-size: 13px;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 9px;
      content: '#';
      color: $primary;
      font-size: 13px;
    }

    input {
      display: block;
      width: 100%;
      border: none;
      background: none;
      color: $primary;
      font-size: 13px;
    }
  }
}

.ddp-tag-default .ddp-ui-drop-search {
  position: absolute;
  top: 100%;
  left: 0;
  right: inherit;
}

.ddp-wrap-drop-search.ddp-tag-default .ddp-ui-drop-search:after {
  display: none;
}

.ddp-ui-option .ddp-option-column {
  padding: 0;
}

.ddp-option-column {
  padding: 20px 0;
  text-align: right;

  a.ddp-reset {
    margin-right: 20px;
    color: #90969f;

    em {
      margin-right: 2px;
      vertical-align: middle;
    }

    &:hover {
      color: #4b515b;
    }

    .ddp-icon-redo {
      display: inline-block;
      width: 14px;
      height: 13px;
      background: url(/assets/bi/images/btn_refresh.png) no-repeat;
      background-position: -15px -34px;
    }

    &:hover .ddp-icon-redo {
      background-position-x: -30px;
    }
  }
}

.ddp-wrap-datadetail.ddp-wrap-datacolumn {
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
}

table.ddp-table-form.ddp-table-details tr {
  td .ddp-bar {
    background-color: #e9edf7;

    span {
      background-color: #c1cef1;
    }
  }

  th {
    .ddp-box-layout4 {
      position: absolute;
      top: 100%;
      right: -30px;
    }

    &:nth-child(1) .ddp-box-layout4,
    &:nth-child(2) .ddp-box-layout4,
    &:nth-child(3) .ddp-box-layout4,
    &:nth-child(4) .ddp-box-layout4 {
      right: inherit;
      left: -30px;
    }
  }
}

.ddp-wrap-datadetail.ddp-wrap-datacolumn .ddp-data-details.ddp-ui-preview-contents {
  position: fixed;
  left: 45px;
  right: 45px;
  bottom: 20px;
  top: 246px;
  height: inherit;

  &.ddp-info {
    top: 265px;
  }

  &.ddp-info2 {
    top: 299px;
  }
}

.ddp-page-catalog {
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid #e7e7ea;

  .ddp-ui-root {
    padding: 8px 0;

    .ddp-btn-bg {
      float: right;
      margin-left: 14px;
    }

    .ddp-data-create {
      display: block;
      position: relative;
      padding: 3px 0 3px 0;
      overflow: hidden;

      .ddp-btn-back {
        position: relative;
        top: -5px;
        float: left;
        width: 34px;
        height: 34px;

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 11px;
          width: 17px;
          height: 16px;
          margin-top: -8px;
          background: url(/assets/bi/images/btn_back.png) no-repeat;
          background-position: left -27px;
          content: '';
        }
      }

      .ddp-data-name {
        display: block;
        padding: 3px 20px 3px 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: normal;
        box-sizing: border-box;
        color: #4b515b;
        font-size: 14px;
      }

      .ddp-data-input {
        position: absolute;
        top: 4px;
        left: 37px;
        right: 0;
        background: #f6f6f7;

        input {
          display: block;
          width: 100%;
          padding: 3px 20px 3px 6px;
          border: none;
          box-sizing: border-box;
          background: none;
          color: #4b515b;
          font-size: 14px;
        }

        em.ddp-icon-control-check {
          position: absolute;
          top: 50%;
          right: 5px;
          margin-top: -7px;
        }
      }
    }
  }

  .ddp-select-catalog {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    bottom: 50px;
  }

  .ddp-wrap-list-type {
    padding: 0;
  }

  ul.ddp-list-workspace li a {
    padding-left: 89px;

    em.ddp-icon-folder {
      left: 33px;
    }

    .ddp-box-tag-value {
      left: 10px;
    }

    .ddp-data-input {
      left: 80px;
    }

    em {
      &.ddp-icon-file-s {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 36px;
        margin-top: -8px;
        width: 11px;
        height: 13px;
        background: url(/assets/bi/images/icon_file2.png) no-repeat;
      }

      &.ddp-icon-question2 {
        display: inline-block;
        width: 7px;
        height: 11px;
        position: absolute;
        top: 50%;
        left: 35px;
        margin-top: -6px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: left -41px;
      }
    }
  }
}

.ddp-select-catalog {
}

.ddp-page-catalog .ddp-nodata {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 250px;
  text-align: Center;
  color: #90969f;
  font-size: 18px;
}

.ddp-select-catalog ul.ddp-list-workspace li.ddp-sub a {
  &:hover {
    padding-right: 84px;
  }

  .ddp-view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    cursor: pointer;
    z-index: 10;

    &:before {
      width: 6px;
      height: 11px;
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -6px;
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -68px;
      content: '';
    }
  }

  .ddp-data-input {
    right: 52px;
  }

  .ddp-btn-control {
    padding-right: 15px;
  }
}

/**********************************************************************************
	메타데이터 Explore Data
**********************************************************************************/

.ddp-layout-meta {
}

.ddp-layout-contents.ddp-layout-meta .ddp-ui-contents-in {
  padding-right: 24px;
}

.ddp-layout-meta .ddp-ui-contents-list .ddp-box-list .ddp-label-type {
  padding: 22px 0 23px 0;
  color: $primary;
  font-size: 14px;
  font-weight: bold;
}

table.ddp-table-line {
  thead tr th {
    background-color: #eff0f7;
    color: $primary;
    font-size: 12px;
  }

  tbody tr {
    &:first-of-type td {
      border-top: 1px solid #e7e7ea;
    }

    td {
      padding: 10px 10px;
      border: none;
      border-bottom: 1px solid #e7e7ea;
      background: #fff;
      cursor: pointer;
    }

    &:hover td {
      background-color: #f3f4f9 !important;
    }

    &:nth-child(odd) td {
      border: none;
      border-bottom: 1px solid #e7e7ea;
      background-color: #fff;

      &:first-of-type,
      &:last-of-type {
        border: none;
        border-bottom: 1px solid #e7e7ea;
        background-color: #fff;
      }
    }

    td .ddp-btn-control {
      display: none;
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -9px;
    }

    &:hover td .ddp-btn-control {
      display: block;
    }

    td .ddp-ui-popularity {
      .ddp-icon-popularity {
        position: relative;
        top: -2px;
        float: left;
        width: 18px;
        height: 14px;
        margin-right: 8px;
      }

      .ddp-bar {
        float: left;
      }
    }
  }
}

.ddp-layout-meta .ddp-ui-contents-top {
  height: 190px;
  text-align: center;
  background: none;
  box-shadow: none;
  border-bottom: none;

  .ddp-ui-title {
    padding: 35px 0 0 0;
    text-align: center;
    font-size: 28px;
  }

  .ddp-ui-tag {
    padding-top: 5px;
    text-align: center;
    font-weight: normal;

    .ddp-box-tag-value {
      &.ddp-disabled {
        opacity: 0.3;
      }

      + .ddp-box-tag-value {
        margin-left: 4px;
      }
    }
  }
}

.ddp-meta-search {
  position: relative;
  display: inline-block;
  margin-top: 30px;
  border: 1px solid #d0d1d9;
  border-radius: 2px;
  background-color: #fff;

  .ddp-search-select {
    position: relative;
    float: left;
    width: 185px;
    padding: 0 40px 0 15px;
    text-align: left;
    border-right: 1px solid #d0d1d9;
    cursor: pointer;
    vertical-align: top;
    box-sizing: Border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 25px;
      margin-top: -2px;
      width: 7px;
      height: 4px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -8px top;
      content: '';
    }

    span.ddp-data {
      display: block;
      padding: 15px 0 15px 0;
      color: #4b515b;
      font-size: 16px;
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 100%;
      left: -1px;
      right: -1px;
      background-color: #fff;
      box-sizing: border-box;
    }

    &.ddp-selected .ddp-wrap-popup2 {
      display: block;
    }
  }

  .ddp-search-b {
    float: left;
    position: relative;
    width: 516px;
    padding: 0 0 0 40px;
    box-sizing: border-box;

    .ddp-icon-search {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -9px;
      width: 17px;
      height: 17px;
      background: url(/assets/bi/images/btn_search.png) no-repeat;
      background-position: left -20px;
    }

    input {
      display: block;
      width: 100%;
      padding: 15px 35px 15px 0;
      font-size: 16px;
      background: none;
      box-sizing: border-box;
      border: none;
      font-family: 'Titillium Web';
    }

    &.type-search input {
      position: relative;
      color: #666db7;
      z-index: 1;
    }

    &.type-focus:before {
      display: inline-block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid #90969f;
      content: '';
    }

    .ddp-btn-delete {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -8px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/btn_close.png) no-repeat;
      background-position: -16px -132px;
      z-index: 1;
    }
  }
}

/* scroll 시 */

.ddp-layout-meta {
  &.ddp-scroll {
    .ddp-ui-contents-top {
      position: fixed;
      left: 0;
      right: 0;
      top: 55px;
      padding: 0;
      height: 80px;
      text-align: left;
      background-color: #fff;
      -webkit-transition: height 0.3s;

      /* Safari */
      transition: height 0.3s;
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

      /* drop shadow */
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

      /* drop shadow */

      .ddp-ui-title {
        float: left;
        text-align: left;
        padding: 26px 15px 5px 25px;
        font-size: 22px;
      }

      .ddp-ui-tag {
        float: left;
        padding: 33px 0;
      }
    }

    .ddp-meta-search {
      position: absolute;
      top: 15px;
      right: 24px;
      margin-top: 0;
      min-width: 380px;
    }

    .ddp-ui-contents-list.ddp-type {
      top: 95px !important;
      padding-top: 0;
    }

    .ddp-wrap-catalogs {
      top: 136px;
    }
  }

  .ddp-wrap-catalogs {
    top: 245px;
    border-top: 1px solid rgba(44, 34, 141, 0.1);
    border-right: 1px solid rgba(44, 34, 141, 0.1);
    z-index: 20;
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    /* drop shadow */

    .ddp-ui-searching {
      margin: 0;
    }

    .ddp-wrap-searching {
      padding: 10px 16px 10px 16px;
    }

    &.ddp-close {
      width: 26px;
      border: 2px solid #eaeaea;

      * {
        display: none;
      }

      .ddp-btn-folding {
        display: block;
        position: absolute;
        top: 10px;
        right: 3px;

        * {
          display: block;
        }
      }
    }

    .ddp-wrap-tabs {
      position: relative;

      &:before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #d7d8dd;
        content: '';
      }
    }

    .ddp-wrap-folding {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 41px;
      border-left: 1px solid #ddd;
    }
  }

  .ddp-view-catalog.ddp-close .ddp-wrap-catalogs .ddp-wrap-folding {
    border-left: none;
  }

  .ddp-wrap-catalogs {
    .ddp-wrap-folding .ddp-btn-folding:hover * {
      display: block;
    }

    ul.ddp-ui-tab li {
      min-width: 123px;

      a {
        padding: 16px 10px 15px 10px;
        height: 41px;
        text-align: center;
      }

      &.ddp-selected a:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        border-bottom: 1px solid #fff;
      }

      &:last-of-type a {
        border-right: none;
      }
    }
  }

  em.ddp-bg-back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(/assets/bi/images/bg_meta.png) no-repeat;
    background-size: 100% 100%;
  }
}

.ddp-layout-contents.ddp-layout-meta .ddp-ui-contents-in.ddp-scroll {
  padding-left: 0;
}

.ddp-layout-meta .ddp-view-catalog.type-view .ddp-ui-contents-list.ddp-type {
  padding-top: 129px;
}

.ddp-layout-contents.ddp-layout-meta .ddp-ui-contents-in.ddp-scroll .ddp-ui-contents-list.ddp-type {
  position: relative;
  top: 190px;
  padding: 0 0 0 296px;
  overflow: initial;
}

.ddp-layout-meta {
  .ddp-ui-contents-list.ddp-type.ddp-close {
    padding-left: 0;
  }

  .ddp-meta-list {
    .ddp-no-data {
      padding: 42px 0;
      color: #b5b9c2;
      border-top: 1px solid #e7e7ea;
      text-align: center;
    }

    .ddp-type-label {
      padding: 0 0 20px 0;
      color: #4b515b;
      font-size: 16px;

      em.ddp-icon-fav {
        display: inline-block;
        position: relative;
        top: -2px;
        width: 13px;
        height: 12px;
        background: url(/assets/bi/images/icon_fav.png) no-repeat;
        cursor: pointer;
        background-position: 0 -31px;
        vertical-align: middle;

        &.ddp-selected {
          background-position: -14px -31px;
        }
      }
    }

    .ddp-box-list {
      padding: 16px 20px;
      background-color: #fff;
      -webkit-box-shadow: 0 2px 6px 2px rgba(102, 110, 178, 0.15);
      -moz-box-shadow: 0 2px 6px 2px rgba(102, 110, 178, 0.15);
      box-shadow: 0 2px 6px 2px rgba(102, 110, 178, 0.15);

      .ddp-data-total {
        float: left;
        padding-bottom: 17px;
        color: #4b515b;

        .ddp-txt-search {
          color: $primary;
          font-weight: bold;
        }
      }

      .ddp-data-box {
        position: relative;
        top: -5px;
        padding: 4px 26px 3px 7px;
        height: 26px;
        float: right;
        background: #f3f4f9;
        border: 1px solid #e8e9f4;
        border-radius: 2px;
        vertical-align: middle;
        box-sizing: border-box;

        .ddp-icon-folder {
          position: relative;
          top: 0;
          left: 0;
          margin: 0 4px 0 0;
        }

        .ddp-ui-root {
          float: left;
        }

        .ddp-list-root {
          + .ddp-list-root {
            position: relative;
            padding: 0 0 0 12px;
            margin-left: 4px;

            &:before {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -4px;
              width: 4px;
              height: 8px;
              background: url(/assets/bi/images/icon_dataview.png) no-repeat;
              background-position: left -40px;
              content: '';
            }
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .ddp-ui-root .ddp-list-root:last-of-type {
          font-weight: bold;
        }

        .ddp-icon-control-fav {
          position: relative;
          top: -1px;
          margin-right: 3px;
          vertical-align: top;
        }

        .ddp-link-reset {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          width: 26px;
          height: 24px;
          background-color: #e9ebf3;

          .ddp-btn-reset {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -7px -7px;
            width: 14px;
            height: 13px;
            background: url(/assets/bi/images/icon_reset.png) no-repeat -16px -20px;
          }
        }

        .ddp-data-tag {
          margin-right: 6px;
          color: #4b515b;

          .ddp-icon-tag {
            display: inline-block;
            position: relative;
            top: -1px;
            width: 12px;
            height: 12px;
            margin-right: 3px;
            vertical-align: middle;
            background: url(/assets/bi/images/icon_tag.png) no-repeat;
          }
        }
      }
    }

    .ddp-box-page {
      border: none;
      -webkit-box-shadow: 0 2px 2px 1px rgba(231, 231, 234, 1);
      -moz-box-shadow: 0 2px 2px 1px rgba(231, 231, 234, 1);
      box-shadow: 0 2px 2px 1px rgba(231, 231, 234, 1);
    }
  }
}

.ddp-ui-banner {
  margin-bottom: 27px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #d2d4ed;
  background-color: #fff;

  .ddp-type-label {
    padding: 20px 20px;
    color: #4b515b;
    font-weight: bold;
    font-size: 14px;
  }

  ul.ddp-list-popular {
    padding: 0 35px 35px 35px;

    li {
      float: left;
      width: 20%;
      padding: 0 4px;
      box-sizing: border-box;

      a {
        display: block;
        position: relative;
        border-radius: 4px;
        height: 220px;
        text-align: center;
      }

      &.type-database a {
        background-color: #4478ec;

        &:hover {
          background-color: #3966c8;
        }
      }

      &.type-datasource a {
        background-color: #3d417e;

        &:hover {
          background-color: #33376b;
        }
      }

      &.type-stagingdb a {
        background-color: #664ad5;

        &:hover {
          background-color: #563eb5;
        }
      }

      &.type-dataset a {
        background-color: #48c7c4;

        &:hover {
          background-color: #3da9a6;
        }
      }

      a {
        svg {
          width: 43px;
          height: 43px;
          margin: 40px 0 0 0;
        }

        .ddp-data-name {
          margin: 18px 0 0 0;
          padding: 0 10px;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;

          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }

        .ddp-data-det {
          padding: 0 10px;
          margin-top: 5px;
          font-size: 12px;
          color: #fff;
          opacity: 0.7;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;

          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }

        .ddp-data-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 4px 10px;
          background: rgba(0, 0, 0, 0.2);

          .ddp-list-tags {
            display: block;
            margin-top: 0;
            color: #c1cef1;
            overflow: hidden;

            em.ddp-data-num {
              float: right;
              position: relative;
              top: 6px;
              margin-left: 4px;
            }

            span.ddp-tags {
              display: block;
              padding: 3px 6px 3px 6px;
              font-size: 13px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              background-color: rgba(255, 255, 255, 0.1);
              color: #fff;
            }
          }

          .ddp-ui-popularity {
            float: left;
            position: relative;
            top: 5px;
            margin-right: 5px;

            .ddp-icon-popularity {
              float: left;
              width: 18px;
              height: 14px;
              margin-right: 3px;
              background: url(/assets/bi/images/icon_popularity.png) no-repeat;
              background-position: -19px top;
            }

            .ddp-bar {
              position: relative;
              top: 3px;
              float: left;
              width: 70px;
              height: 10px;
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 2px;

              span {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 2px;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .ddp-wrap-slider {
    position: Relative;
  }

  .ddp-wrap-bullet {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;

    .ddp-ui-bullet {
      display: inline-block;
    }

    .ddp-bullet {
      display: inline-block;
      float: left;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #d0d1d8;
      margin: 0 5px;
      cursor: pointer;

      &.ddp-selected {
        position: relative;
        top: -1px;
        width: 9px;
        height: 9px;
        background-color: $primary;
      }
    }
  }

  .ddp-paging {
    .ddp-btn-prev,
    .ddp-btn-next {
      display: inline-block;
      width: 12px;
      height: 22px;
      background: url(/assets/bi/images/btn_slider2.png) no-repeat;
    }

    .ddp-btn-prev:hover,
    .ddp-btn-next:hover {
      background-position-y: -23px;
    }

    .ddp-btn-prev {
      position: absolute;
      top: 95px;
      left: 13px;
      transform: rotate(180deg);
    }

    .ddp-btn-next {
      position: absolute;
      top: 95px;
      right: 13px;
    }
  }
}

.ddp-wrap-meta-summary {
  height: 368px;
  margin: 0 -16px 30px -16px;

  .ddp-box-summary {
    float: left;
    width: 33.3333%;
    padding: 0 16px;
    box-sizing: border-box;

    .ddp-data-nodata {
      padding: 27px 0;
      text-align: center;
      color: #90969f;
      font-size: 14px;

      &:before {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 4px;
        background: url(/assets/bi/images/icon_delete.png) no-repeat;
        background-position: left -30px;
        content: '';
        vertical-align: middle;
      }
    }

    .ddp-type-label {
      border-bottom: 1px solid #e7e7ea;
      padding: 0 10px 15px 10px;
      color: #4b515b;
      font-size: 14px;
      font-weight: bold;

      .ddp-btn-more {
        float: right;
        padding: 3px 7px;
        background-color: rgba(156, 162, 204, 0.2);
        color: #4c515a;
        font-size: 12px;
        font-weight: normal;
        border-radius: 2px;

        &:hover {
          background-color: rgba(156, 162, 204, 0.4);
        }
      }
    }

    .ddp-list-summary li {
      a {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        padding: 10px 10px 10px 75px;
        box-sizing: border-box;

        .ddp-images-svg {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 50px;
          height: 50px;
          border-radius: 4px;
        }

        &:hover {
          border-radius: 4px;
          background-color: rgba(156, 162, 204, 0.15);
        }
      }

      &.type-database a .ddp-images-svg {
        background-color: #4478ec;
      }

      &.type-datasource a .ddp-images-svg {
        background-color: #3d417e;
      }

      &.type-stagingdb a .ddp-images-svg {
        background-color: #664ad5;
      }

      &.type-dataset a .ddp-images-svg {
        background-color: #48c7c4;
      }

      a {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -15px 0 0 -15px;
          width: 30px;
          height: 30px;
        }

        .ddp-ui-popularity {
          display: block;
        }

        .ddp-data-summary {
          display: block;

          .ddp-data-name {
            color: #4b515b;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .ddp-data-detail {
            color: #b5b9c2;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .ddp-ui-popularity {
            padding: 6px 0;

            .ddp-icon-popularity {
              float: left;
              width: 18px;
              height: 14px;
              margin-right: 3px;
              background: url(/assets/bi/images/icon_popularity.png) no-repeat;
            }

            .ddp-bar {
              position: relative;
              top: 3px;
              float: left;
              width: 70px;
              height: 10px;
              background-color: #e6e8f3;
              border-radius: 2px;

              span {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 2px;
                background-color: $primary;
              }

              .ddp-box-layout4 {
                display: none;
                position: absolute;
                top: 100%;
                margin-top: 5px;
                text-align: left;

                &.ddp-popularity {
                  width: 360px;
                }
              }

              &:hover .ddp-box-layout4 {
                display: block;
              }
            }

            .ddp-data-by {
              display: block;
              padding-left: 6px;
              overflow: hidden;
              color: #90969f;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .ddp-list-tags {
            margin-top: 3px;
            height: 25px;
            overflow: hidden;
            white-space: nowrap;

            span {
              &.ddp-tags {
                float: left;
                display: block;
                padding: 3px 5px;
                max-width: 90px;
                background-color: rgba(102, 110, 178, 0.1);
                color: $primary;
                word-break: break-all;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;

                &.type-nodata {
                  color: #b5b9c2;
                  background-color: rgba(144, 150, 159, 0.1);
                }
              }

              &.ddp-tags-num {
                color: $primary;
                position: relative;
                top: 6px;
              }
            }
          }
        }
      }
    }
  }
}

/*.ddp-wrap-meta-summary .ddp-box-summary .ddp-list-summary li a:hover {border-radius:4px; background-color: rgba(156, 162, 204, 0.15);}*/

.ddp-box-summary .ddp-list-data {
  padding: 5px 0;
  margin-left: -10px;

  li {
    + li {
      padding-top: 5px;
    }

    a {
      display: block;
      position: relative;
      padding: 5px 10px 5px 75px;
      min-height: 60px;
      box-sizing: border-box;

      &:hover {
        background-color: rgba(156, 162, 204, 0.15);
        border-radius: 4px;
      }

      .ddp-data-images {
        position: absolute;
        top: 5px;
        left: 10px;
      }

      .ddp-data-profile {
        display: inline-block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 5px;
        left: 10px;
        background: url(/assets/bi/images/img_profile.png) no-repeat left -82px;
        border-radius: 50%;
        overflow: hidden;
      }

      .ddp-data-txt {
        .ddp-list-tags {
          padding-top: 6px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .ddp-tags {
            padding: 3px 6px;
            margin: 0 3px 0 0;
            background-color: #e2dff5;
          }
        }

        .ddp-data-name {
          display: block;
          padding: 3px 0 5px 0;
          font-size: 14px;
          color: #4b515b;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .ddp-data-create {
          display: block;
          padding: 3px 0 5px 0;
          font-size: 12px;
          color: #90969f;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .ddp-data-by {
          display: block;
          width: 100%;
          color: #4b515b;
          opacity: 0.6;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

/*.ddp-wrap-meta-summary .ddp-wrap-box {}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-box-in {float:left; width:100%; padding-right:325px; margin-right:-325px; box-sizing:border-box;}*/

/*.ddp-wrap-meta-summary .ddp-type-summary-label {padding:15px 25px 15px 25px;font-size:14px; color:#4b515b; font-weight:bold; border-bottom:1px solid #e7e8eb;}*/

/*.ddp-wrap-meta-summary .ddp-data-images {display:inline-block; position:relative; width:50px; height:50px; border-radius:5px;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-svg:before {background:none;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-svg svg {display:inline-block; width:30px; height:30px; position:absolute; top:50%; left:50%; margin:-15px 0 0 -15px;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-database {background-color:#4478ec;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-datasource {background-color:#3d417e;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-stagingdb {background-color:#664ad5;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-dataset {background-color:#48c7c4;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-hive {background-color:#353a5c;}*/

/*.ddp-wrap-meta-summary .ddp-data-images.type-hive:before {}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-box-summary {margin-top:18px; background-color:#fff;*/

/*-moz-box-shadow: 0 0 20px rgba(206,210,237); !* drop shadow *!*/

/*-webkit-box-shadow: 0 0 20px rgba(206,210,237); !* drop shadow *!*/

/*box-shadow: 0 0 20px rgba(206,210,237); !* drop shadow *!}*/

/*.ddp-wrap-meta-summary .ddp-wrap-list-summary {float:left; padding:31px 0 0 25px; width:333px; min-width:333px; box-sizing:Border-box;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-list-summary .ddp-type-summary-label {padding:0 0 15px 0;}*/

/*.ddp-wrap-meta-summary .ddp-ui-list-summary .ddp-type-summary-label .ddp-btn-more  {float:right; padding:3px 7px; background-color:rgba(156, 162, 204, 0.2); color:#4c515a; font-size:12px; font-weight:normal; border-radius:2px;}*/

/*.ddp-wrap-meta-summary .ddp-ui-list-summary .ddp-type-summary-label .ddp-btn-more:hover {background-color:rgba(156, 162, 204, 0.4);}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-wrap-summary {padding:20px 15px 20px 15px;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary > li {width:50%; float:left; box-sizing:border-box;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li a {display:block; padding:10px 25px 10px 10px; min-height:70px; box-sizing:border-box;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li a:hover { background-color:rgba(156, 162, 204, 0.15); border-radius:4px;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-wrap-data {position:relative; padding-left:65px; }*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-wrap-data .ddp-data-images {position:absolute; top:0; left:0;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-data-txt {}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-data-txt .ddp-data-name {color:#4b515b; font-size:14px; white-space:nowrap; text-overflow:ellipsis; overflow:hidden;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-data-txt .ddp-data-detail {color:rgba(75,81,91,0.5); white-space:nowrap; text-overflow:ellipsis; overflow:hidden;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-list-tags {clear:both; width:100%; height:22px; margin-top:0; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-list-summary li .ddp-list-tags .ddp-tags {padding:3px 6px; margin:0 3px 0 0; background-color:rgba(156, 162, 204, 0.15)}*/

/*.ddp-wrap-meta-summary .ddp-wrap-box .ddp-ui-list-summary {width:300px; float:right; margin-top:18px;}*/

/*.ddp-wrap-meta-summary .ddp-ui-list-summary .ddp-type-summary-label {padding:15px 0 15px 0;}*/

/*.ddp-ui-list-summary {margin-top:18px;}*/

/*.ddp-ui-list-summary .ddp-list-data {padding:5px 0; margin-left:-10px;}*/

/*.ddp-ui-list-summary .ddp-list-data li + li {padding-top:5px;}*/

/*.ddp-ui-list-summary .ddp-list-data li a {display:block; position:relative;padding:5px 10px 5px 75px; min-height:60px; box-sizing:border-box;}*/

/*.ddp-ui-list-summary .ddp-list-data li a:hover {background-color:rgba(156, 162, 204, 0.15); border-radius:4px;}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-images {position:absolute; top:5px; left:10px;}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-profile {display:inline-block; width:50px; height:50px; position:absolute; top:5px; left:10px; background:url(/assets/bi/images/img_profile.png) no-repeat left -82px; border-radius:50%; overflow:hidden; }*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-txt .ddp-list-tags {padding-top:6px; white-space:nowrap; text-overflow:ellipsis; overflow:hidden;}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-txt .ddp-list-tags .ddp-tags {padding:3px 6px; margin:0 3px 0 0; background-color:#e2dff5;}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-txt .ddp-data-name {display:block;padding:3px 0 5px 0; font-size:14px; color:#4b515b; white-space:nowrap;text-overflow:ellipsis; overflow:hidden}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-txt .ddp-data-create {display:block;padding:3px 0 5px 0; font-size:12px; color:#90969f; white-space:nowrap;text-overflow:ellipsis; overflow:hidden}*/

/*.ddp-ui-list-summary .ddp-list-data li a .ddp-data-txt .ddp-data-by {display:block; width:100%;color:#4b515b; opacity:0.6; white-space:nowrap;text-overflow:ellipsis; overflow:hidden;}*/

/**************************************************************
  메타데이터 used workspace popup
**************************************************************/

.ddp-used-workspace {
  padding: 0 50px;
}

.ddp-ui-usedworkspace {
  &:last-of-type {
    border-bottom: 1px solid #e4e5eb;
  }

  .ddp-data-name {
    display: block;
    padding: 10px 17px;
    background-color: #f6f6f7;
    border-top: 1px solid #e4e5eb;
    color: #35393f;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .ddp-ui-sub-usedwork {
    display: none;
    padding-bottom: 10px;
    border-top: 1px solid #e4e5eb;
  }

  &.ddp-selected .ddp-ui-sub-usedwork {
    display: block;
  }
}

.ddp-ui-sub-usedwork .ddp-list-tree {
  + .ddp-list-tree {
    padding: 20px 0 0 0;
  }

  li {
    a {
      display: block;
      position: relative;
      padding: 12px 0 12px 45px;
      color: #4b515b;
      font-size: 13px;

      &:hover {
        font-weight: bold;
        text-decoration: underline;
      }

      em[class*='ddp-icon-'] {
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -7px;
      }
    }

    ul {
      li a:before {
        position: absolute;
        top: 0;
        left: 0;
        background: url(/assets/bi/images/img_tree_line.png) no-repeat;
        width: 13px;
        height: 21px;
        content: '';
      }

      padding-left: 20px;
    }
  }
}

/**************************************************************
  메타데이터 세일즈데이터
**************************************************************/

.ddp-pop-meta-detail {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(208, 209, 216, 0.5);
}

.ddp-box-meta {
  position: absolute;
  top: 30px;
  left: 40px;
  right: 40px;
  bottom: 30px;
  background-color: #fff;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  /* drop shadow */
  z-index: 91;

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 22px;
    height: 22px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    background-position: left -112px;
    z-index: 10;
  }

  &.type-form {
    .ddp-pop-top {
      padding-top: 40px;
      padding-bottom: 40px;

      .ddp-ui-title {
        padding-top: 0;
      }

      .ddp-label-title {
        font-size: 22px;
      }
    }

    .ddp-detail-contents {
      top: 102px;
    }
  }

  .ddp-pop-top {
    padding: 16px 62px 16px 40px;

    &:before {
      display: table;
      content: '';
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    .ddp-ui-title {
      padding-top: 4px;
      float: left;
    }

    .ddp-label-title {
      position: relative;
      top: -2px;
      float: left;
      max-width: 570px;
      margin-right: 12px;
      font-weight: bold;
      color: #4b515b;
      font-size: 16px;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ddp-box-tag-value {
      float: left;
      margin-right: 7px;
      vertical-align: middle;
    }

    .ddp-wrap-info {
      position: relative;
      display: inline-block;
      margin-right: 3px;
      cursor: pointer;
      z-index: 2;

      .ddp-icon-info {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: left -30px;
      }

      .ddp-box-layout4 {
        display: none;
        position: absolute;
        top: 100%;
        margin-top: 6px;
      }

      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }

      .ddp-box-layout4.type-info {
        width: 340px;
      }
    }
  }

  .ddp-box-layout4 .ddp-icon-close {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -58px;
  }

  .ddp-detail-contents {
    top: 62px;

    ul.ddp-ui-tab {
      height: 42px;
      overflow: hidden;
    }
  }

  .ddp-pop-top {
    .ddp-right {
      float: right;

      .ddp-btn-pop {
        margin-left: 6px;
      }
    }

    .ddp-ui-title .ddp-data-name {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-left: 17px;
      color: #b7bac1;
      font-size: 13px;

      .ddp-txt-name {
        color: #4b515b;
        font-size: 13px;
        text-decoration: underline;

        &.type-line-none {
          text-decoration: none;
        }
      }
    }
  }
}

.ddp-detail-view {
  &.ddp-left {
    position: relative;
    float: left;
    width: 475px;
    height: 100%;
    z-index: 1;
  }

  &.ddp-right {
    float: left;
    position: relative;
    width: 100%;
    margin-left: -475px;
    padding-left: 514px;
    box-sizing: border-box;

    &:before {
      display: block;
      position: absolute;
      left: 476px;
      bottom: 0;
      top: 0;
      border-left: 1px solid #e5e7f0;
      content: '';
    }
  }
}

.ddp-detail ul.ddp-list-user {
  overflow: hidden;
  margin: -3px -3px;

  li {
    float: left;
    padding: 3px 3px;
    width: 33.3333%;
    box-sizing: border-box;

    .ddp-box-user {
      display: block;
      padding-right: 10px;
      overflow: hidden;
      background-color: rgba(236, 238, 246, 0.3);
      cursor: default;

      .ddp-data-photo {
        position: relative;
        width: 50px;
        height: 50px;
        float: left;
        margin: 22px 10px 22px 13px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        &:before {
          display: inline-block;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 0;
          left: 0;
          background-position: left -82px;
          content: '';
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .ddp-wrap-data {
        display: block;
        margin-left: -78px;
        padding-left: 78px;
        width: 100%;
        float: left;
        box-sizing: border-box;

        .ddp-data-name {
          display: inline-block;
          padding: 20px 0 0 0;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          span.ddp-txt-name {
            display: block;
            color: #4e5368;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }

          .ddp-icon-control-fav {
            position: relative;
            top: -2px;
          }
        }

        .ddp-data-info {
          position: relative;
          display: inline-block;
          max-width: 100%;
          padding: 4px 0 0 0;
          color: #4e5368;
          font-size: 12px;

          em.ddp-link-new {
            display: inline-block;
            position: relative;
            top: 2px;
            float: right;
            width: 14px;
            height: 12px;
            margin-left: 2px;
            background: url(/assets/bi/images/icon_window.png) no-repeat;
            background-position: left -24px;
            content: '';
          }

          .ddp-txt-info {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.type-link:hover .ddp-txt-info {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .ddp-data-date {
          padding: 2px 0 0 0;
          color: #90969f;
          font-size: 12px;
        }
      }
    }
  }
}

/*.ddp-detail ul.ddp-list-user li .ddp-box-user:hover {background-color:#eceef6;}*/

.ddp-page-catalog.ddp-list-catalog {
  .ddp-select-catalog {
    position: relative;
    top: 0;
    overflow: inherit;
  }

  .ddp-nodata {
    position: absolute;
  }
}

.ddp-detail-contents {
  position: absolute;
  top: 97px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 30px;

  .ddp-wrap-tab-contents {
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 25px 50px 20px 50px;
    overflow: auto;

    .ddp-ui-tab-contents {
      position: relative;
      height: 100%;
    }
  }

  .type-sampledata {
    .ddp-box-synch {
      position: absolute;
      top: -25px;
      left: 0;
      right: 0;
    }

    .ddp-data-details.ddp-ui-preview-contents {
      margin-top: 0;
      top: 10px;
    }
  }

  .ddp-data-details.ddp-ui-preview-contents {
    position: absolute;
    margin-top: -25px;

    .ddp-bar {
      background-color: #ecedf3;

      span {
        background-color: #9ca2cc;
      }
    }
  }

  table.ddp-table-form.ddp-table-details tr th .ddp-box-layout4.ddp-popularity {
    top: 100%;
  }

  .ddp-ui-tag .ddp-label-tag {
    display: inline-block;
    float: left;
    padding: 6px 0;
    margin-right: 17px;
    color: #4c515a;
    font-weight: bold;
  }

  .ddp-data-tags {
    display: block;
    padding: 6px 0 20px 0;
    overflow: hidden;

    .ddp-tags {
      padding: 6px;
      margin-right: 5px;
      border-radius: 3px;
      background-color: #eff0f8;
      color: #4b515b;
      font-size: 13px;
    }
  }

  .ddp-ui-datadetail {
    padding-top: 0;
  }

  table.ddp-table-detail:first-of-type {
    margin-top: 0;
  }
}

.ddp-detail-view {
  margin: 0 -3px;
  padding-bottom: 25px;

  .ddp-detail-col {
    padding: 0 3px;
  }

  .ddp-box-table {
    border: 1px solid #e7e7ea;

    .ddp-label {
      padding: 18px 17px;
      color: #4c515a;
      font-weight: bold;
    }
  }
}

.ddp-detail-contents .ddp-box-catalogs {
  font-weight: bold;
}

table.ddp-table-form2.ddp-type tr {
  th {
    background-color: #f6f6f7;
    font-weight: normal;
  }

  td {
    .ddp-ui-photo {
      margin: -4px 0;
    }

    .ddp-data-used {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ddp-detail-view .ddp-detail {
  padding: 30px 0 0 0;

  &:first-of-type {
    padding-top: 0;
  }

  .ddp-label {
    padding-bottom: 10px;
    font-weight: bold;
    color: #4c515a;
    font-size: 13px;

    .ddp-link {
      margin-left: 10px;
      color: #90969f;
      font-size: 13px;
      text-decoration: underline;
      font-weight: normal;

      &:hover {
        color: #4b515b;
      }
    }
  }

  .ddp-data-det {
    line-height: 18px;

    .ddp-list-catalog {
      span {
        position: relative;
        padding: 0 8px 0 12px;
        color: #35393f;
        font-weight: bold;

        &:first-of-type {
          padding-left: 0;
        }

        &:before {
          display: inline-block;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
          width: 4px;
          height: 8px;
          background: url(/assets/bi/images/icon_tagview.png) no-repeat;
          transform: rotate(180deg);
          content: '';
        }

        &:first-of-type:before {
          display: none;
        }
      }

      + .ddp-list-catalog {
        margin-top: 15px;
      }
    }

    .ddp-ui-more {
      position: relative;
    }

    .ddp-more {
      display: inline-block;
      margin-top: 15px;
      text-decoration: underline;
      font-style: italic;
    }

    .ddp-ui-more.ddp-selected .ddp-box-layout4 {
      display: block;
    }

    .ddp-box-layout4 {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 3px;
      left: -30px;
      width: 280px;
      text-decoration: none;

      * {
        font-style: normal;
        text-decoration: none;
      }
    }

    ul.ddp-list-graph {
      margin-top: 0;

      span.ddp-txt-det {
        font-size: 13px;
        color: #90969f;

        &:hover {
          text-decoration: underline;
        }

        .ddp-det-new {
          display: inline-block;
          max-width: 100%;

          em.ddp-link-new {
            display: inline-block;
            float: right;
            position: relative;
            top: 2px;
            width: 14px;
            height: 12px;
            margin-left: 2px;
            background: url(/assets/bi/images/icon_window.png) no-repeat;
            background-position: left -24px;
            content: '';
          }

          .ddp-txt-in {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &.type-link:hover .ddp-txt-in {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.ddp-type {
    padding-top: 30px;
    clear: both;
  }
}

/**************************************************************
  멀티데이터소스
**************************************************************/

.ddp-data-board-title {
  padding: 24px 29px;
  text-align: left;
  color: #535c67;
  font-size: 16px;

  .ddp-icon-dashboard2 {
    margin-right: 5px;
    vertical-align: middle;
  }
}

.ddp-box-popupcontents3.ddp-type-multidata {
  position: relative;
  height: 598px;
  margin-top: -53px;
}

.ddp-type-multidata {
  .ddp-multidata-option {
    position: relative;
    padding: 14px 20px;
    height: 50px;
    border-bottom: 1px solid #b7b9c2;
    background-color: #fff;
    z-index: 1;
    box-sizing: border-box;

    .ddp-btn-line {
      position: relative;
      top: -3px;
      float: right;
    }

    .ddp-btn-guide {
      position: relative;
      z-index: 1;

      &:before {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: 7px;
        background: url(/assets/bi/images/icon_que.png) no-repeat;
        background-position: left -53px;
        vertical-align: middle;
        content: '';
      }
    }

    .ddp-edit-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 15px 0;
      text-align: center;
      color: #4b515b;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ddp-ui-multidata {
    height: 100%;
    width: 100%;
    background-color: #f7f9ff;
  }

  .ddp-wrap-add {
    display: table;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .ddp-view-multidata {
    width: 100%;
    height: 100%;
    overflow: auto;

    .ddp-txt-edit {
      position: absolute;
      top: 20px;
      left: 20px;
      color: #4b515b;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &.ddp-edit {
    border: 1px solid #b7b9c2;

    .ddp-ui-multidata {
      margin-top: -49px;
      padding-top: 49px;
      box-sizing: border-box;
      background-color: #e7e7ea;
    }

    .ddp-view-multidata {
      position: relative;
      height: 100%;
      z-index: 1;

      .ddp-txt-message {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 16px 0 0 20px;
        color: #4b515b;
        font-size: 13px;
      }
    }
  }
}

.ddp-ui-multidata {
  .ddp-ui-add {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;

    .ddp-txt-det {
      display: block;
      padding-top: 18px;
      text-align: center;
      color: #b7b9c2;
      font-size: 18px;
    }
  }

  .ddp-add-datasource {
    display: inline-block;
    width: 198px;
    padding: 25px 0;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #b7b9c2;
    text-align: center;
    color: #4a515c;
    font-size: 14px;
    background-color: #fff;
    box-sizing: border-box;

    &:hover {
      background-color: #f0f4ff;
      border: 2px solid $primary;
    }

    .ddp-icon-add {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: left -66px;
      vertical-align: middle;
    }
  }

  .ddp-option-button {
    position: absolute;
    top: 14px;
    left: 20px;
    z-index: 1;

    .ddp-txt-info {
      display: inline-block;
      position: relative;
      top: 14px;
      padding-bottom: 0;
      font-size: 13px;
      color: $primary;
      font-weight: bold;

      &:before {
        display: inline-block;
        margin-right: 7px;
        width: 6px;
        height: 13px;
        background: url(/assets/bi/images/icon_info2.png) no-repeat;
        content: '';
        vertical-align: middle;
      }
    }

    a[class*='ddp-btn-'] {
      float: left;
      margin-right: 10px;
      vertical-align: middle;
    }

    .ddp-btn-add-r {
      display: inline-block;
      width: 36px;
      height: 36px;
      background: url(/assets/bi/images/btn_add_r.png) no-repeat;

      &:hover {
        background-position: -37px top;
      }
    }

    .ddp-btn-edit-r {
      padding: 11px 22px;
      border-radius: 20px;
      background-color: #747880;
      color: #fff;
      font-size: 14px;

      &:hover {
        background-color: #4b515b;
      }

      .ddp-icon-btn-edit {
        background-position-x: -28px;
      }
    }
  }

  .ddp-size-button {
    position: absolute;
    top: 18px;
    right: 22px;
    z-index: 1;

    [class*='ddp-btn-'] {
      position: relative;
      float: left;
      margin-left: 22px;

      &:before {
        float: left;
        background: url(/assets/bi/images/btn_network.png) no-repeat;
        content: '';
      }

      &.ddp-disabled {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          cursor: no-drop;
        }

        &:before {
          opacity: 0.3;
        }
      }
    }

    .ddp-btn-sizeup:before {
      width: 20px;
      height: 18px;
    }

    .ddp-btn-sizedown:before {
      width: 20px;
      height: 18px;
      background-position: left -19px;
    }

    .ddp-btn-fit:before {
      width: 15px;
      height: 15px;
      background-position: left -38px;
    }
  }
}

.ddp-type-multidata .ddp-box-resultdata {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 260px;
  background-color: #fff;
  border: 1px solid #d0d1d9;
  z-index: 10;
  -webkit-box-shadow: 0 0 8px 1px rgba(231, 231, 234, 1);
  -moz-box-shadow: 0 0 8px 1px rgba(231, 231, 234, 1);
  box-shadow: 0 0 8px 1px rgba(231, 231, 234, 1);

  &.ddp-full {
    height: 100%;
    background-color: #fff;
  }
}

.ddp-type-top-option.ddp-type {
  padding-bottom: 0;

  ul.ddp-ui-tab li.ddp-selected a:after {
    display: none;
  }

  &:before {
    content: '';
    display: table;
    width: 100%;
  }

  &:after {
    content: '';
    display: table;
    width: 100%;
    clear: both;
  }

  .ddp-link-type {
    float: left;
    padding: 8px 10px 0 0;
    color: #4b515b;
    font-size: 13px;

    em.ddp-icon-link-cut2 {
      margin-right: 5px;
    }
  }
}

.ddp-type-multidata {
  .ddp-box-resultdata {
    .ddp-ui-tab-contents {
      position: absolute;
      top: 32px;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #d7d8dd;
    }

    .ddp-wrap-tab-result {
      position: absolute;
      top: 41px;
      left: 9px;
      right: 9px;
      bottom: 10px;
      border: 1px solid #d7d8dd;

      .ddp-wrap-grid-option .ddp-part-right {
        margin-top: -2px;
      }
    }

    .ddp-ui-top-area {
      position: relative;

      .ddp-name {
        display: block;
        padding: 10px 20px 10px 9px;
        overflow: hidden;

        .ddp-txt-name {
          margin-right: 6px;
          color: #4b515b;
          font-size: 16px;
        }

        .ddp-txt-det {
          color: #b7b9c2;
          font-size: 12px;
        }
      }

      .ddp-link-type {
        float: left;
        padding: 14px 0;
        color: #b7b9c2;

        &:hover {
          color: #4b515b;
        }

        em.ddp-icon-delete {
          display: inline-block;
          position: relative;
          top: -2px;
          width: 11px;
          height: 12px;
          background: url(/assets/bi/images/icon_edit2.png) no-repeat;
          background-position: -36px -21px;
          vertical-align: middle;
        }

        &:hover em.ddp-icon-delete {
          background-position-x: 0;
        }
      }

      .ddp-icon-fullsize {
        float: left;
        width: 13px;
        height: 13px;
        margin: 14px 20px 0 29px;
        background: url(/assets/bi/images/btn_size.png) no-repeat;
      }
    }
  }

  .ddp-btn-area-close {
    display: inline-block;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 22px;
    height: 22px;
    background: url(/assets/bi/images/btn_close.png) no-repeat;
    background-position: left -109px;
  }

  .ddp-box-join-option {
    height: 100%;
    overflow: auto;
    background-color: #fafafb;

    a.ddp-data-name {
      background-color: #90969f;
    }

    .ddp-box-lookup ul.ddp-list-lookup li {
      &:first-of-type:before,
      .ddp-ui-lookup:before,
      .ddp-ui-add-data:before {
        border-color: #90969f;
      }

      a {
        &.ddp-ui-joinicon:hover {
          background-color: #e7e7ea;
        }

        &.ddp-link-joinname {
          background-color: #efeff1;
        }
      }
    }
  }

  .ddp-box-resultdata .ddp-ui-tab-contents .ddp-ui-grid-form {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #d7d8dd;
  }

  .ddp-wrap-grid-option {
    .ddp-txt-title {
      display: inline-block;
      padding: 10px 15px;
      color: #4b515b;
      font-size: 13px;
      font-weight: bold;

      .ddp-txt-title-info {
        margin-left: 10px;
        color: #b7b9c2;
        font-size: 12px;
        font-weight: normal;
      }
    }

    .ddp-data-form {
      padding: 5px 0 6px 0;

      .ddp-wrap-popup2 {
        top: 35px;
      }
    }
  }
}

.ddp-essential-filter {
  height: 100%;
  overflow: auto;

  .ddp-ui-filter-result {
    padding: 4px 16px;
    color: #4b515b;
    font-size: 13px;

    .ddp-btn-line-s {
      margin-left: 10px;
    }
  }
}

ul.ddp-list-essential li {
  padding: 15px;
  position: relative;
  border-top: 1px solid #ededee;

  span.ddp-txt-filter {
    display: block;
    padding-left: 20px;
    color: #4b515b;
    font-size: 13px;
    position: relative;
  }

  .ddp-data-filter [class*='ddp-icon-'] {
    position: absolute;
    left: 0;
    top: 2px;
  }

  .ddp-data-det {
    padding-top: 5px;
    color: #90969f;
    line-height: 18px;
    font-size: 12px;

    .ddp-txt-label {
      color: #b7b9c2;
    }
  }
}

.ddp-association {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding-right: 128px;
  background-color: #fff;
  border: 1px solid #eeeef0;
  -webkit-box-shadow: 0 -2px 2px 1px rgba(231, 231, 234, 1);
  -moz-box-shadow: 0 -2px 2px 1px rgba(231, 231, 234, 1);
  box-shadow: 0 -2px 2px 1px rgba(231, 231, 234, 1);

  .ddp-label {
    float: left;
    padding: 31px 63px 31px 16px;
    font-size: 14px;
    font-weight: bold;
    color: #4b515b;
  }

  .ddp-box-data {
    display: block;
    padding: 18px 0;
    position: relative;
    overflow: hidden;

    .ddp-bar {
      position: absolute;
      top: 23px;
      left: 60px;
      right: 60px;
      height: 30px;
      background-color: #c1cef1;

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -2px;
        border-bottom: 1px solid #fff;
        content: '';
      }
    }

    .ddp-box-name {
      position: relative;
      width: 380px;
      padding: 10px;
      text-align: center;
      color: #4b515b;
      font-size: 16px;
      border-radius: 25px;
      border: 2px solid #c1cef1;
      background-color: #f0f4ff;
      box-sizing: border-box;

      &:before {
        display: inline-block;
        width: 9px;
        height: 9px;
        background-color: #c1cef1;
        border-radius: 50%;
        border: 2px solid #fff;
        content: '';
      }

      &.ddp-fleft:before {
        position: absolute;
        top: 50%;
        right: -5px;
        margin-top: -6px;
      }

      &.ddp-fright:before {
        position: absolute;
        top: 50%;
        left: -5px;
        margin-top: -6px;
      }
    }
  }

  .ddp-buttons {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -9px;

    .ddp-btn-line {
      margin-left: 7px;
    }

    [class*='ddp-btn-'] {
      margin-left: 20px;
    }
  }
}

.ddp-type-multidata .ddp-massage {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #f6f6f7;
  background-color: #f6f6f7;
  color: #4b515b;

  .ddp-buttons {
    float: right;
    padding: 10px 20px 10px 0;
  }

  .ddp-txt-message {
    display: block;
    padding: 15px 20px;
    color: #4b515b;
    font-size: 13px;
    text-align: center;
  }
}

.ddp-popup-dashboard.ddp-type {
  width: auto;
  position: absolute;
  top: 120px;
  left: 50px;
  right: 50px;
  bottom: 42px;
  box-sizing: border-box;

  .ddp-wrap-grid {
    margin-top: -44px;
    padding: 44px 0 0 0;
    height: 100%;
    box-sizing: border-box;

    &.ddp-selected {
      margin-right: 300px;
    }
  }

  .ddp-wrap-viewtable {
    position: relative;
    height: 100%;
    padding-bottom: 43px;
    box-sizing: border-box;

    .ddp-box-add-link3 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .ddp-wrap-grid .ddp-box-viewtable {
    height: 100%;
    min-height: auto;
  }
}

.ddp-ui-popup-join.ddp-multy {
  .ddp-ui-part {
    .ddp-ui-grid-form,
    .ddp-data-none {
      top: 115px;
    }
  }

  .ddp-box-key {
    padding: 6px 0;
    margin: -10px 0 6px 0;
    background-color: #f2f2f4;
    text-align: center;
    color: #b7b9c2;
    font-size: 14px;

    &.ddp-selected {
      background-color: #f2f1f8;
      color: $primary;
    }
  }
}

/**************************************************************
      메타데이터 discussion
**************************************************************/

.ddp-type-top-option.type-discussion {
  margin-top: -13px;

  .ddp-type-search {
    width: 250px;
  }
}

.ddp-box-comment {
  border: 1px solid #d0d1d8;
  border-radius: 3px;

  .ddp-top-comment {
    border-bottom: 1px solid #d0d1d8;
    background-color: #e7e7e9;

    .ddp-wrap-select {
      position: relative;
      width: 200px;

      &:after {
        display: inline-block;
        position: absolute;
        top: 8px;
        height: 25px;
        border-left: 1px solid #d0d1d8;
        content: '';
      }
    }

    .ddp-type-select {
      display: block;
      position: relative;
      padding: 12px 30px 10px 13px;
      width: 100%;
      height: 40px;
      float: left;
      box-sizing: border-box;
      cursor: pointer;

      .ddp-icon-view {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        width: 7px;
        height: 4px;
        background: url('/assets/bi/images/icon_select.png') no-repeat -8px top;
      }

      .ddp-txt-result {
        position: relative;
        padding: 0 0 0 36px;
        font-size: 13px;

        &.type-success {
          color: #10bf83;
        }

        &.type-warning {
          color: #ffba01;
        }

        &.type-error {
          color: #dc494f;
        }
      }
    }
  }

  .ddp-icon-success-r,
  .ddp-icon-warning-r,
  .ddp-icon-error-r {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 6px;
    background: url(/assets/bi/images/icon_confidence.png) no-repeat;
    vertical-align: middle;
  }

  .ddp-icon-success-r {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }

  .ddp-icon-warning-r {
    width: 20px;
    height: 18px;
    margin-top: -9px;
    background-position: left -21px;
  }

  .ddp-icon-error-r {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-position: left -57px;
  }

  .ddp-top-comment .ddp-type-buttons {
    float: right;
    position: relative;

    &:before {
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 25px;
      border-left: 1px solid #d0d1d8;
      content: '';
    }

    .ddp-btn-summit {
      display: block;
      position: relative;
      padding: 12px 20px 12px 36px;
      color: #4b515b;
      font-size: 13px;

      .ddp-icon-check {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -4px;
        width: 11px;
        height: 8px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -68px top;
      }
    }
  }

  .ddp-wrap-textarea {
    height: 62px;

    textarea {
      display: block;
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      box-sizing: border-box;
      border: none;
      font-size: 13px;
      border-radius: 0 0 3px 3px;
    }
  }
}

.ddp-wrap-discussion {
}

.ddp-ui-discussion {
  position: relative;
  padding: 20px 0 20px 54px;
  border-bottom: 1px solid #e7e7e9;

  .ddp-ui-top {
    padding-bottom: 10px;
  }

  .ddp-data-top {
    display: inline-block;
    padding-top: 4px;

    .ddp-data-name {
      display: block;
      font-weight: bold;
      color: #4b515b;
      overflow: hidden;
    }

    .ddp-data-date {
      float: right;
      margin-left: 6px;
      color: #90969f;
    }
  }

  .ddp-data-photo {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: url(/assets/bi/images/img_profile.png) no-repeat;
    background-position: left -51px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .ddp-ui-status-block {
    padding-bottom: 8px;
  }

  .ddp-box-condition {
    display: inline-block;
    position: relative;
    padding: 5px 5px 5px 28px;
    border-radius: 2px;
    font-size: 13px;

    &.type-warning {
      background-color: rgba(255, 182, 2, 0.1);
      color: #ffba01;
    }

    &.type-success {
      background-color: rgba(16, 191, 131, 0.1);
      color: #10bf83;
    }

    &.type-error {
      background-color: rgba(220, 73, 79, 0.1);
      color: #dc494f;
    }
  }

  .ddp-icon-success-r,
  .ddp-icon-warning-r,
  .ddp-icon-error-r {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 6px;
    background: url(/assets/bi/images/icon_confidence.png) no-repeat;
    vertical-align: middle;
  }

  .ddp-icon-success-r {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }

  .ddp-icon-warning-r {
    width: 20px;
    height: 18px;
    margin-top: -9px;
    background-position: left -21px;
  }

  .ddp-icon-error-r {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-position: left -57px;
  }

  .ddp-link-reply {
    position: absolute;
    top: 61px;
    right: 0;
    color: #90969f;
    text-decoration: underline;
  }

  .ddp-txt-det {
    padding-bottom: 10px;
    font-size: 13px;
  }

  .ddp-ui-reply {
    padding: 20px;
    margin-top: 10px;
    background-color: #f6f6f7;

    + .ddp-ui-reply {
      margin-top: 4px;
    }

    .ddp-txt-det {
      padding-bottom: 0;
    }

    .ddp-ui-reply-top {
      padding-bottom: 10px;

      .ddp-top-in {
        display: inline-block;
        max-width: 100%;
      }

      .ddp-data-reply-date {
        float: right;
        margin-left: 10px;
      }

      .ddp-data-reply-name {
        display: block;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ddp-ui-textarea {
      height: 62px;
      border: 1px solid #d0d1d8;
      border-radius: 2px;
      box-sizing: border-box;
      background-color: #fff;

      .ddp-box-textarea {
        display: block;
        height: 100%;
        overflow: hidden;

        textarea {
          display: block;
          padding: 9px 10px;
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          box-sizing: border-box;
        }
      }

      .ddp-btn-selection {
        float: right;
        margin: 16px 24px 16px 10px;
      }
    }
  }
}

/**************************************************************
  멀티데이터 가이드
**************************************************************/

.ddp-type-multidata.ddp-edit .ddp-view-guide {
  position: absolute;
  top: 49px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(125, 132, 142, 0.85);
  z-index: 20;

  &:before {
    display: inline-block;
    position: absolute;
    top: -10px;
    left: 20px;
    width: 18px;
    height: 11px;
    background: url(/assets/bi/images/icon_guide_arrow.png) no-repeat;
    content: '';
  }

  .ddp-wrap-guide {
    display: table;
    width: 100%;
    height: 100%;

    .ddp-ui-guide {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;
    }
  }

  .ddp-txt-det {
    display: block;
    padding-bottom: 15px;
    color: #fff;
    font-size: 22px;
  }

  .ddp-sub-det {
    display: block;
    color: #fff;
    font-size: 22px;
  }
}

.ddp-guide-motion {
  padding: 24px 0 0 0;

  .ddp-motion {
    display: inline-block;
    position: relative;
  }

  .ddp-box-nodes {
    position: relative;
    display: inline-block;
    width: 198px;
    height: 72px;
    padding: 27px 0;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 10;
    -webkit-box-shadow: 0 16px 35px -15px #000;
    -moz-box-shadow: 0 16px 35px -15px #000;
    box-shadow: 0 16px 35px -15px #000;

    &.ddp-last {
      margin-left: 178px;
    }
  }

  .ddp-line-bar {
    display: inline-block;
    position: absolute;
    top: 25px;
    left: 180px;
    width: 45px;
    height: 61px;

    em.ddp-icon-handler {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 61px;
      background: url(/assets/bi/images/icon_handle.png) no-repeat;
      z-index: 20;
    }
  }
}

.ddp-guide-link {
  padding: 35px 0 0 0;

  .ddp-link-txt {
    padding: 8px 20px 9px 20px;
    border: 1px solid #d0d1d8;
    border-radius: 2px;
    color: #fff;
    font-size: 16px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.ddp-guide-motion .ddp-line-bar:before {
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 0;
  right: 27px;
  height: 40px;
  background-color: #d0d1d8;
  content: '';
  z-index: 5;
}

/**************************************************************
  network
**************************************************************/

#mynetwork {
  width: 100%;
  height: 100%;
}

/**************************************************************
  mapview
**************************************************************/

em {
  &.ddp-icon-mapview1:before,
  &.ddp-icon-mapview2:before,
  &.ddp-icon-mapview3:before,
  &.ddp-icon-mapview1-w:before,
  &.ddp-icon-mapview2-w:before,
  &.ddp-icon-mapview3-w:before,
  &.ddp-icon-analysis:before {
    display: inline-block;
    width: 17px;
    height: 15px;
    content: '';
    background: url(/assets/bi/images/icon_mapview.png) no-repeat;
    background-position: -18px top;
    vertical-align: middle;
  }

  &.ddp-icon-mapview2:before {
    background-position: -18px -16px;
  }

  &.ddp-icon-mapview3:before {
    background-position: -18px -32px;
  }

  &.ddp-icon-mapview1-w:before {
    background-position: 0 0;
  }

  &.ddp-icon-mapview2-w:before {
    background-position: 0 -16px;
  }

  &.ddp-icon-mapview3-w:before {
    background-position: 0 -32px;
  }

  &.ddp-icon-analysis:before {
    height: 12px;
    margin-top: -6px;
    background-position: -18px -48px;
  }

  &.ddp-icon-layer1 {
    display: inline-block;
    width: 17px;
    height: 15px;
    content: '';
    background: url(/assets/bi/images/icon_mapview.png) no-repeat;
    background-position: -18px top;
    vertical-align: middle;
  }

  &.ddp-icon-layer2 {
    display: inline-block;
    width: 17px;
    height: 15px;
    content: '';
    background: url(/assets/bi/images/icon_mapview.png) no-repeat;
    background-position: -18px top;
    vertical-align: middle;
    background-position: -18px -16px;
  }
}

.ddp-ui-shelf {
  em {
    &.ddp-icon-mapview1,
    &.ddp-icon-mapview2,
    &.ddp-icon-mapview3,
    &.ddp-icon-analysis {
      position: absolute;
      top: 4px;
      left: 7px;
      width: 42px;
      height: 39px;
      z-index: 5;
      background-color: #fff;
    }

    &.ddp-icon-mapview1:before,
    &.ddp-icon-mapview2:before,
    &.ddp-icon-mapview3:before,
    &.ddp-icon-analysis:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -9px;
    }
  }

  .ddp-tag-layer,
  .ddp-tag-layer2,
  .ddp-tag-analysis {
    padding: 2px 4px;
    margin: 0 5px 0 13px;
    background-color: #e8eaef;
    color: #838992;
    font-size: 13px;
    border-radius: 2px;
  }

  .ddp-tag-layer {
    margin-left: 0;
  }

  .ddp-tag-layer2 {
    background-color: #e9eff6;
    color: #8d9bb0;
  }

  .ddp-tag-analysis {
    background-color: #f2efe8;
    color: #a4968d;
  }
}

.ddp-map-tooltip {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
  padding: 9px;
  box-sizing: border-box;
  z-index: 10;

  .ddp-label {
    display: block;
    padding-bottom: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;

    [class*='ddp-icon-mapview'] {
      margin-right: 5px;
    }
  }

  .ddp-data-tooltip {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .ddp-title {
      color: #d0d1d8;
      font-size: 13px;
      font-weight: normal;
      text-align: left;
      white-space: normal;
    }

    .ddp-det {
      color: #fff;
      font-size: 13px;
      white-space: normal;
    }
  }
}

.ddp-list-datavalue {
  > li {
    position: relative;
    padding: 5px 28px 5px 10px;
    background-color: #eeeef0;
    color: #4a525c;
    font-size: 13px;
    cursor: pointer;

    + li {
      margin-top: 2px;
    }
  }

  li {
    em {
      &[class*='ddp-icon-dimension-'],
      &[class*='ddp-icon-measure'] {
        float: left;
        margin-right: 8px;
      }
    }

    &.ddp-add {
      &.ddp-selected .ddp-box-layout4 {
        display: block;
      }

      em.ddp-icon-plus {
        position: absolute;
        top: 50%;
        left: 8px;
        margin: -8px 0 0 0;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/icon_plus.png) no-repeat;
        background-position: left -15px;
        content: '';
      }

      span.ddp-data-value {
        color: #90969f;
      }

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 14px;
        width: 7px;
        height: 4px;
        margin-left: 9px;
        margin-top: -2px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        transform: rotate(180deg);
        content: '';
      }

      &.ddp-selected:after {
        transform: rotate(0);
      }
    }
  }

  > li {
    span.ddp-data-value {
      display: block;
      position: relative;
      padding-left: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: normal;

      > em {
        &[class*='ddp-icon-dimension-'],
        &[class*='ddp-icon-measure'] {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -5px;
        }
      }
    }

    &:hover .ddp-icon-listdelete {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -8px;
      width: 15px;
      height: 15px;
      background: url(/assets/bi/images/icon_delete.png) no-repeat;
      background-position: left -14px;
      cursor: pointer;
    }
  }
}

.ddp-data-value {
  position: relative;

  .ddp-box-layout4 {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 12px 0 0 0;
    width: auto;
    max-height: 334px;
    overflow: auto;
    z-index: 10;

    .ddp-form-search {
      width: auto;
      margin: 0 10px;
    }
  }

  .ddp-list-typebasic {
    padding: 11px 0;

    &.ddp-list-popup li a {
      padding-left: 38px;
      padding-right: 16px;

      em {
        &[class*='ddp-icon-dimension-'],
        &[class*='ddp-icon-measure-'] {
          position: absolute;
          top: 50%;
          left: 18px;
          margin-top: -7px;
        }

        &.ddp-icon-dimension-ab,
        &.ddp-icon-dimension-maplink,
        &.ddp-icon-measure-ab,
        &.ddp-icon-measure-maplink,
        &.ddp-icon-type-ab,
        &.ddp-icon-type-maplink {
          margin-top: -5px;
        }

        &.ddp-icon-dimension-sharp,
        &.ddp-icon-dimension-float,
        &.ddp-icon-dimension-tf,
        &.ddp-icon-dimension-int,
        &.ddp-icon-measure-sharp,
        &.ddp-icon-measure-float,
        &.ddp-icon-measure-tf,
        &.ddp-icon-measure-int,
        &.ddp-icon-type-sharp,
        &.ddp-icon-type-float,
        &.ddp-icon-type-tf,
        &.ddp-icon-type-int {
          margin-top: -4px;
        }
      }
    }
  }
}

.ddp-list-datavalue .ddp-list-typebasic li a .ddp-txt-name {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
}

.ddp-box-layout4 .ddp-box-search {
  padding: 0 20px;

  .ddp-form-search {
    width: 100%;
    margin-left: 0;
  }
}

.ddp-wrap-tab-type {
  margin: 0 -15px;
  text-align: center;
}

ul.ddp-list-tab-type {
  display: inline-block;

  li {
    display: inline-block;
    position: relative;
    float: left;

    + li {
      margin-left: 1px;
    }

    a {
      display: inline-block;
      width: 46px;
      height: 46px;

      em {
        &[class*='ddp-img-map-'] {
          display: inline-block;
          width: 46px;
          height: 46px;
          background: url(/assets/bi/images/img_maptype.png) no-repeat;
        }

        &.ddp-img-map-sign {
        }

        &.ddp-img-map-line {
          background-position: -47px 0;
        }

        &.ddp-img-map-polygon {
          background-position: -94px 0;
        }

        &.ddp-img-map-heatmap {
          background-position: -141px 0;
        }

        &.ddp-img-map-tile {
          background-position: -188px 0;
        }
      }

      &:hover em[class*='ddp-img-map-'] {
        background-position-y: -47px;
      }
    }

    &.ddp-selected a em[class*='ddp-img-map-'] {
      background-position-y: -47px;
    }

    a:hover + .ddp-ui-tooltip-info {
      display: block;
      top: 100%;
      margin-top: 5px;
      left: 0;
    }
  }
}

.ddp-list-tab-type2 {
  border-radius: 2px;
  margin: 0 -1px;

  li {
    float: left;
    position: relative;
    width: 25%;
    padding: 0 1px;
    box-sizing: border-box;

    a {
      display: block;
      width: 100%;
      height: 58px;
      position: relative;
      border: 2px solid #e3e3e6;
      background: url(/assets/bi/images/bg_maptype.png) no-repeat;
      box-sizing: border-box;

      &:hover {
        border-color: #b6b9c1;
      }

      [class*='ddp-icon-map-'] {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        background: url(/assets/bi/images/img_maptype2.png) no-repeat;
      }

      .ddp-icon-map-point {
        width: 41px;
        height: 38px;
        margin: -19px 0 0 -20px;
      }

      .ddp-icon-map-heatmap {
        width: 39px;
        height: 36px;
        margin: -18px 0 0 -19px;
        background-position-y: -39px;
      }

      .ddp-icon-map-hexagon {
        width: 42px;
        height: 41px;
        margin: -21px 0 0 -21px;
        background-position-y: -76px;
      }

      .ddp-icon-map-cluster {
        width: 40px;
        height: 41px;
        margin: -20px 0 0 -21px;
        background-position-y: -118px;
      }
    }

    &.ddp-selected a {
      border-color: #9ca2c9;

      .ddp-icon-map-point,
      .ddp-icon-map-heatmap {
        background-position-x: -42px;
      }

      .ddp-icon-map-hexagon {
        background-position-x: -43px;
      }

      .ddp-icon-map-cluster {
        background-position-x: -41px;
      }
    }

    .ddp-txt-type {
      display: block;
      color: #91969e;
      font-size: 12px;
      text-align: center;
      letter-spacing: -0.5px;
    }

    &.ddp-selected .ddp-txt-type {
      color: $primary;
    }

    &.ddp-disabled {
      opacity: 0.5;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 1;
      }

      a {
        cursor: no-drop;
      }
    }
  }
}

/**********************************************************************************
     데이터셋치환
 **********************************************************************************/

.ddp-type-dataset {
  .ddp-ui-popup-contents {
    position: relative;
  }

  .ddp-popup-dashboard {
    position: absolute;
    top: 120px;
    left: 50px;
    right: 50px;
    bottom: 50px;
    width: auto;
  }

  .ddp-wrap-variable {
    position: absolute;
    top: 43px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ddp-wrap-grid {
    height: 100%;
    padding-bottom: 44px;
    box-sizing: border-box;
  }

  .ddp-wrap-viewtable {
    height: 100%;
  }
}

/**********************************************************************************
     pop manager
**********************************************************************************/

.ddp-pop-manager {
  position: relative;
  padding: 15px 60px 16px 40px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  z-index: 5;

  .ddp-data-photo {
    display: inline-block;
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    background: url(/assets/bi/images/img_photo2.png) no-repeat;
    background-size: 100% 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ddp-wrap-manager {
    display: block;
    padding: 5px 0 0 0;
    overflow: hidden;

    .ddp-data-name {
      display: inline-block;
      max-width: 100%;

      .ddp-icon-control-fav {
        float: right;
        margin-left: 3px;
        position: relative;
        top: 2px;
      }

      .ddp-txt-name {
        display: block;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #4b515b;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .ddp-data-date {
      color: #90969f;
      font-size: 12px;
    }
  }
}

.ddp-box-meta .ddp-detail-contents {
  &.type-manager {
    display: flex;
    flex-direction: column;
    top: 0;
    padding: 94px 60px 20px 60px;
    height: 100%;
    box-sizing: border-box;

    .ddp-wrap-table {
      flex: 1 auto;
      overflow: auto;
    }
  }

  .ddp-no-data {
    padding: 42px 0;
    color: #b5b9c2;
    border-top: 1px solid #e7e7ea;
    text-align: center;
  }

  &.type-manager {
    .ddp-type-top-option {
      min-width: 846px;
    }

    .ddp-box-page {
    }
  }
}

/**********************************************************************************
     pop stream
 **********************************************************************************/

.page-storage-create {
  .form-check {
    display: flex;

    .ddp-btn-pop {
      min-width: auto;
      margin-left: 4px;
      white-space: nowrap;
    }
  }

  .ddp-wrap-edit2.type-url {
    margin-top: 0;

    .ddp-ui-buttons {
      padding-top: 6px;
    }
  }

  .ddp-radio-inline {
    padding-top: 5px;
    overflow: hidden;

    li {
      float: left;
      margin-right: 20px;
    }
  }

  .ddp-wrap-update {
    margin-top: 17px;
    margin-bottom: 6px;

    .ddp-txt-update {
      color: $primary;
      font-size: 14px;
      float: left;
    }

    .ddp-btn-line {
      float: right;
      margin-top: -7px;

      .ddp-icon-reload {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 4px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
        background-position: left -76px;
        vertical-align: middle;
      }
    }
  }

  .ddp-txt-error.type-inline {
    position: relative;
    top: -5px;
    margin-left: 5px;
  }
}

/**********************************************************************************
     데이터셋치환
 **********************************************************************************/

.ddp-pop-manager {
  position: relative;
  padding: 15px 60px 16px 40px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  z-index: 5;

  .ddp-data-photo {
    display: inline-block;
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    background: url(/assets/bi/images/img_photo2.png) no-repeat;
    background-size: 100% 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ddp-wrap-manager {
    display: block;
    padding: 5px 0 0 0;
    overflow: hidden;

    .ddp-data-name {
      display: inline-block;
      max-width: 100%;

      .ddp-icon-control-fav {
        float: right;
        margin-left: 3px;
        position: relative;
        top: 2px;
      }

      .ddp-txt-name {
        display: block;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #4b515b;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .ddp-data-date {
      color: #90969f;
      font-size: 12px;
    }
  }
}

.ddp-box-meta .ddp-detail-contents {
  &.type-manager {
    display: flex;
    flex-direction: column;
    top: 0;
    padding: 94px 60px 20px 60px;
    height: 100%;
    box-sizing: border-box;

    .ddp-wrap-table {
      flex: 1 auto;
      overflow: auto;
    }
  }

  .ddp-no-data {
    padding: 42px 0;
    color: #b5b9c2;
    border-top: 1px solid #e7e7ea;
    text-align: center;
  }

  &.type-manager {
    .ddp-type-top-option {
      min-width: 846px;
    }

    .ddp-box-page {
    }
  }
}

/**********************************************************************************
     pop EngineMonitoring datasource detail
**********************************************************************************/

.pop-detail .ddp-wrap-option {
  .ddp-data-total {
    position: relative;
    top: 6px;
    color: #90969f;
    font-size: 12px;
  }

  .ddp-ui-edit-option .ddp-box-tag-value {
    min-width: 52px;
    padding: 0 5px;
    text-align: center;
  }
}

/**********************************************************************************
     pop EngineMonitoring rule edit
**********************************************************************************/

.ddp-wrap-rule-edit {
  padding-bottom: 50px;

  .ddp-list-rule {
    li {
      padding-top: 10px;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .ddp-box-rule {
      position: relative;
      padding: 20px;
      background-color: rgba(246, 246, 247, 0.5);
      cursor: pointer;

      &.type-add {
        background-color: #eff0fb;
      }

      &:hover .ddp-btn-control {
        display: block;
      }

      .ddp-btn-control {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .ddp-data-name {
        padding-bottom: 10px;
        color: #4b515b;
        font-size: 13px;
        font-weight: bold;

        .ddp-icon-new {
          position: relative;
          top: -1px;
          font-weight: normal;
          margin-left: 7px;
        }
      }

      .ddp-wrap-edit3 {
        padding: 0 0 0 20px;
        margin-top: 10px;
        margin-bottom: 0;
        width: 615px;

        &.ddp-type .ddp-label-type {
          width: 80px;
        }

        .type-full {
          width: 100%;
        }

        .type-input-size {
          width: 300px;
        }
      }
    }
  }
}

/**********************************************************************************
     pop Admin Column Dictionry popup
**********************************************************************************/

.ddp-detail-summary {
  .ddp-data-name {
    padding: 0 0 17px 0;
    color: #4b515b;
    font-size: 18px;
    font-weight: bold;
  }

  .ddp-detail-txt {
    color: #4b515b;
    font-size: 13px;
  }
}

.ddp-ui-flex-popup .ddp-pop-contents .ddp-wrap-table-detail {
  padding: 30px 0 0 0;
  margin: 30px 0 0 0;

  .ddp-label-detail {
    color: #4b515b;
    font-size: 13px;
    font-weight: bold;
  }

  table.ddp-table-detail {
    margin-top: 19px;
  }
}

table.ddp-table-detail tbody tr td {
  .ddp-type-format {
    margin: -4px 0 13px 0;

    .ddp-label-checkbox {
      margin-left: 10px;
      vertical-align: top;
    }
  }

  .ddp-wrap-label.type-inline .ddp-label-radio {
    margin-right: 18px;
  }

  .ddp-txt-logical [class*='ddp-icon-type-'] {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 4px 0 0;
  }
}

.ddp-wrap-table-detail .ddp-table-solid-bg {
  width: 50%;
}

table.ddp-table-solid-bg tr {
  th {
    padding: 3px 15px;
    color: #90969f;
    font-weight: normal;
    font-size: 13px;
    background-color: #f6f6f7;
    text-align: left;
  }

  td {
    padding: 5px 15px;
    font-size: 13px;
    color: #4b515b;
  }
}
