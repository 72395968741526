/*!
 * Toast UI Colorpicker
 * @version 2.0.1
 * @author NHNEnt FE Development Team <dl_javascript@nhnent.com>
 * @license MIT
 */
.tui-colorpicker-clearfix {
  zoom: 1;
}
.tui-colorpicker-clearfix:after {
  content: '';
  display: block;
  clear: both;
}
.tui-colorpicker-vml {
  behavior: url('#default#VML');
  display: block;
}
.tui-colorpicker-container {
  width: 152px;
}
.tui-colorpicker-palette-container {
  width: 152px;
}
.tui-colorpicker-palette-container ul {
  width: 152px;
  margin: 0px;
  padding: 0px;
}
.tui-colorpicker-palette-container li {
  float: left;
  margin: 0;
  padding: 0 3px 3px 0;
  list-style: none;
}
.tui-colorpicker-palette-button {
  display: block;
  border: none;
  overflow: hidden;
  outline: none;
  margin: 0px;
  padding: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.tui-colorpicker-palette-button.tui-colorpicker-selected {
  border: 2px solid #000;
}
.tui-colorpicker-palette-hex {
  font-family: monospace;
  display: inline-block;
  zoom: 1;
  width: 60px;
  vertical-align: middle;
}
.tui-colorpicker-palette-preview {
  display: inline-block;
  zoom: 1;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border: 1px solid #ccc;
  vertical-align: middle;
  overflow: hidden;
}
.tui-colorpicker-palette-toggle-slider {
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  float: right;
}
.tui-colorpicker-slider-container {
  margin: 5px 0 0 0;
  height: 122px;
  zoom: 1;
}
.tui-colorpicker-slider-container:after {
  content: '';
  display: block;
  clear: both;
}
.tui-colorpicker-slider-left {
  float: left;
  width: 120px;
  height: 120px;
}
.tui-colorpicker-slider-right {
  float: right;
  width: 32px;
  height: 120px;
}
.tui-colorpicker-svg {
  display: block;
}
.tui-colorpicker-slider-handle {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  z-index: 2;
  opacity: 0.9;
}
.tui-colorpicker-svg-slider {
  width: 120px;
  height: 120px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.tui-colorpicker-vml-slider {
  position: relative;
  width: 120px;
  height: 120px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.tui-colorpicker-vml-slider-bg {
  position: absolute;
  margin: -1px 0 0 -1px;
  top: 0;
  left: 0;
  width: 122px;
  height: 122px;
}
.tui-colorpicker-svg-huebar {
  float: right;
  width: 18px;
  height: 120px;
  border: 1px solid #ccc;
  overflow: visible;
}
.tui-colorpicker-vml-huebar {
  width: 32px;
  position: relative;
}
.tui-colorpicker-vml-huebar-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 121px;
}
