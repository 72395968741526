//
// Daterange
//

.daterangepicker {
  font-family: $font-family-sans-serif;
  border: 1px solid $dropdown-border-color;
  box-shadow: 0 0 25px transparentize(theme-color('primary'), 0.85);
  background-color: $card-bg;

  .calendar-table {
    border: 1px solid $card-bg;
    background-color: $card-bg;

    td,
    th {
      color: $gray-600;
    }

    .next,
    .prev {
      span {
        border-color: $gray-500;
      }
    }
  }

  td {
    &.in-range {
      background-color: lighten(theme-color('primary'), 32%);
      color: $gray-700;
    }

    &.off,
    &.off.end-date,
    &.off.in-range,
    &.off.start-date {
      background-color: $card-bg;
      color: $theme-font-color;
      opacity: 0.5;
    }

    &.active,
    &.active:hover {
      background-color: theme-color('primary');
      color: #fff;
    }
  }

  td.available:hover,
  th.available:hover {
    background-color: theme-color('primary');
    color: #fff;

    > span {
      border-color: #fff;
    }
  }

  &:after {
    border-bottom: 6px solid $card-bg;
  }

  &:before {
    border-bottom: 7px solid $dropdown-border-color;
  }

  .ranges {
    ul {
      padding-top: 15px;
      padding-bottom: 15px;
      @media (min-width: 564px) {
        width: 160px;
      }

      li {
        margin: 5px 10px;
        transition: all 0.2s ease-in-out;

        &.active {
          background-color: theme-color('primary');
        }

        &:hover {
          box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
          background-color: theme-color('primary');
          color: #fff;
        }
      }
    }
  }

  .drp-buttons {
    border-top: 1px solid $dropdown-border-color;
  }

  &.show-ranges {
    .drp-calendar.left {
      border-left: 1px solid $dropdown-border-color;
    }
  }
}
