/**  =====================
      button css start
==========================  **/
.btn-page {
  .btn {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .btn-group,
  .btn-group-vertical {
    .btn {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.btn.btn-icon {
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-flat {
}
