@font-face {
  font-family: 'Feather';
  src: url('/assets/bi/fonts/Feather.woff?sdxovp') format('woff'),
    url('/assets/bi/fonts/Feather.ttf?sdxovp') format('truetype'),
    url('/assets/bi/fonts/Feather.svg?sdxovp#Feather') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='feather-'],
[class*=' feather-'] {
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather-activity:before {
  content: '\e900';
}
.feather-airplay:before {
  content: '\e901';
}
.feather-alert-circle:before {
  content: '\e902';
}
.feather-alert-octagon:before {
  content: '\e903';
}
.feather-alert-triangle:before {
  content: '\e904';
}
.feather-align-center:before {
  content: '\e905';
}
.feather-align-justify:before {
  content: '\e906';
}
.feather-align-left:before {
  content: '\e907';
}
.feather-align-right:before {
  content: '\e908';
}
.feather-anchor:before {
  content: '\e909';
}
.feather-aperture:before {
  content: '\e90a';
}
.feather-archive:before {
  content: '\e90b';
}
.feather-arrow-down:before {
  content: '\e90c';
}
.feather-arrow-down-circle:before {
  content: '\e90d';
}
.feather-arrow-down-left:before {
  content: '\e90e';
}
.feather-arrow-down-right:before {
  content: '\e90f';
}
.feather-arrow-left:before {
  content: '\e910';
}
.feather-arrow-left-circle:before {
  content: '\e911';
}
.feather-arrow-right:before {
  content: '\e912';
}
.feather-arrow-right-circle:before {
  content: '\e913';
}
.feather-arrow-up:before {
  content: '\e914';
}
.feather-arrow-up-circle:before {
  content: '\e915';
}
.feather-arrow-up-left:before {
  content: '\e916';
}
.feather-arrow-up-right:before {
  content: '\e917';
}
.feather-at-sign:before {
  content: '\e918';
}
.feather-award:before {
  content: '\e919';
}
.feather-bar-chart:before {
  content: '\e91a';
}
.feather-bar-chart-2:before {
  content: '\e91b';
}
.feather-battery:before {
  content: '\e91c';
}
.feather-battery-charging:before {
  content: '\e91d';
}
.feather-bell:before {
  content: '\e91e';
}
.feather-bell-off:before {
  content: '\e91f';
}
.feather-bluetooth:before {
  content: '\e920';
}
.feather-bold:before {
  content: '\e921';
}
.feather-book:before {
  content: '\e922';
}
.feather-book-open:before {
  content: '\e923';
}
.feather-bookmark:before {
  content: '\e924';
}
.feather-box:before {
  content: '\e925';
}
.feather-briefcase:before {
  content: '\e926';
}
.feather-calendar:before {
  content: '\e927';
}
.feather-camera:before {
  content: '\e928';
}
.feather-camera-off:before {
  content: '\e929';
}
.feather-cast:before {
  content: '\e92a';
}
.feather-check:before {
  content: '\e92b';
}
.feather-check-circle:before {
  content: '\e92c';
}
.feather-check-square:before {
  content: '\e92d';
}
.feather-chevron-down:before {
  content: '\e92e';
}
.feather-chevron-left:before {
  content: '\e92f';
}
.feather-chevron-right:before {
  content: '\e930';
}
.feather-chevron-up:before {
  content: '\e931';
}
.feather-chevrons-down:before {
  content: '\e932';
}
.feather-chevrons-left:before {
  content: '\e933';
}
.feather-chevrons-right:before {
  content: '\e934';
}
.feather-chevrons-up:before {
  content: '\e935';
}
.feather-chrome:before {
  content: '\e936';
}
.feather-circle:before {
  content: '\e937';
}
.feather-clipboard:before {
  content: '\e938';
}
.feather-clock:before {
  content: '\e939';
}
.feather-cloud:before {
  content: '\e93a';
}
.feather-cloud-drizzle:before {
  content: '\e93b';
}
.feather-cloud-lightning:before {
  content: '\e93c';
}
.feather-cloud-off:before {
  content: '\e93d';
}
.feather-cloud-rain:before {
  content: '\e93e';
}
.feather-cloud-snow:before {
  content: '\e93f';
}
.feather-code:before {
  content: '\e940';
}
.feather-codepen:before {
  content: '\e941';
}
.feather-command:before {
  content: '\e942';
}
.feather-compass:before {
  content: '\e943';
}
.feather-copy:before {
  content: '\e944';
}
.feather-corner-down-left:before {
  content: '\e945';
}
.feather-corner-down-right:before {
  content: '\e946';
}
.feather-corner-left-down:before {
  content: '\e947';
}
.feather-corner-left-up:before {
  content: '\e948';
}
.feather-corner-right-down:before {
  content: '\e949';
}
.feather-corner-right-up:before {
  content: '\e94a';
}
.feather-corner-up-left:before {
  content: '\e94b';
}
.feather-corner-up-right:before {
  content: '\e94c';
}
.feather-cpu:before {
  content: '\e94d';
}
.feather-credit-card:before {
  content: '\e94e';
}
.feather-crop:before {
  content: '\e94f';
}
.feather-crosshair:before {
  content: '\e950';
}
.feather-database:before {
  content: '\e951';
}
.feather-delete:before {
  content: '\e952';
}
.feather-disc:before {
  content: '\e953';
}
.feather-dollar-sign:before {
  content: '\e954';
}
.feather-download:before {
  content: '\e955';
}
.feather-download-cloud:before {
  content: '\e956';
}
.feather-droplet:before {
  content: '\e957';
}
.feather-edit:before {
  content: '\e958';
}
.feather-edit-2:before {
  content: '\e959';
}
.feather-edit-3:before {
  content: '\e95a';
}
.feather-external-link:before {
  content: '\e95b';
}
.feather-eye:before {
  content: '\e95c';
}
.feather-eye-off:before {
  content: '\e95d';
}
.feather-facebook:before {
  content: '\e95e';
}
.feather-fast-forward:before {
  content: '\e95f';
}
.feather-feather:before {
  content: '\e960';
}
.feather-file:before {
  content: '\e961';
}
.feather-file-minus:before {
  content: '\e962';
}
.feather-file-plus:before {
  content: '\e963';
}
.feather-file-text:before {
  content: '\e964';
}
.feather-film:before {
  content: '\e965';
}
.feather-filter:before {
  content: '\e966';
}
.feather-flag:before {
  content: '\e967';
}
.feather-folder:before {
  content: '\e968';
}
.feather-folder-minus:before {
  content: '\e969';
}
.feather-folder-plus:before {
  content: '\e96a';
}
.feather-gift:before {
  content: '\e96b';
}
.feather-git-branch:before {
  content: '\e96c';
}
.feather-git-commit:before {
  content: '\e96d';
}
.feather-git-merge:before {
  content: '\e96e';
}
.feather-git-pull-request:before {
  content: '\e96f';
}
.feather-github:before {
  content: '\e970';
}
.feather-gitlab:before {
  content: '\e971';
}
.feather-globe:before {
  content: '\e972';
}
.feather-grid:before {
  content: '\e973';
}
.feather-hard-drive:before {
  content: '\e974';
}
.feather-hash:before {
  content: '\e975';
}
.feather-headphones:before {
  content: '\e976';
}
.feather-heart:before {
  content: '\e977';
}
.feather-help-circle:before {
  content: '\e978';
}
.feather-home:before {
  content: '\e979';
}
.feather-image:before {
  content: '\e97a';
}
.feather-inbox:before {
  content: '\e97b';
}
.feather-info:before {
  content: '\e97c';
}
.feather-instagram:before {
  content: '\e97d';
}
.feather-italic:before {
  content: '\e97e';
}
.feather-layers:before {
  content: '\e97f';
}
.feather-layout:before {
  content: '\e980';
}
.feather-life-buoy:before {
  content: '\e981';
}
.feather-link:before {
  content: '\e982';
}
.feather-link-2:before {
  content: '\e983';
}
.feather-linkedin:before {
  content: '\e984';
}
.feather-list:before {
  content: '\e985';
}
.feather-loader:before {
  content: '\e986';
}
.feather-lock:before {
  content: '\e987';
}
.feather-log-in:before {
  content: '\e988';
}
.feather-log-out:before {
  content: '\e989';
}
.feather-mail:before {
  content: '\e98a';
}
.feather-map:before {
  content: '\e98b';
}
.feather-map-pin:before {
  content: '\e98c';
}
.feather-maximize:before {
  content: '\e98d';
}
.feather-maximize-2:before {
  content: '\e98e';
}
.feather-menu:before {
  content: '\e98f';
}
.feather-message-circle:before {
  content: '\e990';
}
.feather-message-square:before {
  content: '\e991';
}
.feather-mic:before {
  content: '\e992';
}
.feather-mic-off:before {
  content: '\e993';
}
.feather-minimize:before {
  content: '\e994';
}
.feather-minimize-2:before {
  content: '\e995';
}
.feather-minus:before {
  content: '\e996';
}
.feather-minus-circle:before {
  content: '\e997';
}
.feather-minus-square:before {
  content: '\e998';
}
.feather-monitor:before {
  content: '\e999';
}
.feather-moon:before {
  content: '\e99a';
}
.feather-more-horizontal:before {
  content: '\e99b';
}
.feather-more-vertical:before {
  content: '\e99c';
}
.feather-move:before {
  content: '\e99d';
}
.feather-music:before {
  content: '\e99e';
}
.feather-navigation:before {
  content: '\e99f';
}
.feather-navigation-2:before {
  content: '\e9a0';
}
.feather-octagon:before {
  content: '\e9a1';
}
.feather-package:before {
  content: '\e9a2';
}
.feather-paperclip:before {
  content: '\e9a3';
}
.feather-pause:before {
  content: '\e9a4';
}
.feather-pause-circle:before {
  content: '\e9a5';
}
.feather-percent:before {
  content: '\e9a6';
}
.feather-phone:before {
  content: '\e9a7';
}
.feather-phone-call:before {
  content: '\e9a8';
}
.feather-phone-forwarded:before {
  content: '\e9a9';
}
.feather-phone-incoming:before {
  content: '\e9aa';
}
.feather-phone-missed:before {
  content: '\e9ab';
}
.feather-phone-off:before {
  content: '\e9ac';
}
.feather-phone-outgoing:before {
  content: '\e9ad';
}
.feather-pie-chart:before {
  content: '\e9ae';
}
.feather-play:before {
  content: '\e9af';
}
.feather-play-circle:before {
  content: '\e9b0';
}
.feather-plus:before {
  content: '\e9b1';
}
.feather-plus-circle:before {
  content: '\e9b2';
}
.feather-plus-square:before {
  content: '\e9b3';
}
.feather-pocket:before {
  content: '\e9b4';
}
.feather-power:before {
  content: '\e9b5';
}
.feather-printer:before {
  content: '\e9b6';
}
.feather-radio:before {
  content: '\e9b7';
}
.feather-refresh-ccw:before {
  content: '\e9b8';
}
.feather-refresh-cw:before {
  content: '\e9b9';
}
.feather-repeat:before {
  content: '\e9ba';
}
.feather-rewind:before {
  content: '\e9bb';
}
.feather-rotate-ccw:before {
  content: '\e9bc';
}
.feather-rotate-cw:before {
  content: '\e9bd';
}
.feather-rss:before {
  content: '\e9be';
}
.feather-save:before {
  content: '\e9bf';
}
.feather-scissors:before {
  content: '\e9c0';
}
.feather-search:before {
  content: '\e9c1';
}
.feather-send:before {
  content: '\e9c2';
}
.feather-server:before {
  content: '\e9c3';
}
.feather-settings:before {
  content: '\e9c4';
}
.feather-share:before {
  content: '\e9c5';
}
.feather-share-2:before {
  content: '\e9c6';
}
.feather-shield:before {
  content: '\e9c7';
}
.feather-shield-off:before {
  content: '\e9c8';
}
.feather-shopping-bag:before {
  content: '\e9c9';
}
.feather-shopping-cart:before {
  content: '\e9ca';
}
.feather-shuffle:before {
  content: '\e9cb';
}
.feather-sidebar:before {
  content: '\e9cc';
}
.feather-skip-back:before {
  content: '\e9cd';
}
.feather-skip-forward:before {
  content: '\e9ce';
}
.feather-slack:before {
  content: '\e9cf';
}
.feather-slash:before {
  content: '\e9d0';
}
.feather-sliders:before {
  content: '\e9d1';
}
.feather-smartphone:before {
  content: '\e9d2';
}
.feather-speaker:before {
  content: '\e9d3';
}
.feather-square:before {
  content: '\e9d4';
}
.feather-star:before {
  content: '\e9d5';
}
.feather-stop-circle:before {
  content: '\e9d6';
}
.feather-sun:before {
  content: '\e9d7';
}
.feather-sunrise:before {
  content: '\e9d8';
}
.feather-sunset:before {
  content: '\e9d9';
}
.feather-tablet:before {
  content: '\e9da';
}
.feather-tag:before {
  content: '\e9db';
}
.feather-target:before {
  content: '\e9dc';
}
.feather-terminal:before {
  content: '\e9dd';
}
.feather-thermometer:before {
  content: '\e9de';
}
.feather-thumbs-down:before {
  content: '\e9df';
}
.feather-thumbs-up:before {
  content: '\e9e0';
}
.feather-toggle-left:before {
  content: '\e9e1';
}
.feather-toggle-right:before {
  content: '\e9e2';
}
.feather-trash:before {
  content: '\e9e3';
}
.feather-trash-2:before {
  content: '\e9e4';
}
.feather-trending-down:before {
  content: '\e9e5';
}
.feather-trending-up:before {
  content: '\e9e6';
}
.feather-triangle:before {
  content: '\e9e7';
}
.feather-truck:before {
  content: '\e9e8';
}
.feather-tv:before {
  content: '\e9e9';
}
.feather-twitter:before {
  content: '\e9ea';
}
.feather-type:before {
  content: '\e9eb';
}
.feather-umbrella:before {
  content: '\e9ec';
}
.feather-underline:before {
  content: '\e9ed';
}
.feather-unlock:before {
  content: '\e9ee';
}
.feather-upload:before {
  content: '\e9ef';
}
.feather-upload-cloud:before {
  content: '\e9f0';
}
.feather-user:before {
  content: '\e9f1';
}
.feather-user-check:before {
  content: '\e9f2';
}
.feather-user-minus:before {
  content: '\e9f3';
}
.feather-user-plus:before {
  content: '\e9f4';
}
.feather-user-x:before {
  content: '\e9f5';
}
.feather-users:before {
  content: '\e9f6';
}
.feather-video:before {
  content: '\e9f7';
}
.feather-video-off:before {
  content: '\e9f8';
}
.feather-voicemail:before {
  content: '\e9f9';
}
.feather-volume:before {
  content: '\e9fa';
}
.feather-volume-1:before {
  content: '\e9fb';
}
.feather-volume-2:before {
  content: '\e9fc';
}
.feather-volume-x:before {
  content: '\e9fd';
}
.feather-watch:before {
  content: '\e9fe';
}
.feather-wifi:before {
  content: '\e9ff';
}
.feather-wifi-off:before {
  content: '\ea00';
}
.feather-wind:before {
  content: '\ea01';
}
.feather-x:before {
  content: '\ea02';
}
.feather-x-circle:before {
  content: '\ea03';
}
.feather-x-square:before {
  content: '\ea04';
}
.feather-youtube:before {
  content: '\ea05';
}
.feather-zap:before {
  content: '\ea06';
}
.feather-zap-off:before {
  content: '\ea07';
}
.feather-zoom-in:before {
  content: '\ea08';
}
.feather-zoom-out:before {
  content: '\ea09';
}
